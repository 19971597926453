import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useEffect, useState } from 'react'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'
import connect from '../../connect'
import { Button } from '../../../../components/common/Buttons'
import { useAlertContext } from '../../../../contexts/AlertContext'
import EditIcon from '@material-ui/icons/Edit'
import PlusIcon from '@material-ui/icons/Add'

import Item from './item'
import ItemFile from './itemFile'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import SetupParentFormsModal from '../formstackManagement/setupParentFormsModal'
import { emptyArray } from '../../../../utils/arrayUtils'
import { useParams } from 'react-router-dom'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard'
import AddFileToFormModal from '../formstackManagement/addFileToFormModal'
import Loader from '../../../../components/common/Loader'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const FormDetail = ({
  adjustmentForms,
  getAdjustmentForms,
  changeCurrentIdsForms,
  formstackForms,
  changeFormstackForms,
  currentFormDetail,
  files,
  pendingDetail,
}) => {
  const { trans } = useTrans()
  const [modules, setModules] = useState([])
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const { id } = useParams()
  const permissions = useSelector(selectUserPermissions)
  const [openAddFileModal, onOpenAddFileModal, onCloseAddFileModal] =
    useBooleanHandlers()

  const parentFormsModal = () => {
    const ids = adjustmentForms.map(i => i.formStackId)

    let formstackFormsCopy = []
    formstackFormsCopy = JSON.parse(JSON.stringify(formstackForms))
    formstackFormsCopy = formstackFormsCopy.map(i => ({
      ...i,
      checked: ids.includes(i.id),
    }))

    changeFormstackForms(formstackFormsCopy)
    changeCurrentIdsForms(ids)
    onOpenModal()
  }

  const addFileFormModal = () => {
    onOpenAddFileModal()
  }

  const openLink = () => {
    window.open(currentFormDetail.url, '_blank')
  }

  useEffect(() => {
    if (id) {
      getAdjustmentForms({
        formId: id,
      })
    }
  }, [id])

  return (
    <SpacingContainerBase>
      {pendingDetail && <Loader open={pendingDetail} />}
      {!pendingDetail && (
        <>
          <Paper component={SpacingContainer} py={3} px="5sm3">
            <SetupParentFormsModal
              open={openModal}
              onClose={onCloseModal}
              parentFormId={id}
              type="adjust"
            />

            <AddFileToFormModal
              open={openAddFileModal}
              onClose={onCloseAddFileModal}
              parentFormId={id}
              type="principal"
            />

            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs zeroMinWidth>
                <Typography variant="h4" weight="500" fontFamily="lexend">
                  {/* <AssigmentIcon style={{ marginRight: '5px' }} />{' '} */}
                  {currentFormDetail && currentFormDetail.name}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  style={{ marginRight: '12px', padding: '0 8px' }}
                  isIcon
                  color="primary"
                  variant="outlined"
                  onClick={() => openLink()}
                >
                  <OpenInNewIcon />
                </Button>
                {isEnabled(
                  permissions,
                  'global-settings',
                  'global-settings-page',
                  'edit'
                ) && (
                  <Button
                    isIcon
                    color="primary"
                    variant="contained"
                    onClick={() => parentFormsModal()}
                  >
                    <EditIcon />
                  </Button>
                )}
              </Grid>
            </Grid>
            <Divider light style={{ marginTop: '20px' }} />
            {!emptyArray(adjustmentForms) && (
              <TableContainer component={Paper} radius={9}>
                <Table style={{ minWidth: 1000 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{trans('adjustment-forms')}</TableCell>
                      <TableCell align="left">{trans('state')}</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {!emptyArray(adjustmentForms) &&
                      adjustmentForms.map(item => (
                        <Item form={item} key={item.id} />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {emptyArray(adjustmentForms) && (
              <div style={{ textAlign: 'center', padding: '16px' }}>
                <NothingFoundCard
                  title={trans('no-adjustment-forms')}
                  size="medium"
                />
                {isEnabled(
                  permissions,
                  'global-settings',
                  'global-settings-page',
                  'edit'
                ) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => parentFormsModal()}
                  >
                    {trans('add-forms')}
                  </Button>
                )}
              </div>
            )}
          </Paper>
          <br />
          <Paper component={SpacingContainer} py={3} px="5sm3">
            <SetupParentFormsModal
              open={openModal}
              onClose={onCloseModal}
              parentFormId={id}
              type="adjust"
            />

            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs zeroMinWidth>
                <Typography variant="h4" weight="500" fontFamily="lexend">
                  {trans('form-files')}
                </Typography>
              </Grid>
              <Grid item>
                {isEnabled(
                  permissions,
                  'global-settings',
                  'global-settings-page',
                  'edit'
                ) && (
                  <Button
                    isIcon
                    color="primary"
                    variant="contained"
                    onClick={() => addFileFormModal()}
                  >
                    <PlusIcon />
                  </Button>
                )}
              </Grid>
            </Grid>
            <Divider light style={{ marginTop: '20px' }} />
            {!emptyArray(files) && (
              <TableContainer component={Paper} radius={9}>
                <Table style={{ minWidth: 1000 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{trans('file-name')}</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {!emptyArray(files) &&
                      files.map((item, index) => (
                        <ItemFile file={item} index={index} key={item} />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {emptyArray(files) && (
              <div style={{ textAlign: 'center', padding: '16px' }}>
                <NothingFoundCard title={trans('no-files')} size="medium" />
                {isEnabled(
                  permissions,
                  'global-settings',
                  'global-settings-page',
                  'edit'
                ) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => addFileFormModal()}
                  >
                    {trans('add-files')}
                  </Button>
                )}
              </div>
            )}
          </Paper>
        </>
      )}
    </SpacingContainerBase>
  )
}
export default connect(FormDetail)
