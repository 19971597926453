import React, { useEffect, useState } from 'react'
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase'
import DetailsAnchorsHeader from '../../../components/generics/DetailsAnchorsHeader'
import SectionScrollable from '../../../components/common/Containers/SectionScrollable'
import { V_USFCRSTAFF } from '../../../utils/helpers'
import SummaryUsfcrStaff from './Summary'
import AssignedOrganizations from './AssignedOrganizations'
import LatestActivity from './LatestActivity'
import Loader from '../../../components/common/Loader'
import connect from '../connect'
import { useParams } from 'react-router-dom'

const UsfcrStaffDetail = ({ userDetail, findOne }) => {
  const params = useParams()
  const [pending, setPending] = useState(false)
  const [user, setUser] = useState(null)

  const getUser = () => {
    setPending(true)
    findOne({ id: params.id }).then(res => {
      setPending(false)
      setUser(res.payload)
    })
  }

  useEffect(() => {
    getUser(params.id)
  }, [params.id])

  return (
    <SectionScrollable
      TopFixed={
        <DetailsAnchorsHeader
          anchors={V_USFCRSTAFF}
          backLink={`/usfcr-staff`}
          onPrint={undefined}
        />
      }
    >
      {pending && <Loader open={pending} />}
      {!pending && user && (
        <SpacingContainerBase py={0}>
          <div id="summary">
            <SummaryUsfcrStaff
              item={userDetail}
              fullFilledUserAction={getUser}
            />
          </div>
          <div id="assigned-organizations">
            <AssignedOrganizations userId={userDetail.userName} />
          </div>
          <div id="latest-activity">
            <LatestActivity userId={userDetail.userName} />
          </div>
        </SpacingContainerBase>
      )}
    </SectionScrollable>
  )
}

export default connect(UsfcrStaffDetail)
