import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import { useTrans } from '../../../../services/i18n'
import { Grid } from '@material-ui/core'
import { Button } from '../../../../components/common/Buttons'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'
import RequestFiles from '../requestFiles'
import AssignForms from '../assingForms'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import AddFromTemplates from './AddFromTemplates'
import { useState } from 'react'
import { LoaderSmall } from '../../../../components/common/LoaderSmall'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const Requests = ({ organization, onChangeTab }) => {
  const { trans } = useTrans()
  const permissions = useSelector(selectUserPermissions)
  const [openTemplates, onOpenTemplatesModal, onCloseTemplatesModal] =
    useBooleanHandlers()
  const [loading, setLoading] = useState(false)

  const showLoading = section => setLoading(section)

  return (
    <SpacingContainerBase py={3}>
      <Paper component={SpacingContainer}>
        <SpacingContainer px={5} py={3}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item zeroMinWidth>
              <Typography variant="h4" weight="500" fontFamily="lexend">
                {trans('request-files-forms')}
              </Typography>
            </Grid>
            <Grid item spacing={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={onOpenTemplatesModal}
                style={{ marginRight: '0.8rem' }}
                disabled={
                  !isEnabled(permissions, 'organization', 'files', 'create')
                }
              >
                {trans('add-from-template')}
              </Button>
              <Button color="primary" variant="contained" onClick={null}>
                {trans('download-all')}
              </Button>
            </Grid>
          </Grid>
        </SpacingContainer>

        <Divider light />

        <div>
          {loading && ['all', 'file'].includes(loading) && (
            <LoaderSmall text={false} />
          )}
          {(!loading || !['all', 'file'].includes(loading)) &&
            isEnabled(permissions, 'organization', 'files') && (
              <RequestFiles onChangeTab={onChangeTab} />
            )}
        </div>

        <Divider light spacing={1} />

        <div>
          {loading && ['all', 'form'].includes(loading) && (
            <LoaderSmall text={false} />
          )}
          {(!loading || !['all', 'form'].includes(loading)) &&
            isEnabled(permissions, 'organization', 'assigned-forms') && (
              <AssignForms />
            )}
        </div>
      </Paper>

      <AddFromTemplates
        item={organization}
        open={openTemplates}
        onLoading={showLoading}
        onClose={onCloseTemplatesModal}
      />
    </SpacingContainerBase>
  )
}

export default Requests
