import React from 'react'
import { Chip } from '../../../components/common/Chip'
import { useTrans } from '../../../services/i18n'
import { capitalizeFirstLetter } from '../../../utils/helpers'

const colorMap = {
  Completed: '#a5cb55',
  Pending: '#9576ff',
  Paid: '#ff5225',
  Expired: '#003eaa',
}

const StatusChip = React.memo(({ status, expired = false }) => {
  const { trans } = useTrans()
  return (
    <>
      {expired ? (
        <Chip label={trans('expired')} color={'tertiary'} />
      ) : (
        <Chip
          label={trans(status)}
          color={'secondary'}
          style={{
            backgroundColor:
              colorMap[capitalizeFirstLetter(status)] ?? colorMap.unknown,
          }}
        />
      )}
    </>
  )
})

export default StatusChip
