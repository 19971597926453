import React from 'react';
import palette from '../../../themes/theme/pallete';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconSort = (props) => {
  const { width = 24, height = 24, order = '' } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      {order === 'asc' ? (
        <>
          <path
            d="M12 13L16 18 8 18z"
            transform="translate(-1000.000000, -2213.000000) translate(250.000000, 182.000000) translate(0.000000, 1811.000000) translate(0.000000, 207.000000) translate(750.000000, 13.000000) translate(12.000000, 15.500000) scale(1, -1) translate(-12.000000, -15.500000)"
          />
          <path
            fill={palette.gray.main}
            d="M12 6L16 11 8 11z"
            transform="translate(-1000.000000, -2213.000000) translate(250.000000, 182.000000) translate(0.000000, 1811.000000) translate(0.000000, 207.000000) translate(750.000000, 13.000000)"
          />
        </>
      ) : order === 'desc' ? (
        <>
          <path
            fill={palette.gray.main}
            d="M12 13L16 18 8 18z"
            transform="translate(-858.000000, -2213.000000) translate(250.000000, 182.000000) translate(0.000000, 1811.000000) translate(0.000000, 207.000000) translate(608.000000, 13.000000) translate(12.000000, 15.500000) scale(1, -1) translate(-12.000000, -15.500000)"
          />
          <path
            d="M12 6L16 11 8 11z"
            transform="translate(-858.000000, -2213.000000) translate(250.000000, 182.000000) translate(0.000000, 1811.000000) translate(0.000000, 207.000000) translate(608.000000, 13.000000)"
          />
        </>
      ) : (
        <path
          d="M16 13l-4 5-4-5h8zm-4-7l4 5H8l4-5z"
          transform="translate(-1169 -321) translate(380 146) translate(0 172) translate(548 3) translate(241)"
        />
      )}
    </SvgIcon>
  );
};

IconSort.propTypes = simpleIconPropsTypes;
