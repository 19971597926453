import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import React, { useCallback } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Button } from '../../../../../components/common/Buttons'
import {
  InputPropsLock,
  InputPropsUser,
  TextField,
} from '../../../../../components/common/Inputs'
import SimpleForm from '../../../../../components/common/SimpleForm'
import Typography from '../../../../../components/common/Typography'
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers'
import { useTrans } from '../../../../../services/i18n'
import useStyles from '../../../styles'
import { ForgotPassword } from '../ForgotPassword'
import { NeedHelpDialog } from '../NeedHelpDialog'
import { temporaryPasswordExpired, userNotConfirmed } from '../../constants'
import ResendConfirmationLink from '../../../ResendConfirmationLink'
import ResendTemporaryPassword from '../../../ResendTemporaryPassword'

function SignInForm({ pending = false, onSubmit, error, onFlushError }) {
  const classes = useStyles()
  const { trans } = useTrans()
  const [openForgotPassword, onOpenForgotPassword, onCloseForgotPassword] =
    useBooleanHandlers()
  const [openNeedHelpDialog, onOpenNeedHelpDialog, onCloseNeedHelpDialog] =
    useBooleanHandlers()
  const [email, setEmail] = React.useState('')

  const getErrorMessage = () => {
    switch (error) {
      case userNotConfirmed:
        return (
          <React.Fragment>
            <Typography variant={'span'} color="error">
              {trans('looks-like-not-confirmed-user')}{' '}
            </Typography>
            <ResendConfirmationLink email={email} onSent={onFlushError} />
          </React.Fragment>
        )
      case temporaryPasswordExpired:
        return (
          <React.Fragment>
            <Typography variant={'span'} color="error">
              {trans('temporary-password-expired')}{' '}
            </Typography>
            <ResendTemporaryPassword email={email} onSent={onFlushError} />
          </React.Fragment>
        )
    }
    return <Typography color="error">{error}</Typography>
  }

  const handleHelpResetPress = useCallback(() => {
    onCloseNeedHelpDialog()
    onOpenForgotPassword()
  }, [onOpenForgotPassword, onCloseNeedHelpDialog])

  return (
    <>
      <ForgotPassword
        open={openForgotPassword}
        onClose={onCloseForgotPassword}
      />
      <NeedHelpDialog
        open={openNeedHelpDialog}
        onClose={onCloseNeedHelpDialog}
        onResetClick={handleHelpResetPress}
      />

      <div className={classes.formBack}>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          className={classes.content}
        >
          <Grid item xs className={classes.signForm}>
            <Grid
              container
              spacing={3}
              component={SimpleForm}
              name="signInForm"
              onSubmit={onSubmit}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h1Medium"
                  fontFamily="lexend"
                  weight="500"
                  style={{ color: 'black' }}
                >
                  {trans('admin-portal')}
                </Typography>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  {getErrorMessage()}
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  type="email"
                  onChange={e => {
                    setEmail(e.target.value)
                  }}
                  name="login"
                  placeholder={trans('email')}
                  InputProps={InputPropsUser}
                  big
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="password"
                  type="password"
                  placeholder={trans('password')}
                  InputProps={InputPropsLock}
                  disabled={pending}
                  big
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                spacing={1}
                container
                alignItems="center"
                className={classes.mainActions}
              >
                <Grid item xs={4}>
                  <Button
                    id="login-btn"
                    className={classes.loginButton}
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    pending={pending}
                  >
                    {trans('login')}
                  </Button>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Link onClick={onOpenForgotPassword}>
                    <Typography variant="body2" weight="400">
                      {trans('recover-password')}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default SignInForm
