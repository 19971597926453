import MUIChip from '@material-ui/core/Chip';
import { alpha as fade } from '@material-ui/core/styles/colorManipulator';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React from 'react';

const customColors = ['success', 'warning', 'disabled', 'error', 'white', 'attached'];

export const Chip = ({
  color,
  className,
  variant,
  indented,
  dashed,
  square = false,
  isIcon = false,
  flex = false,
  ...rest
}) => {
  const styles = useStyles();
  const isCustomColor = customColors.includes(color);

  return (
    <MUIChip
      color={isCustomColor ? 'default' : color}
      classes={{ deleteIcon: clsx(isCustomColor && styles.deleteIcon) }}
      className={clsx(
        'mui-chip',
        className,
        !variant && isCustomColor && styles[color],
        variant === 'outlined' && isCustomColor && styles[`${color}Outlined`],
        indented && styles.indented,
        dashed && styles.dashed,
        square && styles.square,
        isIcon && 'mui-chip-icon',
        flex && 'flex'
      )}
      variant={variant}
      deleteIcon={<CloseIcon />}
      {...rest}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  square: { borderRadius: 2 },
  indented: { margin: 5 },
  dashed: { borderStyle: 'dashed' },
  deleteIcon: { color: 'inherit' },

  attached: {
    backgroundColor: theme.palette.gray.background,
    color: theme.palette.common.black,
  },

  // Contained
  white: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
  },
  error: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
  },
  disabled: {
    color: theme.palette.gray.main,
  },

  // Outlined
  whiteOutlined: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  errorOutlined: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  warningOutlined: {
    color: theme.palette.warning.main,
    borderColor: fade(theme.palette.warning.main, 0.3),
  },
  successOutlined: {
    color: theme.palette.success.main,
    borderColor: fade(theme.palette.success.main, 0.3),
  },
  disabledOutlined: {
    color: theme.palette.gray.main,
    borderColor: fade(theme.palette.gray.main, 0.3),
  },
}));
