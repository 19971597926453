import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useEffect, useState } from 'react'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import { useAlertContext } from '../../../../contexts/AlertContext'
import AssigmentIcon from '@material-ui/icons/AssignmentOutlined'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'

import connect from '../../connect'
import { Button } from '../../../../components/common/Buttons'
import { TextField } from '../../../../components/common/Inputs'
import { ReactComponent as KeyIcon } from './images/key.svg'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const ApiKeyConfiguration = ({
  samApiKey,
  getGlobalSettings,
  updateApiKey,
}) => {
  const { trans } = useTrans()
  const [modules, setModules] = useState([])
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const [openModalFile, setOpenModalFile] = useState(false)
  const permissions = useSelector(selectUserPermissions)

  const [apiKey, setApiKey] = useState('')
  const [currentApiKey, setCurrentApiKey] = useState('')

  const openModalFileAction = () => {
    setOpenModalFile(true)
  }
  const closeModalFileAction = () => {
    setOpenModalFile(false)
  }

  const changeInput = ev => {
    setApiKey(ev.target.value)
  }

  const changeApiKey = async () => {
    try {
      if (!apiKey) {
        errorAlert('The api key field cannot be empty')
        return
      }
      setIsSend(true)
      await updateApiKey({ apiKey })
      setIsSend(false)
      successAlert('Your api key was saved successfully')
    } catch (error) {
      setIsSend(false)
    }
  }

  useEffect(async () => {
    await getGlobalSettings({})
  }, [])

  useEffect(() => {
    setCurrentApiKey(samApiKey)
    setApiKey(' ')
    setApiKey('')
  }, [samApiKey])

  return (
    <SpacingContainer py={2}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              style={{ alignItems: 'center', display: 'flex' }}
              variant="h4"
              weight="500"
              fontFamily="lexend"
            >
              <KeyIcon style={{ marginRight: '5px' }} />{' '}
              {trans('api-key-configuration')}{' '}
              <ShowTooltip sectionToShow="api-key-configuration" />
            </Typography>
          </Grid>
        </Grid>
        <Divider light style={{ marginTop: '20px' }} />
        <div style={{ alignItems: 'center', paddingTop: '20px' }}>
          <TextField
            placeholder={currentApiKey ? currentApiKey : 'Paste your API key'}
            onChange={changeInput}
            value={apiKey}
            label="API Key"
            style={{ width: '60%', marginRight: '16px' }}
          />{' '}
          {isEnabled(permissions, 'global-settings', '', 'edit') && (
            <Button
              onClick={() => changeApiKey()}
              size="large"
              variant="contained"
              color="primary"
              pending={isSend}
            >
              {trans('add-api-key')}
            </Button>
          )}
        </div>
      </Paper>
    </SpacingContainer>
  )
}
export default connect(ApiKeyConfiguration)
