import { connect } from 'react-redux'
import { application } from '../../../../services/application';
import { actions } from '../../../../store/clientDetail'

function mapStateToProps(state) {
  return {
    settings: state.orgSettings,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveCustomization: params => dispatch(actions.saveCustomization(params)),
    getAvatarUploadUrl: (fileName) => {
      return application.call('admin.partnership.getUploadAvatarUrl', { fileName });
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
