import { ModalDialog } from '../../../../components/common/Dialogs'
import { Box, Grid } from '@material-ui/core'
import { Button } from '../../../../components/common/Buttons'
import Typography from '../../../../components/common/Typography'

function FailedDeleteOrganizationModal({ open, onClose }) {
  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title="Organization can't be deleted"
      actions={
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="contained" color="primary" onClick={onClose}>
              Confirm
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Box sx={{ textAlign: 'center' }}>
        <Typography py={5} variant="h2" weight="500">
          Delete this organization?
        </Typography>
        <Typography variant="body1">
          Please remove all users except owner from the Organization Members
          section before you can delete this organization. All existing
          subsidiary organizations will be deleted as well.
        </Typography>
      </Box>
    </ModalDialog>
  )
}

export default FailedDeleteOrganizationModal
