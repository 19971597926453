import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { API, SCOPE_REQUEST_TEMPLATE_FORMS } from '../../constants'
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common'
import {
  extendListBuilder,
  generateListActions,
} from '../helpers/listActionsHelpers'
import { application } from '../../services/application'

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['createdAt', 'desc'],
}

//Actions
const listActions = generateListActions({
  scope: SCOPE_REQUEST_TEMPLATE_FORMS,
  apiMethod: {
    GET_LIST: API.REQUEST_TEMPLATE_FORMS.GET_LIST,
  },
  getStore: (store) => store.requestedQuotes,
})

const deleteRequestTemplateForm = createAsyncThunk(
  'requestTemplateForm/deleteRequestTemplateForm',
  (params) => {
    return application.call(API.REQUEST_TEMPLATE_FORMS.DELETE, params)
  }
)
function onDeleteRequestTemplateForm(state, action) {}

const findOne = createAsyncThunk('requestTemplateForm/findOne', (params) => {
  return application.call(API.REQUEST_TEMPLATE_FORMS.FIND_ONE, params)
})
function onFindOne(state, action) {}

const saveRequestTemplateForm = createAsyncThunk(
  'requestTemplateForm/saveRequestTemplateForm',
  (params) => {
    return application.call(
      API.REQUEST_TEMPLATE_FORMS.SAVE_REQUEST_TEMPLATE,
      params
    )
  }
)

function onSaveRequestTemplateForm(state, action) {}

const getList = createAsyncThunk('requestTemplateForm/getList', (params) => {
  return application.call(API.REQUEST_TEMPLATE_FORMS.GET_LIST, params)
})
function onGetList(state, action) {
  console.log(action, state)
  state.items = action.payload.items
  state.count = action.payload.count
  state.pending = false
}

export const actions = {
  ...listActions,
  findOne,
  deleteRequestTemplateForm,
  saveRequestTemplateForm,
  getList,
}

export default createReducer(INITIAL_STATE, (builder) => {
  return extendListBuilder(builder, actions)
    .addCase(deleteRequestTemplateForm.fulfilled, onDeleteRequestTemplateForm)
    .addCase(findOne.fulfilled, onFindOne)
    .addCase(saveRequestTemplateForm.fulfilled, onSaveRequestTemplateForm)
    .addCase(getList.pending, (state) => {
      state.pending = true
    })
    .addCase(getList.fulfilled, onGetList)
})
