import { Box, Grid, Tooltip } from '@material-ui/core'
import PaletteIcon from '@mui/icons-material/Palette'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import { Button } from '../../../../components/common/Buttons'
import { Avatar } from '../../../../components/common/Cards'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import { Divider } from '../../../../components/common/Divider'
import Typography from '../../../../components/common/Typography'
import FieldValue from '../../../../components/generics/Helpers/FieldValue'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { useTrans } from '../../../../services/i18n'
import {
  intlDateFormat,
  intlDateWithTimeFormat,
} from '../../../../utils/dateUtils'
import CustomizationModal from '../../../GlobalSettings/components/partnerCode/customize'
import connect from '../connect'
import TagsChip from './Tags'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import {
  DeleteOrganizationModal,
  FailedDeleteOrganizationModal,
} from '../deleteOrganizationModal'
import { MergeOrganizationModal } from '../../components'
import { useSelector } from 'react-redux'
import { selectUserPermissions, isEnabled } from '../../../../selectors'

const OrganizationDetails = ({
  detail,
  activeDeactiveOrganization,
  organizationHierarchy,
}) => {
  const { trans } = useTrans()
  const permissions = useSelector(selectUserPermissions)
  const { successAlert, errorAlert } = useAlertContext()
  const [openDeleteOrgModal, onOpenDeleteOrgModal, onCloseDeleteOrgModal] =
    useBooleanHandlers()
  const [
    openFailedDeleteOrgModal,
    onOpenFailedDeleteOrgModal,
    onCloseFailedDeleteOrgModal,
  ] = useBooleanHandlers()
  const [
    openMergeOrganizationModal,
    onOpenMergeOrganizationModal,
    onCloseMergeOrganizationModal,
  ] = useBooleanHandlers()
  const [isSend, setIsSend] = useState(false)
  const [openModal, setModal] = useState(false)

  const today = dayjs()
  const typeAccount = useMemo(() => {
    if (
      dayjs(detail?.afpdsExpirationDate) > today ||
      dayjs(detail?.salesforce?.afpds_expiration__c) > today
    ) {
      return 'Enterprise'
    }
    if (detail.planId) {
      return 'Premium'
    }

    return 'Basic'
  }, [detail, today])

  const subscriptionInterval = useMemo(() => {
    if (!detail?.planId) return null

    const intervals = {
      month: 'Monthly',
      year: 'Yearly',
    }

    return intervals[detail?.subscription?.interval] ?? null
  }, [detail])

  const isClient = useMemo(
    () =>
      dayjs(detail?.samExpirationDate) > today ||
      dayjs(detail?.salesforce?.sam_expiration__c) > today,
    [detail, today]
  )

  const isSap = useMemo(
    () =>
      detail?.salesforce?.service_website__r &&
      detail?.salesforce?.service_website__r?.Service_Disposition__c.toLowerCase() !==
        'stop work' &&
      today.isAfter(
        detail.salesforce.service_website__r?.Service_Start_Date__c
      ) &&
      today.isBefore(detail.salesforce.service_website__r?.Service_End_Date__c),
    [detail, today]
  )

  const activeDeactiveAccount = () => {
    setIsSend(true)
    activeDeactiveOrganization({ orgId: detail.id, active: !detail.active })
      .then(() => {
        const message = `This organization has been successfully ${
          detail.active ? 'deactivated' : 'activated'
        }`
        setIsSend(false)
        successAlert(message)
      })
      .catch(() => {
        setIsSend(false)
        errorAlert('Error')
      })
  }

  const handleDeleteOrganization = () => {
    if (detail.members.some(m => m.role !== 'Owner')) {
      onOpenFailedDeleteOrgModal()
    } else {
      onOpenDeleteOrgModal()
    }
  }

  const modalsSlot = (
    <>
      <DeleteOrganizationModal
        onClose={onCloseDeleteOrgModal}
        open={openDeleteOrgModal}
        org={detail}
        orgHierarchy={organizationHierarchy}
      />
      <FailedDeleteOrganizationModal
        onClose={onCloseFailedDeleteOrgModal}
        open={openFailedDeleteOrgModal}
      />
      <MergeOrganizationModal
        onClose={onCloseMergeOrganizationModal}
        open={openMergeOrganizationModal}
        org={detail.id}
      />
    </>
  )
  return (
    <>
      {modalsSlot}
      <SpacingContainerBase py={1}>
        <Paper component={SpacingContainer} py={3} px="5sm3">
          <CustomizationModal open={openModal} set={setModal} detail={detail} />
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              {isEnabled(
                permissions,
                'organization',
                'account-tab',
                'delete'
              ) && (
                <Button
                  onClick={handleDeleteOrganization}
                  color="secondary"
                  variant="outlined"
                >
                  Delete
                </Button>
              )}
            </Grid>
            <Grid item>
              {isEnabled(permissions, 'organization', 'customization') && (
                <>
                  <Button
                    onClick={onOpenMergeOrganizationModal}
                    color="primary"
                    variant="outlined"
                    style={{ marginRight: '1.5rem' }}
                    disabled={
                      !isEnabled(
                        permissions,
                        'organization',
                        'customization',
                        'edit'
                      )
                    }
                  >
                    Merge
                  </Button>
                  <Button
                    isIcon
                    color="primary"
                    variant="contained"
                    onClick={() => setModal(true)}
                    disabled={
                      !isEnabled(
                        permissions,
                        'organization',
                        'customization',
                        'view'
                      )
                    }
                  >
                    <Tooltip title={'Customization'} placement="left" arrow>
                      <PaletteIcon />
                    </Tooltip>
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 15 }}
            spacing={1}
          >
            <Grid item>
              <Avatar
                variantAvatar="rounded"
                size={200}
                value={detail?.logo ? detail.logo : detail?.orgName}
                src={detail?.logo}
              />
            </Grid>
            <Grid item>
              <Typography variant="h1" weight="800">
                {detail?.orgName}
              </Typography>
            </Grid>
            {isEnabled(
              permissions,
              'organization',
              'activate-deactivate-account'
            ) && (
              <Grid item>
                <Button
                  color={detail && detail.active ? 'secondary' : 'success'}
                  variant="outlined"
                  onClick={activeDeactiveAccount}
                  pending={isSend}
                  disabled={isSend}
                  size="small"
                >
                  {detail && detail.active
                    ? trans('deactivate-account')
                    : trans('activate-account')}
                </Button>
              </Grid>
            )}
          </Grid>
          {/* // TODO: tags */}
          <Grid
            container
            spacing={4}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 20,
              gap: '1rem',
            }}
          >
            {detail?.tier && (
              <Box>
                <TagsChip status={detail.tier} />
              </Box>
            )}
            {detail?.featuredVendor && (
              <Box>
                <TagsChip status={'Claimed'} />
              </Box>
            )}
            <Box>
              <TagsChip
                status={detail && detail.active ? 'Active' : 'Deactivated'}
              />
            </Box>
          </Grid>
          <Divider light spacing={4} />
          <Grid style={{ marginTop: 15 }} container spacing={4}>
            <Grid item sm={4} xs={12}>
              <FieldValue
                value={intlDateWithTimeFormat(detail?.createdAt)}
                label={trans('registered')}
              />
            </Grid>
            {/* <Grid item sm={3} xs={12}>
              <FieldValue
                value={intlDateFormat(detail?.salesforce?.afpds_expiration__c)}
                label={trans('expiration-date')}
              />
            </Grid> */}
            <Grid item sm={4} xs={12}>
              <FieldValue
                value={detail?.owner?.name + ' ' + detail?.owner?.familyName}
                label={trans('owner')}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FieldValue
                value={detail?.owner?.email}
                label={trans('owner-email')}
              />
            </Grid>
            {detail?.urlFolderDrive &&
              isEnabled(permissions, 'organization', 'folder-google-drive') && (
                <Grid item sm={3} xs={12}>
                  <FieldValue
                    href={detail?.urlFolderDrive}
                    value={detail?.urlFolderDrive}
                    label={trans('folder-drive')}
                  />
                </Grid>
              )}
            <Grid item xs={12}>
              {detail?.featuredVendor && (
                <FieldValue
                  href={
                    detail?.featuredVendor
                      ? `https://app.test.usfcr.com/vendors/${detail?.featuredVendor?.idVendorClaim}`
                      : ' '
                  }
                  value={
                    detail?.featuredVendor
                      ? `https://app.test.usfcr.com/vendors/${detail?.featuredVendor?.idVendorClaim}`
                      : ' '
                  }
                  label={trans('organization-profile')}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </SpacingContainerBase>
    </>
  )
}
export default connect(OrganizationDetails)
