import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit'
import { API } from '../../constants'
import { application } from '../../services/application'
import { download } from '../../utils/filesUtils'
import { config } from '../../services/api'

//State
export const INITIAL_STATE = {
  detail: null,
  organizationHierarchy: [],
  users: [],
  forms: [],
  pendingDetail: false,
  currentFolder: null,
  folders: [],
  selectedFolder: null,
  breadcrumFolders: [],
  loadingFolders: false,
  historyEvents: {
    items: [],
    count: 0,
  },
  current: 0,
  monthlyLimit: 0,
  resetLimitDate: null,
}

const changeBreadcrumFolders = createAction(
  'clientDetail/changeBreadcrumFolders'
)
function onChangeBreadcrumFolders(state, action) {
  return Object.assign(state, {
    breadcrumFolders: action.payload,
  })
}

const changeSelectedFolder = createAction('clientDetail/changeSelectedFolder')
function onChangeSelectedFolder(state, action) {
  return Object.assign(state, {
    selectedFolder: action.payload,
  })
}

const createFolder = createAsyncThunk('clientDetail/createFolder', params => {
  return application.call(API.CLIENT_DETAIL.CREATE_FOLDER, params)
})
function onCreateFolder(state, action) {
  return Object.assign(state, {
    folders: [
      {
        id: action.payload.folder.data.id,
        name: action.payload.folder.data.name,
      },
      ...state.folders,
    ],
  })
}

const saveCustomization = createAsyncThunk(
  'partnership/addBrandingColor',
  params => {
    return application.call(API.CLIENT_DETAIL.SAVE_CUSTOMIZATION, params)
  }
)

const getDetail = createAsyncThunk('clientDetail/getDetail', params => {
  return application.call(API.CLIENT_DETAIL.GET_DETAIL, params)
})
function onGetDetail(state, action) {
  return Object.assign(state, {
    detail: action.payload.organization,
    organizationHierarchy: action.payload.organizationHierarchy,
    pendingDetail: false,
    selectedFolder: action.payload.selectedFolder,
    currentFolder: action.payload.currentFolder,
    folders: action.payload.folders,
    current: action.payload?.current ?? 0,
    monthlyLimit: action.payload?.monthlyLimit ?? 0,
    resetLimitDate: action.payload?.resetLimitDate ?? '',
  })
}

function onEditUser(state, action) {
  const memberPayload = action.payload.member
  const stateClone = Object.assign(state, {
    ...state,
  })
  stateClone.detail.members = stateClone.detail.members.map(member => {
    return member.id === memberPayload.id ? memberPayload : member
  })
  return stateClone
}

export const changeUserAccountStatus = createAsyncThunk(
  'clientDetail/changeUserAccountStatus',
  params => {
    return application.call(
      API.CLIENT_DETAIL.CHANGE_USFCR_USER_ACCOUNT_STATUS,
      params
    )
  }
)

export const editUserRole = createAsyncThunk(
  'clientDetail/editUserRole',
  params => {
    return application.call(API.CLIENT_DETAIL.EDIT_USER_ROLE, params)
  }
)

const getHistoryEvents = createAsyncThunk(
  'clientDetail/getHistoryEvents',
  params => {
    return application.call(API.CLIENT_DETAIL.GET_HISTORY_EVENTS, params)
  }
)
function onGetHistoryEvents(state, action) {
  return Object.assign(state, {
    historyEvents: action.payload,
  })
}

const getFoldersByFolderId = createAsyncThunk(
  'clientDetail/getFoldersByFolderId',
  params => {
    return application.call(API.CLIENT_DETAIL.GET_FOLDERS_BY_FOLDER_ID, params)
  }
)
function onGetFoldersByFolderId(state, action) {
  return Object.assign(state, {
    folders: action.payload.folders,
    currentFolder: action.payload.currentFolder,
    selectedFolder: action.payload.selectedFolder,
    loadingFolders: false,
  })
}

const getUsers = createAsyncThunk('clientDetail/getUsers', params => {
  return application.call(API.CLIENT_DETAIL.GET_USERS, params)
})
function onGetUsers(state, action) {
  return Object.assign(state, {
    users: action.payload.users,
  })
}

const getForms = createAsyncThunk('clientDetail/getForms', params => {
  return application.call(API.CLIENT_DETAIL.GET_FORMS, params)
})
function onGetForms(state, action) {
  return Object.assign(state, {
    forms: action.payload.forms,
  })
}

const saveUserUsfcr = createAsyncThunk('clientDetail/saveUserUsfcr', params => {
  return application.call(API.CLIENT_DETAIL.SAVE_USER_USFCR, params)
})
function onSaveUserUsfcr(state, action) {
  const payload = [...action.payload.users]
  const userIdsStaff = payload.map(i => i.userId)
  const users = state.users.filter(u => !userIdsStaff.includes(u.id))

  return Object.assign(state, {
    detail: { ...state.detail, assignedStaff: payload },
    users: users,
  })
}

const saveBillingSettings = createAsyncThunk(
  'clientDetail/saveBillingSettings',
  params => {
    return application.call(API.CLIENT_DETAIL.SAVE_BILLING_SETTINGS, params)
  }
)
function onSaveBillingSettings(state, action) {
  return Object.assign(state, {
    detail: { ...state.detail, salesforce: action.payload.salesforce },
  })
}

const saveFormOrg = createAsyncThunk('clientDetail/saveFormOrg', params => {
  return application.call(API.CLIENT_DETAIL.SAVE_FORM_ORG, params)
})
function onSaveFormOrg(state, action) {
  const form = action.payload.form
  const assignedForms = state.detail.forms
  assignedForms.push(form)

  const forms = state.forms.filter(f => f.id !== form.id)

  return Object.assign(state, {
    detail: { ...state.detail, forms: assignedForms },
    forms: forms,
  })
}

const saveFileRequest = createAsyncThunk(
  'clientDetail/saveFileRequest',
  params => {
    return application.call(API.CLIENT_DETAIL.SAVE_FILE_REQUEST, params)
  }
)
function onSaveFileRequest(state, action) {
  const file = action.payload.file
  const fileRequests = state.detail.fileRequests
  fileRequests.push(file)

  return Object.assign(state, {
    detail: { ...state.detail, fileRequests: fileRequests },
  })
}

const updateFileRequest = createAsyncThunk(
  'clientDetail/updateFileRequest',
  params => {
    return application.call(API.CLIENT_DETAIL.UPDATE_FILE_REQUEST, params)
  }
)
function onUpdateFileRequest(state, action) {
  const file = action.payload.file
  const fileRequests = state.detail.fileRequests

  const index = fileRequests.findIndex(i => i.id === file.id)
  fileRequests[index] = file

  return Object.assign(state, {
    detail: { ...state.detail, fileRequests: fileRequests },
  })
}

const savePermissions = createAsyncThunk(
  'clientDetail/savePermissions',
  params => {
    return application.call(API.CLIENT_DETAIL.SAVE_PERMISSIONS, params)
  }
)
function onSavePermissions(state, action) {
  const permissions = action.payload.permissions

  return Object.assign(state, {
    detail: { ...state.detail, permissions: permissions },
  })
}

const deleteUserUsfcr = createAsyncThunk(
  'clientDetail/deleteUserUsfcr',
  params => {
    return application.call(API.CLIENT_DETAIL.DELETE_USER_USFCR, params)
  }
)
function onDeleteUserUsfcr(state, action) {
  const user = action.payload.user
  const assignedStaff = state.detail.assignedStaff
  const index = assignedStaff.findIndex(i => i.id === user.id)
  assignedStaff.splice(index, 1)

  const users = [...state.users]
  users.push(user)

  return Object.assign(state, {
    detail: { ...state.detail, assignedStaff: assignedStaff },
    users: users,
  })
}

const removeUserFromOrg = createAsyncThunk(
  'clientDetail/removeUserFromOrg',
  params => {
    return application.call(API.CLIENT_DETAIL.REMOVE_USER_FROM_ORG, params)
  }
)
function onRemoveUserFromOrg(state, action) {
  const memberPayload = action.payload.user
  const stateClone = Object.assign(state, {
    ...state,
  })
  stateClone.detail.members = stateClone.detail.members.filter(member => {
    return member.organizationUserId !== memberPayload.organizationUserId
  })
  return stateClone
}

const deleteFormOrg = createAsyncThunk('clientDetail/deleteFormOrg', params => {
  return application.call(API.CLIENT_DETAIL.DELETE_FORM_ORG, params)
})
function onDeleteFormOrg(state, action) {
  const isAdjustForm = action.payload.isAdjustForm || false
  const form = action.payload.form
  const assignedForms = isAdjustForm
    ? state.detail.adjustedForms
    : state.detail.forms
  const index = assignedForms.findIndex(i => i.id === form.id)
  assignedForms.splice(index, 1)

  const forms = [...state.forms]
  forms.push(form)

  return Object.assign(state, {
    detail: {
      ...state.detail,
      ...(!isAdjustForm && { forms: assignedForms }),
      ...(isAdjustForm && { adjustedForms: assignedForms }),
    },
    ...(!isAdjustForm && { forms: forms }),
  })
}

const activeDeactiveOrganization = createAsyncThunk(
  'clientDetail/activeDeactiveOrganization',
  params => {
    return application.call(API.CLIENT_DETAIL.ACTIVE_DEACTIATE_ORG, params)
  }
)
function onActiveDeactiveOrganization(state, action) {
  return Object.assign(state, {
    detail: { ...state.detail, active: action.payload.active },
  })
}

const deleteFileOrg = createAsyncThunk('clientDetail/deleteFileOrg', params => {
  return application.call(API.CLIENT_DETAIL.DELETE_FILE_ORG, params)
})
function onDeleteFileOrg(state, action) {
  const file = action.payload.file
  const fileRequests = state.detail.fileRequests
  const index = fileRequests.findIndex(i => i.id === file.id)
  fileRequests.splice(index, 1)

  return Object.assign(state, {
    detail: { ...state.detail, fileRequests: fileRequests },
  })
}

const approveFileOrg = createAsyncThunk(
  'clientDetail/approveFileOrg',
  params => {
    return application.call(API.CLIENT_DETAIL.APPROVE_FILE_ORG, params)
  }
)
function onApproveFileOrg(state, action) {
  // Get the file request
  const file = action.payload.file
  // Get all file request from org
  const fileRequests = state.detail.fileRequests
  // Find the file request by its id
  const updatedItems = fileRequests.map(item => {
    if (item.id === file.id) {
      // Modify the status property of the matched item
      return { ...item, status: 'Approved' }
    }
    return item
  })

  return Object.assign(state, {
    detail: { ...state.detail, fileRequests: updatedItems },
  })
}

const denyFileOrg = createAsyncThunk('clientDetail/denyFileOrg', params => {
  return application.call(API.CLIENT_DETAIL.DENY_FILE_ORG, params)
})
function onDenyFileOrg(state, action) {
  // Get the file request
  const file = action.payload.file
  // Get all file request from org
  const fileRequests = state.detail.fileRequests
  // Find the file request by its id
  const updatedItems = fileRequests.map(item => {
    if (item.id === file.id) {
      // Modify the status property of the matched item
      return { ...item, status: 'Denied' }
    }
    return item
  })

  return Object.assign(state, {
    detail: { ...state.detail, fileRequests: updatedItems },
  })
}

const downloadFileOrg = createAsyncThunk(
  'clientDetail/downloadFileOrg',
  params => {
    return fetch(
      `${config.apiHost}/download-all-file-request-drive?fileId=${params.id}`
    )
      .then(res => {
        return res.blob()
      })
      .then(blob => {
        download(blob, 'FileRequest.zip')
      })
  }
)

const downloadFile = createAsyncThunk('clientDetail/downloadFile', params => {
  return fetch(`${config.apiHost}/download-file-drive?fileId=${params.id}`)
    .then(res => {
      return res.blob()
    })
    .then(blob => {
      download(blob, params.fileDriveName)
    })
})

const downloadOrganizationFiles = createAsyncThunk(
  'clientDetail/downloadOrganizationFiles',
  params => {
    console.log('🚀 ~ file: index.js:393 ~ params:', params)
    return fetch(`${config.apiHost}/download-organization-files`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
      .then(res => {
        return res.blob()
      })
      .then(blob => {
        download(blob, `${params.filename ?? 'FileRequest.zip'}`)
      })
  }
)

const changeToLoadingFolders = state =>
  Object.assign(state, {
    loadingFolders: true,
  })

const handleAssociateUser = createAsyncThunk(
  'clientDetail/associateUser',
  params => {
    return application.call(API.CLIENT_DETAIL.ASSOC_USER, params)
  }
)
const pushNewMember = createAction('clientDetail/pushNewMember')
function onAssociateUser(state, action) {
  const user = action.payload
  const copyMembers = [...state.detail.members]
  copyMembers.push(user)
  Object.assign(state, {
    detail: { ...state.detail, members: copyMembers },
  })
}

const addRemoveLicenses = createAsyncThunk(
  'clientDetail/addRemoveLicenses',
  params => {
    return application.call(API.CLIENT_DETAIL.UPDATE_NUMBER_LICENSES, params)
  }
)
function onAddRemoveLicenses(state, action) {
  if (action.payload.success) {
    return Object.assign(state, {
      detail: {
        ...state.detail,
        licenses: action.payload.licenses,
        availableSeats: action.payload.availableSeats,
      },
    })
  }
}

const updateClaimedVendor = createAsyncThunk(
  'clientDetail/updateClaimedVendor',
  params => {
    return application.call(API.CLIENT_DETAIL.UPDATE_CLAIMED_VENDOR, params)
  }
)
function onUpdateClaimedVendor(state, action) {
  if (action.payload.success) {
    return Object.assign(state, {
      detail: { ...state.detail, featuredVendor: action.payload.vendor },
    })
  }
}

const removeClaimedVendor = createAsyncThunk(
  'clientDetail/removeClaimedVendor',
  params => {
    return application.call(API.CLIENT_DETAIL.REMOVE_CLAIMED_VENDOR, params)
  }
)
function onRemoveClaimedVendor(state, action) {
  if (action.payload.success) {
    return Object.assign(state, {
      detail: { ...state.detail, featuredVendor: {} },
    })
  }
}

const refreshClaimedVendor = createAsyncThunk(
  'clientDetail/refreshClaimedVendor',
  params => {
    return application.call(API.CLIENT_DETAIL.REFRESH_CLAIMED_VENDOR, params)
  }
)

const updateExportsConfiguration = createAsyncThunk(
  'clientDetail/updateExportsConfiguration',
  params => {
    return application.call(
      API.CLIENT_DETAIL.UPDATE_EXPORTS_CONFIGURATION,
      params
    )
  }
)
function onUpdateExportsConfiguration(state, action) {
  if (action.payload.success) {
    return Object.assign(state, {
      detail: { ...state.detail, exportsLimit: action.payload.exportsLimit },
    })
  }
}

const getCategories = createAsyncThunk('clientDetail/getCategories', params => {
  return application.call(API.CLIENT_DETAIL.GET_CATEGORIES, params)
})
function onGetCategories(state, action) {
  return Object.assign(state, {
    categories: action.payload.categories,
  })
}

const getCategoryFiles = createAsyncThunk(
  'clientDetail/getCategoryFiles',
  params => {
    return application.call(API.CLIENT_DETAIL.GET_CATEGORIES_FILES, params)
  }
)
function onGetCategoryFiles(state, action) {
  return Object.assign(state, {
    files: action.payload.files,
  })
}

const createSubsidiaryOrganization = createAsyncThunk(
  'clientDetail/createSubsidiaryOrganization',
  params => {
    return application.call(
      API.CLIENT_DETAIL.CREATE_SUBSIDIARY_ORGANIZATION,
      params
    )
  }
)

function onCreateSubsidiaryOrganization(state, action) {
  if (action.payload.success) {
    return Object.assign(state, {
      organizationHierarchy: [
        action.payload.organization,
        ...state.organizationHierarchy,
      ],
    })
  }
}

const updateParentOrganization = createAsyncThunk(
  'clientDetail/updateParentOrganization',
  params => {
    return application.call(
      API.CLIENT_DETAIL.UPDATE_PARENT_ORGANIZATION,
      params
    )
  }
)

function onUpdateParentOrganization(state, action) {
  if (action.payload.success) {
    return Object.assign(state, {
      organizationHierarchy: state.organizationHierarchy.map(org => {
        if (org.id === action.payload.organization) {
          return {
            ...org,
            parentOrganizationId: null,
          }
        } else {
          return {
            ...org,
            parentOrganizationId: action.payload.organization,
          }
        }
      }),
      detail: {
        ...state.detail,
        parentOrganizationId:
          action.payload.organization === state.detail.id
            ? null
            : action.payload.organization,
      },
    })
  }
}

const deleteOrganization = createAsyncThunk(
  'clientDetail/deleteOrganization',
  params => application.call(API.CLIENT_DETAIL.DELETE_ORGANIZATION, params)
)

const mergeOrganizations = createAsyncThunk(
  'clientDetail/mergeOrganizations',
  params => application.call(API.CLIENT_DETAIL.MERGE_ORGANIZATION, params)
)

const getFormStackJSONSignedUrl = createAsyncThunk(
  'clientDetail/getFormStackJSONSignedUrl',
  params =>
    application.call(API.CLIENT_DETAIL.GET_JSON_FORM_URL, params).then(res => {
      return res
    })
)

//Actions
export const actions = {
  pushNewMember,
  handleAssociateUser,
  getDetail,
  getForms,
  getUsers,
  saveUserUsfcr,
  deleteUserUsfcr,
  removeUserFromOrg,
  saveFormOrg,
  deleteFormOrg,
  deleteFileOrg,
  approveFileOrg,
  denyFileOrg,
  downloadFileOrg,
  getFoldersByFolderId,
  changeBreadcrumFolders,
  changeSelectedFolder,
  saveFileRequest,
  updateFileRequest,
  activeDeactiveOrganization,
  getHistoryEvents,
  saveBillingSettings,
  savePermissions,
  createFolder,
  addRemoveLicenses,
  saveCustomization,
  updateClaimedVendor,
  refreshClaimedVendor,
  removeClaimedVendor,
  changeUserAccountStatus,
  editUserRole,
  updateExportsConfiguration,
  getCategories,
  createSubsidiaryOrganization,
  updateParentOrganization,
  deleteOrganization,
  mergeOrganizations,
  getFormStackJSONSignedUrl,
  downloadOrganizationFiles,
  getCategoryFiles,
  downloadFile,
}

export default createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(getDetail.fulfilled, onGetDetail)
    .addCase(getUsers.fulfilled, onGetUsers)
    .addCase(getForms.fulfilled, onGetForms)
    .addCase(saveFormOrg.fulfilled, onSaveFormOrg)
    .addCase(saveUserUsfcr.fulfilled, onSaveUserUsfcr)
    .addCase(deleteUserUsfcr.fulfilled, onDeleteUserUsfcr)
    .addCase(removeUserFromOrg.fulfilled, onRemoveUserFromOrg)
    .addCase(deleteFormOrg.fulfilled, onDeleteFormOrg)
    .addCase(deleteFileOrg.fulfilled, onDeleteFileOrg)
    .addCase(approveFileOrg.fulfilled, onApproveFileOrg)
    .addCase(denyFileOrg.fulfilled, onDenyFileOrg)
    .addCase(getFoldersByFolderId.pending, changeToLoadingFolders)
    .addCase(getFoldersByFolderId.fulfilled, onGetFoldersByFolderId)
    .addCase(changeBreadcrumFolders, onChangeBreadcrumFolders)
    .addCase(changeSelectedFolder, onChangeSelectedFolder)
    .addCase(saveFileRequest.fulfilled, onSaveFileRequest)
    .addCase(updateFileRequest.fulfilled, onUpdateFileRequest)
    .addCase(activeDeactiveOrganization.fulfilled, onActiveDeactiveOrganization)
    .addCase(getHistoryEvents.fulfilled, onGetHistoryEvents)
    .addCase(saveBillingSettings.fulfilled, onSaveBillingSettings)
    .addCase(savePermissions.fulfilled, onSavePermissions)
    .addCase(createFolder.fulfilled, onCreateFolder)
    .addCase(pushNewMember, onAssociateUser)
    .addCase(addRemoveLicenses.fulfilled, onAddRemoveLicenses)
    .addCase(updateClaimedVendor.fulfilled, onUpdateClaimedVendor)
    .addCase(refreshClaimedVendor.fulfilled, onUpdateClaimedVendor)
    .addCase(removeClaimedVendor.fulfilled, onRemoveClaimedVendor)
    .addCase(changeUserAccountStatus.fulfilled, onEditUser)
    .addCase(editUserRole.fulfilled, onEditUser)
    .addCase(updateExportsConfiguration.fulfilled, onUpdateExportsConfiguration)
    .addCase(getCategories.fulfilled, onGetCategories)
    .addCase(getCategoryFiles.fulfilled, onGetCategoryFiles)
    .addCase(
      createSubsidiaryOrganization.fulfilled,
      onCreateSubsidiaryOrganization
    )
    .addCase(updateParentOrganization.fulfilled, onUpdateParentOrganization)
})
