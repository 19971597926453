import React from 'react'
import { SearchContextProvider } from './context'
import SearchPanelQuickView from './SearchPanelQuickView'
import SearchTopPanel from './SearchTopPanel'

const SearchTopPanelWrapper = ({
  scope,
  filters,
  filterOptions,
  sharedSearch,
  onChangeFilters,
  onSaveSearch,
  onCreateShareSearch,
  onExportSearch,
  onLoadSearch,
  quickView = false,
  action = null,
}) => {
  const state = { filters, sharedSearch, filterOptions, scope }
  const handlers = {
    onChangeFilters,
    onSaveSearch,
    onCreateShareSearch,
    onExportSearch,
    onLoadSearch,
  }

  return (
    <SearchContextProvider state={state} handlers={handlers}>
      {quickView ? (
        <SearchPanelQuickView action={action} />
      ) : (
        <SearchTopPanel />
      )}
    </SearchContextProvider>
  )
}

export default SearchTopPanelWrapper
