import React from 'react'
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'
import clsx from 'clsx'
import { DropdownSelect } from '../../../components/common/Inputs'
import { useStyles } from './styles'
import { useTrans } from '../../../services/i18n'
import connect from '../connect'
import { ANY_OF_THOSE } from '../../../constants/filter'

const items = [
  { value: 'all', i18nKey: 'all' },
  { value: 'fco', i18nKey: 'solicitation' },
  { value: 'fgo', i18nKey: 'grant' },
]

function TypeDropdown({ tab, filters, onChangeFilters }) {
  const { trans } = useTrans()
  const classes = useStyles()
  const selectedStatus = React.useMemo(
    () =>
      items.find(
        item => item.value === filters?.fields?.opportunityType?.value[0]
      ) || items[0],
    [filters]
  )

  const handleChange = React.useCallback(
    newSelected => {
      if (newSelected.value === 'all') {
        onChangeFilters({ opportunityType: undefined })
        return
      }

      onChangeFilters({
        opportunityType: {
          type: ANY_OF_THOSE,
          value: [newSelected.value],
        },
      })
    },
    [onChangeFilters]
  )
  return (
    <DropdownSelect
      options={items}
      onChange={handleChange}
      value={selectedStatus}
      label={trans('type')}
      className={clsx(classes.dropDownButton, 'mr-2')}
      Icon={<ArrowDownIcon />}
    />
  )
}

export default connect(TypeDropdown)
