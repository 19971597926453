import { Box } from '@material-ui/core';
import React from 'react';
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase';
import { SCOPE_SAVED_SEARCHES } from '../../../constants/search';
import connect from '../connect';
import SavedSearchesList from './List';

function SavedSearches({ userDetail, onInitSavedSearches }) {
  React.useEffect(() => {
    onInitSavedSearches ? onInitSavedSearches(userDetail.userName) : console.log('No saved searches');;
  }, [onInitSavedSearches]);

  return (
    <SpacingContainerBase>
      <SavedSearchesList scope={SCOPE_SAVED_SEARCHES} />
      <Box my={4} />
    </SpacingContainerBase>
  );
}

export default connect(SavedSearches);
