import React from 'react'
import { SvgIcon } from '../../common/SvgIcon'
import { simpleIconPropsTypes } from '../propTypes'

export const IconDelete = props => {
  const { width = 24, height = 24 } = props

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        fill="#4E5978"
        fillRule="evenodd"
        d="M13 2a3 3 0 0 1 2.995 2.824L16 5v1h4a1 1 0 0 1 .117 1.993L20 8h-1v11a3 3 0 0 1-2.824 2.995L16 22H8a3 3 0 0 1-2.995-2.824L5 19V8H4a1 1 0 0 1-.117-1.993L4 6h4V5a3 3 0 0 1 2.824-2.995L11 2h2ZM7 8v11a1 1 0 0 0 .883.993L8 20h8a1 1 0 0 0 .993-.883L17 19V8H7Zm7-2h-4V5l.007-.117A1 1 0 0 1 11 4h2l.117.007A1 1 0 0 1 14 5v1Zm-4 5a1 1 0 0 1 .993.883L11 12v4a1 1 0 0 1-1.993.117L9 16v-4a1 1 0 0 1 1-1Zm4.993.883A1 1 0 0 0 13 12v4l.007.117A1 1 0 0 0 15 16v-4l-.007-.117Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  )
}

IconDelete.propTypes = simpleIconPropsTypes
