import { connect } from 'react-redux';
import { update } from '../../../store/auth';

function mapStateToProps(state) {
  return {
    authUser: state.auth || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateOnViewHelpUseNotifications: (params) => dispatch(update(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
