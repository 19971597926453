import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconFile = (props) => {
  const { width, height, filled = false } = props;

  return filled ? (
    <SvgIcon width={width || 24} height={height || 24} viewBox="0 0 24 24">
      <path d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z" />
    </SvgIcon>
  ) : (
    <SvgIcon width={width || 51} height={height || 62} viewBox="0 0 51 62">
      <path
        d="M186 0c.043 0 .085.003.126.008l.03.004c.032.005.063.012.094.02l.03.008c.03.009.061.02.091.031l.027.012c.028.012.056.025.082.04l.032.018c.026.015.052.032.076.05.042.03.082.065.12.102l-.094-.082.011.009.082.073 21 21c.03.03.057.06.082.093l.016.021c.02.026.038.053.054.081l.018.032c.015.026.028.054.04.082l.012.027c.012.03.022.06.031.091l.008.03c.008.03.015.062.02.093.008.052.012.104.012.157l-.008-.126.001.01L208 22v33c0 3.866-3.134 7-7 7h-36c-3.866 0-7-3.134-7-7V7c0-3.866 3.134-7 7-7h21zm-1 2h-20c-2.761 0-5 2.239-5 5v48c0 2.761 2.239 5 5 5h36c2.761 0 5-2.239 5-5V23h-20c-.513 0-.936-.386-.993-.883L185 22V2zm19.585 19L187 3.415V21h17.585z"
        transform="translate(-736.000000, -609.000000) translate(251.000000, 182.000000) translate(0.000000, 347.000000) translate(327.500000, 80.000000)"
      />
    </SvgIcon>
  );
};

IconFile.propTypes = simpleIconPropsTypes;
