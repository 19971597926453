import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTrans } from '../../../services/i18n';
import Button from '../../common/Buttons/Button';
import { Paper, SpacingContainer } from '../../common/Containers';
import { ModalDialog } from '../../common/Dialogs/ModalDialog';
import Typography from '../../common/Typography';
import { IconRemove } from '../../svg/Icons/IconRemove';

export default function ConfirmDialog({
  open,
  onConfirm,
  onClose,
  icon,
  hideIcon = false,
  children,
  pending = false,
  title = '',
  text = '',
  textStyles = {},
  confirmType = false,
  cancelTitle = '',
  confirmTitle = '',
  note = '',
  noteStyles = {}
}) {
  const { trans } = useTrans();

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={title || trans('confirm')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" onClick={onClose}>
              {cancelTitle || trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color={confirmType? 'primary' : 'secondary'} disabled={!onConfirm} pending={pending} onClick={onConfirm} >
              {confirmTitle || trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      {!hideIcon && 
        <SpacingContainer className="center" py={3}>
          {icon ? icon : <IconRemove thin />}
        </SpacingContainer>
      }
      <Typography align="center" weight="600" gutter="bottomSpace" style={textStyles}>
        {text || trans('ConfirmAction')}
      </Typography>

      { note &&
        <Typography align="center" weight="bold" gutter="bottomSpace" style={noteStyles}>
          { note }
        </Typography>
      }

      { children && <Paper bgColor="paperSecondary" component={SpacingContainer} py={2} px={2} className="center">
        {children}
        </Paper> }
    </ModalDialog>
  );
}
