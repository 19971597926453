import { connect } from 'react-redux'
import { actions } from '../../../../store/requestTemplates'
import { actions as orgDetailActions } from '../../../../store/clientDetail'

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    getTemplates: (params = {}) => dispatch(actions.getAllTemplates(params)),
    getResourcesByTemplate: (params = {}) => dispatch(actions.getResourcesByTemplate(params)),
    saveRequest: (params) => dispatch(actions.saveRequestInOrganization(params)),
    getDetail: params => dispatch(orgDetailActions.getDetail(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
