import * as Yup from 'yup'
import makeValidationSchema from '../../../hooks/makeValidationSchema'

export const initialValues = {
  user: null,
}

export const getInitialValues = item => {
  return Object.assign({}, initialValues, item)
}

export const useScheme = makeValidationSchema(trans =>
  Yup.object().shape({
    user: Yup.object().required().nullable(),
  })
)
