import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Button } from '../../../components/common/Buttons'
import { ModalDialog } from '../../../components/common/Dialogs'
import { useTrans } from '../../../services/i18n'
import { useAlertContext } from '../../../contexts/AlertContext'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import Typography from '../../../components/common/Typography'
import { application } from '../../../services/application'

export default function ResetPasswordModal({ item, open, onClose }) {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)

  const handleSubmit = async () => {
    setIsSend(true)
    application
      .call('user.forgotPassword', { email: item.email })
      .then(({ success, reason }) => {
        if (!success) {
          if (reason === 'not-verified') {
            setIsSend(true)
            application
              .call('user.resendConfirmationCode', {
                email: item.email,
              })
              .then(() => {
                onClose()
                successAlert(
                  'Confirmation link was re-sent successfully',
                  'Please check your email'
                )
              })
              .finally(() => {
                setIsSend(false)
              })
          }
        } else {
          successAlert('Password Sent')
          onClose()
        }
      })
      .catch(err => {
        errorAlert(err.message)
      })
      .finally(() => {
        setIsSend(false)
      })
  }

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans('recover-password')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSend}
              onClick={onClose}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={handleSubmit}
            >
              {trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer px={3} py={3}>
        <Grid container spacing={3}>
          <Grid style={{ textAlign: 'center' }} item sm={12} xs={12}>
            <Typography
              style={{ marginBottom: '5px' }}
              variant="h2"
              weight="500"
            >
              {trans('send-password-email')}
            </Typography>
            <Typography variant="h6" weight="500">
              {trans('message-send-password-email')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Paper
              bgColor="paperSecondary"
              component={SpacingContainer}
              py={2}
              px={2}
              className="center"
            >
              <Typography color="placeholder" variant="h6">
                {item?.name} {item?.familyName}
              </Typography>
              <Typography variant="h6" weight="bold">
                {item?.email}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </SpacingContainer>
    </ModalDialog>
  )
}
