import React, { useState } from 'react'
import connect from '../connect'
import { IconButton } from '@material-ui/core'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers'
import { useTrans } from '../../../../../services/i18n'
import AddNewForm from './AddNewForm'
import ConfirmDialog from '../../../../../components/generics/Confirm/Dialog'
import Typography from '../../../../../components/common/Typography'
import { useAlertContext } from '../../../../../contexts/AlertContext'
import { selectUserPermissions, isEnabled } from '../../../../../selectors'
import { useSelector } from 'react-redux'

const ItemMenu = ({
  cat,
  deleteRequestTemplateForm,
  requestTemplateId,
  getList,
}) => {
  const { trans } = useTrans()
  const [open, openModal, onCloseModal] = useBooleanHandlers()
  const permissions = useSelector(selectUserPermissions)
  const [openConfirmDelete, onOpenConfirmDelete, onCloseConfirmDelete] =
    useBooleanHandlers()
  const { successAlert, errorAlert } = useAlertContext()
  const [pendingDelete, setPendingDelete] = useState(false)

  const onDelete = async () => {
    setPendingDelete(true)
    const { payload } = await deleteRequestTemplateForm({
      id: cat.id,
      requestTemplateId,
    })
    successAlert(payload.message)
    setPendingDelete(false)
    onCloseConfirmDelete()
    getList({
      requestTemplateId: requestTemplateId,
      text: '',
      order: ['createdAt', 'desc'],
      filters: {},
      pagination: { page: 1, perPage: 10 },
    })
  }

  return (
    <>
      {isEnabled(permissions, 'request-templates', '', 'edit') && (
        <>
          <IconButton onClick={openModal}>
            <EditIcon />
          </IconButton>

          <IconButton onClick={onOpenConfirmDelete}>
            <DeleteIcon />
          </IconButton>
        </>
      )}

      <AddNewForm
        requestTemplateId={requestTemplateId}
        item={{
          id: cat.id,
          name: cat.name,
          requestId: cat.requestId,
        }}
        open={open}
        onClose={onCloseModal}
      />
      <ConfirmDialog
        open={openConfirmDelete}
        onClose={onCloseConfirmDelete}
        onConfirm={onDelete}
        pending={pendingDelete}
        title={trans('remove-form-template')}
        text={`${trans('remove-form-template')} ?`}
        note={'It will no affect requests made from this template'}
      >
        <Typography color="hint">{trans('form-name')}</Typography>
        <Typography color="hint" weight="bold">
          {cat.name}
        </Typography>
      </ConfirmDialog>
    </>
  )
}

export default connect(ItemMenu)
