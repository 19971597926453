import { batch, connect } from 'react-redux'
import { generateListCallbacks } from '../../store/helpers/listActionsHelpers'
import { actions } from '../../store/registeredUserDetail'

function mapStateToProps(state) {
  return {
    userDetail: state.registeredUserDetail.userDetail,
    savedSearches: state.registeredUserDetail,
    authUser: state.auth || {},
    // Variables to manage select all and unselect all functionalities
    savedSearchesTotal: state.registeredUserDetail.count,
    // If select all was checked
    isAllCheckedSavedSearches:
      state.registeredUserDetail.isAllCheckedSavedSearches,
    // If select all was checked and some item was unselected
    isSomeCheckedSavedSearches:
      state.registeredUserDetail.isSomeCheckedSavedSearches,
    // Store the selected saved searches
    savedSearchesSelected: state.registeredUserDetail.savedSearchesSelected,
    // Store the un slected saved searches
    savedSearchesUnselected: state.registeredUserDetail.savedSearchesUnselected,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    findOne: params => dispatch(actions.findOne(params)),
    addAllSavedSearches: () => dispatch(actions.addAllSavedSearchesAction()),
    removeAllSavedSearches: () =>
      dispatch(actions.removeAllSavedSearchesAction()),
    addRemainingSavedSearches: () =>
      dispatch(actions.addRemainingSavedSearchesAction()),
    addOneSavedSearches: params =>
      dispatch(actions.addOneSavedSearchesAction(params)),
    removeOneSavedSearches: params =>
      dispatch(actions.removeOneSavedSearchesAction(params)),
    saveDataAfterDeleteSearch: params =>
      dispatch(actions.saveDataAfterDeleteSearchAction(params)),
    saveDataAfterDeleteBulkSearch: params =>
      dispatch(actions.saveDataAfterDeleteBulkSearchAction(params)),
    onInitSavedSearches: async filterByUserId => {
      batch(() => {
        dispatch(actions.restartStateAction())
        dispatch(actions.setParams({ filterByUserId }))
        dispatch(actions.setPagination({ page: 1, perPage: 10 }))
      })
      await Promise.all([dispatch(actions.fetchList())])
    },
    savedSearchesActions: {
      ...generateListCallbacks(dispatch, actions),
      onShareSearchWithEmail: params =>
        dispatch(actions.shareWithEmailAction(params)),
      onSave: params =>
        batch(async () => {
          await dispatch(actions.saveSearchAction(params))
          await dispatch(actions.fetchList())
        }),
      onDelete: params =>
        batch(async () => {
          await dispatch(actions.deleteSearchAction(params))
          await dispatch(actions.fetchList())
        }),
      onWatch: params =>
        batch(async () => {
          await dispatch(actions.toggleWatchAction(params))
          await dispatch(actions.fetchList())
        }),
      onFindOne: params =>
        batch(async () => {
          await dispatch(actions.findOneSearchAction(params))
        }),
      onTurnOnOffNotifications: params =>
        batch(async () => {
          await dispatch(actions.turnOnOffNotificationsAction(params))
          await dispatch(actions.findOne({ id: params.idSavedSearchOwner }))
        }),
      onDeleteBulk: params =>
        batch(async () => {
          await dispatch(actions.deleteBulkSearchAction(params))
          await dispatch(actions.fetchList())
        }),
      onGetSavedSearchStats: async params => {
        let resp = null
        try {
          const { payload } = await dispatch(
            actions.getSavedSearchStats(params)
          )
          resp = payload
        } catch (error) {
          resp = error
        }
        return resp
      },
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
