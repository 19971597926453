import React from 'react'
import { Grid} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  header: {
    padding : '0 0.5rem 0 0.5rem',
    zIndex: theme.zIndex.appBar + 1,
    position: 'relative',
    borderTop: `1px solid ${theme.palette.gray.divider}`,
    borderLeft: `1px solid ${theme.palette.gray.divider}`,
    height: 60,
    boxShadow: '0 10px 20px 0 rgba(78, 89, 120, 0.1)',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
  },
  btnHeader: {
    borderRadius: 0,
    height: '100%',
    width: 80,
    [theme.breakpoints.down('sm')]: { width: 60 },
  },
  content: { height: '100%' },
}));

export default React.forwardRef(
  ({ children, ...rest }, ref) => {
  const styles = useStyles();
    return (
      <Grid container className={styles.header} alignItems="center" wrap="nowrap" justifyContent="space-between">
				{children}
      </Grid>
    );
  }
);


