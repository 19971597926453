import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import { IconCreditCard, IconLock, IconSearch, IconUser } from '../../../svg/Icons';

export const InputPropsUser = {
  startAdornment: (
    <InputAdornment position="start">
      <IconUser />
    </InputAdornment>
  ),
};

export const InputPropsSearch = {
  startAdornment: (
    <InputAdornment position="start">
      <IconSearch />
    </InputAdornment>
  ),
};

export const InputPropsSearchSmall = {
  startAdornment: (
    <InputAdornment position="start">
      <IconSearch width={18} height={18} />
    </InputAdornment>
  ),
};

export const InputPropsLock = {
  startAdornment: (
    <InputAdornment position="start">
      <IconLock />
    </InputAdornment>
  ),
};
export const InputPropsCard = {
  startAdornment: (
    <InputAdornment position="start">
      <IconCreditCard />
    </InputAdornment>
  ),
};
