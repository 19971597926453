import { LinearProgress, withStyles } from '@material-ui/core';
import React from 'react';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 7,
    borderRadius: 5,
    backgroundColor: '#E5E7EF',
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: (props) => props.color,
  },
}))(LinearProgress);

function LinearProgressBar({ value, color = '#11CA7B' }) {
  return <BorderLinearProgress variant="determinate" value={value} color={color} />;
}

export default LinearProgressBar;
