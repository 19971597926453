export const QUERY_QUICK_VIEW = 'qv';
export const QUERY_FAVORITES = 'my';
export const QUERY_BY_UBICATION = 'z';
export const QUERY_SAVED_SEARCH = 'ssId';
export const QUERY_SHARED_SEARCH = 'shsId';
export const QUERY_PIPELINE = 'pipeline';
export const QUERY_TEXT = 'text';
export const QUERY_TASKS_COMPLETED = 'completed';

export function getListQueries(query) {
  if (!query) return {};

  return {
    savedSearchId: query[QUERY_SAVED_SEARCH],
    sharedSearchId: query[QUERY_SHARED_SEARCH],
    isQuickView: query[QUERY_QUICK_VIEW] === 'y',
    isMy: query[QUERY_FAVORITES] === 'y',
    text: query[QUERY_TEXT],
  };
}