import { Box, Grid, Hidden, makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import Avatar from '../../../../components/common/Cards/Avatar'
import { Title } from '../../TeamingOpportunityItem/Title'
import { AvatarWithTitle } from '../../TeamingOpportunityItem/AvatarWithTitle'
import DetailsRow from '../../TeamingOpportunityItem/DetailsRow'
import CodeSetAsideInfo from '../../TeamingOpportunityItem/CodeSetAsideInfo'
import Keywords from '../../TeamingOpportunityItem/Keywords'
import Typography from '../../../../components/common/Typography'
import { formatDate } from '../../../../utils/dateUtils'
import connect from '../../connect'
import { ITEM_VARIANT, LABEL_SIZE } from '../../constants'
import { Label, ValueText } from '../../components/styled'
import { useTrans } from '../../../../services/i18n'
import { SpacingContainer } from '../../../../components/common/Containers/Base'
import Buttons from './Buttons'
import Information from './Information'
import OpportunitySection from './OpportunitySection'

function QuickViewItem({ item, getAvatarDownloadUrl, onClick }) {
  const { trans } = useTrans()
  const [avatar, setAvatar] = React.useState(true)
  const getAvatar = React.useCallback(
    id => {
      getAvatarDownloadUrl(item.organizationId)
        .then(url => setAvatar(url))
        .catch(console.warn)
    },
    [getAvatarDownloadUrl, item.organizationId]
  )

  React.useEffect(() => {
    getAvatar()
  }, [getAvatar])

  const allNaics = useMemo(
    () => [item.primaryNaics, ...item.naics].filter(naic => !!naic),
    [item]
  )

  return (
    <SpacingContainer py={5} px={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Buttons item={item} />
        </Grid>
        <Grid item xs={12}>
          <Information item={item} avatar={avatar} onClick={onClick} />
        </Grid>

        <Hidden smUp>
          <Grid item xs={12}>
            <AvatarWithTitle
              size={40}
              variant={'body1'}
              title={item.title}
              detailLink={`/teaming-opportunities?qv=y&id=${item.id}`}
              onClick={onClick}
              src={avatar}
            />
          </Grid>
          <Grid item xs={12}>
            <DetailsRow
              publisher={item.publisher}
              location={item.location}
              postedDate={formatDate(item.createdAt)}
              responseByDate={formatDate(item.responseBy)}
              variant={ITEM_VARIANT.QUICK_VIEW}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12}>
          <Typography weight={'700'}>{trans('description')}</Typography>
          <Typography weight={'400'}>{item.description}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Keywords keywords={item.keywords} />
        </Grid>

        <Grid item xs={12}>
          <OpportunitySection item={item} />
        </Grid>

        {item.lookingForSetAsides?.length > 0 && (
          <Grid item xs={12}>
            <Typography weight={'700'} style={{ marginBottom: '.5rem' }}>
              {trans('looking-for-set-asides')}
            </Typography>

            <CodeSetAsideInfo setAsides={item.lookingForSetAsides} hideLabels />
          </Grid>
        )}

        {allNaics?.length > 0 && (
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" gridGap={4}>
              <Label fontSize={LABEL_SIZE}>{trans('other-naics')}</Label>

              {allNaics.map((naic, index) => (
                <ValueText key={index} fontSize={LABEL_SIZE} color={'#2445a7'}>
                  {index === 0 && trans('primary') + ' - '}
                  {naic.value}
                </ValueText>
              ))}
            </Box>
          </Grid>
        )}

        {item?.otherPscs?.length > 0 && (
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" gridGap={4}>
              <Label fontSize={LABEL_SIZE}>{trans('other-pscs')}</Label>

              {item.otherPscs.map((psc, index) => (
                <ValueText key={index} fontSize={LABEL_SIZE} color={'#2445a7'}>
                  {psc.value}
                </ValueText>
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
    </SpacingContainer>
  )
}

export default connect(QuickViewItem)
