import { SpacingContainer } from '../../../components/common/Containers/Base'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import ListInfoRow from '../../../components/generics/ListInfoRow'
import SearchTopPanel from '../../../components/generics/SearchTopPanel'
import SortingPanel from '../../../components/generics/SortingPanel'
import React from 'react'
import connect from '../connect'
import { Button } from '../../../components/common/Buttons'
import { IconPlus } from '../../../components/svg/Icons'
import { useNavigate } from 'react-router-dom'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'
import { useSelector } from 'react-redux'
import { selectUserPermissions } from '../../../selectors'

const Header = ({ scope, users, onChangeOrder, onChangeFilters }) => {
  const { trans } = useTrans()
  const history = useNavigate()
  const permissions = useSelector(selectUserPermissions)
  const isEnabled = () => {
    const routeKey = 'registered-users'
    const module = permissions.find(p => p.key === routeKey)
    if (!module) return true

    const subModule = module.submodules.find(sm => sm.key === module.menuOption)

    if (!subModule) return true
    return subModule.create
  }

  const orderOptions = [
    {
      name: 'Registration date',
      value: 'createdAt',
      asc: 'Oldest first',
      desc: 'Newest first',
    },
  ]
  const { filters, order, count, items } = users

  const newUser = () => {
    history('/register-new-user')
  }

  return (
    <>
      <SpacingContainer
        py={3}
        px={0}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Typography
          weight="600"
          variant="h2"
          fontFamily="lexend"
          title
          style={{ alignItems: 'center', display: 'flex' }}
        >
          {trans('registered-users')}{' '}
          <ShowTooltip sectionToShow="registered-users-table" />
        </Typography>
        <span
          style={{
            cursor: 'pointer',
            ...(!isEnabled() && { display: 'none' }),
          }}
        >
          <span
            style={{ fontWeight: '600', color: '#4E5978' }}
            onClick={() => newUser()}
          >
            Register new user{' '}
          </span>
          <Button
            isIcon
            color="primary"
            variant="contained"
            onClick={() => newUser()}
            style={{ marginLeft: '16px' }}
          >
            <IconPlus />
          </Button>
        </span>
      </SpacingContainer>

      <SearchTopPanel
        scope={scope}
        filters={filters}
        onChangeFilters={onChangeFilters}
        filterOptions={[]}
      />

      {!!items.length && (
        <ListInfoRow
          count={count}
          description={trans('results')}
          sort={
            <SortingPanel
              order={order}
              scope={scope}
              orderOptions={orderOptions}
              onChangeOrder={onChangeOrder}
            />
          }
        />
      )}
    </>
  )
}

export default connect(Header)
