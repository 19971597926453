import React from 'react';
import { Chip } from '../../../components/common/Chip';
import { useTrans } from '../../../services/i18n';

const colorMap = {
  Active: '#a5cb55',
  Invited:'#9576ff',
  Blocked:'#ff5225'

};

const StatusChip = React.memo(({ status, expired = false }) => {
  const { trans } = useTrans();
  return (
    <>
      {expired ? (
        <Chip label={trans('expired')} color={'secondary'} />
      ) : (
        <Chip
          label={trans(status)}
          color={'secondary'}
          style={{
            backgroundColor: colorMap[status] ?? colorMap.unknown,
          }}
        />
      )}
    </>
  );
});

export default StatusChip;