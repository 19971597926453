import Typography from '../../../components/common/Typography'
import { RouterLink } from '../../../components/common/Buttons'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  wrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1 /* number of lines to show */,
    lineClamp: 1,
    '-webkit-box-orient': 'vertical',
  },
}))
export function Title({ variant, weight, color, detailLink, title, onClick, noWrap }) {
  const classes = useStyles()

  return (
    <Typography
      variant={variant}
      weight={weight}
      color={color}
      component={detailLink ? RouterLink : Box}
      to={detailLink && !onClick ? detailLink : undefined}
      onClick={onClick ? onClick : undefined}
      className={!noWrap && classes.wrap}
    >
      <Box width={'100%'} height={'100%'}>
        {title}
      </Box>
    </Typography>
  )
}
