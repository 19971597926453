export const INITIAL_LIST_STATE = {
  items: [],
  count: 0,
  pending: false,
  filters: { text: '', fields: {} },
  order: [],
  pagination: { page: 1, perPage: 5 },
};

export const INITIAL_CHECKED_ITEMS_STATE = {
  checkedItems: [],
};

export const INITIAL_LIST_STATE_EXTENDED = {
  ...INITIAL_LIST_STATE,
  pagination: { page: 1, perPage: 10 },
};

export const INITIAL_FUNDING_STATE = {
  itemsYear: [],
  itemsMonth: [],
  pending: false,
};

export const INITIAL_AWARDS_COUNT_STATE = {
  itemsYear: [
    {
      id: 'FederalContractAwards',
      data: [],
    },
    {
      id: 'FederalGrantAwards',
      data: [],
    },
  ],
  itemsMonth: [
    {
      id: 'FederalContractAwards',
      data: [],
    },
    {
      id: 'FederalGrantAwards',
      data: [],
    },
  ],
  pending: false,
};

export function isLoadMoreAction(action) {
  return action.meta && action.meta.arg && action.meta.arg.isLoadMore;
}
