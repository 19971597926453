import authBack from '../assests/auth-image-new.jpg'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
  },
  media: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    borderRadius: 0,
    backgroundImage: `url(${authBack})`,
    backgroundSize: 'cover',
    padding: '2.5rem',
    width: '100%',
    justifyContent: 'space-between',
    color: '#fff',
  },
  height: {
    height: '100vh',
  },
  authHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logos: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  logoApp: {
    width: 60,
    height: 22,
  },
  imageBlock: {
    maxHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileHeader: {
    padding: '2.5rem 1.875rem',
    boxShadow: '0 10px 20px 0 rgba(78, 89, 120, 0.06)',
    zIndex: 999,
    height: 100,
    display: 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  mobileFooter: {
    padding: '2.5rem 1.875rem',
    color: theme.palette.gray.light,
    borderWidth: 0,
    borderTopWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.gray.border,
    zIndex: 999,
    width: '100%',
    display: 'none',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
    '& p': {
      marginTop: 13,
    },
  },
  content: {
    display: 'none',
    maxHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
}))
