import React from 'react'
import { useTrans } from '../../../services/i18n'
import { Box } from '@material-ui/core'
import { Button } from '../../../components/common/Buttons'
import Typography from '../../../components/common/Typography'

function SaveButton({ onClick }) {
  const { trans } = useTrans()
  return (
    <Box display="flex" justifyContent="center" mt="1rem">
      <Button color="success" variant="contained" onClick={onClick}>
        {trans('save')}
      </Button>
    </Box>
  )
}

export default SaveButton
