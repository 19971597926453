import { useTrans } from '../../../services/i18n'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Item from './Item'
import { selectUserPermissions, isEnabled } from '../../../selectors'
import { useSelector } from 'react-redux'

export default function List({ items }) {
  const { trans } = useTrans()
  const permissions = useSelector(selectUserPermissions)

  return (
    <TableContainer component={Paper} radius={9}>
      <SpacingContainer px="5sm3" py={2}>
        <Table style={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell>{trans('name')}</TableCell>
              <TableCell align="left">{trans('owner')}</TableCell>
              <TableCell align="left">{trans('status')}</TableCell>
              {isEnabled(permissions, 'organization', 'additional-seats') && (
                <TableCell align="left">{trans('seats')}</TableCell>
              )}
              <TableCell align="left">{trans('expires')}</TableCell>
              <TableCell align="left">{trans('registered')}</TableCell>
              <TableCell align="left">{trans('subscription')}</TableCell>
              <TableCell align="left">{trans('is-grandfathered')}</TableCell>
              <TableCell align="left">{trans('ss-allotment')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <Item key={index} item={item} />
            ))}
          </TableBody>
        </Table>
      </SpacingContainer>
    </TableContainer>
  )
}
