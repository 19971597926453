import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
    fileCard: {
        padding: '0 1.5em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignitems: 'center',
        backgroundColor: '#F1F3F9',
        borderRadius: '12px',
        minWidth: '350px',
        maxWidth: '350px',
        minHeight: '180px',
        maxHeight: '180px',
    },
}));