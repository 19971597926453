import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  formBack: {
    padding: '2rem',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      padding: '1.875rem',
      boxShadow: 'none',
      height: 'auto',
    },
  },
  topHeader: {
    color: theme.palette.gray.main,
    width: '100%',
    marginBottom: 16,
  },
  signHeader: {
    color: theme.palette.gray.light,
    width: '100%',
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  signForm: {
    maxWidth: 450,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  flexHelper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    height: '3rem',
  },
  centeredBlock: {
    maxWidth: 420,
  },
  verificationTitle: {
    marginTop: 24,
    lineHeight: 1.3,
    marginBottom: 12,
  },
  email: {
    marginBottom: 38,
  },
  link: {
    marginLeft: 10,
  },
  codeRow: {
    marginBottom: 30,
  },
  helpRow: {
    color: theme.palette.gray.light,
    display: 'flex',
    marginTop: 20,
  },
  codeHelperText: {
    marginTop: 10,
    marginBottom: 30,
  },
  hintIcon: {
    marginRight: 10,
    marginLeft: 10,
  },
  authStateImage: {
    width: 140,
    height: 140,
    marginBottom: -24,
    marginLeft: -12,
  },
  modalText: {
    marginBottom: 15,
  },

  loginButton: { height: 64, borderRadius: 8, fontSize: '1rem' },
  mainActions: {
    marginTop: 15,
  },
}))
