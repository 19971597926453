import { useTrans } from '../../../../services/i18n'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import Typography from '../../../../components/common/Typography'
import AddOutlined from '@material-ui/icons/AddOutlined'
import { IconGovermentFiles } from '../../../../components/svg/Icons/Menu'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import AddRequestFilesModal from './addRequestFilesModal'
import Item from './item'
import connect from '../connect'
import { emptyArray } from '../../../../utils/arrayUtils'
import { Button } from '../../../../components/common/Buttons'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { Divider } from '../../../../components/common/Divider'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const RequestFiles = ({
  onChangeTab,
  detail,
  deleteFileOrg,
  approveFileOrg,
  denyFileOrg,
  downloadFileOrg,
}) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const permissions = useSelector(selectUserPermissions)
  const [file, setFile] = useState(null)

  const deleteFile = file => {
    return deleteFileOrg({ id: file.id })
      .then(() => {
        successAlert(
          `${file.fileName} upload request has been removed from this organization`
        )
      })
      .catch(() => {
        errorAlert('Error')
      })
  }

  const editFile = item => {
    setFile({ ...item, folderId: '' })
    onOpenModal(true)
  }

  const createFile = () => {
    setFile(null)
    onOpenModal(true)
  }

  const approveFile = file => {
    return approveFileOrg({ id: file.id })
      .then(() => {
        successAlert(`${file.fileName} has been approved`)
      })
      .catch(() => {
        errorAlert('Error')
      })
  }

  const denyFile = (file, reason) => {
    return denyFileOrg({ id: file.id, reason: reason })
      .then(() => {
        successAlert(`${file.fileName} has been denied`)
      })
      .catch(() => {
        errorAlert('Error')
      })
  }

  /***
   * Download all files inside a File Request
   */
  const downloadFile = file => {
    return downloadFileOrg({ id: file.id })
      .then(() => {
        successAlert(`${file.fileName} has been downloaded`)
      })
      .catch(() => {
        errorAlert('Error')
      })
  }

  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  return (
    <Paper component={SpacingContainer} px={5} py={2}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs zeroMinWidth>
          <Typography
            variant="h4"
            weight="500"
            fontFamily="lexend"
            style={{ alignItems: 'center', display: 'flex' }}
          >
            <IconGovermentFiles />{' '}
            <span style={{ marginLeft: '10px' }}>{trans('files')}</span>{' '}
            <ShowTooltip sectionToShow="files" />
          </Typography>
        </Grid>
        {isEnabled(permissions, 'organization', 'files') && (
          <Grid item>
            <Button
              isIcon
              color="primary"
              variant="outlined"
              onClick={createFile}
              disabled={
                !isEnabled(permissions, 'organization', 'files', 'create')
              }
            >
              <AddOutlined />
            </Button>
          </Grid>
        )}
        <AddRequestFilesModal
          item={file}
          open={openModal}
          onClose={onCloseModal}
        />
      </Grid>

      <SpacingContainer px="0" py={2}>
        <Divider light />
        <TableContainer component={Paper} radius={9}>
          <Table style={{ minWidth: 1000 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">{trans('file-name')}</TableCell>
                <TableCell align="left">{trans('requested-at')}</TableCell>
                <TableCell align="left">{trans('due-date')}</TableCell>
                <TableCell align="left">{trans('category')}</TableCell>
                <TableCell align="left">{trans('status')}</TableCell>
                <TableCell align="left">{trans('approval')}</TableCell>
                {isEnabled(permissions, 'organization', 'files', 'edit') && (
                  <TableCell align="right">{trans('actions')}</TableCell>
                )}
              </TableRow>
            </TableHead>
            {detail && !emptyArray(detail.fileRequests) && (
              <TableBody>
                {detail.fileRequests.map(fr => (
                  <Item
                    file={fr}
                    key={fr.id}
                    deleteFile={deleteFile}
                    editFile={editFile}
                    approveFile={approveFile}
                    denyFile={denyFile}
                    downloadFile={downloadFile}
                    onChangeTab={onChangeTab}
                  />
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {emptyArray(detail.fileRequests) && (
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            <NothingFoundCard
              title={trans('no-request-files-added-yet')}
              size="medium"
            />
            <Button
              color="primary"
              variant="contained"
              onClick={createFile}
              disabled={
                !isEnabled(permissions, 'organization', 'files', 'create')
              }
            >
              {trans('add-request-file')}
            </Button>
          </div>
        )}
      </SpacingContainer>
    </Paper>
  )
}
export default connect(RequestFiles)
