import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import { Avatar } from '../../../../components/common/Cards'
import { InlineContainer } from '../../../../components/common/Containers'
import Typography from '../../../../components/common/Typography'
import { DATE_TIME_PRETTY_FORMAT } from '../../../../constants'
import {
  formatPrettyUnixDate,
  intlDateWithTimeFormat,
} from '../../../../utils/dateUtils'
import { formatToTitleCase } from '../../../../utils/helpers'
import UserActions from './components/UserActions'
import { ROLE_OWNER } from '../../../../constants/user'

const Item = ({ user, authUser }) => {
  return (
    <>
      <TableRow>
        <TableCell align="left">
          <InlineContainer spacing={1}>
            <Avatar
              bgColor="gray-second"
              variantAvatar="rounded"
              size={40}
              value={user?.name + ' ' + user?.familyName}
            />
            <Typography weight="500" variant="h6" color="primary">
              {user?.name + ' ' + user?.familyName}
            </Typography>
          </InlineContainer>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {user?.email}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {intlDateWithTimeFormat(user.createdAt)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {user?.lastLogin
              ? formatPrettyUnixDate(user?.lastLogin, DATE_TIME_PRETTY_FORMAT)
              : ''}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {formatToTitleCase(user.role)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body2"
            weight="600"
            noWrap
            color={user.disabled ? 'secondary' : 'success'}
          >
            {user.disabled ? 'Disabled' : 'Enabled'}
          </Typography>
        </TableCell>

        <TableCell align="right">
          {(authUser.role !== 'admin' && user.role !== ROLE_OWNER) ||
          authUser.role === 'admin' ? (
            <UserActions user={user} />
          ) : null}
        </TableCell>
      </TableRow>
    </>
  )
}

export default Item
