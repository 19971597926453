import IconButton from '@material-ui/core/IconButton'
import IconMore from '@material-ui/icons/MoreHoriz'
import React from 'react'
import { ListItemTextBase } from '../../../components/common/Containers'
import {
  ContextMenuListItem,
  Popover,
} from '../../../components/common/Dialogs/Popover'
import useBooleanHandlers from '../../../hooks/useBooleanHandlers'
import usePopover from '../../../hooks/usePopover'
import { useTrans } from '../../../services/i18n'
import BlockModal from '../components/QuickViewItem/Modals/BlockModal'
import DeleteModal from '../components/QuickViewItem/Modals/DeleteModal'
import EditModal from '../components/QuickViewItem/Modals/EditModal'
import { makeStyles } from '@material-ui/core'
import { useItemStatus } from '../hooks/useItemStatus'

const useStyles = makeStyles(theme => ({
  menuItem: {
    '& p': {
      color: 'black',
    },
  },
}))

export default function ItemMenu({ item }) {
  const { trans } = useTrans()
  const classes = useStyles()
  const { canRestore, canUnblock } = useItemStatus(item)
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()
  const [openBlock, onOpenBlock, onCloseBlock] = useBooleanHandlers(false)
  const [openDelete, onOpenDelete, onCloseDelete] = useBooleanHandlers(false)
  const [openEdit, onOpenEdit, onCloseEdit] = useBooleanHandlers(false)

  return (
    <>
      <IconButton onClick={onOpenPopover} size="small">
        <IconMore />
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClosePopover}
      >
        <ContextMenuListItem
          button
          onClick={onOpenEdit}
          onClickCB={onClosePopover}
          size="normal"
          className={classes.menuItem}
        >
          <ListItemTextBase>{trans('edit')}</ListItemTextBase>
        </ContextMenuListItem>

        {!canUnblock && (
          <ContextMenuListItem
            button
            onClick={onOpenDelete}
            onClickCB={onClosePopover}
            size="normal"
            className={classes.menuItem}
          >
            <ListItemTextBase>
              {trans(canRestore ? 'restore' : 'delete')}
            </ListItemTextBase>
          </ContextMenuListItem>
        )}

        {!canRestore && (
          <ContextMenuListItem
            button
            onClick={onOpenBlock}
            onClickCB={onClosePopover}
            size="normal"
            className={classes.menuItem}
          >
            <ListItemTextBase>
              {trans(canUnblock ? 'unblock' : 'block')}
            </ListItemTextBase>
          </ContextMenuListItem>
        )}
      </Popover>

      <BlockModal
        item={item}
        open={openBlock}
        onClose={onCloseBlock}
        canUnblock={canUnblock}
      />
      <DeleteModal
        item={item}
        open={openDelete}
        onClose={onCloseDelete}
        canRestore={canRestore}
      />
      <EditModal item={item} open={openEdit} onClose={onCloseEdit} />
    </>
  )
}
