import { useEffect, useCallback } from 'react'
import connect from './connect'
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase'
import Loader from '../../components/common/Loader'
import { Grid } from '@material-ui/core'
import NothingFoundCard from '../../components/generics/Cards/NothingFoundCard/NothingFoundCardList'
import PaginationSmall from '../../components/common/Paginations/PaginationSmall'
import List from './components/List'
import Header from './components/Header'
import { emptyArray } from '../../utils/arrayUtils'
import { useLocation } from 'react-router';
import qs from 'query-string';
import { EXISTS, IS_BETWEEN, IS_BEFORE } from '../../constants/filter';
import { TODAY, EXPIRES_SOON_MAX_DATE } from './filter'
import dayjs from 'dayjs';

const Clients = ({ clients, onInit, onChangeFilters, onResetFilters, onChangePage, onChangeRowPerPage }) => {
  const { items, count, pending, pagination } = clients
  const { search } = useLocation();

  const handleFilter = useCallback((key, value, type = EXISTS) => {
    onChangeFilters({
      [key]: {
        type: type,
        value
      },
    });
  }, [onChangeFilters])

  useEffect(() => {
    if (!search) onInit()
  }, [])

  useEffect(() => {
    if (search) {
      (async () => {
        await onResetFilters();
        let q = qs.parse(search);
        if (q && q.filter) {
          const filters = {
            active: 1,
            deactivated: '0',
            inactive: dayjs().subtract(1, 'month').unix(),
            expiresSoon: [TODAY,EXPIRES_SOON_MAX_DATE],
          }

          const types = {
            expiresSoon: IS_BETWEEN,
            inactive: IS_BEFORE
          }

          const fields = {
            inactive: 'userLastLoginTime',
          }
          handleFilter(fields[q.filter] ?? q.filter, filters[q.filter], types[q.filter] || EXISTS)
        }
      })();
    };
  }, [search, handleFilter, onResetFilters]);

  return (
    <SpacingContainerBase>
      <Header />
      {pending && <Loader open={pending} />}
      {!pending && !emptyArray(items) ? (
        <Grid container spacing={2} direction="column">
          <Grid item xs zeroMinWidth>
            <List items={items} />
          </Grid>

          <Grid item xs zeroMinWidth>
            <PaginationSmall
              page={pagination.page}
              perPage={pagination.perPage}
              count={count}
              onChangePage={onChangePage}
              onChangeRowPerPage={onChangeRowPerPage}
              pending={pending}
            />
          </Grid>
        </Grid>
      ) : !pending && emptyArray(items) ? (
        <Grid item xs zeroMinWidth>
          <NothingFoundCard />
        </Grid>
      ) : null}
    </SpacingContainerBase>
  )
}

export default connect(Clients)
