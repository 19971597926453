import * as Yup from 'yup'
import makeValidationSchema from '../../../hooks/makeValidationSchema'

export const initialValues = {
  name: '',
  basedOn: 'defualt'
}

export const getInitialValues = item => {
  return Object.assign({}, initialValues, item)
}

export const useScheme = makeValidationSchema(() =>
  Yup.object().shape({
    name: Yup.string().required().nullable().default(''),
    basedOn: Yup.string().required().nullable().default('')
  })
)
