import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { IconCheckBox } from '../../../svg/Icons/IconCheckBox';

const useStyles = makeStyles({
  small: { padding: 3 },
  dense: { padding: 0 },
});

export default function CheckBox({ className, size, dense, color = 'primary', id = '', name = '', disabled = false, fill = null, ...rest }) {
  const styles = useStyles();

  return (
    <Checkbox
      id={id || `checkbox-${name}`}
      name={name}
      disabled={disabled? true : null}
      disableRipple
      className={clsx(className, size && styles[size], dense && styles.dense)}
      color={color}
      icon={<IconCheckBox fill={fill}/>}
      checkedIcon={<IconCheckBox checked />}
      indeterminateIcon={<IconCheckBox indeterminate />}
      {...rest}
    />
  );
}
