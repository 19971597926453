import { Grid, Input } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { Button } from "../../../../components/common/Buttons";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from "../../../../components/common/Typography";
import { useTrans } from "../../../../services/i18n";
import useStyles from './styles';
import { useDropzone } from "react-dropzone";

const FileUpload = ({ file, setFile, setLinkFile }) => {
    const styles = useStyles();
    const { trans } = useTrans();
    // const onDrop = useCallback((accFiles, rejFiles) => {
    //     // Do something with the files
    //     // const mappedAcc = accFiles.map(file => ({ file, erros: [] }))
    //     // setFiles(curr => [...curr, ...mappedAcc, ...rejFiles])
    // }, [])
    const onDrop = useCallback(acceptedFiles => {
        // setFiles(acceptedFiles.map(file => {console.log('======',file);URL.createObjectURL(file)}))
        setFile(acceptedFiles[0])
        setLinkFile(URL.createObjectURL(acceptedFiles[0]))
    }, [file]);
    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    return (
        <React.Fragment>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.fileCard}
                    spacing={1}
                >
                    <Grid item>
                        <CloudUploadIcon style={{ width: "46px", height: "34px" }} />
                    </Grid>
                    <Grid item>
                        <Typography fontFamily="Manrope" weight="700" variant="body1" >
                            {trans('drag-and-drop-to-upload')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography fontFamily="Manrope" weight="400" variant="body2"  >
                            or browse
                        </Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: '0px' }}>
                        <Typography fontFamily="Manrope" weight="300" variant="body2"  >
                            {trans('supported-files')}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}
export default FileUpload