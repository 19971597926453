import React from 'react'
import { SvgIcon } from '../../../../../components/common/SvgIcon'

function PersonalSettingIcon() {
  return (
    <SvgIcon width={12} height={12} viewBox="0 0 12 12">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#a)">
          <path
            fill="#4E5978"
            d="M6 4a2 2 0 1 0-.001 3.999A2 2 0 0 0 6 4Zm4.47 1.5A4.497 4.497 0 0 0 6.5 1.53V.5h-1v1.03A4.497 4.497 0 0 0 1.53 5.5H.5v1h1.03a4.497 4.497 0 0 0 3.97 3.97v1.03h1v-1.03a4.497 4.497 0 0 0 3.97-3.97h1.03v-1h-1.03ZM6 9.5A3.497 3.497 0 0 1 2.5 6c0-1.935 1.565-3.5 3.5-3.5S9.5 4.065 9.5 6 7.935 9.5 6 9.5Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h12v12H0z" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default PersonalSettingIcon
