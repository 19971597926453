import { Button } from "../../../components/common/Buttons";
import { ModalDialog } from "../../../components/common/Dialogs";
import {
  InlineContainer,
  SpacingContainer,
} from "../../../components/common/Containers/Base";
import { useTrans } from "../../../services/i18n";
import Typography from "../../../components/common/Typography";
import { Paper } from "../../../components/common/Containers";
import { Grid } from "@material-ui/core";
import { formatPrettyUSD } from "../../../utils/numberUtils";
import StatusChip from "./Status";

export default function ResponseModal({ open, onClose, quote }) {
  const { trans } = useTrans();

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      disableContentSpacing
      title={trans("quote-response")}
      actions={
        <InlineContainer>
          <Button
            onClick={onClose}
            variant="contained"
            color="primary"
          >
            {trans("ok")}
          </Button>
        </InlineContainer>
      }
    >
      <SpacingContainer px={4} py={4}>
        <Typography
          variant="h4"
          weight={500}
          fontFamily="lexend"
          gutter="bottom2"
        >
          USFCR - Response Justification &nbsp;
          {quote.status == 'denied' ? <StatusChip status={quote.status} /> : '' }
        </Typography>
        <Typography
          variant="h6"
          weight="600"
          color="label"
          gutter="bottomSpace"
        >
          {quote?.adminResponse}
        </Typography>
        {quote?.amount && (
          <Paper
            component={SpacingContainer}
            py={2}
            px="4sm3"
            bgColor="paperSecondary"
            radius={4}
          >
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item zeroMinWidth>
                <Typography
                  variant="h5"
                  weight={600}
                  fontFamily="lexend"
                  color="label"
                >
                  Amount
                </Typography>
              </Grid>
              <Grid item zeroMinWidth>
                <Typography variant="h4" weight={600} fontFamily="lexend">
                  {formatPrettyUSD(quote?.amount)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )}
      </SpacingContainer>
    </ModalDialog>
  );
}
