import colors from '../pallete';
import typography from '../typography';

const MuiFilledInput = {
  root: {
    borderRadius: '4px !important',
    backgroundColor: colors.background.input,
    border: `0px solid ${colors.background.input}`,
    color: colors.common.black,
    minWidth: 70,
    '&:hover': { backgroundColor: `${colors.background.input} !important` },
    '&$focused': { backgroundColor: colors.background.input },
    '&$disabled': { backgroundColor: colors.background.paperSecondary, color: colors.text.disabled },
    '&:not($focused) > svg:first-child': { color: colors.text.disabled },

    // Custom Bg Colors
    '.textfield-secondary > &': {
      backgroundColor: colors.gray.divider,
      '&:hover': { backgroundColor: `${colors.gray.divider} !important` },
      '&$focused': { backgroundColor: colors.gray.divider },
    },
    '.textfield-default > &': {
      backgroundColor: colors.common.white,
      '&:hover': { backgroundColor: `${colors.common.white} !important` },
      '&$focused': { backgroundColor: colors.common.white },
    },
    '.textfield-transparent > &': {
      backgroundColor: 'transparent',
      '&:hover': { backgroundColor: `transparent !important` },
      '&$focused': { backgroundColor: 'transparent' },
    },

    '& input::placeholder': { color: colors.gray.active, opacity: 1 },
    '& textarea::placeholder': { color: colors.gray.active, opacity: 1 },

    '.login > &': { borderRadius: 0 },
    '&.datepicker': { minWidth: 155 },
  },

  input: {
    padding: '16px 23px 17px',
    height: '1.4em',
    fontSize: typography.h6.fontSize,
    '&::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)',
    },

    '.textfield-center &': { textAlign: 'center', padding: '16px 5px 17px' },
    '.textfield-right &': { textAlign: 'right' },
  },
  inputMarginDense: {
    paddingTop: 19,
    paddingLeft: 15,
    paddingBottom: 3,
    // fontSize: typography.body2.fontSize,
  },
  inputHiddenLabel: {
    paddingTop: 16,
    paddingBottom: 15,
    '&$inputMarginDense': {
      paddingLeft: 15,
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  inputAdornedStart: {
    '&$inputMarginDense': {
      paddingLeft: 5,
    },
  },
  inputAdornedEnd: {
    paddingRight: 5,
  },
  marginDense: {
    borderRadius: 4,
  },
  multiline: {
    padding: '16px 23px 17px',
  },
  inputMultiline: {
    height: 'auto',
  },
};

export default MuiFilledInput;
