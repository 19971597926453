import { Grid } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { ReactComponent as PortalLogo } from '../../../../components/svg/logos/portalLogo.svg'
import { emptyArray } from "../../../../utils/arrayUtils";
const PreviewFile = ({ linkFile }) => {
    return (
        <React.Fragment>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ minWidth:'160px', maxWidth:'160px', minHeight:'160px', maxHeight:'160px'}}
                spacing={1}
            >
                <Grid item>
                    {linkFile == undefined ?
                        <PortalLogo  style={{  width: '210px', height: '80px' }}/>
                        :
                        <img key={linkFile} src={linkFile} style={{minWidth:'160px', maxWidth:'160px', minHeight:'160px', maxHeight:'160px'}} />
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
export default PreviewFile