import { useState } from 'react'
import { ModalDialog } from '../../../../components/common/Dialogs'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { Button } from '../../../../components/common/Buttons'
import { useAlertContext } from '../../../../contexts/AlertContext'
import Typography from '../../../../components/common/Typography'
import { Autocomplete, CheckBox } from '../../../../components/common/Inputs'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { actions } from '../../../../store/clientDetail'
import { useNavigate } from 'react-router-dom'
import FailedDeleteOrganizationModal from './FailedDeleteOrganizationModal'

function DeleteOrganizationModal({ open, onClose, org, orgHierarchy }) {
  const [subsidiary, setSubsidiary] = useState(null)
  const [loading, setLoading] = useState(false)

  const { successAlert, errorAlert } = useAlertContext()
  const styles = useStyles()
  const dispatch = useDispatch()
  const history = useNavigate()

  const handleSelectSubsidiary = ev => {
    setSubsidiary(ev.target.value.id)
  }

  const handleSelectSubsidiaryFromChecBox = ev => {
    if (ev.target.checked === true) setSubsidiary('DELETE-ALL')
    else setSubsidiary(null)
  }

  const handleSubmit = () => {
    if (!org.parentOrganizationId && !subsidiary) {
      errorAlert('Please select an option to proceed')
      return
    }

    const body = {
      id: org.id,
      subsidiary,
    }

    setLoading(true)
    Promise.resolve(dispatch(actions.deleteOrganization(body)))
      .then(res => {
        if (res.payload.success) {
          successAlert('Organization deleted successfully')
          onClose()
          history('/organizations')
        } else errorAlert('Something went wrong')
      })
      .catch(err => {
        console.log(err)
        errorAlert('Something went wrong')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <FailedDeleteOrganizationModal />
      <ModalDialog
        open={open}
        onClose={onClose}
        title="Delete Organization"
        disableContentSpacing
        actions={
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                disabled={loading}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                pending={loading}
                disabled={loading}
                onClick={handleSubmit}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Box
          className={clsx(
            styles.modalBodySection,
            `${styles.modalBodySection}--center`
          )}
        >
          <Typography py={5} variant="h2" weight="500">
            Delete this organization?
          </Typography>
          <Typography className={styles.subtitle} variant="body1">
            <span>Note!</span> Organization will be permanently deleted from the
            system.
          </Typography>
        </Box>
        {!org.parentOrganizationId && (
          <Grid container spacing={1} className={styles.modalBodySection}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disabled={subsidiary === 'DELETE-ALL'}
                options={orgHierarchy.filter(o => o.id !== org.id)}
                disableClearable
                placeholder="Please select..."
                onChange={handleSelectSubsidiary}
                labelKey="orgName"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: 'flex',
                  padding: '4px',
                  alignItems: 'center',
                  border: '1px solid #E0E0E0',
                  overflow: 'hidden',
                }}
              >
                <CheckBox
                  color="secondary"
                  id="subsidiaryCheckbox"
                  name="subsidiary"
                  dense={false}
                  onChange={handleSelectSubsidiaryFromChecBox}
                />
                <label
                  style={{ flex: 1, cursor: 'pointer' }}
                  htmlFor="subsidiaryCheckbox"
                >
                  Delete all subsidiaries too
                </label>
              </Box>
            </Grid>
          </Grid>
        )}
      </ModalDialog>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  modalBodySection: {
    padding: '30px',
    overflow: 'hidden',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.gray.divider}`,
    },
    '&--center': {
      textAlign: 'center',
    },
  },
  subtitle: {
    '& span': {
      color: theme.palette.secondary.main,
    },
  },
}))

export default DeleteOrganizationModal
