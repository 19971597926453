import React from 'react'
import Typography from '../../../../../components/common/Typography'

function ModalText({ title, itemName }) {
  return (
    <>
      <Typography variant="h3" weight={600}>
        {title}
      </Typography>
      <br />
      <Typography weight={500} color="secondary">
        {itemName}
      </Typography>
    </>
  )
}

export default ModalText
