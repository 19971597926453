import React from 'react';
import { VENDORS_MANAGEMENT } from '../../constants/api';
import { useApiAutocomplete } from '../../hooks/useApiAutocomplete';
import { application } from '../../services/application';
import { formatToTitleCase } from '../../utils/helpers';
import Autocomplete from '../common/Inputs/Autocomplete';

export const ExpiresAutocomplete = (props) => {
  const { onChange, value, multi, labelKey = 'value', label, ...rest } = props;

  const { options, loading, handleValueChange, filterOptions, handleInputChange } = useApiAutocomplete({
    labelKey,
    onChange: React.useCallback((val) => onChange && onChange(val), [onChange]),
    getOptionList: () => [
      {id: 'expires-soon', value: 'Expires Soon'},
      {id: 'expired', value: 'Expired'},
    ],
  });

  return (
    <Autocomplete
      name="categories"
      options={options}
      label={label}
      labelKey={labelKey}
      loading={loading}
      value={value}
      filterOptions={filterOptions}
      onChange={handleValueChange}
      multiple={multi}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      formatLabelCB={formatToTitleCase}
      onInputChange={handleInputChange}
      {...rest}
    />
  );
};
