export const SUBSCRIPTION_SETTINGS_INITIAL_STATE = [
  {
    key: 'expired-tab',
    idTab: 1,
    label: 'Expired',
    existingUsers: {
      optionsList: [
        {
          columnName: 'Business',
          key: 'Business',
          icon: 'IconBusiness',
          active: false,
          options: [
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Overview',
              key: 'checkboxs-bussines-overview',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Support Tickets',
              key: 'checkboxs-bussines-Supportickets',
            },
            {
              label: 'Documents',
              aCheckBox: false,
              vCheckBox: false,
            },
            {
              label: 'Forms',
              aCheckBox: false,
              vCheckBox: false,
            },
          ],
        },
        {
          columnName: 'Knowledge',
          key: 'Knowledge',
          icon: 'IconKnowledge',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Releases',
              key: 'checkboxs-knowledge-releases',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Code Categories',
              key: 'checkboxs-knowledge-code-categories',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Learning Center',
              key: 'checkboxs-knowledge-learning-center',
            },
          ],
        },
        {
          columnName: 'Workplace',
          key: 'Workplace',
          icon: 'IconWorkplace',
          active: true,
          options: [
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Exports',
              key: 'checkboxs-workplace-exports',
            },
            {
              vCheckBox: true,
              aCheckBox: false,
              label: 'My Pursuits',
              key: 'checkboxs-workplace-my-pursuits',
            },
            {
              vCheckBox: true,
              aCheckBox: false,
              label: 'Pipelines',
              key: 'checkboxs-workplace-pipelines',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Saved Searches',
              key: 'checkboxs-workplace-saved-searches',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Vendor Management',
              key: 'checkboxs-workplace-vendor-management',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Teaming Opportunities',
              key: 'checkboxs-workplace-teaming-opportunities',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Tasks',
              key: 'checkboxs-workplace-tasks',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Dashboard',
              key: 'checkboxs-workplace-dashboard',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Demo Dashboard',
              key: 'checkboxs-workplace-demo-dashboard',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Request',
              key: 'checkboxs-workplace-request',
            },
          ],
        },
        {
          columnName: 'Discovery',
          key: 'Discovery',
          icon: 'IconExplore',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Contract Opportunities',
              key: 'checkboxs-discovery-contract-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Vendors',
              key: 'checkboxs-discovery-vendors',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Government Files',
              key: 'checkboxs-discovery-government-files',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'NSN Opportunities',
              key: 'checkboxs-discovery-nsn-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: false,
              label: 'Federal Agents',
              key: 'checkboxs-discovery-federal-agents',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Contract Awards',
              key: 'checkboxs-discovery-contract-awards',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Grant Opportunities',
              key: 'checkboxs-discovery-grant-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: false,
              label: 'Federal Agencies and Offices',
              key: 'checkboxs-discovery-federal-agencies-and-offices',
            },
          ],
        },
      ],
    },
  },
  {
    key: 'limited-tab',
    idTab: 2,
    label: 'Limited',
    existingUsers: {
      optionsList: [
        {
          columnName: 'Business',
          key: 'Business',
          icon: 'IconBusiness',
          active: false,
          options: [
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Overview',
              key: 'checkboxs-bussines-overview',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Support Tickets',
              key: 'checkboxs-bussines-Supportickets',
            },
            {
              label: 'Documents',
              aCheckBox: false,
              vCheckBox: false,
            },
            {
              label: 'Forms',
              aCheckBox: false,
              vCheckBox: false,
            },
          ],
        },
        {
          columnName: 'Knowledge',
          key: 'Knowledge',
          icon: 'IconKnowledge',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Releases',
              key: 'checkboxs-knowledge-releases',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Code Categories',
              key: 'checkboxs-knowledge-code-categories',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Learning Center',
              key: 'checkboxs-knowledge-learning-center',
            },
          ],
        },
        {
          columnName: 'Workplace',
          key: 'Workplace',
          icon: 'IconWorkplace',
          active: true,
          options: [
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Exports',
              key: 'checkboxs-workplace-exports',
            },
            {
              vCheckBox: true,
              aCheckBox: false,
              label: 'My Pursuits',
              key: 'checkboxs-workplace-my-pursuits',
            },
            {
              vCheckBox: true,
              aCheckBox: false,
              label: 'Pipelines',
              key: 'checkboxs-workplace-pipelines',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Saved Searches',
              key: 'checkboxs-workplace-saved-searches',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Vendor Management',
              key: 'checkboxs-workplace-vendor-management',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Teaming Opportunities',
              key: 'checkboxs-workplace-teaming-opportunities',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Tasks',
              key: 'checkboxs-workplace-tasks',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Dashboard',
              key: 'checkboxs-workplace-dashboard',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Demo Dashboard',
              key: 'checkboxs-workplace-demo-dashboard',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Request',
              key: 'checkboxs-workplace-request',
            },
          ],
        },
        {
          columnName: 'Discovery',
          key: 'Discovery',
          icon: 'IconExplore',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Contract Opportunities',
              key: 'checkboxs-discovery-contract-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Vendors',
              key: 'checkboxs-discovery-vendors',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Government Files',
              key: 'checkboxs-discovery-government-files',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'NSN Opportunities',
              key: 'checkboxs-discovery-nsn-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: false,
              label: 'Federal Agents',
              key: 'checkboxs-discovery-federal-agents',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Contract Awards',
              key: 'checkboxs-discovery-contract-awards',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Grant Opportunities',
              key: 'checkboxs-discovery-grant-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: false,
              label: 'Federal Agencies and Offices',
              key: 'checkboxs-discovery-federal-agencies-and-offices',
            },
          ],
        },
      ],
    },
  },
  {
    key: 'advantage-tab',
    idTab: 3,
    label: 'Advantage',
    existingUsers: {
      optionsList: [
        {
          columnName: 'Business',
          key: 'Business',
          icon: 'IconBusiness',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Overview',
              key: 'checkboxs-bussines-overview',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Support Tickets',
              key: 'checkboxs-bussines-Supportickets',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Documents',
              key: 'checkboxs-bussines',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Forms',
              key: 'checkboxs-bussines',
            },
          ],
        },
        {
          columnName: 'Knowledge',
          key: 'Knowledge',
          icon: 'IconKnowledge',
          active: false,
          options: [
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Releases',
              key: 'checkboxs-knowledge-releases',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Code Categories',
              key: 'checkboxs-knowledge-code-categories',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Learning Center',
              key: 'checkboxs-knowledge-learning-center',
            },
          ],
        },
        {
          columnName: 'Workplace',
          key: 'Workplace',
          icon: 'IconWorkplace',
          active: false,
          options: [
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Exports',
              key: 'checkboxs-workplace-exports',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'My Pursuits',
              key: 'checkboxs-workplace-my-pursuits',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Pipelines',
              key: 'checkboxs-workplace-pipelines',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Saved Searches',
              key: 'checkboxs-workplace-saved-searches',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Vendor Management',
              key: 'checkboxs-workplace-vendor-management',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Teaming Opportunities',
              key: 'checkboxs-workplace-teaming-opportunities',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Tasks',
              key: 'checkboxs-workplace-tasks',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Dashboard',
              key: 'checkboxs-workplace-dashboard',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Demo Dashboard',
              key: 'checkboxs-workplace-demo-dashboard',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Request',
              key: 'checkboxs-workplace-request',
            },
          ],
        },
        {
          columnName: 'Discovery',
          key: 'Discovery',
          icon: 'IconExplore',
          active: false,
          options: [
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Contract Opportunities',
              key: 'checkboxs-discovery-contract-opportunities',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Vendors',
              key: 'checkboxs-discovery-vendors',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Government Files',
              key: 'checkboxs-discovery-government-files',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'NSN Opportunities',
              key: 'checkboxs-discovery-nsn-opportunities',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Federal Agents',
              key: 'checkboxs-discovery-federal-agents',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Contract Awards',
              key: 'checkboxs-discovery-contract-awards',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Grant Opportunities',
              key: 'checkboxs-discovery-grant-opportunities',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Federal Agencies and Offices',
              key: 'checkboxs-discovery-federal-agencies-and-offices',
            },
          ],
        },
      ],
    },
  },
  {
    key: 'pro-tab',
    idTab: 4,
    label: 'Pro',
    existingUsers: {
      optionsList: [
        {
          columnName: 'Business',
          key: 'Business',
          icon: 'IconBusiness',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Overview',
              key: 'checkboxs-bussines-overview',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Support Tickets',
              key: 'checkboxs-bussines-Supportickets',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Documents',
              key: 'checkboxs-bussines',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Forms',
              key: 'checkboxs-bussines',
            },
          ],
        },
        {
          columnName: 'Knowledge',
          key: 'Knowledge',
          icon: 'IconKnowledge',
          active: false,
          options: [
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Releases',
              key: 'checkboxs-knowledge-releases',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Code Categories',
              key: 'checkboxs-knowledge-code-categories',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Learning Center',
              key: 'checkboxs-knowledge-learning-center',
            },
          ],
        },
        {
          columnName: 'Workplace',
          key: 'Workplace',
          icon: 'IconWorkplace',
          active: false,
          options: [
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Exports',
              key: 'checkboxs-workplace-exports',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'My Pursuits',
              key: 'checkboxs-workplace-my-pursuits',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Pipelines',
              key: 'checkboxs-workplace-pipelines',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Saved Searches',
              key: 'checkboxs-workplace-saved-searches',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Vendor Management',
              key: 'checkboxs-workplace-vendor-management',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Teaming Opportunities',
              key: 'checkboxs-workplace-teaming-opportunities',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Tasks',
              key: 'checkboxs-workplace-tasks',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Dashboard',
              key: 'checkboxs-workplace-dashboard',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Demo Dashboard',
              key: 'checkboxs-workplace-demo-dashboard',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Request',
              key: 'checkboxs-workplace-request',
            },
          ],
        },
        {
          columnName: 'Discovery',
          key: 'Discovery',
          icon: 'IconExplore',
          active: false,
          options: [
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Contract Opportunities',
              key: 'checkboxs-discovery-contract-opportunities',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Vendors',
              key: 'checkboxs-discovery-vendors',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Government Files',
              key: 'checkboxs-discovery-government-files',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'NSN Opportunities',
              key: 'checkboxs-discovery-nsn-opportunities',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Federal Agents',
              key: 'checkboxs-discovery-federal-agents',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Contract Awards',
              key: 'checkboxs-discovery-contract-awards',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Grant Opportunities',
              key: 'checkboxs-discovery-grant-opportunities',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Federal Agencies and Offices',
              key: 'checkboxs-discovery-federal-agencies-and-offices',
            },
          ],
        },
      ],
    },
  },
  {
    key: 'free-trial-tab',
    idTab: 5,
    label: 'Free Trial',
    existingUsers: {
      optionsList: [
        {
          columnName: 'Business',
          key: 'Business',
          icon: 'IconBusiness',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Overview',
              key: 'checkboxs-bussines-overview',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Support Tickets',
              key: 'checkboxs-bussines-Supportickets',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Documents',
              key: 'checkboxs-bussines',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Forms',
              key: 'checkboxs-bussines',
            },
          ],
        },
        {
          columnName: 'Knowledge',
          key: 'Knowledge',
          icon: 'IconKnowledge',
          active: false,
          options: [
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Releases',
              key: 'checkboxs-knowledge-releases',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Code Categories',
              key: 'checkboxs-knowledge-code-categories',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Learning Center',
              key: 'checkboxs-knowledge-learning-center',
            },
          ],
        },
        {
          columnName: 'Workplace',
          key: 'Workplace',
          icon: 'IconWorkplace',
          active: false,
          options: [
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Exports',
              key: 'checkboxs-workplace-exports',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'My Pursuits',
              key: 'checkboxs-workplace-my-pursuits',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Pipelines',
              key: 'checkboxs-workplace-pipelines',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Saved Searches',
              key: 'checkboxs-workplace-saved-searches',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Vendor Management',
              key: 'checkboxs-workplace-vendor-management',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Teaming Opportunities',
              key: 'checkboxs-workplace-teaming-opportunities',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Tasks',
              key: 'checkboxs-workplace-tasks',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Dashboard',
              key: 'checkboxs-workplace-dashboard',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Demo Dashboard',
              key: 'checkboxs-workplace-demo-dashboard',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Request',
              key: 'checkboxs-workplace-request',
            },
          ],
        },
        {
          columnName: 'Discovery',
          key: 'Discovery',
          icon: 'IconExplore',
          active: false,
          options: [
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Contract Opportunities',
              key: 'checkboxs-discovery-contract-opportunities',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Vendors',
              key: 'checkboxs-discovery-vendors',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Government Files',
              key: 'checkboxs-discovery-government-files',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'NSN Opportunities',
              key: 'checkboxs-discovery-nsn-opportunities',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Federal Agents',
              key: 'checkboxs-discovery-federal-agents',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Contract Awards',
              key: 'checkboxs-discovery-contract-awards',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Grant Opportunities',
              key: 'checkboxs-discovery-grant-opportunities',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Federal Agencies and Offices',
              key: 'checkboxs-discovery-federal-agencies-and-offices',
            },
          ],
        },
      ],
    },
  },
  {
    key: 'sap-advantage-tab',
    idTab: 6,
    label: 'SAP Advantage',
    existingUsers: {
      optionsList: [
        {
          columnName: 'Business',
          key: 'Business',
          icon: 'IconBusiness',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Overview',
              key: 'checkboxs-bussines-overview',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Support Tickets',
              key: 'checkboxs-bussines-Supportickets',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Documents',
              key: 'checkboxs-bussines',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Forms',
              key: 'checkboxs-bussines',
            },
          ],
        },
        {
          columnName: 'Knowledge',
          key: 'Knowledge',
          icon: 'IconKnowledge',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Releases',
              key: 'checkboxs-knowledge-releases',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Code Categories',
              key: 'checkboxs-knowledge-code-categories',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Learning Center',
              key: 'checkboxs-knowledge-learning-center',
            },
          ],
        },
        {
          columnName: 'Workplace',
          key: 'Workplace',
          icon: 'IconWorkplace',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Exports',
              key: 'checkboxs-workplace-exports',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'My Pursuits',
              key: 'checkboxs-workplace-my-pursuits',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Pipelines',
              key: 'checkboxs-workplace-pipelines',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Saved Searches',
              key: 'checkboxs-workplace-saved-searches',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Vendor Management',
              key: 'checkboxs-workplace-vendor-management',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Teaming Opportunities',
              key: 'checkboxs-workplace-teaming-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Tasks',
              key: 'checkboxs-workplace-tasks',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Dashboard',
              key: 'checkboxs-workplace-dashboard',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Demo Dashboard',
              key: 'checkboxs-workplace-demo-dashboard',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Request',
              key: 'checkboxs-workplace-request',
            },
          ],
        },
        {
          columnName: 'Discovery',
          key: 'Discovery',
          icon: 'IconExplore',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Contract Opportunities',
              key: 'checkboxs-discovery-contract-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Vendors',
              key: 'checkboxs-discovery-vendors',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Government Files',
              key: 'checkboxs-discovery-government-files',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'NSN Opportunities',
              key: 'checkboxs-discovery-nsn-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Federal Agents',
              key: 'checkboxs-discovery-federal-agents',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Contract Awards',
              key: 'checkboxs-discovery-contract-awards',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Grant Opportunities',
              key: 'checkboxs-discovery-grant-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Federal Agencies and Offices',
              key: 'checkboxs-discovery-federal-agencies-and-offices',
            },
          ],
        },
      ],
    },
  },
  {
    key: 'app-max-tab',
    idTab: 7,
    label: 'APP Max',
    existingUsers: {
      optionsList: [
        {
          columnName: 'Business',
          key: 'Business',
          icon: 'IconBusiness',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Overview',
              key: 'checkboxs-bussines-overview',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Support Tickets',
              key: 'checkboxs-bussines-Supportickets',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Documents',
              key: 'checkboxs-bussines',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Forms',
              key: 'checkboxs-bussines',
            },
          ],
        },
        {
          columnName: 'Knowledge',
          key: 'Knowledge',
          icon: 'IconKnowledge',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Releases',
              key: 'checkboxs-knowledge-releases',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Code Categories',
              key: 'checkboxs-knowledge-code-categories',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Learning Center',
              key: 'checkboxs-knowledge-learning-center',
            },
          ],
        },
        {
          columnName: 'Workplace',
          key: 'Workplace',
          icon: 'IconWorkplace',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Exports',
              key: 'checkboxs-workplace-exports',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'My Pursuits',
              key: 'checkboxs-workplace-my-pursuits',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Pipelines',
              key: 'checkboxs-workplace-pipelines',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Saved Searches',
              key: 'checkboxs-workplace-saved-searches',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Vendor Management',
              key: 'checkboxs-workplace-vendor-management',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Teaming Opportunities',
              key: 'checkboxs-workplace-teaming-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Tasks',
              key: 'checkboxs-workplace-tasks',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Dashboard',
              key: 'checkboxs-workplace-dashboard',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Demo Dashboard',
              key: 'checkboxs-workplace-demo-dashboard',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Request',
              key: 'checkboxs-workplace-request',
            },
          ],
        },
        {
          columnName: 'Discovery',
          key: 'Discovery',
          icon: 'IconExplore',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Contract Opportunities',
              key: 'checkboxs-discovery-contract-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Vendors',
              key: 'checkboxs-discovery-vendors',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Government Files',
              key: 'checkboxs-discovery-government-files',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'NSN Opportunities',
              key: 'checkboxs-discovery-nsn-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Federal Agents',
              key: 'checkboxs-discovery-federal-agents',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Contract Awards',
              key: 'checkboxs-discovery-contract-awards',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Grant Opportunities',
              key: 'checkboxs-discovery-grant-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Federal Agencies and Offices',
              key: 'checkboxs-discovery-federal-agencies-and-offices',
            },
          ],
        },
      ],
    },
  },
  {
    key: 'procurement-pro-tab',
    idTab: 8,
    label: 'Procurement Pro',
    existingUsers: {
      optionsList: [
        {
          columnName: 'Business',
          key: 'Business',
          icon: 'IconBusiness',
          active: false,
          options: [
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Overview',
              key: 'checkboxs-bussines-overview',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Support Tickets',
              key: 'checkboxs-bussines-Supportickets',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Documents',
              key: 'checkboxs-bussines',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Forms',
              key: 'checkboxs-bussines',
            },
          ],
        },
        {
          columnName: 'Knowledge',
          key: 'Knowledge',
          icon: 'IconKnowledge',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Releases',
              key: 'checkboxs-knowledge-releases',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Code Categories',
              key: 'checkboxs-knowledge-code-categories',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Learning Center',
              key: 'checkboxs-knowledge-learning-center',
            },
          ],
        },
        {
          columnName: 'Workplace',
          key: 'Workplace',
          icon: 'IconWorkplace',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Exports',
              key: 'checkboxs-workplace-exports',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'My Pursuits',
              key: 'checkboxs-workplace-my-pursuits',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Pipelines',
              key: 'checkboxs-workplace-pipelines',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Saved Searches',
              key: 'checkboxs-workplace-saved-searches',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Vendor Management',
              key: 'checkboxs-workplace-vendor-management',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Teaming Opportunities',
              key: 'checkboxs-workplace-teaming-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Tasks',
              key: 'checkboxs-workplace-tasks',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Dashboard',
              key: 'checkboxs-workplace-dashboard',
            },
            {
              vCheckBox: false,
              aCheckBox: false,
              label: 'Demo Dashboard',
              key: 'checkboxs-workplace-demo-dashboard',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Request',
              key: 'checkboxs-workplace-request',
            },
          ],
        },
        {
          columnName: 'Discovery',
          key: 'Discovery',
          icon: 'IconExplore',
          active: true,
          options: [
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Contract Opportunities',
              key: 'checkboxs-discovery-contract-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Vendors',
              key: 'checkboxs-discovery-vendors',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Government Files',
              key: 'checkboxs-discovery-government-files',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'NSN Opportunities',
              key: 'checkboxs-discovery-nsn-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Federal Agents',
              key: 'checkboxs-discovery-federal-agents',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Contract Awards',
              key: 'checkboxs-discovery-contract-awards',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Grant Opportunities',
              key: 'checkboxs-discovery-grant-opportunities',
            },
            {
              vCheckBox: true,
              aCheckBox: true,
              label: 'Federal Agencies and Offices',
              key: 'checkboxs-discovery-federal-agencies-and-offices',
            },
          ],
        },
      ],
    },
  },
]

export const ACCOUNT_FEATURES = {
  EXPIRED: 'expired',
  LIMITED: 'limited',
  ADVANTAGE: 'advantage',
  PRO: 'pro',
  FREE_TRIAL: 'freeTrial',
  SAP_ADVANTAGE: 'sapAdvantage',
  APP_MAX: 'appMax',
  PROCUREMENT_PRO: 'procurementPro',
}

export const FEATURES_SHORT_NAMES = {
  [ACCOUNT_FEATURES.LIMITED]: 'lmt',
  [ACCOUNT_FEATURES.ADVANTAGE]: 'adv',
  [ACCOUNT_FEATURES.PRO]: 'pro-mayus',
  [ACCOUNT_FEATURES.SAP_ADVANTAGE]: 'advm',
  [ACCOUNT_FEATURES.APP_MAX]: 'max',
  [ACCOUNT_FEATURES.PROCUREMENT_PRO]: 'prom',
}

export const COURSE_LEVELS_LABELS = {
  none: 'None',
  '2': 'Level 2',
  '3': 'Level 3',
}

export const COURSE_TYPES_LABELS = {
  course: 'Course',
  subscription: 'Subscription',
}