import { connect } from 'react-redux'
import { actions } from '../../store/pendingOrgAvatar'
import { generateListCallbacks } from '../../store/helpers/listActionsHelpers'

function mapStateToProps(state) {
  return {
    pendingAvatars: state.pendingAvatars,
    loading: state.pendingAvatars.loading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    updateAvatarStatus: params => dispatch(actions.updateAvatarStatus(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
