import { useEffect } from 'react'
import connect from './connect'
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase'
import Loader from '../../components/common/Loader'
import { Grid } from '@material-ui/core'
import NothingFoundCard from '../../components/generics/Cards/NothingFoundCard/NothingFoundCardList'
import PaginationSmall from '../../components/common/Paginations/PaginationSmall'
import List from './components/List'
import Header from './components/Header'
import { emptyArray } from '../../utils/arrayUtils'

const OrganizationLogoReview = ({
  pendingAvatars,
  onInit,
  onChangePage,
  onChangeRowPerPage,
}) => {
  const { items, count, pending, pagination } = pendingAvatars

  useEffect(() => {
    onInit()
  }, [])

  useEffect(() => {
    if (count > 0 && count <= (pagination.page - 1) * pagination.perPage) {
      onChangePage(pagination.page - 1)
    }
  }, [count])

  return (
    <SpacingContainerBase>
      <Header />
      {pending && <Loader open={pending} />}
      {!pending && !emptyArray(items) ? (
        <Grid container spacing={1} direction="column">
          <Grid item xs zeroMinWidth>
            <List items={items} />
          </Grid>

          <Grid item xs zeroMinWidth>
            <PaginationSmall
              page={pagination.page}
              perPage={pagination.perPage}
              count={count}
              onChangePage={onChangePage}
              onChangeRowPerPage={onChangeRowPerPage}
              pending={pending}
            />
          </Grid>
        </Grid>
      ) : !pending && emptyArray(items) ? (
        <Grid item xs zeroMinWidth>
          <NothingFoundCard />
        </Grid>
      ) : null}
    </SpacingContainerBase>
  )
}

export default connect(OrganizationLogoReview)
