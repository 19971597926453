import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined'
import { KeyboardDatePicker as MUIDatePicker } from '@material-ui/pickers/DatePicker'
import React from 'react'
import { DATE_FORMAT } from '../../../../../../constants'
import Typography from '../../../../../../components/common/Typography'

const InputPropsDefault = { disableUnderline: true, className: 'datepicker' }
const PopoverPropsDefault = { className: 'datepicker' }
const InputAdornmentProps = { position: 'start', variant: 'outlined' }
const KeyboardButtonProps = { size: 'small' }

export default function DatePicker({
  label,
  value,
  onChange,
  onChangeValue,
  name = 'date',
  format = DATE_FORMAT,
  error,
  ...rest
}) {

  return (
    <>
      {label && (
        <Typography
          weight="500"
          fontFamily="lexend"
          color="label"
          gutter="label"
          variant="body2"
        >
          {label}
        </Typography>
      )}
      <MUIDatePicker
        value={value || null}
        name={name}
        onChange={onChange}
        inputVariant="filled"
        variant="inline"
        autoOk
        format={format}
        fullWidth
        invalidDateMessage={null}
        KeyboardButtonProps={KeyboardButtonProps}
        InputAdornmentProps={InputAdornmentProps}
        InputProps={InputPropsDefault}
        PopoverProps={PopoverPropsDefault}
        keyboardIcon={<CalendarIcon fontSize="small" />}
        helperText={error}
        error={Boolean(error)}
        {...rest}
      />
    </>
  )
}
