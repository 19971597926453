import React, { useEffect, useState } from 'react'
import useTabs from '../../../hooks/useTabs'
import { Tab, Tabs } from '@material-ui/core'
import styles from '../styles.module.css'
import clsx from 'clsx'

const tabListFix = [
  {
    key: 'consulting',
    idTab: 1,
    label: 'Consulting',
  },
  {
    key: 'leads',
    idTab: 2,
    label: 'Leads',
  },
]

function TabsContainer({ tab, onChangeTab }) {
  return (
    <Tabs
      key="subscription-tabs"
      indicatorColor="primary"
      TabIndicatorProps={{
        style: {
          height: '3px',
        },
      }}
      className={styles.tabsContainer}
      value={tab}
      onChange={onChangeTab}
      scrollButtons="off"
    >
      {tabListFix &&
        tabListFix.map(item => (
          <Tab
            key={item.idTab}
            value={item.idTab}
            label={item.label}
            className={clsx(styles.tab, tab === item.idTab && styles.activeTab)}
          />
        ))}
    </Tabs>
  )
}

export default TabsContainer
