import * as Yup from 'yup';
import makeValidationSchema from '../../../../hooks/makeValidationSchema';

export const initialValues = {
  name: '',
  lastName: '',
  email: '',
  role: '',
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    name: Yup.string().required(trans('empty-not-allowed', { field: trans('name') })),
    email: Yup.string()
      .email(trans('incorrect-email'))
      .required(trans('empty-not-allowed', { field: trans('email') })),
    role: Yup.string().required(trans('empty-not-allowed', { field: trans('role') })),
  })
);
