import { useTrans } from '../../../../services/i18n'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import Typography from '../../../../components/common/Typography'
import { IconPlus } from '../../../../components/svg/Icons'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import AddAssignedStaffModal from './addAssignedStaffModal'
import Item from './item'
import connect from '../connect'
import { emptyArray } from '../../../../utils/arrayUtils'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { Button } from '../../../../components/common/Buttons'
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import { Divider } from '../../../../components/common/Divider'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const AssignedStaff = ({ detail, getUsers, deleteUserUsfcr, getDetail }) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const permissions = useSelector(selectUserPermissions)
  const deleteUser = user => {
    return deleteUserUsfcr({ id: user.id })
      .then(() => {
        successAlert(
          `${user.name} ${user.familyName} has been removed from this organization`
        )
      })
      .catch(() => {
        errorAlert('Error')
      })
  }

  useEffect(() => {
    let excludedUsers = []
    if (detail) {
      excludedUsers = !emptyArray(detail.assignedStaff)
        ? detail.assignedStaff.map(a => a.userId)
        : []
      getUsers({ excludedUsers })
    }
  }, [detail])
  return (
    <SpacingContainerBase py={1}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              variant="h4"
              weight="500"
              fontFamily="lexend"
              style={{ alignItems: 'center', display: 'flex' }}
            >
              <AssignmentIndOutlinedIcon style={{ marginRight: '10px' }} />{' '}
              {trans('usfcr-staff')} <ShowTooltip sectionToShow="usfcr-staff" />
            </Typography>
          </Grid>
          {isEnabled(permissions, 'organization', 'usfcr-staff', 'create') && (
            <Grid item>
              <Button
                isIcon
                color="primary"
                variant="contained"
                onClick={onOpenModal}
              >
                <IconPlus />
              </Button>
            </Grid>
          )}
          <AddAssignedStaffModal open={openModal} onClose={onCloseModal} />
        </Grid>

        {detail && !emptyArray(detail.assignedStaff) && (
          <SpacingContainer px="0" py={2}>
            <Divider light />
            <TableContainer component={Paper} radius={9}>
              <Table style={{ minWidth: 1000 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{trans('name')}</TableCell>
                    <TableCell align="left">{trans('email')}</TableCell>
                    <TableCell align="left">
                      {trans('last-login-date')}
                    </TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {detail &&
                    detail.assignedStaff.map(item => (
                      <Item
                        detail={detail}
                        user={item}
                        key={item.id}
                        deleteUser={deleteUser}
                        getDetail={getDetail}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </SpacingContainer>
        )}
        {emptyArray(detail.assignedStaff) && (
          <NothingFoundCard
            title={trans('no-users-staff-added-yet')}
            size="medium"
          />
        )}
      </Paper>
    </SpacingContainerBase>
  )
}
export default connect(AssignedStaff)
