import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { API } from '../../constants'
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common'
import { extendListBuilder } from '../helpers/listActionsHelpers'
import { application } from '../../services/application'

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  currentTemplate: null,
  order: ['createdAt', 'desc'],
  pagination: { page: 1, perPage: 100 },
}

//Actions
const getList = createAsyncThunk('emailTemplates/getList', params => {
  return application.call(API.EMAIL_TEMPLATES.GET_LIST, params)
})
function onGetList(state, action) {
  state.items = action.payload.items
  state.count = action.payload.count
  state.pending = false
}

const findOne = createAsyncThunk('emailTemplates/findOne', params => {
  return application.call(API.EMAIL_TEMPLATES.FIND_ONE, params)
})
function onFindOne(state, action) {
  state.currentTemplate = action.payload
  state.pending = false
}

const saveEmailTemplate = createAsyncThunk(
  'emailTemplates/saveEmailTemplate',
  params => {
    return application.call(API.EMAIL_TEMPLATES.SAVE, params)
  }
)
function onSaveEmailTemplate(state, action) {
  state.currentTemplate = action.payload
  state.items = state.items.map(item => {
    if (item.id === action.payload.id) {
      return action.payload
    }
    return item
  })
  state.pending = false
}

export const actions = {
  getList,
  findOne,
  saveEmailTemplate,
}

const handlePending = state => {
  state.pending = true
}

const handleRejected = state => {
  state.pending = false
}

export default createReducer(INITIAL_STATE, builder => {
  return extendListBuilder(builder, actions)
    .addCase(getList.pending, handlePending)
    .addCase(getList.fulfilled, onGetList)
    .addCase(getList.rejected, handleRejected)

    .addCase(findOne.pending, handlePending)
    .addCase(findOne.fulfilled, onFindOne)
    .addCase(findOne.rejected, handleRejected)

    .addCase(saveEmailTemplate.pending, handlePending)
    .addCase(saveEmailTemplate.fulfilled, onSaveEmailTemplate)
    .addCase(saveEmailTemplate.rejected, handleRejected)
})
