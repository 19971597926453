import DayjsUtils from '@date-io/dayjs'
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider'
import React, { useEffect } from 'react'
import useBooleanHandlers from '../../../hooks/useBooleanHandlers'
import { useMediaQueryDown } from '../../../hooks/useMediaQuery'
import SectionScrollable from '../../common/Containers/SectionScrollable'
import AppHeader from './AppHeader'
import AppMenu from './AppMenu'
import useStyles from './styles'

const AppLayout = ({ children }) => {
  const styles = useStyles()
  const isSmallScreen = useMediaQueryDown('sm')
  const [openDrawer, setDrawerOpen, setDrawerClose] = useBooleanHandlers(true)

  useEffect(() => {
    if (isSmallScreen) {
      setDrawerClose()
    } else {
      setDrawerOpen()
    }
  }, [isSmallScreen, setDrawerClose, setDrawerOpen])

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <div className={styles.root}>
        <AppMenu
          openDrawer={openDrawer}
          setDrawerOpen={setDrawerOpen}
          setDrawerClose={setDrawerClose}
        />
        <section className={styles.content}>
          <SectionScrollable 
          bgColor="paperSecondary"
            TopFixed={isSmallScreen ? (  <AppHeader openDrawer={openDrawer} setDrawerClose={setDrawerClose} setDrawerOpen={setDrawerOpen} />): ( <></>)
             
            }>
            {children}
          </SectionScrollable>
        </section>
      </div>
    </MuiPickersUtilsProvider>
  )
}

export default AppLayout
