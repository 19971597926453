import styled from 'styled-components'

export const Label = styled.span`
  color: #888fa9;
  font-family: Manrope;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const ValueText = styled.span`
  color: ${({ color }) => (color ? color : 'var(--Black, #000)')};
  font-family: Manrope;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
