import React, { useEffect } from 'react'
import connect from './connect'
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase'
import Loader from '../../components/common/Loader'
import { Grid } from '@material-ui/core'
import NothingFoundCard from '../../components/generics/Cards/NothingFoundCard/NothingFoundCardList'
import PaginationSmall from '../../components/common/Paginations/PaginationSmall'
import List from './components/List'
import Header from './components/Header'
import { emptyArray } from '../../utils/arrayUtils'

const RequestTemplates = ({
  requestTemplates,
  onChangePage,
  onChangeRowPerPage,
  getList,
}) => {
  const { items, count, pending, pagination } = requestTemplates

  useEffect(() => {
    getList({
      text: '',
      order: ['createdAt', 'desc'],
      filters: {},
      pagination: { page: 1, perPage: 10 },
    })
  }, [])

  const [pag, setPag] = React.useState({
    page: 1,
    perPage: 10,
  })

  const handleChangePage = (newPage) => {
    setPag({ ...pag, page: newPage })
    getList({
      text: '',
      order: ['createdAt', 'desc'],
      filters: {},
      pagination: { page: newPage, perPage: 10 },
    })
  }

  return (
    <SpacingContainerBase>
      <Header />
      {pending && <Loader open={pending} />}
      {!emptyArray(items) ? (
        <Grid container spacing={2} direction="column">
          <Grid item xs zeroMinWidth>
            <List items={items} />
          </Grid>

          <Grid item xs zeroMinWidth>
            <PaginationSmall
              page={pag.page}
              perPage={pag.perPage}
              count={count}
              onChangePage={handleChangePage}
              onChangeRowPerPage={onChangeRowPerPage}
              pending={pending}
            />
          </Grid>
        </Grid>
      ) : !pending && emptyArray(items) ? (
        <Grid item xs zeroMinWidth>
          <NothingFoundCard />
        </Grid>
      ) : null}
    </SpacingContainerBase>
  )
}

export default connect(RequestTemplates)
