import { useTrans } from '../../../../services/i18n'

import React, { useEffect, useState, useCallback } from 'react'

import Typography from '../../../../components/common/Typography'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import MenuItem from '@material-ui/core/MenuItem'

import Alert from '@material-ui/lab/Alert'

import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import { Grid, IconButton } from '@material-ui/core'
import { TextField } from '../../../../components/common/Inputs'
import { Button } from '../../../../components/common/Buttons'
import { Divider } from '../../../../components/common/Divider'

import { useForm } from '../../../../hooks/useForm'
import { getInitialValues, useScheme } from './helpers'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { SelectField } from '../../../../components/common/Inputs'

import MuiAlert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close'
import { selectUserPermissions } from '../../../../selectors'
import { useSelector } from 'react-redux'

import connect from '../../connect'

const formOptions = {
  validateOnMount: false,
  validateOnChange: true,
  enableReinitialize: true,
}

const AddBanner = ({
  publishBanner,
  getPublishedBanner,
  publishedBanner,
  unpublishBanner,
}) => {
  const { trans } = useTrans()
  const schema = useScheme()
  const [isSend, setIsSend] = useState(false)
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const permissions = useSelector(selectUserPermissions)

  const isEnabled = (att = 'view') => {
    const routeKey = 'tooltips-management'
    const module = permissions.find(p => p.key === routeKey)
    if (!module) return true

    const subModule = module.submodules.find(sm => sm.key === module.menuOption)

    if (!subModule) return true
    return subModule[att]
  }

  const [currentBanner, setCurrentBanner] = useState()

  const onSubmit = useCallback(
    async values => {
      setIsSend(true)
      Promise.resolve(publishBanner(values))
        .then(res => {
          if (res.payload && res.payload.success) {
            successAlert('The banner was successfully added')
            resetForm()
            getPublishedBanner()
          } else {
            errorAlert(
              res?.payload?.message
                ? trans(res.payload.message)
                : res?.error?.message
            )
          }
        })
        .catch(err => {
          errorAlert(err.message)
        })
        .finally(() => setIsSend(false))
    },
    [infoAlert, errorAlert, successAlert]
  )

  const {
    getFieldProps,
    handleSubmit,
    values,
    isSubmitting,
    isValid,
    resetForm,
    setFieldValue,
  } = useForm(schema, getInitialValues(), onSubmit, formOptions)

  useEffect(() => {
    getPublishedBanner()
  }, [])

  useEffect(() => {
    if (publishedBanner && publishedBanner.success) {
      if (publishedBanner.banner) {
        setCurrentBanner(JSON.parse(publishedBanner.banner.value))
      } else {
        setCurrentBanner(null)
      }
    }
  }, [publishedBanner])

  function unpublishBannerFunc() {
    setIsSend(true)
    Promise.resolve(unpublishBanner())
      .then(res => {
        if (res.payload && res.payload.success) {
          successAlert('The banner was successfully unpublished')
          getPublishedBanner()
        } else {
          errorAlert(
            res?.payload?.message
              ? trans(res.payload.message)
              : res?.error?.message
          )
        }
      })
      .catch(err => {
        errorAlert(err.message)
      })
      .finally(() => setIsSend(false))
  }

  function Alert(props) {
    return (
      <MuiAlert
        style={{ borderRadius: 0 }}
        elevation={6}
        variant="filled"
        {...props}
      />
    )
  }

  return (
    <SpacingContainer py={2}>
      {isEnabled('create') && (
        <Paper component={SpacingContainer} py={3} px="5sm3">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs zeroMinWidth>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  marginBottom: '16px',
                }}
              >
                <CheckCircleOutlineIcon
                  style={{ marginRight: '16px', color: '#28a745' }}
                />
                <Typography variant="body2" weight="500" fontFamily="lexend">
                  {trans('add-banner-first-message')}
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <TextField
                multiline
                minRows={5}
                label={trans('message') + ' (180 Characters maximum)'}
                placeholder={trans('message')}
                {...getFieldProps('message')}
                name="message"
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Typography
                weight="500"
                fontFamily="lexend"
                color="label"
                gutter="label"
                variant="body2"
              >
                Select severity
              </Typography>
              <SelectField
                type="select"
                placeholder={trans('required')}
                {...getFieldProps('severity')}
                getRenderValue={item => item && trans(item)}
              >
                <MenuItem style={{ padding: 0 }} key="error" value="Error">
                  <Alert
                    style={{ width: '100%', borderRadius: 0 }}
                    variant="filled"
                    severity="error"
                  >
                    Error
                  </Alert>
                </MenuItem>
                <MenuItem style={{ padding: 0 }} key="warning" value="Warning">
                  <Alert
                    style={{ width: '100%', borderRadius: 0 }}
                    variant="filled"
                    severity="warning"
                  >
                    Warning
                  </Alert>
                </MenuItem>
                <MenuItem style={{ padding: 0 }} key="info" value="Info">
                  <Alert
                    style={{ width: '100%', borderRadius: 0 }}
                    variant="filled"
                    severity="info"
                  >
                    Info
                  </Alert>
                </MenuItem>
                <MenuItem style={{ padding: 0 }} key="success" value="Success">
                  <Alert
                    style={{ width: '100%', borderRadius: 0 }}
                    variant="filled"
                    severity="success"
                  >
                    Success
                  </Alert>
                </MenuItem>
              </SelectField>
            </Grid>
          </Grid>
          <Button
            style={{ marginTop: '30px' }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            size="large"
            disabled={isSend}
            pending={isSend}
          >
            {trans('publish-banner')}
          </Button>
        </Paper>
      )}
      <SpacingContainer py={2}>
        <Paper component={SpacingContainer} py={3} px="5sm3">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs zeroMinWidth>
              <Typography
                variant="h4"
                weight="500"
                fontFamily="lexend"
                style={{ alignItems: 'center', display: 'flex' }}
              >
                {trans('current-banner-published')}{' '}
              </Typography>
            </Grid>
          </Grid>
          <br></br>
          <Grid>
            {currentBanner ? (
              <Alert
                action={
                  <IconButton aria-label="close" color="inherit" size="small">
                    <CloseIcon fontSize="inherit"></CloseIcon>{' '}
                  </IconButton>
                }
                variant="filled"
                severity={currentBanner.severity}
              >
                {currentBanner.message}
              </Alert>
            ) : (
              <Typography
                style={{ marginTop: '16px' }}
                variant="body2"
                fontFamily="lexend"
              >
                {trans('no-active-banner')}
              </Typography>
            )}
          </Grid>

          {isEnabled('edit') && (
            <Button
              style={{ marginTop: '30px' }}
              variant="contained"
              color="primary"
              onClick={unpublishBannerFunc}
              size="large"
              disabled={isSend}
              pending={isSend}
            >
              {trans('unpublish-banner')}
            </Button>
          )}
        </Paper>
      </SpacingContainer>
    </SpacingContainer>
  )
}

export default connect(AddBanner)
