const emailingValues = {
  instant: {
    instant: () => 'Instant',
    minutes: (notificationNumber) => `Each ${notificationNumber} min`,
    hour: () => `Each hour`,
  },
  daily: 'Daily Recap',
  weekly: 'Weekly',
  none: 'Never',
  global: 'Global',
};

export function getNotificationFrequencyValue({ enabled, notification, notificationFrequency, notificationNumber }) {
  if (!enabled) {
    return 'Off';
  }

  notification = notification?.toLowerCase() || 'none';
  const value = emailingValues[notification];
  if (typeof value === 'object') {
    const key = notificationFrequency?.toLowerCase() in value ? notificationFrequency?.toLowerCase() : 'instant';
    return value[key](notificationNumber);
  }
  return value;
}
