import { useTrans } from '../../../../services/i18n'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Grid } from '@material-ui/core'
import React from 'react'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import Typography from '../../../../components/common/Typography'
import { IconContractAwards } from '../../../../components/svg/Icons/Menu'
import Item from './item'
import connect from '../connect'
import { emptyArray } from '../../../../utils/arrayUtils'
import { Divider } from '../../../../components/common/Divider'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'

const AdjustedForms = ({ detail, getFormStackJSONSignedUrl,deleteFormOrg }) => {
  const { trans } = useTrans()

  return (
    <SpacingContainerBase py={0}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              variant="h4"
              weight="500"
              fontFamily="lexend"
              style={{ alignItems: 'center', display: 'flex' }}
            >
              <IconContractAwards />
              <span style={{ marginLeft: '10px' }}>
                {trans('adjustment-forms')}
              </span>{' '}
              <ShowTooltip sectionToShow="adjusment-forms" />
            </Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
        {detail && !emptyArray(detail.adjustedForms) && (
          <SpacingContainer px="0" py={2}>
            <Divider light />
            <TableContainer component={Paper} radius={9}>
              <Table style={{ minWidth: 1000 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {trans('associated-form')}
                    </TableCell>
                    <TableCell align="left">
                      {trans('adjustment-form')}
                    </TableCell>
                    <TableCell align="left">{trans('last-modified')}</TableCell>
                    <TableCell align="left">{trans('status')}</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detail.adjustedForms.map(af => (
                    <Item
                      key={af.id}
                      form={af}
                      organizationId={detail.id}
                      deleteForm={deleteFormOrg}
                      getFormSubmissionJSONSignedUrl={getFormStackJSONSignedUrl}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </SpacingContainer>
        )}
        {emptyArray(detail.adjustedForms) && (
          <NothingFoundCard
            title={trans('no-ajustment-forms-added-yet')}
            size="medium"
          />
        )}
      </Paper>
    </SpacingContainerBase>
  )
}
export default connect(AdjustedForms)
