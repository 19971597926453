import { configureStore } from '@reduxjs/toolkit'
import { authReducer } from './auth'
import notificationsReducer from './notifications'
import dashboardReducer from './dashboard'
import clientsReducer from './clients'
import usersReducer from './users'
import registeredUserDetailReducer from './registeredUserDetail'
import clientDetailReducer from './clientDetail'
import usfcrStaffReducer from './usfcrStaff'
import releasesReducer from './releases'
import globalSettingsReducer from './globalSettings'
import subscriptionSettingsReducer from './subscriptionSettings'
import tooltipsManagementReducer from './tooltipsManagement'
import requestedQuotes from './requestedQuotes'
import requestTemplates from './requestTemplates'
import requestTemplateFiles from './requestTemplateFiles'
import requestTemplateForms from './requestTemplateForms'
import emailTemplates from './emailTemplates'
import adminPortalRoles from './adminPortalRoles'
import learnWorldsReducer from './learnWorlds'
import pendingOrgAvatarReducer from './pendingOrgAvatar'
import teamingOpportunitiesReducer from './teamingOpportunities'

/**
 * @type {EnhancedStore}
 */
export default configureStore({
  reducer: {
    auth: authReducer,
    notifications: notificationsReducer,
    dashboard: dashboardReducer,
    clients: clientsReducer,
    users: usersReducer,
    registeredUserDetail: registeredUserDetailReducer,
    clientDetail: clientDetailReducer,
    usfcrStaff: usfcrStaffReducer,
    releases: releasesReducer,
    globalSettings: globalSettingsReducer,
    subscriptionSettings: subscriptionSettingsReducer,
    tooltipsManagement: tooltipsManagementReducer,
    requestedQuotes: requestedQuotes,
    requestTemplates: requestTemplates,
    requestTemplateFiles: requestTemplateFiles,
    requestTemplateForms: requestTemplateForms,
    emailTemplates: emailTemplates,
    adminPortalRoles: adminPortalRoles,
    learnWorlds: learnWorldsReducer,
    pendingAvatars: pendingOrgAvatarReducer,
    teamingOpportunities: teamingOpportunitiesReducer,
  },
})
