import { useTrans } from '../../../../services/i18n'
import { Grid, Box } from '@material-ui/core'
import React from 'react'
import { TextField } from '../../../../components/common/Inputs'
import Button from '../../../../components/common/Buttons/Button'

import { useForm } from '../../../../hooks/useForm'
import makeValidationSchema from '../../../../hooks/makeValidationSchema'
import { schemaToInitialState } from '../../../../utils/validationUtils'
import * as Yup from 'yup'
import connect from '../connect'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { ERROR_MESSAGES } from './errorMessages'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'
const useScheme = makeValidationSchema(trans =>
  Yup.object().shape({
    vendorIdentifier: Yup.string()
      .min(5, 'Must be min 5 digits')
      .max(12, 'Must be max 12 digits'),
  })
)

function VendorIdentifierCodeField({ detail, updateClaimedVendor }) {
  const { featuredVendor } = detail
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const permissions = useSelector(selectUserPermissions)

  const onSubmit = ({ vendorIdentifier }) => {
    if (vendorIdentifier.length !== 5 && vendorIdentifier.length !== 12) {
      errorAlert(trans('uei-cage-length-error'))
      return
    }

    claimVendor()
  }

  const schema = useScheme()
  const formik = useForm(
    schema,
    schemaToInitialState(schema, {
      vendorIdentifier: featuredVendor?.ueiCode ?? '',
    }),
    onSubmit,
    {
      validateOnChange: false,
      validateOnBlur: false,
    }
  )

  const claimVendor = () => {
    const values = {
      ...formik.values,
    }

    setIsSend(true)
    updateClaimedVendor({ ...values, orgId: detail.id })
      .then(res => {
        if (res?.error) {
          throw res.error
        }

        successAlert('Claimed vendor updated!')
        setIsSend(false)
      })
      .catch(err => {
        setIsSend(false)
        const message = err.message.toLowerCase()
        if (ERROR_MESSAGES.includes(message)) {
          errorAlert(trans(message))
        }
      })
  }

  const executeSubmit = () => {
    formik.handleSubmit()
  }

  return (
    <>
      <Box
        style={{
          borderTop: `1px solid rgba(78, 89, 120, 0.12)`,
          padding: '2rem 0 2rem 0',
        }}
      >
        <Grid container direction="row" justifyContent="flex-start" spacing={3}>
          <Grid item sm={6} xs={12}>
            <TextField
              label={trans('uei-cage-code')}
              placeholder={trans('optional')}
              {...formik.getFieldProps('vendorIdentifier')}
            />
          </Grid>
        </Grid>
      </Box>
      {isEnabled(permissions, 'organization', 'claimed-vendor', 'edit') && (
        <Box
          style={{
            borderTop: `1px solid rgba(78, 89, 120, 0.12)`,
            padding: '2rem 0 0.5rem 0',
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs zeroMinWidth>
              <Button
                variant="contained"
                style={{ background: '#17b471', color: '#fff' }}
                onClick={executeSubmit}
                pending={isSend}
              >
                {' '}
                {trans('claim-vendor')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default connect(VendorIdentifierCodeField)
