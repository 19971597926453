import React, { useEffect } from 'react'
import connect from './connect'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import Loader from '../../../../components/common/Loader'
import { Grid } from '@material-ui/core'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard/NothingFoundCardList'
import PaginationSmall from '../../../../components/common/Paginations/PaginationSmall'
import List from './components/List'
import Header from './components/Header'
import { emptyArray } from '../../../../utils/arrayUtils'
import { useNavigate } from 'react-router-dom'
import Card from '@material-ui/core/Card'

const RequestTemplateFiles = ({
  requestTemplateFiles,
  onChangePage,
  onChangeRowPerPage,
  onInit,
  requestTemplateId,
  getList,
}) => {
  const { items, count, pending, pagination } = requestTemplateFiles
  useEffect(() => {
    getList({
      requestTemplateId: requestTemplateId,
      text: '',
      order: ['createdAt', 'desc'],
      filters: {},
      pagination: { page: 1, perPage: 10 },
    })
  }, [])

  const [pag, setPag] = React.useState({
    page: 1,
    perPage: 10,
  })

  const handleChangePage = (newPage) => {
    setPag({ ...pag, page: newPage })
    getList({
      requestTemplateId: requestTemplateId,
      text: '',
      order: ['createdAt', 'desc'],
      filters: {},
      pagination: { page: newPage, perPage: 10 },
    })
  }

  return (
    <SpacingContainerBase>
      <Card>
        {pending && <Loader open={pending} />}
        {!emptyArray(items) ? (
          <Grid container spacing={2} direction="column">
            <Grid item xs zeroMinWidth>
              <Header requestTemplateId={requestTemplateId} />
              <List items={items} requestTemplateId={requestTemplateId} />
            </Grid>

            <Grid item xs zeroMinWidth style={{ padding: '20px 40px' }}>
              <PaginationSmall
                page={pag.page}
                perPage={pag.perPage}
                count={count}
                onChangePage={handleChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                pending={pending}
              />
            </Grid>
          </Grid>
        ) : !pending && emptyArray(items) ? (
          <Grid item xs zeroMinWidth>
            <Header requestTemplateId={requestTemplateId} />
            <NothingFoundCard />
          </Grid>
        ) : null}
      </Card>
    </SpacingContainerBase>
  )
}

export default connect(RequestTemplateFiles)
