import React, { useEffect, useState } from 'react'
import { SpacingContainer } from '../../components/common/Containers'
import { useTrans } from '../../services/i18n'
import { Grid } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import Header from './components/Header'
import connect from './connect'
import List from './components/List'
import { emptyArray } from '../../utils/arrayUtils'
import NothingFoundCard from '../../components/generics/Cards/NothingFoundCard/NothingFoundCardList'

const AdminRoles = ({ authUser, rolesList, getRolesList }) => {
  const { trans } = useTrans()
  const navigate = useNavigate()
  const [arrayRoles, setArrayRoles] = useState([])

  useEffect(() => {
    getRolesList()
  }, [])

  useEffect(() => {
    if (Array.isArray(rolesList)) {
      setArrayRoles(rolesList)
    }
  }, [rolesList])

  useEffect(() => {
    if (
      authUser &&
      (authUser.role === 'manager' || authUser.role === 'consulting')
    ) {
      navigate('/organizations')
    }
  }, [authUser])

  return (
    <SpacingContainer py={7} px={7}>
      <Header />
      {!emptyArray(
        arrayRoles.filter(q => !['defualt', 'admin'].includes(q.key))
      ) ? (
        <Grid container spacing={2} direction="column">
          <Grid item xs zeroMinWidth>
            <List />
          </Grid>
        </Grid>
      ) : emptyArray(
          arrayRoles.filter(q => !['defualt', 'admin'].includes(q.key))
        ) ? (
        <Grid item xs zeroMinWidth>
          <NothingFoundCard />
        </Grid>
      ) : null}
    </SpacingContainer>
  )
}

export default connect(AdminRoles)
