import Grid from '@material-ui/core/Grid'
import React from 'react'
import { useTrans } from '../../../services/i18n'
import Button from '../../common/Buttons/Button'
import { Paper, SpacingContainer } from '../../common/Containers'
import { ModalDialog } from '../../common/Dialogs/ModalDialog'
import Typography from '../../common/Typography'
import { IconRemove } from '../../svg/Icons/IconRemove'

export default function Modal({
  open,
  onConfirm,
  onClose,
  icon,
  children,
  pending = false,
  title = '',
  bodyTitle,
  bodySubTitle,
  bodyParagraph,
  cancelTitle = '',
  confirmTitle = ''
}) {
  const { trans } = useTrans()

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={title || trans('confirm')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" onClick={onClose}>
              {cancelTitle || trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={!onConfirm}
              pending={pending}
              onClick={onConfirm}
            >
              {confirmTitle || trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      {icon && (
        <SpacingContainer className="center" py={3}>
          {icon}
        </SpacingContainer>
      )}

      {bodyTitle && (
        <Typography
          variant="h2"
          align="center"
          weight="600"
          fontFamily="lexend"
          gutter="label"
        >
          {bodyTitle}
        </Typography>
      )}

      {bodySubTitle && (
        <Typography
          variant="body1"
          align="center"
          weight="600"
          fontFamily="manrope"
          gutter="label"
          style={{ color: '#4E5978' }}
        >
          {bodySubTitle}
        </Typography>
      )}

      {bodyParagraph && (
        <Typography
          variant="body1"
          align="center"
          weight="500"
          fontFamily="manrope"
        >
          {bodyParagraph}
        </Typography>
      )}

      {children && children }

    </ModalDialog>
  )
}
