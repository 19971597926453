import React, { useCallback, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import Button from '../../../components/common/Buttons/Button'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import DefaultHeader from '../../../components/generics/DefaultHeader'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import SectionScrollable from '../../../components/common/Containers/SectionScrollable'
import { TextField } from '../../../components/common/Inputs'
import CheckBox from '../../../components/common/Inputs/CheckBox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MDEditor from '@uiw/react-md-editor'
import { getInitialValues, useScheme } from './helpers'
import { useForm } from '../../../hooks/useForm'
import { useAlertContext } from '../../../contexts/AlertContext'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { emptyArray } from '../../../utils/arrayUtils'
import { intlDateFormat } from '../../../utils/dateUtils'
import Tag from '../../../views/Releases/Details/ReleaseDetails/Tag'
import { Divider } from '../../../components/common/Divider'
import { IconBack } from '../../../components/svg/Icons'
import ShowTooltip from '../../../views/TooltipsManagement/components/showTooltip'
const formOptions = {
  validateOnMount: false,
  validateOnChange: true,
  enableReinitialize: true,
  validateOnBlur: false,
}
const tags = ['Improvements', 'New Features', 'Fixes']

const ReleaseEditor = ({ item = {}, saveRelease }) => {
  const { trans } = useTrans()
  const schema = useScheme()
  let history = useNavigate()
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const [content, setContentDescription] = React.useState('')
  const [busy, setBusy] = useState(false)
  const [publishRelease, setPublishRelease] = useState(false)
  const [preview, setPreview] = useState(false)

  const executeSubmit = values => {
    console.log(publishRelease)
    const editting = !!values.id
    if (typeof saveRelease !== 'function') return
    setIsSend(true)
    setTimeout(() => {
      Promise.resolve(
        saveRelease({ ...values, publishRelease: publishRelease })
      )
        .then(res => {
          if (res.error && res.error.message) {
            errorAlert()
          } else {
            successAlert(
              editting
                ? `${values.title} has been updated`
                : `${values.title} has been added`
            )
            onDiscard()
          }
        })
        .catch(errorAlert)
        .finally(() => setIsSend(false))
    }, 1000)
  }

  const onSubmit = useCallback(
    values => {
      executeSubmit(values)
    },
    [infoAlert, errorAlert, successAlert, publishRelease]
  )

  const { getFieldProps, handleSubmit, values, resetForm, setFieldValue } =
    useForm(schema, getInitialValues(item), onSubmit, formOptions)

  const onDiscard = () => {
    resetForm()
    history('/releases')
  }

  const onChangeContent = e => {
    setContentDescription(e)
    setFieldValue('content', e)
  }

  const handleChangeTags = e => {
    const { checked, name } = e.target
    if (checked) {
      setFieldValue('tags', [...values.tags, name])
    } else {
      setFieldValue(
        'tags',
        values.tags.filter(v => v !== name)
      )
    }
  }

  const publish = () => {
    setPublishRelease(true)
    setBusy(true)
    handleSubmit()
  }

  const saveQuit = () => {
    if (!preview) {
      setPreview(!preview)
    } else {
      setPublishRelease(false)
      setBusy(true)
      handleSubmit()
    }
  }

  useEffect(() => {
    if (item.hasOwnProperty('content')) onChangeContent(item.content)
  }, [item])

  return (
    <>
      <SectionScrollable
        TopFixed={
          <DefaultHeader>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onDiscard}
                disabled={isSend}
              >
                {trans('discard-&-quit')}
              </Button>
            </Grid>
            <Grid item>
              <Typography
                weight="bold"
                variant="h6"
                color="inherit"
                style={{ alignItems: 'center', display: 'flex' }}
              >
                {values.id
                  ? trans('edit-release')
                  : trans('create-new-release')}{' '}
                <ShowTooltip sectionToShow="create-release" />
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="success"
                pending={isSend}
                onClick={saveQuit}
              >
                {preview ? trans('save-&-quit') : 'Preview'}
              </Button>
            </Grid>
          </DefaultHeader>
        }
      >
        <SpacingContainer px="35" py="5">
          {!preview ? (
            <>
              <Paper component={SpacingContainer} py={4} px="5sm3">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label={trans('title')}
                      placeholder={trans('required')}
                      required
                      maxLength={255}
                      {...getFieldProps('title')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      weight="500"
                      color="label"
                      gutter="label"
                      variant="body2"
                      fontFamily="lexend"
                    >
                      {trans('tags')}
                    </Typography>
                    {tags.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <CheckBox
                            color="secondary"
                            name={item}
                            id={`check-${index}`}
                            checked={values.tags.includes(item)}
                            onChange={handleChangeTags}
                          />
                        }
                        label={item}
                      />
                    ))}
                    {emptyArray(values.tags) && busy && (
                      <div style={{ marginTop: '5px' }}>
                        <Typography
                          style={{ fontSize: '0.75rem' }}
                          variant="body2"
                          color="error"
                        >
                          At least one tag is required
                        </Typography>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                style={{ margin: '30px 0 30px 0' }}
                component={SpacingContainer}
                py={4}
                px="5sm3"
              >
                <MDEditor
                  height={400}
                  autoFocus={false}
                  value={content}
                  onChange={onChangeContent}
                  preview="edit"
                  required
                />
                {busy && !content && (
                  <div style={{ marginTop: '5px' }}>
                    <Typography
                      style={{ fontSize: '0.75rem' }}
                      variant="body2"
                      color="error"
                    >
                      Required
                    </Typography>
                  </div>
                )}
              </Paper>
            </>
          ) : (
            <Paper
              component={SpacingContainer}
              py={4}
              px="5sm3"
              style={{ marginBottom: 30 }}
            >
              <Typography
                weight="bold"
                variant="body1"
                color="primary"
                onClick={() => setPreview(false)}
                style={{ cursor: 'pointer' }}
              >
                <IconBack />
                Release preview
              </Typography>
              <Divider light spacing={3} />
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={3}
              >
                <Grid item xs zeroMinWidth>
                  <Typography weight="bold" variant="h3">
                    {values.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {intlDateFormat(new Date())}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    {values.tags &&
                      !emptyArray(values.tags) &&
                      values.tags.map((item, index) => (
                        <Grid item key={index}>
                          <Tag tagName={item} />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MDEditor.Markdown source={values.content} />
                </Grid>
              </Grid>
            </Paper>
          )}

          {!values.id && (
            <Grid container spacing={3}>
              <Grid item xs style={{ textAlign: 'center' }}>
                <Button
                  onClick={() => publish()}
                  pending={isSend}
                  variant="contained"
                  size="large"
                  color="success"
                >
                  {trans('publish')}
                </Button>
              </Grid>
            </Grid>
          )}
        </SpacingContainer>
      </SectionScrollable>
    </>
  )
}

export default ReleaseEditor
