import { connect } from 'react-redux'
import { actions } from '../../store/usfcrStaff'
import { generateListCallbacks } from '../../store/helpers/listActionsHelpers'

function mapStateToProps(state) {
  return {
    users: state.usfcrStaff,
    userDetail: state.usfcrStaff.userDetail,
    authUser: state.auth,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    addUser: params => dispatch(actions.addUser(params)),
    editUser: params => dispatch(actions.editUser(params)),
    getUsersNoIsUsfcr: () => dispatch(actions.getUsersNoIsUsfcr({})),
    removeUserStaff: params => dispatch(actions.removeUserStaff(params)),
    activeUserStaff: params => dispatch(actions.activeUserStaff(params)),
    fetchList: () => dispatch(actions.fetchList()),
    findOne: params => dispatch(actions.findOne(params)),
    onAssignOrganizations: params =>
      dispatch(actions.onAssignOrganizations(params)),
    getAssignedOrganizations: params =>
      dispatch(actions.getAssignedOrganizations(params)),
    removeUserOrganization: params =>
      dispatch(actions.removeUserOrganization(params)),
    getActivityHistory: params => dispatch(actions.getActivityHistory(params)),
    disabledTwoFactorAuth: (params) => dispatch(actions.disabledTwoFactorAuth(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
