import { useState } from 'react'
import connect from '../connect'
import Typography from '../../../components/common/Typography'
import { intlDateFormat, formatPrettyUnixDate } from '../../../utils/dateUtils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { InlineContainer } from '../../../components/common/Containers'
import { Avatar } from '../../../components/common/Cards'
import IconButton from '@material-ui/core/IconButton'
import useBooleanHandlers from '../../../hooks/useBooleanHandlers'
import ResetPasswordModal from './ResetPasswordModal'
import { DATE_TIME_PRETTY_FORMAT } from '../../../constants'
import ConfirmDialog from '../../../components/generics/Confirm/Dialog'
import { useTrans } from '../../../services/i18n'
import { useAlertContext } from '../../../contexts/AlertContext'
import ListItemTextBase from '../../../components/common/Containers/Base/ListItemTextBase'
import {
  ContextMenuListItem,
  Popover,
} from '../../../components/common/Dialogs/Popover'
import usePopover from '../../../hooks/usePopover'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import OrganizationsCell from '../../../components/common/Table/OrganizationsCell'
import { RouterLink } from '../../../components/common/Buttons'
import { useSelector } from 'react-redux'
import { selectUserPermissions, isEnabled } from '../../../selectors'

const Item = ({ item, authUser, deleteUser, disabledTwoFactorAuth }) => {
  const { trans } = useTrans()
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const [openConfirmDelete, onConfirmDelete, onCloseConfirmDelete] =
    useBooleanHandlers()
  const [
    openConfirmDisabledTwoFactor,
    onConfirmDisabledTwoFactorAuth,
    onCloseConfirmDisabledTwoFactorAuth,
  ] = useBooleanHandlers()
  const { successAlert, errorAlert } = useAlertContext()
  const fullName =
    item.name !== 'Unassigned' ? `${item.name} ${item.familyName}` : ''

  const [pendingDelete, setPendingDelete] = useState(false)
  const [pendingDisabledTwoFactorAuth, setPendingDisabledTwoFactorAuth] =
    useState(false)
  let validationRole =
    (item.userName !== authUser.userName &&
      !(authUser.role == 'manager' || authUser.role == 'consulting')) ||
    authUser.email == item.email
  const onDelete = () => {
    setPendingDelete(true)
    deleteUser({ id: item.id })
      .then(res => {
        if (res && res.payload) {
          onCloseConfirmDelete()
          successAlert(`${fullName} has been removed`)
        } else {
          errorAlert(`${fullName} could not be removed`)
        }
      })
      .catch(err => {
        errorAlert(err.message)
      })
      .finally(() => {
        setPendingDelete(false)
      })
  }

  const handleDisabledTwoFactorAuth = () => {
    setPendingDisabledTwoFactorAuth(true)
    Promise.resolve(disabledTwoFactorAuth({ id: item.id }))
      .then(res => {
        if (res && res.payload) {
          onCloseConfirmDisabledTwoFactorAuth()
          successAlert(
            `${fullName} ${trans('has-bee-disabled-two-factor-authentication')}`
          )
        } else {
          errorAlert(
            `${fullName} ${trans(
              'could-not-be-disabled-two-factor-authentication'
            )}`
          )
        }
      })
      .catch(err => {
        errorAlert(err.message)
      })
      .finally(() => setPendingDisabledTwoFactorAuth(false))
  }

  const permissions = useSelector(selectUserPermissions)

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <InlineContainer spacing={1}>
            <Avatar
              variantAvatar="rounded"
              size={40}
              value={fullName}
              bgColor="gray-second"
            />
            <Typography
              weight="500"
              to={`/users/${item.id}`}
              variant="h6"
              component={RouterLink}
            >
              {fullName}
            </Typography>
          </InlineContainer>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {item.email}
          </Typography>
          {item.phone && (
            <Typography
              style={{ color: '#888fa9' }}
              variant="body2"
              fontFamily="lexend"
            >
              {item.phone}
            </Typography>
          )}
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {intlDateFormat(item.createdAt)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {item.lastLogin
              ? formatPrettyUnixDate(item.lastLogin, DATE_TIME_PRETTY_FORMAT)
              : ''}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <OrganizationsCell organizations={item.organizations} />
        </TableCell>
        {(isEnabled(permissions, 'registered-users', '', 'delete') ||
          isEnabled(permissions, 'registered-users', '', 'edit')) && (
          <TableCell align="right">
            <IconButton onClick={onOpenPopover}>
              <MoreHorizIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClosePopover}
      >
        {isEnabled(permissions, 'registered-users', '', 'edit') && (
          <ContextMenuListItem
            button
            onClick={onOpenModal}
            onClickCB={onClosePopover}
          >
            <ListItemTextBase>
              {trans('forgot-password-sentence')}
            </ListItemTextBase>
          </ContextMenuListItem>
        )}
        {isEnabled(permissions, 'registered-users', '', 'delete') && (
          <ContextMenuListItem
            button
            onClick={onConfirmDelete}
            onClickCB={onClosePopover}
          >
            <ListItemTextBase>{trans('delete-user')}</ListItemTextBase>
          </ContextMenuListItem>
        )}
        {!!item['2faActive'] &&
          isEnabled(permissions, 'registered-users', '', 'delete') && (
            <ContextMenuListItem
              button
              onClick={onConfirmDisabledTwoFactorAuth}
              onClickCB={onClosePopover}
            >
              <ListItemTextBase>{trans('disabled-2fa')}</ListItemTextBase>
            </ContextMenuListItem>
          )}
      </Popover>
      <ResetPasswordModal item={item} open={openModal} onClose={onCloseModal} />

      <ConfirmDialog
        open={openConfirmDelete}
        onClose={onCloseConfirmDelete}
        onConfirm={onDelete}
        pending={pendingDelete}
        title={`${trans('delete-user')} ${fullName}`}
        text={trans('confirm-delete-user')}
        note={
          'The following items will be deleted permanently: User settings, User data, Pipelines, Pursuits, Teaming opportunities, Uploaded documents, Forms, and Organizations in which this user has the role of "Owner". This could affect other users too as they will lose access to the aforementioned items if they were previously shared with the user that is about to be deleted. If you agree, click Confirm to delete this user from the platform.'
        }
      >
        <Typography color="hint" weight="bold">
          {fullName}
        </Typography>
        <Typography color="hint">{item.email}</Typography>
      </ConfirmDialog>

      <ConfirmDialog
        open={openConfirmDisabledTwoFactor}
        onClose={onCloseConfirmDisabledTwoFactorAuth}
        onConfirm={handleDisabledTwoFactorAuth}
        pending={pendingDisabledTwoFactorAuth}
        title={`${trans('disabled-2fa')} ${fullName}`}
        text={trans('confirm-disabled-2fa')}
      >
        <Typography color="hint" weight="bold">
          {fullName}
        </Typography>
        <Typography color="hint">{item.email}</Typography>
      </ConfirmDialog>
    </>
  )
}

export default connect(Item)
