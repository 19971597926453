import React from 'react';
import { simpleIconPropsTypes } from '../../propTypes';
import MyLocationIcon from '@material-ui/icons/MyLocation';

export const IconPursuits = (props) => {
  const { width = 22, height = 22 } = props;

  return <MyLocationIcon width={width} height={height} />;
};

IconPursuits.propTypes = simpleIconPropsTypes;
