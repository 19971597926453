import { useTrans } from '../../../services/i18n'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { InlineContainer } from '../../../components/common/Containers'
import Typography from '../../../components/common/Typography'
import { IconButton } from '@material-ui/core'
import EditIcon from '@mui/icons-material/Edit'
import connect from '../connect'
import React, { useEffect, useState } from 'react'
import useBooleanHandlers from '../../../hooks/useBooleanHandlers'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import { intlDateFormat } from '../../../utils/dateUtils'
import { makeStyles } from '@material-ui/core/styles'
import { useNavigate } from 'react-router-dom'
import ConfirmDialog from '../../../components/generics/Confirm/Dialog'
import { useAlertContext } from '../../../contexts/AlertContext'
import { IconDelete } from '../../../components/svg/Icons'

const List = ({ rolesList, deleteRole, getRolesList, setRoleToEdit }) => {
  const [openRemove, onOpenRemove, onCloseRemove] = useBooleanHandlers()
  const { trans } = useTrans()
  const [pendingRemove, setPendingRemove] = useState(false)
  const navigate = useNavigate()
  const [arrayRoles, setArrayRoles] = useState([])
  const [roleToDelete, setRoleToDelete] = useState('')
  const { errorAlert } = useAlertContext()

  useEffect(() => {
    if (Array.isArray(rolesList)) {
      setArrayRoles(rolesList)
    }
    setRoleToDelete('')
  }, [rolesList])

  const useStyles = makeStyles({
    tableText: {
      fontFamily: 'Manrope',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '19px',
    },
  })

  const textClasses = useStyles()

  const onDeleteTemplate = async () => {
    try {
      setPendingRemove(true)
      await deleteRole({ roleKey: roleToDelete })
      setPendingRemove(false)
      getRolesList()
      onCloseRemove()
    } catch (error) {
      setPendingRemove(false)
      onCloseRemove()
    }
  }

  const openDetail = role => {
    setRoleToEdit(role)
    navigate('/admin-portal-roles/' + role.key)
  }

  const noteStyles = {
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: '30px',
  }

  const textStyles = {
    marginTop: '40px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '28px',
    lineHeight: '35px',
  }

  const validaDelete = roleDelete => {
    if (!roleDelete.count > 0) {
      setRoleToDelete(roleDelete.key)
      onOpenRemove()
    } else {
      errorAlert(
        'This role cannot be deleted because it is being used by some users'
      )
    }
  }

  return (
    <>
      <TableContainer component={Paper} radius={9}>
        <SpacingContainer px="5sm3" py={2}>
          <Table style={{ minWidth: 1000 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ width: '20%' }}
                  className={textClasses.tableText}
                >
                  {trans('created')}
                </TableCell>
                <TableCell
                  align="left"
                  style={{ width: '35%' }}
                  className={textClasses.tableText}
                >
                  {trans('role')}
                </TableCell>
                <TableCell
                  align="left"
                  style={{ width: '35%' }}
                  className={textClasses.tableText}
                >
                  {trans('users-assigned')}
                </TableCell>
                <TableCell
                  align="right"
                  style={{ width: '10%' }}
                  className={textClasses.tableText}
                >
                  {trans('')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arrayRoles.length > 0 &&
                arrayRoles
                  .filter(q => !['defualt', 'admin'].includes(q.key))
                  .map(q => (
                    <TableRow key={q.key}>
                      <TableCell align="left" style={{ width: '20%' }}>
                        <InlineContainer>
                          <Typography className={textClasses.tableText}>
                            {intlDateFormat(q.createdAt)}
                          </Typography>
                        </InlineContainer>
                      </TableCell>
                      <TableCell align="left" style={{ width: '35%' }}>
                        <Typography className={textClasses.tableText}>
                          {q.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" style={{ width: '35%' }}>
                        <Typography className={textClasses.tableText}>
                          {q?.count || 0}
                        </Typography>
                      </TableCell>
                      <TableCell align="right" style={{ width: '10%' }}>
                        <IconButton onClick={() => openDetail(q)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => validaDelete(q)}>
                          <IconDelete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </SpacingContainer>
      </TableContainer>
      <ConfirmDialog
        open={openRemove}
        onClose={onCloseRemove}
        onConfirm={onDeleteTemplate}
        pending={pendingRemove}
        title={trans('remove-role')}
        hideIcon={true}
        note={trans('all-progress-will-be-lost')}
        noteStyles={noteStyles}
        textStyles={textStyles}
        confirmType={true}
        text={`${trans('delete-this-role')}?`}
      />
    </>
  )
}

export default connect(List)
