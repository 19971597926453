import React, { useEffect, useState } from 'react'
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase'
import DetailsAnchorsHeader from '../../components/generics/DetailsAnchorsHeader'
import SectionScrollable from '../../components/common/Containers/SectionScrollable'
import { V_REGISTERED_USER } from '../../utils/helpers'
import Loader from '../../components/common/Loader'
import connect from './connect'
import { useParams } from 'react-router-dom'
import SavedSearches from './SavedSearches/SavedSearches'
import { useSelector } from 'react-redux'
import { selectUserPermissions, isEnabled } from '../../selectors'

const RegisteredUserDetail = ({ userDetail, findOne }) => {
  const params = useParams()
  const [pending, setPending] = useState(false)
  const [user, setUser] = useState(null)
  const permissions = useSelector(selectUserPermissions)
  const getUser = () => {
    setPending(true)
    findOne({ id: params.id }).then(res => {
      console.log('From back ', res)
      setPending(false)
      setUser(res.payload)
    })
  }

  useEffect(() => {
    getUser(params.id)
  }, [params.id])

  const [savedSearchTab] = isEnabled(permissions, 'registered-users', [
    'saved-searches',
  ])

  return (
    <SectionScrollable
      TopFixed={
        <DetailsAnchorsHeader
          anchors={
            savedSearchTab?.view
              ? V_REGISTERED_USER
              : V_REGISTERED_USER.filter(item => item.id !== 'saved-searches')
          }
          backLink={`/users`}
          onPrint={undefined}
        />
      }
    >
      {pending && <Loader open={pending} />}
      {!pending && user && savedSearchTab?.view && (
        <SpacingContainerBase py={0}>
          <div id="saved-searches">
            <SavedSearches />
          </div>
        </SpacingContainerBase>
      )}
    </SectionScrollable>
  )
}

export default connect(RegisteredUserDetail)
