import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { useTrans } from '../../../services/i18n'
import { IconStar } from '../../../components/svg'

const Label = styled(Typography)`
  color: #888fa9;
  font-family: Manrope;
  font-size: 12px;
  font-weight: 400;
`

const CodeWrapper = styled.div`
  display: flex;
  padding: 6px 12px 6px 6px;
  align-items: center;
  gap: 2px;
  border-radius: 2px;
  background: ${props =>
    props.primary ? 'rgba(23, 180, 113, 0.08)' : '#F1F3F9'};
  color: ${props => (props.primary ? 'var(--Green, #17B471)' : '#2445A7')};
  font-family: Manrope;
  font-size: 12px;
  font-weight: 600;
`

function CodeSection({ title, codes, primaryIndex, hideLabel }) {
  if (!codes) return <></>
  return (
    <Grid item xs={6}>
      {!hideLabel && <Label>{title}</Label>}
      <Grid container spacing={1}>
        {codes.map((code, index) => (
          <Grid item key={index}>
            <CodeWrapper primary={index === primaryIndex}>
              {primaryIndex !== undefined && index === primaryIndex && (
                <IconStar width={16} height={16} />
              )}
              {code?.id}
            </CodeWrapper>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

function CodeSetAsideInfo({
  naicsCodes,
  setAsides,
  federalGrantPrograms,
  hideLabels,
}) {
  const { trans } = useTrans()
  const checkCodes = code => !!code

  return (
    <Grid container spacing={3}>
      {naicsCodes?.filter(checkCodes).length ? (
        <CodeSection
          hideLabel={hideLabels}
          title={trans('naics-codes')}
          codes={naicsCodes}
          primaryIndex={0}
        />
      ) : (
        <></>
      )}
      {setAsides?.filter(checkCodes).length ? (
        <CodeSection
          hideLabel={hideLabels}
          title={trans('set-asides')}
          codes={setAsides}
        />
      ) : (
        <></>
      )}
      {federalGrantPrograms?.filter(checkCodes).length ? (
        <CodeSection
          hideLabel={hideLabels}
          title={trans('federal-grant-programs')}
          codes={federalGrantPrograms}
        />
      ) : (
        <></>
      )}
    </Grid>
  )
}

export default CodeSetAsideInfo
