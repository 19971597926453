import { createReducer, createAsyncThunk } from '@reduxjs/toolkit'
import { API, SCOPE_PENDING_ORG_AVATAR } from '../../constants'
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common'
import {
  extendListBuilder,
  generateListActions,
} from '../helpers/listActionsHelpers'
import { application } from '../../services/application'

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  loading: false,
}

const updateAvatarStatus = createAsyncThunk(
  'clients/changeAvatarStatus',
  params => {
    return application.call(API.CLIENTS.CHANGE_AVATAR_STATUS, params)
  }
)
function onUpdateAvatarStatus(state, action) {
  Object.assign(state, { loading: false })
}

//Actions
const listActions = generateListActions({
  scope: SCOPE_PENDING_ORG_AVATAR,
  apiMethod: {
    GET_LIST: API.CLIENTS.GET_PENDING_AVATAR,
  },
  getStore: store => store.pendingAvatars,
})

export const actions = {
  ...listActions,
  updateAvatarStatus,
}

export default createReducer(INITIAL_STATE, builder => {
  return extendListBuilder(builder, actions)
    .addCase(updateAvatarStatus.fulfilled, onUpdateAvatarStatus)
    .addCase(updateAvatarStatus.pending, state =>
      Object.assign(state, { loading: true })
    )
})
