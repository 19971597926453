import React from 'react'
import Typography from '../common/Typography'
import { Grid, Paper } from '@material-ui/core'
import { useStyles } from './styles'
import { useTrans } from '../../services/i18n'
import { ColoredLogoSvg, LogoSvg } from '../svg/logos'

const AuthLayout = ({ actions, children }) => {
  const classes = useStyles()
  const { trans } = useTrans()

  return (
    <Grid container spacing={0} direction="row" className={classes.height}>
      <Grid item xs={12} className={classes.imageBlock}>
        <Paper className={classes.media}>
          <div className={classes.authHeader}>
            <div className={classes.logos}>
              <LogoSvg />
            </div>
          </div>
          <div
            style={{
              background: 'white',
              width: 550,
              margin: '0 auto',
              borderRadius: '40px',
            }}
          >
            {children}
          </div>
          <div style={{ textAlign: 'center' }}>
            <Typography variant="body2" component="p">
              © {trans('copyright')} - 2022 US Federal Contractor Registration -
              All Rights Reserved.
            </Typography>
          </div>
        </Paper>
      </Grid>
      <Grid className={classes.mobileHeader} item xs={12}>
        <ColoredLogoSvg />
        {false && <div>{actions}</div>}
      </Grid>
      <div item sm={12} xs={12} className={classes.content}>
        {children}
      </div>
      <div className={classes.mobileFooter}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="body2" component="p">
            © {trans('copyright')} - 2022 US Federal Contractor Registration -
            All Rights Reserved.
          </Typography>
        </div>
      </div>
    </Grid>
  )
}

export default AuthLayout
