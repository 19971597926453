import React from 'react';
import { simpleIconPropsTypes } from '../../propTypes';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';

export const IconForms = (props) => {
  const { width = 25, height = 25 } = props;

  return <AssignmentIcon width={width} height={height} />;
};

IconForms.propTypes = simpleIconPropsTypes;
