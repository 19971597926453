import React from 'react'
import { intlDateFormat } from '../../../../../utils/dateUtils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { useTrans } from '../../../../../services/i18n'
import DownloadButton from '../../../components/DownloadButton'
import FolderIcon from '@material-ui/icons/Folder'
import { StyledTypography } from './StyledComponents'
import { useSelectedDocumentCategory } from '../../../../../contexts/SelectedDocumentCategoryContext'

const Item = ({ category, downloadOrganizationFiles, organization }) => {
  const { trans } = useTrans()
  const { setSelectedDocumentCategory, setSelectedPath } =
    useSelectedDocumentCategory()

  const onDownloadAll = e => {
    return downloadOrganizationFiles({
      organizationId: organization.id,
      category: category.name,
      filename: `${organization.orgName}-${category.name}.zip`,
    })
  }

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <StyledTypography
            variant="inherit"
            fontFamily="manrope"
            noWrap
            cursor={'pointer'}
            onClick={() => {
              setSelectedDocumentCategory(category.name)
              setSelectedPath(prev => ({
                ...prev,
                index: -1,
                path: '',
              }))
            }}
          >
            <FolderIcon style={{ color: '#b2b8cf' }} />
            {category.name.replace('/Default', ' (Default)')}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography
            variant="inherit"
            fontFamily="manrope"
            noWrap
            color={'#000'}
          >
            {trans('Folder')}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography
            variant="inherit"
            fontFamily="manrope"
            noWrap
            color={'#000'}
          >
            {intlDateFormat(category.lastUpdated)}
          </StyledTypography>
        </TableCell>

        <TableCell align="left">
          {category.fileRequests.filter(fr => fr.status !== 'In Progress')
            .length > 0 ? (
            <DownloadButton
              item={category}
              onDownload={onDownloadAll}
              color={'#4E5978'}
            />
          ) : null}
        </TableCell>
      </TableRow>
    </>
  )
}

export default Item
