import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import { ColoredLogoSvg, IconMenu, IconMenuControl } from '../../../svg'
import useStyles from '../styles'

const AppHeader = ({ setDrawerOpen, openDrawer, setDrawerClose }) => {
  const styles = useStyles()

  return (
    <>
      <div className={styles.appHeader}>
        <IconButton
          disableFocusRipple
          disableRipple
          className={styles.btnToggle}
          edge="start"
          color="inherit"
          onClick={openDrawer ? setDrawerClose : setDrawerOpen}
        >
          <IconMenuControl rotate={openDrawer} />
        </IconButton>
        <div className={styles.mobileAppLogo}>
          <ColoredLogoSvg />
        </div>
        <div className={styles.searchDiv}></div>
        <div className={styles.rightMenu}>
          <IconButton
            disableFocusRipple
            disableRipple
            className={styles.mobileToggle}
            edge="start"
            color="inherit"
            onClick={openDrawer ? setDrawerClose : setDrawerOpen}
          >
            <IconMenu open={!openDrawer} />
          </IconButton>
        </div>
      </div>
    </>
  )
}

export default AppHeader
