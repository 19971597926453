import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import { Button } from '../../../../components/common/Buttons'
import { ModalDialog } from '../../../../components/common/Dialogs'
import { useTrans } from '../../../../services/i18n'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { SpacingContainer } from '../../../../components/common/Containers'
import CheckBox from '../../../../components/common/Inputs/CheckBox'
import connect from '../../connect'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { emptyArray } from '../../../../utils/arrayUtils'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard'

const SetupTemplatesModal = ({
  formstackForms,
  getDefaultSAMRequests,
  defaultSAMRequests,
  changeDefaultSAMRequests,
  open,
  onClose,
  saveDefaultSAMRequests,
}) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = useState(false)
  const [defaultSAMRequestsList, setDefaultSAMRequestsList] = useState([])

  const closeModal = () => {
    setDefaultSAMRequestsList(defaultSAMRequests)
    setIsSend(false)
    onClose()
  }

  const saveDefaultSAMRequestsList = () => {
    setIsSend(true)
    saveDefaultSAMRequests(defaultSAMRequestsList.filter(i => {return i.checked === true}))
      .then(() => {
        successAlert('Default SAM  forms updated!')
        getDefaultSAMRequests()
        closeModal()
      })
      .catch(() => {
        errorAlert('Error')
        closeModal()
      })
  }

  const changeCheckTemplate = (ev, formId) => {
    let defaultSAMRequestsCopy = []
    defaultSAMRequestsCopy = JSON.parse(JSON.stringify(defaultSAMRequestsList))
    const index = defaultSAMRequestsCopy.findIndex(i => i.id === formId)
    defaultSAMRequestsCopy[index].checked = ev.target.checked
    setDefaultSAMRequestsList(defaultSAMRequestsCopy)
  }

  const selectUnselectAll = active => {
    let defaultSAMRequestsCopy = []
    defaultSAMRequestsCopy = JSON.parse(JSON.stringify(defaultSAMRequestsList))
    defaultSAMRequestsCopy = defaultSAMRequestsCopy.map(f => {
      return { ...f, checked: active }
    })
    setDefaultSAMRequestsList(defaultSAMRequestsCopy)
  }

  useEffect(() => {
    setDefaultSAMRequestsList(defaultSAMRequests)
  }, [defaultSAMRequests])

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={trans('select-request-templates')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSend}
              onClick={closeModal}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={() => saveDefaultSAMRequestsList()}
            >
              {trans('save')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer px={3} py={0}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <div>
            <Button
              color="primary"
              variant="text"
              onClick={() => selectUnselectAll(true)}
            >
              {trans('select-all')}
            </Button>
            <Button
              color="primary"
              variant="text"
              onClick={() => selectUnselectAll(false)}
            >
              {trans('unselect-all')}
            </Button>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          
        </div>

        {!emptyArray(defaultSAMRequestsList) && (
          <div style={{ height: '300px' }}>
            {defaultSAMRequestsList.map(i => (
              <div style={{ padding: '5px 0' }}>
                <FormControlLabel
                  key={i.id}
                  control={
                    <CheckBox
                      color="secondary"
                      checked={i.checked}
                      onChange={ev => changeCheckTemplate(ev, i.id)}
                    />
                  }
                  label={i.name}
                />
              </div>
            ))}
          </div>
        )}

        {emptyArray(defaultSAMRequestsList) && (
          <div
            style={{ display: 'flex', alignItems: 'center', height: '300px' }}
          >
            <NothingFoundCard
              title={trans('no-templates-found')}
              description={trans('no-templates-found-description')}
              size="medium"
            />
          </div>
        )}
      </SpacingContainer>
    </ModalDialog>
  )
}

export default connect(SetupTemplatesModal)
