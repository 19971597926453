import Grid from '@material-ui/core/Grid'
import React, { useState } from 'react'
import { Button } from '../../../../components/common/Buttons'
import { ModalDialog } from '../../../../components/common/Dialogs'
import { useTrans } from '../../../../services/i18n'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { SpacingContainer } from '../../../../components/common/Containers'
import { Autocomplete } from '../../../../components/common/Inputs'
import connect from '../connect'
import { formatToTitleCase } from '../../../../utils/helpers'

const AddAssignedStaffModal = ({
  users,
  open,
  onClose,
  saveUserUsfcr,
  detail,
}) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const [selectedUsers, setUser] = useState([])
  const [busy, setBusy] = useState(false)

  const closeModal = () => {
    setUser([])
    setBusy(false)
    setIsSend(false)
    onClose()
  }

  const changeSelect = ev => setUser(ev.target.value)

  const saveUserUsfcrAction = () => {

    const usersLength = selectedUsers.length

    setBusy(true)
    if (!usersLength) return
    setIsSend(true)

    let messageSuccess = `${selectedUsers[0].name} has been assigned to this organization`
    if (usersLength > 1)
      messageSuccess = `${usersLength} users have been assigned to this organization`

    saveUserUsfcr({
      orgId: detail.id,
      selectedUsers: selectedUsers.map(i => i.userName),
    })
      .then(() => {
        successAlert(messageSuccess)
        closeModal()
      })
      .catch(() => {
        errorAlert('Error')
        closeModal()
      })
  }

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={trans('assign-usfcr-staff')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSend}
              onClick={closeModal}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={() => saveUserUsfcrAction()}
            >
              {trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer px={3} py={3}>
        <Autocomplete
          options={users}
          required
          multiple
          label={trans('select-user')}
          placeholder={trans('required')}
          disableCloseOnSelect
          onChange={changeSelect}
          error={!selectedUsers.length && busy && trans('required')}
          getOptionFieldLabel={value => `${value.name} (${formatToTitleCase(value.role)})`}
        />
        {/*<SelectField
          type="select"
          placeholder={'Required'}
          name={'user'}
          value={user}
          getRenderValue={value => `${value.name} ${value.familyName}`}
          onChange={changeSelect}
          error={!user && busy && trans('required')}
        >
          {users.map(i => (
            <MenuItem value={i}>
              {i.name} {i.familyName}
            </MenuItem>
          ))}
        </SelectField>*/}
      </SpacingContainer>
    </ModalDialog>
  )
}

export default connect(AddAssignedStaffModal)
