import React from 'react';
import { simpleIconPropsTypes } from '../../propTypes';
import AdjustIcon from '@material-ui/icons/AdjustOutlined';

export const IconTeamingOpportunities = (props) => {
  const { width = 25, height = 25 } = props;

  return <AdjustIcon width={width} height={height} />;
};

IconTeamingOpportunities.propTypes = simpleIconPropsTypes;
