import React, { useCallback } from 'react';
import { IS_AFTER, IS_BEFORE, IS_GREATER, IS_LESS } from '../../../../../constants/filter';
import { FromChipGroup } from './FromChipGroup';
import { ToChipGroup } from './ToChipGroup';

export const BetweenChipGroup = ({ item, onChangeFilter, onOpenFilter }) => {
  const handleDeleteFromFilter = useCallback(
    () =>
      onChangeFilter(
        item.key,
        typeof item.to === 'number' ? { data: item.to, type: IS_LESS } : { to: item.to, type: IS_AFTER }
      ),
    [item, onChangeFilter]
  );
  const handleDeleteToFilter = useCallback(
    () =>
      onChangeFilter(
        item.key,
        typeof item.from === 'number' ? { data: item.from, type: IS_GREATER } : { from: item.from, type: IS_BEFORE }
      ),
    [item, onChangeFilter]
  );

  return (
    <>
      <FromChipGroup item={item} onDelete={handleDeleteFromFilter} onOpenFilter={onOpenFilter} />
      <ToChipGroup item={item} onDelete={handleDeleteToFilter} onOpenFilter={onOpenFilter} />
    </>
  );
};
