import React from 'react'
import { SvgIcon } from '../../../common/SvgIcon'
import { simpleIconPropsTypes } from '../../propTypes'

export const IconUSFCRStaff = props => {
  const { width = 24, height = 24, fill } = props

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        id="Shape"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM12 2.75C12.22 2.75 12.41 2.85 12.55 3C12.67 3.13 12.75 3.31 12.75 3.5C12.75 3.91 12.41 4.25 12 4.25C11.59 4.25 11.25 3.91 11.25 3.5C11.25 3.31 11.33 3.13 11.45 3C11.59 2.85 11.78 2.75 12 2.75ZM4 5V21H20V5H4ZM12 7C10.35 7 9 8.35 9 10C9 11.65 10.35 13 12 13C13.65 13 15 11.65 15 10C15 8.35 13.65 7 12 7ZM11 10C11 10.55 11.45 11 12 11C12.55 11 13 10.55 13 10C13 9.45 12.55 9 12 9C11.45 9 11 9.45 11 10ZM6 17.47V19H18V17.47C18 14.97 14.03 13.89 12 13.89C9.97 13.89 6 14.96 6 17.47ZM12 15.88C10.69 15.88 9 16.44 8.31 17H15.69C15.01 16.44 13.31 15.88 12 15.88Z"
        fill={fill}
      />
    </SvgIcon>
  )
}

IconUSFCRStaff.propTypes = simpleIconPropsTypes
