import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQuery() {
  const location = useLocation();

  const { query, searchParams } = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = Object.fromEntries(searchParams.entries());

    return { query, searchParams };
  }, [location.search]);

  return { query, searchParams };
}
