import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconSearch = (props) => {
  const { width, height, notFound = false } = props;

  return notFound ? (
    <SvgIcon width={width || 64} height={height || 64} viewBox="0 0 64 64">
      <path
        d="M132 0c14.912 0 27 12.088 27 27 0 7.096-2.737 13.552-7.213 18.371l16.92 16.922c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-16.922-16.92C145.552 51.263 139.096 54 132 54c-14.912 0-27-12.088-27-27s12.088-27 27-27zm0 2c-13.807 0-25 11.193-25 25s11.193 25 25 25 25-11.193 25-25-11.193-25-25-25zm-5.387 18.21l.094.083L132 25.585l5.293-5.292c.39-.39 1.024-.39 1.414 0 .36.36.388.928.083 1.32l-.083.094L133.415 27l5.292 5.293c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083L132 28.415l-5.293 5.292c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094L130.585 27l-5.292-5.293c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083z"
        transform="translate(-838 -446) translate(380 146) translate(0 220) translate(353 80)"
      />
    </SvgIcon>
  ) : (
    <SvgIcon width={width || 24} height={height || 24} viewBox="0 0 24 24">
      <path
        d="M11 2c4.97 0 9 4.03 9 9 0 2.125-.736 4.078-1.968 5.617l3.675 3.676c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-3.676-3.675C15.077 19.264 13.125 20 11 20c-4.97 0-9-4.03-9-9s4.03-9 9-9zm0 2c-3.866 0-7 3.134-7 7s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7z"
        transform="translate(-410 -28) translate(300) translate(110 28)"
      />
    </SvgIcon>
  );
};

IconSearch.propTypes = simpleIconPropsTypes;
