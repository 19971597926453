import { Button } from '../../../../components/common/Buttons'
import { DrawerDialog } from '../../../../components/common/Dialogs/DrawerDialog'
import QuickViewLayout from '../../../../components/layouts/QuickViewLayout'
import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import { Grid, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { useCallback,  useState } from 'react'
import ColorPicker from './colorPicker'
import { Divider } from '../../../../components/common/Divider'
import FileUpload from './fileUpload'
import PreviewFile from './previewFile'
import { ColoredLogoSvg } from '../../../../components/svg/logos'
import PreviewMenu from './previewMenu'
import TopBar from './topBar'
import connect from './connect'
import { successAlert } from '../../../../contexts/AlertContext'
import { useSelector } from 'react-redux'
import { selectUserPermissions, isEnabled } from '../../../../selectors'

const uploadFileToS3 = async (url, file) => {
  try {
    const requestOptions = {
      method: 'PUT',
      body: file,
    }
    await fetch(url, requestOptions).catch(console.warn)
  } catch (e) {
    console.warn(e)
  }
}

const CustomizationModal = ({
  open,
  set,
  detail,
  saveCustomization,
  getAvatarUploadUrl,
}) => {
  const { trans } = useTrans()
  const permissions = useSelector(selectUserPermissions)

  const [firstColor, setFirstColor] = useState(
    detail?.colorsBranding?.firstColor
      ? detail?.colorsBranding?.firstColor
      : '#2445a7'
  )
  const [secondColor, setSecondColor] = useState(
    detail?.colorsBranding?.secondColor
      ? detail?.colorsBranding?.secondColor
      : '#f32445'
  )
  const [thirdColor, setThirdColor] = useState(
    detail?.colorsBranding?.thirdColor
      ? detail?.colorsBranding?.thirdColor
      : '#4e5978'
  )
  const [fourthColor, setFourthColor] = useState(
    detail?.colorsBranding?.fourthColor
      ? detail?.colorsBranding?.fourthColor
      : '#888fa9'
  )
  const [fifthColor, setFifthColor] = useState(
    detail?.colorsBranding?.fifthColor
      ? detail?.colorsBranding?.fifthColor
      : '#ffffff'
  )
  const [file, setFile] = useState([])
  const [linkFile, setLinkFile] = useState()
  const [uploadedFile, setUploadedFile] = useState()
  const uploadPhoto = useCallback(() => {
    return getAvatarUploadUrl(file.name)
      .then(async value => {
        await uploadFileToS3(value.url, file)
        setUploadedFile(value.id)
      })
      .catch(e => {
        console.warn(e)
      })
  })
  const onSubmit = () => {
    if (linkFile != undefined) {
      uploadPhoto()
      saveCustomization({
        orgId: detail.id,
        colorsBranding: {
          firstColor,
          secondColor,
          thirdColor,
          fourthColor,
          fifthColor,
        },
        nameImageBranding: uploadedFile,
      }).then(res => successAlert('Personalization successfully saved'))
    } else {
      saveCustomization({
        orgId: detail.id,
        colorsBranding: {
          firstColor,
          secondColor,
          thirdColor,
          fourthColor,
          fifthColor,
        },
      }).then(res => successAlert('Personalization successfully saved'))
    }
  }
  return (
    <DrawerDialog
      open={open}
      maxWidth="100%"
      transitionDuration={400}
      anchor="bottom"
    >
      <QuickViewLayout
        title={<ColoredLogoSvg />}
        onBackClick={() => set(false)}
      >
        <Paper component={SpacingContainer} py={3}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 15 }}
          >
            <Grid item>
              <Typography variant="h1" weight="800">
                {trans('start-customizing-the-platform')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                weight={'500'}
                variant="body1"
                color="hint"
                style={{ marginBottom: 3 }}
              >
                {detail.orgName}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ marginTop: 15 }}
            spacing={3}
          >
            <Grid item>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography variant="h5" weight="800">
                    Customization Preview
                  </Typography>
                </Grid>
                <Divider light spacing={3} />
                <Grid container spacing={3}>
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <PreviewFile linkFile={linkFile} />
                      </Grid>
                    </Grid>
                    <Grid item style={{ marginTop: '10px' }}>
                      <Button
                        variant="outlined"
                        color={'primary'}
                        onClick={() => setFile([])}
                      >
                        Change
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <FileUpload
                      file={file}
                      setFile={setFile}
                      setLinkFile={setLinkFile}
                    />
                  </Grid>
                </Grid>
                <Divider light spacing={3} />
                <Grid container>
                  <Grid item xs={10}>
                    <Typography variant="h6" weight="800">
                      {trans('change-styles')}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="outlined"
                      color={'primary'}
                      onClick={() => (
                        setFirstColor('#2445a7'),
                        setSecondColor('#f32445'),
                        setThirdColor('#4e5978'),
                        setFourthColor('#888fa9'),
                        setFifthColor('#FFFFFF'),
                        setFile([]),
                        setLinkFile(undefined)
                      )}
                    >
                      <Typography variant="h6" weight="800">
                        {trans('reset')}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ borderBottom: 'none' }}>
                          <ColorPicker
                            color={firstColor}
                            setColor={setFirstColor}
                            label={'Color #1'}
                          />
                        </TableCell>
                        <TableCell style={{ borderBottom: 'none' }}>
                          <ColorPicker
                            color={secondColor}
                            setColor={setSecondColor}
                            label={'Color #2'}
                          />
                        </TableCell>
                        <TableCell style={{ borderBottom: 'none' }}>
                          <ColorPicker
                            color={thirdColor}
                            setColor={setThirdColor}
                            label={'Color #3'}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ borderBottom: 'none' }}>
                          <ColorPicker
                            color={fourthColor}
                            setColor={setFourthColor}
                            label={'Color #4'}
                          />
                        </TableCell>
                        <TableCell style={{ borderBottom: 'none' }}>
                          <ColorPicker
                            color={fifthColor}
                            setColor={setFifthColor}
                            label={'Color #5'}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Divider light spacing={3} />
                <Grid container>
                  {isEnabled(
                    permissions,
                    'organization',
                    'customization',
                    'edit'
                  ) && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color={'primary'}
                        onClick={onSubmit}
                      >
                        <Typography variant="body2" weight="500">
                          {trans('save-changes')}
                        </Typography>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Divider light spacing={3} />
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12}>
                  <PreviewMenu
                    firstColor={firstColor}
                    secondColor={secondColor}
                    thirdColor={thirdColor}
                    fourthColor={fourthColor}
                    fifthColor={fifthColor}
                    linkFile={linkFile}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TopBar
                    firstColor={firstColor}
                    secondColor={secondColor}
                    thirdColor={thirdColor}
                    fourthColor={fourthColor}
                    fifthColor={fifthColor}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </QuickViewLayout>
    </DrawerDialog>
  )
}
export default connect(CustomizationModal)
