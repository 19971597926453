import { Button } from '@material-ui/core'
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'
import React from 'react'
import Typography from '../../Typography'
import { ListItemTextBase } from '../../Containers'
import {
  ContextMenuListItem,
  Popover,
} from '../../Dialogs/Popover'
import { useTrans } from '../../../../services/i18n'
import usePopover from '../../../../hooks/usePopover'
import { RouterLink } from '../../Buttons'

const DropdownOrganizations = ({ items = [] }) => {
  const { trans } = useTrans()
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()

  return (
    !!items && (
      <>
        <Button variant="text" size="small" onClick={onOpenPopover}>
          <Typography
            fontFamily="lexend"
            color="primary"
            variant="h6"
            weight="500"
          >
            {items.length} {trans('organizations')}
            <ArrowDownIcon />
          </Typography>
        </Button>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClosePopover}
        >
          {items.map(org => (
            <ContextMenuListItem
              key={org.id}
              button
              onClick={undefined}
              onClickCB={onClosePopover}
              size="normal"
            >
              <ListItemTextBase>
                <Typography
                  weight="500"
                  variant="h6"
                  component={RouterLink}
                  to={`/organizations/${org.organizationId}`}
                  noWrap
                >
                  {org.orgName}
                </Typography>
              </ListItemTextBase>
            </ContextMenuListItem>
          ))}
        </Popover>
      </>
    )
  )
}

export default DropdownOrganizations
