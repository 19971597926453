import React from 'react'
import { ModalDialog } from '../../../../components/common/Dialogs'
import {
  WITHIN_THE_LAST,
  WITHIN_THE_NEXT,
  PARTIAL_FILTER_OPTIONS,
} from '../../../../constants/filter'
import { useForm } from '../../../../hooks/useForm'
import { useTrans } from '../../../../services/i18n'
import { formatFilter } from '../../../../utils/filterUtils'
import FilterModalDialogActions from './FilterModalDialogActions'
import { filterComponents } from './Filters'
import { useSearchContext } from '../context'

const FilterModalDialog = ({
  item,
  onClose,
  onFilterChange,
  onFiltersChange,
}) => {
  const { trans } = useTrans()
  const filterIsMultiple = !!item.multiple
  const {
    state: { filterOptions },
  } = useSearchContext()

  const filters = React.useMemo(
    () =>
      filterIsMultiple
        ? item.multiple.filters
            .map(filterValue => {
              return filterOptions.find(f => f && f.value === filterValue)
            })
            .filter(f => f)
        : [item],
    [item, filterOptions, filterIsMultiple]
  )

  const setFilters = React.useCallback(
    filters => {
      const filtersObj = Object.assign(
        {},
        ...Object.keys(filters).map(filterName => {
          if (!filters[filterName]) return { [filterName]: null }

          const values = filters[filterName]
          const { type } = values

          if (type === WITHIN_THE_LAST || type === WITHIN_THE_NEXT) {
            const { value } = formatFilter(values)
            values.from = value[0]
            values.to = value[1]
          }

          return {
            [filterName]: {
              ...values,
              data: PARTIAL_FILTER_OPTIONS.includes(type)
                ? values.text
                : values.data,
              type,
            },
          }
        })
      )

      onFiltersChange(filtersObj)
    },
    [onFiltersChange]
  )

  const onRemove = React.useCallback(() => {
    if (onFilterChange) {
      const filterValues = filters.reduce((acc, filter) => {
        acc[filter.value] = null

        return acc
      }, {})

      setFilters(filterValues)
    }
    return onClose && onClose()
  }, [onClose, onFilterChange, filters, setFilters])

  const onSubmit = values => {
    const filtersObj = {}

    filters?.forEach(f => {
      const filterValues = filterIsMultiple
        ? getMultipleFilterValues(f, values)
        : {}
      const removeFilter = filterIsMultiple ? !filterValues.show : false

      filtersObj[f.value] = !removeFilter
        ? filterIsMultiple
          ? filterValues
          : values
        : null
    })

    setFilters(filtersObj)

    return onClose && onClose()
  }

  const getMultipleDefaultValues = () => {
    return filters
      .map((f, k) => {
        return Object.keys(f.default).map(propKey => ({
          [`${f.value}_${propKey}`]: f.default[propKey],
        }))
      })
      .reduce((acc, val) => Object.assign(acc, ...val), {})
  }

  const formik = useForm(
    item.scheme && item.scheme(trans),
    Object.assign(
      {},
      filterIsMultiple ? getMultipleDefaultValues() : item.default,
      item.state
    ),
    onSubmit,
    {
      validateOnChange: false,
      validateOnBlur: false,
    }
  )

  const getMultipleFilterValues = React.useCallback(
    f => {
      const values = formik.values
      return Object.assign(
        {},
        ...Object.keys(values)
          .filter(valueKey => valueKey.startsWith(f.value))
          .map(valueKey => ({
            [valueKey.replace(`${f.value}_`, '')]: values[valueKey],
          }))
      )
    },
    [formik.values]
  )

  const FilterComponent = React.useMemo(
    () => filterComponents[item.component],
    [item]
  )

  if (!FilterComponent) return

  return (
    <ModalDialog
      open={Boolean(item)}
      onClose={onClose}
      title={`${trans('filter-by')} ${item ? trans(item.name) : ''}`}
      actions={
        <FilterModalDialogActions
          filterIsMultiple
          onRemoveClick={onRemove}
          onApplyClick={formik.handleSubmit}
          removeLabel={
            filterIsMultiple ? trans('remove-filters') : trans('remove-filter')
          }
        />
      }
    >
      <FilterComponent
        getFieldProps={formik.getFieldProps}
        data={item.values}
        values={formik.values}
        setValues={formik.setValues}
        autocomplete={item.autocomplete}
        options={item.options}
        getMultipleFilterValues={getMultipleFilterValues}
        filters={filters}
      />
    </ModalDialog>
  )
}

export default FilterModalDialog
