import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import React from 'react';
import Typography from '../../../common/Typography';
import useStyles from '../styles';

const AppHeader = ({ onBackClick, search, title, actions }) => {
  const styles = useStyles();

  return (
    <Grid container className={styles.appHeader}>
      <Grid container item md={4} xs={12} alignItems="center" wrap="nowrap">
        <IconButton onClick={onBackClick} disableFocusRipple disableRipple className={styles.headerBtn} color="inherit">
          <ChevronLeft />
        </IconButton>
        <Grid item xs={10}>
          <Typography align="center" fontFamily="lexend" variant="h3" weight="500">
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid item md={8} xs={12} className={styles.searchDiv}>
        {search}
      </Grid>
      {actions && (
        <Grid item xs={12} className={styles.actions}>
          {actions}
        </Grid>
      )}
    </Grid>
  );
};

export default AppHeader;
