import * as Yup from 'yup'
import makeValidationSchema from '../../../../hooks/makeValidationSchema'

export const initialValues = {
  menu: '',
  section: '',
  message: '',
}

export const getInitialValues = item => {
  return Object.assign({}, initialValues, item)
}

export const useScheme = makeValidationSchema(trans =>
  Yup.object().shape({
    menu: Yup.object().required(trans('required')),
    section: Yup.string().required(trans('required')),
    message: Yup.string().max(180).required(trans('required')),
  })
)
