import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { Button } from '../../../Buttons'
import Typography from '../../../Typography'

const TopBar = ({
  title,
  discardButtonText,
  onDiscardClick,
  successButtonText,
  onSuccessClick,
}) => {
  const styles = useStyles()

  return (
    <Grid
      container
      className={styles.header}
      alignItems="center"
      wrap="nowrap"
      justifyContent="space-between"
    >
      <Button color="primary" variant="outlined" onClick={onDiscardClick}>
        {discardButtonText}
      </Button>

      <Typography weight={500}>
        {title}
      </Typography>

      <Button color="success" variant="contained" onClick={onSuccessClick}>
        {successButtonText}
      </Button>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    zIndex: theme.zIndex.appBar + 1,
    position: 'relative',
    borderTop: `1px solid ${theme.palette.gray.divider}`,
    borderLeft: `1px solid ${theme.palette.gray.divider}`,
    height: 60,
    boxShadow: '0 10px 20px 0 rgba(78, 89, 120, 0.1)',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
    padding: '0 1rem',
  },
  // content: { height: '100%' },
}))

export default TopBar
