import React, { useEffect } from 'react'
import connect from './connect'
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase'
import Loader from '../../components/common/Loader'
import { Grid, Button } from '@material-ui/core'
import NothingFoundCard from '../../components/generics/Cards/NothingFoundCard'
import { RouterLink } from '../../components/common/Buttons'

import PaginationSmall from '../../components/common/Paginations/PaginationSmall'
import List from './components/List'
import Header from './components/Header'
import { emptyArray } from '../../utils/arrayUtils'
import { useNavigate } from 'react-router-dom'
import { useTrans } from '../../services/i18n'
import { ReactComponent as IconSvg } from '../../components/assests/combined-shape.svg'

const Releases = ({
  releases,
  onInit,
  onChangePage,
  onChangeRowPerPage,
  authUser,
}) => {
  const { items, count, pending, pagination } = releases
  const navigate = useNavigate()
  const { trans } = useTrans()

  useEffect(() => {
    onInit()
  }, [])

  useEffect(() => {
    if (authUser && (authUser.role === 'manager' || authUser.role == 'consulting')) {
      navigate('/organizations')
    }
  }, [authUser])

  return (
    <SpacingContainerBase>
      <Header />
      {pending && <Loader open={pending} />}
      {!pending && !emptyArray(items) && (
        <Grid container spacing={2} direction="column">
          <Grid item xs zeroMinWidth>
            <List items={items} />
          </Grid>

          <Grid item xs zeroMinWidth>
            <PaginationSmall
              page={pagination.page}
              perPage={pagination.perPage}
              count={count}
              onChangePage={onChangePage}
              onChangeRowPerPage={onChangeRowPerPage}
              pending={pending}
            />
          </Grid>
        </Grid>
      )}
      {!pending && emptyArray(items) && (
        <Grid item xs zeroMinWidth>
          <NothingFoundCard
            px={5}
            py={10}
            Icon={<IconSvg />}
            title={trans('no-releases-created-yet')}
            description={trans('no-releases-description')}
            size="medium"
          >
            <Button
              variant="contained"
              to={`/releases/new`}
              component={RouterLink}
              style={{ color: '#fff' }}
              color="primary"
            >
              {trans('add-release')}
            </Button>
          </NothingFoundCard>
        </Grid>
      )}
    </SpacingContainerBase>
  )
}

export default connect(Releases)
