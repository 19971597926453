import { Grid } from '@material-ui/core'
import { SpacingContainer } from '../../../components/common/Containers/Base'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import React, { useEffect } from 'react'
import connect from '../connect'
import useBooleanHandlers from '../../../hooks/useBooleanHandlers'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'
import { Button } from '../../../components/common/Buttons'
import AddRolModal from './AddRolModal'

const Header = ({}) => {
  const { trans } = useTrans()
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()

  useEffect(() => {}, [])
  return (
    <>
      <SpacingContainer py={3} px={0}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              weight="600"
              variant="h2"
              fontFamily="lexend"
              title
              style={{ alignItems: 'center', display: 'flex' }}
            >
              {trans('admin-portal-roles')}{' '}
              <ShowTooltip sectionToShow="admin-portal-roles-ta e" />
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={onOpenModal} variant="contained" color="primary">
              {trans('add-role')}
            </Button>
          </Grid>
        </Grid>
      </SpacingContainer>
      <AddRolModal open={openModal} onClose={onCloseModal} />
    </>
  )
}

export default connect(Header)
