import { connect } from 'react-redux';
import { actions } from '../../store/clients';
import { generateListCallbacks } from '../../store/helpers/listActionsHelpers';

function mapStateToProps(state) {
  return { 
    clients: state.clients,
    authUser: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
