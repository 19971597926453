import { Button, Hidden, makeStyles } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React from 'react';
import { useTrans } from '../../../services/i18n';
import Typography from '../../common/Typography';

const StepButtons = ({ onNext, onPrev }) => {
  const styles = useStyles();
  const { trans } = useTrans();

  return (
    <div className={styles.controlBtns}>
      <Button className={styles.controlLeft} disabled={!onPrev} onClick={onPrev}>
        <ChevronLeft />
        <Hidden mdUp>
          <Typography color="hint" weight="600">
            {trans('previous')}
          </Typography>
        </Hidden>
      </Button>
      <Button className={styles.controlRight} disabled={!onNext} onClick={onNext}>
        <Hidden mdUp>
          <Typography weight="600">{trans('next')}</Typography>
        </Hidden>
        <ChevronRight />
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  controlBtns: {
    position: 'absolute',
    bottom: 40,
    right: 40,
    boxShadow: '0 6px 18px 0 rgba(78, 89, 120, 0.16)',
    zIndex: theme.zIndex.mobileStepper,
    borderRadius: 26,
    height: 54,
    [theme.breakpoints.down('sm')]: {
      bottom: 0,
      right: 0,
      width: '100%',
      borderRadius: 0,
      position: 'relative',
    },
  },
  controlLeft: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 0,
    borderBottomLeftRadius: 26,
    borderTopLeftRadius: 26,
    height: 54,
    zIndex: 1000,
    '&:hover': {
      backgroundColor: theme.palette.gray.divider,
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      justifyContent: 'flex-start',
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      textTransform: 'uppercase',
    },
  },
  controlRight: {
    borderRadius: 0,
    height: 54,
    overflow: 'hidden',
    borderBottomRightRadius: 26,
    borderTopRightRadius: 26,
    backgroundColor: theme.palette.common.white,
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    borderLeftColor: theme.palette.gray.divider,
    zIndex: 1000,
    '&:hover': {
      backgroundColor: theme.palette.gray.divider,
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      justifyContent: 'flex-end',
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      textTransform: 'uppercase',
    },
  },
}));

export default StepButtons;
