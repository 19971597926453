import React from 'react'
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'
import clsx from 'clsx'
import { DropdownSelect } from '../../../components/common/Inputs'
import { useStyles } from './styles'
import { useTrans } from '../../../services/i18n'
import connect from '../connect'
import { ANY_OF_THOSE } from '../../../constants/filter'

const items = [
  { value: ['all'], i18nKey: 'all' },
  { value: ['pending', 'completed'], i18nKey: 'open' },
  { value: ['expired'], i18nKey: 'expired' },
  { value: ['paid'], i18nKey: 'paid', hideForGrants: true },
  { value: ['denied'], i18nKey: 'denied', hideForGrants: true },
]

function StatusDropdown({ isGrant, filters, onChangeFilters, onResetFilters }) {
  const { trans } = useTrans()
  const classes = useStyles()
  const selectedStatus = React.useMemo(() => {
    const filterValue = filters?.fields?.status?.value
    if (!filterValue) return items[0] // If no filter, default to all

    return items.find(item => item.value.includes(filterValue[0])) || items[0]
  }, [filters])

  const handleChange = React.useCallback(
    newSelected => {
      if (newSelected.value.includes('all')) {
        onChangeFilters({ status: undefined })
        return
      }

      onChangeFilters({
        status: {
          type: ANY_OF_THOSE,
          value: newSelected.value,
        },
      })
    },
    [onChangeFilters]
  )

  return (
    <DropdownSelect
      options={!isGrant ? items : items.filter(item => !item.hideForGrants)}
      onChange={handleChange}
      value={selectedStatus}
      label={trans('status')}
      className={clsx(classes.dropDownButton, 'mr-2')}
      Icon={<ArrowDownIcon />}
    />
  )
}

export default connect(StatusDropdown)
