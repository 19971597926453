import { SpacingContainer } from '../../../components/common/Containers/Base'
import { Grid, Button } from '@material-ui/core'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import ListInfoRow from '../../../components/generics/ListInfoRow'
import SearchTopPanel from '../../../components/generics/SearchTopPanel'
import { RouterLink } from '../../../components/common/Buttons'
import SortingPanel from '../../../components/generics/SortingPanel'
import React from 'react'
import connect from '../connect'
import { emptyArray } from '../../../utils/arrayUtils'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'
import { selectUserPermissions, isEnabled } from '../../../selectors'
import { useSelector } from 'react-redux'
const Header = ({ scope, releases, onChangeOrder, onChangeFilters }) => {
  const permissions = useSelector(selectUserPermissions)

  const { trans } = useTrans()
  const orderOptions = [
    {
      name: 'Publishing date',
      value: 'createdAt',
      asc: 'Oldest first',
      desc: 'Newest first',
    },
    {
      name: 'Modified date',
      value: 'updatedAt',
      asc: 'Oldest first',
      desc: 'Newest first',
    },
  ]
  const { filters, order, count, items } = releases
  return (
    <>
      <SpacingContainer py={3} px={0}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              weight="600"
              variant="h2"
              fontFamily="lexend"
              title
              style={{ alignItems: 'center', display: 'flex' }}
            >
              {trans('releases')} <ShowTooltip sectionToShow="releases-table" />
            </Typography>
          </Grid>
          <Grid item>
            {isEnabled(permissions, 'releases', '', 'create') && (
              <Button
                variant="contained"
                to={`/releases/new`}
                component={RouterLink}
                style={{ color: '#fff' }}
                color="primary"
              >
                {trans('add-release')}
              </Button>
            )}
          </Grid>
        </Grid>
      </SpacingContainer>

      <SearchTopPanel
        scope={scope}
        filters={filters}
        onChangeFilters={onChangeFilters}
        filterOptions={[]}
      />

      {!!items.length && (
        <ListInfoRow
          count={count}
          description={trans('results')}
          sort={
            <SortingPanel
              order={order}
              scope={scope}
              orderOptions={orderOptions}
              onChangeOrder={onChangeOrder}
            />
          }
        />
      )}
    </>
  )
}

export default connect(Header)
