import { connect } from 'react-redux'
import { actions } from '../../store/requestTemplates'
import { generateListCallbacks } from '../../store/helpers/listActionsHelpers'

function mapStateToProps(state) {
  return {
    requestTemplates: state.requestTemplates,
    requestTemplateDetail: state.requestTemplateDetail,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    fetchList: () => dispatch(actions.fetchList()),
    findOne: (params) => dispatch(actions.findOne(params)),
    saveRequestTemplate: (params) =>
      dispatch(actions.saveRequestTemplate(params)),
    deleteRequestTemplate: (params) =>
      dispatch(actions.deleteRequestTemplate(params)),
    getList: (params) => dispatch(actions.getList(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
