import Grid from '@material-ui/core/Grid'
import React, { useEffect, useCallback } from 'react'
import { useForm } from '../../../../../hooks/useForm'
import { useTrans } from '../../../../../services/i18n'
import { Button } from '../../../../../components/common/Buttons'
import { ModalDialog } from '../../../../../components/common/Dialogs'
import {
  TextField,
  DatePicker,
  SelectField,
} from '../../../../../components/common/Inputs'
import { useScheme, useSchemeGrant } from './helpers'
import { useAlertContext } from '../../../../../contexts/AlertContext'
import Typography from '../../../../../components/common/Typography'
import connect from '../../../connect'
import { NaicsCodeAutocomplete } from '../../../../../components/autocompletes/NaicsCodeAutocomplete'
import { PscAutocomplete } from '../../../../../components/autocompletes/PscAutocomplete'
import { SetAsideAutocomplete } from '../../../../../components/autocompletes/SetAsideAutocomplete'
import { TagsAutocomplete } from '../../../../../components/autocompletes/TagsAutocomplete'
import { GrantProgramsAutocomplete } from '../../../../../components/autocompletes/GrantProgramsAutocomplete'
import { OrganizationTypesAutocomplete } from '../../../../../components/autocompletes/OrganizationTypesAutocomplete'
import MenuItem from '@material-ui/core/MenuItem'

const formOptions = {
  validateOnMount: false,
  validateOnChange: true,
  enableReinitialize: true,
}

function EditModal({ open, onClose, updateOpportunity, item, fetchList }) {
  const { trans } = useTrans()
  const schema = useScheme()
  const grantSchema = useSchemeGrant()

  const { successAlert, errorAlert } = useAlertContext()
  const [state, setState] = React.useState({
    jobTypes: [
      { value: 'Grant', i18nKey: 'Grant' },
      { value: 'Contract', i18nKey: 'Contract' },
    ],
    jobType: 'Contract', // You'll need to determine how to get initialJobType
  })
  const tags = item?.tags
  // Determine which schema to use based on state.jobType
  const currentSchema = state.jobType === 'Grant' ? grantSchema : schema

  const onSubmit = useCallback(
    values => {
      console.log('🚀 ~ file: EditModal.js:52 ~ values:', values)

      saveOpportunity(values);
    },
    [onClose, updateOpportunity]
  )

  const initialValue = {
    ...item,
    keywords: item?.keywords?.map(item => ({
      id: item,
      value: item,
    })),
  }

  const {
    getFieldProps,
    handleSubmit,
    values,
    isSubmitting,
    resetForm,
    // setFieldValue,
  } = useForm(currentSchema, initialValue, onSubmit, formOptions)

  // Update jobType in state whenever values.jobType changes
  useEffect(() => {
    setState(prevState => ({ ...prevState, jobType: values.jobType }))
  }, [values.jobType])

  const closeEditModal = () => {
    resetForm()
    onClose()
  }

  const saveOpportunity = values => {
    try {
      const data = { ...values }
      updateOpportunity(data).then(res => {
        closeEditModal()
        fetchList()
        successAlert(trans('job-opportunity-saved'))
      })
    } catch (error) {
      console.log('🚀 ~ file: index.js:72 ~ saveOpportunity ~ error:', error)
      errorAlert(trans('failed-to-save-job-opportunity'))
    }
  }
  return (
    <ModalDialog
      open={open}
      PaperProps={{ style: { maxWidth: '730px' }, square: true }}
      onClose={() => closeEditModal()}
      title={
        values.id
          ? trans('edit-job-opportunity')
          : trans('create-job-opportunity')
      }
      actions={
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => closeEditModal()}
          >
            {trans('cancel')}
          </Button>
          <Button
            pending={isSubmitting}
            disabled={isSubmitting}
            style={{ marginLeft: '16px' }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            {isSubmitting
              ? trans(values.id ? 'Saving' : 'Creating')
              : trans(values.id ? 'save' : 'create')}
          </Button>
        </div>
      }
    >
      <Grid container spacing={3}>
        <Grid container style={{ padding: '0  12px 16px 12px' }}>
          <Grid container style={{ marginTop: '16px' }}>
            <Grid item xs={12}>
              <TextField
                label={trans('job-opportunity-title')}
                placeholder={trans('required')}
                required
                maxLength={80}
                {...getFieldProps('title')}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '15px' }} spacing={2}>
            <Grid item xs={4}>
              <Typography
                weight="500"
                fontFamily="lexend"
                color="label"
                variant="body2"
              >
                {trans('job-opportunity-type')}
              </Typography>
              <SelectField
                style={{ marginTop: '7px' }}
                type="select"
                {...getFieldProps('jobType')}
                placeholder={trans('required')}
                getRenderValue={item =>
                  item && state.jobTypes.find(i => i.value === item).i18nKey
                }
              >
                {state.jobTypes.map((item, ind) => (
                  <MenuItem key={ind} value={item.value}>
                    {trans(item.i18nKey)}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={trans('location')}
                placeholder={trans('Tampa Bay, FL')}
                required
                maxLength={80}
                {...getFieldProps('location')}
              />
            </Grid>
            <Grid item xs={4} style={{ paddingRight: '16px' }}>
              <DatePicker
                label={trans('response-by-date')}
                placeholder={trans('07/14/2023')}
                maxLength={80}
                disablePast
                disableToolbar
                {...getFieldProps('responseBy')}
              />
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: '16px' }}>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={5}
                tabIndex="2"
                label={trans('description')}
                placeholder={trans('Type job opportunity description here...')}
                required
                maxLength={500}
                {...getFieldProps('description')}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '16px' }}>
            <Grid item xs={12}>
              <TagsAutocomplete
                label={trans('keywords')}
                value={tags?.create}
                required
                placeholder={trans('add-keywords')}
                multi
                freeSolo={false}
                {...getFieldProps('keywords')}
              />
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: '15px' }} spacing={2}>
            <Grid item xs={6}>
              <TextField
                label={trans('opportunity-number')}
                placeholder={trans('required')}
                required
                maxLength={80}
                {...getFieldProps('opportunityNumber')}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label={
                  state.jobType === 'Grant'
                    ? trans('expiration')
                    : trans('expiration-date')
                }
                placeholder={trans('08/14/2023')}
                maxLength={80}
                disablePast
                disableToolbar
                {...getFieldProps('dueDate')}
              />
            </Grid>
          </Grid>
          {state.jobType !== 'Grant' && (
            <>
              <Grid container style={{ marginTop: '15px' }} spacing={2}>
                <Grid item xs={6}>
                  <NaicsCodeAutocomplete
                    label={trans('primary-naics')}
                    placeholder={trans('required')}
                    multi={false}
                    {...getFieldProps('primaryNaics')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PscAutocomplete
                    label={trans('primary-psc')}
                    placeholder={trans('required')}
                    multi={false}
                    {...getFieldProps('primaryPsc')}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '16px' }}>
                <Grid item xs={12} style={{ paddingTop: '16px' }}>
                  <NaicsCodeAutocomplete
                    label={trans('other-naics')}
                    placeholder={trans('add-naics-optional')}
                    multi
                    {...getFieldProps('naics')}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '16px' }}>
                <Grid item xs={12} style={{ paddingTop: '16px' }}>
                  <PscAutocomplete
                    label={trans('other-pscs')}
                    placeholder={trans('add-pscs-optional')}
                    multi
                    {...getFieldProps('otherPscs')}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '16px' }}>
                <Grid item xs={12} style={{ paddingTop: '16px' }}>
                  <SetAsideAutocomplete
                    label={trans('looking-for-set-asides')}
                    placeholder={trans('add-set-asides-optional')}
                    multi
                    {...getFieldProps('lookingForSetAsides')}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {state.jobType === 'Grant' && (
            <>
              <Grid container style={{ marginTop: '16px' }}>
                <Grid item xs={12} style={{ paddingTop: '16px' }}>
                  <GrantProgramsAutocomplete
                    label={trans('federal-grant-programs')}
                    placeholder={trans('add-federal-grant-programs')}
                    multi
                    {...getFieldProps('federalGrantPrograms')}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '16px' }}>
                <Grid item xs={12} style={{ paddingTop: '16px' }}>
                  <OrganizationTypesAutocomplete
                    label={trans('looking-for-organization-types')}
                    placeholder={trans('add-organization-types-optional')}
                    multi
                    {...getFieldProps('lookingForOrganizationTypes')}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '16px' }}>
                <Grid item xs={12}>
                  <TagsAutocomplete
                    label={trans('skill-sets-needed')}
                    value={tags?.create}
                    required
                    placeholder={trans('add-skill-sets-optional')}
                    multi
                    freeSolo={false}
                    {...getFieldProps('skillSetsNeeded')}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </ModalDialog>
  )
}

export default connect(EditModal)
