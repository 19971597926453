import * as Yup from 'yup'
import makeValidationSchema from '../../../../../../hooks/makeValidationSchema'

export const initialValues = {
  folderName: '',
}

export const getInitialValues = item => {
  return Object.assign({}, initialValues)
}

export const useScheme = makeValidationSchema(trans =>
  Yup.object().shape({
    folderName: Yup.string().required('Folder name is required'),
  })
)
