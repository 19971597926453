import { Button, makeStyles } from '@material-ui/core'
import React from 'react'
import Typography from '../../../components/common/Typography'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  active: {
    color: theme.palette.secondary.main,
  },
}))

function OpportunityTypeButton({ text, active, icon, onClick }) {
  const classes = useStyles()

  return (
    <Button
      className={clsx([classes.button, ...(active ? [classes.active] : [])])}
      onClick={onClick}
    >
      <Typography weight="600">
        {icon && icon({ style: { marginRight: '.25rem' } })}
        {text}
      </Typography>
    </Button>
  )
}

export default OpportunityTypeButton
