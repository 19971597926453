import Grid from '@material-ui/core/Grid';
import IconDown from '@material-ui/icons/KeyboardArrowDown';
import IconUp from '@material-ui/icons/KeyboardArrowUp';
import React, { useMemo } from 'react';
import { Button } from '../../../../components/common/Buttons';
import { Chip } from '../../../../components/common/Chip';
import SpacingContainer from '../../../../components/common/Containers/Base/SpacingContainer';
import Typography from '../../../../components/common/Typography';
import {
  ANY_OF_THOSE,
  DOES_NOT_EXISTS,
  EXISTS,
  IS_AFTER,
  IS_BEFORE,
  IS_BETWEEN,
  IS_GREATER,
  ONLY_TEXT,
  IS_LESS,
  NONE_OF_THOSE,
  WITHIN_THE_LAST,
  WITHIN_THE_NEXT,
} from '../../../../constants/filter';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import { useTrans } from '../../../../services/i18n';
import { emptyArray } from '../../../../utils/arrayUtils';
import { useSearchContext } from '../context';
import { BetweenChipGroup } from './Groups/BetweenChipGroup';
import { EmptyChipGroup } from './Groups/EmptyChipGroup';
import { FromChipGroup } from './Groups/FromChipGroup';
import { LessChipGroup } from './Groups/LessChipGroup';
import { ListChipGroup } from './Groups/ListChipGroup';
import { GreaterChipGroup } from './Groups/MoreChipGroup';
import { ToChipGroup } from './Groups/ToChipGroup';

export default function SelectedFilterslist({ onSelectFilter }) {
  const {
    state: { filters: storeFilters, filterOptions },
    onChangeFilters,
    onChangeFilter,
    onDeleteFilter,
    onResetFilters,
  } = useSearchContext();
  const { fields } = storeFilters;

  const { trans } = useTrans();
  const [open, onOpen, onClose] = useBooleanHandlers();

  const filterArray = useMemo(() => {
    if (!fields) return [];

    return Object.entries(fields).reduce((arrFilters, [key, valueShema]) => {
      if (!valueShema) return arrFilters;

      const data = valueShema.data ? valueShema.data : valueShema.value;
      const filterOption = filterOptions.find((f) => f && f.value === key) || {};
      arrFilters.push({ key, filterOption, data, ...valueShema });

      return arrFilters;
    }, []);
  }, [filterOptions, fields]);

  return emptyArray(filterArray) ? null : (
    <Grid container alignItems="center" spacing={2} component={SpacingContainer} py={1}>
      <Grid item>
        <Button
          id="selected-filters-list-arrow"
          isIcon
          size="small"
          variant="outlined"
          onClick={open ? onClose : onOpen}
        >
          {open ? <IconUp fontSize="small" /> : <IconDown fontSize="small" />}
        </Button>
      </Grid>

      {open ? (
        filterArray.map((item, ind) => (
          <React.Fragment key={ind}>
            {item.type === IS_BETWEEN || item.type === WITHIN_THE_NEXT || item.type === WITHIN_THE_LAST ? (
              <BetweenChipGroup item={item} onChangeFilter={onChangeFilter} onOpenFilter={onSelectFilter} />
            ) : null}
            {item.type === IS_AFTER ? (
              <FromChipGroup item={item} onDeleteFilter={onDeleteFilter} onOpenFilter={onSelectFilter} />
            ) : null}
            {item.type === IS_BEFORE ? (
              <ToChipGroup item={item} onDeleteFilter={onDeleteFilter} onOpenFilter={onSelectFilter} />
            ) : null}
            {item.type === IS_LESS ? (
              <LessChipGroup item={item} onDeleteFilter={onDeleteFilter} onOpenFilter={onSelectFilter} />
            ) : null}
            {item.type === IS_GREATER ? (
              <GreaterChipGroup item={item} onDeleteFilter={onDeleteFilter} onOpenFilter={onSelectFilter} />
            ) : null}
            {item.type === NONE_OF_THOSE || item.type === ANY_OF_THOSE || item.type === ONLY_TEXT ? (
              <ListChipGroup
                item={item}
                filters={fields}
                onChangeFilters={onChangeFilters}
                onOpenFilter={onSelectFilter}
              />
            ) : null}
            {item.type === DOES_NOT_EXISTS || item.type === EXISTS ? (
              <EmptyChipGroup item={item} onDeleteFilter={onDeleteFilter} onOpenFilter={onSelectFilter} />
            ) : null}
          </React.Fragment>
        ))
      ) : (
        <Grid item>
          <Chip
            label={
              <Typography variant="caption" color="primary" weight={600}>
                {`${filterArray.length} ${trans('ActiveFilters')}`}
              </Typography>
            }
            color="white"
            square
          />
        </Grid>
      )}

      <Grid item>
        <Button id="selected-filters-reset" size="small" variant="outlined" onClick={onResetFilters}>
          {trans('reset')}
        </Button>
      </Grid>
    </Grid>
  );
}
