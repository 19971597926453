import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { application } from '../../services/application';

const INITIAL_STATE = {};

export const signUp = createAsyncThunk('auth/signUp', async (params) => {
  return application.call('user.createUser', params);
});

function onSignUpFulfilled(state, action) {
  return { ...state, result: action.result };
}

export const login = createAction('auth/login');

function onLogin(state, action) {
  localStorage.setItem('authFormatDate', action.payload.formatDate ? action.payload.formatDate : 'MM/DD/YYYY');
  localStorage.setItem('authTimezone', action.payload.authTimezone);

  return Object.assign({}, action.payload);
}

export const update = createAction('auth/update');

function onUpdate(state, action) {
  return Object.assign(state, action.payload);
}

export const toggleTutorials = createAction('auth/toggleTutorials');

function onToggleTutorials(state, action) {
  return Object.assign(state, action.payload);
}

export const updateUserOrganizations = createAction('auth/updateUserOrganizations');
function onUserOrganizations(state, action) {
  const stateUser = Object.assign({}, state);
  const organizations = stateUser.organizations.map((org) => {
    if (org.organizationId === action.payload.organizationId) return { ...org, active: true };
    if (org.organizationId !== action.payload.organizationId) return { ...org, active: false };
  });
  stateUser.organizations = organizations;
  return Object.assign({}, stateUser);
}

export const authActions = {
  signUp,
  login,
  update,
  toggleTutorials,
  updateUserOrganizations,
};

export const authReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(login, onLogin)
    .addCase(update, onUpdate)
    .addCase(signUp.fulfilled, onSignUpFulfilled)
    .addCase(updateUserOrganizations, onUserOrganizations)
    .addCase(toggleTutorials, onToggleTutorials);
});
