import React from 'react'
import {
  TableCell,
  TableHead,
  TableSortCell,
} from '../../../../../components/common/ResponsiveTable'
import { useTrans } from '../../../../../services/i18n'
import { CheckBox } from '../../../../../components/common/Inputs'

export default function Head({
  orderBy,
  orderDir,
  onChangeOrder,
  isAllCheckedSavedSearches,
  isSomeCheckedSavedSearches,
  onAddAllSavedSearches,
  onRemoveAllSavedSearches
}) {
  const { trans } = useTrans()

  const onSelectAll = () => {
    console.log('isAllCheckedSavedSearches ', isAllCheckedSavedSearches)
    if (isAllCheckedSavedSearches) {
      onRemoveAllSavedSearches()
    } else {
      onAddAllSavedSearches()
    }
  }

  return (
    <TableHead>
      <TableCell>
        <CheckBox
          dense
          color="secondary"
          // checked={isAllChecked || isSomeChecked}
          checked={isAllCheckedSavedSearches}
          // indeterminate={isSomeChecked && !isAllChecked}
          indeterminate={isSomeCheckedSavedSearches}
          // onChange={
          //   isAllChecked || isSomeChecked ? onRemoveAllChecked : onCheckAll
          // }
          onChange={onSelectAll}
        />
      </TableCell>
      <TableSortCell
        orderLabel="title"
        orderBy={orderBy}
        orderDir={orderDir}
        onChange={onChangeOrder}
      >
        {trans('name')}
      </TableSortCell>
      <TableSortCell
        orderLabel="dateCreated"
        orderBy={orderBy}
        orderDir={orderDir}
        onChange={onChangeOrder}
      >
        {trans('date-created')}
      </TableSortCell>
      <TableSortCell
        orderLabel="scope"
        orderBy={orderBy}
        orderDir={orderDir}
        onChange={onChangeOrder}
      >
        {trans('scope')}
      </TableSortCell>
      <TableSortCell
        orderLabel="count"
        orderBy={orderBy}
        orderDir={orderDir}
        onChange={onChangeOrder}
      >
        {trans('Count')}
      </TableSortCell>
      <TableCell>{trans('emailing')}</TableCell>
      <TableCell width={1} align="right" />
      <TableCell width={1} align="right" />
    </TableHead>
  )
}
