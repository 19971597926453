import { connect } from 'react-redux'
import { actions } from '../../store/tooltipsManagement'

function mapStateToProps(state) {
  return {
    tooltips: state.tooltipsManagement.tooltips,
    currentSection: state.tooltipsManagement.currentSection,
    publishedBanner: state.tooltipsManagement.publishedBanner
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createTooltip: params => dispatch(actions.createTooltip(params)),
    getTooltips: params => dispatch(actions.getTooltips(params)),
    changeCurrentSection: params =>
      dispatch(actions.changeCurrentSection(params)),
    deleteTooltip: params => dispatch(actions.deleteTooltip(params)),
    updateTooltip: params => dispatch(actions.updateTooltip(params)),
    createOnboarding: params => dispatch(actions.createOnboarding(params)),
    getOnboardings: params => dispatch(actions.getOnboardings(params)),
    deleteOnboarding: params => dispatch(actions.deleteOnboarding(params)),
    deleteOnboardingStep: params => dispatch(actions.deleteOnboardingStep(params)),
    publishBanner: params => dispatch(actions.publishBanner(params)),
    getPublishedBanner: params => dispatch(actions.getPublishedBanner(params)),
    unpublishBanner: params => dispatch(actions.unpublishBanner(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
