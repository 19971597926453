import React from "react";
import { RouterLink } from "../../Buttons";
import Typography from "../../Typography";
import DropdownOrganizations from "./DropdownOrganizations";

function OrganizationsCell({ organizations = [] }) {
  return (
    <>
      {organizations.length === 1 && (
        <>
          <Typography
            fontFamily="lexend"
            weight="500"
            variant="body2"
            component={RouterLink}
            noWrap
            to={`/organizations/${organizations[0].organizationId}`}
          >
            {organizations[0].orgName}
          </Typography>
        </>
      )}
      {organizations.length > 1 && (
        <DropdownOrganizations items={organizations}></DropdownOrganizations>
      )}
      {organizations.length === 0 && (
        <Typography
          fontFamily="lexend"
          weight="500"
          variant="body2"
          noWrap
          color="placeholder"
        >
          No organizations
        </Typography>
      )}
    </>
  );
}

export default OrganizationsCell;
