import Grid from '@material-ui/core/Grid'
import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '../../../components/common/Buttons'
import { ModalDialog } from '../../../components/common/Dialogs'
import { TextField } from '../../../components/common/Inputs'
import Typography from '../../../components/common/Typography'
import { useForm } from '../../../hooks/useForm'
import { useTrans } from '../../../services/i18n'
import { getInitialValues, useScheme } from './helpers'
import { errorAlert, successAlert } from '../../../contexts/AlertContext'

import connect from './connect'

const formOptions = {
  validateOnMount: false,
  validateOnChange: true,
  enableReinitialize: false,
}

const SaveSearchForm = ({
  item,
  open,
  onClose,
  setOpenModalPremium,
  onSaveSearch,
  userDetail,
}) => {
  const { trans } = useTrans()
  const schema = useScheme()
  const [step, setStep] = useState(1)
  const [notification, setNotification] = useState('GLOBAL')
  const [notificationFrequency, setNotificationFrequency] = useState('hour')
  const [notificationNumber, setNotificationNumber] = useState(1)
  const [
    notificationsEnabled,
  ] = React.useMemo(
    () => [
      userDetail.savedSearchNotices,
      userDetail.savedSearchTypeNotices,
      userDetail.consolidateNotificationSelect,
      userDetail.consolidateNotificationNumber,
    ],
    [userDetail]
  )

  /***
   * When edit
   */
  const onSubmit = useCallback(
    (values, { resetForm }) => {
      if (typeof onSaveSearch !== 'function') return
      Promise.resolve(
        onSaveSearch({
          id: values.id,
          searchSavedOwner: userDetail.userName,
          title: values.title,
          notification: notification,
          notificationFrequency: notificationFrequency,
          notificationNumber: notificationNumber,
        })
      ).then(e => {
        onClose()
        if (e?.error?.code === 'SubscribedContentOnlyError') {
          errorAlert('To continue to save searches get a premium account')
          setOpenModalPremium(true)
        } else {
          successAlert('Your search has been saved successfully')
        }
        resetForm()
      })
    },
    [
      onClose,
      onSaveSearch,
      setOpenModalPremium,
      notificationFrequency,
      notificationNumber,
      notification,
    ]
  )

  const {
    getFieldProps,
    handleSubmit,
    values,
    isSubmitting,
    isValid,
    resetForm,
  } = useForm(schema, getInitialValues(item), onSubmit, formOptions)

  /***
   * Set values for SS
   */
  useEffect(() => {
    setNotification('GLOBAL');
    setNotificationFrequency('hour');
    setNotificationNumber(1);
  }, [item, notificationsEnabled]);

  /***
   * 
   */
  useEffect(() => {
    if (userDetail?.onViewHelpUseNotifications) setStep(2)
  }, [userDetail])

  /***
   * 
   */
  const handleClose = () => {
    onClose()
    resetForm()
  }

  return (
    <>
      <ModalDialog
        open={open}
        onClose={onClose}
        title={trans('edit-saved-search')}
        actions={
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                disabled={isSubmitting}
                onClick={handleClose}
              >
                {trans('cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={!isValid}
                pending={isSubmitting}
                onClick={handleSubmit}
              >
                {trans(values.id ? 'save' : 'create')}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <>
          <Typography
            weight="500"
            fontFamily="lexend"
            style={{ fontSize: '14px', color: '#4E5978', marginBottom:'0.5rem' }}
          >
            {trans('name-this-saved-search')}
          </Typography>

          <TextField
            placeholder={trans('name')}
            required
            {...getFieldProps('title')}
          />
        </>
      </ModalDialog>
    </>
  )
}

export default connect(SaveSearchForm)
