import { connect } from 'react-redux'
import { actions } from '../../store/teamingOpportunities'
import { generateListCallbacks } from '../../store/helpers/listActionsHelpers'
import { SCOPE_TEAMING_OPPORTUNITIES } from '../../constants/search'
import { application } from '../../services/application'

function mapStateToProps(state) {
  return {
    scope: SCOPE_TEAMING_OPPORTUNITIES,
    opportunities: state.teamingOpportunities,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    getAvatarDownloadUrl: id => {
      return application.call('organization.getOrganizationAvatar', { id })
    },
    fetchList: params => dispatch(actions.fetchList(params)),
    updateStatus: params => dispatch(actions.updateStatus(params)),
    updateOpportunity: params => dispatch(actions.updateOpportunity(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
