import { connect, batch } from 'react-redux';
import {
  generateCheckItemsCallbacks,
  generateListCallbacks,
  generateNotInterestedCallbacks,
} from '../../../../store/helpers/listActionsHelpers';
import { actions } from '../../../../store/notifications';

function mapStateToProps(store) {
  const notifications = store.notifications;
  const checkedLength = Array.isArray(notifications.checkedItems) ? notifications.checkedItems.length : 0;
  const itemsLength = Array.isArray(notifications.items) ? notifications.items.length : 0;

  return {
    notifications,
    isSomeChecked: checkedLength > 0,
    isAllChecked: itemsLength > 0 && checkedLength === itemsLength,
    settings: notifications.settings,
    settingsPending: notifications.settingsPending,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    ...generateNotInterestedCallbacks(dispatch, actions),
    ...generateCheckItemsCallbacks(dispatch, actions),

    onInit: async (params) => {
      batch(() => {
        dispatch(actions.getSettings());
        dispatch(actions.removeAllItems());
        dispatch(actions.setParams(params));
      });
      return dispatch(actions.fetchList());
    },
    onRefreshList: () => dispatch(actions.fetchList()),

    onCheckRead: () => dispatch(actions.checkAllItems({ filterCB: 'filterRead' })),
    onCheckUnread: () => dispatch(actions.checkAllItems({ filterCB: 'filterUnread' })),
    onSetSettings: (params) => dispatch(actions.setSettings(params)),
    getUnread: () => dispatch(actions.getUnread()),

    onMarkAsRead: async (value) => {
      await dispatch(actions.markAsRead(value));
      await dispatch(actions.getUnread());
      return dispatch(actions.fetchList());
    },
    onMarkAsArchived: async () => {
      await dispatch(actions.markAsArchived());
      dispatch(actions.removeAllItems());
      return dispatch(actions.fetchList());
    },
    onDelete: async () => {
      await dispatch(actions.remove());
      dispatch(actions.removeAllItems());
      return dispatch(actions.fetchList());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
