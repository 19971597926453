import React from 'react'
import { Chip } from '../../../../components/common/Chip'
import { useTrans } from '../../../../services/i18n'

const colorMap = {
  pending: '#ffac00',
  're-submitted': '#a5cb55',
  progress: '#9576ff',
  submitted: '#17B471',
}

const StatusChip = React.memo(({ status, expired = false }) => {
  const { trans } = useTrans()
  return (
    <>
      {expired ? (
        <Chip label={trans('expired')} color={'secondary'} />
      ) : (
        <Chip
          label={trans(status)}
          color={'secondary'}
          style={{
            backgroundColor: colorMap[status.toLowerCase()] ?? colorMap.unknown,
          }}
        />
      )}
    </>
  )
})

export default StatusChip
