import { connect } from 'react-redux'
import { actions } from '../../store/emailTemplates'

function mapStateToProps(state) {
  return {
    emailTemplates: state.emailTemplates,
    currentTemplate: state.emailTemplates.currentTemplate,
    pending: state.emailTemplates.pending,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getList: params => dispatch(actions.getList(params)),
    findOne: params => dispatch(actions.findOne(params)),
    saveEmailTemplate: params =>
      dispatch(actions.saveEmailTemplate(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
