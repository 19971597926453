import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import {
  StyledTableCell,
  NotFoundWrapper,
  NotFoundLabel,
} from './StyledComponents' // Import from the separate file
import { ItemFile } from '.'
import { IconFile } from '../../../../../components/svg/Icons'

const TableCategoryDetail = ({ files, downloadFile, setState }) => {
  console.log(
    '🚀 ~ file: TableCategoryDetail.js:15 ~ TableCategoryDetail ~ files:',
    files
  )
  return (
    <>
      {files.length > 0 ? (
        <Table size="medium">
          <TableHead>
            <TableRow>
              <StyledTableCell width="50%">Name</StyledTableCell>
              <StyledTableCell width="15%">Type</StyledTableCell>
              <StyledTableCell width="15%">File Size</StyledTableCell>
              <StyledTableCell width="15%">Last Modified</StyledTableCell>
              <StyledTableCell align="right" width="5%"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map(file => (
              <ItemFile
                file={file}
                downloadFile={downloadFile}
                setState={setState}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <NotFoundWrapper>
          <IconFile style={{ color: '#B2B8CF' }} coloredStroke={true} />
          <NotFoundLabel>{'No Documents Found'}</NotFoundLabel>
        </NotFoundWrapper>
      )}
    </>
  )
}

export default TableCategoryDetail
