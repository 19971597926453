import mixins from '../mixins';
import colors from '../pallete';

const MuiCssBaseline = {
  '@global': {
    '*': {
      'scrollbar-color': `${colors.gray.paperActive} ${colors.background.paper}`,
      'scrollbar-width': 'thin',
    },
    '::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
    },
    '::-webkit-scrollbar-track': {
      background: colors.background.paper,
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.gray.paperActive,
      '&:hover': {
        background: colors.gray.active,
      },
    },
    '.forwardHeight': {
      height: '100%',
    },
    '.forwardScroll': {
      height: '100%',
      overflow: 'hidden',
    },
    '.app-ul': { listStyle: 'none', margin: 0, padding: 0 },
    '.pointer': { cursor: 'pointer' },
    '.draggable': {
      cursor: 'move',
      transform: 'translate3d(0, 0, 0)',
    },
    '.unselectable': { ...mixins.unselectable },
    '.sticky': {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 3,
    },
    '.relative': { position: 'relative' },
    '.center': { textAlign: 'center' },
    '.right': { textAlign: 'right' },
    '.showOnHover': { opacity: 0, transition: 'opacity 0.3s' },
    '.targetHover:hover .showOnHover': { opacity: 1 },

    form: { width: '100%' },

    '.order-1': { order: -1 },
    '.order1': { order: 1 },
    '.order2': { order: 2 },
    '.order3': { order: 3 },

    '.border-left': { borderLeft: `1px solid ${colors.gray.divider}` },

    // GRAPHS
    '.graph-io': {
      '& svg': { maxWidth: '100%' },
    },
  },
};

export default MuiCssBaseline;
