import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTrans } from '../../../services/i18n'
import Typography from '../../../components/common/Typography'
import { ModalDialog } from '../../../components/common/Dialogs'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import { Button } from '../../../components/common/Buttons'
import { useAlertContext } from '../../../contexts/AlertContext'
import connect from '../connect'
import { TextField } from '../../../components/common/Inputs'
import { useForm } from '../../../hooks/useForm'
import { getInitialValues, useScheme } from './helpers'

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  enableReinitialize: true,
}

const AddCategoryModal = ({
  open,
  onClose,
  item = null,
  saveRequestTemplate,
  onInit,
}) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [pending, setPending] = React.useState(false)
  const schema = useScheme()

  const onSubmit = async () => {
    try {
      setPending(true)
      const { payload } = await saveRequestTemplate({ ...values, id: item?.id })
      setPending(false)
      successAlert(payload.message)
      onInit()
      closeModal()
    } catch (error) {
      errorAlert('Server error, please refresh the page or try again later.')
      setPending(false)
      closeModal()
    }
  }

  const {
    getFieldProps,
    handleSubmit,
    values,
    isSubmitting,
    isValid,
    resetForm,
  } = useForm(schema, getInitialValues(item), onSubmit, formOptions)

  const closeModal = () => {
    resetForm()
    onClose()
  }

  useEffect(() => {}, [])

  return (
    <ModalDialog
      title={
        item ? trans('edit-template-category') : trans('add-template-category')
      }
      open={open}
      onClose={onClose}
      actions={
        <Grid container>
          <Grid
            item
            sm={12}
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              variant="contained"
              onClick={closeModal}
              style={{ marginRight: '16px' }}
            >
              {trans('cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              pending={pending}
            >
              {item ? trans('save') : trans('create')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Paper>
        <SpacingContainer>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item sm={12} xs={12}>
              <TextField
                label={trans('category-name')}
                {...getFieldProps('name')}
              />
            </Grid>
          </Grid>
        </SpacingContainer>
      </Paper>
    </ModalDialog>
  )
}

export default connect(AddCategoryModal)
