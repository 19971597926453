import React from 'react'
import { Chip } from '../../../components/common/Chip'
import { useTrans } from '../../../services/i18n'
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDown'
import { Box } from '@material-ui/core'
import styled from '@emotion/styled'
import usePopover from '../../../hooks/usePopover'
import {
  BottomLeftPosition,
  ContextMenuListItem,
  Popover,
} from '../../../components/common/Dialogs/Popover'
import { ListItemTextBase } from '../../../components/common/Containers'
import connect from '../connect'

export const PROGRESS_COLOR_MAP = {
  new: '#00A6FF',
  in_progress: '#9576FF',
  closed: '#17B471',
}

const CustomChip = styled(Chip)`
  color: ${props => props.color};
  background-color: ${props => `${props.color}29`};

  & span {
    padding-right: 5px;
  }

  &:hover {
    background-color: ${props => `${props.color}40`};
  }

  &:active,
  &:focus {
    background-color: ${props => `${props.color}29`};
  }
`

const ProgressStatusChip = React.memo(({ quote, updateProgressStatus }) => {
  const { trans } = useTrans()
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()
  const { id, progressStatus: status } = quote

  const selectedColor = PROGRESS_COLOR_MAP[status] ?? PROGRESS_COLOR_MAP.new

  const onClick = newStatus => {
    updateProgressStatus({
      id,
      newStatus,
    })
  }

  return (
    <>
      <CustomChip
        clickable
        color={selectedColor}
        onClick={onOpenPopover}
        label={
          <Box display="flex" justifyContent="center" alignItems="center">
            {trans(status)}
            <ArrowDropDownRoundedIcon />
          </Box>
        }
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClosePopover}
        anchorOrigin={BottomLeftPosition.anchorOrigin}
        transformOrigin={BottomLeftPosition.transformOrigin}
      >
        <ContextMenuListItem>
          <ListItemTextBase color="hint">{trans('mark-as')}</ListItemTextBase>
        </ContextMenuListItem>
        {Object.keys(PROGRESS_COLOR_MAP).map(status => (
          <ContextMenuListItem
            button
            onClick={() => onClick(status)}
            onClickCB={onClosePopover}
          >
            <ListItemTextBase>
              <span
                style={{
                  color: PROGRESS_COLOR_MAP[status],
                }}
              >
                &#x2022;{' '}
              </span>
              {trans(status)}
            </ListItemTextBase>
          </ContextMenuListItem>
        ))}
      </Popover>
    </>
  )
})

export default connect(ProgressStatusChip)
