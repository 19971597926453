import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useEffect, useState } from 'react'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'

import connect from '../../connect'
import { Button } from '../../../../components/common/Buttons'
import { TextField } from '../../../../components/common/Inputs'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import { Box } from '@mui/material'
import { Launch } from '@material-ui/icons'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const ExportsConfiguration = ({
  exportedItemsAtOnce,
  monthlyExportsLimit,
  getGlobalSettings,
  saveExportLimits,
}) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const permissions = useSelector(selectUserPermissions)

  const [state, setState] = useState({
    exportedItemsAtOnce: 0,
    monthlyExportsLimit: 0,
  })

  const changeInput = ev => {
    setState({
      ...state,
      [ev.target.name]: parseInt(ev.target.value),
    })
  }

  const saveSettings = async () => {
    if (!state.exportedItemsAtOnce || !state.monthlyExportsLimit) {
      errorAlert(trans('greater-than-zero-error'))
      return
    }
    try {
      setIsSend(true)
      await saveExportLimits({ ...state })
      setIsSend(false)
      successAlert('Export limits saved successfully')
    } catch (error) {
      setIsSend(false)
    }
  }

  useEffect(async () => {
    await getGlobalSettings({})
  }, [])

  useEffect(() => {
    setState({
      exportedItemsAtOnce,
      monthlyExportsLimit,
    })
  }, [exportedItemsAtOnce, monthlyExportsLimit])

  return (
    <SpacingContainer py={2}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              style={{ alignItems: 'center', display: 'flex' }}
              variant="h4"
              weight="500"
              fontFamily="lexend"
            >
              <Launch style={{ marginRight: '5px' }} />{' '}
              {trans('exports-configuration')}{' '}
              <ShowTooltip sectionToShow="exports-configuration" />
            </Typography>
          </Grid>
        </Grid>
        <Divider light style={{ marginTop: '20px' }} />
        <Box style={{ alignItems: 'center', paddingTop: '20px' }}>
          <TextField
            placeholder="Ex.: 500"
            onChange={changeInput}
            name="exportedItemsAtOnce"
            type="number"
            value={state.exportedItemsAtOnce}
            label="Exported items at once limit"
            style={{ width: '60%', marginRight: '16px' }}
          />{' '}
        </Box>

        <Box style={{ alignItems: 'center', paddingTop: '20px' }}>
          <TextField
            placeholder="Ex.: 3000"
            type="number"
            name="monthlyExportsLimit"
            onChange={changeInput}
            value={state.monthlyExportsLimit}
            label="Monthly exported items limit"
            style={{ width: '60%', marginRight: '16px' }}
          />{' '}
        </Box>
        {isEnabled(permissions, 'global-settings', '', 'edit') && (
          <Box
            style={{
              borderTop: `1px solid rgba(78, 89, 120, 0.12)`,
              padding: '2rem 0 0.5rem 0',
              marginTop: '2rem',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs zeroMinWidth>
                <Button
                  variant="contained"
                  style={{ background: '#17b471', color: '#fff' }}
                  onClick={() => saveSettings()}
                  pending={isSend}
                >
                  {' '}
                  Save Settings
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </SpacingContainer>
  )
}
export default connect(ExportsConfiguration)
