import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import { StyledTableCell } from './StyledComponents' // Import from the separate file
import { Item } from '.'

const TableCategory = ({
  categories,
  downloadOrganizationFiles,
  organization,
}) => {
  return (
    <>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <StyledTableCell width="65%">Name</StyledTableCell>
            <StyledTableCell width="20%">Type</StyledTableCell>
            <StyledTableCell width="10%">Last Modified</StyledTableCell>
            <StyledTableCell align="right" width="5%"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map(category => (
            <Item
              category={category}
              downloadOrganizationFiles={downloadOrganizationFiles}
              organization={organization}
            />
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default TableCategory
