import React from 'react';
import Typography from '../../../components/common/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
  },
  alignCenter: {
    alignItems: 'center',
  },
  card: {
    textAlign: 'left',
    padding: '0.7rem 0',
  },
  number: {
    fontWeight: 'bold',
    fontSize: 40,
  },
  title: {
    padding: '8px 0',
    fontWeight: 600,
    color: '#4e5978',
    fontSize: 15,
  },
  border: {
    width: 5,
    height: 60,
    marginRight: 20,
    borderRadius: 3,
  },
  containerMetric: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '1rem',
    cursor: 'pointer'
  }
});

export default function TopInfo({
  value = 0,
  text = '',
  link = '',
  color,
}) {
  const classes = useStyles();
  let history = useNavigate();

  return (
    <div
      onClick={() => history(link)}
      className={clsx(classes.card, classes.flex, 'on-hover-dashboard-metric')}
    >
      <div className={classes.border} style={{ backgroundColor: color }}></div>
      <div className={classes.containerMetric}>
				<Typography className={classes.number} variant="body2" style={{ color: color }}>
					{value}
				</Typography>

        <div style={{gap: 20}} className={clsx(classes.flex, classes.alignCenter)}>
          <Typography className={classes.title} variant="h5">
            {text}
          </Typography>
          <ArrowForwardIosIcon color="action" fontSize="small"/>
        </div>
      </div>
    </div>
  );
}
