import Grid from '@material-ui/core/Grid';
import React from 'react';
import ChipGroup from '../../../../../components/common/Chip/ChipGroup';
import { useTrans } from '../../../../../services/i18n';
import { formatPrettyDate } from '../../../../../utils/dateUtils';
import { ChipOption } from '../ChipOption';

export const ToChipGroup = ({ item, onDeleteFilter, onDelete, onOpenFilter }) => {
  const { trans } = useTrans();
  const filterOption = item.filterOption || {};

  return (
    <Grid item component={ChipGroup}>
      <ChipOption
        name={filterOption.name ? trans(filterOption.name) : '-'}
        value={`${trans('to')} ${isNaN(item.to) ? formatPrettyDate(item.to) : item.to}`}
        onClick={onOpenFilter ? onOpenFilter.bind(null, filterOption) : undefined}
        onDelete={onDelete ? onDelete : onDeleteFilter ? onDeleteFilter.bind(null, item.key) : undefined}
      />
    </Grid>
  );
};
