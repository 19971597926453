import React from 'react';
import { useTrans } from '../../../../services/i18n';
import { Button } from '../../../../components/common/Buttons';
import { Grid } from '@material-ui/core';

const FilterModalDialogActions = ({ color = 'primary', onRemoveClick, onApplyClick }) => {
  const { trans } = useTrans();
  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item>
        <Button id="remove-filter" variant="outlined" color={color} onClick={onRemoveClick}>
          {trans('remove-filter')}
        </Button>
      </Grid>
      <Grid item>
        <Button id="filters-apply" variant="contained" color={color} onClick={onApplyClick}>
          {trans('apply')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FilterModalDialogActions;
