import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import { Button } from '../../../../components/common/Buttons'
import { ModalDialog } from '../../../../components/common/Dialogs'
import { useTrans } from '../../../../services/i18n'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { SpacingContainer } from '../../../../components/common/Containers'
import CheckBox from '../../../../components/common/Inputs/CheckBox'
import connect from '../../connect'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '../../../../components/common/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { emptyArray } from '../../../../utils/arrayUtils'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard'

const SetupParentFormsModal = ({
  formstackForms,
  changeFormstackForms,
  changeCurrentIdsForms,
  open,
  onClose,
  saveFormsUsfcr,
  type = 'principal',
  parentFormId = null,
  currentFormDetail,
}) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)

  const closeModal = () => {
    changeCurrentIdsForms([])
    setIsSend(false)
    onClose()
  }

  const saveForms = () => {
    setIsSend(true)
    const forms = formstackForms.map(i => ({ id: i.id, checked: i.checked }))
    saveFormsUsfcr({
      type: type,
      forms: forms,
      parentFormId: parentFormId,
    })
      .then(() => {
        successAlert(
          type === 'principal'
            ? 'Parent forms updated!'
            : 'Adjust forms updated!'
        )
        closeModal()
      })
      .catch(() => {
        errorAlert('Error')
        closeModal()
      })
  }

  const changeCheckForm = (ev, formId) => {
    let formstackFormsCopy = []
    formstackFormsCopy = JSON.parse(JSON.stringify(formstackForms))
    const index = formstackFormsCopy.findIndex(i => i.id === formId)
    formstackFormsCopy[index].checked = ev.target.checked
    changeFormstackForms(formstackFormsCopy)
  }

  const selectUnselectAll = active => {
    let formstackFormsCopy = []
    formstackFormsCopy = JSON.parse(JSON.stringify(formstackForms))
    formstackFormsCopy = formstackFormsCopy.map(f => {
      return { ...f, checked: active }
    })
    changeFormstackForms(formstackFormsCopy)
  }

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={
        type === 'principal'
          ? trans('setup-parent-forms')
          : trans('assign-adjustment-forms')
      }
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSend}
              onClick={closeModal}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={() => saveForms()}
            >
              {trans('save')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer px={3} py={0}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <div>
            <Button
              color="primary"
              variant="text"
              onClick={() => selectUnselectAll(true)}
            >
              {trans('select-all')}
            </Button>
            <Button
              color="primary"
              variant="text"
              onClick={() => selectUnselectAll(false)}
            >
              {trans('unselect-all')}
            </Button>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          <InfoOutlinedIcon />
          <div style={{ marginLeft: '10px' }}>
            {type === 'principal' && (
              <>
                <div>
                  <Typography
                    variant="body2"
                    weight={600}
                    style={{ width: '100%' }}
                  >
                    {trans('select-forms-you-want-to-set-as-parent')}
                  </Typography>
                </div>
                <Typography variant="body2">
                  {trans('you-will-be-able-to-assign-adjustment-forms-to-them')}
                </Typography>
              </>
            )}

            {type !== 'principal' && (
              <div>
                <Typography
                  variant="body2"
                  weight={600}
                  style={{ width: '100%' }}
                >
                  {trans('select-adjustment-forms-you-want-to-assign-to-the')}{' '}
                </Typography>
                {currentFormDetail.name}
              </div>
            )}
          </div>
        </div>

        {!emptyArray(formstackForms) && (
          <div style={{ height: '300px' }}>
            {formstackForms.map(i => (
              <div style={{ padding: '5px 0' }}>
                <FormControlLabel
                  key={i.id}
                  control={
                    <CheckBox
                      color="secondary"
                      checked={i.checked}
                      onChange={ev => changeCheckForm(ev, i.id)}
                    />
                  }
                  label={i.name}
                />
              </div>
            ))}
          </div>
        )}

        {emptyArray(formstackForms) && (
          <div
            style={{ display: 'flex', alignItems: 'center', height: '300px' }}
          >
            <NothingFoundCard
              title={trans('no-forms-found')}
              description={trans('no-forms-found-description')}
              size="medium"
            />
          </div>
        )}
      </SpacingContainer>
    </ModalDialog>
  )
}

export default connect(SetupParentFormsModal)
