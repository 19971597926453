import { Grid } from '@material-ui/core'
import { Title } from './Title'
import Avatar from '../../../components/common/Cards/Avatar'

export function AvatarWithTitle({
  size,
  variant,
  title,
  detailLink,
  src,
  onClick,
}) {
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={3}>
        <Avatar
          size={size}
          variant={variant}
          value={title}
          src={src}
          alt={title}
        />
      </Grid>
      <Grid item xs={9}>
        <Title
          variant="h4"
          weight="600"
          color="primary"
          detailLink={detailLink}
          title={title}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  )
}
