import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconAddRequestTemplate = (props) => {
  const { width = 48, height = 48 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 48 25">
      <path d="M5 21L3 9H21L19 21H5ZM6.25 19.5H17.75L19.175 10.5H4.825L6.25 19.5ZM10 14.5H14C14.2167 14.5 14.3958 14.425 14.5375 14.275C14.6792 14.125 14.75 13.95 14.75 13.75C14.75 13.5333 14.6792 13.3542 14.5375 13.2125C14.3958 13.0708 14.2167 13 14 13H10C9.8 13 9.625 13.0708 9.475 13.2125C9.325 13.3542 9.25 13.5333 9.25 13.75C9.25 13.95 9.325 14.125 9.475 14.275C9.625 14.425 9.8 14.5 10 14.5ZM6 7.5C5.8 7.5 5.625 7.425 5.475 7.275C5.325 7.125 5.25 6.95 5.25 6.75C5.25 6.53333 5.325 6.35417 5.475 6.2125C5.625 6.07083 5.8 6 6 6H18C18.2167 6 18.3958 6.07083 18.5375 6.2125C18.6792 6.35417 18.75 6.53333 18.75 6.75C18.75 6.95 18.6792 7.125 18.5375 7.275C18.3958 7.425 18.2167 7.5 18 7.5H6ZM8 4.5C7.8 4.5 7.625 4.425 7.475 4.275C7.325 4.125 7.25 3.95 7.25 3.75C7.25 3.53333 7.325 3.35417 7.475 3.2125C7.625 3.07083 7.8 3 8 3H16C16.2167 3 16.3958 3.07083 16.5375 3.2125C16.6792 3.35417 16.75 3.53333 16.75 3.75C16.75 3.95 16.6792 4.125 16.5375 4.275C16.3958 4.425 16.2167 4.5 16 4.5H8ZM6.25 19.5H17.75H6.25Z" fill="#2445A7"/>
    </SvgIcon>
  );
};

IconAddRequestTemplate.propTypes = simpleIconPropsTypes;
