import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import * as Yup from 'yup'
import { ModalDialog } from '../../../../../../components/common/Dialogs'
import SaveCancelDialogActions from '../../../../../../components/common/Dialogs/SaveCancelDialogActions'
import { SelectField } from '../../../../../../components/common/Inputs'
import Typography from '../../../../../../components/common/Typography'
import { ROLE_OWNER, USER_ROLES } from '../../../../../../constants/user'
import { useAlertContext } from '../../../../../../contexts/AlertContext'
import makeValidationSchema from '../../../../../../hooks/makeValidationSchema'
import { useForm } from '../../../../../../hooks/useForm'
import { useTrans } from '../../../../../../services/i18n'
import { schemaToInitialState } from '../../../../../../utils/validationUtils'
import connect from '../../../connect'

const useScheme = makeValidationSchema(trans => {
  return Yup.object().shape({
    role: Yup.string().required(
      trans('empty-not-allowed', { field: trans('role') })
    ),
  })
})

function UserModal({ editUserRole, user, onClose, open = false, authUser }) {
  const { trans } = useTrans()
  const schema = useScheme()
  const { successAlert, errorAlert } = useAlertContext()
  const [pending, setPending] = React.useState(false)

  const onEditRole = React.useCallback(
    values => {
      console.log('submitted')
      editUserRole(values).then(() => {
        successAlert(trans('user-role-updated'))
      })
      typeof onClose === 'function' && onClose()
    },
    [editUserRole, onClose, user]
  )

  const { handleSubmit, resetForm, getFieldProps } = useForm(
    schema,
    user || schemaToInitialState(schema),
    onEditRole,
    { validateOnChange: false, validateOnBlur: false }
  )

  const handleCancel = React.useCallback(() => {
    resetForm()
    typeof onClose === 'function' && onClose()
  }, [onClose, resetForm])

  const FILTERED_USER_ROLES =
    authUser.role === 'admin'
      ? USER_ROLES
      : USER_ROLES.filter(role => role.value !== ROLE_OWNER)

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans('edit-user')}
      actions={
        <SaveCancelDialogActions
          formId="edit-user"
          onCancelClick={handleCancel}
          onSaveClick={handleSubmit}
          pending={pending}
        />
      }
    >
      <Grid container spacing={3}>
        <Grid item sm={user ? 12 : 6} xs={12}>
          <Typography
            weight="500"
            fontFamily="lexend"
            color="label"
            variant="body2"
          >
            {trans('role')}
          </Typography>
          <SelectField
            style={{ marginTop: '7px' }}
            type="select"
            {...getFieldProps('role')}
            placeholder={trans('required')}
          >
            {FILTERED_USER_ROLES.map((item, ind) => (
              <MenuItem key={ind} value={item.value}>
                {trans(item.i18nKey)}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
      </Grid>
    </ModalDialog>
  )
}

export default connect(UserModal)
