import React, { useEffect } from 'react'
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase'
import Header from './components/Header'
import connect from './connect'
import Loader from '../../components/common/Loader'
import { emptyArray } from '../../utils/arrayUtils'
import { Grid } from '@material-ui/core'
import List from './components/List'
import NothingFoundCard from '../../components/generics/Cards/NothingFoundCard/NothingFoundCardList'

function EmailTemplates({ getList, emailTemplates }) {
  const { items, pending } = emailTemplates

  useEffect(() => {
    getList({
      text: '',
      order: ['createdAt', 'desc'],
      filters: {},
      pagination: { page: 1, perPage: 10 },
    })
  }, [getList])

  return (
    <SpacingContainerBase>
      <Header />
      {pending && <Loader open={pending} />}
      {!emptyArray(items) ? (
        <Grid container spacing={2} direction="column">
          <Grid item xs zeroMinWidth>
            <List items={items} />
          </Grid>
        </Grid>
      ) : !pending && emptyArray(items) ? (
        <Grid item xs zeroMinWidth>
          <NothingFoundCard />
        </Grid>
      ) : null}
    </SpacingContainerBase>
  )
}

export default connect(EmailTemplates)
