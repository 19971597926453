import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { API, SCOPE_REQUEST_TEMPLATES } from '../../constants'
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common'
import {
  extendListBuilder,
  generateListActions,
} from '../helpers/listActionsHelpers'
import { application } from '../../services/application'

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['createdAt', 'desc'],
}

//Actions
const listActions = generateListActions({
  scope: SCOPE_REQUEST_TEMPLATES,
  apiMethod: {
    GET_LIST: API.REQUEST_TEMPLATES.GET_LIST,
  },
  getStore: (store) => store.requestedQuotes,
})

const deleteRequestTemplate = createAsyncThunk(
  'requestTemplate/deleteRequestTemplate',
  (params) => {
    return application.call(API.REQUEST_TEMPLATES.DELETE, params)
  }
)
function onDeleteRequestedQuote(state, action) {}

const findOne = createAsyncThunk('requestTemplate/findOne', (params) => {
  return application.call(API.REQUEST_TEMPLATES.FIND_ONE, params)
})
function onFindOne(state, action) {}

const saveRequestTemplate = createAsyncThunk(
  'requestTemplate/saveRequestTemplate',
  (params) => {
    return application.call(API.REQUEST_TEMPLATES.SAVE_REQUEST_TEMPLATE, params)
  }
)

function onSaveRequestTemplate(state, action) {}

const getAllTemplates = createAsyncThunk(
  'requestTemplate/getAllTemplates',
  (params = {}) => {
    return application.call(API.REQUEST_TEMPLATES.GET_ALL_TEMPLATES, params)
  }
)

const getResourcesByTemplate = createAsyncThunk(
  'requestTemplate/getResourcesByTemplate',
  (params = {}) => {
    return application.call(
      API.REQUEST_TEMPLATES.GET_RESOURCES_BY_TEMPLATE,
      params
    )
  }
)

const saveRequestInOrganization = createAsyncThunk(
  'requestTemplate/saveRequestInOrganization',
  (params) => {
    return application.call(
      API.REQUEST_TEMPLATES.SAVE_REQUEST_IN_ORGANIZATION,
      params
    )
  }
)

const getList = createAsyncThunk('requestTemplateFile/getList', (params) => {
  return application.call(API.REQUEST_TEMPLATES.GET_LIST, params)
})
function onGetList(state, action) {
  state.items = action.payload.items
  state.count = action.payload.count
  state.pending = false
}

export const actions = {
  ...listActions,
  findOne,
  deleteRequestTemplate,
  saveRequestTemplate,
  getAllTemplates,
  getResourcesByTemplate,
  saveRequestInOrganization,
  getList,
}

export default createReducer(INITIAL_STATE, (builder) => {
  return extendListBuilder(builder, actions)
    .addCase(deleteRequestTemplate.fulfilled, onDeleteRequestedQuote)
    .addCase(findOne.fulfilled, onFindOne)
    .addCase(saveRequestTemplate.fulfilled, onSaveRequestTemplate)
    .addCase(getList.pending, (state) => {
      state.pending = true
    })
    .addCase(getList.fulfilled, onGetList)
})
