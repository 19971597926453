import React from 'react'
import { Paper } from '../../../components/common/Containers'
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase'
import { FormControl } from '@material-ui/core'
import { TextField } from '../../../components/common/Inputs'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'

function BaseFields({ formik }) {
  const { trans } = useTrans()
  return (
    <Paper>
      <SpacingContainerBase>
        <FormControl style={{ width: '100%' }}>
          <Typography
            variant="body1"
            weight="500"
            color="hint"
            fontFamily="lexend"
            component="label"
            htmlFor="template-name"
            style={{ marginBottom: '0.5rem' }}
          >
            {trans('template-name')}
          </Typography>
          <TextField
            id="template-name"
            placeholder={trans('template-name')}
            {...formik.getFieldProps('name')}
          />
        </FormControl>

        <FormControl style={{ width: '100%', marginTop: '1rem' }}>
          <Typography
            variant="body1"
            weight="500"
            color="hint"
            fontFamily="lexend"
            component="label"
            htmlFor="email-subject"
            style={{ marginBottom: '0.5rem' }}
          >
            {trans('email-subject')}
          </Typography>
          <TextField
            id="email-subject"
            placeholder={trans('email-subject')}
            {...formik.getFieldProps('emailSubject')}
          />
        </FormControl>
      </SpacingContainerBase>
    </Paper>
  )
}

export default BaseFields
