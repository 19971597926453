import makeStyles from '@material-ui/core/styles/makeStyles';
import MUITableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import React from 'react';
import Typography from '../Typography';
import { useTableContext } from './Table';

export default function TableCell({ label, children, className, noWrap = false, order = 0, respWidth = '', ...rest }) {
  const styles = useStyles();
  const { responsive } = useTableContext();

  return (
    <MUITableCell
      className={clsx(
        className,
        styles[responsive],
        order && `order${order}`,
        respWidth && styles[`${respWidth}Width`],
        noWrap && styles.noWrap
      )}
      {...rest}
    >
      {label && (
        <Typography className={styles.label} variant="body2" color="hint" component="div" noWrap>
          {label}
        </Typography>
      )}
      {children}
    </MUITableCell>
  );
}

const responsiveStyles = {
  flex: '1 1 50%',
  textAlign: 'left',
  '& $label': { display: 'block' },

  // WIDTH
  '&$autoWidth': { flex: '0 0 auto' },
  '&$fullWidth': { flex: '1 1 100%' },
};

const useStyles = makeStyles((theme) => ({
  noWrap: { ...theme.mixins.ellipsis },
  label: { display: 'none' },

  autoWidth: {},
  fullWidth: {},

  xs: { [theme.breakpoints.down('xs')]: responsiveStyles },
  sm: { [theme.breakpoints.down('sm')]: responsiveStyles },
  md: { [theme.breakpoints.down('md')]: responsiveStyles },
  lg: { [theme.breakpoints.down('lg')]: responsiveStyles },
  xl: { [theme.breakpoints.down('xl')]: responsiveStyles },
}));
