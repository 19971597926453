import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import { QUERY_QUICK_VIEW } from '../../../constants/queries';
import useQueryCallbacks from '../../../hooks/query/useQueryCallbacks';
import { useMediaQueryDown } from '../../../hooks/useMediaQuery';
import { useTrans } from '../../../services/i18n';
import { formatNumber } from '../../../utils/numberUtils';
import { Button } from '../../common/Buttons';
import { Chip } from '../../common/Chip';
import { SpacingContainer } from '../../common/Containers/Base';
import { Divider } from '../../common/Divider';
import Typography from '../../common/Typography';
import { IconView } from '../../svg';

export default function ListInfoRow({ description, sort, count = 0, withQuickView = false }) {
  const isSmScreen = useMediaQueryDown('xs');
  const { trans } = useTrans();
  const { setQueryFromHref } = useQueryCallbacks();

  return (
    <SpacingContainer py={4} px={0}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between">
        <Grid item sm="auto" xs={12} container alignItems="center" spacing={1}>
          <Grid item>
            <Chip
              variant="outlined"
              color="secondary"
              label={
                <Typography variant="body2" weight="bold">
                  {formatNumber(count)}
                </Typography>
              }
            />
          </Grid>
          <Grid item>
            <Typography weight="800" variant="body2" component="span">
              {description}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sm="auto" xs={12}>
          <Grid container justifyContent="flex-end" alignItems="stretch" spacing={2}>
            <Grid item sm xs={12}>
              {sort}
            </Grid>
            {withQuickView && (
              <>
                <Hidden smDown>
                  <Grid sm="auto" item>
                    <Divider vertical />
                  </Grid>
                </Hidden>
                <Grid item sm xs={12}>
                  <Button
                    id="quick-view-btn"
                    onClick={setQueryFromHref}
                    data-href={`${QUERY_QUICK_VIEW}=y`}
                    fullWidth={isSmScreen}
                    variant={isSmScreen ? 'outlined' : 'text'}
                    size={isSmScreen ? 'large' : 'small'}
                    startIcon={<IconView variant="quick" />}
                  >
                    <Typography variant="body2" component="span" noWrap>
                      {trans('quick-view')}
                    </Typography>
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </SpacingContainer>
  );
}
