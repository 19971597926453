import React, { useState, useCallback, forwardRef } from 'react';
import { Divider, Fade, InputAdornment, InputBase, Popover, MenuItem, Typography } from '@material-ui/core';
import { IconSearch } from '../../../../components/svg';
import { useTrans } from '../../../../services/i18n';
import { TopTopPosition } from '../../../common/Dialogs/Popover';
import { useSearchContext } from '../context';
import FilterModalDialog from './FilterModalDialog';

const PaperProps = { component: 'ul' };

const MenuItemInput = forwardRef((props, ref) => {
  return (
    <MenuItem ref={ref}>
      <InputBase
        id={'filter-menu-search'}
        {...props}
        margin="dense"
        startAdornment={
          <InputAdornment position="start">
            <IconSearch height={16} width={16} />
          </InputAdornment>
        }
      />
    </MenuItem>
  );
});

export default function FiltersMenu({ anchorEl, onClose, onSelectFilter }) {
  const { trans } = useTrans();

  const [searchInput, setSearchinput] = useState('');
  const [searchItem, setSearchItem] = useState(undefined);

  const { state, onChangeFilters, setCurrent, onChangeFilter } = useSearchContext();
  const filterOptions = state.filterOptions;
  const current = state.current;

  const onChangeFiltersSearchText = useCallback((e) => {
    setSearchinput(e.target.value);
  }, []);

  const onSelectStaticFilter = useCallback(
    (data) => {
      if (onChangeFilters && data && data.filters) onChangeFilters(data.filters);
      return onClose && onClose();
    },
    [onClose, onChangeFilters]
  );

  const filters = filterOptions;

  const staticFilters = filters.filter((f) => f.static && trans(f.name).toLowerCase().includes(searchInput));

  const handleKeyUp = useCallback(
    (e) => {
      let findItem;
      if (searchInput) {
        findItem = filters.find((f) => trans(f.name).toLowerCase().includes(searchInput));
        if (findItem) {
          setSearchItem(findItem);
        }
      } else {
        setSearchItem(undefined);
      }

      if (findItem && e.key === 'Enter') {
        if (findItem.static) {
          onSelectStaticFilter(findItem.static);
        } else {
          onSelectFilter && onSelectFilter(findItem);
        }
      }
    },
    [searchInput, filters, onSelectFilter, onSelectStaticFilter, trans]
  );

  const onDialogClose = React.useCallback(() => setCurrent(undefined), [setCurrent]);

  const onFilterChange = React.useCallback(
    (name, value) => {
      setCurrent(undefined);
      onChangeFilter(name, value);
    },
    [onChangeFilter, setCurrent]
  );

  const onFiltersChange = React.useCallback(
    (filtersObj) => {
      setCurrent(undefined);
      onChangeFilters(filtersObj);
    },
    [onChangeFilters, setCurrent]
  );

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        onClose={onClose}
        TransitionComponent={Fade}
        PaperProps={PaperProps}
        {...TopTopPosition}
      >
        {filterOptions.length > 5 && (
          <>
            <MenuItemInput value={searchInput} onKeyUp={handleKeyUp} onChange={onChangeFiltersSearchText} />
            <Divider light component="li" />
          </>
        )}

        {staticFilters.length
          ? staticFilters.map((s, i) =>
              s.static.hasOwnProperty('show') && !s.static.show ? null : (
                <MenuItem
                  key={`savedSearch-${i}`}
                  onClick={onSelectStaticFilter.bind(null, s.static)}
                  selected={searchItem && searchItem.name === s.name}
                >
                  <Typography color="primary">{trans(s.name)}</Typography>
                </MenuItem>
              )
            )
          : null}

        {staticFilters.length ? <Divider light component="li" /> : null}
        {filters
          .filter((f) => !f.static && !f.hidden && trans(f.name).toLowerCase().includes(searchInput) && !f.hidden)
          .map((f) => (
            <MenuItem
              key={`filter-${f.name}`}
              onClick={onSelectFilter.bind(null, f)}
              selected={searchItem && searchItem.name === f.name}
            >
              {trans(f.name)}
            </MenuItem>
          ))}
      </Popover>

      {current && (
        <FilterModalDialog
          item={current}
          onClose={onDialogClose}
          onFilterChange={onFilterChange}
          onFiltersChange={onFiltersChange}
        />
      )}
    </>
  );
}
