import Grid from '@material-ui/core/Grid'
import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import { Button } from '../../../../components/common/Buttons'
import { ModalDialog } from '../../../../components/common/Dialogs'
import { useTrans } from '../../../../services/i18n'
import { useAlertContext } from '../../../../contexts/AlertContext'
import Typography from '../../../../components/common/Typography'
import { TextField, CheckBox } from '../../../../components/common/Inputs'
import DatePicker from './components/DatePicker'
import Popover from '@material-ui/core/Popover'
import { Divider } from '../../../../components/common/Divider'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos'
import FolderIcon from '@material-ui/icons/Folder'
import connect from '../connect'
import { emptyArray } from '../../../../utils/arrayUtils'
import Loader from '../../../../components/common/Loader'
import { useForm } from '../../../../hooks/useForm'
import makeValidationSchema from '../../../../hooks/makeValidationSchema'
import { schemaToInitialState } from '../../../../utils/validationUtils'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import IconButton from '@material-ui/core/IconButton'
import CreateFolderModal from './components/CreateFolderModal'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import FormTemplateFile from './FormTemplateFile'
import { IconAddRequestTemplate } from '../../../../components/svg/Icons'
import { Autocomplete } from '../../../../components/common/Inputs'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ACCEPTABLE_FILE_TYPES } from './const/index'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const useStyles = makeStyles(theme => ({
  fileTypeItem: {
    width: '5.64rem',
  },
  dividerStyles: {
    margin: '1rem 0rem',
  },
  acceptableFileTypeSelectAll: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  acceptableFileTypeSelectAllText: {
    color: '#2445A7',
    fontSize: '14px',
    fontStyle: 'normal',
    cursor: 'pointer',
    marginLeft: '1rem',
  },
  fileTypeTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fileTypeText: {
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
}))

// Form schema
const useScheme = makeValidationSchema(trans =>
  Yup.object().shape({
    fileName: Yup.string().required('File name is a required field'),
    description: Yup.string().required('File description is a required field'),
    category: Yup.object().required('Category is a required field'),
    acceptableFileTypes: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one type must be selected'),
    dueDateOrDaysFulFill: Yup.object().shape(
      // Create a object in order to handle the logic of two inputs
      {
        dueDate: Yup.date()
          .nullable()
          .when('daysFulFill', {
            is: daysFulFill => !daysFulFill,
            then: Yup.date()
              .required('Required field')
              .min(dayjs().startOf('day'), 'Must be current or future date'),
            otherwise: Yup.date()
              .nullable()
              .min(dayjs().startOf('day'), 'Must be current or future date'), // Due date can be null if daysFulFill is set
          }),
        daysFulFill: Yup.number()
          .nullable()
          .when('dueDate', {
            is: dueDate => !dueDate,
            then: Yup.number()
              .integer('Must be a number') // Ensure it's an integer
              .typeError('Must be a number') // Show custom error if not a number
              .positive('Must be a positive number') // Ensure it's a positive number
              .required('Required field'),
            otherwise: Yup.number()
              .integer('Must be a number') // Ensure it's an integer
              .typeError('Must be a number') // Show custom error if not a number
              .positive('Must be a positive number') // Ensure it's a positive number
              .nullable(),
          }),
      },
      [
        ['dueDate', 'daysFulFill'],
        ['daysFulFill', 'dueDate'],
      ]
    ),
  })
)

const AddRequestFilesModal = ({
  item,
  open,
  onClose,
  folders,
  currentFolder,
  selectedFolder,
  getFoldersByFolderId,
  breadcrumFolders,
  changeBreadcrumFolders,
  detail,
  loadingFolders,
  changeSelectedFolder,
  saveFileRequest,
  updateFileRequest,
  getCategories,
}) => {
  const styles = useStyles()
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [pathFolder, setPathFolder] = useState('')
  const [busy, setBusy] = useState(false)
  const [createFolderModalOpen, setCreateFolderModalOpen] =
    React.useState(false)
  const [openFromTemplate, onOpenFromTemplate, onCloseFromTemplate] =
    useBooleanHandlers()
  const [categories, setCategories] = useState([])
  const schema = useScheme()

  /***
   *
   */
  const onSubmit = () => {
    if (!pathFolder && !item) return
    saveFile()
  }

  /***
   *
   */
  const formik = useForm(
    schema,
    schemaToInitialState(
      schema,
      item
        ? {
            ...item,
            folderDriveId: '',
            category: { id: 1, name: item.category },
            acceptableFileTypes: [...item.acceptableFileTypes.split(',')],
          }
        : {}
    ),
    onSubmit,
    {
      validateOnChange: false,
      validateOnBlur: false,
      enableReinitialize: true,
    }
  )

  /***
   * Get all categories from DB
   */
  const getAllCategories = React.useCallback(async () => {
    const { payload } = await getCategories()
    if (payload && payload.categories) {
      setCategories(payload.categories)
    }
  }, [getCategories])

  /***
   * Event handler when a check is clicked
   */
  const handleChangeAcceptableFileType = e => {
    const { checked, name } = e.target
    if (checked) {
      formik.setFieldValue('acceptableFileTypes', [
        ...formik.values.acceptableFileTypes,
        name,
      ])
    } else {
      formik.setFieldValue(
        'acceptableFileTypes',
        formik.values.acceptableFileTypes.filter(v => v !== name)
      )
    }
  }

  /***
   * Event handler when select all is clicked
   */
  const onSelectAll = e => {
    formik.setFieldValue('acceptableFileTypes', [
      ...ACCEPTABLE_FILE_TYPES.map(aft => aft.extension)
    ])
  }

  /***
   * Event handler when unselect all is clicked
   */
  const onUnselectAll = e => {
    formik.setFieldValue('acceptableFileTypes', [])
  }

  /***
   * Call method from WS
   */
  useEffect(() => {
    getAllCategories()
  }, [getAllCategories])

  /***
   * In charge of create the request to create / edit a file request
   */
  const saveFile = () => {
    setIsSend(true)
    const dueDateSelected = formik.values?.dueDateOrDaysFulFill?.dueDate || null
    const values = {
      ...formik.values,
      folderPath: pathFolder,
      folderId:
        item && selectedFolder.id !== item.folderDriveId
          ? selectedFolder.id
          : selectedFolder.id,
      orgId: detail.id,
      id: item ? item.id : '',
      category: formik.values.category.name,
      acceptableFileTypes: formik.values.acceptableFileTypes.join(','),
      dueDate: dayjs(dueDateSelected).isValid()
        ? dayjs.utc(dueDateSelected).format('YYYY-MM-DD HH:mm:ss')
        : null,
      daysFulFill: formik.values?.dueDateOrDaysFulFill?.daysFulFill,
    }

    // Update process is un dated because it was removed on front
    if (item) {
      updateFileRequest(values)
        .then(() => {
          successAlert(
            `${formik.values.fileName} upload request has been updated`
          )
          setBusy(false)
          setIsSend(false)
          formik.resetForm()
          setPathFolder('')
          onClose()
        })
        .catch(() => {
          errorAlert('Error')
          onClose()
        })
    } else {
      saveFileRequest(values)
        .then(() => {
          successAlert(
            `${formik.values.fileName} upload request has been sent to this organization`
          )
          setBusy(false)
          formik.resetForm()
          setPathFolder('')
          setIsSend(false)
          onClose()
        })
        .catch(() => {
          errorAlert('Error')
          onClose()
        })
    }
  }

  const executeSubmit = () => {
    setBusy(true)
    formik.handleSubmit()
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const openP = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const selectFolder = folder => {
    changeSelectedFolder(folder)
  }

  const applySelectFolder = () => {
    if (!emptyArray(breadcrumFolders)) {
      const lastSame = breadcrumFolders.find(i => i.id === selectedFolder.id)
      setPathFolder(
        breadcrumFolders.map(i => i.name).join(' > ') +
          (lastSame ? '' : ' > ' + selectedFolder.name)
      )
    } else {
      setPathFolder(selectedFolder.name)
    }

    handleClose()
  }

  const openFolder = folder => {
    getFoldersByFolderId(folder).then(() => {
      let copyBreacrumFolders = [...breadcrumFolders]
      copyBreacrumFolders.push(folder)
      changeBreadcrumFolders(copyBreacrumFolders)
    })
  }

  const backFolder = () => {
    let copyBreacrumFolders = [...breadcrumFolders]
    let folder = {
      id: detail.folderGoogleDriveId,
      name: detail.orgName,
    }
    copyBreacrumFolders.pop()

    if (!emptyArray(copyBreacrumFolders)) {
      folder = copyBreacrumFolders[copyBreacrumFolders.length - 1]
    }

    getFoldersByFolderId(folder).then(() => {
      changeBreadcrumFolders(copyBreacrumFolders)
    })
  }

  const closeModal = () => {
    formik.resetForm()
    onClose()
    setBusy(false)
  }

  const openCreateFolderModal = () => {
    setCreateFolderModalOpen(true)
  }

  const closeCreateFolderModal = () => {
    setCreateFolderModalOpen(false)
  }

  const handleSelectedFile = file => {
    formik.setFieldValue('fileName', file.name)
    formik.setFieldValue('description', file.description)
    formik.setFieldValue('daysFulFill', file.daysToFill)
  }

  useEffect(() => {
    if (item && Object.entries(item).length) {
      setPathFolder(item.folderPath)
      changeSelectedFolder({ id: item.folderDriveId, name: item.folderPath })
    } else {
      setPathFolder('')
    }
  }, [item])

  return (
    <>
      <ModalDialog
        open={open}
        onClose={closeModal}
        title={
          item && item.id
            ? trans('edit-file-upload-request')
            : trans('request-a-new-file-upload')
        }
        actions={
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                disabled={isSend}
                onClick={closeModal}
              >
                {trans('cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={isSend}
                pending={isSend}
                onClick={executeSubmit}
              >
                {trans('confirm')}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <Typography variant="body1" weight="bold" gutter="label">
              {trans('file-details')}
            </Typography>
          </Grid>
          <Grid>
            <Button variant="text" color="primary" onClick={onOpenFromTemplate}>
              <IconAddRequestTemplate />
              {trans('add-from-template')}
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <TextField
              label={trans('file-name')}
              placeholder={trans('required')}
              required
              maxLength={255}
              {...formik.getFieldProps('fileName')}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              options={categories}
              required
              disableClearable
              label={trans('categories')}
              placeholder={trans('required')}
              {...formik.getFieldProps('category')}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6} sm={6}>
                <Typography
                  weight="500"
                  color="label"
                  gutter="label"
                  variant="body2"
                  fontFamily="lexend"
                >
                  {trans('acceptable-file-types')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                className={styles.acceptableFileTypeSelectAll}
              >
                <Typography
                  className={styles.acceptableFileTypeSelectAllText}
                  weight="500"
                  color="label"
                  gutter="label"
                  variant="body2"
                  fontFamily="manrope"
                  onClick={onSelectAll}
                >
                  {trans('select-all')}
                </Typography>
                <Typography
                  className={styles.acceptableFileTypeSelectAllText}
                  weight="500"
                  color="label"
                  gutter="label"
                  variant="body2"
                  fontFamily="manrope"
                  onClick={onUnselectAll}
                >
                  {trans('unselect-all')}
                </Typography>
              </Grid>
            </Grid>
            <Divider className={styles.dividerStyles} light />

            {/* Word processor */}
            <div>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  className={styles.fileTypeTextContainer}
                >
                  <Typography
                    className={styles.fileTypeText}
                    weight="400"
                    color="label"
                    gutter="label"
                    variant="body2"
                    fontFamily="lexend"
                  >
                    {trans('word-processor')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <div>
                    {ACCEPTABLE_FILE_TYPES.map((item, index) => {
                      if (item.type === 'word-processor') {
                        return (
                          <FormControlLabel
                            key={index}
                            className={styles.fileTypeItem}
                            control={
                              <CheckBox
                                color="secondary"
                                name={item.extension}
                                id={`check-${index}`}
                                checked={formik.values?.acceptableFileTypes?.includes(
                                  item.extension
                                )}
                                onChange={handleChangeAcceptableFileType}
                              />
                            }
                            label={item.extension}
                          />
                        )
                      }
                    })}
                  </div>
                </Grid>
              </Grid>
              <Divider className={styles.dividerStyles} light />
            </div>

            {/* Spreadsheet */}
            <div>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  className={styles.fileTypeTextContainer}
                >
                  <Typography
                    className={styles.fileTypeText}
                    weight="400"
                    color="label"
                    gutter="label"
                    variant="body2"
                    fontFamily="lexend"
                  >
                    {trans('spreadsheet')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <div>
                    {ACCEPTABLE_FILE_TYPES.map((item, index) => {
                      if (item.type === 'spreadsheet') {
                        return (
                          <FormControlLabel
                            key={index}
                            className={styles.fileTypeItem}
                            control={
                              <CheckBox
                                color="secondary"
                                name={item.extension}
                                id={`check-${index}`}
                                checked={formik.values?.acceptableFileTypes?.includes(
                                  item.extension
                                )}
                                onChange={handleChangeAcceptableFileType}
                              />
                            }
                            label={item.extension}
                          />
                        )
                      }
                    })}
                  </div>
                </Grid>
              </Grid>
              <Divider className={styles.dividerStyles} light />
            </div>

            {/* Image */}
            <Grid container>
              <Grid
                item
                xs={12}
                sm={3}
                className={styles.fileTypeTextContainer}
              >
                <Typography
                  className={styles.fileTypeText}
                  weight="400"
                  color="label"
                  gutter="label"
                  variant="body2"
                  fontFamily="lexend"
                >
                  {trans('Image')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <div>
                  {ACCEPTABLE_FILE_TYPES.map((item, index) => {
                    if (item.type === 'image') {
                      return (
                        <FormControlLabel
                          key={index}
                          className={styles.fileTypeItem}
                          control={
                            <CheckBox
                              color="secondary"
                              name={item.extension}
                              id={`check-${index}`}
                              checked={formik.values?.acceptableFileTypes?.includes(
                                item.extension
                              )}
                              onChange={handleChangeAcceptableFileType}
                            />
                          }
                          label={item.extension}
                        />
                      )
                    }
                  })}
                </div>
              </Grid>
            </Grid>

            {emptyArray(formik.values.acceptableFileTypes) && busy && (
              <div style={{ marginTop: '5px' }}>
                <Typography
                  style={{ fontSize: '0.75rem' }}
                  variant="body2"
                  color="error"
                >
                  At least one type is required
                </Typography>
              </div>
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={trans('description')}
              placeholder={trans('required')}
              multiline
              minRows={4}
              maxRows={10}
              maxLength={255}
              {...formik.getFieldProps('description')}
            />
          </Grid>

          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '90%' }}>
              <Typography
                variant="body2"
                weight="700"
                style={{ color: '#4e5978', margin: '8px 0' }}
              >
                {trans('location')}
              </Typography>
              <TextField
                disabled={true}
                placeholder={trans('Choose a folder')}
                style={{ width: '100%' }}
                value={pathFolder}
              />
            </div>

            <div>
              <Button
                aria-describedby={id}
                variant="outlined"
                color="primary"
                size="large"
                style={{ marginTop: '35px' }}
                onClick={handleClick}
              >
                {trans('browse')}
              </Button>

              <Popover
                id={id}
                open={openP}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <div>
                  <Loader open={loadingFolders} />
                  <div onClick={backFolder}>
                    <Typography
                      variant="body1"
                      weight="800"
                      style={{
                        color: '#4e5978',
                        margin: '8px 0',
                        padding: '10px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {!emptyArray(breadcrumFolders) && (
                        <ArrowBackIcon
                          style={{ marginRight: '20px', cursor: 'pointer' }}
                        />
                      )}{' '}
                      {currentFolder?.name}
                    </Typography>
                  </div>
                  <Divider light />
                  <div
                    style={{
                      height: '200px',
                      minWidth: '400px',
                      overflowY: 'scroll',
                    }}
                  >
                    {folders.map(f => (
                      <div key={f.id}>
                        <Typography
                          variant="body2"
                          weight="600"
                          style={{
                            color: '#4e5978',
                            padding: '14px 16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            background:
                              selectedFolder?.id === f.id
                                ? '#f1f3f9'
                                : 'transparent',
                          }}
                        >
                          <span onClick={() => selectFolder(f)}>
                            {' '}
                            <FolderIcon style={{ marginRight: '10px' }} />
                            {f.name}
                          </span>
                          <div onClick={() => openFolder(f)}>
                            <ArrowForwardIcon
                              style={{
                                marginLeft: '50px',
                                fontSize: '16px',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                        </Typography>
                      </div>
                    ))}
                  </div>
                  <Divider light />
                  <div
                    style={{
                      padding: '10px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      color="primary"
                      onClick={() => openCreateFolderModal()}
                    >
                      <CreateNewFolderIcon />
                    </IconButton>

                    <Button
                      onClick={applySelectFolder}
                      variant="outlined"
                      color="success"
                    >
                      {trans('select-folder')}
                    </Button>
                  </div>
                </div>
              </Popover>
            </div>
          </Grid>
          {busy && !pathFolder && (
            <small
              style={{ width: '100%', padding: '0 16px', color: '#ff5225' }}
            >
              Folder location is a required field
            </small>
          )}

          <Grid item sm={4} xs={12}>
            <TextField
              label={trans('days-to-fulfil')}
              placeholder={trans('optional')}
              {...formik.getFieldProps('dueDateOrDaysFulFill.daysFulFill')}
              error={formik?.errors?.dueDateOrDaysFulFill?.daysFulFill}
            />
          </Grid>

          <Grid
            item
            sm={1}
            xs={12}
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Typography variant="body1" color="placeholder">
              {trans('or')}
            </Typography>
          </Grid>

          <Grid item sm={6} xs={12}>
            <DatePicker
              label={trans('Due Date')}
              placeholder={trans('optional')}
              maxLength={5}
              align="center"
              name={'dueDate'}
              {...formik.getFieldProps('dueDateOrDaysFulFill.dueDate')}
              onChange={dateSelected => {
                if (!dayjs(dateSelected).isValid()) {
                  // Delete obj from formik, so the valitations work properly
                  formik.setFieldValue(
                    'dueDateOrDaysFulFill.dueDate',
                    undefined
                  )
                } else {
                  formik.setFieldValue(
                    'dueDateOrDaysFulFill.dueDate',
                    dayjs(dateSelected)
                  )
                }
              }}
              error={formik?.errors?.dueDateOrDaysFulFill?.dueDate}
            />
          </Grid>
        </Grid>
      </ModalDialog>

      <CreateFolderModal
        open={createFolderModalOpen}
        onClose={() => closeCreateFolderModal()}
      />

      <FormTemplateFile
        open={openFromTemplate}
        handleSelectedFile={handleSelectedFile}
        onClose={onCloseFromTemplate}
      />
    </>
  )
}

export default connect(AddRequestFilesModal)
