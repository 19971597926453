import Grid from '@material-ui/core/Grid';
import React from 'react';
import ChipGroup from '../../../../../components/common/Chip/ChipGroup';
import { EXISTS } from '../../../../../constants/filter';
import { useTrans } from '../../../../../services/i18n';
import { ChipOption } from '../ChipOption';

export const EmptyChipGroup = ({ item, onDeleteFilter, onOpenFilter }) => {
  const { trans } = useTrans();
  const exists = item.type === EXISTS;
  const filterOption = item.filterOption || {};

  return (
    <Grid item component={ChipGroup}>
      <ChipOption
        name={filterOption.name ? trans(filterOption.name) : item.key ? trans(item.key) : '-'}
        value={exists ? trans('exists') : trans('does_not_exists')}
        onClick={onOpenFilter ? onOpenFilter.bind(null, filterOption) : undefined}
        onDelete={onDeleteFilter ? onDeleteFilter.bind(null, item.key) : undefined}
      />
    </Grid>
  );
};
