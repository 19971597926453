import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import FormRelease from '../../../components/forms/FormRelease';
import Loader from '../../../components/common/Loader'
import connect from '../connect'

const UpdateRelease = ({ releases, findOne, updateRelease }) => {
  const params = useParams()
  const { releaseDetail } = releases
  const [pending, setPending] = useState(false)

  useEffect(() => {
    setPending(true)
    findOne({ id: params.id }).then(() => setPending(false))
  }, [params.id])

  return (
    <>
    {pending && <Loader open={pending} />}
    {!pending && releaseDetail && (
    <FormRelease item={releaseDetail} saveRelease={updateRelease} />
    )}
    </>
  )
}

export default connect(UpdateRelease)
