import React from 'react'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import { Card, Grid } from '@material-ui/core'
import Chart from './Chart'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'
import TierBreakdown from './TierBreakdown'
import { ACCOUNT_FEATURES } from '../../../constants/subscriptionSettings'

const useStyles = makeStyles({
  flex: {
    display: 'flex',
  },
  alignEnd: {
    alignItems: 'center',
  },
  number: {
    fontWeight: 'bold',
    fontSize: 40,
  },
  title: {
    padding: '8px 0',
    fontWeight: 'bold',
    color: '#4e5978',
    fontSize: 16,
  },
  border: {
    width: 4,
    height: 70,
    borderRadius: 2,
  },
})

export default function AccountsBreakdown({ options, numbers }) {
  const { trans } = useTrans()
  const classes = useStyles()
  return (
    <>
      <Card>
        <Paper component={SpacingContainer} py={4} px="5sm3">
          <Grid container spacing={3}>
            <Grid item xs zeroMinWidth style={{ textAlign: 'center' }}>
              <Typography
                variant="h5"
                weight="bold"
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                {trans('accounts-breakdown')}{' '}
                <ShowTooltip sectionToShow="account-breakdown" />
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Chart options={options} />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              <TierBreakdown
                tier={ACCOUNT_FEATURES.LIMITED}
                classes={classes}
                total={numbers.limited}
                percentage={numbers.percentLimited}
              />
            </Grid>
            <Grid item>
              <TierBreakdown
                tier={ACCOUNT_FEATURES.ADVANTAGE}
                classes={classes}
                total={numbers.advantage}
                percentage={numbers.percentAdvantage}
              />
            </Grid>
            <Grid item>
              <TierBreakdown
                tier={ACCOUNT_FEATURES.PRO}
                classes={classes}
                total={numbers.pro}
                percentage={numbers.percentPro}
              />
            </Grid>
            <Grid item>
              <TierBreakdown
                tier={ACCOUNT_FEATURES.SAP_ADVANTAGE}
                classes={classes}
                total={numbers.sapAdvantage}
                percentage={numbers.percentSapAdvantage}
              />
            </Grid>
            <Grid item>
              <TierBreakdown
                tier={ACCOUNT_FEATURES.APP_MAX}
                classes={classes}
                total={numbers.appMax}
                percentage={numbers.percentAppMax}
              />
            </Grid>
            <Grid item>
              <TierBreakdown
                tier={ACCOUNT_FEATURES.PROCUREMENT_PRO}
                classes={classes}
                total={numbers.procurementPro}
                percentage={numbers.percentProcurementPro}
              />
            </Grid>
          </Grid>
        </Paper>
      </Card>
    </>
  )
}
