import { connect } from 'react-redux'
import { actions } from '../../../../store/requestTemplateForms'
import { actions as globalSettingsActions } from '../../../../store/globalSettings'
import {
  generateListCallbacks,
} from '../../../../store/helpers/listActionsHelpers'

function mapStateToProps(state) {
  return {
    requestTemplateForms: state.requestTemplateForms,
    forms: state.globalSettings.forms,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    fetchList: () => dispatch(actions.fetchList()),
    findOne: (params) => dispatch(actions.findOne(params)),
    saveRequestTemplateForm: (params) =>
      dispatch(actions.saveRequestTemplateForm(params)),
    deleteRequestTemplateForm: (params) =>
      dispatch(actions.deleteRequestTemplateForm(params)),
    getFeatures: (params) =>
      dispatch(globalSettingsActions.getFeatures(params)),
    getList: (params) => dispatch(actions.getList(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
