import React, { useState } from 'react'
import { Grid, IconButton } from '@material-ui/core'
import styled from 'styled-components'
import IconPublic from '@material-ui/icons/Public'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'
import IconBusiness from '@material-ui/icons/Business'
import connect from '../connect'
import { ITEM_VARIANT } from '../constants'
import ItemMenu from './ItemMenu'

const JobPillType = styled.div`
  display: flex;
  padding: 5px 10px 7px 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 14px;
  background: ${props => props.background || '#FFF2E9'};
  color: ${props => props.color || 'var(--Support-Orange, #FF7825)'};
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const ActionButtons = ({ item, isSaved, markSaved, getDashboard, variant }) => {
  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      <Grid item>
        <JobPillType
          color={item.jobType === 'Grant' ? '#9576FF' : '#FF7825'}
          background={item.jobType === 'Grant' ? '#EEE9FF' : '#FFF2E9'}
        >
          {item.jobType}
        </JobPillType>
      </Grid>

      <ItemMenu item={item} />
    </Grid>
  )
}

export default connect(ActionButtons)
