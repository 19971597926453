import useStyles from './styles'
import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTrans } from '../../../services/i18n'
import Typography from '../../../components/common/Typography'
import { ModalDialog } from '../../../components/common/Dialogs'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import { ReactComponent as IconCheck } from '../../../components/assests/ico-check.svg'
import Counter from './Counter'
import { Button } from '../../../components/common/Buttons'
import connect from '../Details/connect'
import { useAlertContext } from '../../../contexts/AlertContext'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const ModalSeats = ({
  open,
  onClose,
  item,
  addRemoveLicenses,
  refreshList = null,
  updateItemListLicense,
}) => {
  const styles = useStyles()
  const { trans } = useTrans()
  const [number, setNumber] = React.useState(0)
  const [currentLicenses, setCurrentLicenses] = React.useState(0)
  const { successAlert, errorAlert } = useAlertContext()
  const [pending, setPending] = React.useState(false)
  const [alert, setAlert] = React.useState(false)

  const executeChange = async () => {
    try {
      setPending(true)
      const res = await addRemoveLicenses({
        orgId: item.id,
        licenses: number,
        // licenses: Number(number) + Number(currentLicenses),
        // availableSeats:  Number(number) + Number(item.availableSeats),
      })
      setPending(false)
      if (res.payload.success) {
        successAlert(res.payload.message)
      } else {
        errorAlert("Server error, please refresh the page or try again later.")
      }
      if (refreshList) {
        updateItemListLicense({
          id: item.id,
          licenses: number,
          // licenses: Number(number) + Number(currentLicenses),
          // availableSeats:  Number(number) + Number(item.availableSeats),
        })
      }
      onClose()
    } catch (error) {
      errorAlert("Server error, please refresh the page or try again later.")
      setPending(false)
      onClose()
    }
  }

  useEffect(() => {
    if (item) {
      setNumber(item.licenses)
      setCurrentLicenses(item.licenses)
    }
  }, [item])

  return (
    <ModalDialog
      className={styles.root}
      title={
        <Grid
          container
          direction={'column'}
          spacing={1}
          className={styles.title}
        >
          <Grid item>
            <Grid container spacing={1} wrap={'nowrap'}>
              <Grid item>
                <IconCheck style={{ color: '#17B471' }} />
              </Grid>
              <Grid item>
                <Typography variant="h5" weight="bold">
                  {trans('addSeatsTitle')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      open={open}
      onClose={onClose}
      actions={
        <Grid container>
          <Grid item sm={12} xs={12} className={styles.saveButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => executeChange()}
              pending={pending}
            >
              {trans('update')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Paper
        bgColor="paperSecondary"
        component={SpacingContainer}
        px={2}
        py={2}
        className={styles.wrapper}
        onClick={e => e.stopPropagation()}
      >
        {/* <SpacingContainer className={styles.container}>
          <Grid container spacing={3} className={styles.banner}>
            <Grid item sm={2} xs={1} className={styles.iconBanner}>
              <CorporateFareIcon />
            </Grid>
            <Grid item sm={10} xs={11} className={styles.titleBanner}>
              <Typography variant="h6">{item.orgName}</Typography>
            </Grid>
          </Grid>
        </SpacingContainer> */}
        <SpacingContainer>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item sm={12} xs={12} className={styles.question}>
              <Typography variant="body1" weight="600">
                This organization's plan has <strong>{currentLicenses}</strong>{' '}
                seats right now, with{' '}
                <strong>
                  {Math.abs(
                    Math.min(0, item.allowedUsersPerTier - item.members.length)
                  )}
                </strong>{' '}
                active people on there team filling those seats.
              </Typography>
            </Grid>
            <Paper
              bgColor="paperSecondary"
              component={SpacingContainer}
              radius="4"
              style={{ display: 'flex', marginTop: '25px' }}
            >
            </Paper>
            <Grid item sm={12} xs={12} className={styles.question}>
              <Typography variant="body1" weight="600">
                Current seats
              </Typography>
            </Grid>
            <Grid item sm={4} xs={4}>
              <Counter currentLicenses={item.licenses} number={number} setNumber={setNumber} setAlert={setAlert} />
            </Grid>
            <Grid item sm={12} xs={12} className={styles.question}>
              <Typography variant="body1" weight="600">
                <span>
                  This organizaton currently has {item.members.length} seats in
                  use.
                </span>
              </Typography>
            </Grid>

            {alert && (
              <Paper
                bgColor="paperSecondary"
                component={SpacingContainer}
                py={2}
                px={2}
                radius="4"
                style={{ display: 'flex', marginTop: '25px' }}
              >
                <InfoOutlinedIcon
                  color="primary"
                  style={{ marginRight: '16px' }}
                />
                <Typography variant="body2" color="placeholder">
                  The user(s) associated with this additional seat(s) must be
                  deleted from the organizaton first
                </Typography>
              </Paper>
            )}
          </Grid>
        </SpacingContainer>
      </Paper>
    </ModalDialog>
  )
}

export default connect(ModalSeats)
