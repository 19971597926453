import React from 'react'
import ConfirmDialog from '../../../../../components/generics/Confirm/Dialog'
import { useTrans } from '../../../../../services/i18n'
import ModalText from './ModalText'
import connect from '../../../connect'

function DeleteModal({
  item,
  open,
  onClose,
  updateStatus,
  fetchList,
  canRestore,
}) {
  const { trans } = useTrans()

  const handleConfirm = () => {
    updateStatus({
      id: item.id,
      status: canRestore ? 'active' : 'deleted',
    }).then(() => {
      fetchList()
    })

    onClose()
  }

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      onConfirm={handleConfirm}
      title={trans(
        canRestore ? 'restore-job-opportunity' : 'delete-job-opportunity'
      )}
      text={
        <ModalText
          title={trans(
            canRestore
              ? 'sure-you-want-to-restore-opportunity'
              : 'sure-you-want-to-delete-opportunity'
          )}
          itemName={item.title}
        />
      }
      textStyles={{ margin: 0, padding: 0 }}
      hideIcon
      confirmType={canRestore}
    />
  )
}

export default connect(DeleteModal)
