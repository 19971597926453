import MUIAvatar from '@material-ui/core/Avatar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import Typography from '../../Typography'

const useStyles = makeStyles(theme => ({
  label: { maxWidth: '100%', textAlign: 'center' },

  'bg-gray': { backgroundColor: theme.palette.gray.main },
  'bg-transparent': { color: 'inherit', backgroundColor: 'transparent' },
  'bg-success': { color: 'white', backgroundColor: theme.palette.success.main },
  'bg-secondary': {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '12px',
  },
  'bg-gray-second': { color: '#4e5978', backgroundColor: '#f1f3f9', borderRadius: '8px' },

  40: { width: 40, height: 40 },
  60: { width: 60, height: 60 },
  card: { width: 100, height: 'auto' },
  fullWidth: { width: '100%', height: 'auto' },

  left: { marginRight: theme.spacing(1.5) },
  right: { marginLeft: theme.spacing(1.5) },
}))

export function getAvatarValue(value) {
  if (!value) return 'UN'
  const [w1, w2] = String(value).split(' ')
  const res =
    w1.length && !w2
      ? w1.substr(0, 2)
      : [w1.substr(0, 1), w2.substr(0, 1)].join('')
  return res.toUpperCase()
}

export default function Avatar(props) {
  const styles = useStyles()
  const {
    value,
    children,
    size,
    className,
    position,
    src,
    variant,
    weight = 800,
    bgColor = '',
    variantAvatar = 'circular',
    ...rest
  } = props

  const classNames = clsx(
    className,
    size && styles[size],
    position && styles[position],
    bgColor && styles[`bg-${bgColor}`]
  )

  return src && !children ? (
    <MUIAvatar variant={variantAvatar} className={classNames} src={src} {...rest} />
  ) : (
    <MUIAvatar variant={variantAvatar} className={classNames} {...rest}>
      <Typography
        component="span"
        weight={weight}
        variant={variant}
        className={styles.label}
      >
        {children ? children : getAvatarValue(value)}
      </Typography>
    </MUIAvatar>
  )
}
