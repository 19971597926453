import Grid from '@material-ui/core/Grid'
import React, { useCallback } from 'react'
import { Button } from '../../common/Buttons'
import { ModalDialog } from '../../common/Dialogs'
import { useTrans } from '../../../services/i18n'
import { useAlertContext } from '../../../contexts/AlertContext'
import Typography from '../../common/Typography'
import { SelectField } from '../../common/Inputs'
import { getInitialValues, useScheme, roles } from './helpers'
import { useForm } from '../../../hooks/useForm'
import MenuItem from '@material-ui/core/MenuItem'
//import Autocomplete from '@material-ui/lab/Autocomplete';
import { Autocomplete } from '../../common/Inputs'
import { selectRoles, selectUserRole } from '../../../selectors'
import { useSelector } from 'react-redux'
const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  enableReinitialize: true,
}

export default function FormUserStaff({
  item,
  open,
  onClose,
  handleAction,
  notIsUsfcr = [],
  fetchList,
}) {
  const { trans } = useTrans()
  const schema = useScheme()
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const roleList = useSelector(selectRoles)
  const userRole = useSelector(selectUserRole)
  const allowedRoles =
    userRole.key === 'admin' ? ['defualt'] : ['defualt', 'admin']
  const closeModal = () => {
    resetForm()
    onClose()
  }

  const onSubmit = useCallback(
    values => {
      const editting = !!values.id
      if (typeof handleAction !== 'function') return
      setIsSend(true)
      Promise.resolve(handleAction(values))
        .then(res => {
          if (res.error && res.error.message) {
            errorAlert()
          } else {
            const messageEdit = `${item?.name} has been updated as USFCR staff`
            const messageAdd = `Users has been added as USFCR staff`
            successAlert(editting ? messageEdit : messageAdd)
            closeModal()
            fetchList()
          }
        })
        .catch(errorAlert)
        .finally(() => setIsSend(false))
    },
    [closeModal, handleAction, infoAlert, errorAlert, successAlert]
  )

  const {
    getFieldProps,
    handleSubmit,
    values,
    isSubmitting,
    isValid,
    resetForm,
  } = useForm(schema, getInitialValues(item), onSubmit, formOptions)

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={values.id ? trans('edit-user') : trans('add-user')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSend}
              onClick={closeModal}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={handleSubmit}
            >
              {trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        {!values.id && (
          <Grid item sm={7} xs={12}>
            <Autocomplete
              options={notIsUsfcr}
              required
              multiple
              label={trans('select-user')}
              placeholder={trans('required')}
              disableCloseOnSelect
              {...getFieldProps('usersId')}
            />
          </Grid>
        )}
        <Grid item sm={5} xs={12}>
          <Typography
            weight="500"
            fontFamily="lexend"
            color="label"
            gutter="label"
            variant="body2"
            style={{ marginBottom: '0.5rem' }}
          >
            {trans('role')}
          </Typography>
          <SelectField
            type="select"
            placeholder={trans('required')}
            {...getFieldProps('role')}
            getRenderValue={item =>
              item && roles.find(i => i.value === item).text
            }
          >
            {roles.map((item, ind) => (
              <MenuItem key={ind} value={item.value}>
                {item.text}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item sm={5} xs={12}>
          <Typography
            weight="500"
            fontFamily="lexend"
            color="label"
            gutter="label"
            variant="body2"
            style={{ marginBottom: '0.5rem' }}
          >
            {trans('subRole')}
          </Typography>
          <SelectField
            type="select"
            placeholder={trans('required')}
            {...getFieldProps('sub_role_id')}
            getRenderValue={item =>
              item && roleList.find(i => i.id === item).key
            }
          >
            {roleList
              .filter(q => !allowedRoles.includes(q.key))
              .map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.key}
                </MenuItem>
              ))}
          </SelectField>
        </Grid>
      </Grid>
    </ModalDialog>
  )
}
