import Grid from '@material-ui/core/Grid'
import React, { useCallback, useEffect } from 'react'
import { Button } from '../../../../components/common/Buttons'
import { ModalDialog } from '../../../../components/common/Dialogs'
import { useTrans } from '../../../../services/i18n'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { SpacingContainer } from '../../../../components/common/Containers'
import { getInitialValues, useScheme } from './helpers'
import { useForm } from '../../../../hooks/useForm'
import { TextField } from '../../../../components/common/Inputs'
import connect from '../../connect'
import Typography from '../../../../components/common/Typography'

const formOptions = {
  validateOnMount: false,
  validateOnChange: true,
  enableReinitialize: true,
}

const EditTooltip = ({
  currentSection,
  open,
  onClose,
  updateTooltip,
  changeCurrentSection,
}) => {
  const { trans } = useTrans()
  const schema = useScheme()
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)

  const closeModal = () => {
    resetForm()
    onClose()
    changeCurrentSection(null)
  }

  const onSubmit = useCallback(
    async values => {
      setIsSend(true)
      Promise.resolve(
        updateTooltip({
          menu: currentSection.menu,
          section: currentSection.section,
          ...values,
        })
      )
        .then(res => {
          if (res.payload && res.payload.success) {
            successAlert('Tooltip was successfully updated')
            closeModal()
          } else {
            errorAlert(
              res?.payload?.message
                ? trans(res.payload.message)
                : res?.error?.message
            )
          }
        })
        .catch(err => {
          errorAlert(err.message)
        })
        .finally(() => setIsSend(false))
    },
    [
      closeModal,
      updateTooltip,
      infoAlert,
      errorAlert,
      successAlert,
      currentSection,
    ]
  )

  const { getFieldProps, handleSubmit, resetForm, setFieldValue } = useForm(
    schema,
    getInitialValues({ currentSection }),
    onSubmit,
    formOptions
  )

  useEffect(() => {
    if (currentSection) {
      console.log(currentSection)
      setFieldValue('message', currentSection.message)
    }
  }, [currentSection])

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={trans('message')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSend}
              onClick={closeModal}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={handleSubmit}
            >
              {trans('save')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer px={3} py={2}>
        <Typography
          style={{ marginTop: '10x', marginBottom: '20px' }}
          variant="body2"
          weight="500"
          fontFamily="lexend"
        >
          {trans('edit-message')}
        </Typography>

        <TextField
          multiline
          minRows={5}
          label={trans('message') + ' (180 Characters maximum)'}
          placeholder={trans('message')}
          {...getFieldProps('message')}
          name="message"
        />
      </SpacingContainer>
    </ModalDialog>
  )
}

export default connect(EditTooltip)
