import { MenuItem, TableCell, TableRow, TextField } from '@material-ui/core'
import React from 'react'
import Typography from '../../../components/common/Typography'
import { SelectField } from '../../../components/common/Inputs'
import {
  COURSE_LEVELS_LABELS,
  COURSE_TYPES_LABELS,
} from '../../../constants/subscriptionSettings'
import { useTrans } from '../../../services/i18n'

function Course({ course, onChange }) {
  const { trans } = useTrans()

  const handleChange = e => {
    onChange(course.id, {
      [e.target.name]: e.target.value,
    })
  }

  return (
    <TableRow>
      <TableCell align="left">
        <Typography variant="body1" size="2em" weight={500}>
          {course.title}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <TextField
          placeholder={trans('required')}
          required
          maxLength={255}
          value={course?.learnworldsId || ''}
          onChange={handleChange}
          name="learnworldsId"
        />
      </TableCell>
      <TableCell align="left">
        <div style={{ width: 200 }}>
          <SelectField
            name="objectType"
            value={course?.objectType ?? COURSE_TYPES_LABELS.course}
            onChange={handleChange}
            getRenderValue={item => COURSE_TYPES_LABELS[item]}
          >
            {Object.keys(COURSE_TYPES_LABELS).map((item, ind) => (
              <MenuItem key={ind} value={item}>
                {COURSE_TYPES_LABELS[item]}
              </MenuItem>
            ))}
          </SelectField>
        </div>
      </TableCell>
      <TableCell align="left">
        <SelectField
          name="level"
          value={course?.level ?? 'none'}
          onChange={handleChange}
          getRenderValue={item => COURSE_LEVELS_LABELS[item]}
        >
          {Object.keys(COURSE_LEVELS_LABELS).map((item, ind) => (
            <MenuItem key={ind} value={item}>
              {COURSE_LEVELS_LABELS[item]}
            </MenuItem>
          ))}
        </SelectField>
      </TableCell>
    </TableRow>
  )
}

export default Course
