import makeValidationSchema from '../../../hooks/makeValidationSchema'
import * as Yup from 'yup'

export const useScheme = makeValidationSchema(trans =>
  Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string().required(
      trans('empty-not-allowed', { field: trans('name') })
    ),
    emailSubject: Yup.string().required(
      trans('empty-not-allowed', { field: trans('emailSubject') })
    ),
    primaryEmailBody: Yup.string().required(
      trans('empty-not-allowed', { field: trans('primaryEmailBody') })
    ),
    secondaryEmailBody: Yup.string().required(
      trans('empty-not-allowed', { field: trans('secondaryEmailBody') })
    ),
  })
)
