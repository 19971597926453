import {
  AUTOCOMPLETES,
  FILTER_COMPONENTS,
} from '../../components/generics/SearchTopPanel/FiltersMenu/Filters'
import {
  DATE_SCHEMA,
  DEFAULT_DATE_STATE,
  DEFAULT_STRING_STATE,
  DEFAULT_STRING_STATE_SINGLE,
  SINGLE_VALUE_SCHEMA,
  REQUIRED_STRING_FILTER_OPTIONS,
  NAICS_PSC_VENDOR_SCHEMA,
  SELECTION_OR_STRING_SCHEMA,
} from '../../constants/filter'

export const filterOptions = [
  //NAICS & PSCs
  {
    name: 'naics-&-psc',
    value: 'naics&PSC',
    multiple: {
      filters: ['primaryNaics', 'primaryPsc'],
    },
    scheme: NAICS_PSC_VENDOR_SCHEMA,
    component: FILTER_COMPONENTS.MULTIPLE,
  },
  {
    hidden: true,
    name: 'NAICS-category',
    scheme: SELECTION_OR_STRING_SCHEMA,
    value: 'primaryNaics',
    component: FILTER_COMPONENTS.DROPDOWN,
    autocomplete: AUTOCOMPLETES.NAICS,
    default: { ...DEFAULT_STRING_STATE },
  },
  {
    hidden: true,
    name: 'PSC-category',
    scheme: SELECTION_OR_STRING_SCHEMA,
    value: 'primaryPsc',
    component: FILTER_COMPONENTS.DROPDOWN,
    autocomplete: AUTOCOMPLETES.PSC,
    default: { ...DEFAULT_STRING_STATE },
    options: {
      menuItems: REQUIRED_STRING_FILTER_OPTIONS,
    },
  },
  //Federal Grant Programs
  {
    name: 'federal-grant-programs',
    value: 'federalGrantPrograms',
    scheme: SINGLE_VALUE_SCHEMA,
    component: FILTER_COMPONENTS.TEXT,
    default: { ...DEFAULT_STRING_STATE_SINGLE },
    options: { label: 'Federal grant program' },
  },
  {
    name: 'Solicitation',
    value: 'opportunityNumber',
    scheme: SINGLE_VALUE_SCHEMA,
    component: FILTER_COMPONENTS.TEXT,
    default: { ...DEFAULT_STRING_STATE_SINGLE },
    options: { label: 'Solicitation' },
  },
  {
    name: 'keywords',
    value: 'keywords',
    scheme: SINGLE_VALUE_SCHEMA,
    component: FILTER_COMPONENTS.TEXT,
    default: { ...DEFAULT_STRING_STATE_SINGLE },
  },
  {
    name: 'Location',
    value: 'location',
    scheme: SINGLE_VALUE_SCHEMA,
    component: FILTER_COMPONENTS.TEXT,
    default: { ...DEFAULT_STRING_STATE_SINGLE },
    options: { label: 'Location' },
  },
  {
    name: 'opportunity-type',
    value: 'jobType',
    scheme: SINGLE_VALUE_SCHEMA,
    component: FILTER_COMPONENTS.TEXT,
    default: { ...DEFAULT_STRING_STATE_SINGLE },
    options: { label: 'Opportunity Type' },
  },
  {
    name: 'date',
    value: 'createdAt',
    scheme: DATE_SCHEMA,
    component: FILTER_COMPONENTS.DATE,
    default: { ...DEFAULT_DATE_STATE },
  },
  {
    name: 'organization-type',
    value: 'lookingForOrganizationTypes',
    scheme: SINGLE_VALUE_SCHEMA,
    component: FILTER_COMPONENTS.TEXT,
    default: { ...DEFAULT_STRING_STATE_SINGLE },
    options: { label: 'Organization Type' },
  },
]

export const orderOptions = [
  {
    name: 'Posted Date',
    value: 'createdAt',
    asc: 'Oldest first',
    desc: 'Newest first',
  },
]