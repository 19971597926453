import React from 'react'
import { intlDateFormat } from '../../../../../utils/dateUtils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { useTrans } from '../../../../../services/i18n'
import DownloadButton from '../../../components/DownloadButton'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import { StyledTypography } from './StyledComponents'
import FolderIcon from '@material-ui/icons/Folder'
import { sortByFoldersFirst } from '../../../../../utils/arrayUtils'

const ItemFile = ({ file, downloadFile, setState }) => {
  console.log('🚀 ~ file: itemFile.js:13 ~ ItemFile ~ file:', file)
  const { trans } = useTrans()

  const onDownloadAll = e => {
    return downloadFile({
      id: file.fileDriveId,
      fileDriveName: file.fileDriveName,
    })
  }

  function formatFileSize(sizeInBytes) {
    const sizeInKB = sizeInBytes / 1024
    if (sizeInKB < 1024) {
      return `${sizeInKB.toFixed(2)} KB`
    } else {
      const sizeInMB = sizeInKB / 1024
      if (sizeInMB < 1024) {
        return `${sizeInMB.toFixed(2)} MB`
      } else {
        const sizeInGB = sizeInMB / 1024
        return `${sizeInGB.toFixed(2)} GB`
      }
    }
  }

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <StyledTypography
            variant="inherit"
            fontFamily="manrope"
            noWrap
            cursor={file.type === 'folder' ? 'pointer' : null}
            color={file.type === 'folder' ? '' : '#000'}
            onClick={() => {
              if (file.type !== 'folder') return
              setState(prev => ({
                ...prev,
                categoryFiles: file.files.sort(sortByFoldersFirst),
                breadcrumb: [...prev.breadcrumb, file.name],
              }))
            }}
          >
            {file.type === 'folder' ? (
              <>
                <FolderIcon style={{ color: '#b2b8cf' }} />
                {file.name}
              </>
            ) : (
              <>
                <InsertDriveFile style={{ color: '#b2b8cf' }} />
                {file.fileDriveName}
              </>
            )}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography
            variant="inherit"
            fontFamily="manrope"
            noWrap
            color={'#000'}
          >
            {file.type === 'folder' ? trans('Folder') : trans('File')}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography
            variant="inherit"
            fontFamily="manrope"
            noWrap
            color={'#000'}
          >
            {file.type === 'folder'
              ? formatFileSize(file.folderSize)
              : file.fileSize}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography
            variant="inherit"
            fontFamily="manrope"
            noWrap
            color={'#000'}
          >
            {intlDateFormat(file.lastUpdated ?? file.updatedAt)}
          </StyledTypography>
        </TableCell>

        <TableCell align="left">
          {file.type === 'folder' ? null : (
            <DownloadButton
              item={file}
              onDownload={onDownloadAll}
              color={'#4E5978'}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  )
}

export default ItemFile
