import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTrans } from '../../../services/i18n'
import Typography from '../../../components/common/Typography'
import { SelectField } from '../../../components/common/Inputs'
import { ModalDialog } from '../../../components/common/Dialogs'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import { Button } from '../../../components/common/Buttons'
import { useAlertContext } from '../../../contexts/AlertContext'
import connect from '../connect'
import { TextField } from '../../../components/common/Inputs'
import { useForm } from '../../../hooks/useForm'
import { getInitialValues, useScheme } from './helpers'
import { formatToTitleCase } from '../../../utils/helpers'
import { MenuItem } from '@material-ui/core'

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  enableReinitialize: true,
}

const AddRolModal = ({
  open,
  onClose,
  item = null,
  createRole,
  rolesList,
  getRolesList,
}) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [pending, setPending] = React.useState(false)
  const schema = useScheme()
  const [roles, setRoles] = useState([])
  const [selectedRol, setSelectedRol] = useState({
    name: 'Default template',
    key: 'defualt',
  })

  const reloadEffect = async () => {
    await getRolesList()
  }

  const onSubmit = async () => {
    try {
      const newRolePermission = rolesList.find(r => r.key === selectedRol.key)
      setPending(true)
      const { payload } = await createRole({
        roleName: values.name,
        permissions: JSON.parse(JSON.stringify(newRolePermission.permissions)),
      })
      setPending(false)
      if (payload.success) {
        successAlert(payload.message)
        getRolesList()
        closeModal()
      } else {
        reloadEffect()
        errorAlert(payload.message)
      }
    } catch (error) {
      errorAlert(
        'Server error, please refresh the page or try again later.www ' +
          error.message
      )
      setPending(false)
      closeModal()
    }
  }

  const { getFieldProps, handleSubmit, values, resetForm } = useForm(
    schema,
    getInitialValues(item),
    onSubmit,
    formOptions
  )

  const closeModal = () => {
    resetForm()
    onClose()
  }

  useEffect(() => {
    if (Array.isArray(rolesList)) {
      const auxRoles = rolesList.map(r => {
        return { name: r.name, key: r.key }
      })
      setRoles(auxRoles)
    }
  }, [rolesList])

  const changeSelect = ev => {
    setSelectedRol(ev.target.value)
  }

  return (
    <ModalDialog
      title={item ? trans('edit-role') : trans('add-new-role')}
      open={open}
      onClose={onClose}
      actions={
        <Grid container>
          <Grid
            item
            sm={12}
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={closeModal}
              style={{ marginRight: '16px' }}
            >
              {trans('cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              pending={pending}
            >
              {item ? trans('save') : trans('create')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Paper>
        <SpacingContainer>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item sm={12} md={6}>
              <Typography
                weight={500}
                variant="body2"
                fontFamily="Lexend"
                fontStyle="normal"
                fontSize="14px"
                lineHeight="18px"
                style={{ marginBottom: '0.5rem', color: '#4E5978' }}
              >
                {trans('role-name')}
              </Typography>
              <TextField placeholder="Required" {...getFieldProps('name')} />
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography
                weight={500}
                variant="body2"
                fontFamily="Lexend"
                fontStyle="normal"
                fontSize="14px"
                lineHeight="18px"
                style={{ marginBottom: '0.5rem', color: '#4E5978' }}
              >
                {trans('based-on')}
              </Typography>
              <SelectField
                {...getFieldProps('basedOn')}
                type="select"
                placeholder={'Default template'}
                name={'basedOn'}
                value={selectedRol}
                getRenderValue={value =>
                  `${value.key === 'defualt' ? 'Default template' : value.name}`
                }
                onChange={changeSelect}
              >
                {roles.map(i => {
                  const rolName =
                    i.name === 'Default' ? 'Default template' : i.name
                  return (
                    <MenuItem key={i.key} value={i}>
                      {rolName}
                    </MenuItem>
                  )
                })}
              </SelectField>
            </Grid>
          </Grid>
        </SpacingContainer>
      </Paper>
    </ModalDialog>
  )
}

export default connect(AddRolModal)
