import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useEffect, useState } from 'react'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import { useAlertContext } from '../../../../contexts/AlertContext'
import AssigmentIcon from '@material-ui/icons/AssignmentOutlined'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'

import connect from '../../connect'
import { Button } from '../../../../components/common/Buttons'
import { TextField } from '../../../../components/common/Inputs'
import ShowTooltip from '../showTooltip'

import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
} from '@material-ui/core'

import IconEdit from '@material-ui/icons/Edit'
import IconDelete from '@material-ui/icons/Delete'
import EditTooltip from '../editTooltip'
import { selectUserPermissions } from '../../../../selectors'
import { useSelector } from 'react-redux'

const AddTooltip = ({ tooltips, changeCurrentSection, deleteTooltip }) => {
  const { trans } = useTrans()
  const [open, onOpen, onClose] = useBooleanHandlers()
  const { successAlert, errorAlert } = useAlertContext()
  const permissions = useSelector(selectUserPermissions)

  useEffect(async () => {}, [])

  const editMessage = item => {
    console.log(item)
    changeCurrentSection(item)
    onOpen()
  }

  const deleteTooltipAction = async item => {
    deleteTooltip(item)
      .then(res => {
        if (res.payload && res.payload.success) {
          successAlert('Tooltip was successfully deleted')
        } else {
          errorAlert(
            res?.payload?.message
              ? trans(res.payload.message)
              : res?.error?.message
          )
        }
      })
      .catch(err => {
        errorAlert(err.message)
      })
      .finally(() => {})
  }

  const isEnabled = (att = 'view') => {
    const routeKey = 'tooltips-management'
    const module = permissions.find(p => p.key === routeKey)
    if (!module) return true

    const subModule = module.submodules.find(sm => sm.key === module.menuOption)

    if (!subModule) return true
    return subModule[att]
  }

  return (
    <SpacingContainer py={2}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              variant="h4"
              weight="500"
              fontFamily="lexend"
              style={{ alignItems: 'center', display: 'flex' }}
            >
              {trans('tooltips-added')}{' '}
              <ShowTooltip sectionToShow="tooltips-added" />
            </Typography>
            <Typography
              style={{ marginTop: '16px' }}
              variant="body2"
              fontFamily="lexend"
            >
              {trans('manage-added-tooltips')}
            </Typography>
          </Grid>
        </Grid>
        <Divider light style={{ marginTop: '20px' }} />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ width: '200px' }}>
                {trans('location')}
              </TableCell>
              <TableCell style={{ width: '200px' }} align="left">
                {trans('table')}
              </TableCell>
              <TableCell align="left">{trans('message')}</TableCell>
              <TableCell style={{ width: '100px' }} align="left">
                {trans('actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tooltips.map(s => (
              <TableRow>
                <TableCell align="left">{trans(s.menu)}</TableCell>
                <TableCell align="left">{trans(s.section)}</TableCell>
                <TableCell align="left">{s.message}</TableCell>
                <TableCell align="left">
                  {isEnabled('edit') && (
                    <IconButton>
                      <IconEdit onClick={() => editMessage(s)} />
                    </IconButton>
                  )}
                  {isEnabled('delete') && (
                    <IconButton>
                      <IconDelete onClick={() => deleteTooltipAction(s)} />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <EditTooltip open={open} onClose={onClose} />
    </SpacingContainer>
  )
}
export default connect(AddTooltip)
