import React from 'react'
import connect from '../connect'
import Typography from '../../../components/common/Typography'
import { intlDateFormat } from '../../../utils/dateUtils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { InlineContainer } from '../../../components/common/Containers'
import { Edit } from '@mui/icons-material'
import { Link } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

const Item = ({ template }) => {
  return (
    <>
      <TableRow>
        <TableCell align="left">
          <InlineContainer>
            <Typography weight="500" variant="h6">
              {template.name}
            </Typography>
          </InlineContainer>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {intlDateFormat(template.createdAt)}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Link
            to={`/email-templates/${template.id}`}
            component={NavLink}
            style={{
              color: '#444',
            }}
            underline="none"
          >
            <Edit />
          </Link>
        </TableCell>
      </TableRow>
    </>
  )
}

export default connect(Item)
