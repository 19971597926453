import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useEffect, useState, useCallback } from 'react'
//import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'

import connect from '../../connect'
import { Button } from '../../../../components/common/Buttons'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { SelectField } from '../../../../components/common/Inputs'
import { PORTAL_SECTIONS, PORTAL_VIEWS } from '../../../../constants'
import { useForm } from '../../../../hooks/useForm'
import MenuItem from '@material-ui/core/MenuItem'
import { getInitialValues, useScheme } from './helpers'
import MDEditor from '@uiw/react-md-editor'

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  validateOnBlur: false,
  validateOnSubmit: true,
  enableReinitialize: true,
}

const AddOnboarding = ({ createOnboarding, handleSuccess }) => {
  const { trans } = useTrans()
  const schema = useScheme()
  const [portalViews, setPortalViews] = useState([])
  const [content, setContentDescription] = React.useState('')
  const [isSend, setIsSend] = useState(false)
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  //const [content, setContent] = useState('')

  // const onChangeContent = e => {
  //   setContent(e)
  //   setFieldValue('message', e)
  // }

  const onChangeContent = e => {
    setContentDescription(e)
    setFieldValue('content', e)
  }

  const onSubmit = useCallback(
    async values => {
      setIsSend(true)
      Promise.resolve(createOnboarding({ ...values }))
        .then(res => {
          if (res.payload && res.payload.success) {
            successAlert('Onboarding was successfully added')
            //resetForm()
            onChangeContent('')
            handleSuccess()
          } else {
            errorAlert(
              res?.payload?.message
                ? trans(res.payload.message)
                : res?.error?.message
            )
          }
        })
        .catch(err => {
          errorAlert(err.message)
        })
        .finally(() => setIsSend(false))
    },
    [infoAlert, errorAlert, successAlert, createOnboarding]
  )

  const {
    getFieldProps,
    handleSubmit,
    values,
    resetForm,
    setFieldValue,
    errors,
  } = useForm(schema, getInitialValues(), onSubmit, formOptions)

  useEffect(() => {
    if (values.section) {
      setPortalViews(
        PORTAL_VIEWS.filter(item => item.section === values.section)
      )
    }
  }, [values.section])

  return (
    <SpacingContainer py={2}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs zeroMinWidth>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                marginBottom: '16px',
              }}
            >
              <CheckCircleOutlineIcon
                style={{ marginRight: '16px', color: '#28a745' }}
              />
              <Typography variant="body2" weight="500" fontFamily="lexend">
                {trans('add-onboarding-first-message')}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Typography
              weight="500"
              fontFamily="lexend"
              color="label"
              gutter="label"
              variant="body2"
            >
              Select section
            </Typography>
            <SelectField
              type="select"
              placeholder={trans('required')}
              {...getFieldProps('section')}
              getRenderValue={item => item && trans(item)}
            >
              {PORTAL_SECTIONS.map((item, ind) => (
                <MenuItem key={ind} value={item.key}>
                  {trans(item.title)}
                </MenuItem>
              ))}
            </SelectField>

            <Typography
              weight="500"
              fontFamily="lexend"
              color="label"
              gutter="label"
              variant="body2"
              style={{ marginTop: 20 }}
            >
              Select view
            </Typography>
            <SelectField
              disabled={!values.section}
              type="select"
              placeholder={trans('required')}
              {...getFieldProps('view')}
              getRenderValue={item => item && trans(item)}
            >
              {portalViews.map((item, ind) => (
                <MenuItem key={ind} value={item.key}>
                  {trans(item.title)}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item lg={8} md={8} sm={6} xs={12}>
            <Typography
              weight="500"
              fontFamily="lexend"
              color="label"
              gutter="label"
              variant="body2"
            >
              Content 500 Characters maximum
            </Typography>
            <MDEditor
              height={200}
              autoFocus={false}
              value={content}
              onChange={onChangeContent}
              preview="edit"
              required
              textareaProps={{
                maxLength: 500
              }}
            />
            {errors.content && (
              <small style={{ color: '#ff5225' }}>Required</small>
            )}
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 30 }}>
          <Grid item sm={12} xs={12} style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={handleSubmit}
              size="large"
            >
              {trans('add-onboarding-step')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </SpacingContainer>
  )
}
export default connect(AddOnboarding)
