import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit'
import { API } from '../../constants'
import { application } from '../../services/application'

export const INITIAL_STATE = {
  tooltips: [],
  currentSection: null,
  onboardings: [],
  currentOnboarding: null,
  publishedBanner: null,
}

//Actions
//Onboadings
// const getOnboardings = createAsyncThunk(
//   'Onboardings/getOnboardings',
//   params => {
//     return application.call(API.TOOLTIPS_MANAGEMENT.GET_ONBOARDINGS, params)
//   }
// )
// function onGetOnboardings(state, action) {
//   const onboardings = action.payload.onboardings.map(i =>
//     i.sections.map(s => ({ ...s, menu: i.menu }))
//   )

//   const arrayOnboardings = []

//   onboardings.forEach(i => {
//     arrayOnboardings.push(...i)
//   })

//   Object.assign(state, {
//     onboardings: arrayOnboardings,
//   })
// }

// const deleteOnboarding = createAsyncThunk(
//   'Onboardings/deleteOnboarding',
//   params => {
//     return application.call(API.TOOLTIPS_MANAGEMENT.DELETE_ONBOARDING, params)
//   }
// )
// function onDeleteOnboarding(state, action) {
//   const onboardings = [...state.onboardings]
//   const resOnboarding = action.payload.onboarding
//   const onboardingIndex = onboardings.findIndex(
//     i => i.menu === resOnboarding.menu && i.section === resOnboarding.section
//   )
//   onboardings.splice(onboardingIndex, 1)
//   console.log(onboardingIndex, onboardings)
//   return Object.assign(state, { onboardings: onboardings })
// }

// const createOnboarding = createAsyncThunk('Onboardings/create', params => {
//   return application.call(API.TOOLTIPS_MANAGEMENT.CREATE_ONBOARDING, params)
// })
// function onAddOnboarding(state, action) {
//   const onboardings = [...state.onboardings]
//   const resOnboarding = action.payload.onboarding
//   const onboardingIndex = onboardings.findIndex(
//     i => i.menu === resOnboarding.menu && i.section === resOnboarding.section
//   )
//   if (onboardingIndex === -1) {
//     onboardings.unshift(resOnboarding)
//   } else {
//     onboardings[onboardingIndex].message = resOnboarding.message
//   }
//   return Object.assign(state, { onboardings: onboardings })
// }

const updateOnboarding = createAsyncThunk('Onboardings/update', params => {
  return application.call(API.TOOLTIPS_MANAGEMENT.UPDATE_ONBOARDING, params)
})
function onUpdateOnboarding(state, action) {
  const onboardings = [...state.onboardings]
  const resOnboarding = action.payload.onboarding
  const onboardingIndex = onboardings.findIndex(
    i => i.menu === resOnboarding.menu && i.section === resOnboarding.section
  )
  onboardings[onboardingIndex].message = resOnboarding.message
  return Object.assign(state, { onboardings: onboardings })
}

//Tooltips
const getTooltips = createAsyncThunk('Tooltips/getTooltips', params => {
  return application.call(API.TOOLTIPS_MANAGEMENT.GET_TOOLTIPS, params)
})
function onGetTooltips(state, action) {
  const tooltips = action.payload.tooltips.map(i =>
    i.sections.map(s => ({ ...s, menu: i.menu }))
  )

  const arrayTooltips = []

  tooltips.forEach(i => {
    arrayTooltips.push(...i)
  })

  Object.assign(state, {
    tooltips: arrayTooltips,
  })
}

const deleteTooltip = createAsyncThunk('Tooltips/deleteTooltip', params => {
  return application.call(API.TOOLTIPS_MANAGEMENT.DELETE, params)
})
function onDeleteTooltip(state, action) {
  const tooltips = [...state.tooltips]
  const resTooltip = action.payload.tooltip
  const tooltipIndex = tooltips.findIndex(
    i => i.menu === resTooltip.menu && i.section === resTooltip.section
  )
  tooltips.splice(tooltipIndex, 1)
  console.log(tooltipIndex, tooltips)
  return Object.assign(state, { tooltips: tooltips })
}

const createTooltip = createAsyncThunk('Tooltips/create', params => {
  return application.call(API.TOOLTIPS_MANAGEMENT.CREATE, params)
})

const publishBanner = createAsyncThunk('Banner/publish', params => {
  return application.call(API.BANNER_MANAGEMENT.PUBLISH, params)
})

const getPublishedBanner = createAsyncThunk('Banner/publishedBanner', params => {
  return application.call(API.BANNER_MANAGEMENT.GET_BANNER, params)
})

const unpublishBanner = createAsyncThunk('Banner/unpublishedBanner', params => {
  return application.call(API.BANNER_MANAGEMENT.UNPUBLISH, params)
})

function onGetPublishedBanner(state, action) {
  return Object.assign(state, { publishedBanner: action.payload });
}

function onPublishBanner(state, action) {
  return Object.assign(state, { ...state });
}

function onUnpublishBanner(state, action) {
  return Object.assign(state, { ...state });
}

function onAddTooltip(state, action) {
  const tooltips = [...state.tooltips]
  const resTooltip = action.payload.tooltip
  const tooltipIndex = tooltips.findIndex(
    i => i.menu === resTooltip.menu && i.section === resTooltip.section
  )
  if (tooltipIndex === -1) {
    tooltips.unshift(resTooltip)
  } else {
    tooltips[tooltipIndex].message = resTooltip.message
  }
  return Object.assign(state, { tooltips: tooltips })
}

const updateTooltip = createAsyncThunk('Tooltips/update', params => {
  return application.call(API.TOOLTIPS_MANAGEMENT.UPDATE, params)
})
function onUpdateTooltip(state, action) {
  const tooltips = [...state.tooltips]
  const resTooltip = action.payload.tooltip
  const tooltipIndex = tooltips.findIndex(
    i => i.menu === resTooltip.menu && i.section === resTooltip.section
  )
  tooltips[tooltipIndex].message = resTooltip.message
  return Object.assign(state, { tooltips: tooltips })
}

const changeCurrentSection = createAction('Tooltips/changeCurrentSection')
function onChangeCurrentSection(state, action) {
  return Object.assign(state, { currentSection: action.payload })
}

const createOnboarding = createAsyncThunk('onboardingView/create', params => {
  return application.call(API.ONBOARDING_VIEW_MANAGEMENT.CREATE, params)
})

const getOnboardings = createAsyncThunk(
  'onboardingView/getOnboardings',
  params => {
    return application.call(API.ONBOARDING_VIEW_MANAGEMENT.GET, params)
  }
)

const deleteOnboarding = createAsyncThunk('onboardingView/delete', params => {
  return application.call(
    API.ONBOARDING_VIEW_MANAGEMENT.DELETE_ONBOARDING,
    params
  )
})

const deleteOnboardingStep = createAsyncThunk(
  'onboardingView/deleteStep',
  params => {
    return application.call(API.ONBOARDING_VIEW_MANAGEMENT.DELETE_STEP, params)
  }
)

export const actions = {
  createOnboarding,
  deleteOnboarding,
  deleteOnboardingStep,
  getTooltips,
  deleteTooltip,
  createTooltip,
  updateTooltip,
  publishBanner,
  getPublishedBanner,
  unpublishBanner,
  changeCurrentSection,
  //Onboardings
  //getOnboardings,
  deleteOnboarding,
  createOnboarding,
  updateOnboarding,
  getOnboardings,
}

export default createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(deleteTooltip.fulfilled, onDeleteTooltip)
    .addCase(createTooltip.fulfilled, onAddTooltip)
    .addCase(updateTooltip.fulfilled, onUpdateTooltip)
    .addCase(getTooltips.fulfilled, onGetTooltips)
    .addCase(changeCurrentSection, onChangeCurrentSection)
    .addCase(publishBanner.fulfilled, onPublishBanner)
    .addCase(getPublishedBanner.fulfilled, onGetPublishedBanner)
    .addCase(unpublishBanner.fulfilled, onUnpublishBanner)
  // .addCase(deleteOnboarding.fulfilled, onDeleteOnboarding)
  // .addCase(createOnboarding.fulfilled, onAddOnboarding)
  // .addCase(updateOnboarding.fulfilled, onUpdateOnboarding)
  // .addCase(getOnboardings.fulfilled, onGetOnboardings)
})
