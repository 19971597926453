import React from 'react';
import { useTrans } from '../../../../services/i18n';
import { Paper, SpacingContainer } from '../../../../components/common/Containers';
import { Grid } from '@material-ui/core';
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase';
import Typography from '../../../../components/common/Typography';
import StatusChip from './Status'
import FieldValue from '../../../../components/generics/Helpers/FieldValue';
import { intlDateFormat } from '../../../../utils/dateUtils';
import { formatToTitleCase } from '../../../../utils/helpers'
import UserMenu from '../../components/UserMenu'

const SummaryUsfcrStaff = ({ item }) => {
  const { trans } = useTrans();

  return (
    <SpacingContainerBase>
      <Paper component={SpacingContainer} py={4} px="5sm3">
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item xs zeroMinWidth>
            <Typography variant="h1" weight="bold">
              {item.name} { item.familyName }
            </Typography>
          </Grid>
          <Grid item>
            <StatusChip status={item.usfcrActive ? 'Active' : 'Blocked'} />
            <UserMenu detail={true} user={item}/>
          </Grid>
        </Grid>

        <Grid style={{ marginTop: '1rem' }}
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item sm={2} xs={12}>
            <FieldValue value={intlDateFormat(item.createdAt)} label={trans('registered')} />
          </Grid>
          <Grid item sm={2} xs={12}>
            <FieldValue value={item.role ? formatToTitleCase(item.role) : ''} label={trans('Role')} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FieldValue value={item.email} label={trans('email')} />
          </Grid>
        </Grid>
      </Paper>
    </SpacingContainerBase>
  )
}

export default SummaryUsfcrStaff
