import React, { useEffect } from 'react'
import connect from './connect'
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase'
import Loader from '../../components/common/Loader'
import { Grid } from '@material-ui/core'
import NothingFoundCard from '../../components/generics/Cards/NothingFoundCard/NothingFoundCardList'
import PaginationSmall from '../../components/common/Paginations/PaginationSmall'
import List from './components/List'
import Header from './components/Header'
import { emptyArray } from '../../utils/arrayUtils'
import { useNavigate } from 'react-router-dom'
import useTabs from '../../hooks/useTabs'
import { Button, RouterLink } from '../../components/common/Buttons'
import { useTrans } from '../../services/i18n'
import Typography from '../../components/common/Typography'
import { ANY_OF_THOSE } from '../../constants/filter'

const RequestedQuotes = ({
  requestedQuotes,
  onInit,
  onChangePage,
  onChangeRowPerPage,
  getStaffUsers,
  onChangeFilters,
}) => {
  const { items, count, pending, pagination } = requestedQuotes
  const [tab, onChangeTab] = useTabs(1)
  const { trans } = useTrans()

  useEffect(() => {
    getStaffUsers()
  }, [])

  useEffect(() => {
    // Fetch new Quote Requests when tab changes
    onInit({
      params: { taggedAs: tab === 1 ? 'Consulting' : 'Lead' },
    })
  }, [tab, onInit])

  const resetFilters = () => {
    onChangeFilters({
      progressStatus: {
        type: ANY_OF_THOSE,
        value: ['new', 'in_progress', 'closed'],
      },
    })
  }

  return (
    <SpacingContainerBase>
      <Header tab={tab} onChangeTab={onChangeTab} />
      {pending && <Loader open={pending} />}
      {!emptyArray(items) ? (
        <Grid container spacing={2} direction="column">
          <Grid item xs zeroMinWidth>
            <List items={items} tab={tab} />
          </Grid>

          <Grid item xs zeroMinWidth>
            <PaginationSmall
              page={pagination.page}
              perPage={pagination.perPage}
              count={count}
              onChangePage={onChangePage}
              onChangeRowPerPage={onChangeRowPerPage}
              pending={pending}
            />
          </Grid>
        </Grid>
      ) : !pending && emptyArray(items) ? (
        <Grid item xs zeroMinWidth>
          <NothingFoundCard>
            <Button component={RouterLink} onClick={resetFilters}>
              <Typography weight="400" variant="body1">
                {trans('clear-filters')}
              </Typography>
            </Button>
          </NothingFoundCard>
        </Grid>
      ) : null}
    </SpacingContainerBase>
  )
}

export default connect(RequestedQuotes)
