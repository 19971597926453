import React, { useMemo } from 'react'
import connect from '../../connect'
import { SpacingContainer } from '../../../../components/common/Containers'
import { Grid, MenuItem } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { useTrans } from '../../../../services/i18n'
import FeatureField from './FeatureField'
import { SelectField } from '../../../../components/common/Inputs'
import { COURSE_LEVELS_LABELS } from '../../../../constants/subscriptionSettings'

function ComponentFeaturesForm({
  componentFeatures,
  setComponentFeatures,
  tabTier,
}) {
  const { trans } = useTrans()
  const currentComponentFeatures = useMemo(() => {
    return componentFeatures[tabTier]
  }, [componentFeatures, tabTier])

  const onChange = (key, value) => {
    const newComponentFeatures = currentComponentFeatures.map(component => {
      if (component.key === key) {
        return {
          ...component,
          value,
        }
      }
      return component
    })

    setComponentFeatures({
      ...componentFeatures,
      [tabTier]: newComponentFeatures,
    })
  }

  return (
    <SpacingContainer py={2}>
      <Grid
        key={`existing-users-tab-grid-${tabTier}`}
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography
            variant="h4"
            weight="500"
            fontFamily="lexend"
            fontSize="20px"
          >
            {trans('component-features')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {currentComponentFeatures?.map(component => (
              <Grid item xs={12} md={6} key={component.key}>
                {component.key !== 'courses' ? (
                  <FeatureField feature={component} onChange={onChange} />
                ) : (
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontFamily: 'Lexend',
                        }}
                        variant="body2"
                        weight="600"
                      >
                        {trans('course-level')}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <SelectField
                        value={component?.value ?? 'none'}
                        onChange={e => onChange(component.key, e.target.value)}
                        getRenderValue={item => COURSE_LEVELS_LABELS[item]}
                      >
                        {Object.keys(COURSE_LEVELS_LABELS).map((item, ind) => (
                          <MenuItem key={ind} value={item}>
                            {COURSE_LEVELS_LABELS[item]}
                          </MenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </SpacingContainer>
  )
}

export default connect(ComponentFeaturesForm)
