function _getScrollableParent(element) {
  if (!element) return null;
  const elementStyles = getComputedStyle(element);
  if (elementStyles.position === 'fixed') return null;

  const overflowRegex = /(auto|scroll)/;

  for (let parent = element; !!parent; parent = parent.parentElement) {
    const parentStyles = getComputedStyle(parent);
    if (elementStyles.position === 'absolute' && parentStyles.position === 'static') continue;
    if (!overflowRegex.test(parentStyles.overflow + parentStyles.overflowY + parentStyles.overflowX)) continue;

    return parent;
  }

  return null;
}

export function scrollToTarget(target, offset = 10, scrollableParent = null) {
  if (!target) return;
  const scrollableElement = scrollableParent ? scrollableParent : _getScrollableParent(target);

  const scrollable = scrollableElement || window;
  const offsetTop = scrollableElement ? target.offsetTop : target.getBoundingClientRect().top + window.pageYOffset;
  const offsetNum = Number(offset) || 10;

  scrollable.scrollTo({
    top: offsetTop > offsetNum ? offsetTop - offsetNum : 0,
    left: 0,
    behavior: 'smooth',
  });
}

export function scrollToTargetFromEvent(e) {
  e.preventDefault();
  const targetId = e.currentTarget.getAttribute('data-target');
  if (!targetId) return;

  scrollToTarget(document.getElementById(targetId), 20);
}

export function scrollIntoViewById(id) {
  const el = document.getElementById(id);
  if (!el) return;

  el.scrollIntoView({ behavior: 'smooth' });
}
