import React, { useCallback } from 'react'
import Grid from '@material-ui/core/Grid'

import { Button } from '../../../../../../components/common/Buttons'
import { ModalDialog } from '../../../../../../components/common/Dialogs/ModalDialog'
import { TextField } from '../../../../../../components/common/Inputs'
import { useForm } from '../../../../../../hooks/useForm'
import { useTrans } from '../../../../../../services/i18n'
import { getInitialValues, useScheme } from './helpers'
import { useAlertContext } from '../../../../../../contexts/AlertContext'
import Typography from '../../../../../../components/common/Typography'
import EmailIcon from '@material-ui/icons/Email'
import connect from '../../../connect'

const formOptions = {
  validateOnMount: false,
  validateOnChange: true,
  enableReinitialize: true,
}

const CreateFolderModal = ({
  item,
  open,
  onClose,
  createFolder,
  currentFolder,
}) => {
  const { trans } = useTrans()
  const schema = useScheme()
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)

  const onSubmit = useCallback(
    async values => {
      //console.log(values, currentFolder)
      setIsSend(true)
      createFolder({
        folderName: values.folderName,
        folderParent: currentFolder.id,
      })
        .then(() => {
          successAlert(`Folder ${values.folderName} created!`)
          setIsSend(false)
          onClose()
          resetForm()
        })
        .catch(error => {
          errorAlert(error.message)
          setIsSend(false)
          onClose()
          resetForm()
        })
    },
    [onClose, infoAlert, errorAlert, successAlert, currentFolder]
  )

  const { getFieldProps, handleSubmit, values, isValid, resetForm } = useForm(
    schema,
    getInitialValues(null),
    onSubmit,
    formOptions
  )

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans('create-folder')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={!isValid}
              pending={isSend}
              onClick={handleSubmit}
            >
              {trans('create-folder')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            style={{ color: '#888FA9', marginBottom: '16px' }}
            variant="body1"
            weight="600"
          >
            {trans('create-new-folder-text')}
          </Typography>
          <Grid item xs={12}>
            <TextField
              {...getFieldProps('folderName')}
              label={trans('folder-name')}
              placeholder={trans('folder-name')}
              required
              maxLength={255}
            />
          </Grid>
        </Grid>
      </Grid>
    </ModalDialog>
  )
}

export default connect(CreateFolderModal)
