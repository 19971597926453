import { useState } from 'react'
import Typography from '../../../../components/common/Typography'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { InlineContainer } from '../../../../components/common/Containers'
import { RouterLink } from '../../../../components/common/Buttons'
import { IconButton } from '@material-ui/core'
import ConfirmDialog from '../../../../components/generics/Confirm/Dialog'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import { useTrans } from '../../../../services/i18n'
import Switch from '../../../../components/common/Inputs/Switch'
import usePopover from '../../../../hooks/usePopover'

import connect from '../../connect'
import { useNavigate } from 'react-router-dom'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import EditIcon from '@material-ui/icons/Edit'
import { useAlertContext } from '../../../../contexts/AlertContext'
import AddFileToFormModal from '../formstackManagement/addFileToFormModal'

const Item = ({ file, index, deleteFileForm, currentFormDetail }) => {
  const [openRemove, onOpenRemove, onCloseRemove] = useBooleanHandlers()
  const [pendingRemove, setPendingRemove] = useState(false)
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()
  const navigate = useNavigate()
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const [openAddFileModal, onOpenAddFileModal, onCloseAddFileModal] =
    useBooleanHandlers()

  const { trans } = useTrans()
  const onDeleteForm = () => {
    setPendingRemove(true)
    deleteFileForm({
      id: currentFormDetail.id,
      fileName: file,
    })
      .then(() => {
        setPendingRemove(false)
        onCloseRemove()
        successAlert('File deleted!')
      })
      .catch(error => {
        errorAlert(error.message)
      })
  }

  const onEditForm = () => {
    onOpenAddFileModal()
  }

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <InlineContainer spacing={1}>
            <Typography
              weight="500"
              variant="h6"
              noWrap
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '40rem',
              }}
            >
              {file}
            </Typography>
          </InlineContainer>
        </TableCell>

        <TableCell align="right">
          <IconButton onClick={() => onEditForm()}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => onOpenRemove()}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={openRemove}
        onClose={onCloseRemove}
        onConfirm={onDeleteForm}
        pending={pendingRemove}
        title={trans('remove-file')}
        text={`Are you sure you want to remove this file`}
      >
        <Typography color="hint">{file}</Typography>
      </ConfirmDialog>
      <AddFileToFormModal
        open={openAddFileModal}
        onClose={onCloseAddFileModal}
        file={file}
        type="principal"
      />
    </>
  )
}

export default connect(Item)
