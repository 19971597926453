import { Grid } from '@material-ui/core'
import { ModalDialog } from '../../../../components/common/Dialogs'
import { Button } from '../../../../components/common/Buttons'
import { useTrans } from '../../../../services/i18n'
import { useState } from 'react'
import { useForm } from '../../../../hooks/useForm'
import { SpacingContainer } from '../../../../components/common/Containers'
import { TextField } from '../../../../components/common/Inputs'
import { getInitialValues, useScheme } from './formik'
import { useAlertContext } from '../../../../contexts/AlertContext'

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: true,
}

const AddOrganizationModal = ({ onClose, open, saveOrganization, parentId }) => {
  const { trans } = useTrans()
  const schema = useScheme()
  const { successAlert, errorAlert } = useAlertContext()
  const [loading, setLoading] = useState(false)

  const saveUserUsfcrAction = values => {
    setLoading(true)
    const body = {
      parentId,
      ...values,
    }
    Promise.resolve(saveOrganization(body))
      .then(res => {
        if (res.payload && res.payload.success) {
          successAlert('Organization was successfully added')
          closeModal()
        } else {
          errorAlert(
            res?.payload?.message
              ? trans(res.payload.message)
              : res?.error?.message
          )
        }
      })
      .catch(err => {
        errorAlert(err.message)
      })
      .finally(() => setLoading(false))
  }

  const { getFieldProps, handleSubmit, resetForm } = useForm(
    schema,
    getInitialValues({}),
    saveUserUsfcrAction,
    formOptions
  )

  const closeModal = () => {
    console.log('closeModal')
    resetForm()
    onClose()
  }

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title="Add Subsidiary Organization"
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={loading}
              onClick={closeModal}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              pending={loading}
              disabled={loading}
            >
              {trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer px={1} py={2}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              label="Organization Name"
              placeholder="Required"
              required
              maxLength={255}
              {...getFieldProps('orgName')}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Salesforce ID"
              placeholder="Optional"
              maxLength={255}
              {...getFieldProps('salesforceId')}
            />
          </Grid>
        </Grid>
      </SpacingContainer>
    </ModalDialog>
  )
}

export default AddOrganizationModal
