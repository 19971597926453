import { useState, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import { Button } from '../../../../components/common/Buttons'
import { ModalDialog } from '../../../../components/common/Dialogs'
import { useTrans } from '../../../../services/i18n'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { SpacingContainer } from '../../../../components/common/Containers'
import { SelectField, TextField } from '../../../../components/common/Inputs'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '../../../../components/common/Typography'
import { ROLE_OWNER, USER_ROLES } from '../../../../constants/user'
import { useForm } from '../../../../hooks/useForm'
import { getInitialValues, useScheme } from './formik'

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: true,
}

const FormAddUser = ({
  getDetail,
  organization,
  open,
  onClose,
  saveUser,
  pushNewMember,
  authUser,
}) => {
  const { trans } = useTrans()
  const schema = useScheme()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = useState(false)

  const onSubmit = useCallback(
    values => {
      if (typeof saveUser !== 'function' || !organization) return

      setIsSend(true)
      Promise.resolve(saveUser({ ...values, organizationId: organization.id }))
        .then(res => {
          if (res.payload && res.payload.success) {
            pushNewMember(res.payload.data)
            successAlert('User was successfully added')
            getDetail({ organizationId: organization.id })
            closeModal()
          } else {
            errorAlert(
              res?.payload?.message
                ? trans(res.payload.message)
                : res?.error?.message
            )
          }
        })
        .catch(err => {
          errorAlert(err.message)
        })
        .finally(() => setIsSend(false))
    },
    [onClose, saveUser, errorAlert, successAlert]
  )

  const { getFieldProps, handleSubmit, resetForm } = useForm(
    schema,
    getInitialValues({}),
    onSubmit,
    formOptions
  )

  const closeModal = () => {
    resetForm()
    onClose()
  }
  const FILTERED_USER_ROLES =
    authUser.role === 'admin'
      ? USER_ROLES
      : USER_ROLES.filter(role => role.value !== ROLE_OWNER)
  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={`${trans('associating-user')} to ${organization?.orgName}`}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSend}
              onClick={closeModal}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={handleSubmit}
            >
              {trans('save')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer px={1} py={2}>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} xs={12}>
            <TextField
              label={trans('first-name')}
              placeholder={trans('required')}
              required
              maxLength={255}
              {...getFieldProps('name')}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <TextField
              label={trans('last-name')}
              placeholder={trans('required')}
              required
              maxLength={255}
              {...getFieldProps('lastName')}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              label={trans('email')}
              type="text"
              placeholder={trans('required')}
              {...getFieldProps('email')}
              required
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography
              weight="500"
              fontFamily="lexend"
              color="label"
              variant="body2"
            >
              {trans('role')}
            </Typography>
            <SelectField
              style={{ marginTop: '7px' }}
              type="select"
              {...getFieldProps('role')}
              placeholder={trans('required')}
            >
              {FILTERED_USER_ROLES.map((item, ind) => (
                <MenuItem key={ind} value={item.value}>
                  {trans(item.i18nKey)}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
        </Grid>
      </SpacingContainer>
    </ModalDialog>
  )
}

export default FormAddUser
