import React from 'react'
import { SpacingContainer } from '../../../../components/common/Containers/Base'
import Grid from '@material-ui/core/Grid'
import { useTrans } from '../../../../services/i18n'
import FieldValue from '../../../../components/generics/Helpers/FieldValue'
import { formatDate } from '../../../../utils/dateUtils'
import connect from '../connect'
import { Box } from '@mui/material'
import Button from '../../../../components/common/Buttons/Button'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const FeaturedVendorFields = ({ detail, removeClaimedVendor }) => {
  const { featuredVendor } = detail
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const permissions = useSelector(selectUserPermissions)

  const removeVendor = () => {
    setIsSend(true)
    removeClaimedVendor({ orgId: detail.id })
      .then(res => {
        if (res?.error) {
          throw res.error
        }

        successAlert('Claimed vendor updated!')
        setIsSend(false)
      })
      .catch(() => {
        setIsSend(false)
        errorAlert(trans('api-error'))
      })
  }

  return (
    <>
      <Box
        style={{
          borderTop: `1px solid rgba(78, 89, 120, 0.12)`,
          padding: '2rem 0 2rem 0',
        }}
      >
        <SpacingContainer>
          <Grid container>
            <Grid item md={6} sm={12} xs={12}>
              <FieldValue value={featuredVendor.name} label={trans('name')} />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FieldValue label={trans('corporate-url')}>
                <a
                  style={{
                    textDecoration: 'none',
                    color: featuredVendor?.corpUrl ? '#2445a7' : '#aaa',
                  }}
                  href={
                    featuredVendor?.corpUrl &&
                    (featuredVendor.corpUrl.startsWith('http')
                      ? featuredVendor.corpUrl
                      : `http://${featuredVendor.corpUrl}`)
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {featuredVendor?.corpUrl ?? 'Not provided'}
                </a>
              </FieldValue>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '16px' }}>
            <Grid item md={6} sm={12} xs={12}>
              <FieldValue
                value={formatDate(featuredVendor.lastUpdatedDate)}
                label={trans('last-updated')}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FieldValue
                value={formatDate(featuredVendor.expirationDate)}
                label={trans('expiration-date')}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: '16px' }}>
            <Grid item md={6} sm={12} xs={12}>
              <FieldValue
                value={featuredVendor.ueiCode}
                label={trans('uei-code')}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FieldValue
                value={featuredVendor.cageCode}
                label={trans('cage-code')}
              />
            </Grid>
          </Grid>
        </SpacingContainer>
      </Box>
      {isEnabled(permissions, 'organization', 'claimed-vendor', 'edit') && (
        <Box
          style={{
            borderTop: `1px solid rgba(78, 89, 120, 0.12)`,
            padding: '2rem 0 0.5rem 0',
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs zeroMinWidth>
              <Button
                variant="contained"
                style={{ background: '#17b471', color: '#fff' }}
                onClick={removeVendor}
                pending={isSend}
              >
                {' '}
                {trans('remove-vendor')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default connect(FeaturedVendorFields)
