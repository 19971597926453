import * as Yup from 'yup'
import makeValidationSchema from '../../../hooks/makeValidationSchema'

export const initialValues = {
  amount: null,
  adminResponse: '',
  isDenied: false
}

export const getInitialValues = item => {
  return Object.assign({}, initialValues, item)
}

export const useScheme = makeValidationSchema(() => {
  return Yup.object().shape({
    id: Yup.string().optional().nullable().default(undefined),
    isDenied: Yup.boolean(),
    amount: Yup.number().nullable(true).when('isDenied', {
      is: false,
      then: Yup
        .number()
        .typeError('Amount must be a number')
        .required('Amount is a required field')
        .moreThan(0, 'Amount must be greater than 0')
    }),
    expires: Yup.date().nullable(true).when('isDenied', {
      is: false,
      then: Yup
        .date()
        .typeError('Expires must be a valid date')
        .required('Expires is a required field')
        .min(new Date(), 'Expires must be greater than today')
    }),
    adminResponse: Yup.string().required('Response is a required field').nullable().default(''),
  })
}
)
