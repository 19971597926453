import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  dropDownButton: {
    color: theme.palette.common.black,
    borderColor: alpha(theme.palette.common.black, 0.4),
    backgroundColor: theme.palette.common.white,

    '& *': {
      color: theme.palette.common.black,
    },
  },
}))