import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useTrans } from '../../../services/i18n'
import Typography from '../../../components/common/Typography'
import { ModalDialog } from '../../../components/common/Dialogs'
import { SpacingContainer } from '../../../components/common/Containers'
import { Button } from '../../../components/common/Buttons'
import connect from '../connect'
import { useAlertContext } from '../../../contexts/AlertContext'
import { makeStyles } from '@material-ui/core'
import { Avatar } from '../../../components/common/Cards'
import { Chip } from '../../../components/common/Chip'

const useStyles = makeStyles(theme => ({
  actionButton: {
    display: 'flex',
    gap: '1.25rem',
    justifyContent: 'right',
    alignItems: 'right',
    padding: '0.8rem',
  },
  avatarSize: {
    width: '120px',
    height: '120px',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    gap: '1rem',
    padding: '0.8rem',
  },
}))

const ApproveDenyLogoModal = ({
  open,
  onClose,
  item,
  title,
  subtitle,
  isLogoApproved,
  handleAction,
  loading,
}) => {
  const styles = useStyles()
  const { trans } = useTrans()

  return (
    <ModalDialog
      className={styles.root}
      title={
        <Typography variant="h4" weight="bold">
          {title}
        </Typography>
      }
      open={open}
      onClose={onClose}
      actions={
        <Grid container spacing={1} className={styles.actionButton}>
          <Button variant="outlined" color="primary" onClick={() => onClose()}>
            {trans('cancel')}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleAction}
            pending={loading}
          >
            {trans('confirm')}
          </Button>
        </Grid>
      }
    >
      <SpacingContainer>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
          direction="column"
          className={styles.container}
        >
          <Avatar
            src={item?.avatarUrl}
            alt="-"
            size={120}
            className={styles.avatarSize}
          />
          <Chip
            label={isLogoApproved ? trans('approved') : trans('denied')}
            color={isLogoApproved ? 'success' : 'error'}
          />
          <Typography
            variant="h2"
            fontFamily="lexend"
            weight="400"
            align="center"
          >
            {subtitle}
          </Typography>
        </Grid>
      </SpacingContainer>
    </ModalDialog>
  )
}

export default connect(ApproveDenyLogoModal)
