import { Box } from '@material-ui/core'
import React from 'react'
import { Button } from '../../../../components/common/Buttons'
import { useTrans } from '../../../../services/i18n'
import { BlockOutlined } from '@mui/icons-material'
import { DeleteOutline } from '@material-ui/icons'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import BlockModal from './Modals/BlockModal'
import DeleteModal from './Modals/DeleteModal'
import EditModal from './Modals/EditModal'
import { useItemStatus } from '../../hooks/useItemStatus'

const iconStyle = {
  fontSize: '1.5em',
  marginRight: '.25rem',
}

function Buttons({ item }) {
  const { trans } = useTrans()
  const { canRestore, canUnblock } = useItemStatus(item)
  const [openBlock, onOpenBlock, onCloseBlock] = useBooleanHandlers(false)
  const [openDelete, onOpenDelete, onCloseDelete] = useBooleanHandlers(false)
  const [openEdit, onOpenEdit, onCloseEdit] = useBooleanHandlers(false)

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Button variant="outlined" color="primary" onClick={onOpenEdit}>
          {trans('edit')}
        </Button>
        <Box display="flex" gridGap="1rem">
          {!(canRestore || canUnblock) && (
            <>
              <Button variant="contained" color="primary" onClick={onOpenBlock}>
                <BlockOutlined style={iconStyle} />
                {trans('block')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={onOpenDelete}
              >
                <DeleteOutline style={iconStyle} />
                {trans('delete')}
              </Button>
            </>
          )}

          {canUnblock && (
            <Button variant="contained" color="primary" onClick={onOpenBlock}>
              {trans('unblock')}
            </Button>
          )}

          {canRestore && (
            <Button variant="contained" color="primary" onClick={onOpenDelete}>
              {trans('restore')}
            </Button>
          )}
        </Box>
      </Box>

      <BlockModal
        item={item}
        open={openBlock}
        onClose={onCloseBlock}
        canUnblock={canUnblock}
      />
      <DeleteModal
        item={item}
        open={openDelete}
        onClose={onCloseDelete}
        canRestore={canRestore}
      />
      <EditModal item={item} open={openEdit} onClose={onCloseEdit} />
    </>
  )
}

export default Buttons
