import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Toolbar, { getModules, formats } from './components/Toolbar'

function RichTextEditor({ id = 'toolbar', variables = [], value, onChange }) {
  const quillEditor = React.useRef(null)

  return (
    <>
      <div className="text-editor">
        <Toolbar
          toolbarId={id}
          editorRef={quillEditor?.current?.getEditor?.()}
          variables={variables}
        />
        <ReactQuill
          ref={quillEditor}
          theme="snow"
          modules={getModules(id)}
          formats={formats}
          value={value}
          onChange={onChange}
        />
      </div>
    </>
  )
}

export default RichTextEditor
