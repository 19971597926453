import React from 'react'
import { SvgIcon } from '../../../common/SvgIcon'
import { simpleIconPropsTypes } from '../../propTypes'

export const IconOrgLogoReview = props => {
  const { width = 24, height = 24 } = props

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M20 12C20 11.39 19.92 10.79 19.79 10.22L21.4 8.61C21.78 9.67 22 10.81 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C14.13 2 16.1 2.67 17.72 3.81L16.28 5.25C15.04 4.46 13.58 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12ZM10.59 16.6L22 5.18L20.59 3.78L10.59 13.78L7.76 10.95L6.35 12.36L10.59 16.6Z"/>
    </SvgIcon>
  )
}

IconOrgLogoReview.propTypes = simpleIconPropsTypes
