import React, { useEffect } from 'react'
import { Paper } from '../../../../components/common/Containers'
import { SpacingContainer } from '../../../../components/common/Containers/Base'
import PaginationSmall from '../../../../components/common/Paginations/PaginationSmall'
import { Table } from '../../../../components/common/ResponsiveTable'
import Typography from '../../../../components/common/Typography'
import NothingFoundCardList from '../../../../components/generics/Cards/NothingFoundCard/NothingFoundCardList'
import { useTrans } from '../../../../services/i18n'
import { emptyArray } from '../../../../utils/arrayUtils'
import connect from '../../connect'
import { IconSearch } from '../../../../components/svg/Icons/IconSearch'
import { IconSavedSearches } from '../../../../components/svg/Icons/IconSavedSearches'
import Item from './Item'
import Head from './Item/Head'
import { Grid } from '@material-ui/core'
import { Divider } from '../../../../components/common/Divider'
import { LoaderSmall } from '../../../../components/common/LoaderSmall'
import { Switch } from '../../../../components/common/Inputs'
import { Button } from '../../../../components/common/Buttons'
import styles from './styles.module.css'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import ConfirmDialog from '../../../../components/generics/Confirm/Dialog'
import { useSelector } from 'react-redux'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import SavedSearchesCounter from './Item/SavedSearchesCounter'

const SavedSearchesList = ({
  savedSearches,
  savedSearchesActions,
  userDetail,
  isAllCheckedSavedSearches,
  isSomeCheckedSavedSearches,
  savedSearchesTotal,
  savedSearchesSelected,
  savedSearchesUnselected,
  addAllSavedSearches,
  removeAllSavedSearches,
  addRemainingSavedSearches,
  addOneSavedSearches,
  removeOneSavedSearches,
  saveDataAfterDeleteSearch,
  saveDataAfterDeleteBulkSearch,
}) => {
  const { trans } = useTrans()
  const permissions = useSelector(selectUserPermissions)
  const { items, order, count, pending, pagination } = savedSearches
  const {
    onChangePage,
    onChangeRowPerPage,
    onChangeOrder,
    onChangeFilters,
    ...itemActions
  } = savedSearchesActions
  const [orderBy, orderDir] = order || []
  const { onTurnOnOffNotifications, onDeleteBulk } = itemActions
  const [
    openConfirmDeleteBulk,
    onOpenConfirmDeleteBulk,
    onCloseConfirmDeleteBulk,
  ] = useBooleanHandlers()
  const [deleteSearchesCount, setDeleteSearchesCount] = React.useState(0)

  React.useEffect(() => {
    setDeleteSearchesCount(
      isAllCheckedSavedSearches
        ? savedSearchesTotal - savedSearchesUnselected.length
        : savedSearchesSelected.length
    )
  }, [
    isAllCheckedSavedSearches,
    savedSearchesSelected,
    savedSearchesUnselected,
  ])

  /***
   * Each time items changed (different page or order)
   */
  React.useEffect(() => {
    addRemainingSavedSearches()
  }, [items])

  /***
   * In charge of turn on/off the search notification for a specific client
   */
  const onToggleSwitch = () => {
    onTurnOnOffNotifications({
      idSavedSearchOwner: userDetail.id,
      savedSearchOwner: userDetail.userName,
      pursuitNotices: userDetail.pursuitNotices ? 1 : 0,
      savedSearchNotices: userDetail.savedSearchNotices === 1 ? 0 : 1,
      teamUpdates: userDetail.teamUpdates ? 1 : 0,
      savedSearchTypeNotices: userDetail.savedSearchTypeNotices,
      consolidateNotificationSelect: userDetail.consolidateNotificationSelect,
      consolidateNotificationNumber: userDetail.consolidateNotificationNumber,
      taskReminders: userDetail.taskReminders,
      onEmailNotifications: userDetail.onEmailNotifications,
    })
  }

  /***
   *
   */
  const onDeleteBulkHandler = () => {
    const params = {
      ids: isAllCheckedSavedSearches
        ? [...savedSearchesUnselected]
        : [...savedSearchesSelected],
      type: isAllCheckedSavedSearches ? 'notIn' : 'in',
      savedSearchOwner: userDetail.userName,
    }
    onDeleteBulk(params).then(res => {
      saveDataAfterDeleteBulkSearch(params)
      onCloseConfirmDeleteBulk()
    })
  }

  return (
    <>
      <SavedSearchesCounter />
      <Paper className="relative" hidden>
        <>
          <SpacingContainer px="5sm3" py={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
              style={{ margin: '0.1rem 0rem' }}
            >
              <Grid item xs zeroMinWidth py={10}>
                <Typography variant="h4" weight={500} fontFamily="lexend">
                  <IconSavedSearches /> {trans('saved-searches')}
                </Typography>
              </Grid>
              {!pending && !emptyArray(items) && (
                <>
                  {isEnabled(
                    permissions,
                    'registered-users',
                    'saved-searches',
                    'edit'
                  ) && (
                    <Grid item className={styles.notificationContainer}>
                      <Typography
                        className={styles.notificationText}
                        weight="600"
                      >
                        {'Notifications'}
                      </Typography>
                      <Switch
                        size="small"
                        checked={Boolean(
                          userDetail.savedSearchNotices === 1 ? true : false
                        )}
                        onChange={onToggleSwitch}
                      />
                    </Grid>
                  )}
                  {isEnabled(
                    permissions,
                    'registered-users',
                    'saved-searches',
                    'delete'
                  ) && (
                    <Grid item>
                      <Button
                        disabled={pending}
                        color={
                          deleteSearchesCount === 0 ? 'disabled' : 'secondary'
                        }
                        variant="outlined"
                        onClick={
                          deleteSearchesCount === 0
                            ? null
                            : onOpenConfirmDeleteBulk
                        }
                      >
                        {`Delete Searches (${deleteSearchesCount})`}
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            <SpacingContainer px="0" py={2}>
              <Divider light />
              {pending && <LoaderSmall />}
              {!pending && !emptyArray(items) && (
                <Table
                  stickyHeader
                  head={
                    <Head
                      orderBy={orderBy}
                      orderDir={orderDir}
                      onChangeOrder={onChangeOrder}
                      onAddAllSavedSearches={addAllSavedSearches}
                      onRemoveAllSavedSearches={removeAllSavedSearches}
                      isAllCheckedSavedSearches={isAllCheckedSavedSearches}
                      isSomeCheckedSavedSearches={isSomeCheckedSavedSearches}
                    />
                  }
                >
                  {items.map(item => (
                    <Item
                      key={item.id}
                      item={item}
                      userDetail={userDetail}
                      pending={pending}
                      isChecked={
                        savedSearchesSelected.find(s => s === item.id)
                          ? true
                          : false
                      }
                      addOneSavedSearches={addOneSavedSearches}
                      removeOneSavedSearches={removeOneSavedSearches}
                      saveDataAfterDeleteSearch={saveDataAfterDeleteSearch}
                      {...itemActions}
                    />
                  ))}
                </Table>
              )}
              {!pending && emptyArray(items) && (
                <NothingFoundCardList
                  Icon={<IconSearch notFound />}
                  title={trans('no-saved-searches')}
                  description={trans('all-saved-searches-will-appear-here')}
                />
              )}
            </SpacingContainer>
          </SpacingContainer>
        </>

        {count > 0 && (
          <PaginationSmall
            {...pagination}
            count={count}
            onChangePage={onChangePage}
            onChangeRowPerPage={onChangeRowPerPage}
            pending={pending}
            px="5sm3"
            py={2}
            divider="top"
          />
        )}
      </Paper>

      <ConfirmDialog
        open={openConfirmDeleteBulk}
        onClose={onCloseConfirmDeleteBulk}
        onConfirm={onDeleteBulkHandler}
        pending={pending}
        title={trans('delete-saved-search')}
        text={trans('confirm-delete-saved-search-bulk')}
        confirmTitle={trans('delete')}
      >
        <Typography variant="button" color="hint">
          {trans('saved-searches-to-delete')}
        </Typography>
        <Typography color="primary">{deleteSearchesCount}</Typography>
      </ConfirmDialog>
    </>
  )
}

export default connect(SavedSearchesList)
