import React from 'react'
import { Box } from '@material-ui/core'
import { useTrans } from '../../../services/i18n'
import { FormControlLabel, alpha } from '@material-ui/core'
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'
import Typography from '../../../components/common/Typography'
import { CheckBox } from '../../../components/common/Inputs'
import { PROGRESS_COLOR_MAP } from './ProgressStatus'
import connect from '../connect'

function CustomFormControl({ label, onChange, checked, color = '#fff' }) {
  const style = {
    backgroundColor: checked ? alpha(color, 0.16) : 'transparent',
    padding: '1.25rem .75rem',
    borderRadius: '0.75rem',
    border: `1px solid ${checked ? 'transparent' : alpha(color, 0.1)}`,
    gap: '0.25rem',
    transition: 'all 150ms ease-in-out',
    userSelect: 'none',
    maxHeight: '52px',
  }

  return (
    <FormControlLabel
      style={style}
      control={
        <CheckBox
          icon={
            <RadioButtonUnchecked
              htmlColor={color}
              style={{ width: '1.2rem' }}
            />
          }
          checkedIcon={
            <RadioButtonChecked htmlColor={color} style={{ width: '1.2rem' }} />
          }
          checked={checked}
          onChange={onChange}
          dense
          color={color}
        />
      }
      label={
        <Typography weight="500" variant="body2" color="label">
          {label}
        </Typography>
      }
    />
  )
}

function ProgressFilters({ filters = {}, onChangeFilters }) {
  const { trans } = useTrans()

  const getCheckedValue = key => {
    return filters.fields.progressStatus.value.includes(key)
  }

  const setCheckedValue = (key, value) => {
    let newFilterValue = [...filters.fields.progressStatus.value]
    if (value) {
      newFilterValue.push(key)
    } else {
      newFilterValue = newFilterValue.filter(k => k !== key)
    }
    onChangeFilters({
      progressStatus: {
        type: 'ANY_OF_THOSE',
        value: newFilterValue,
      },
    })
  }

  return (
    <Box display="flex" alignItems="center" gridGap="1rem">
      <Typography weight="500" variant="body2" color="hint">
        {trans('filter')}
      </Typography>
      <Box>
        {Object.keys(PROGRESS_COLOR_MAP).map(status => (
          <CustomFormControl
            label={trans(status)}
            color={PROGRESS_COLOR_MAP[status]}
            checked={getCheckedValue(status)}
            onChange={e => setCheckedValue(status, e.target.checked)}
          />
        ))}
      </Box>
    </Box>
  )
}

export default connect(ProgressFilters)
