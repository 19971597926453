import React from 'react'
import { application } from '../../../services/application'
import Typography from '../../../components/common/Typography'
import { Link as RouterLink } from 'react-router-dom'
import { useAlertContext } from '../../../contexts/AlertContext'
import { useTrans } from '../../../services/i18n'

const ResendConfirmationLink = React.memo(({ email, onSent }) => {
  const [pendingResend, setPendingResend] = React.useState(false)
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const { trans } = useTrans()

  return (
    <RouterLink
      onClick={
        pendingResend
          ? null
          : e => {
              e.preventDefault()
              setPendingResend(true)
              application
                .call('user.resendConfirmationCode', {
                  email: email.trim().replaceAll(' ', '+'),
                })
                .then(() => {
                  successAlert(
                    'Confirmation link was re-sent successfully',
                    'Please check your email'
                  )
                  onSent?.()
                })
                .finally(() => {
                  setPendingResend(false)
                })
            }
      }
    >
      <Typography
        variant={'span'}
        color={pendingResend ? 'disabled' : undefined}
      >
        {trans('resend-confirmation-email')}
      </Typography>
    </RouterLink>
  )
})

export default ResendConfirmationLink
