import { useTrans } from '../../../../services/i18n'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Grid } from '@material-ui/core'
import React from 'react'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import Typography from '../../../../components/common/Typography'
import Item from './Item'
import connect from '../connect'
import { emptyArray } from '../../../../utils/arrayUtils'
import { Divider } from '../../../../components/common/Divider'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard'
import { Button } from '../../../../components/common/Buttons'
import { IconPlus } from '../../../../components/svg/Icons'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import FormAddUser from './FormAddUser'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const OrganizationMembers = ({
  authUser,
  detail,
  getDetail,
  handleAssociateUser,
  pushNewMember,
}) => {
  const { trans } = useTrans()
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const permissions = useSelector(selectUserPermissions)

  return (
    <SpacingContainerBase py={1}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              variant="h4"
              weight="500"
              fontFamily="lexend"
              style={{ alignItems: 'center', display: 'flex' }}
            >
              <PeopleOutlineIcon style={{ marginRight: '10px' }} />{' '}
              {trans('organization-members')}{' '}
              <ShowTooltip sectionToShow="organization-members" />
            </Typography>
          </Grid>
          <Grid item>
            {isEnabled(
              permissions,
              'organization',
              'organization-members',
              'create'
            ) && (
              <Button
                isIcon
                color="primary"
                variant="contained"
                onClick={onOpenModal}
                disabled={detail?.availableSeats === 0}
              >
                <IconPlus />
              </Button>
            )}
            <FormAddUser
              getDetail={getDetail}
              organization={detail}
              open={openModal}
              onClose={onCloseModal}
              saveUser={handleAssociateUser}
              pushNewMember={pushNewMember}
              authUser={authUser}
            />
          </Grid>
        </Grid>
        {detail && !emptyArray(detail.members) && (
          <SpacingContainer px="0" py={2}>
            <Divider light />
            <TableContainer component={Paper} radius={9}>
              <Table style={{ minWidth: 1000 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{trans('user')}</TableCell>
                    <TableCell align="left">{trans('email')}</TableCell>
                    <TableCell align="left">{trans('registered')}</TableCell>
                    <TableCell align="left">{trans('last-login')}</TableCell>
                    <TableCell align="left">{trans('role')}</TableCell>
                    <TableCell align="left">{trans('status')}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detail.members.map(m => (
                    <Item user={m} key={m.id} authUser={authUser} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </SpacingContainer>
        )}
        {emptyArray(detail.members) && (
          <NothingFoundCard
            title={trans('no-members-added-yet')}
            size="medium"
          />
        )}
      </Paper>
    </SpacingContainerBase>
  )
}
export default connect(OrganizationMembers)
