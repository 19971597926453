import * as Yup from 'yup'
import makeValidationSchema from '../../../../hooks/makeValidationSchema'
import { salesforceId } from '../../../../constants/regex'

export const initialValues = {
  orgName: '',
  salesforceId: '',
}

export const getInitialValues = item => {
  return Object.assign({}, initialValues, item)
}

export const useScheme = makeValidationSchema(() => {
  return Yup.object().shape({
    orgName: Yup.string().required('Required'),
    salesforceId: Yup.string().matches(salesforceId, "Invalid Salesforce ID"),
  })
})
