import React from 'react';
import Tooltip from '../../../../../components/common/Tooltip';
import Typography from '../../../../../components/common/Typography';
import { getNotificationFrequencyValue } from '../../../../../utils/savedSearchesUtils';
import PersonalSettingIcon from './PersonalSettingIcon';
import styles from './styles.module.css';

function EmailingChip({ item, userDetail, isGlobal }) {
  let value = '';
  const { savedSearchNotices, savedSearchTypeNotices, consolidateNotificationSelect, consolidateNotificationNumber } =
    userDetail;

  if (isGlobal) {
    value = getNotificationFrequencyValue({
      enabled: savedSearchNotices,
      notification: savedSearchTypeNotices,
      notificationFrequency: consolidateNotificationSelect,
      notificationNumber: consolidateNotificationNumber,
    });
  } else {
    value = getNotificationFrequencyValue({ enabled: savedSearchNotices, ...item });
  }
  return (
    <div className={styles.emailing}>
      {!isGlobal && !!savedSearchNotices && (
        <Tooltip
          placement="left"
          RefComponent="span"
          title={<Typography variant="caption">Personal setting</Typography>}
          className={styles.tooltip}
        >
          <PersonalSettingIcon />
        </Tooltip>
      )}

      <span>{value}</span>
    </div>
  );
}

export default EmailingChip;
