import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { API, SCOPE_REQUEST_TEMPLATE_FILES } from '../../constants'
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common'
import {
  extendListBuilder,
  generateListActions,
} from '../helpers/listActionsHelpers'
import { application } from '../../services/application'

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['createdAt', 'desc'],
}

//Actions
const listActions = generateListActions({
  scope: SCOPE_REQUEST_TEMPLATE_FILES,
  apiMethod: {
    GET_LIST: API.REQUEST_TEMPLATE_FILES.GET_LIST,
  },
  getStore: (store) => store.requestTemplates,
})

const deleteRequestTemplateFile = createAsyncThunk(
  'requestTemplateFile/deleteRequestTemplateFile',
  (params) => {
    return application.call(API.REQUEST_TEMPLATE_FILES.DELETE, params)
  }
)
function onDeleteRequestTemplateFile(state, action) {}

const findOne = createAsyncThunk('requestTemplateFile/findOne', (params) => {
  return application.call(API.REQUEST_TEMPLATE_FILES.FIND_ONE, params)
})
function onFindOne(state, action) {}

const saveRequestTemplateFile = createAsyncThunk(
  'requestTemplateFile/saveRequestTemplateFile',
  (params) => {
    return application.call(
      API.REQUEST_TEMPLATE_FILES.SAVE_REQUEST_TEMPLATE,
      params
    )
  }
)

const getList = createAsyncThunk('requestTemplateFile/getList', (params) => {
  return application.call(API.REQUEST_TEMPLATE_FILES.GET_LIST, params)
})
function onGetList(state, action) {
  state.items = action.payload.items
  state.count = action.payload.count
  state.pending = false
}

function onSaveRequestTemplateFile(state, action) {}

export const actions = {
  ...listActions,
  findOne,
  deleteRequestTemplateFile,
  saveRequestTemplateFile,
  getList,
}

export default createReducer(INITIAL_STATE, (builder) => {
  return extendListBuilder(builder, actions)
    .addCase(deleteRequestTemplateFile.fulfilled, onDeleteRequestTemplateFile)
    .addCase(findOne.fulfilled, onFindOne)
    .addCase(saveRequestTemplateFile.fulfilled, onSaveRequestTemplateFile)
    .addCase(getList.pending, (state) => {
      state.pending = true
    })
    .addCase(getList.fulfilled, onGetList)
})
