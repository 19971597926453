import { SpacingContainer } from '../../../components/common/Containers/Base'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import connect from '../connect'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'

const Header = () => {
  const { trans } = useTrans()

  return (
    <>
      <SpacingContainer py={3} px={0}>
        <Typography
          weight="600"
          variant="h2"
          fontFamily="lexend"
          title
          style={{ alignItems: 'center', display: 'flex' }}
        >
          {trans('org-logo-review')}
          <ShowTooltip sectionToShow="organizations-table" />
        </Typography>
      </SpacingContainer>
    </>
  )
}

export default connect(Header)
