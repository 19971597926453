import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Paper } from '../../common/Containers';
import { SpacingContainer } from '../../common/Containers/Base';
import Skeleton from '../../common/Skeleton';

export default function OpportunitySkeleton() {
  return (
    <Paper component={SpacingContainer} py="5sm3" px="5sm3">
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item sm={3} xs={6}>
              <Skeleton height={30} variant="rect" />
            </Grid>
            <Grid item sm={3} xs={6}>
              <Skeleton height={30} variant="rect" />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Skeleton height={50} variant="rect" />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Skeleton height={20} variant="rect" />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Skeleton />
              <Skeleton />
            </Grid>
            <Grid item sm={6} xs={6}>
              <Skeleton />
              <Skeleton />
            </Grid>
            <Grid item xs={6}>
              <Skeleton />
              <Skeleton />
            </Grid>
            <Grid item sm={6} xs={6}>
              <Skeleton />
              <Skeleton />
            </Grid>
            <Grid item xs={6}>
              <Skeleton />
              <Skeleton />
            </Grid>
            <Grid item sm={6} xs={6}>
              <Skeleton />
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
              <Skeleton />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Skeleton height={20} variant="rect" />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Grid>
      </Grid>
    </Paper>
  );
}
