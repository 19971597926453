import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit'
import { API } from '../../constants'
import {
  ACCOUNT_FEATURES,
  SUBSCRIPTION_SETTINGS_INITIAL_STATE,
} from '../../constants/subscriptionSettings'
import { application } from '../../services/application'

const TABS = [
  ACCOUNT_FEATURES.EXPIRED,
  ACCOUNT_FEATURES.LIMITED,
  ACCOUNT_FEATURES.ADVANTAGE,
  ACCOUNT_FEATURES.PRO,
  ACCOUNT_FEATURES.FREE_TRIAL,
  ACCOUNT_FEATURES.SAP_ADVANTAGE,
  ACCOUNT_FEATURES.APP_MAX,
  ACCOUNT_FEATURES.PROCUREMENT_PRO,
]

//State
export const INITIAL_STATE = {
  samApiKey: '',
  currentFormDetail: {
    id: '',
    name: '',
    url: '',
  },
  subscriptionSettingsData: [],
  componentFeatures: {},
  optionTab: 1,
  tabTier: TABS[0],
  keepTrashedNotificationsFor: [],
  keyTabOptions: {
    1: ACCOUNT_FEATURES.EXPIRED,
    2: ACCOUNT_FEATURES.LIMITED,
    3: ACCOUNT_FEATURES.ADVANTAGE,
    4: ACCOUNT_FEATURES.PRO,
    5: ACCOUNT_FEATURES.FREE_TRIAL,
    6: ACCOUNT_FEATURES.SAP_ADVANTAGE,
    7: ACCOUNT_FEATURES.APP_MAX,
    8: ACCOUNT_FEATURES.PROCUREMENT_PRO,
  },
  subscriptionSetting: [],
}

const getFeatures = createAsyncThunk('clientDetail/getFeatures', params => {
  return application.call(API.GLOBAL_SETTINGS.GET_FEATURES, params)
})
function onGetFeatures(state, action) {
  let settings = []

  if (action.payload.success) {
    settings = action.payload?.subscriptionSettings
  } else {
    settings = SUBSCRIPTION_SETTINGS_INITIAL_STATE
  }

  return Object.assign(state, {
    subscriptionSettingsData: settings,
    optionTab: state.optionTab || 1,
    subscriptionSettings: [
      {
        key: 'expired-tab',
        idTab: 1,
        label: 'Expired',
        existingUsers: {
          optionsList: [...action.payload.expiredFeatures],
        },
      },
      {
        key: 'limited-tab',
        idTab: 2,
        label: 'Limited',
        existingUsers: {
          optionsList: [...action.payload.limitedFeatures],
        },
      },
      {
        key: 'advantage-tab',
        idTab: 3,
        label: 'Advantage',
        existingUsers: {
          optionsList: [...action.payload.advantageFeatures],
        },
      },
      {
        key: 'pro-tab',
        idTab: 4,
        label: 'Pro',
        existingUsers: {
          optionsList: [...action.payload.proFeatures],
        },
      },
      {
        key: 'free-trial-tab',
        idTab: 5,
        label: 'Free Trial',
        existingUsers: {
          optionsList: [...action.payload.freeTrialFeatures],
        },
      },
      {
        key: 'sap-advantage-tab',
        idTab: 6,
        label: 'SAP Advantage',
        existingUsers: {
          optionsList: [...action.payload.sapAdvantageFeatures],
        },
      },
      {
        key: 'app-max-tab',
        idTab: 7,
        label: 'APP Max',
        existingUsers: {
          optionsList: [...action.payload.appMaxFeatures],
        },
      },
      {
        key: 'procurement-pro-tab',
        idTab: 8,
        label: 'Procurement Pro',
        existingUsers: {
          optionsList: [...action.payload.procurementProFeatures],
        },
      },
    ],
    componentFeatures: {
      [ACCOUNT_FEATURES.EXPIRED]: action?.payload?.expiredComponents || [],
      [ACCOUNT_FEATURES.LIMITED]: action?.payload?.limitedComponents || [],
      [ACCOUNT_FEATURES.ADVANTAGE]: action?.payload?.advantageComponents || [],
      [ACCOUNT_FEATURES.PRO]: action?.payload?.proComponents || [],
      [ACCOUNT_FEATURES.FREE_TRIAL]: action?.payload?.freeTrialComponents || [],
      [ACCOUNT_FEATURES.SAP_ADVANTAGE]:
        action?.payload?.sapAdvantageComponents || [],
      [ACCOUNT_FEATURES.APP_MAX]: action?.payload?.appMaxComponents || [],
      [ACCOUNT_FEATURES.PROCUREMENT_PRO]:
        action?.payload?.procurementProComponents || [],
    },
  })
}

const setComponentFeatures = createAction(
  'subscriptionSettings/setComponentFeatures'
)
function onSetComponentFeatures(state, action) {
  return Object.assign(state, {
    componentFeatures: action.payload,
  })
}

const changeTab = createAction('subscriptionSettings/changeTab')
function onChangeTab(state, action) {
  return Object.assign(state, {
    optionTab: action.payload,
    tabTier: TABS[action.payload - 1],
  })
}

const updateSubscriptionSettings = createAction(
  'subscriptionSettings/updateSubscriptionSettings'
)
function onUpdateSubscriptionSettings(state, action) {
  return Object.assign(state, {
    subscriptionSettings: action.payload,
  })
}

const saveSubscriptionSettings = createAsyncThunk(
  'subscriptionSettings/saveSubscriptionSettings',
  params => {
    return application.call(API.SUBSCRIPTION_SETTINGS.SAVE_SETTINGS, params)
  }
)
function onSaveSubscriptionSettings(state, action) {
  const newData = state.subscriptionSettings.map(tab => {
    if (tab.key != `${action.payload.type}-tab`) {
      return tab
    } else {
      return {
        ...tab,
        existingUsers: {
          optionsList: [...action.payload.features],
        },
      }
    }
  })
  return Object.assign(state, {
    subscriptionSettings: newData ? newData : state.subscriptionSettings,
  })
}

const updateOrganizationsSettings = createAsyncThunk(
  'subscriptionSettings/updateOrganizationsSettings',
  params => {
    return application.call(
      API.SUBSCRIPTION_SETTINGS.UPDATE_ORGANIZATION_SETTINGS,
      params
    )
  }
)
function onUpdateOrganizationsSettings(state, action) {
  return Object.assign(state, {})
}

const updateApiKey = createAsyncThunk(
  'subscriptionSettings/updateApiKey',
  params => {
    return application.call(API.SUBSCRIPTION_SETTINGS.UPDATE_API_KEY, params)
  }
)
function onUpdateApiKey(state, action) {
  return Object.assign(state, {
    samApiKey: action.payload.samApiKey,
  })
}

const getKeepTrashedNotifications = createAsyncThunk(
  'subscriptionSettings/getKeepTrashedNotification',
  params => {
    return application.call(
      API.SUBSCRIPTION_SETTINGS.GET_KEEP_TRASHED_NOTIFICATIONS,
      params
    )
  }
)
function onGetKeepTrashedNotifications(state, action) {
  return Object.assign(state, {
    keepTrashedNotificationsFor: action.payload.keepTrashedNotificationsFor,
  })
}

function onUpdateKeepTrashedNotification(state, action) {
  return Object.assign(state, {
    keepTrashedNotificationsFor: action.payload.keepTrashedNotificationsFor,
  })
}
const updateKeepTrashedNotification = createAsyncThunk(
  'globalSettings/updateKeepTrashedNotification',
  params => {
    return application.call(
      API.GLOBAL_SETTINGS.UPDATE_KEEP_TRASHED_NOTIFICATIONS,
      params
    )
  }
)

const saveFeatures = createAsyncThunk('globalSettings/saveFeatures', params => {
  return application.call(API.GLOBAL_SETTINGS.SAVE_FEATURES, params)
})
function onSaveFeatures(state, action) {
  const freeFeatures = action.payload.freeFeatures
  const premiumFeatures = action.payload.premiumFeatures

  return Object.assign(state, {
    freeFeatures: freeFeatures ? freeFeatures : state.freeFeatures,
    premiumFeatures: premiumFeatures ? premiumFeatures : state.premiumFeatures,
  })
}

//Actions
export const actions = {
  getFeatures,
  updateSubscriptionSettings,
  saveFeatures,
  getKeepTrashedNotifications,
  updateApiKey,
  changeTab,
  updateKeepTrashedNotification,
  saveSubscriptionSettings,
  updateOrganizationsSettings,
  setComponentFeatures,
}

export default createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(getFeatures.fulfilled, onGetFeatures)
    .addCase(saveFeatures.fulfilled, onSaveFeatures)
    .addCase(updateSubscriptionSettings, onUpdateSubscriptionSettings)
    .addCase(changeTab, onChangeTab)
    .addCase(
      getKeepTrashedNotifications.fulfilled,
      onGetKeepTrashedNotifications
    )
    .addCase(updateApiKey.fulfilled, onUpdateApiKey)
    .addCase(
      updateKeepTrashedNotification.fulfilled,
      onUpdateKeepTrashedNotification
    )
    .addCase(saveSubscriptionSettings.fulfilled, onSaveSubscriptionSettings)
    .addCase(updateOrganizationsSettings, onUpdateOrganizationsSettings)
    .addCase(setComponentFeatures, onSetComponentFeatures)
})
