import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { API } from '../../../constants';
import { successAlert } from '../../../contexts/AlertContext';
import { application } from '../../../services/application';
import { getFilterParams } from '../../../utils/filterUtils';
import { getObjectHashCode } from '../../../utils/helpers';
import { onRejectAlert } from '../sharedCases';

export function generateSearchActions({ scope, apiMethod, getStore }) {
  if (!(scope && apiMethod && typeof getStore === 'function')) return {};

  return {
    loadSearch: createAction(`${scope}/loadSearch`),

    saveSearch: createAsyncThunk(`${scope}/saveSearch`, (data, { getState }) => {
      const { title, notification } = data;
      const { count, filters, order } = getStore(getState());
      const { text, fields: storeFields } = filters;
      const fields = getFilterParams(storeFields);
      const search = { filters: { text, fields }, order };

      return apiMethod.SAVE_SEARCH
        ? application.call(apiMethod.SAVE_SEARCH, { title, notification, search, scope, count })
        : new Error('no api method');
    }),

    createSharedSearch: createAsyncThunk(`${scope}/shareSearch`, (_, { getState }) => {
      const { filters, order, sharedSearch } = getStore(getState());
      const search = { filters, order };

      if (sharedSearch) {
        const searchHash = getObjectHashCode(search);
        if (searchHash && sharedSearch.hash && Number(searchHash) === Number(sharedSearch.hash)) {
          return sharedSearch;
        }
      }

      return application.call(apiMethod.SHARE_SEARCH, { search, scope });
    }),

    getAndLoadSearchById: createAsyncThunk(`${scope}/getAndLoadSearchById`, ({ id, type }, { getState }) => {
      const { searchId } = getStore(getState());

      // Do not load search if already loaded
      if (searchId === id) return null;
      return application.call(type === 'saved' ? API.SAVED_SEARCH.FIND_ONE : API.SHARED_SEARCH.FIND_ONE, { id, scope });
    }),
  };
}

export function extendBuilderWithSearchActions(builder, actions) {
  const { saveSearch, createSharedSearch, loadSearch, getAndLoadSearchById } = actions;

  const setSearchParams = (state, search) => {
    const { filters, order, searchId } = search;
    const { text, fields } = filters || state.filters;

    Object.assign(state, {
      searchId,
      filters: { text: text || '', fields: fields || {} },
      order: order || state.order,
    });
  };

  if (loadSearch) {
    builder.addCase(loadSearch, (state, action) => {
      setSearchParams(state, action.payload);
    });
  }

  if (getAndLoadSearchById) {
    builder.addCase(getAndLoadSearchById.fulfilled, (state, action) => {
      const result = action.payload;
      if (!(result && result.search)) return;

      setSearchParams(state, Object.assign({ searchId: result.id }, result.search));
    });
  }

  if (saveSearch) {
    builder
      .addCase(saveSearch.fulfilled, (_state, _action) => {
        successAlert('Success');
      })
      .addCase(saveSearch.rejected, onRejectAlert);
  }

  if (createSharedSearch) {
    builder.addCase(createSharedSearch.fulfilled, (state, action) =>
      Object.assign(state, { sharedSearch: action.payload })
    );
  }
}

export function generateSearchCallbacks(dispatch, actions) {
  const { createSharedSearch, loadSearch, saveSearch, fetchList } = actions || {};
  if (!(createSharedSearch && fetchList && dispatch)) return {};

  return {
    onLoadSearch: async (params) => {
      dispatch(loadSearch(params));
      await dispatch(fetchList());
    },
    onCreateShareSearch: (params) => dispatch(createSharedSearch(params)),
    onSaveSearch: (params) => dispatch(saveSearch(params)),

    onInit: generateOnInitCallbackWithSearches(dispatch, actions),
  };
}

export function generateOnInitCallbackWithSearches(dispatch, actions, extendCallback) {
  const { setParams, fetchList, getAndLoadSearchById, setFilters } = actions;

  return (data) => {
    batch(async () => {
      const { savedSearchId, sharedSearchId, text, ...params } = data || {};

      if (savedSearchId || sharedSearchId) {
        await dispatch(
          getAndLoadSearchById({
            id: savedSearchId ? savedSearchId : sharedSearchId,
            type: savedSearchId ? 'saved' : 'shared',
          })
        );
      }

      if (typeof extendCallback === 'function') {
        await Promise.resolve(extendCallback());
      }

      if (text) dispatch(setFilters({ text }));
      if (params) dispatch(setParams(params));
      await dispatch(fetchList());
    });
  };
}
