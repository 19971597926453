import { Box, Grid, Hidden, makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Paper } from '../../../components/common/Containers'
import { SpacingContainer } from '../../../components/common/Containers/Base'
import Avatar from '../../../components/common/Cards/Avatar'
import { Title } from './Title'
import ActionButtons from './ActionButtons'
import { AvatarWithTitle } from './AvatarWithTitle'
import DetailsRow from './DetailsRow'
import CodeSetAsideInfo from './CodeSetAsideInfo'
import Keywords from './Keywords'
import Typography from '../../../components/common/Typography'
import { formatDate } from '../../../utils/dateUtils'
import connect from '../connect'
import { useLocation, useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2 /* number of lines to show */,
    lineClamp: 2,
    '-webkit-box-orient': 'vertical',
  },
}))

const TeamingOpportunityItem = ({
  item,
  getAvatarDownloadUrl,
  variant = 'list',
  onClick,
}) => {
  const classes = useStyles()
  const [avatar, setAvatar] = React.useState(true)

  const detailLink = useMemo(() => {
    const { pathname, search } = window.location
    const newParams = `qv=y&id=${item.id}`
    return `${pathname}${search ? `${search}&${newParams}` : '?' + newParams}`
  }, [item])

  const getAvatar = React.useCallback(() => {
    getAvatarDownloadUrl(item.organizationId)
      .then(url => setAvatar(url))
      .catch(console.warn)
  }, [getAvatarDownloadUrl, item.organizationId])

  React.useEffect(() => {
    getAvatar()
  }, [getAvatar])

  const isContract = useMemo(() => item?.jobType === 'Contract', [item])

  return !item ? null : (
    <Paper component={SpacingContainer} py={3} px={3}>
      <Grid container spacing={2}>
        <Hidden xsDown>
          <Box display="flex" width="100%">
            <Avatar
              size={60}
              variant={'h5'}
              value={item.title}
              src={avatar}
              alt={item.title}
            />
            <Box flex={1} marginLeft="1rem">
              <Box display="flex" justifyContent="space-between">
                <Box flex={1}>
                  <Title
                    variant="h4"
                    weight="600"
                    color="primary"
                    detailLink={detailLink}
                    onClick={onClick}
                    title={item.title}
                  />
                </Box>
                <Box>
                  <ActionButtons
                    item={item}
                    isSaved={false}
                    variant={variant}
                  />
                </Box>
              </Box>
              <DetailsRow
                publisher={item?.publisher}
                location={item?.location}
                postedDate={formatDate(item.createdAt)}
                responseByDate={formatDate(item.responseBy)}
                variant={variant}
              />
            </Box>
          </Box>
        </Hidden>

        <Hidden smUp>
          <Grid item xs={12}>
            <AvatarWithTitle
              size={40}
              variant={'body1'}
              title={item.title}
              detailLink={detailLink}
              onClick={onClick}
              src={avatar}
            />
          </Grid>
          <Grid item xs={12}>
            <DetailsRow
              publisher={item.publisher}
              location={item.location}
              postedDate={formatDate(item.createdAt)}
              responseByDate={formatDate(item.responseBy)}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12}>
          <Typography weight={'400'} className={classes.description}>
            {item.description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CodeSetAsideInfo
            naicsCodes={isContract ? [item.primaryNaics, ...item.naics] : []}
            setAsides={isContract ? item.lookingForSetAsides : []}
            federalGrantPrograms={!isContract ? item.federalGrantPrograms : []}
          />
        </Grid>
        <Grid item xs={12}>
          <Keywords keywords={item.keywords} />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default connect(TeamingOpportunityItem)
