import { Grid, MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  DATE_FILTER_OPTIONS,
  DATE_UNIT_LIST,
  IS_AFTER,
  IS_BEFORE,
  IS_BETWEEN,
  WITHIN_THE_LAST,
  WITHIN_THE_NEXT,
  DEFAULT_DATE_STATE,
} from '../../../../../constants/filter';
import { useTrans } from '../../../../../services/i18n';
import { omit } from '../../../../../utils/helpers';
import { DatePicker, TextField } from '../../../../common/Inputs';

const DateFilter = ({ getFieldProps, values, setValues }) => {
  const { trans } = useTrans();

  useEffect(() => {
    setValues({ ...omit(DEFAULT_DATE_STATE, 'type'), type: values.type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.type]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12}>
        <TextField {...getFieldProps('type')} select>
          {DATE_FILTER_OPTIONS.map((v, i) => (
            <MenuItem key={i} value={v}>
              {trans(v.toLocaleLowerCase())}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {(values.type === IS_BETWEEN || values.type === IS_AFTER) && (
        <Grid item sm={3} xs={12}>
          <DatePicker {...getFieldProps('from')} placeholder={trans('from')} />
        </Grid>
      )}
      {(values.type === IS_BETWEEN || values.type === IS_BEFORE) && (
        <Grid item sm={3} xs={12}>
          <DatePicker {...getFieldProps('to')} placeholder={trans('to')} />
        </Grid>
      )}

      {(values.type === WITHIN_THE_LAST || values.type === WITHIN_THE_NEXT) && (
        <>
          <Grid item sm={3} xs={12}>
            <TextField {...getFieldProps('unitCount')} placeholder={trans('number')} />
          </Grid>
          <Grid item sm={3} xs={12}>
            <TextField {...getFieldProps('dateUnit')} select>
              {DATE_UNIT_LIST.map((v, i) => (
                <MenuItem key={i} value={v}>
                  {trans(v.toLocaleLowerCase())}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DateFilter;
