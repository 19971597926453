import { useCallback } from 'react';
import { queryParse } from '../../utils/query';
import useQueryHistory from './useQueryHistory';

export default function useQueryCallbacks() {
  const { query, setToHistory, rmFromHistory, clearQueryString } = useQueryHistory();

  const isQuerySet = useCallback((key, value) => query[key] === value[key], [query]);

  // SET
  const setQuery = useCallback(
    (...params) =>
      () =>
        setToHistory(...params),
    [setToHistory]
  );

  const setQueryFromInput = useCallback(
    ({ target = {} }) => {
      if (!target.name) return;

      if (typeof target.checked !== 'undefined' && !target.checked) {
        return rmFromHistory(target.name);
      }

      return setToHistory({ [target.name]: target.value });
    },
    [setToHistory, rmFromHistory]
  );

  const setQueryFromHref = useCallback(
    (e) => {
      try {
        e.preventDefault();
        const href = e.currentTarget.getAttribute('href') || e.currentTarget.getAttribute('data-href') || e.value;
        if (!href) return;

        return setToHistory(queryParse(href));
      } catch (error) {
        return;
      }
    },
    [setToHistory]
  );

  const setSimpleQueryFromHref = useCallback(
    (e) => {
      try {
        const href = e.value;
        if (!href) return;

        return setToHistory(queryParse(href));
      } catch (error) {
        return;
      }
    },
    [setToHistory]
  );

  // DELETE
  const deleteQuery = useCallback(
    (...params) =>
      () =>
        rmFromHistory(...params),
    [rmFromHistory]
  );

  const deleteQueryFromHref = useCallback(
    (e) => {
      try {
        e.preventDefault();
        const href = e.currentTarget.getAttribute('href') || e.currentTarget.getAttribute('data-href');
        if (!href) return;

        const queryParams = queryParse(href);
        return rmFromHistory(Object.keys(queryParams || {}));
      } catch (error) {
        return;
      }
    },
    [rmFromHistory]
  );

  return {
    query,
    clearQueryString,
    setQuery,
    setQueryFromInput,
    setQueryFromHref,
    setSimpleQueryFromHref,
    deleteQuery,
    deleteQueryFromHref,
    isQuerySet,
  };
}
