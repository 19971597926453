import Grid from '@material-ui/core/Grid'
import React, { useEffect } from 'react'
import usePopover from '../../../../hooks/usePopover'
import Avatar from '../../../common/Cards/Avatar'
import ListItemTextBase from '../../../common/Containers/Base/ListItemTextBase'
import { ContextMenuListItem, Popover } from '../../../common/Dialogs/Popover'
import Typography from '../../../common/Typography'
import { useTrans } from '../../../../services/i18n'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import useStyles from '../styles'
import useLogout from '../../../../hooks/useLogout'
import connect from './connect'
import useUserFullName from '../../../../hooks/useUserFullName'
import { Divider } from '../../../common/Divider'
import { Link } from 'react-router-dom'

function UserMenu({ authUser, getTooltips }) {
  const styles = useStyles()
  const { trans } = useTrans()
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()
  const onLogout = useLogout()

  const fullName = useUserFullName(authUser)

  useEffect(() => {
    getTooltips()
  }, [])

  return (
    <>
      <Grid
        container
        alignItems="center"
        className={`${styles.avatarRoot} pointer`}
        onClick={onOpenPopover}
        wrap="nowrap"
      >
        <Avatar
          bgColor="secondary"
          value={fullName}
          position="left"
          size={40}
        />

        <Typography
          component="span"
          variant="body2"
          noWrap
          weight="bold"
          className={styles.avatarTitle}
        >
          {fullName}
          {/* <Typography
            style={{ width: '100%', fontSize: '12px', color: '#979db4' }}
            component="span"
            variant="body2"
            noWrap
          >
            {authUser.email}
          </Typography> */}
        </Typography>

        <ArrowDropDown />
      </Grid>
      <Popover
        id={'id-user-menu'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClosePopover}
      >
        {/*
        <ContextMenuListItem button component={Link} to="/profile">
          <ListItemTextBase>{trans('Profile')}</ListItemTextBase>
        </ContextMenuListItem>

        <Divider light spacing={1} />
        */}

        <ContextMenuListItem button onClick={onLogout}>
          <ListItemTextBase>{trans('logout')}</ListItemTextBase>
        </ContextMenuListItem>
      </Popover>
    </>
  )
}

export default connect(UserMenu)
