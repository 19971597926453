import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { useTrans } from '../../../../services/i18n'
import premiumIcon from '../../../../components/assests/premium-icon.png'
import Typography from '../../../../components/common/Typography'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import ModalSeats from '../../components/ModalSeats'
import AvailableSeats from './AvailableSeats'
import connect from '../connect'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const OrganizationSeats = ({ detail, authUser }) => {
  const { trans } = useTrans()
  const [contactOpen, setContactOpen] = React.useState(false)
  const permissions = useSelector(selectUserPermissions)

  return (
    <SpacingContainerBase py={1}>
      <ModalSeats
        open={contactOpen}
        onClose={() => setContactOpen(false)}
        item={detail}
      />
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            sm={detail?.associatedSalesforceId ? 8 : 10}
            xs={detail?.associatedSalesforceId ? 8 : 10}
            lg={6}
          >
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <img src={premiumIcon} alt="" />
              </Grid>
              <Grid item>
                <Typography variant="body2" fontFamily="lexend" weight="500">
                  {trans('active-licenses')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            lg={6}
            sm={12}
            xs={12}
            style={{
              display: 'flex',
              gap: 10,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <AvailableSeats settings={detail} />
            {!(authUser.role == 'manager' || authUser.role == 'consulting') &&
              detail?.associatedSalesforceId && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setContactOpen(true)
                  }}
                  disabled={
                    !isEnabled(
                      permissions,
                      'organization',
                      'additional-seats',
                      'edit'
                    )
                  }
                >
                  {trans('manage-seats')}
                </Button>
              )}
          </Grid>
        </Grid>
      </Paper>
    </SpacingContainerBase>
  )
}

export default connect(OrganizationSeats)
