import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { IconFilters } from '../../../components/svg';
import usePopover from '../../../hooks/usePopover';
import { emptyArray } from '../../../utils/arrayUtils';
import { isFiltersSet } from '../../../utils/filterUtils';
import { useSearchContext } from './context';
import FiltersMenu from './FiltersMenu';
import SearchField from './SearchField';
import useStyles from './styles';

const SearchPanelQuickView = ({ action }) => {
  const styles = useStyles();
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();

  const { state, onTextChange, setCurrent } = useSearchContext();
  const { text, fields } = state.filters || {};
  const filterOptions = state.filterOptions;

  const isFiltersSetMemo = React.useMemo(() => isFiltersSet(fields), [fields]);

  const onSelectFilter = React.useCallback(
    (filter) => {
      if (!fields) return;

      const state = fields[filter.value];
      setCurrent(Object.assign({}, filter, { state }));
      return onClosePopover();
    },
    [fields, onClosePopover, setCurrent]
  );

  return (
    <Grid container wrap="nowrap" className={styles.qvSearch}>
      <Grid item xs container alignItems="center">
        <Grid item xs>
          <SearchField onValueChange={onTextChange} value={text || ''} quickView name="searchTextQv" />
        </Grid>
      </Grid>
      {!emptyArray(filterOptions) && (
        <Grid item className={styles.action}>
          <IconButton onClick={onOpenPopover}>
            <Badge invisible={!isFiltersSetMemo} color="secondary" variant="dot">
              <IconFilters />
            </Badge>
          </IconButton>

          <FiltersMenu anchorEl={anchorEl} onClose={onClosePopover} onSelectFilter={onSelectFilter} />
        </Grid>
      )}
      {action && (
        <Grid item className={styles.action}>
          {action}
        </Grid>
      )}
    </Grid>
  );
};

export default SearchPanelQuickView;
