import React from 'react'
import { SvgIcon } from '../../common/SvgIcon'
import { simpleIconPropsTypes } from '../propTypes'

export const IconSavedSearches = props => {
  const defaultValue = 24
  const { width = defaultValue, height = defaultValue } = props

  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 24 24`}>
      <path
        fillRule="evenodd"
        d="M1 11C1 5.477 5.477 1 11 1s10 4.477 10 10a9.958 9.958 0 0 1-2.257 6.329l3.964 3.964a1 1 0 0 1-1.32 1.497l-.094-.083-3.964-3.964A9.958 9.958 0 0 1 11 21C5.477 21 1 16.523 1 11Zm18 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0Zm-5.707-2.707a1 1 0 0 1 1.497 1.32l-.083.094-4 4a1 1 0 0 1-1.32.083l-.094-.083-2-2a1 1 0 0 1 1.32-1.497l.094.083L10 11.585l3.293-3.292Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  )
}

IconSavedSearches.propTypes = simpleIconPropsTypes
