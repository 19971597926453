import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API, SCOPE_USERS } from '../../constants';
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common';
import { extendListBuilder, generateListActions, } from '../helpers/listActionsHelpers';
import { application } from '../../services/application'
import { onRejectAlert } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['createdAt', 'desc'],
};

//Actions
const listActions = generateListActions({
  scope: SCOPE_USERS,
  apiMethod: {
    GET_LIST: API.USERS.GET_LIST,
  },
  getStore: (store) => store.users,
});

const deleteUser = createAsyncThunk('users/deleteUser', async (params) => {
  return application.call(API.USERS.DELETE, params)
})
function onDeleteUser(state, action) {
  const copy = [...state.items]
  const index = copy.findIndex(i => i.id === action.payload.id)
  if (index !== -1) {
    copy.splice(index, 1)
    Object.assign(state, {
      items: [...copy],
      count: state.count - 1,
    })
  }
}

const disabledTwoFactorAuth = createAsyncThunk('users/disabledTwoFactorAuth', async (params) => {
  return application.call(API.USERS.DISABLE_TWO_FACTOR_AUTH, params)
})
function onDisabledTwoFactorAuth(state, action) {
  if (action?.payload?.user) {
    const copy = [...state.items]
    const index = copy.findIndex(i => i.id === action.payload.user)
    if (index !== -1) {
      copy[index]['2faActive'] = null
      Object.assign(state, {
        items: [...copy],
      })
    }
  }
}

export const actions = {
  ...listActions,
  deleteUser,
  disabledTwoFactorAuth
};

export default createReducer(INITIAL_STATE, (builder) => {
  return extendListBuilder(builder, actions)
  .addCase(deleteUser.fulfilled, onDeleteUser)
  .addCase(deleteUser.rejected, onRejectAlert)
  .addCase(disabledTwoFactorAuth.fulfilled, onDisabledTwoFactorAuth)
});
