import React from 'react'
import { useTrans } from '../../../../services/i18n'
import SpacingContainer from '../../../common/Containers/Base/SpacingContainer'
import { IconSearch } from '../../../svg/Icons/IconSearch'
import NothingFoundCard from './index'

export default function NothingFoundCardList({
  title,
  description,
  Icon,
  py = 2,
  px = 0,
  children = null,
}) {
  const { trans } = useTrans()

  return (
    <SpacingContainer py={py} px={px}>
      <NothingFoundCard
        Icon={Icon || <IconSearch notFound />}
        title={typeof title === 'string' ? title : trans('nothing-found')}
        description={
          typeof description === 'string'
            ? description
            : trans('please-try-something-else')
        }
        size="large"
        py={10}
      >
        {children}
      </NothingFoundCard>
    </SpacingContainer>
  )
}
