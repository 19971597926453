import * as Yup from 'yup';
import makeValidationSchema from '../../../hooks/makeValidationSchema';

export const initialValues = {
  title: '',
  content: '',
  tags: []
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    id: Yup.string().optional().nullable().default(undefined),
    tags: Yup.array().min(1, trans('required')),
    title: Yup.string().required(trans('required')),
    content: Yup.string().required(trans('required')),
  })
);
