import { useEffect, useCallback } from 'react'
import { Button } from '../../../../components/common/Buttons'
import { ModalDialog } from '../../../../components/common/Dialogs'
import { useTrans } from '../../../../services/i18n'
import { Grid } from '@material-ui/core'
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core'
import { useState } from 'react'
import Typography from '../../../../components/common/Typography'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import { Autocomplete } from '../../../../components/common/Inputs';
import { SpacingContainer } from '../../../../components/common/Containers'
import connect from './connect'
import { useAlertContext } from '../../../../contexts/AlertContext'

const AddFromTemplates = ({ item, open, getTemplates, saveRequest, getDetail, onLoading, onClose }) => {

  const { trans } = useTrans()
  const [templateType, setTemplateType] = useState('all')
  const [templateSelect, setTemplateSelect] = useState(null)
  const [templates, setTemplates] = useState([])
  const [busy, setBusy] = useState(false)
  const [loading, setLoading] = useState(false)
  const { successAlert, errorAlert } = useAlertContext()

  const handleSelectTemplateType = event => setTemplateType(event.target.value)
  const handleSelectTemplate = async ev => setTemplateSelect(ev.target.value)

  const closeModal = () => {
    setTemplateType('all')
    setTemplateSelect(null)
    setBusy(false)
    onClose()
  }

  const getAllTemplates = useCallback(async () => {
    if (open) {
      const { payload } = await getTemplates()
      if (payload) {
        setTemplates(payload)
      }
    }
  }, [open, getTemplates])

  const handleSave = async () => {
    setBusy(true)
    if (!templateSelect) return
    setLoading(true)
    const { payload } = await saveRequest({
      resourcesType: templateType,
      organizationId: item.id,
      templateId: templateSelect.id
    })
    if (payload) {
      onLoading(templateType)
      getDetail({ organizationId: item.id }).then(() => onLoading(false))
      closeModal()
      successAlert('Successful process')
    } else {
      errorAlert('An error occurred while loading the template, try again later.')
    }
    setLoading(false)
  }

  useEffect(() => {
    getAllTemplates()
  }, [getAllTemplates])

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={trans('add-request-from-template')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeModal}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              pending={loading}
            >
              {trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={4}>
        <Grid item style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <ErrorIcon />
          <Typography
            variant="h6"
            fontFamily="lexend"
            color="label"
            id="demo-radio-buttons-group-label"
          >
            {trans('text-modal-select-template-for-add-requests')}
          </Typography>
        </Grid>
      </Grid>

      <SpacingContainer px={0} py={4}>
        <Autocomplete
          options={templates}
          required
          disableClearable
          label={trans('choose-template-category')}
          placeholder={trans('required')}
          onChange={handleSelectTemplate}
          error={!templateSelect && busy && trans('required')}
        />
      </SpacingContainer>

      { templateSelect &&
      <Grid container>
        <Grid item>
          <FormControl>
            <Typography
              variant="h6"
              fontFamily="lexend"
              color="label"
            >
              {trans('include')}
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              row
              value={templateType}
              onChange={handleSelectTemplateType}
            >
              <FormControlLabel value="all" control={<Radio />} label={`${trans('files-and-forms')} (${ templateSelect?.filesCount + templateSelect?.formsCount})`} />
              <FormControlLabel value="file" control={<Radio />} label={`${trans('only-files')} (${templateSelect?.filesCount})`} />
              <FormControlLabel value="form" control={<Radio />} label={`${trans('only-forms')} (${templateSelect?.formsCount})`} />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      }
    </ModalDialog>
  )
}

export default connect(AddFromTemplates)
