import React, { useState } from 'react'
import connect from '../connect'
import Typography from '../../../components/common/Typography'
import { intlDateFormat } from '../../../utils/dateUtils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { InlineContainer } from '../../../components/common/Containers'
import { RouterLink } from '../../../components/common/Buttons'
import StatusChip from './Status'
import { IconButton } from '@material-ui/core'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ListItemTextBase from '../../../components/common/Containers/Base/ListItemTextBase'
import {
  ContextMenuListItem,
  Popover,
} from '../../../components/common/Dialogs/Popover'
import useBooleanHandlers from '../../../hooks/useBooleanHandlers'
import usePopover from '../../../hooks/usePopover'
import { useTrans } from '../../../services/i18n'
import ConfirmDialog from '../../../components/generics/Confirm/Dialog'
import { useNavigate } from 'react-router-dom'
import { useAlertContext } from '../../../contexts/AlertContext'
import { selectUserPermissions, isEnabled } from '../../../selectors'
import { useSelector } from 'react-redux'

const Item = ({ item, changeStatus, deleteRelease }) => {
  const { trans } = useTrans()
  let history = useNavigate()
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()
  const [openDelete, onOpenDelete, onCloseDelete] = useBooleanHandlers()
  const { successAlert } = useAlertContext()
  const permissions = useSelector(selectUserPermissions)

  const onChangeStatus = () => {
    const status = item.status === 'Published' ? 'Unpublished' : 'Published'
    changeStatus({ id: item.id, status })
  }

  const [pendingDelete, setPendingDelete] = useState(false)
  const onDelete = () => {
    setPendingDelete(true)
    deleteRelease({ id: item.id }).then(() => {
      setPendingDelete(false)
      onCloseDelete()
      successAlert(`${item?.title} has been removed`)
    })
  }

  return (
    <TableRow>
      <TableCell align="left">
        <InlineContainer spacing={1}>
          <Typography
            weight="500"
            to={`/releases/${item.id}`}
            variant="h6"
            component={RouterLink}
          >
            {item.title}
          </Typography>
        </InlineContainer>
      </TableCell>
      <TableCell align="left">
        <Typography variant="inherit" fontFamily="lexend">
          {item.author}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="inherit" fontFamily="lexend">
          {intlDateFormat(item.createdAt)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="inherit" fontFamily="lexend">
          {intlDateFormat(item.updatedAt)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <StatusChip status={item.status} />
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={onOpenPopover}>
          <MoreHorizIcon />
        </IconButton>
      </TableCell>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClosePopover}
      >
        <ContextMenuListItem
          button
          onClick={() => history(`/releases/${item.id}`)}
          onClickCB={onClosePopover}
        >
          <ListItemTextBase>{trans('view')}</ListItemTextBase>
        </ContextMenuListItem>

        {isEnabled(permissions, 'releases', '', 'edit') && (
          <>
            <ContextMenuListItem
              button
              onClick={() => history(`/releases/update/${item.id}`)}
              onClickCB={onClosePopover}
            >
              <ListItemTextBase>{trans('edit')}</ListItemTextBase>
            </ContextMenuListItem>
            <ContextMenuListItem
              button
              onClick={onChangeStatus}
              component={RouterLink}
              onClickCB={onClosePopover}
            >
              <ListItemTextBase>
                {item.status === 'Published'
                  ? trans('unpublish')
                  : trans('publish')}
              </ListItemTextBase>
            </ContextMenuListItem>
          </>
        )}
        {isEnabled(permissions, 'releases', '', 'delete') && (
          <ContextMenuListItem
            button
            onClick={onOpenDelete}
            onClickCB={onClosePopover}
          >
            <ListItemTextBase>{trans('delete')}</ListItemTextBase>
          </ContextMenuListItem>
        )}
      </Popover>

      <ConfirmDialog
        open={openDelete}
        onClose={onCloseDelete}
        onConfirm={onDelete}
        pending={pendingDelete}
        title={trans('delete-release')}
        text={trans('confirm-action-release')}
      >
        <Typography color="hint">{item.title}</Typography>
      </ConfirmDialog>
    </TableRow>
  )
}

export default connect(Item)
