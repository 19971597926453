import Grid from '@material-ui/core/Grid'
import React, { useState } from 'react'
import { Button } from '../../../../components/common/Buttons'
import { ModalDialog } from '../../../../components/common/Dialogs'
import { useTrans } from '../../../../services/i18n'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { SpacingContainer } from '../../../../components/common/Containers'
import Typography from '../../../../components/common/Typography'
import { SelectField } from '../../../../components/common/Inputs'
import { MenuItem } from '@material-ui/core'
import connect from '../connect'

const AddAssingFormsModal = ({ detail, open, onClose, saveFormOrg, forms }) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const [form, setForm] = useState('')
  const [busy, setBusy] = useState(false)

  const closeModal = () => {
    setForm('')
    setBusy(false)
    setIsSend(false)
    onClose()
  }

  const changeSelect = ev => {
    setForm(ev.target.value)
  }

  const saveForm = () => {
    setBusy(true)
    if (!form) return
    setIsSend(true)

    saveFormOrg({
      orgId: detail.id,
      formId: form.id,
    })
      .then(() => {
        successAlert(`Form assigned to this organization`)
        closeModal()
      })
      .catch(() => {
        errorAlert('Error')
        closeModal()
      })
  }

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={trans('assign-form')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSend}
              onClick={closeModal}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={saveForm}
            >
              {trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer px={3} py={3}>
        <Typography
          weight={500}
          variant="body2"
          color="hint"
          style={{ marginBottom: '0.5rem' }}
        >
          {trans('form')}
        </Typography>
        <SelectField
          type="select"
          placeholder={'Required'}
          name={'form'}
          value={form}
          getRenderValue={value => `${value.name}`}
          onChange={changeSelect}
          error={!form && busy && 'Required'}
        >
          {forms.map((i, index) => (
            <MenuItem key={index} value={i}>{i.name}</MenuItem>
          ))}
        </SelectField>
      </SpacingContainer>
    </ModalDialog>
  )
}

export default connect(AddAssingFormsModal)
