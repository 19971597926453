import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import React, { useMemo } from 'react'
import { useMediaQueryDown } from '../../../hooks/useMediaQuery'
import usePagination, {
  ROWS_PER_PAGE_OPTIONS,
} from '../../../hooks/usePagination'
import { useTrans } from '../../../services/i18n'
import Button from '../Buttons/Button'
import Paper from '../Containers/Paper'
import { TextField } from '../Inputs'
import Typography from '../Typography'
import styles from './styles'

export default function Pagination(props) {
  const { trans } = useTrans()
  const isMdScreen = useMediaQueryDown('sm')
  const rowsPerPage = useMemo(
    () => (props?.rowsPerPage ? props.rowsPerPage : ROWS_PER_PAGE_OPTIONS),
    [props.rowsPerPage]
  )
  const {
    page,
    perPage,
    count,
    countPages,
    isLastPage,
    pending,
    inputPage,
    handleInputPage,
    handleNextPage,
    handlePrevPage,
    handleChangeRowPerPage,
  } = usePagination(props)

  return count <= rowsPerPage[0] ? null : (
    <Grid container spacing={3} direction="column">
      {!isLastPage && (
        <Grid item>
          <Paper hidden>
            <Button
              pending={pending}
              size="large"
              fullWidth
              color="primary"
              onClick={handleNextPage}
              data-load="more"
            >
              <Typography weight={500}>
                {trans('load')} {perPage} {trans('more')}
              </Typography>
            </Button>
          </Paper>
        </Grid>
      )}

      <Grid item>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid
            item
            md="auto"
            sm={6}
            xs={6}
            className={isMdScreen ? 'order2' : undefined}
          >
            <Button
              onClick={handlePrevPage}
              pending={pending}
              disabled={page <= 1}
              isIcon
              variant="outlined"
              size="large"
            >
              <ChevronLeftIcon />
            </Button>
          </Grid>

          <Grid item md="auto" sm={6} xs={6}>
            <Grid container alignItems="center" spacing={2} wrap="nowrap">
              <Grid item xs="auto">
                <TextField
                  value={inputPage}
                  onChange={handleInputPage}
                  bgColor="secondary"
                  type="text"
                  style={styles.input}
                  align="center"
                  name="list-page"
                />
              </Grid>
              <Grid item xs="auto">
                <Typography weight="600" variant="body2" color="hint" noWrap>
                  {trans('of')} {countPages}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            md
            sm={6}
            xs={6}
            container
            alignItems="center"
            justifyContent={isMdScreen ? 'flex-end' : 'center'}
          >
            <Button
              onClick={handleNextPage}
              pending={pending}
              disabled={isLastPage}
              size="large"
              variant="contained"
              color="primary"
            >
              <Typography noWrap weight={600}>
                {trans('next-page')}
              </Typography>
            </Button>
          </Grid>

          <Grid item md="auto" sm={6} xs={6} className="order3">
            <Grid
              container
              alignItems="center"
              spacing={2}
              wrap="nowrap"
              justifyContent="flex-end"
            >
              <Grid item xs="auto">
                <Typography weight="600" variant="body2" color="hint" noWrap>
                  {trans('results-per-page')}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <TextField
                  bgColor="secondary"
                  select
                  onChange={handleChangeRowPerPage}
                  value={String(perPage)}
                  disabled={pending}
                >
                  {rowsPerPage.map(rowPageList => (
                    <MenuItem key={`rowList${rowPageList}`} value={rowPageList}>
                      {rowPageList}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
