import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'
import connect from '../../connect'
import { Button } from '../../../../components/common/Buttons'
import EditIcon from '@material-ui/icons/Edit'
import AssigmentIcon from '@material-ui/icons/AssignmentOutlined'
import Item from './item'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import SetupParentFormsModal from './setupParentFormsModal'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard'
import { emptyArray } from '../../../../utils/arrayUtils'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const ManageFeatures = ({
  forms,
  changeCurrentIdsForms,
  formstackForms,
  changeFormstackForms,
}) => {
  const { trans } = useTrans()
  const [modules, setModules] = useState([])
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const [openModalFile, setOpenModalFile] = useState(false)
  const permissions = useSelector(selectUserPermissions)

  const openModalFileAction = () => {
    setOpenModalFile(true)
  }
  const closeModalFileAction = () => {
    setOpenModalFile(false)
  }

  const parentFormsModal = () => {
    const ids = forms.map(i => i.formId)

    let formstackFormsCopy = []
    formstackFormsCopy = JSON.parse(JSON.stringify(formstackForms))
    formstackFormsCopy = formstackFormsCopy.map(i => ({
      ...i,
      checked: ids.includes(i.id),
    }))

    changeFormstackForms(formstackFormsCopy)

    changeCurrentIdsForms(ids)
    onOpenModal()
  }

  return (
    <SpacingContainer py={2}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <SetupParentFormsModal open={openModal} onClose={onCloseModal} />
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              variant="h4"
              weight="500"
              fontFamily="lexend"
              style={{ alignItems: 'center', display: 'flex' }}
            >
              <AssigmentIcon style={{ marginRight: '5px' }} />{' '}
              {trans('formstack-management')}{' '}
              <ShowTooltip sectionToShow="formstack-management" />
            </Typography>
          </Grid>
          {isEnabled(permissions, 'global-settings', '', 'edit') && (
            <Grid item>
              <Button
                isIcon
                color="primary"
                variant="contained"
                onClick={() => parentFormsModal()}
              >
                <EditIcon />
              </Button>
            </Grid>
          )}
        </Grid>
        <Divider light style={{ marginTop: '20px' }} />
        {!emptyArray(forms) && (
          <TableContainer component={Paper} radius={9}>
            <Table style={{ minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <TableCell>{trans('form-name')}</TableCell>
                  <TableCell align="left">
                    {trans('active-adjustment-forms')}
                  </TableCell>
                  {isEnabled(permissions, 'global-settings', '', 'edit') && (
                    <TableCell align="left">{trans('state')}</TableCell>
                  )}
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {forms.map(item => (
                  <Item form={item} key={item.id} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {emptyArray(forms) && (
          <>
            <div style={{ textAlign: 'center', padding: '16px' }}>
              <NothingFoundCard
                title={trans('no-forms-added-yet')}
                size="medium"
              />

              <Button
                color="primary"
                variant="contained"
                onClick={() => parentFormsModal()}
                disabled={
                  !isEnabled(permissions, 'global-settings', '', 'create')
                }
              >
                {trans('add-forms')}
              </Button>
            </div>
          </>
        )}
      </Paper>
    </SpacingContainer>
  )
}
export default connect(ManageFeatures)
