// SelectedDocumentCategoryContext.js
import { createContext, useContext, useState } from 'react'

const SelectedDocumentCategoryContext = createContext()

export const useSelectedDocumentCategory = () => {
  return useContext(SelectedDocumentCategoryContext)
}

export const SelectedDocumentCategoryProvider = ({ children }) => {
  const [selectedDocumentCategory, setSelectedDocumentCategory] = useState(null)
  const [selectedPath, setSelectedPath] = useState({ index: -1, path: '' }) // New state for selectedPath

  const value = {
    selectedDocumentCategory,
    setSelectedDocumentCategory,
    selectedPath, // Add selectedPath to the value object
    setSelectedPath, // Add setSelectedPath to the value object
  }

  return (
    <SelectedDocumentCategoryContext.Provider value={value}>
      {children}
    </SelectedDocumentCategoryContext.Provider>
  )
}
