import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconPlus = (props) => {
  const { width = 24, height = 24 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <path d="M12 5a1 1 0 0 1 .993.883L13 6v5h5a1 1 0 0 1 .117 1.993L18 13h-5v5a1 1 0 0 1-1.993.117L11 18v-5H6a1 1 0 0 1-.117-1.993L6 11h5V6a1 1 0 0 1 1-1z" fill="#FFF"/>
      </g>
    </SvgIcon>
  );
};

IconPlus.propTypes = simpleIconPropsTypes;
