import React from 'react'
import Typography from '../../../../components/common/Typography'
import Switch from '../../../../components/common/Inputs/Switch'
import { useTrans } from '../../../../services/i18n'
import { Box, Grid } from '@material-ui/core'
import { TextField } from '../../../../components/common/Inputs'

function FeatureField({ feature, onChange }) {
  const { trans } = useTrans()

  return (
    <>
      {feature.type === 'number' && (
        <Grid container>
          <Grid item xs={5}>
            <Typography
              style={{
                fontSize: '16px',
                fontFamily: 'Lexend',
              }}
              variant="body2"
              weight="600"
              key={`component-feature-${feature.key}`}
            >
              {trans(feature.key)}
            </Typography>
            {feature?.comment && (
              <Typography variant="caption" color="hint">
                {feature.comment}
              </Typography>
            )}
          </Grid>
          <Grid item xs={7}>
            <TextField
              placeholder="Ex.: 5"
              type="number"
              name={feature.key}
              onChange={ev => onChange(feature.key, parseInt(ev.target.value))}
              value={feature.value}
            />
          </Grid>
        </Grid>
      )}

      {feature.type === 'boolean' && (
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item xs={11}>
            <Typography
              style={{
                fontSize: '16px',
                fontFamily: 'Lexend',
              }}
              variant="body2"
              weight="600"
              key={`component-feature-${feature.key}`}
            >
              {trans(feature.key)}
            </Typography>
            {feature?.comment && (
              <Typography variant="caption" color="hint">
                {feature.comment}
              </Typography>
            )}
          </Grid>
          <Grid item xs={1}>
            <Switch
              checked={feature.value}
              inputProps={{ 'aria-label': 'module' }}
              color="primary"
              name={feature.key}
              onChange={ev => onChange(feature.key, ev.target.checked)}
            />
          </Grid>
          {/* {feature?.comment && (
            <Grid item xs={12}>
              <Typography variant="caption" color="hint">
                {feature.comment}
              </Typography>
            </Grid>
          )} */}
        </Grid>
      )}
    </>
  )
}

export default FeatureField
