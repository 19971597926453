import { useMemo } from 'react'
import useQueryCallbacks from '../../../hooks/query/useQueryCallbacks'

export const useItemStatus = item => {
  const { query } = useQueryCallbacks()
  const [canRestore, canUnblock] = useMemo(() => {
    const canRestore = query?.type === 'deleted' && item?.status === 'Deleted'
    const canUnblock = query?.type === 'blocked' && item?.status === 'Blocked'
    return [canRestore, canUnblock]
  }, [query, item])

  return { canRestore, canUnblock }
}
