import { useTrans } from '../../../services/i18n';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { Paper, SpacingContainer } from '../../../components/common/Containers';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Item from './Item'

export default function List({ items }) {
	const { trans } = useTrans();
	return (
		<TableContainer component={Paper} radius={9}>
			<SpacingContainer px="5sm3" py={2}>
				<Table style = {{minWidth: 600}}>
					<TableHead>
						<TableRow>
							<TableCell>{ trans('title') }</TableCell>
							<TableCell align="left">{ trans('author') }</TableCell>
							<TableCell align="center">{ trans('creation-date') }</TableCell>
							<TableCell align="center">{ trans('last-modified') }</TableCell>
							<TableCell align="center">{ trans('status') }</TableCell>
							<TableCell align="right">{ trans('') }</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
          {items.map(release => (
						<Item item={release} key={release.id}/>
          ))}
					</TableBody>
				</Table>
			</SpacingContainer>
		</TableContainer>
	)
}
