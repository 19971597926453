import {
  /*createAsyncThunk,*/ createReducer,
  createAction,
} from '@reduxjs/toolkit'
import { API, SCOPE_CLIENTS } from '../../constants'
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common'
import {
  extendListBuilder,
  generateListActions,
} from '../helpers/listActionsHelpers'

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['orgName', 'asc'],
}

const updateItemListLicense = createAction('clients/updateItemListLicense')
function onUpdateItemListLicense(state, action) {
  const copyItems = [...state.items]
  const orgIndex = copyItems.findIndex(i => i.id === action.payload.id)
  copyItems[orgIndex].licenses = action.payload.licenses
  return Object.assign(state, {
    items: copyItems,
  })
}

//Actions
const listActions = generateListActions({
  scope: SCOPE_CLIENTS,
  apiMethod: {
    GET_LIST: API.CLIENTS.GET_LIST,
  },
  getStore: store => store.clients,
})

export const actions = {
  ...listActions,
  updateItemListLicense,
}

export default createReducer(INITIAL_STATE, builder => {
  return extendListBuilder(builder, actions).addCase(
    updateItemListLicense,
    onUpdateItemListLicense
  )
})
