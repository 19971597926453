import Toolbar from './CustomToolbar'
import { Quill } from 'react-quill'

export function insertVariables(value, editorRef) {
  const selection = editorRef?.getSelection()
  if (!selection) return

  const cursorPosition = selection.index
  editorRef.insertText(cursorPosition, value)
  editorRef.setSelection(cursorPosition + value.length)
}

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size')
Size.whitelist = ['small', 'medium', 'large', 'huge']
Quill.register(Size, true)

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font')
Font.whitelist = ['sans-serif', 'serif', 'monospace']
Quill.register(Font, true)

export const getModules = containerId => ({
  toolbar: {
    container: `#${containerId}`,
    handlers: {
      insertVariables,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
})

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
]

const ToolbarWithInsertVariables = ({ toolbarId, editorRef, variables = [] }) => (
  <Toolbar
    toolbarId={toolbarId}
    dropdownItems={variables}
    onDropdownChange={value => insertVariables(value, editorRef)}
  />
)

export default ToolbarWithInsertVariables
