import React from 'react'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import connect from '../connect'
import { Card, Grid } from '@material-ui/core'
import ItemTopInfo from './ItemTopInfo'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'

const Metrics = ({ org, items }) => {
  const { trans } = useTrans()
  return (
    <>
      <Card style={{ marginTop: 30, marginBottom: 50 }}>
        <Paper component={SpacingContainer} py={3} px="5sm3">
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs zeroMinWidth>
              <Typography
                variant="h4"
                weight="bold"
                style={{ alignItems: 'center', display: 'flex' }}
              >
                {trans('organization-metrics')}{' '}
                <ShowTooltip sectionToShow="organization-metrics" />
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            {items.map(st => (
              <Grid key={st.text} item lg={3} md={6} sm={6} xs={12}>
                <ItemTopInfo {...st} />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Card>
    </>
  )
}

export default connect(Metrics)
