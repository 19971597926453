import { useState } from 'react'
import Typography from '../../../../components/common/Typography'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { InlineContainer } from '../../../../components/common/Containers'
import { RouterLink } from '../../../../components/common/Buttons'
import { IconButton } from '@material-ui/core'
import ConfirmDialog from '../../../../components/generics/Confirm/Dialog'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import { useTrans } from '../../../../services/i18n'
import Switch from '../../../../components/common/Inputs/Switch'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import usePopover from '../../../../hooks/usePopover'
import { useAlertContext } from '../../../../contexts/AlertContext'

import ListItemTextBase from '../../../../components/common/Containers/Base/ListItemTextBase'
import {
  ContextMenuListItem,
  Popover,
} from '../../../../components/common/Dialogs/Popover'
import connect from '../../connect'
import { useNavigate } from 'react-router-dom'
import AddFileToFormModal from './addFileToFormModal'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const Item = ({
  form,
  deleteForm,
  changeStatusForm,
  changeCurrentFormDetail,
}) => {
  const [openRemove, onOpenRemove, onCloseRemove] = useBooleanHandlers()
  const [pendingRemove, setPendingRemove] = useState(false)
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const [openAddFile, onOpenAddFile, onCloseAddFile] = useBooleanHandlers()
  const permissions = useSelector(selectUserPermissions)

  const navigate = useNavigate()

  const { trans } = useTrans()
  const onDeleteForm = () => {
    setPendingRemove(true)
    deleteForm({
      type: 'principal',
      id: form.id,
    })
      .then(() => {
        setPendingRemove(false)
        successAlert('Form deleted!')
        onCloseRemove()
      })
      .catch(error => {
        errorAlert(error.message)
      })
  }

  const changeStatus = () => {
    changeStatusForm({
      type: 'principal',
      id: form.id,
      active: form.active ? false : true,
    })
      .then(res => {
        successAlert('State changed!')
      })
      .catch(error => {
        errorAlert(error.message)
      })
  }

  const showDetail = () => {
    changeCurrentFormDetail(form)
    navigate(`/form/${form.id}`)
  }

  const openLink = () => {
    window.open(form.url, '_blank')
  }

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <InlineContainer spacing={1}>
            <Typography
              weight="500"
              onClick={() => showDetail()}
              variant="h6"
              component={RouterLink}
              noWrap
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '20rem',
              }}
            >
              {form.name}
            </Typography>
          </InlineContainer>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {form.adjustFormsActiveCount}/{form.adjustFormsCount}
          </Typography>
        </TableCell>
        {isEnabled(permissions, 'global-settings', '', 'edit') && (
          <TableCell align="left">
            <Typography variant="inherit" fontFamily="lexend">
              <Switch
                checked={form.active}
                onChange={() => changeStatus()}
                inputProps={{ 'aria-label': 'module' }}
                color="primary"
              />
            </Typography>
          </TableCell>
        )}
        <TableCell align="right">
          <IconButton onClick={onOpenPopover}>
            <MoreHorizIcon />
          </IconButton>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClosePopover}
          >
            <ContextMenuListItem
              button
              onClick={() => showDetail()}
              onClickCB={onClosePopover}
            >
              <ListItemTextBase>{trans('manage')}</ListItemTextBase>
            </ContextMenuListItem>
            <ContextMenuListItem
              button
              onClick={() => openLink()}
              onClickCB={onClosePopover}
            >
              <ListItemTextBase>{trans('view-on-formstack')}</ListItemTextBase>
            </ContextMenuListItem>
            {isEnabled(permissions, 'global-settings', '', 'delete') && (
              <ContextMenuListItem
                button
                onClick={() => onOpenRemove()}
                onClickCB={onClosePopover}
              >
                <ListItemTextBase>{trans('remove')}</ListItemTextBase>
              </ContextMenuListItem>
            )}
          </Popover>
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={openRemove}
        onClose={onCloseRemove}
        onConfirm={onDeleteForm}
        pending={pendingRemove}
        title={trans('remove-form')}
        text={`Are you sure you want to remove this form`}
      >
        <Typography color="hint">{form.name}</Typography>
      </ConfirmDialog>
      <AddFileToFormModal open={openAddFile} onClose={onCloseAddFile} />
    </>
  )
}

export default connect(Item)
