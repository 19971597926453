import React from 'react'
import { SvgIcon } from '../../common/SvgIcon'
import { simpleIconPropsTypes } from '../propTypes'

export const IconDownload = props => {
  const { width = 18, height = 18 } = props

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 16 18">
      <path
        d="M24 20c.513 0 .936.386.993.883L25 21v2.4c0 1.375-1.052 2.51-2.393 2.595l-.163.005H11.556c-1.364 0-2.468-1.082-2.551-2.436L9 23.4V21c0-.552.448-1 1-1 .513 0 .936.386.993.883L11 21v2.4c0 .304.207.547.467.592l.089.008h10.888c.27 0 .505-.213.549-.501L23 23.4V21c0-.552.448-1 1-1zM17 8c.513 0 .936.386.993.883L18 9v8.585l1.293-1.292c.36-.36.928-.388 1.32-.083l.094.083c.36.36.388.928.083 1.32l-.083.094-3 3c-.028.029-.059.055-.09.08l.09-.08c-.035.036-.073.068-.112.097-.024.018-.049.034-.074.05l-.037.021c-.02.011-.04.022-.06.031l-.053.023-.06.021-.045.014-.065.016-.053.009c-.02.003-.04.006-.062.007-.018.002-.035.003-.053.003L17 21h-.032l-.054-.004L17 21c-.05 0-.1-.004-.149-.011l-.052-.01c-.022-.004-.043-.009-.065-.015l-.046-.014-.06-.021-.051-.023-.061-.03-.037-.022c-.025-.016-.05-.032-.074-.05l-.018-.014c-.035-.028-.066-.054-.094-.083l-3-3c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083l.094.083L16 17.585V9c0-.552.448-1 1-1z"
        transform="translate(-866 -1570) translate(251 182) translate(0 1356) translate(606 24)"
      />
    </SvgIcon>
  )
}

IconDownload.propTypes = simpleIconPropsTypes
