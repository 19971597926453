import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit';
import { SCOPE_NOTIFICATIONS, API } from '../../constants';
import { INITIAL_CHECKED_ITEMS_STATE, INITIAL_LIST_STATE } from '../helpers/common';
import {
  extendBuilderWithListActions,
  extendBuilderWithNotInterestedActions,
  extendBuilderWithCheckItemsActions,
  generateListActions,
  generateCheckItemsActions,
} from '../helpers/listActionsHelpers';
import { application } from '../../services/application';
import { emptyArray } from '../../utils/arrayUtils';
import { successAlert } from '../../contexts/AlertContext';
import { extendBuilderWithAsyncAction, onPendingDone } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE,
  ...INITIAL_CHECKED_ITEMS_STATE,
  pagination: { page: 1, perPage: 20 },
  order: ['lastModifiedDate', 'desc'],

  items: [],
  count: 20,
  unReadCount: 0,
  settings: null,
  settingsPending: false,
};

const listActions = generateListActions({
  scope: SCOPE_NOTIFICATIONS,
  apiMethod: {
    GET_LIST: API.NOTIFICATIONS.GET_LIST,
  },
  getStore: (store) => store.notifications,
});

const checkedItemsActions = generateCheckItemsActions({ scope: SCOPE_NOTIFICATIONS });

function getCheckedIds(store) {
  const { checkedItems = [] } = store.notifications;
  return checkedItems;
}

const markAsRead = createAsyncThunk('notifications/markAsRead', (ids, { getState }) =>
  application.call(API.NOTIFICATIONS.MARK_AS_READ, { ids: emptyArray(ids) ? getCheckedIds(getState()) : ids })
);

const markAsArchived = createAsyncThunk('notifications/markAsArchived', (_, { getState }) =>
  application.call(API.NOTIFICATIONS.MARK_AS_ARCHIVED, { ids: getCheckedIds(getState()) })
);

const remove = createAsyncThunk('notifications/remove', (_, { getState }) =>
  application.call(API.NOTIFICATIONS.REMOVE, { ids: getCheckedIds(getState()) })
);

const getSettings = createAsyncThunk('notifications/getSettings', () => {
  return application.call(API.NOTIFICATIONS.GET_SETTINGS);
});

const setSettings = createAsyncThunk('notifications/setSettings', (params) => {
  return application.call(API.NOTIFICATIONS.SET_SETTINGS, params);
});

const getUnread = createAsyncThunk('notifications/getUnread', (params) => {
  return application.call(API.NOTIFICATIONS.GET_UNREAD, params);
});
function onGetUnread(state, action) {
  Object.assign(state, {
    unReadCount: action.payload,
  });
}

function settingsPending(state) {
  return {
    ...state,
    settingsPending: true,
  };
}

function onGetNotificationSettings(state, action) {
  return {
    ...state,
    settingsPending: false,
    settings: action.payload,
  };
}

export const actions = {
  ...listActions,
  ...checkedItemsActions,
  getSettings,
  setSettings,
  markAsRead,
  markAsArchived,
  remove,
  getUnread,
};

function onSuccess(state, action) {
  if (action.payload) successAlert('Success');
  return onPendingDone(state);
}

export default createReducer(INITIAL_STATE, (builder) => {
  extendBuilderWithListActions(builder, actions);
  extendBuilderWithNotInterestedActions(builder, actions);
  extendBuilderWithCheckItemsActions(builder, checkedItemsActions);

  extendBuilderWithAsyncAction(builder, markAsRead, { onSuccess });
  extendBuilderWithAsyncAction(builder, markAsArchived, { onSuccess });
  extendBuilderWithAsyncAction(builder, remove, { onSuccess });

  return builder
    .addCase(getSettings.pending, settingsPending)
    .addCase(getSettings.fulfilled, onGetNotificationSettings)
    .addCase(setSettings.pending, settingsPending)
    .addCase(setSettings.fulfilled, onGetNotificationSettings)
    .addCase(getUnread.fulfilled, onGetUnread);
});
