import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import { Grid } from '@material-ui/core'
import React, {  } from 'react'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import Typography from '../../../../components/common/Typography'
import RefreshIcon from '@mui/icons-material/Refresh';

import connect from '../connect'
import { useAlertContext } from '../../../../contexts/AlertContext'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import FeaturedVendorFields from './FeaturedVendorFields'
import VendorIdentifierCodeField from './VendorIdentifierCodeField'
import { Button } from '../../../../components/common/Buttons'
import { ERROR_MESSAGES } from './errorMessages'

const ClaimedVendor = ({ detail, refreshClaimedVendor }) => {
  const { featuredVendor } = detail
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)

  const isClaimed = !!featuredVendor?.idVendorClaim;

  const refreshVendor = () => {
    setIsSend(true);

    refreshClaimedVendor({ vendorIdentifier: featuredVendor.ueiCode })
    .then(res => {
      if (res?.error) {
        throw res.error
      }

      successAlert('Claimed vendor refreshed!')
      setIsSend(false)
    })
    .catch(err => {
      setIsSend(false)
      const message = err.message.toLowerCase()
      if (ERROR_MESSAGES.includes(message)) {
        errorAlert(trans(message))
      }
    })
  }

  return (
    <SpacingContainerBase py={0}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
          style={{ marginBottom: '.75rem' }}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              style={{
                alignItems: 'center',
                display: 'flex',
              }}
              variant="h4"
              weight="500"
              fontFamily="lexend"
            >
              <CorporateFareIcon /> {trans('claimed-vendor')}
            </Typography>
          </Grid>

          {
            isClaimed && (
              <Grid item>
              <Button
                isIcon
                color="primary"
                variant="contained"
                pending={isSend}
                onClick={refreshVendor}
              >
                <RefreshIcon />
              </Button>
            </Grid>
            )
          }
        </Grid>

        {isClaimed ? (
          <FeaturedVendorFields />
        ) : (
          <VendorIdentifierCodeField />
        )}

      </Paper>
    </SpacingContainerBase>
  )
}
export default connect(ClaimedVendor)
