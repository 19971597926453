import * as Yup from 'yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import makeValidationSchema from '../../../../../hooks/makeValidationSchema';
dayjs.extend(utc);

export const initialValues = {
  responseBy: null,
  title: '',
  description: '',
  naics: [],
  keywords: [],
  pursuit: null,
  jobType: 'Contract',
  primaryNaics: null,
  primaryPsc: null,
  location: '',
  opportunityNumber: '',
  dueDate: null,
  otherPscs: [],
  lookingForSetAsides: [],
  federalGrantPrograms: [],
  lookingForOrganizationTypes: [],
  skillSetsNeeded: [],
};

export const getInitialValues = (item) => {
  if (item) {
    item = {
      ...item,
      naics: item.naics ? item.naics.map((i) => ({ id: i, value: i })) : [],
    };
  }
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    id: Yup.string().optional().nullable().default(undefined),
    // pursuit: Yup.object().required(trans('required-field')).nullable(),
    title: Yup.string().required(trans('required-field')),
    dueDate: Yup.date()
      .nullable()
      .when('daysFulFill', {
        is: (daysFulFill) => !daysFulFill,
        then: Yup.date().required('Required field').min(dayjs().startOf('day'), 'Must be current or future date'),
        otherwise: Yup.date().nullable().min(dayjs().startOf('day'), 'Must be current or future date'), // Due date can be null if daysFulFill is set
      }),
    jobType: Yup.string().required(trans('required-field')),
    opportunityNumber: Yup.string().required(trans('required-field')),
    primaryNaics: Yup.object().required(trans('required-field')).nullable(),
    primaryPsc: Yup.object().required(trans('required-field')).nullable(),
  })
);

export const useSchemeGrant = makeValidationSchema((trans) =>
  Yup.object().shape({
    id: Yup.string().optional().nullable().default(undefined),
    // pursuit: Yup.object().required(trans('required-field')).nullable(),
    title: Yup.string().required(trans('required-field')),
    dueDate: Yup.date()
      .nullable()
      .when('daysFulFill', {
        is: (daysFulFill) => !daysFulFill,
        then: Yup.date().required('Required field').min(dayjs().startOf('day'), 'Must be current or future date'),
        otherwise: Yup.date().nullable().min(dayjs().startOf('day'), 'Must be current or future date'), // Due date can be null if daysFulFill is set
      }),
    jobType: Yup.string().required(trans('required-field')),
    opportunityNumber: Yup.string().required(trans('required-field')),
    federalGrantPrograms: Yup.array().required(trans('required-field')).min(1),
  })
);
