import clsx from 'clsx';
import React from 'react';
import Loader from '../../Loader';
import Paper from '../Paper';
import useStyles from './styles';

export default function SectionScrollable({
  children,
  TopFixed = null,
  BottomFixed = null,
  BottomFixedBar = null,
  dividedFixed = false,
  LeftSidebar = null,
  RightSidebar = null,
  className,
  BodyComponent = 'div',
  bodyProps = {},
  scrollableID,
  pending = false,
  bgColor = 'transparent',
  scrollProps = {},
  ...rest
}) {
  const styles = useStyles();

  return (
    <Paper className={clsx(styles.root, className)} square bgColor={bgColor} component="section" {...rest}>
      <Loader open={pending} />
      {TopFixed && <div className={clsx(styles.fixed, dividedFixed && styles.dividedTop)}>{TopFixed}</div>}
      <BodyComponent {...bodyProps} className={styles.body}>
        {LeftSidebar}
        <div id={scrollableID} className={styles.scrollable} {...scrollProps}>
          {children}
        </div>
        {RightSidebar}
      </BodyComponent>
      {BottomFixed && <div className={clsx(styles.fixed, dividedFixed && styles.dividedBottom)}>{BottomFixed}</div>}
    </Paper>
  );
}
