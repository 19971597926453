import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  '10py': { paddingTop: theme.spacing(10), paddingBottom: theme.spacing(10) },
  '7py': { paddingTop: theme.spacing(7), paddingBottom: theme.spacing(7) },
  '5py': { paddingTop: theme.spacing(5), paddingBottom: theme.spacing(5) },
  '4py': { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4) },
  '3py': { paddingTop: theme.spacing(3), paddingBottom: theme.spacing(3) },
  '2py': { paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) },
  '1py': { paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) },
  '5sm3py': {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: { paddingTop: theme.spacing(3), paddingBottom: theme.spacing(3) },
  },

  '35px': { paddingLeft: theme.spacing(35), paddingRight: theme.spacing(35) },
  '25px': { paddingLeft: theme.spacing(25), paddingRight: theme.spacing(25) },
  '20px': { paddingLeft: theme.spacing(20), paddingRight: theme.spacing(20) },
  '7px': { paddingLeft: theme.spacing(7), paddingRight: theme.spacing(7) },
  '6px': { paddingLeft: theme.spacing(6), paddingRight: theme.spacing(6) },
  '5px': { paddingLeft: theme.spacing(5), paddingRight: theme.spacing(5) },
  '4px': { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) },
  '3px': { paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) },
  '2px': { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) },
  '1px': { paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) },
  '5sm3px': {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('sm')]: { paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) },
  },
  '4sm3px': {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: { paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) },
  },
  '4sm2px': {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) },
  },
}));

const SpacingContainer = React.forwardRef(({ className, component, py = 0, px = 0, ...rest }, ref) => {
  const Component = component ? component : 'div';
  const styles = useStyles();

  return (
    <Component
      ref={ref}
      className={clsx(className, styles[`${py}py`], styles[`${px}px`], component === 'ul' && 'app-ul')}
      {...rest}
    />
  );
});

export default SpacingContainer;
