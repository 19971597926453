import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useApplication } from '../services/application'
import { AmplifyAuth } from '../services/amplifyAuth'
import { ROUTE_SIGNIN } from '../constants'

export default function useLogout() {
  const navigate = useNavigate()
  const application = useApplication()

  // eslint-disable-next-line no-undef
  return React.useCallback(() => {
    AmplifyAuth.signOut()
      .then(() => application.authorize())
      .then(() => navigate(ROUTE_SIGNIN.path))
      .catch(console.warn)
  }, [navigate, application])
}
