import React from 'react'
import DropdownVariables from '../DropdownVariables'

function Toolbar({ toolbarId, dropdownItems, onDropdownChange }) {
  return (
    <div id={toolbarId}>
      <span className="ql-formats">
        <select className="ql-font" defaultValue="sans-serif">
          <option value="sans-serif">Sans Serif</option>
          <option value="serif">Serif</option>
          <option value="monospace">Monospace</option>
        </select>
      </span>

      <span className="ql-formats">
        <select className="ql-size" defaultValue="medium">
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
          <option value="huge">Huge</option>
        </select>
      </span>

      <span className="ql-formats">
        <select className="ql-header" defaultValue="3">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option value="3">Normal</option>
        </select>
      </span>

      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
      </span>

      <span className="ql-formats">
        <select className="ql-color" />
        <select className="ql-background" />
        <button className="ql-link" />
        <select className="ql-align" />
      </span>

      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />

        {dropdownItems?.length ? (
          <DropdownVariables
            onChange={onDropdownChange}
            items={dropdownItems}
          />
        ) : null}
      </span>
    </div>
  )
}

export default Toolbar
