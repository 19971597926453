export function formatNumber(value, format = 'en-US', options) {
  try {
    const number = Number(value);
    if (!number) return '0';

    return new Intl.NumberFormat(format, options).format(number);
  } catch (error) {
    return '0';
  }
}

export function formatPrettyUSD(value, maximumFractionDigits = 0, minimumFractionDigits = 0) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits,
    maximumFractionDigits,
  })
    .format(value || 0)
    .toLowerCase();
}

export function formatBigNumber(value, maximumFractionDigits = 0, minimumFractionDigits = 0) {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits,
    maximumFractionDigits,
  })
    .format(value || 0)
    .toLowerCase();
}

export function formatPercent(value) {
  return new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 }).format(value);
}

export function formatPhoneNumber(str) {
  // Filter only numbers from the input
  const cleaned = ('' + str).replace(/\D/g, '');

  // Check if the input is of correct
  const match = cleaned.match(/^(\d{1,6}|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    let intlCode = match[1] ? `+${match[1]} ` : '';
    return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
  }

  return null;
}
