import React from 'react';
import { Chip } from '../../../../components/common/Chip';
import Typography from '../../../../components/common/Typography';
import { IconClose } from '../../../../components/svg';

export const ChipOption = ({ value, name, onClick, onDelete }) => (
  <>
    {name && (
      <Chip
        label={
          <Typography variant="caption" color="primary" weight={600}>
            {name}
          </Typography>
        }
        color="white"
        square
        onClick={onClick}
      />
    )}
    <Chip label={<Typography variant="caption">{value}</Typography>} color="white" square />
    {onDelete ? <Chip label={<IconClose />} clickable onClick={onDelete} isIcon color="white" square /> : null}
  </>
);
