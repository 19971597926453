import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import { useTrans } from '../../../../services/i18n'
import { Grid } from '@material-ui/core'
import { Button } from '../../../../components/common/Buttons'
import { Divider } from '../../../../components/common/Divider'
import {
  Breadcrumb,
  BreadcrumbSeparator,
  BreadcrumbWrapper,
} from './components/StyledComponents' // Import from the separate file
import connect from '../connect'
import { TableCategory, TableCategoryDetail } from './components'
import React, { useEffect, useState } from 'react'
import { LoaderSmall } from '../../../../components/common/LoaderSmall'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelectedDocumentCategory } from '../../../../contexts/SelectedDocumentCategoryContext'
import { sortByFoldersFirst } from '../../../../utils/arrayUtils'

const Documents = ({
  organization,
  getCategories,
  downloadOrganizationFiles,
  getCategoryFiles,
  downloadFile,
}) => {
  const { trans } = useTrans()
  const [categories, setCategories] = useState([])
  const [state, setState] = useState({
    downloadingAll: false,
    loadingDetails: false,
    fullCategoryFiles: [],
    categoryFiles: [],
    breadcrumb: [],
  })
  const { selectedDocumentCategory, setSelectedDocumentCategory } =
    useSelectedDocumentCategory()
  /***
   * Get all categories from DB
   */
  const getAllCategories = React.useCallback(async () => {
    const { payload } = await getCategories({ organizationId: organization.id })
    if (payload && payload.categories) {
      setCategories(payload.categories)
    }
  }, [getCategories, organization.id])

  /***
   * Call method from WS
   */
  useEffect(() => {
    ;(async () => {
      if (!selectedDocumentCategory) return
      setState(prev => ({ ...prev, loadingDetails: true }))
      const { payload } = await getCategoryFiles({
        organizationId: organization.id,
        category: selectedDocumentCategory,
      })
      if (payload && payload.files) {
        const categoryFiles = groupFilesByPath(payload.files)
        setState(prev => ({
          ...prev,
          categoryFiles: categoryFiles.sort(sortByFoldersFirst),
          fullCategoryFiles: categoryFiles.sort(sortByFoldersFirst),
          loadingDetails: false,
        }))
      } else {
        setState(prev => ({
          ...prev,
          categoryFiles: [],
          loadingDetails: false,
        }))
      }
    })()
  }, [selectedDocumentCategory, organization.id, getCategoryFiles])

  function groupFilesByPath(files) {
    const groupedFiles = []

    for (const file of files) {
      const { path, fileSizeLong, updatedAt, ...rest } = file
      const pathArr = path || [] // Use an empty array if path is not defined
      let currentFolder = groupedFiles

      for (const folderName of pathArr) {
        let nextFolder = currentFolder.find(
          item => item.name === folderName && item.type === 'folder'
        )

        if (!nextFolder) {
          nextFolder = {
            name: folderName,
            type: 'folder',
            files: [],
            folderSize: 0,
            lastUpdated: updatedAt, // Initialize lastUpdated with the current file's updatedAt
          }
          currentFolder.push(nextFolder)
        } else if (new Date(nextFolder.lastUpdated) < new Date(updatedAt)) {
          // If the current file's updatedAt is later than the folder's lastUpdated, update it
          nextFolder.lastUpdated = updatedAt
        }

        currentFolder = nextFolder.files
        nextFolder.folderSize += Number(fileSizeLong)
      }

      currentFolder.push({ ...rest, updatedAt, type: 'file' })
    }

    return groupedFiles
  }

  /***
   * Call method from WS
   */
  useEffect(() => {
    getAllCategories()
  }, [getAllCategories])

  const onDownloadAll = file => {
    setState(prev => ({ ...prev, downloadingAll: true }))
    downloadOrganizationFiles({
      organizationId: organization.id,
      category: selectedDocumentCategory,
      filename: `${organization.orgName}.zip`,
    }).then(() => {
      setState(prev => ({ ...prev, downloadingAll: false }))
    })
  }

  const handleBreadcrumbItemClick = index => {
    // Slice the breadcrumb array to keep only items up to the clicked item
    const updatedBreadcrumb = state.breadcrumb.slice(0, index + 1)
    setState(prev => ({
      ...prev,
      breadcrumb: updatedBreadcrumb,
      categoryFiles: getFilesFromFolder(
        state.fullCategoryFiles,
        state.breadcrumb[index]
      ),
    }))

    // Update the categoryFiles state with the files in the clicked folder
    // You might need to add a helper function to get the corresponding folder
    // object from the breadcrumb path and set the categoryFiles state accordingly.
  }

  function getFilesFromFolder(data, folderName) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].type === 'folder') {
        if (data[i].name === folderName) {
          return data[i].files
        } else if (data[i].files) {
          const files = getFilesFromFolder(data[i].files, folderName)
          if (files) {
            return files
          }
        }
      }
    }
    return []
  }

  return (
    <SpacingContainerBase py={3}>
      <Paper component={SpacingContainer}>
        <SpacingContainer px={5} py={3}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item zeroMinWidth>
              {selectedDocumentCategory && (
                <BreadcrumbWrapper>
                  <Breadcrumb
                    pointer
                    onClick={() => setSelectedDocumentCategory(null)}
                  >
                    {trans('organization-documents')}
                  </Breadcrumb>
                  <BreadcrumbSeparator>{'>'}</BreadcrumbSeparator>
                  <Breadcrumb
                    color={state.breadcrumb.length === 0 ? '#000' : null}
                    pointer={state.breadcrumb.length > 0}
                    onClick={() =>
                      setState(prev => ({
                        ...prev,
                        breadcrumb: [],
                        categoryFiles: prev.fullCategoryFiles,
                      }))
                    }
                  >
                    {selectedDocumentCategory}
                  </Breadcrumb>
                  {state.breadcrumb.map((item, index) => (
                    <>
                      <BreadcrumbSeparator>{'>'}</BreadcrumbSeparator>
                      <Breadcrumb
                        pointer={state.breadcrumb.length > index + 1}
                        color={
                          state.breadcrumb.length === index + 1 ? '#000' : null
                        }
                        onClick={() => handleBreadcrumbItemClick(index)}
                      >
                        {item}
                      </Breadcrumb>
                    </>
                  ))}
                </BreadcrumbWrapper>
              )}
              {!selectedDocumentCategory && (
                <Breadcrumb color="#000">
                  {trans('organization-documents')}
                </Breadcrumb>
              )}
            </Grid>
            <Grid item spacing={2}>
              {state.downloadingAll ? (
                <CircularProgress color="inherit" size="1.1rem" />
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    (selectedDocumentCategory &&
                      state.categoryFiles.length === 0) ||
                    state.breadcrumb.length !== 0
                  }
                  onClick={onDownloadAll}
                >
                  {trans('download-all')}
                </Button>
              )}
            </Grid>
          </Grid>
        </SpacingContainer>

        <Divider light />

        <SpacingContainer px="5sm3" py={2}>
          {(categories.length === 0 || state.loadingDetails) && <LoaderSmall />}
          {categories.length > 0 && !selectedDocumentCategory && (
            <TableCategory
              categories={categories}
              downloadOrganizationFiles={downloadOrganizationFiles}
              organization={organization}
            />
          )}
          {selectedDocumentCategory && !state.loadingDetails && (
            <TableCategoryDetail
              files={state.categoryFiles}
              downloadFile={downloadFile}
              setState={setState}
            />
          )}
        </SpacingContainer>
      </Paper>
    </SpacingContainerBase>
  )
}

export default connect(Documents)
