export const ABBREVIATION_EXCEPTIONS = ['USA', 'LLC', 'U.S.', 'US']
export const LOWER_CASE_EXCEPTIONS = ['of', 'for', 'on']

export const V_ANCHORS = [
  //{ id: 'summary', i18nKey: 'summary' },
  { id: 'organization-details', i18nKey: 'organization-details' },
  { id: 'assigned-staff', i18nKey: 'assigned-staff' },
  { id: 'requests', i18nKey: 'request-files-forms' },
  //{ id: 'assigned-forms', i18nKey: 'assigned-forms' },
  { id: 'manage-features', i18nKey: 'manage-features' },
  { id: 'billing-settings', i18nKey: 'billing-settings' },
  { id: 'claimed-vendor', i18nKey: 'claimed-vendor' },
]
export const V_USFCRSTAFF = [
  { id: 'summary', i18nKey: 'summary' },
  { id: 'assigned-organizations', i18nKey: 'assigned-organizations' },
  { id: 'latest-activity', i18nKey: 'latest-activity' },
]

export const V_REGISTERED_USER = [
  { id: 'account', i18nKey: 'account' },
  { id: 'assigned-organizations', i18nKey: 'assigned-organizations' },
  { id: 'saved-searches', i18nKey: 'saved-searches' },
]

export const V_ADMIN_ROLES = [
  { id: 'role-info', i18nKey: 'role-info' },
  { id: 'role-permissions', i18nKey: 'role-permissions' },
]

export const formatNumberToUsd = (value, digits = 2, isShowSign = false) => {
  value = Number(value)
  if (!value) {
    value = 0
  }
  return `${
    isShowSign ? (value > 0 ? '+' : value < 0 ? '-' : '') : ''
  }${new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
    currencyDisplay: 'symbol',
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(value || 0)}`
}

export function replaceCharacters(string) {
  if (!string) return ''
  return string.replace(/[^a-zA-Z0-9]/g, '')
}

export function removeHyphenFromString(string) {
  return string.split('-').join('')
}

export function htmlToText(content) {
  const el = document.createElement('div')
  el.innerHTML = String(content || '')

  return el.innerText
}

export function formatToTitleCase(str) {
  if (!str) return str
  return str.replace(/\w\S*/g, txt => {
    if (ABBREVIATION_EXCEPTIONS.includes(txt.toUpperCase()))
      return txt.toUpperCase()
    if (LOWER_CASE_EXCEPTIONS.includes(txt.toLowerCase()))
      return txt.toLowerCase()
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function jsonStringify(obj) {
  if (!obj) return ''
  try {
    return JSON.stringify(obj)
  } catch (e) {
    console.warn(e)
    return ''
  }
}

export function jsonParse(str) {
  if (!str) return {}
  try {
    return JSON.parse(str)
  } catch (e) {
    console.warn(e)
    return {}
  }
}

export function cloneObject(object) {
  return jsonParse(jsonStringify(object))
}

export function omit(obj, ...props) {
  const result = { ...obj }
  props.forEach(function (prop) {
    delete result[prop]
  })
  return result
}

export function pick(obj, ...props) {
  return props.reduce(function (result, prop) {
    result[prop] = obj[prop]
    return result
  }, {})
}

export function hashCode(str) {
  let hash = 0,
    i,
    chr
  if (str.length === 0) return hash
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return Math.abs(hash)
}

export function sortObjectKeys(obj) {
  return Object.keys(obj)
    .sort()
    .reduce((acc, curr) => ({ ...acc, [curr]: obj[curr] }), {})
}

export function getObjectHashCode(obj) {
  return hashCode(JSON.stringify(sortObjectKeys(obj)))
}

export function getCurrentPathWithoutQuery() {
  return window.location.href.split('?')[0]
}

export function getSiteDomain() {
  return window.location.protocol + '//' + window.location.host
}

export function getClientSiteDomain() {
  return (window.location.protocol + '//' + window.location.host).replace('admin', 'app');
}

export function isNull(value) {
  return !(typeof value !== 'undefined' && value !== null)
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const EXPORT_COLORS = {
  PRIMARY: 'primary',
  ERROR: 'secondary',
};

export const EXPORT_COLOR_MAP = {
  normal: EXPORT_COLORS.PRIMARY,
  over: EXPORT_COLORS.ERROR,
};

export function getColorAndPercentage(current, limit) {
  const status = current > 0 && current > limit ? 'over' : 'normal';
  const color = EXPORT_COLOR_MAP[status];

  const currentValue = current > limit ? limit : current;
  const percentageValue = (currentValue / limit) * 100;

  return { color, percentageValue };
}