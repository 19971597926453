import React from 'react'
import { emptyArray } from '../../../utils/arrayUtils'
import { Grid } from '@material-ui/core'
import Pagination from '../../../components/common/Paginations/Pagination'
import TeamingOpportunityItem from '../TeamingOpportunityItem'
import NothingFoundCardList from '../../../components/generics/Cards/NothingFoundCard/NothingFoundCardList'
import connect from '../connect'
import { ITEM_VARIANT } from '../constants'

function List({ opportunities, onChangePage, onChangeRowPerPage }) {
  const { items, pending, pagination, count } = opportunities
  const hasItems = !emptyArray(items)

  return (
    <Grid container spacing={3} direction="column">
      {hasItems ? (
        items.map((item, index) => (
          <Grid key={index} item xs zeroMinWidth>
            <TeamingOpportunityItem item={item} variant={ITEM_VARIANT.LIST} />
          </Grid>
        ))
      ) : !pending ? (
        <Grid item xs zeroMinWidth>
          <NothingFoundCardList />
        </Grid>
      ) : null}

      {hasItems && (
        <Grid item xs zeroMinWidth>
          <Pagination
            page={pagination.page}
            perPage={pagination.perPage}
            count={count}
            onChangePage={onChangePage}
            onChangeRowPerPage={onChangeRowPerPage}
            pending={pending}
            rowsPerPage={Array.from(Array(101).keys())
              .filter(i => i % 5 === 0)
              .slice(1)} // All multiples of 5 from 0 to 100
          />
        </Grid>
      )}
    </Grid>
  )
}

export default connect(List)
