import { Grid } from '@material-ui/core'
import { SpacingContainer } from '../../../../../components/common/Containers/Base'
import Typography from '../../../../../components/common/Typography'
import { useTrans } from '../../../../../services/i18n'
import ListInfoRow from '../../../../../components/generics/ListInfoRow'
import SortingPanel from '../../../../../components/generics/SortingPanel'
import React, { useEffect } from 'react'
import connect from '../../../connect'
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers'
import ShowTooltip from '../../../../TooltipsManagement/components/showTooltip'
import { Button } from '../../../../../components/common/Buttons'
import AddNewFile from './AddNewFile'
import { IconPlus } from '../../../../../components/svg/Icons'
import FolderIcon from '@material-ui/icons/FolderOutlined'
import { selectUserPermissions, isEnabled } from '../../../../../selectors'
import { useSelector } from 'react-redux'

const Header = ({
  scope,
  requestTemplates,
  onChangeOrder,
  requestTemplateId,
}) => {
  const { trans } = useTrans()
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const permissions = useSelector(selectUserPermissions)

  const orderOptions = [
    {
      name: 'Registration date',
      value: 'createdAt',
      asc: 'Oldest first',
      desc: 'Newest first',
    },
  ]
  const { order, count, items } = requestTemplates

  useEffect(() => {}, [])

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        style={{ padding: '30px 30px 0 30px' }}
      >
        <Grid item>
          <Typography
            weight="400"
            variant="h3"
            fontFamily="lexend"
            title
            style={{ alignItems: 'center', display: 'flex' }}
          >
            <FolderIcon style={{ marginRight: '10' }} />
            {trans('file-templates')}
            <ShowTooltip sectionToShow="request-template-files-table" />
          </Typography>
        </Grid>
        {isEnabled(permissions, 'request-templates', '', 'create') && (
          <Grid item>
            <Button
              onClick={onOpenModal}
              isIcon
              variant="contained"
              color="primary"
            >
              <IconPlus />
            </Button>
          </Grid>
        )}
      </Grid>

      <AddNewFile
        requestTemplateId={requestTemplateId}
        open={openModal}
        onClose={onCloseModal}
      />
    </>
  )
}

export default connect(Header)
