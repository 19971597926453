import { Button } from '@material-ui/core'
import { CodeRounded } from '@material-ui/icons'
import React from 'react'
import usePopover from '../../../../../../hooks/usePopover'
import { ContextMenuListItem, Popover } from '../../../../Dialogs/Popover'
import { ListItemTextBase } from '../../../../Containers'
import Typography from '../../../../Typography'

const DropdownVariables = ({ items = [], onChange }) => {
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()

  function handleChange(event) {
    const value = event.target.textContent
    onChange(value)
    onClosePopover()
  }

  return (
    !!items && (
      <>
        <Button
          variant="text"
          size="small"
          onClick={onOpenPopover}
          style={{ paddingTop: 0 }}
        >
          <Typography
            fontFamily="lexend"
            color="primary"
            variant="h6"
            weight="500"
          >
            <CodeRounded style={{ fill: '#444', padding: 0, margin: 0 }} />
          </Typography>
        </Button>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClosePopover}
        >
          {items.map((item, idx) => (
            <ContextMenuListItem
              key={idx}
              button
              onClick={handleChange}
              onClickCB={onClosePopover}
              size="normal"
            >
              <ListItemTextBase>
                <Typography
                  weight="500"
                  variant="h6"
                  noWrap
                  style={{ color: '#444' }}
                >
                  {item}
                </Typography>
              </ListItemTextBase>
            </ContextMenuListItem>
          ))}
        </Popover>
      </>
    )
  )
}

export default DropdownVariables
