export const ROUTE_SIGNUP = { key: 'sign-up', path: '/sign-up', noAuth: true }
export const ROUTE_VERIFY = {
  key: 'verification',
  path: '/verification',
  noAuth: true,
}
export const ROUTE_SIGNIN = { key: 'sign-in', path: '/sign-in', noAuth: true }
export const ROUTE_CHANGEPASSWORD = {
  key: 'change-password',
  path: '/change-password',
  noAuth: true,
}
export const ROUTE_EXPIRATION = {
  key: 'expiration',
  path: '/expiration',
  noAuth: true,
}
export const ROUTE_PASSWORD_SUCCESS = {
  key: 'password-success',
  path: '/password-changed',
  noAuth: true,
}

export const ROUTE_PROFILE = { key: 'profile', path: '/profile' }
export const ROUTE_ORG_SETTINGS = { key: 'org-settings', path: '/org-settings' }
export const ROUTE_MIGRATION = { key: 'migration', path: '/extra-info' }
export const ROUTE_BILLING = { key: 'billing', path: '/billing' }

const ROUTES_ADMIN = [
  {
    key: 'dashboard',
    path: '/dashboard',
    component: 'Dashboard',
    showMenu: true,
    roles: ['admin'],
    sections: [
      'organization-metrics',
      'recently-registered-clients',
      'account-breakdown',
      'clients-location',
    ],
  },
  {
    key: 'organization',
    path: '/organizations',
    component: 'Clients',
    showMenu: true,
    icon: 'IconBusiness',
    roles: ['admin', 'manager', 'consulting'],
    sections: [
      'organizations-table',
      'usfcr-staff',
      'organization-members',
      'files',
      'assigned-forms',
      'adjusment-forms',
      'manage-features',
      'billing-settings',
      'claimed-vendor',
      'activity-history',
    ],
  },
  {
    key: 'registered-users',
    path: '/users',
    component: 'Users',
    showMenu: true,
    icon: 'IconUser',
    roles: ['admin', 'manager', 'consulting'],
    sections: ['registered-users-table', 'registered-users-new'],
  },
  {
    key: 'usfcr-staff',
    path: '/usfcr-staff',
    component: 'UsfcrStaff',
    showMenu: true,
    icon: 'IconUSFCRStaff',
    roles: ['admin', 'manager', 'consulting'],
    sections: ['usfcr-staff-table'],
  },
  {
    key: 'tooltips-management',
    path: '/tooltips-management',
    component: 'TooltipsManagement',
    showMenu: true,
    icon: 'IconUser',
    roles: ['admin'],
    sections: ['tooltips-added'],
  },
  {
    key: 'releases',
    path: '/releases',
    component: 'Releases',
    showMenu: true,
    icon: 'IconReleases',
    roles: ['admin'],
    sections: ['releases-table', 'create-release'],
  },
  {
    key: 'teaming-opportunities',
    path: '/teaming-opportunities',
    component: 'TeamingOpportunities',
    showMenu: true,
    icon: 'IconTeamingOpportunities',
    roles: ['admin'],
    sections: ['teaming-opportunities-table'],
  },
  {
    key: 'org-logo-review',
    path: '/org-logo-review',
    component: 'OrgLogoReview',
    showMenu: true,
    icon: 'IconOrgLogoReview',
    roles: ['admin'],
    sections: ['request-templates-table'],
  },
  {
    key: 'admin-portal-roles',
    path: '/admin-portal-roles',
    component: 'AdminPortalRoles',
    showMenu: true,
    icon: 'IconAdminRoles',
    roles: ['admin'],
    sections: ['admin-portal-roles-table', 'create-admin-portal-roles'],
  },

  {
    key: 'global-settings',
    path: '/global-settings',
    component: 'GlobalSettings',
    showMenu: true,
    icon: 'IconSettings',
    roles: ['admin'],
    sections: [
      'formstack-management',
      'api-key-configuration',
      'default-free-account-features',
      'default-premium-account-features',
      'default-SAP-requests',
      'default-SAM-requests',
    ],
  },
  {
    key: 'subscription-settings',
    path: '/subscription-settings',
    component: 'SubscriptionSettings',
    showMenu: true,
    icon: 'IconVerify',
    roles: ['admin'],
    sections: ['subscription-settings-table'],
  },
  {
    key: 'course-levels',
    path: '/course-levels',
    component: 'CourseLevels',
    showMenu: true,
    icon: 'IconCourses',
    roles: ['admin'],
    sections: ['course-levels-table'],
  },
  {
    key: 'requested-quotes',
    path: '/requested-quotes',
    component: 'RequestedQuotes',
    showMenu: true,
    icon: 'IconSettings',
    roles: ['admin', 'consulting'],
    sections: ['requested-quotes-table'],
  },
  {
    key: 'request-templates',
    path: '/request-templates',
    component: 'RequestTemplates',
    showMenu: true,
    icon: 'IconSettings',
    roles: ['admin'],
    sections: ['request-templates-table'],
  },
  {
    key: 'email-templates',
    path: '/email-templates',
    component: 'EmailTemplates',
    showMenu: true,
    icon: 'IconEmail',
    roles: ['admin'],
    sections: ['request-templates-table'], // TODO: change to email-templates-table
  },
]

const ADMIN_GROUP = {
  key: 'admin',
  icon: 'IconBusiness',
  items: ROUTES_ADMIN,
}

export const APP_MENU = [ADMIN_GROUP]
