import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import { Grid, Box, Popover } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import Typography from '../../../../components/common/Typography'
//import ToggleButtonStatus from './toggleButtonStatus'
import { TextField /*DatePicker*/ } from '../../../../components/common/Inputs'
import Button from '../../../../components/common/Buttons/Button'

import { useForm } from '../../../../hooks/useForm'
import makeValidationSchema from '../../../../hooks/makeValidationSchema'
import { schemaToInitialState } from '../../../../utils/validationUtils'
import * as Yup from 'yup'
import connect from '../connect'
import { isExpired } from '../../../../utils/dateUtils'
import { useAlertContext } from '../../../../contexts/AlertContext'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
//import FieldValue from '../../../../components/generics/Helpers/FieldValue'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const useScheme = makeValidationSchema(trans =>
  Yup.object().shape({
    salesforceId: Yup.string()
      .min(15, 'Must be min 15 digits')
      .max(18, 'Must be max 15 digits'),
  })
)

const BillingSettings = ({ detail, saveBillingSettings }) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const [anchor, setAnchor] = React.useState(null)
  const permissions = useSelector(selectUserPermissions)

  const onSubmit = event => {
    saveSettings(event)
  }

  const schema = useScheme()
  const formik = useForm(
    schema,
    schemaToInitialState(schema, {
      salesforceId: detail?.salesforce?.id
        ? detail?.salesforce?.id.slice(0, -3)
        : detail?.salesforce?.associatedSalesforceId ?? '',
    }),
    onSubmit,
    {
      validateOnChange: false,
      validateOnBlur: false,
      validateOnChange: true,
    }
  )

  const saveSettings = event => {
    const values = {
      ...formik.values,
    }

    if (
      values.salesforceId !== '' &&
      values.salesforceId.substring(0, 3) !== '001'
    ) {
      errorAlert('Salesforce ID must start with 001')
      return
    }

    setIsSend(true)
    saveBillingSettings({ ...values, orgId: detail.id })
      .then(res => {
        if (res.payload.salesForceValidations) {
          setIsSend(false)
          successAlert('Billing settings updated!')
        } else {
          setIsSend(false)
          setAnchor(true)
        }
      })
      .catch(() => {
        setIsSend(false)
        errorAlert('Billing settings not updated!')
      })
  }

  const executeSubmit = () => {
    formik.handleSubmit()
  }
  const openPopover = event => {
    setAnchor(event.currentTarget)
  }
  const [billingData, setBillingData] = useState({
    salesforceId: '',
    accountType: '',
    expirationDate: '',
  })

  // const changeValue = ev => {
  //   setBillingData({
  //     ...billingData,
  //     [ev.target.name]: ev.target.value,
  //   })
  // }

  const changeDatePicker = (value, name) => {
    setBillingData({
      ...billingData,
      [name]: value,
    })
  }

  useEffect(() => {
    if (detail && detail.salesforce) {
      setBillingData({
        salesforceId: detail.salesforce.id.slice(0, -3),
        expirationDate: detail.salesforce.afpds_expiration__c,
        accountType: trans(detail?.tier),
      })
    }
  }, [detail, trans])

  return (
    <SpacingContainerBase py={0}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              style={{
                paddingBottom: '1rem',
                alignItems: 'center',
                display: 'flex',
              }}
              variant="h4"
              weight="500"
              fontFamily="lexend"
            >
              <AttachMoneyIcon /> {trans('billing-settings')}{' '}
              <ShowTooltip sectionToShow="billing-settings" />
            </Typography>
          </Grid>
        </Grid>

        <Box
          style={{
            borderTop: `1px solid rgba(78, 89, 120, 0.12)`,
            padding: '2rem 0 2rem 0',
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            spacing={3}
          >
            <Grid item sm={3} xs={12}>
              <TextField
                label={trans('salesforce-id')}
                placeholder={trans('optional')}
                {...formik.getFieldProps('salesforceId')}
                {...openPopover}
                //name="salesforceId"
                //value={billingData.salesforceId}
                //onChange={changeValue}
              />
            </Grid>
            <Popover
              motion
              style={{ marginTop: '-5%', marginLeft: '5%' }}
              open={Boolean(anchor)}
              onClose={() => setAnchor(null)}
              anchorEl={anchor}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Grid
                container
                spacing={1}
                wrap={'nowrap'}
                style={{
                  backgroundColor: '#f9f9f9',
                  width: '365px',
                  height: 'auto',
                }}
              >
                <Grid item>
                  <ErrorOutlineIcon
                    style={{ color: '#F32445', margin: '5px 5px 0px 5px' }}
                  />
                </Grid>
                <Grid item>
                  <Typography weight={500} variant={'body2'} color="label">
                    {trans('alertSalesforceId')}
                  </Typography>
                </Grid>
              </Grid>
            </Popover>
            <Grid item sm={2} xs={12}>
              <Typography
                weight={700}
                variant="body2"
                style={{ marginBottom: '0.3rem', color: '#4e5978' }}
              >
                {'Account Type'}
              </Typography>
              <Typography weight={700} variant="body2">
                {billingData.accountType}
              </Typography>

              {/*<ToggleButtonStatus
                accountType={isExpired(billingData.accountType)}
              />*/}
            </Grid>
            <Grid item sm={3} xs={12}>
              <Typography
                weight={700}
                variant="body2"
                style={{ marginBottom: '0.3rem', color: '#4e5978' }}
              >
                {trans('due-date')}
              </Typography>
              <Typography weight={700} variant="body2">
                {billingData.expirationDate}
              </Typography>
              {/*<DatePicker
                format="MM-DD-YYYY"
                disabled={true}
                label={trans('Due Date')}
                placeholder={trans('optional')}
                maxLength={5}
                align="center"
                name="expirationDate"
                value={billingData.expirationDate}
                onChangeValue={changeDatePicker}
                //{...formik.getFieldProps('expirationDate')}
              />*/}
            </Grid>
          </Grid>
        </Box>
        {isEnabled(permissions, 'organization', 'billing-settings', 'edit') && (
          <Box
            style={{
              borderTop: `1px solid rgba(78, 89, 120, 0.12)`,
              padding: '2rem 0 0.5rem 0',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs zeroMinWidth>
                <Button
                  variant="contained"
                  style={{ background: '#17b471', color: '#fff' }}
                  onClick={executeSubmit}
                  pending={isSend}
                >
                  {' '}
                  Save Settings
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </SpacingContainerBase>
  )
}
export default connect(BillingSettings)
