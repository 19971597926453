import React, { useContext } from 'react';

export const initialValue = {};

const SearchContext = React.createContext(initialValue);

export function useSearchContext() {
  return useContext(SearchContext);
}

export class SearchContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      sharedSearch: null,
      filterOptions: [],
      scope: '',
      current: undefined,
    };
  }

  static getDerivedStateFromProps(props, prevState) {
    return { ...prevState, ...props.state };
  }

  get options() {
    return {
      state: this.state,
      setCurrent: this.setCurrent,
      onChangeFilters: this.onChangeFilters,
      onChangeFilter: this.onChangeFilter,
      onDeleteFilter: this.onDeleteFilter,
      onTextChange: this.onChangeFilter.bind(this, 'text'),
      onResetFilters: this.onChangeFilters.bind(this, { reset: true }),

      onSaveSearch: this.onSaveSearch,
      onExportSearch: this.onExportSearch,
      onCreateShareSearch: this.onCreateShareSearch,
      onLoadSearch: this.onLoadSearch,

      ...this.props.handlers,
    };
  }

  setCurrent = (current) => {
    this.setState({ current });
  };

  onChangeFilters = (newFilter) => {
    const { handlers } = this.props;
    handlers && handlers.onChangeFilters && handlers.onChangeFilters(newFilter);
  };

  onChangeFilter = (field, value) => {
    this.onChangeFilters({ [field]: value });
  };

  onDeleteFilter = (field) => {
    this.onChangeFilter(field, undefined);
  };

  onSaveSearch = (data) => {
    const { handlers } = this.props;
    return handlers && handlers.onSaveSearch && handlers.onSaveSearch(data);
  };

  onCreateShareSearch = async () => {
    const { handlers } = this.props;
    return handlers && handlers.onCreateShareSearch && handlers.onCreateShareSearch();
  };

  onExportSearch = () => {
    const { handlers } = this.props;
    return handlers && handlers.onExportSearch && handlers.onExportSearch();
  };

  onLoadSearch = ({ filters, order }) => {
    const { handlers } = this.props;
    return handlers && handlers.onLoadSearch && handlers.onLoadSearch({ filters, order });
  };

  render() {
    return <SearchContext.Provider value={this.options}>{this.props.children}</SearchContext.Provider>;
  }
}
