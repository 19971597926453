import { useState } from 'react'
import Typography from '../../../../components/common/Typography'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { InlineContainer } from '../../../../components/common/Containers'
import { RouterLink } from '../../../../components/common/Buttons'
import { Avatar } from '../../../../components/common/Cards'
import { IconButton } from '@material-ui/core'
import { formatPrettyUnixDate } from '../../../../utils/dateUtils'
import CloseIcon from '@material-ui/icons/Close'
import { DATE_TIME_PRETTY_FORMAT } from '../../../../constants'
import ConfirmDialog from '../../../../components/generics/Confirm/Dialog'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import { useTrans } from '../../../../services/i18n'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const Item = ({ detail, user, deleteUser, getDetail }) => {
  const [openRemove, onOpenRemove, onCloseRemove] = useBooleanHandlers()
  const [pendingRemove, setPendingRemove] = useState(false)
  const { trans } = useTrans()
  const permissions = useSelector(selectUserPermissions)
  const onDeleteUser = () => {
    setPendingRemove(true)
    deleteUser(user).then(() => {
      setPendingRemove(false)
      onCloseRemove()
      getDetail({ organizationId: detail.id })
    })
  }
  return (
    <>
      <TableRow>
        <TableCell align="left">
          <InlineContainer spacing={1}>
            <Avatar
              bgColor="gray-second"
              variantAvatar="rounded"
              size={40}
              value={`${user.name} ${user.familyName}`}
            />
            <Typography
              weight="500"
              to={`/usfcr-staff/${user.userId}`}
              variant="h6"
              component={RouterLink}
            >
              {user.name} {user.familyName}
            </Typography>
          </InlineContainer>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {user.email}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {user.lastLogin
              ? formatPrettyUnixDate(user.lastLogin, DATE_TIME_PRETTY_FORMAT)
              : ''}
          </Typography>
        </TableCell>
        {isEnabled(permissions, 'organization', 'usfcr-staff', 'delete') && (
          <TableCell align="right">
            <IconButton onClick={onOpenRemove}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <ConfirmDialog
        open={openRemove}
        onClose={onCloseRemove}
        onConfirm={onDeleteUser}
        pending={pendingRemove}
        title={trans('remove-user-usfcr-staff')}
        text={'Are you sure you want to remove this user from USFCR Staff?'}
      >
        <Typography color="hint">
          {user.name} {user.familyName}
        </Typography>
      </ConfirmDialog>
    </>
  )
}

export default Item
