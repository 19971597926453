import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import React from 'react'
import Typography from '../../Typography'
import FormHelperText from '@material-ui/core/FormHelperText'

const styles = {
  width: 24,
  height: 24,
  position: 'absolute',
  right: 20,
  padding: 0,
}
const LoadingIcon = () => (
  <CircularProgress disableShrink color="inherit" style={styles} />
)

export default function SelectField(props) {
  const {
    children,
    error,
    placeholder,
    label = null,
    loading,
    fullWidth = true,
    required,
    className,
    getRenderValue,
    id = '',
    name = '',
    ...rest
  } = props

  return (
    <FormControl variant="filled" fullWidth={fullWidth} error={Boolean(error)}>
      <InputLabel required={required}>{label}</InputLabel>
      <Select
        id={id || `select-${name}`}
        name={name}
        disableUnderline
        className={className}
        error={Boolean(error)}
        IconComponent={loading ? LoadingIcon : undefined}
        renderValue={selected => {
          if (placeholder && selected?.length === 0) {
            return (
              <Typography
                style={{ marginTop: 4 }}
                variant="body1"
                color="hint"
                disableLineHeight
              >
                {placeholder}
              </Typography>
            )
          }
          return typeof getRenderValue === 'function'
            ? getRenderValue(selected)
            : selected
        }}
        displayEmpty
        {...rest}
      >
        {children}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  )
}
