import { useCallback } from 'react';
import { useQuery } from './useQuery';
import { useNavigate } from 'react-router-dom';

export default function useQueryHistory() {
  const history = useNavigate();
  const { query, searchParams } = useQuery();

  const searchParamsToString = useCallback(
    (cb) => {
      if (typeof cb === 'function') cb(searchParams);
      return searchParams.toString();
    },
    [searchParams]
  );

  const pushToHistory = useCallback(
    (cb, replace = false) => {
      const params = { search: searchParamsToString(cb) };
      return history(params);
    },
    [searchParamsToString, history]
  );

  const clearQueryString = useCallback(
    (replace = true) => {
      history({ search: undefined });
    },
    [history]
  );

  const setToHistory = useCallback(
    (params, replace = false) => {
      const cb = (sp) =>
        Object.entries(params).forEach(([key, value]) => (!!value ? sp.set(key, value) : sp.delete(key)));

      pushToHistory(cb, replace);
    },
    [pushToHistory]
  );

  const rmFromHistory = useCallback(
    (keys, replace = true) => {
      const keysToDelete = Array.from(Array.isArray(keys) ? keys : [keys]);

      const cb = (sp) => keysToDelete.forEach((key) => sp.delete(key));
      pushToHistory(cb, replace);
    },
    [pushToHistory]
  );

  return {
    history,
    query,
    clearQueryString,
    setToHistory,
    rmFromHistory,
  };
}
