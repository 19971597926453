import { Grid } from '@material-ui/core'
import { SpacingContainer } from '../../../components/common/Containers/Base'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import React from 'react'
import connect from '../connect'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'

const Header = () => {
  const { trans } = useTrans()

  return (
    <>
      <SpacingContainer py={3} px={0}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              weight="600"
              variant="h2"
              fontFamily="lexend"
              title
              style={{ alignItems: 'center', display: 'flex' }}
            >
              {trans('email-templates')}{' '}
              <ShowTooltip sectionToShow="request-templates-table" />
            </Typography>
          </Grid>
        </Grid>
      </SpacingContainer>
    </>
  )
}

export default connect(Header)
