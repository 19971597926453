import { connect } from 'react-redux'
import { actions } from '../../../../store/requestTemplateFiles'
import { generateListCallbacks } from '../../../../store/helpers/listActionsHelpers'

function mapStateToProps(state) {
  return {
    requestTemplateFiles: state.requestTemplateFiles,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    fetchList: () => dispatch(actions.fetchList()),
    findOne: (params) => dispatch(actions.findOne(params)),
    saveRequestTemplateFile: (params) =>
      dispatch(actions.saveRequestTemplateFile(params)),
    deleteRequestTemplateFile: (params) =>
      dispatch(actions.deleteRequestTemplateFile(params)),
    getList: (params) => dispatch(actions.getList(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
