import React, { useEffect, useState } from 'react'
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase';
import Loader from '../../../components/common/Loader'
import Header from './components/Header'
import ReleaseDetails from './ReleaseDetails';
import connect from '../connect'
import { useParams } from 'react-router-dom'

const ReleaseDetail = ({ releases, findOne, deleteRelease }) => {
  const params = useParams()

  const { releaseDetail } = releases
  const [pending, setPending] = useState(false)

  useEffect(() => {
    setPending(true)
    findOne({ id: params.id }).then(() => setPending(false))
  }, [])

  return (
    <>
      <SpacingContainerBase>
        {pending && <Loader open={pending} />}
        {!pending && releaseDetail && (
        <>
          <Header item={releaseDetail}/>
          <ReleaseDetails deleteRelease={deleteRelease} item={releaseDetail}/>
        </>
        )}
      </SpacingContainerBase>
    </>
  )
}

export default connect(ReleaseDetail)
