import { useEffect } from 'react'
import { Paper } from '../../../../../../components/common/Containers'
import { Box, Grid } from '@material-ui/core'
import Typography from '../../../../../../components/common/Typography'
import connect from '../../../../connect'
import LinearProgressBar from '../../../../../../components/generics/LinearProgressBar'

export const SAVED_SEARCH_COLOR_MAP = {
  normal: '#11CA7B',
  over: '#ffad2c',
}

export function getColorAndPercentage(current, limit) {
  const status = current > 0 && current >= limit ? 'over' : 'normal'
  const color = SAVED_SEARCH_COLOR_MAP[status]

  const currentValue = current > limit ? limit : current
  const percentageValue = (currentValue / limit) * 100

  return { color, percentageValue }
}

function SavedSearchesCounter({
  savedSearches,
  savedSearchesActions,
  ...props
}) {
  const {
    userDetail: { userName },
    savedSearchStats: {
      savedSearchLimit = 5,
      savedSearchCount = 4,
      savedSearchLimitTier,
    },
  } = savedSearches
  const { onGetSavedSearchStats } = savedSearchesActions

  const { color, percentageValue } = getColorAndPercentage(
    savedSearchCount,
    savedSearchLimit
  )

  useEffect(() => {
    onGetSavedSearchStats && onGetSavedSearchStats({ userName })
  }, [onGetSavedSearchStats, userName])

  return (
    <Paper
      className="relative"
      elevation={0}
      style={{
        marginBottom: '1rem',
      }}
    >
      <Box p={3} display="flex" gridGap={'1.5rem'} {...props}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={8}
            gap={3}
            style={{
              display: 'block',
            }}
          >
            <Typography variant="body2">
              {savedSearchLimitTier === null ? (
                <>
                  Saved Searches are <b>unlimited</b>
                </>
              ) : (
                <>
                  Saved Searches limit is <b>{savedSearchLimit}</b> items total
                </>
              )}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Typography variant="body2">
              {'Used: '}
              <b>
                <span style={{ color }}>{savedSearchCount}</span>
              </b>{' '}
              of{' '}
              <b>
                {savedSearchLimitTier === null ? 'unlimited' : savedSearchLimit}
              </b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LinearProgressBar value={percentageValue} color={color} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default connect(SavedSearchesCounter)
