import React from 'react'
import connect from '../connect'
import { IconButton } from '@material-ui/core'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  ContextMenuListItem,
  Popover,
} from '../../../components/common/Dialogs/Popover'
import useBooleanHandlers from '../../../hooks/useBooleanHandlers'
import usePopover from '../../../hooks/usePopover'
import ListItemTextBase from '../../../components/common/Containers/Base/ListItemTextBase'
import FormReplyQuoteRequest from '../../../components/forms/ReplyQuoteRequest'
import AssignUserStaffToQuoteRequest from '../../../components/forms/AssignUserStaffToQuoteRequest'
import { useTrans } from '../../../services/i18n'
import ResponseModal from './ResponseModal'
import { selectUserPermissions, isEnabled } from '../../../selectors'
import { useSelector } from 'react-redux'

const QuoteMenu = ({ quote, users, saveResponse, fetchList, assignUser }) => {
  const { trans } = useTrans()
  const permissions = useSelector(selectUserPermissions)

  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()
  const [open, openModal, onCloseModal] = useBooleanHandlers()
  const [openAssignModal, onOpenAssignModal, onCloseAssignModal] =
    useBooleanHandlers()
  const [openResponseModal, onOpenResponseModal, onCloseResponseModal] =
    useBooleanHandlers()

  const handleAction = values => {
    const { amount, expires, id, adminResponse, isDenied } = values
    return saveResponse({ amount, expires, id, adminResponse, isDenied })
  }

  const handleAssignAction = values => {
    return assignUser(values)
  }

  return (
    <>
      {true && (
        <IconButton onClick={onOpenPopover}>
          <MoreHorizIcon />
        </IconButton>
      )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClosePopover}
      >
        {!quote.tags.includes('Lead') &&
          quote.status.toLowerCase() === 'pending' &&
          isEnabled(permissions, 'requested-quotes', '', 'edit') && (
            <ContextMenuListItem
              button
              onClick={openModal}
              onClickCB={onClosePopover}
            >
              <ListItemTextBase>{trans('reply')}</ListItemTextBase>
            </ContextMenuListItem>
          )}
        {quote.status.toLowerCase() !== 'pending' && (
          <ContextMenuListItem
            button
            onClick={onOpenResponseModal}
            onClickCB={onClosePopover}
          >
            <ListItemTextBase>{trans('see-response')}</ListItemTextBase>
          </ContextMenuListItem>
        )}
        {!quote.tags.includes('Lead') &&
          !quote.assignedUserId &&
          isEnabled(permissions, 'requested-quotes', '', 'edit') && (
            <ContextMenuListItem
              button
              onClick={onOpenAssignModal}
              onClickCB={onClosePopover}
            >
              <ListItemTextBase>{trans('assign-to')}</ListItemTextBase>
            </ContextMenuListItem>
          )}
        <ContextMenuListItem
          button
          onClick={() => null}
          onClickCB={onClosePopover}
        >
          <a
            href={quote.opportunityLink}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <ListItemTextBase>Check opportunity</ListItemTextBase>
          </a>
        </ContextMenuListItem>
      </Popover>

      <FormReplyQuoteRequest
        open={open}
        onClose={onCloseModal}
        handleAction={handleAction}
        quote={quote}
        fetchList={fetchList}
      />

      <AssignUserStaffToQuoteRequest
        open={openAssignModal}
        onClose={onCloseAssignModal}
        handleAction={handleAssignAction}
        quote={quote}
        fetchList={fetchList}
        users={users}
      />

      <ResponseModal
        open={openResponseModal}
        onClose={onCloseResponseModal}
        quote={quote}
      />
    </>
  )
}

export default connect(QuoteMenu)
