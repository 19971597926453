import { createAsyncThunk } from '@reduxjs/toolkit';
import { application } from '../../../services/application';
import { onPending, onPendingDone } from '../sharedCases';

export function generateNotInterestedActions({ scope, apiMethod, getStore }) {
  if (!(scope && apiMethod && typeof getStore === 'function')) return {};

  return {
    addToNotInterested: createAsyncThunk(`${scope}/addToNotInterested`, async (params) => {
      return apiMethod.ADD_NOT_INTERESTED ? application.call(apiMethod.ADD_NOT_INTERESTED, { ...params }) : false;
    }),
  };
}

export function extendBuilderWithNotInterestedActions(builder, actions) {
  const { addToNotInterested } = actions;
  if (addToNotInterested) {
    builder
      .addCase(addToNotInterested.pending, onPending)
      .addCase(addToNotInterested.rejected, onPendingDone)
      .addCase(addToNotInterested.fulfilled, onPendingDone);
  }
}

export function generateNotInterestedCallbacks(dispatch, actions) {
  const { fetchList, addToNotInterested } = actions || {};
  if (!(fetchList && addToNotInterested && dispatch)) return {};

  return {
    onAddToNotInterested: async (params) => {
      const { id, title } = params;
      await dispatch(addToNotInterested({ id, title }));
      await dispatch(fetchList());
    },
  };
}
