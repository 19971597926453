import { connect } from 'react-redux'
import { actions } from '../../store/requestedQuotes'
import { generateListCallbacks } from '../../store/helpers/listActionsHelpers'

function mapStateToProps(state) {
  return {
    requestedQuotes: state.requestedQuotes,
    requestedQuoteDetail: state.requestedQuotes.requestedQuoteDetail,
    users: state.requestedQuotes.users,
    filters: state.requestedQuotes.filters,
    authUser: state.auth,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    fetchList: () => dispatch(actions.fetchList()),
    findOne: params => dispatch(actions.findOne(params)),
    saveResponse: params => dispatch(actions.saveResponse(params)),
    assignUser: params => dispatch(actions.assignUser(params)),
    getStaffUsers: () => dispatch(actions.getStaffUsers()),
    updateProgressStatus: (params) => dispatch(actions.updateProgressStatus(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
