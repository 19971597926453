import React from 'react';
import { Chip } from '../../../../components/common/Chip';

const colorMap = {
  improvements: '#9576ff',
  newfeatures: '#a5cb55',
  fixes: '#ffac00',
};

const Tag = React.memo(({ tagName }) => {
  const tagKey = () => {
    const key = tagName.toLowerCase()
    return key.replace(' ', '')
  }
  return (
    <Chip
      label={tagName}
      color={'secondary'}
      variant="outlined"
      style={{
        border: `1px solid ${colorMap[tagKey()] ?? colorMap.unknown}`,
        backgroundColor: "white",
        color: colorMap[tagKey()] ?? colorMap.unknown
      }}
    />
  );
});

export default Tag;
