import React from 'react'
import { SpacingContainer } from '../../components/common/Containers/Base'
import Typography from '../../components/common/Typography'
import { useTrans } from '../../services/i18n'

const Profile = () => {
  const { trans } = useTrans()
  return (
    <SpacingContainer py={7} px={7}>
      <Typography variant="h2" weight="bold">
        {trans('my-profile')}
      </Typography>
    </SpacingContainer>
  )
}

export default Profile
