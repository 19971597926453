import * as Yup from 'yup'

export const IS_BEFORE = 'IS_BEFORE'
export const IS_AFTER = 'IS_AFTER'
export const IS_BETWEEN = 'IS_BETWEEN'
export const WITHIN_THE_LAST = 'WITHIN_THE_LAST'
export const WITHIN_THE_NEXT = 'WITHIN_THE_NEXT'

export const DATE_FILTER_OPTIONS = [
  IS_BEFORE,
  IS_AFTER,
  IS_BETWEEN,
  WITHIN_THE_LAST,
  WITHIN_THE_NEXT,
]

export const WEEK = 'WEEK'
export const MONTH = 'MONTH'
export const YEAR = 'YEAR'

export const DATE_UNIT_LIST = [WEEK, MONTH, YEAR]

export const ANY_OF_THOSE = 'ANY_OF_THOSE'
export const NONE_OF_THOSE = 'NONE_OF_THOSE'
export const EXISTS = 'EXISTS'
export const DOES_NOT_EXISTS = 'DOES_NOT_EXISTS'
export const ONLY_TEXT = 'ONLY_TEXT'
export const STARTS_WITH = 'STARTS_WITH'
export const ENDS_WITH = 'ENDS_WITH'
export const CONTAINS = 'CONTAINS'
export const PARTIAL_FILTER_OPTIONS = [STARTS_WITH, ENDS_WITH, CONTAINS];

export const IS_GREATER = 'IS_GREATER'
export const IS_LESS = 'IS_LESS'

export const REQUIRED_STRING_FILTER_OPTIONS = [ANY_OF_THOSE, NONE_OF_THOSE]
export const REQUIRED_STRING_FILTER_OPTIONS_ANY_OF_THOSE = [ANY_OF_THOSE]
export const STRING_FILTER_OPTIONS = [
  ANY_OF_THOSE,
  NONE_OF_THOSE,
  EXISTS,
  DOES_NOT_EXISTS,
  ONLY_TEXT,
]

export const NUMBER_FILTER_OPTIONS = [IS_GREATER, IS_LESS, IS_BETWEEN]

export const DEFAULT_DATE_STATE = { type: IS_BEFORE, dateUnit: MONTH }
export const DEFAULT_STRING_STATE_SINGLE = { data: '', type: ONLY_TEXT }
export const DEFAULT_STRING_STATE = { data: [], type: ANY_OF_THOSE }
export const DEFAULT_NUMBER_STATE = {
  data: '',
  type: IS_GREATER,
  from: '',
  to: '',
}

export const EVENT_LOG_TYPES = [
  { name: 'All types', value: 'all' },
  { name: 'Features', value: 'features' },
  { name: 'Organization', value: 'organization' },
  { name: 'Subscription', value: 'subscription' },
  { name: 'File Request', value: 'file request' },
  { name: 'Form', value: 'form' },
  { name: 'Pipeline', value: 'pipeline' },
  { name: 'Pursuit', value: 'pursuit' },
  { name: 'Support Ticket', value: 'support ticket' },
  { name: 'Billing', value: 'billing' },
]

export const DATE_SCHEMA = trans =>
  Yup.object().shape({
    type: Yup.string().required(trans('required-field')),
    from: Yup.date().when('type', {
      is: val => val === IS_BETWEEN || val === IS_AFTER,
      then: Yup.date().required(trans('required-field')),
      otherwise: Yup.date().optional(),
    }),
    to: Yup.date().when('type', {
      is: val => val === IS_BETWEEN || val === IS_BEFORE,
      then: Yup.date().required(trans('required-field')),
      otherwise: Yup.date().optional(),
    }),
    unitCount: Yup.number().when('type', {
      is: val => val === WITHIN_THE_LAST || val === WITHIN_THE_NEXT,
      then: Yup.number()
        .typeError(trans('must-be-a-number'))
        .required(trans('required-field')),
      otherwise: Yup.number().optional(),
    }),
    dateUnit: Yup.string().when('type', {
      is: val => val === WITHIN_THE_LAST || val === WITHIN_THE_NEXT,
      then: Yup.string().required(trans('required-field')),
      otherwise: Yup.string().optional(),
    }),
  })

export const STRING_SCHEMA = trans =>
  Yup.object().shape({
    type: Yup.string().required(trans('required-field')),
    data: Yup.mixed().when('type', {
      is: val => val === EXISTS || val === DOES_NOT_EXISTS,
      then: Yup.array().min(0).optional(),
      otherwise: Yup.array().min(1).required(trans('required-field')),
    }),
  })

export const SINGLE_VALUE_SCHEMA = trans =>
  Yup.object().shape({
    type: Yup.string().required(trans('required-field')),
    data: Yup.mixed().required(trans('required-field')),
  })

export const NUMBER_SCHEMA = trans =>
  Yup.object().shape({
    type: Yup.string().required(trans('required-field')),
    data: Yup.mixed().when('type', {
      is: val => val === IS_GREATER || val === IS_LESS,
      then: Yup.number()
        .min(0, trans('should-be-greater-or-equal', { n: 0 }))
        .required(trans('required-field')),
      otherwise: Yup.number().optional(),
    }),
    from: Yup.number().when('type', {
      is: val => val === IS_BETWEEN,
      then: Yup.number()
        // .min(0, trans('should-be-greater-or-equal', { n: 0 }))
        .required(trans('required-field')),
      otherwise: Yup.number().optional(),
    }),
    to: Yup.number().when('type', {
      is: val => val === IS_BETWEEN,
      then: Yup.number()
        // .min(0, trans('should-be-greater-or-equal', { n: 0 }))
        .required(trans('required-field')),
      otherwise: Yup.number().optional(),
    }),
  })

export const LOCATION_SCHEMA = trans => {
  const conditionalArrayValidationObj = {
    is: val => val,
    then: Yup.array()
      .min(1, trans('data-field-one-item'))
      .required(trans('required-field')),
    otherwise: Yup.array().min(0).optional(),
  }

  const conditionalStringValidationObj = {
    is: val => val,
    then: Yup.string().required(trans('required-field')),
    otherwise: Yup.string().optional(),
  }

  const shape = Object.assign(
    {},
    ...['state', 'zip'].map(filter => ({
      [`${filter}_show`]: Yup.boolean(),
      [`${filter}_type`]: Yup.string().when(
        `${filter}_show`,
        conditionalStringValidationObj
      ),
      [`${filter}_data`]: Yup.mixed().when(
        `${filter}_show`,
        filter === 'zip'
          ? conditionalStringValidationObj
          : conditionalArrayValidationObj
      ),
    }))
  )

  return Yup.object().shape(shape)
}

export const NAICS_PSC_VENDOR_SCHEMA = trans => {
  const conditionalStringValidationOptions = {
    is: val => [STARTS_WITH, ENDS_WITH, CONTAINS].includes(val),
    then: Yup.string().required(trans('required-field')),
  }
  const conditionalStringValidationObj = {
    is: val => val,
    then: Yup.string().required(trans('required-field')),
    otherwise: Yup.string().optional(),
  }

  const shape = Object.assign(
    {},
    ...['naicsCategory', 'pscCategory'].map(filter => ({
      [`${filter}_show`]: Yup.boolean(),
      [`${filter}_type`]: Yup.string().when(
        `${filter}_show`,
        conditionalStringValidationObj
      ),
      [`${filter}_data`]: Yup.mixed().when(`${filter}_show`, {
        is: val => val,
        then: Yup.array().when(`${filter}_type`, {
          is: val => val && [STARTS_WITH, ENDS_WITH, CONTAINS].includes(val),
          then: Yup.array().optional(),
          otherwise: Yup.array()
            .min(1, trans('data-field-one-item'))
            .required(trans('required-field')),
        }),
      }),
      [`${filter}_text`]: Yup.string().when(
        `${filter}_show`,
        conditionalStringValidationOptions
      ),
    }))
  )

  return Yup.object().shape(shape)
}

export const SELECTION_OR_STRING_SCHEMA = trans =>
  Yup.object().shape({
    type: Yup.string().required(trans('required-field')),
    data: Yup.mixed().when('type', {
      is: val => val === ANY_OF_THOSE || val === NONE_OF_THOSE,
      then: Yup.array().min(1).required(trans('required-field')),
      otherwise: Yup.array().nullable(),
    }),
    text: Yup.mixed().when('type', {
      is: val => [STARTS_WITH, ENDS_WITH, CONTAINS].includes(val),
      then: Yup.string().required(trans('required-field')),
    }),
  })
