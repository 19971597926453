import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { API } from '../../constants'
import { application } from '../../services/application'
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common'
import {
  extendListBuilder,
  generateListActions,
} from '../helpers/listActionsHelpers'
import { onPending, onReject, onSuccess } from '../helpers/sharedCases'
import { onItemDetails, onItemDetailsReject } from '../helpers/sharedCases'
import { SCOPE_TEAMING_OPPORTUNITIES } from '../../constants/search'

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['createdAt', 'desc'],
  defaultParams: { type: 'open', innerType: 'open' },
}

const listActions = generateListActions({
  scope: SCOPE_TEAMING_OPPORTUNITIES,
  apiMethod: {
    GET_LIST: API.TEAMING_OPPORTUNITIES.GET_LIST,
  },
  getStore: store => store.teamingOpportunities,
})

const findOne = createAsyncThunk('teamingOpportunities/findOne', params => {
  return application.call(API.TEAMING_OPPORTUNITIES.FIND_ONE, params)
})

const updateStatus = createAsyncThunk(
  'teamingOpportunities/updateStatus',
  params => {
    return application.call(API.TEAMING_OPPORTUNITIES.UPDATE_STATUS, params)
  }
)

const updateOpportunity = createAsyncThunk(
  'teamingOpportunities/updateOpportunity',
  params => {
    return application.call(
      API.TEAMING_OPPORTUNITIES.UPDATE,
      params
    )
  }
)

export const actions = {
  ...listActions,
  findOne,
  updateStatus,
  updateOpportunity,
}

export default createReducer(INITIAL_STATE, builder => {
  return extendListBuilder(builder, actions)
    .addCase(findOne.fulfilled, onItemDetails)
    .addCase(findOne.pending, onPending)
    .addCase(findOne.rejected, onItemDetailsReject)
    .addCase(updateStatus.pending, onPending)
    .addCase(updateStatus.rejected, onReject)
    .addCase(updateStatus.fulfilled, onSuccess)
    .addCase(updateOpportunity.pending, onPending)
    .addCase(updateOpportunity.rejected, onReject)
    .addCase(updateOpportunity.fulfilled, onSuccess)
})
