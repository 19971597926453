import React, { useEffect } from 'react'
import { SpacingContainer, Paper } from '../../components/common/Containers'
import Typography from '../../components/common/Typography'
import { useTrans } from '../../services/i18n'
import Box from '@material-ui/core/Box'
import connect from './connect'
import List from './components/List'
import { Button } from '../../components/common/Buttons'
import { useAlertContext } from '../../contexts/AlertContext'
import { IconAdded } from '../../components/svg'
import { useSelector } from 'react-redux'
import { selectUserPermissions, isEnabled } from '../../selectors'
import Tooltip from '@material-ui/core/Tooltip'

const CourseLevels = ({ getItems, saveCourseLevels, items }) => {
  const { trans } = useTrans()
  const [itemLevels, setItemLevels] = React.useState([])
  const { successAlert } = useAlertContext()
  const permissions = useSelector(selectUserPermissions)
  console.log('🚀 ~ CourseLevels ~ permissions:', permissions)
  useEffect(() => {
    setItemLevels(items)
  }, [items])

  const onChange = (id, data) => {
    const newLevels = itemLevels.map(item => {
      if (item.id === id) {
        return { ...item, ...data }
      }
      return item
    })
    setItemLevels(newLevels)
  }

  const onSave = () => {
    saveCourseLevels({ levels: itemLevels }).then(() => {
      successAlert('Item levels saved successfully')
      getItems()
    })
  }

  const addLevel = () => {
    const newLevels = [
      ...itemLevels,
      { id: null, title: '(New Level)', level: 'none', objectType: 'course' },
    ]
    setItemLevels(newLevels)
  }

  useEffect(() => {
    getItems()
  }, [getItems])

  return (
    <SpacingContainer py={7} px={7}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" weight="bold">
          {trans('course-levels')}
        </Typography>
        <div>
          <Tooltip
            title={
              !isEnabled(permissions, 'course-levels', 'page', 'create')
                ? 'Access restricted. Contact support to request permissions.'
                : 'Add Level'
            }
          >
            <span>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !isEnabled(permissions, 'course-levels', 'page', 'create')
                }
                onClick={addLevel}
                style={{ marginRight: '1rem' }}
              >
                <IconAdded />
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={
              !isEnabled(permissions, 'course-levels', 'page', 'edit')
                ? 'Access restricted. Contact support to request permissions.'
                : 'Update Levels'
            }
          >
            <span>
              <Button
                variant="contained"
                color="success"
                onClick={onSave}
                disabled={
                  !isEnabled(permissions, 'course-levels', 'page', 'edit')
                }
              >
                {trans('save')}
              </Button>
            </span>
          </Tooltip>
        </div>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 20 }}>
        <SpacingContainer py={2}>
          <List courses={itemLevels} onChange={onChange} />
        </SpacingContainer>
      </Box>
    </SpacingContainer>
  )
}

export default connect(CourseLevels)
