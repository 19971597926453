import React, { useEffect, useRef, useState } from 'react'
import { useTrans } from '../../../../services/i18n'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import AddOutlined from '@material-ui/icons/AddOutlined'
import { IconForms } from '../../../../components/svg/Icons/Menu'
import AddAssingFormsModal from './addAssingFormsModal'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import Item from './item'
import connect from '../connect'
import { emptyArray } from '../../../../utils/arrayUtils'
import { Button } from '../../../../components/common/Buttons'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { Divider } from '../../../../components/common/Divider'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'
const AssignForms = ({
  detail,
  getForms,
  deleteFormOrg,
  getFormStackJSONSignedUrl,
}) => {
  const { trans } = useTrans()
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const { successAlert, errorAlert } = useAlertContext()
  const permissions = useSelector(selectUserPermissions)

  const deleteForm = id => {
    return deleteFormOrg({ id })
      .then(() => {
        successAlert('Form has been removed from this organization')
      })
      .catch(error => {
        console.log(error)
        errorAlert('Error')
      })
  }

  useEffect(() => {
    let excludedForms = []
    if (detail && !emptyArray(detail.forms)) {
      excludedForms = detail.forms.map(f => f.formId)
    }
    getForms({ excludedForms: excludedForms })
  }, [detail])

  return (
    <Paper component={SpacingContainer} px={5} py={1}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs zeroMinWidth>
          <Typography
            variant="h4"
            weight="500"
            fontFamily="lexend"
            style={{ alignItems: 'center', display: 'flex' }}
          >
            <IconForms />
            <span style={{ marginLeft: '10px' }}>
              {trans('assigned-forms')}
            </span>
            <ShowTooltip sectionToShow="assigned-forms" />
          </Typography>
        </Grid>

        <Grid item>
          {isEnabled(
            permissions,
            'organization',
            'assigned-forms',
            'create'
          ) && (
            <Button
              isIcon
              color="primary"
              variant="outlined"
              onClick={onOpenModal}
            >
              <AddOutlined />
            </Button>
          )}
        </Grid>
        <AddAssingFormsModal open={openModal} onClose={onCloseModal} />
      </Grid>

      <SpacingContainer px="0" py={2}>
        <Divider light />

        <TableContainer component={Paper} radius={9}>
          <Table style={{ minWidth: 900 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">{trans('form-name')}</TableCell>
                <TableCell align="left">{trans('requested-at')}</TableCell>
                <TableCell align="left">{trans('due-date')}</TableCell>
                <TableCell align="left">{trans('status')}</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            {detail && !emptyArray(detail.forms) && (
              <TableBody>
                {detail.forms.map(f => (
                  <Item
                    form={f}
                    key={f.id}
                    deleteForm={deleteForm}
                    getFormSubmissionJSONSignedUrl={getFormStackJSONSignedUrl}
                  />
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {detail && emptyArray(detail.forms) && (
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            <NothingFoundCard
              title={trans('no-forms-added-yet')}
              size="medium"
            />
            {isEnabled(
              permissions,
              'organization',
              'assigned-forms',
              'create'
            ) && (
              <Button color="primary" variant="contained" onClick={onOpenModal}>
                {trans('assign-form')}
              </Button>
            )}
          </div>
        )}
      </SpacingContainer>
    </Paper>
  )
}
export default connect(AssignForms)
