import React from 'react'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import { Card, Grid } from '@material-ui/core'
import ItemClient from './ItemClient'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'

export default function RecentlyClients({ items }) {
  const { trans } = useTrans()
  return (
    !!items && (
      <>
        <Card style={{ marginBottom: 32 }}>
          <Paper component={SpacingContainer} py={4} px="5sm3">
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item xs zeroMinWidth>
                <Typography
                  variant="h5"
                  weight="bold"
                  style={{ alignItems: 'center', display: 'flex' }}
                >
                  {trans('recently-registered-clients')}{' '}
                  <ShowTooltip sectionToShow="recently-registered-clients" />
                </Typography>
              </Grid>
            </Grid>
            <SpacingContainer py={2}>
              <Grid container spacing={3}>
                {items.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <ItemClient item={item} />
                  </Grid>
                ))}
              </Grid>
            </SpacingContainer>
          </Paper>
        </Card>
      </>
    )
  )
}
