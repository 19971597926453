import React, { useEffect, useState } from 'react'
import AdjustedForms from './adjustedForms'
import AssignedStaff from './assignedStaff/index'
import OrganizationMembers from './organizationMembers'
import OrganizationDetails from './organizationDetails'
import BillingSettings from './billingSettings'
import connect from './connect'
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom'
import ActivityHistory from './activityHistory'
import Loader from '../../../components/common/Loader'
import OrganizationSeats from './organizationSeats'
import Requests from './requests'
import Documents from './documents'
import ClaimedVendor from './claimedVendor'
import ExportsConfiguration from './exportsConfiguration'
import OrganizationHierarchy from './organizationHierarchy'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'

import Box from '@material-ui/core/Box'
import { IconBack } from '../../../components/svg/Icons'
import { Tab, Tabs, TabPanel } from '../../../components/common/Tabs'
import useTabs from '../../../hooks/useTabs'
import { useTrans } from '../../../services/i18n'
import clsx from 'clsx'
import {
  SelectedDocumentCategoryProvider,
  useSelectedDocumentCategory,
} from '../../../contexts/SelectedDocumentCategoryContext'
import { selectUserPermissions, isEnabled } from '../../../selectors'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  header: {
    zIndex: theme.zIndex.appBar + 1,
    position: 'relative',
    borderTop: `1px solid ${theme.palette.gray.divider}`,
    borderLeft: `1px solid ${theme.palette.gray.divider}`,
    height: 60,
    boxShadow: '0 10px 20px 0 rgba(78, 89, 120, 0.1)',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
  },
  btnHeader: {
    borderRadius: 0,
    height: '100%',
    width: 80,
    [theme.breakpoints.down('sm')]: { width: 60 },
  },
  content: { height: '100%' },
}))

const DetailsList = ({ getDetail, detail, authUser, getGlobalSettings }) => {
  const styles = useStyles()
  const { trans } = useTrans()
  const [tab, onChangeTab] = useTabs(1)
  const history = useNavigate()
  const permissions = useSelector(selectUserPermissions)
  const activeRoutes = isEnabled(permissions, 'organization', [
    'account-tab',
    'support-tab',
    'activity-history',
    'folder-google-drive',
  ])

  const [accountTab, supportTab, activityTab, documentTab] = activeRoutes
  const params = useParams()
  const [copyDetail, setCopyDetail] = useState(null)
  const [pendingDetail, setLoading] = useState(false)
  const getLink = () => `/clients`
  // Get the param query if there is one
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const tabRecieved = queryParams.get('tab') || 'account' // Default to 'account'

  useEffect(() => {
    setLoading(true)
    getDetail({ organizationId: params.id }).then(res => {
      setCopyDetail(res.payload)
      setLoading(false)
    })
    console.log('the tab received ', tabRecieved)
    let currentTab = 1
    switch (tabRecieved) {
      case 'account':
        currentTab = 1
        break;
      case 'support':
        currentTab = 2
        break;
      case 'documents':
        currentTab = 3
        break;
      case 'settings':
        currentTab = 4
        break;
      case 'activity-history':
        currentTab = 5
        break;
      default:
        currentTab = 1
        break;
    }
    
    if (!accountTab?.view) {
      currentTab = 2
    }
    if (currentTab === 2 && !supportTab?.view) {
      currentTab = 3
    }

    if (currentTab === 3 && documentTab?.view) {
      currentTab = 4
    }
    
    onChangeTab({}, currentTab)

    getGlobalSettings({})
  }, [
    params.id,
    getDetail,
    getGlobalSettings,
    accountTab?.view,
    documentTab?.view,
    tabRecieved
  ])

  return (
    <SelectedDocumentCategoryProvider>
      <Grid
        container
        className={styles.header}
        alignItems="center"
        wrap="nowrap"
        justifyContent="space-between"
      >
        {history.length > 0 ? (
          <IconButton
            onClick={() => history(-1)}
            className={styles.btnHeader}
            color="inherit"
          >
            <IconBack />
          </IconButton>
        ) : (
          <IconButton
            component={RouterLink}
            to={getLink}
            className={styles.btnHeader}
            color="inherit"
          >
            <IconBack />
          </IconButton>
        )}

        <Grid item className={styles.content} zeroMinWidth>
          <Tabs
            scrollButtons="on"
            className={styles.tabsContainer}
            value={tab}
            onChange={onChangeTab}
          >
            {accountTab?.view && (
              <Tab
                key={1}
                value={1}
                label={trans('account')}
                className={clsx(styles.tab)}
              />
            )}
            {supportTab?.view && (
              <Tab
                key={2}
                value={2}
                label={trans('support')}
                className={clsx(styles.tab)}
              />
            )}
            {documentTab?.view && (
              <Tab
                key={3}
                value={3}
                label={trans('documents')}
                className={clsx(styles.tab)}
              />
            )}
            <Tab
              key={4}
              value={4}
              label={trans('settings')}
              className={clsx(styles.tab)}
            ></Tab>
            {activityTab?.view && (
              <Tab
                key={5}
                value={5}
                label={trans('activity-history')}
                className={clsx(styles.tab)}
              />
            )}
          </Tabs>
        </Grid>
        <Grid item className={styles.btnHeader} />
      </Grid>
      {pendingDetail && <Loader open={pendingDetail} />}
      {!pendingDetail && copyDetail && (
        <>
          <TabPanel value={tab} tab={1} index={1}>
            <Box
              sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 15 }}
            >
              <OrganizationDetails detail={detail} />
            </Box>
            {isEnabled(
              permissions,
              'organization',
              'additional-seats',
              'view'
            ) && <OrganizationSeats detail={detail} />}

            <OrganizationHierarchy />
            {isEnabled(permissions, 'organization', 'organization-members') && (
              <OrganizationMembers />
            )}
            {isEnabled(permissions, 'organization', 'usfcr-staff') && (
              <AssignedStaff detail={detail} />
            )}
          </TabPanel>
          <TabPanel value={tab} tab={2} index={2}>
            <Requests organization={detail} onChangeTab={onChangeTab} />
            {isEnabled(permissions, 'organization', 'adjustment-forms') && (
              <AdjustedForms />
            )}
          </TabPanel>
          <TabPanel value={tab} tab={3} index={3}>
            <Documents organization={detail} />
          </TabPanel>
          <TabPanel value={tab} tab={4} index={4}>
            {isEnabled(permissions, 'organization', 'billing-settings') && (
              <BillingSettings />
            )}
            {isEnabled(
              permissions,
              'organization',
              'exports-configuration'
            ) && <ExportsConfiguration />}

            {isEnabled(permissions, 'organization', 'claimed-vendor') && (
              <ClaimedVendor />
            )}
          </TabPanel>
          <TabPanel value={tab} tab={5} index={5}>
            <ActivityHistory organization={detail} />
          </TabPanel>
        </>
      )}
    </SelectedDocumentCategoryProvider>
  )
}

export default connect(DetailsList)
