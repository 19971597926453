import React from 'react'
import { simpleIconPropsTypes } from '../../propTypes'
import MenuBookIcon from '@material-ui/icons/MenuBook'

export const IconCourses = props => {
  const { width = 22, height = 22 } = props

  return <MenuBookIcon style={{ width, height }} />
}

IconCourses.propTypes = simpleIconPropsTypes
