import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'
import connect from '../../connect'
import Switch from '../../../../components/common/Inputs/Switch'
import CheckBox from '../../../../components/common/Inputs/CheckBox'
import { Button } from '../../../../components/common/Buttons'
import { useAlertContext } from '../../../../contexts/AlertContext'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import { LABELS, SUCCESS_MESSAGES } from './constants'

const AccountFeatures = ({ tier, tierFeatures, saveFeatures }) => {
  const { trans } = useTrans()
  const [modules, setModules] = useState([])
  const { successAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const accountFeature = tierFeatures[tier]

  const updatePermissions = () => {
    setIsSend(true)
    saveFeatures({
      type: tier,
      features: modules,
    })
      .then(res => {
        setIsSend(false)
        successAlert(SUCCESS_MESSAGES[tier])
      })
      .catch(error => {
        setIsSend(false)
        successAlert(error.message)
      })
  }

  const changeModule = (ev, mo) => {
    const copyModules = JSON.parse(JSON.stringify(modules))
    const index = copyModules.findIndex(m => m.key === mo.key)
    copyModules[index].active = ev.target.checked
    //console.log(ev.target.checked)
    //if (!ev.target.checked) {
    copyModules[index].submodules = copyModules[index].submodules.map(s => ({
      ...s,
      active: ev.target.checked,
    }))
    // }
    setModules(copyModules)
  }

  const changeSubModule = (ev, mo, sub) => {
    const copyModules = JSON.parse(JSON.stringify(modules))
    const moIndex = copyModules.findIndex(m => m.key === mo.key)
    const subIndex = copyModules[moIndex].submodules.findIndex(
      s => s.key === sub.key
    )
    copyModules[moIndex].submodules[subIndex].active = ev.target.checked
    setModules(copyModules)
  }

  const selectUnselectAll = active => {
    let copyModules = JSON.parse(JSON.stringify(modules))
    copyModules = copyModules.map(m => {
      const submodules = m.submodules.map(s => ({ ...s, active: active }))
      return { ...m, active: active, submodules: submodules }
    })
    setModules(copyModules)
  }

  useEffect(() => {
    if (accountFeature) setModules(accountFeature)
  }, [accountFeature])

  return (
    <SpacingContainer py={2}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item md={6} xs={12} zeroMinWidth>
            <Typography
              variant="h4"
              weight="500"
              fontFamily="lexend"
              style={{ alignItems: 'center', display: 'flex' }}
            >
              {trans(LABELS[tier])}{' '}
              <ShowTooltip sectionToShow={LABELS[tier]} />
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="text"
              onClick={() => selectUnselectAll(true)}
            >
              {trans('select-all')}
            </Button>
            <Button
              color="primary"
              variant="text"
              onClick={() => selectUnselectAll(false)}
            >
              {trans('unselect-all')}
            </Button>
          </Grid>
        </Grid>
        <Divider light spacing={2} />

        <Grid container justifyContent="space-between" spacing={3}>
          {modules.map(mo => (
            <Grid item md={3} xs={12} zeroMinWidth key={mo.key}>
              <div style={{ margin: '16px 0' }}>
                <Typography
                  style={{ fontSize: '18px' }}
                  variant="body2"
                  weight="600"
                >
                  {mo.name}{' '}
                </Typography>
                <Switch
                  checked={mo.active}
                  onChange={ev => changeModule(ev, mo)}
                  inputProps={{ 'aria-label': 'module' }}
                  color="primary"
                />
              </div>
              {mo.submodules.map((sm, index) => (
                <div key={index}>
                  <FormControlLabel
                    key={sm.key}
                    control={
                      <CheckBox
                        color="secondary"
                        checked={sm.active}
                        onChange={ev => changeSubModule(ev, mo, sm)}
                      />
                    }
                    label={sm.name}
                  />
                </div>
              ))}
            </Grid>
          ))}
        </Grid>
        <Divider light spacing={4} />
        <div style={{ padding: '0 0 0.5rem 0' }}>
          <Button
            variant="contained"
            color="success"
            pending={isSend}
            onClick={updatePermissions}
          >
            {trans('save-settings')}
          </Button>
        </div>
      </Paper>
    </SpacingContainer>
  )
}
export default connect(AccountFeatures)
