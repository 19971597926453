import React from 'react';
import usePopover from '../../../../hooks/usePopover';
import { useTrans } from '../../../../services/i18n';
import { emptyArray } from '../../../../utils/arrayUtils';
import { IconSort } from '../../../svg/Icons/IconSort';
import Button from '../../Buttons/Button';
import { ContextMenuListItem, Popover } from '../../Dialogs/Popover';
import Typography from '../../Typography';

const DropdownSelectContext = React.createContext({ handleChange: undefined, selected: undefined });
export const useDropdownSelectContext = () => React.useContext(DropdownSelectContext);

export default function DropdownSelect({
  options,
  value,
  onChange,
  children,
  label = '',
  variant = 'outlined',
  count = 0,
  Icon = null,
  colorText = 'primary',
  ...rest
}) {
  const { trans } = useTrans();
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();

  const handleChange = React.useCallback(
    (newSelected) => {
      typeof onChange === 'function' && onChange(newSelected);
      onClosePopover();
    },
    [onChange, onClosePopover]
  );

  const contextValues = { value, handleChange };

  return (
    <DropdownSelectContext.Provider value={contextValues}>
      <Button onClick={onOpenPopover} variant={variant} endIcon={Icon || <IconSort />} {...rest}>
        <Typography variant="body2" color={colorText} noWrap weight={variant === 'text' ? 'bold' : ''}>
          {label ? `${label} ` : null}
          <Typography color={colorText} weight="700" variant="body2" component="span">
            {value && trans(value.i18nKey, value.i18nValues)}
            {count > 0 ? ` • ${count}` : null}
          </Typography>
        </Typography>
      </Button>

      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover}>
        {children
          ? children
          : !emptyArray(options)
          ? options.map((item, ind) => (
              <ContextMenuListItem
                key={ind}
                button
                onClick={handleChange.bind(null, item)}
                size="normal"
                selected={value && item.value === value.value}
              >
                <Typography variant="body2" color={colorText} weight="500" noWrap>
                  {trans(item.i18nKey, item.i18nValues)}
                </Typography>
              </ContextMenuListItem>
            ))
          : null}
      </Popover>
    </DropdownSelectContext.Provider>
  );
}
