import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTrans } from '../../../../../services/i18n';
import ChipGroup from '../../../../common/Chip/ChipGroup';
import { ChipOption } from '../ChipOption';

export const GreaterChipGroup = ({ item, onDeleteFilter, onDelete, onOpenFilter }) => {
  const { trans } = useTrans();
  const filterOption = item.filterOption || {};

  return (
    <Grid item component={ChipGroup}>
      <ChipOption
        name={filterOption.name ? trans(filterOption.name) : '-'}
        value={`${trans('is_greater')} ${item.value || item.data}`}
        onClick={onOpenFilter ? onOpenFilter.bind(null, filterOption) : undefined}
        onDelete={onDelete ? onDelete : onDeleteFilter ? onDeleteFilter.bind(null, item.key) : undefined}
      />
    </Grid>
  );
};
