import { connect } from 'react-redux'
import { actions } from '../../../../store/tooltipsManagement'

function mapStateToProps(state) {
  return {
    authUser: state.auth || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getTooltips: params => dispatch(actions.getTooltips(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
