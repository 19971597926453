import { connect } from 'react-redux'
import { actions } from '../../../store/clientDetail'
import { actions as actionsClients } from '../../../store/clients'
import { actions as actionsGlobalSettings } from '../../../store/globalSettings'

function mapStateToProps(state) {
  return {
    detail: state.clientDetail.detail,
    organizationHierarchy: state.clientDetail.organizationHierarchy,
    users: state.clientDetail.users,
    forms: state.clientDetail.forms,
    pendingDetail: state.clientDetail.pendingDetail,
    selectedFolder: state.clientDetail.selectedFolder,
    currentFolder: state.clientDetail.currentFolder,
    folders: state.clientDetail.folders,
    breadcrumFolders: state.clientDetail.breadcrumFolders,
    loadingFolders: state.clientDetail.loadingFolders,
    historyEvents: state.clientDetail.historyEvents,
    authUser: state.auth,
    monthlyExportsLimitOrgSettings: state.globalSettings.monthlyExportsLimit,
    current: state.clientDetail.current,
    monthlyLimit: state.clientDetail.monthlyLimit,
    resetLimitDate: state.clientDetail.resetLimitDate
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getGlobalSettings: params => dispatch(actionsGlobalSettings.getGlobalSettings(params)),
    getDetail: params => dispatch(actions.getDetail(params)),
    getForms: params => dispatch(actions.getForms(params)),
    getUsers: params => dispatch(actions.getUsers(params)),
    changeUserAccountStatus: params =>
      dispatch(actions.changeUserAccountStatus(params)),
    editUserRole: params => dispatch(actions.editUserRole(params)),
    saveUserUsfcr: params => dispatch(actions.saveUserUsfcr(params)),
    saveFormOrg: params => dispatch(actions.saveFormOrg(params)),
    removeUserFromOrg: params => dispatch(actions.removeUserFromOrg(params)),
    deleteUserUsfcr: params => dispatch(actions.deleteUserUsfcr(params)),
    deleteFormOrg: params => dispatch(actions.deleteFormOrg(params)),
    deleteFileOrg: params => dispatch(actions.deleteFileOrg(params)),
    approveFileOrg: params => dispatch(actions.approveFileOrg(params)),
    denyFileOrg: params => dispatch(actions.denyFileOrg(params)),
    downloadFileOrg: params => dispatch(actions.downloadFileOrg(params)),
    downloadOrganizationFiles: params => dispatch(actions.downloadOrganizationFiles(params)),
    downloadFile: params => dispatch(actions.downloadFile(params)),
    getFoldersByFolderId: params =>
      dispatch(actions.getFoldersByFolderId(params)),
    changeBreadcrumFolders: params =>
      dispatch(actions.changeBreadcrumFolders(params)),
    changeSelectedFolder: params =>
      dispatch(actions.changeSelectedFolder(params)),
    saveFileRequest: params => dispatch(actions.saveFileRequest(params)),
    updateFileRequest: params => dispatch(actions.updateFileRequest(params)),
    activeDeactiveOrganization: params =>
      dispatch(actions.activeDeactiveOrganization(params)),
    getHistoryEvents: params => dispatch(actions.getHistoryEvents(params)),
    saveBillingSettings: params =>
      dispatch(actions.saveBillingSettings(params)),
    createFolder: params => dispatch(actions.createFolder(params)),
    savePermissions: params => dispatch(actions.savePermissions(params)),
    handleAssociateUser: params => {
      return dispatch(actions.handleAssociateUser(params))
    },
    pushNewMember: params => dispatch(actions.pushNewMember(params)),
    addRemoveLicenses: params => dispatch(actions.addRemoveLicenses(params)),
    updateItemListLicense: params =>
      dispatch(actionsClients.updateItemListLicense(params)),
    updateClaimedVendor: params =>
      dispatch(actions.updateClaimedVendor(params)),
    removeClaimedVendor: params =>
      dispatch(actions.removeClaimedVendor(params)),
    refreshClaimedVendor: params =>
      dispatch(actions.refreshClaimedVendor(params)),
    updateExportsConfiguration: params =>
      dispatch(actions.updateExportsConfiguration(params)),
    getCategories: (params = {}) => dispatch(actions.getCategories(params)),
    getCategoryFiles: (params = {}) => dispatch(actions.getCategoryFiles(params)),
    createSubsidiaryOrganization: params =>
      dispatch(actions.createSubsidiaryOrganization(params)),
    updateParentOrganization: params =>
      dispatch(actions.updateParentOrganization(params)),
    getFormStackJSONSignedUrl: params => {
      return dispatch(actions.getFormStackJSONSignedUrl(params))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
