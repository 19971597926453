import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconLocation = (props) => {
  const { width = 22, height = 22 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 22 22">
      <path d="M11 0c4.976 0 9 4.116 9 9.182 0 3.087-1.611 6.109-4.266 8.975-.91.981-1.88 1.875-2.853 2.667-.34.277-.657.522-.943.734l-.1.072-.275.196c-.34.232-.787.232-1.126 0l-.276-.196-.55-.414c-.158-.123-.322-.254-.492-.392-.972-.792-1.943-1.686-2.853-2.667C3.611 15.29 2 12.269 2 9.182 2 4.116 6.024 0 11 0zm0 2C7.14 2 4 5.21 4 9.182c0 2.48 1.389 5.084 3.734 7.616.84.907 1.744 1.74 2.647 2.475.158.128.31.25.455.362l.164.125.164-.125.455-.362c.903-.736 1.807-1.568 2.647-2.475C16.611 14.266 18 11.663 18 9.182 18 5.21 14.86 2 11 2zm0 4c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0 2c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z" />
    </SvgIcon>
  );
};

IconLocation.propTypes = simpleIconPropsTypes;
