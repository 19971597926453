import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Button } from '../../../components/common/Buttons'
import { ModalDialog } from '../../../components/common/Dialogs'
import { useTrans } from '../../../services/i18n'
import { useAlertContext } from '../../../contexts/AlertContext'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import Typography from '../../../components/common/Typography'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import connect from '../connect'
import { useNavigate } from 'react-router-dom';
import { IconRemove } from '../../../components/svg/Icons/IconRemove';

const RemoveUserStaffModal = ({ detail = false, user, open, onClose, removeUserStaff, getUsersNoIsUsfcr }) => {
  const { trans } = useTrans()
  const history = useNavigate();
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)

  const removeUser = () => {
    setIsSend(true)
    removeUserStaff({ id: user.id })
      .then(() => {
        successAlert(`${user?.name} ${user?.familyName} has been removed from USFCR staff`)
        setIsSend(false)
        onClose()
        getUsersNoIsUsfcr()
        if (detail) history('/usfcr-staff')
      })
      .catch(error => {
        setIsSend(false)
        errorAlert(error.message)
        onClose()
      })
  }

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans('remove-user')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSend}
              onClick={onClose}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              disabled={isSend}
              pending={isSend}
              onClick={() => removeUser()}
            >
              {trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer className="center" py={3}>
        <IconRemove thin />
      </SpacingContainer>

      <Typography align="center" weight="600" gutter="bottomSpace">
        {trans('message-ask-remove-account')}
      </Typography>

      <Paper
        bgColor="paperSecondary"
        component={SpacingContainer}
        py={2}
        px={2}
        className="center"
      >
        <Typography color="placeholder" variant="h6">
          {'User'}
        </Typography>
        <Typography variant="h6" weight="bold">
          {user?.name} {user?.familyName}
        </Typography>
      </Paper>
    </ModalDialog>
  )
}

export default connect(RemoveUserStaffModal)
