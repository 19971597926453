import { SpacingContainer } from '../../../components/common/Containers/Base'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import ListInfoRow from '../../../components/generics/ListInfoRow'
import SearchTopPanel from '../../../components/generics/SearchTopPanel'
import SortingPanel from '../../../components/generics/SortingPanel'
import connect from '../connect'
import { filterOptions } from '../filter';
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'

const Header = ({ scope, clients, onChangeOrder, onChangeFilters }) => {
  const { trans } = useTrans()

  const orderOptions = [
    {
      name: trans('name'),
      value: 'orgName',
      asc: trans('ascending'),
      desc: trans('descending'),
    },
    {
      name: trans('owner'),
      value: 'ownerName',
      asc: trans('ascending'),
      desc: trans('descending'),
    },
    {
      name: trans('status'),
      value: 'active',
      asc: trans('deactivated-first'),
      desc: trans('active-first'),
    },
    {
      name: trans('additional-seats'),
      value: 'licenses',
      asc: trans('ascending'),
      desc: trans('descending'),
    },
    {
      name: trans('expiration-date'),
      value: 'afpdsExpirationDate',
      asc: trans('furthest-first'),
      desc: trans('closest-first'),
    },
    {
      name: trans('registered-at'),
      value: 'createdAt',
      asc: trans('oldest-first'),
      desc: trans('newest-first'),
    },
    {
      name: trans('subscription'),
      value: 'subscription',
      asc: trans('basic-first'),
      desc: trans('premium-first'),
    },
  ]
  const { filters, order, count, items } = clients

  return (
    <>
      <SpacingContainer py={3} px={0}>
        <Typography
          weight="600"
          variant="h2"
          fontFamily="lexend"
          title
          style={{ alignItems: 'center', display: 'flex' }}
        >
          {trans('organizations')}{' '}
          <ShowTooltip sectionToShow="organizations-table" />
        </Typography>
      </SpacingContainer>

      <SearchTopPanel
        scope={scope}
        filters={filters}
        onChangeFilters={onChangeFilters}
        filterOptions={filterOptions}
      />

      {!!items.length && (
        <ListInfoRow
          count={count}
          description={trans('results')}
          sort={
            <SortingPanel
              order={order}
              scope={scope}
              orderOptions={orderOptions}
              onChangeOrder={onChangeOrder}
            />
          }
        />
      )}
    </>
  )
}

export default connect(Header)
