import React from 'react'
import connect from '../connect'
import Typography from '../../../components/common/Typography'
import { intlDateFormat } from '../../../utils/dateUtils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { InlineContainer } from '../../../components/common/Containers'
import ItemMenu from './ItemMenu'

const Item = ({ cat }) => {
  return (
    <>
      <TableRow>
        <TableCell align="left">
          <InlineContainer>
            <Typography weight="500" variant="h6">
              {cat.name}
            </Typography>
          </InlineContainer>
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="inherit"
            fontFamily="lexend"
          >
            {cat.filesCount}
          </Typography>
        </TableCell>
        <TableCell  align="left">
          <Typography
            variant="inherit"
            fontFamily="lexend"
          >
            {cat.formsCount}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <ItemMenu cat={cat} />
        </TableCell>
      </TableRow>
    </>
  )
}

export default connect(Item)
