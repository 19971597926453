import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { API } from '../../constants'
import { application } from '../../services/application'

//State
export const INITIAL_STATE = {
  items: [],
}

const getItems = createAsyncThunk('learnWorlds/getItems', params => {
  return application.call(API.LEARNWORLDS.GET_ITEM_LEVELS, params)
})
function onGetCourses(state, action) {
  if (!action.payload.success) {
    return state
  }
  return Object.assign(state, {
    items: action.payload.items || [],
  })
}

const saveCourseLevels = createAsyncThunk(
  'learnWorlds/saveCourseLevels',
  params => {
    return application.call(API.LEARNWORLDS.SAVE_COURSE_LEVELS, params)
  }
)

//Actions
export const actions = {
  getItems,
  saveCourseLevels,
}

export default createReducer(INITIAL_STATE, builder => {
  builder.addCase(getItems.fulfilled, onGetCourses)
})
