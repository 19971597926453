import clsx from 'clsx'
import React from 'react'
import Loader from '../../Loader'
import Paper from '../Paper'
import useStyles from './styles'
import TopBar from './components/TopBar'
import { useTrans } from '../../../../services/i18n'
import { useNavigate } from 'react-router-dom'
import { Box } from '@material-ui/core'

export default function EditViewContainer({
  children,
  className,
  pending = false,
  bgColor = 'transparent',
  title,
  saveText,
  onSave,
  discardText,
  onDiscard,
  ...rest
}) {
  const { trans } = useTrans()
  const navigate = useNavigate()
  const styles = useStyles()

  return (
    <Paper
      className={clsx(styles.root, className)}
      square
      bgColor={bgColor}
      component="section"
      {...rest}
    >
      <Loader open={pending} />
      <div className={clsx(styles.fixed)}>
        <TopBar
          title={title}
          discardButtonText={discardText ? discardText : trans('discard-quit')}
          onDiscardClick={onDiscard ? onDiscard : () => navigate(-1)}
          successButtonText={saveText ? saveText : trans('save-quit')}
          onSuccessClick={onSave}
        />
      </div>
      <Box style={{ overflowY: 'scroll' }}>{children}</Box>
    </Paper>
  )
}
