import Grid from '@material-ui/core/Grid'
import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import { useTrans } from '../../../../services/i18n'
import { useAlertContext } from '../../../../contexts/AlertContext'
import {
  TextField,
} from '../../../../components/common/Inputs'
import connect from '../connect'
import { useForm } from '../../../../hooks/useForm'
import makeValidationSchema from '../../../../hooks/makeValidationSchema'
import { schemaToInitialState } from '../../../../utils/validationUtils'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  fileTypeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const DenyReasonForm = ({
  setReasonForDenial,
}) => {
  const styles = useStyles()
  const { trans } = useTrans()
  const [error, setError] = useState('')

  return (
    <>
      <Grid
        container
        spacing={3}
        alignItems="center"
        style={{ marginTop: '1.5rem' }}
      >
        <Grid item xs={12}>
          <TextField
            label={trans('reason-for-denial')}
            placeholder={trans('required')}
            multiline
            minRows={4}
            maxRows={10}
            maxLength={255}
            onChange={(e)=>{
              if(e.target.value.trim() === ''){
                setReasonForDenial({reason: e.target.value.trim(), hasError: true});
                setError('Reason for denial is required');
              }else{
                setReasonForDenial({reason: e.target.value.trim(), hasError: false});
                setError('');
              }
            }}
            error={error}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default connect(DenyReasonForm)
