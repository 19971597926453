import {
  ROUTE_AGENCIES_OFFICES,
  ROUTE_CODE_CATEGORIES,
  ROUTE_CONTRACT_AWARDS,
  ROUTE_CONTRACT_OPPORTUNITIES,
  ROUTE_FEDERAL_AGENTS,
  ROUTE_GOVERMENT_FILES,
  ROUTE_GRANT_OPPORTUNITIES,
  ROUTE_VENDORS,
  ROUTE_PIPELINES,
  ROUTE_TASKS,
  ROUTE_MY_PURSUITS,
  ROUTE_TEAMING_OPPORTUNITIES,
  ROUTE_OPEN_PURSUITS,
  ROUTE_NSN_OPPORTUNITIES,
  ROUTE_EXPORTS,
} from './client-routes'

export const SCOPE_NOTIFICATIONS = 'NOTIFICATIONS'
export const SCOPE_SAVED_SEARCHES = 'SAVED_SEARCHES'
export const SCOPE_SUGGESTED_SEARCHES = 'SUGGESTED_SEARCHES'
export const SCOPE_PIPELINES = 'PIPELINES'
export const SCOPE_NSNOPPORTUNITIES = 'NSNOPPORTUNITIES'

export const SCOPE_PURSUITS = 'PURSUITS'
export const SCOPE_MY_PURSUITS = 'MY_PURSUITS'
export const SCOPE_TASKS = 'TASKS'
export const SCOPE_NOT_INTERESTING = 'NOT_INTERESTING'

export const SCOPE_CONTRACT_OPPORTUNITIES = 'CONTRACT_OPPORTUNITIES'
export const SCOPE_GRANT_OPPORTUNITIES = 'GRANT_OPPORTUNITIES'
export const SCOPE_CONTRACT_AWARDS = 'CONTRACT_AWARDS'
export const SCOPE_FEDERAL_AGENTS = 'FEDERAL_AGENTS'
export const SCOPE_AGENCIES_OFFICES = 'FEDERAL_AGENCIES'
export const SCOPE_VENDORS = 'VENDORS'
export const SCOPE_GOVERMENT_FILES = 'GOVERMENT_FILES'
export const SCOPE_CODE_CATEGORIES = 'CODE_CATEGORIES'
export const SCOPE_TEAMING_OPPORTUNITIES = 'TEAMING_OPPORTUNITIES'
export const SCOPE_OPEN_PURSUITS = 'OPEN_PURSUITS'
export const SCOPE_QUOTE_REQUESTS = 'QUOTE_REQUESTS'
export const SCOPE_EXPORTS = 'EXPORTS'

export const SCOPES_ROUTES = {
  [SCOPE_CONTRACT_OPPORTUNITIES]: ROUTE_CONTRACT_OPPORTUNITIES,
  [SCOPE_GRANT_OPPORTUNITIES]: ROUTE_GRANT_OPPORTUNITIES,
  [SCOPE_CONTRACT_AWARDS]: ROUTE_CONTRACT_AWARDS,
  [SCOPE_FEDERAL_AGENTS]: ROUTE_FEDERAL_AGENTS,
  [SCOPE_AGENCIES_OFFICES]: ROUTE_AGENCIES_OFFICES,
  [SCOPE_VENDORS]: ROUTE_VENDORS,
  [SCOPE_GOVERMENT_FILES]: ROUTE_GOVERMENT_FILES,
  [SCOPE_CODE_CATEGORIES]: ROUTE_CODE_CATEGORIES,
  [SCOPE_PIPELINES]: ROUTE_PIPELINES,
  [SCOPE_TASKS]: ROUTE_TASKS,
  [SCOPE_PURSUITS]: ROUTE_MY_PURSUITS,
  [SCOPE_TEAMING_OPPORTUNITIES]: ROUTE_TEAMING_OPPORTUNITIES,
  [SCOPE_OPEN_PURSUITS]: ROUTE_OPEN_PURSUITS,
  [SCOPE_NSNOPPORTUNITIES]: ROUTE_NSN_OPPORTUNITIES,
  [SCOPE_EXPORTS]: ROUTE_EXPORTS,
}
