import Grid from '@material-ui/core/Grid';
import React, { useCallback } from 'react';
import { Button } from '../../common/Buttons';
import { ModalDialog } from '../../common/Dialogs';
import { useTrans } from '../../../services/i18n';
import { useAlertContext } from '../../../contexts/AlertContext';
import { SpacingContainer } from '../../common/Containers';
import { Autocomplete } from '../../common/Inputs';
import { getInitialValues, useScheme } from './helpers';
import { useForm } from '../../../hooks/useForm';

const formOptions = { validateOnMount: false, validateOnChange: false, enableReinitialize: true };

export default function AddAssignedOrganizationsModal({ userId, organizations, open, onClose, handleAction, fullFilledAction }) {
  const { trans } = useTrans();
  const schema = useScheme();
  const { successAlert, errorAlert, infoAlert } = useAlertContext();
  const [isSend, setIsSend] = React.useState(false);

  const closeModal = () => {
    resetForm()
    onClose()
  }

  const onSubmit = useCallback(
    (values) => {
      if (typeof handleAction !== 'function' || !userId) return;
      setIsSend(true);
      Promise.resolve(handleAction({ ...values, userId }))
        .then(res => {
          if (res.error && res.error.message) {
            errorAlert()
          } else {
            successAlert('Organizations assigned!');
            closeModal();
            fullFilledAction()
          }
        })
        .catch(errorAlert)
        .finally(() => setIsSend(false));
    },
    [closeModal, handleAction, infoAlert, errorAlert, successAlert]
  );

  const { getFieldProps, handleSubmit, resetForm } = useForm(
    schema,
    getInitialValues({}),
    onSubmit,
    formOptions
  );

  const inputOrganizationChange = (e) => {
    const text = e.target.value
    if (text.length >= 3) {
      setTimeout(() => {
        fullFilledAction({ text, onlyNotUser: true })
      }, 500);
    }
  }

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={trans('assigned-organization')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" disabled={isSend} onClick={closeModal}>
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" disabled={isSend} pending={isSend} onClick={handleSubmit}>
              {trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
			<SpacingContainer px={3} py={2}>
        <Autocomplete
          options={organizations}
          labelKey="orgName"
          required
          multiple
          label={trans('select-organization')}
          placeholder={trans('required')}
          disableCloseOnSelect
          {...getFieldProps('organizationIds')}
          onInputChange={inputOrganizationChange}
        />
			</SpacingContainer>
    </ModalDialog>
  );
}
