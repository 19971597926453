// StyledComponents.js

import styled from 'styled-components'
import { TableCell, Typography } from '@material-ui/core'

export const StyledTableCell = styled(TableCell)`
  width: ${props => props.width || 'auto'};
`

export const StyledTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.color || '#2445a7'};
  cursor: ${props => props.cursor || 'default'};
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
`
export const Breadcrumb = styled.div`
  color: ${props => props.color || '#2445a7'};
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
  font-family: Manrope;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const BreadcrumbSeparator = styled.div`
  color: #b2b8cf;
  font-family: Manrope;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const BreadcrumbWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const NotFoundWrapper = styled.div`
  display: flex;
  padding: 32px 0px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`


export const NotFoundLabel = styled.span`
  color: #000;
  text-align: center;
  font-family: Lexend;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;