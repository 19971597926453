import { Grid } from '@material-ui/core'
import { SpacingContainer } from '../../../components/common/Containers/Base'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import ListInfoRow from '../../../components/generics/ListInfoRow'
import SortingPanel from '../../../components/generics/SortingPanel'
import React, { useEffect } from 'react'
import connect from '../connect'
import useBooleanHandlers from '../../../hooks/useBooleanHandlers'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'
import { Button } from '../../../components/common/Buttons'
import AddCategoryModal from './AddCategoryModal'
import { selectUserPermissions, isEnabled } from '../../../selectors'
import { useSelector } from 'react-redux'

const Header = ({ scope, requestTemplates, onChangeOrder, getList }) => {
  const { trans } = useTrans()
  const permissions = useSelector(selectUserPermissions)
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const orderOptions = [
    {
      name: 'Registration date',
      value: 'createdAt',
      asc: 'Oldest first',
      desc: 'Newest first',
    },
  ]
  const { order, count, items } = requestTemplates

  useEffect(() => {}, [])

  const onChangeOrderHandler = value => {
    getList({
      text: '',
      order: value,
      filters: {},
      //pagination: { page: newPage, perPage: 10 },
    })
  }

  return (
    <>
      <SpacingContainer py={3} px={0}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              weight="600"
              variant="h2"
              fontFamily="lexend"
              title
              style={{ alignItems: 'center', display: 'flex' }}
            >
              {trans('request-templates')}{' '}
              <ShowTooltip sectionToShow="request-templates-table" />
            </Typography>
          </Grid>
          <Grid item>
            {isEnabled(permissions, 'request-templates', '', 'create') && (
              <Button onClick={onOpenModal} variant="contained" color="primary">
                {trans('add-category')}
              </Button>
            )}
          </Grid>
        </Grid>
      </SpacingContainer>

      {/* <SearchTopPanel
        scope={scope}
        filters={filters}
        onChangeFilters={onChangeFilters}
        filterOptions={[]}
      /> */}

      {!!items.length && (
        <ListInfoRow
          count={count}
          description={trans('results')}
          sort={
            <SortingPanel
              order={order}
              scope={scope}
              orderOptions={orderOptions}
              onChangeOrder={onChangeOrderHandler}
            />
          }
        />
      )}

      <AddCategoryModal open={openModal} onClose={onCloseModal} />
    </>
  )
}

export default connect(Header)
