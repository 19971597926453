import React, { useState } from 'react'
import connect from '../connect'
import { IconButton } from '@material-ui/core'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  ContextMenuListItem,
  Popover,
} from '../../../components/common/Dialogs/Popover'
import useBooleanHandlers from '../../../hooks/useBooleanHandlers'
import usePopover from '../../../hooks/usePopover'
import ListItemTextBase from '../../../components/common/Containers/Base/ListItemTextBase'
import FormUserStaff from '../../../components/forms/SaveUserStaff/'
import DeactivateUserStaffModal from './DeactivateUserStaffModal'
import RemoveUserStaffModal from './RemoveUserStaffModal'
import { useTrans } from '../../../services/i18n'
import ConfirmDialog from '../../../components/generics/Confirm/Dialog'
import { useAlertContext } from '../../../contexts/AlertContext'
import Typography from '../../../components/common/Typography'
import { useSelector } from 'react-redux'
import { selectUserPermissions, isEnabled } from '../../../selectors'

const UserMenu = ({
  detail = false,
  user,
  editUser,
  fetchList,
  findOne,
  disabledTwoFactorAuth,
  authUser,
}) => {
  const { trans } = useTrans()
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()
  const [openEdit, openEditModal, onCloseEditModal] = useBooleanHandlers()
  const [openDeactivate, openDeactivateModal, onCloseDeactivateModal] =
    useBooleanHandlers()
  const [openRemove, openRemoveModal, onCloseRemoveModal] = useBooleanHandlers()
  const [
    openConfirmDisabledTwoFactor,
    onConfirmDisabledTwoFactorAuth,
    onCloseConfirmDisabledTwoFactorAuth,
  ] = useBooleanHandlers()
  const [pendingDisabledTwoFactorAuth, setPendingDisabledTwoFactorAuth] =
    useState(false)
  const { successAlert, errorAlert } = useAlertContext()

  let validationRole = !(
    authUser.role === 'manager' || authUser.role === 'consulting'
  )
  const fullName =
    user?.name !== 'Unassigned' ? `${user?.name} ${user?.familyName}` : ''
  const handleDisabledTwoFactorAuth = () => {
    setPendingDisabledTwoFactorAuth(true)
    Promise.resolve(disabledTwoFactorAuth({ id: user.id }))
      .then(res => {
        if (res && res.payload) {
          onCloseConfirmDisabledTwoFactorAuth()
          successAlert(
            `${fullName} has been disabled two factor authentication`
          )
          if (detail) findOne({ id: user.id })
        } else {
          errorAlert(
            `${fullName} could not be disabled two factor authentication`
          )
        }
      })
      .catch(err => {
        errorAlert(err.message)
      })
      .finally(() => setPendingDisabledTwoFactorAuth(false))
  }
  const permissions = useSelector(selectUserPermissions)

  return (
    <>
      {validationRole &&
        (isEnabled(permissions, 'usfcr-staff', '', 'edit') ||
          isEnabled(permissions, 'usfcr-staff', '', 'delete')) && (
          <IconButton onClick={onOpenPopover}>
            <MoreHorizIcon />
          </IconButton>
        )}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClosePopover}
      >
        {isEnabled(permissions, 'usfcr-staff', '', 'edit') && (
          <>
            <ContextMenuListItem
              button
              onClick={openEditModal}
              onClickCB={onClosePopover}
            >
              <ListItemTextBase>{trans('edit')}</ListItemTextBase>
            </ContextMenuListItem>

            <ContextMenuListItem
              button
              onClick={onConfirmDisabledTwoFactorAuth}
              onClickCB={onClosePopover}
            >
              <ListItemTextBase>{trans('disabled-2fa')}</ListItemTextBase>
            </ContextMenuListItem>
            <ContextMenuListItem
              button
              onClick={openDeactivateModal}
              onClickCB={onClosePopover}
            >
              <ListItemTextBase>
                {user.usfcrActive ? trans('deactivate') : trans('activate')}
              </ListItemTextBase>
            </ContextMenuListItem>
          </>
        )}

        {isEnabled(permissions, 'usfcr-staff', '', 'delete') && (
          <ContextMenuListItem
            button
            onClick={openRemoveModal}
            onClickCB={onClosePopover}
          >
            <ListItemTextBase>{trans('remove')}</ListItemTextBase>
          </ContextMenuListItem>
        )}
      </Popover>

      <FormUserStaff
        open={openEdit}
        onClose={onCloseEditModal}
        handleAction={editUser}
        item={{
          usersId: [{ id: user.id }],
          id: user.id,
          role: user.role,
          name: `${user.name} ${user.familyName}`,
          sub_role_id: user.sub_role_id,
        }}
        fetchList={!detail ? fetchList : () => findOne({ id: user.id })}
      />
      <DeactivateUserStaffModal
        user={user}
        open={openDeactivate}
        onClose={onCloseDeactivateModal}
      />
      <RemoveUserStaffModal
        user={user}
        open={openRemove}
        onClose={onCloseRemoveModal}
        detail={true}
      />

      <ConfirmDialog
        open={openConfirmDisabledTwoFactor}
        onClose={onCloseConfirmDisabledTwoFactorAuth}
        onConfirm={handleDisabledTwoFactorAuth}
        pending={pendingDisabledTwoFactorAuth}
        title={`${trans('disabled-2fa')} ${fullName}`}
        text={trans('confirm-disabled-2fa')}
      >
        <Typography color="hint" weight="bold">
          {fullName}
        </Typography>
        <Typography color="hint">{user?.email}</Typography>
      </ConfirmDialog>
    </>
  )
}

export default connect(UserMenu)
