import React from 'react'
import { SvgIcon } from '../../common/SvgIcon'
import { simpleIconPropsTypes } from '../propTypes'

export const IconFilter = props => {
  const { width = 24, height = 24 } = props

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        id="Vector"
        d="M9 2.5C8.44772 2.5 8 2.94772 8 3.5C8 4.05228 8.44772 4.5 9 4.5V2.5ZM17 4.5C17.5523 4.5 18 4.05229 18 3.5C18 2.94772 17.5523 2.5 17 2.5V4.5ZM9 16.5C8.44772 16.5 8 16.9477 8 17.5C8 18.0523 8.44772 18.5 9 18.5V16.5ZM17 18.5C17.5523 18.5 18 18.0523 18 17.5C18 16.9477 17.5523 16.5 17 16.5V18.5ZM9 11.5C9.55228 11.5 10 11.0523 10 10.5C10 9.94772 9.55229 9.5 9 9.5V11.5ZM1 9.5C0.447715 9.5 5.96046e-08 9.94771 0 10.5C-1.19209e-07 11.0523 0.447715 11.5 1 11.5L1 9.5ZM9 4.5L17 4.5V2.5L9 2.5V4.5ZM9 18.5H17V16.5H9V18.5ZM9 9.5L1 9.5L1 11.5L9 11.5V9.5ZM0 3.5C0 5.15685 1.34315 6.5 3 6.5V4.5C2.44772 4.5 2 4.05228 2 3.5H0ZM3 6.5C4.65685 6.5 6 5.15685 6 3.5H4C4 4.05228 3.55228 4.5 3 4.5V6.5ZM6 3.5C6 1.84315 4.65685 0.5 3 0.5V2.5C3.55228 2.5 4 2.94772 4 3.5H6ZM3 0.5C1.34315 0.5 0 1.84315 0 3.5H2C2 2.94772 2.44772 2.5 3 2.5V0.5ZM0 17.5C0 19.1569 1.34315 20.5 3 20.5V18.5C2.44772 18.5 2 18.0523 2 17.5H0ZM3 20.5C4.65685 20.5 6 19.1569 6 17.5H4C4 18.0523 3.55228 18.5 3 18.5V20.5ZM6 17.5C6 15.8431 4.65685 14.5 3 14.5V16.5C3.55228 16.5 4 16.9477 4 17.5H6ZM3 14.5C1.34315 14.5 0 15.8431 0 17.5H2C2 16.9477 2.44772 16.5 3 16.5V14.5ZM18 10.5C18 8.84315 16.6569 7.5 15 7.5V9.5C15.5523 9.5 16 9.94772 16 10.5H18ZM15 7.5C13.3431 7.5 12 8.84315 12 10.5H14C14 9.94771 14.4477 9.5 15 9.5V7.5ZM12 10.5C12 12.1569 13.3431 13.5 15 13.5V11.5C14.4477 11.5 14 11.0523 14 10.5H12ZM15 13.5C16.6569 13.5 18 12.1569 18 10.5H16C16 11.0523 15.5523 11.5 15 11.5V13.5Z"
        fill="black"
      />
    </SvgIcon>
  )
}

IconFilter.propTypes = simpleIconPropsTypes
