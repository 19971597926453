import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import { Button } from '../../../../components/common/Buttons'
import { ModalDialog } from '../../../../components/common/Dialogs'
import { useTrans } from '../../../../services/i18n'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { SpacingContainer } from '../../../../components/common/Containers'
import CheckBox from '../../../../components/common/Inputs/CheckBox'
import connect from '../../connect'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '../../../../components/common/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { emptyArray } from '../../../../utils/arrayUtils'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard'

const SetupTemplatesModal = ({
  getDefaultSAPRequests,
  defaultSAPRequests,
  open,
  onClose,
  saveDefaultSAPRequests,
}) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = useState(false)
  const [defaultSAPRequestsList, setDefaultSAPRequestsList] = useState([])

  const closeModal = () => {
    setDefaultSAPRequestsList(defaultSAPRequests)
    setIsSend(false)
    onClose()
  }

  const saveDefaultSAPRequestsList = () => {
    setIsSend(true)
    saveDefaultSAPRequests(defaultSAPRequestsList.filter(i => {return i.checked === true}))
      .then(() => {
        successAlert('Default SAP forms updated!')
        getDefaultSAPRequests()
        closeModal()
      })
      .catch(() => {
        errorAlert('Error')
        closeModal()
      })
  }

  const changeCheckTemplate = (ev, formId) => {
    let defaultSAPRequestsCopy = []
    defaultSAPRequestsCopy = JSON.parse(JSON.stringify(defaultSAPRequestsList))
    const index = defaultSAPRequestsCopy.findIndex(i => i.id === formId)
    defaultSAPRequestsCopy[index].checked = ev.target.checked
    setDefaultSAPRequestsList(defaultSAPRequestsCopy)
  }

  const selectUnselectAll = active => {
    let defaultSAPRequestsCopy = []
    defaultSAPRequestsCopy = JSON.parse(JSON.stringify(defaultSAPRequestsList))
    defaultSAPRequestsCopy = defaultSAPRequestsCopy.map(f => {
      return { ...f, checked: active }
    })
    setDefaultSAPRequestsList(defaultSAPRequestsCopy)
  }

  useEffect(() => {
    setDefaultSAPRequestsList(defaultSAPRequests)
  }, [defaultSAPRequests])

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={trans('select-request-templates')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSend}
              onClick={closeModal}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={() => saveDefaultSAPRequestsList()}
            >
              {trans('save')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer px={3} py={0}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <div>
            <Button
              color="primary"
              variant="text"
              onClick={() => selectUnselectAll(true)}
            >
              {trans('select-all')}
            </Button>
            <Button
              color="primary"
              variant="text"
              onClick={() => selectUnselectAll(false)}
            >
              {trans('unselect-all')}
            </Button>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          
        </div>

        {!emptyArray(defaultSAPRequestsList) && (
          <div style={{ height: '300px' }}>
            {defaultSAPRequestsList.map(i => (
              <div style={{ padding: '5px 0' }}>
                <FormControlLabel
                  key={i.id}
                  control={
                    <CheckBox
                      color="secondary"
                      checked={i.checked}
                      onChange={ev => changeCheckTemplate(ev, i.id)}
                    />
                  }
                  label={i.name}
                />
              </div>
            ))}
          </div>
        )}

        {emptyArray(defaultSAPRequestsList) && (
          <div
            style={{ display: 'flex', alignItems: 'center', height: '300px' }}
          >
            <NothingFoundCard
              title={trans('no-templates-found')}
              description={trans('no-templates-found-description')}
              size="medium"
            />
          </div>
        )}
      </SpacingContainer>
    </ModalDialog>
  )
}

export default connect(SetupTemplatesModal)
