import { connect, batch } from 'react-redux'
import { actions } from '../../store/users'
import { generateListCallbacks } from '../../store/helpers/listActionsHelpers'

function mapStateToProps(state) {
  return {
    users: state.users,
    userDetail: state.users.userDetail,
    authUser: state.auth,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    deleteUser: (params) => dispatch(actions.deleteUser(params)),
    disabledTwoFactorAuth: (params) => dispatch(actions.disabledTwoFactorAuth(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
