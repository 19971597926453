import React, { useState } from 'react'
import connect from '../connect'
import { IconButton } from '@material-ui/core'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import useBooleanHandlers from '../../../hooks/useBooleanHandlers'
import { useTrans } from '../../../services/i18n'
import AddCategoryModal from './AddCategoryModal'
import ConfirmDialog from '../../../components/generics/Confirm/Dialog'
import Typography from '../../../components/common/Typography'
import { useAlertContext } from '../../../contexts/AlertContext'
import { useNavigate } from 'react-router-dom'
import { selectUserPermissions, isEnabled } from '../../../selectors'
import { useSelector } from 'react-redux'

const ItemMenu = ({ cat, deleteRequestTemplate, onInit }) => {
  const { trans } = useTrans()
  const [open, openModal, onCloseModal] = useBooleanHandlers()
  const [openConfirmDelete, onOpenConfirmDelete, onCloseConfirmDelete] =
    useBooleanHandlers()
  const navigate = useNavigate()
  const permissions = useSelector(selectUserPermissions)

  const { successAlert } = useAlertContext()
  const [pendingDelete, setPendingDelete] = useState(false)

  const onDelete = async () => {
    setPendingDelete(true)
    const { payload } = await deleteRequestTemplate({ id: cat.id })
    successAlert(payload.message)
    setPendingDelete(false)
    onInit()
    onCloseConfirmDelete()
  }

  const openDetail = () => {
    navigate(
      '/request-template/' + cat.id + '/' + cat.name.split(' ').join('-')
    )
  }

  return (
    <>
      {isEnabled(permissions, 'request-templates', '', 'edit') && (
        <IconButton onClick={openModal}>
          <EditIcon />
        </IconButton>
      )}
    {isEnabled(permissions, 'request-templates', '', 'delete') && (
      <IconButton onClick={onOpenConfirmDelete}>
        <DeleteIcon />
      </IconButton>
    )}
      <IconButton onClick={openDetail}>
        <ChevronRightIcon />
      </IconButton>

      <AddCategoryModal item={cat} open={open} onClose={onCloseModal} />
      <ConfirmDialog
        open={openConfirmDelete}
        onClose={onCloseConfirmDelete}
        onConfirm={onDelete}
        pending={pendingDelete}
        title={trans('remove-template-category')}
        text={`${trans('remove-category')} ?`}
        note={
          'All contained file and form templates will be lost, but it will not affect requests made from this template'
        }
      >
        <Typography color="hint">{trans('category')}</Typography>
        <Typography color="hint" weight="bold">
          {cat.name}
        </Typography>
      </ConfirmDialog>
    </>
  )
}

export default connect(ItemMenu)
