import dayjs from 'dayjs'
import {
  DATE_FORMAT,
  DATE_PRETTY_FORMAT,
  ISO_STANDART_DATE_TIME_FORMAT,
} from '../constants'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

const DATE_SHORT_OPTIONS = { month: 'short', day: 'numeric', year: 'numeric' }
const DATETIME_SHORT_OPTIONS = {
  ...DATE_SHORT_OPTIONS,
  hour: 'numeric',
  minute: 'numeric',
}

/* INTL */
export function intlDateFormat(
  date,
  locale = 'en-US',
  options = DATE_SHORT_OPTIONS
) {
  try {
    if (!date) return 'Not provided'

    const op = options
    const authTimezone = localStorage.getItem('authTimezone')

    if (authTimezone && authTimezone !== 'undefined') {
      op.timeZone = authTimezone
    }

    return new Date(date).toLocaleDateString(locale, options)
  } catch (e) {
    return ''
  }
}

export const intlDateWithTimeFormat = date =>
  intlDateFormat(date, 'en-US', DATETIME_SHORT_OPTIONS)

/* FORMAT */
export const formatDate = (date, customFormat = DATE_FORMAT, dateFormat) => {
  try {
    if (!date) return ''
    const authFormatDate = localStorage.getItem('authFormatDate')
    const authTimezone = localStorage.getItem('authTimezone')
    const dateF = dayjs(date, dateFormat)

    if (authTimezone && authTimezone !== 'undefined') {
      dateF.tz(authTimezone)
    }
    return dateF.format(
      authFormatDate && authFormatDate !== 'undefined'
        ? authFormatDate
        : customFormat
    )
  } catch (err) {
    return ''
  }
}

export const formatPrettyDate = (date, customFormat = DATE_PRETTY_FORMAT) => {
  return formatDate(date, customFormat)
}
export const formatPrettyUnixDate = (date, customFormat = DATE_PRETTY_FORMAT) =>
  dayjs.unix(date).format(customFormat)

export const formatISODate = (date, customFormat = DATE_FORMAT) =>
  formatDate(date, customFormat, ISO_STANDART_DATE_TIME_FORMAT)

export const isExpired = date => {
  if (!date) return true

  const today = dayjs(new Date())
  const pastDate = dayjs(date)
  return pastDate.isBefore(today)
}

/***
 * Given a date, add to it x days
 */
export const addDays = (date, days) => {
  const dateFormatted = dayjs(date);
  // Adding days to the date
  return dateFormatted.add(days, 'day') // Adding x days to the date
}
