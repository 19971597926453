import { APP_MENU } from '../../../../../constants'
import { matchPath, useNavigate, useLocation } from 'react-router-dom'
import React from 'react'

export const useAppMenu = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [activeGroup, setActiveGroup] = React.useState(-1)

  React.useLayoutEffect(() => {
    setActiveGroup(-1)
  }, [pathname])

  const oneMatches = group => {
    return group.items.filter(item => {
      /* console.log(pathname, item)
      return matchPath(pathname, {
        path: item.path,
      })*/
      return true
    })
  }

  return {
    menu: React.useMemo(() => {
      return APP_MENU.map((group, idx) => {
        const groupIsActive =
          activeGroup > -1 ? activeGroup === idx : oneMatches(group)
        return {
          ...group,
          active: groupIsActive,
        }
      })
    }, [activeGroup, pathname]),
    setActive(idx) {
      setActiveGroup(idx)
    },
  }
}
