import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconDashboard = (props) => {
  const { width = 22, height = 22 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 22 22">
      <path d="M19 12c1.105 0 2 .895 2 2v5c0 1.105-.895 2-2 2h-5c-1.105 0-2-.895-2-2v-5c0-1.105.895-2 2-2zM8 12c1.105 0 2 .895 2 2v5c0 1.105-.895 2-2 2H3c-1.105 0-2-.895-2-2v-5c0-1.105.895-2 2-2zm11 2h-5v5h5v-5zM8 14H3v5h5v-5zM8 1c1.105 0 2 .895 2 2v5c0 1.105-.895 2-2 2H3c-1.105 0-2-.895-2-2V3c0-1.105.895-2 2-2zm11 0c1.105 0 2 .895 2 2v5c0 1.105-.895 2-2 2h-5c-1.105 0-2-.895-2-2V3c0-1.105.895-2 2-2zM8 3H3v5h5V3zm11 0h-5v5h5V3z" />
    </SvgIcon>
  );
};

IconDashboard.propTypes = simpleIconPropsTypes;
