import { Grid } from '@material-ui/core'
import Button from '../../../components/common/Buttons/Button'
import React from 'react'
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle'
import { ReactComponent as ArrowDropUpCircleIcon } from '../../../components/assests/ArrowDropUpCircle.svg'
import Typography from '../../../components/common/Typography'
import TextField from '@mui/material/TextField';
import { string } from 'yup'
import { textAlign } from '@mui/system'

const MAX_LICENSES = 99;
const MIN_LICENSES = 0;

function Counter({ currentLicenses, number, setNumber, setAlert }) {
  let incNum = () => {
    const validNumber = number < MAX_LICENSES ? Number(number) + 1 : MAX_LICENSES;
    setNumber(validNumber);
    if (currentLicenses >= validNumber) {
      setAlert(false)
    }
  }
  let decNum = () => {
    if (number > 0) {
      setNumber(number - 1)
      if (number <= currentLicenses) {
        setAlert(true)
      }
    }
  }

  let handelOnChangeNumber = (event) => {
    let newInputData = Number(event.currentTarget.value);
    if (newInputData != NaN) {
      if (newInputData <= MAX_LICENSES) {
        setNumber(newInputData);
      } else {
        setNumber(MAX_LICENSES);
      }
    }
  }

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Grid sm={4} xs={4}>
          <Button onClick={decNum}>
            <ArrowDropDownCircleIcon style={{ color: '2445A7' }} />
          </Button>
        </Grid>
        <Grid
          item
          sm={4}
          xs={4}
          style={{
            background: '#F1F3F9',
            border: '1px solid #2445A7',
            borderRadius: '4px',
            width: 75,
            padding: 0,
          }}
        >
          <TextField inputProps={{ min: 0, style: { textAlign: 'center' } }} id="outlined-basic" type="number" value={number} onChange={handelOnChangeNumber} />
        </Grid>
        <Grid sm={4} xs={4}>
          <Button onClick={incNum}>
            <ArrowDropUpCircleIcon style={{ color: '2445A7' }} />
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Counter
{
  /* <div className="col-xl-1">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <button className="btn btn-outline-primary" type="button" onClick={decNum}>-</button>
                    </div>
                    <input type="text" className="form-control" value={num} onChange={handleChange} />
                    <div className="input-group-prepend">
                        <button className="btn btn-outline-primary" type="button" onClick={incNum}>+</button>
                    </div>
                </div>
            </div> */
}
