import React from 'react'
import connect from '../../../connect'
import Typography from '../../../../../components/common/Typography'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { InlineContainer } from '../../../../../components/common/Containers'
import ItemMenu from './ItemMenu'
import Tooltip from '../../../../../components/common/Tooltip'
import InfoOutlined from '@material-ui/icons/InfoOutlined'

const Item = ({ cat, requestTemplateId }) => {
  return (
    <>
      <TableRow>
        <TableCell align="left">
          <InlineContainer style={{ alignItems: 'center', display: 'flex' }}>
            <Typography weight="500" variant="h6">
              {cat.name}

              <Tooltip
                placement="right"
                RefComponent="span"
                title={cat.description}
              >
                <InfoOutlined
                  style={{
                    marginLeft: '5px',
                    fontSize: '18px',
                    marginTop: '3px',
                    color: '#2445a7',
                  }}
                />
              </Tooltip>
            </Typography>
          </InlineContainer>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {cat.daysToFill ? cat.daysToFill : '--'}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <ItemMenu cat={cat} requestTemplateId={requestTemplateId} />
        </TableCell>
      </TableRow>
    </>
  )
}

export default connect(Item)
