import { useState } from 'react'
import Button from '../../../components/common/Buttons/Button'
import { IconDownload } from '../../../components/svg/Icons'

export default function DownloadButton({ item, disabled, onDownload, color }) {
  const [itemPending, setItemPending] = useState(false)
  const onClickDownload = () => {
    setItemPending(item.id)
    onDownload({ fileId: item.id, name: item.name }).then(() => {
      setItemPending(false)
    })
  }

  return (
    <Button
      disabled={disabled}
      onClick={onClickDownload}
      color={color ?? 'primary'}
      size="small"
      pending={itemPending === item.id}
    >
      <IconDownload />
    </Button>
  )
}
