import Grid from '@material-ui/core/Grid';
import React from 'react';
import SpacingContainer from '../../../components/common/Containers/Base/SpacingContainer';
//import { useMediaQueryDown } from '../../../hooks/useMediaQuery';
import usePopover from '../../../hooks/usePopover';
import { emptyArray } from '../../../utils/arrayUtils';
import { useSearchContext } from './context';
import FiltersMenu from './FiltersMenu';
import SearchField from './SearchField';
import SelectedFilterslist from './SelectedFilterslist';

const SearchTopPanel = ({ withActions = false }) => {
  const { state, onTextChange, setCurrent } = useSearchContext();
  const { text, fields } = state.filters || {};
  const filterOptions = state.filterOptions;

  //const isSmScreen = useMediaQueryDown('xs');
  const [filtersRef, onOpenFilters, onFiltersClose] = usePopover();

  const onSelectFilter = React.useCallback(
    (filter) => {
      if (!fields) return;

      const state = fields[filter.value];
      setCurrent(Object.assign({}, filter, { state }));
      return onFiltersClose();
    },
    [fields, setCurrent, onFiltersClose]
  );

  return (
    <>
      <SpacingContainer py={2}>
        <Grid container spacing={3} alignItems="center" justifyContent="space-between">
          <Grid item sm xs={12}>
            <SearchField
              value={text || ''}
              onValueChange={onTextChange}
              onOpenFilters={!emptyArray(filterOptions) ? onOpenFilters : undefined}
            />
            <FiltersMenu anchorEl={filtersRef} onClose={onFiltersClose} onSelectFilter={onSelectFilter} />
          </Grid>
        </Grid>
      </SpacingContainer>

      <SelectedFilterslist onSelectFilter={onSelectFilter} />
    </>
  );
};

export default SearchTopPanel;
