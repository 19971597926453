import * as React from 'react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

import connect from '../../connect'
import InfoIcon from '@material-ui/icons/InfoOutlined'

const ShowTooltip = ({ tooltips, sectionToShow }) => {
  const getMessage = () => {
    const message = tooltips.find(i => i.section === sectionToShow)
    return message?.message
  }

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }))

  return (
    <>
      {getMessage() && (
        <BootstrapTooltip title={getMessage()} placement="top" arrow>
          <InfoIcon
            style={{ color: '#ccc', fontSize: '20px', marginLeft: '6px' }}
          />
        </BootstrapTooltip>
      )}
    </>
  )
}

export default connect(ShowTooltip)
