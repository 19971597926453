import { fade, Grid } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { Button } from "../../../../components/common/Buttons";
import { Paper, SpacingContainer } from "../../../../components/common/Containers";
import { ReactComponent as PreviewTopBar } from './images/previewTopBar.svg'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ExploreIcon from '@mui/icons-material/Explore';
import BusinessIcon from '@mui/icons-material/Business';
import SchoolIcon from '@mui/icons-material/School';
import { emptyArray } from "../../../../utils/arrayUtils";
import { ReactComponent as PortalLogo } from '../../../../components/svg/logos/portalLogo.svg'
import Brightness1Icon from '@mui/icons-material/Brightness1';
const PreviewMenu = ({ firstColor, secondColor, thirdColor, fourthColor, fifthColor, linkFile }) => {
    return (
        <React.Fragment>
            <Paper
                bgColor="paperSecondary"
                component={SpacingContainer}
                px={2}
                py={2}
                style={{ width: '520px', height: '380px' }}
            >
                <Grid
                    container
                    direction="column"
                >
                    <Grid item>
                        <PreviewTopBar />
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            style={{ marginLeft: '3px' }}
                        >
                            <Grid item xs={5}>
                                <Grid
                                    container
                                    direction="column"
                                    spacing={1}
                                    style={{ background: fifthColor }}
                                >
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="column"
                                            spacing={1}
                                        >
                                            <Grid item>
                                                {linkFile==undefined ?
                                                    <PortalLogo style={{ width: '150px', height: '35px' }} />
                                                    :
                                                    <img key={linkFile} src={linkFile} style={{ minWidth: '150px', maxWidth: '150px', minHeight: '35px', maxHeight: '35px' }} />
                                                }
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <Button item style={{ background: secondColor, borderRadius: '15px', width: '13px' }}>
                                                    <BusinessCenterIcon style={{ color: fifthColor }} />
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Brightness1Icon style={{ color: thirdColor, width: '15px', height: '15px', marginTop: '5px' }} />
                                            </Grid>
                                            <Grid item style={{ background: fourthColor, height: '15px', width: '100px', borderRadius: '10px' }} />
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <Button item style={{ backgroundColor: fade(firstColor, 0.1), borderRadius: '15px', width: '13px' }}>
                                                    <ExploreIcon style={{ color: firstColor }} />
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Brightness1Icon style={{ color: thirdColor, width: '15px', height: '15px', marginTop: '5px' }} />
                                            </Grid>
                                            <Grid item style={{ background: fourthColor, height: '15px', width: '100px', borderRadius: '10px' }} />
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <Button item style={{ backgroundColor: fade(firstColor, 0.1), borderRadius: '15px', width: '13px' }}>
                                                    <BusinessIcon style={{ color: firstColor }} />
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Brightness1Icon style={{ color: thirdColor, width: '15px', height: '15px', marginTop: '5px' }} />
                                            </Grid>
                                            <Grid item style={{ background: fourthColor, height: '15px', width: '100px', borderRadius: '10px' }} />
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <Button item style={{ backgroundColor: fade(firstColor, 0.1), borderRadius: '15px', width: '13px' }}>
                                                    <SchoolIcon style={{ color: firstColor }} />
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Brightness1Icon style={{ color: thirdColor, width: '15px', height: '15px', marginTop: '5px' }} />
                                            </Grid>
                                            <Grid item style={{ background: fourthColor, height: '15px', width: '100px', borderRadius: '10px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: "15px" }}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    style={{ background: firstColor, color: "#ffffff" }}
                                >
                                    Main action
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    style={{ color: firstColor }}
                                >
                                    Secondary action
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Paper>
        </React.Fragment>
    )
}
export default PreviewMenu
