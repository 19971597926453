import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import { Grid, Box, FormControlLabel, Switch } from '@material-ui/core'
import React, { useState, useEffect, useCallback } from 'react'
import Typography from '../../../../components/common/Typography'
import Button from '../../../../components/common/Buttons/Button'

import connect from '../../connect'
import { useAlertContext } from '../../../../contexts/AlertContext'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import { CheckBox } from '../../../../components/common/Inputs'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const QuoteSettigns = ({
  acceptQuoteRequests,
  acceptGrantQuoteRequests,
  acceptStateLocalQuoteRequests,
  updateAcceptQuoteRequests,
  coForQuoteRequests,
}) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const permissions = useSelector(selectUserPermissions)

  const [isSend, setIsSend] = React.useState(false)
  const [quoteRequestData, setQuoteRequestData] = useState({
    acceptQuoteRequests: false,
    acceptGrantQuoteRequests: false,
    acceptStateLocalQuoteRequests: false,
  })
  const [coForQuotes, setCoForQuotes] = React.useState([
    { name: 'Combined Synopsis/Solicitation', checked: false },
    { name: 'Modification/Amendment', checked: false },
    { name: 'Presolicitation', checked: false },
    { name: 'Solicitation', checked: false },
  ])

  const saveSettings = useCallback(() => {
    setIsSend(true)
    updateAcceptQuoteRequests({
      acceptQuoteRequests: quoteRequestData.acceptQuoteRequests,
      acceptGrantQuoteRequests: quoteRequestData.acceptGrantQuoteRequests,
      acceptStateLocalQuoteRequests: quoteRequestData.acceptStateLocalQuoteRequests,
      coForQuotes: coForQuotes,
    })
      .then(res => {
        if (res.payload.success) {
          setIsSend(false)
          successAlert('Quote settings updated!')
        } else {
          setIsSend(false)
          errorAlert('Error updating quote settings!')
        }
      })
      .catch(() => {
        setIsSend(false)
        errorAlert('Error updating quote settings!')
      })
  }, [
    updateAcceptQuoteRequests,
    quoteRequestData,
    coForQuotes,
    successAlert,
    errorAlert,
  ])

  const onChecked = useCallback(
    value => {
      setCoForQuotes(
        coForQuotes.map(item => {
          if (item.name === value) {
            return {
              ...item,
              checked: !item.checked,
            }
          }
          return item
        })
      )
    },
    [coForQuotes]
  )

  useEffect(() => {
    setQuoteRequestData({
      acceptQuoteRequests: acceptQuoteRequests || false,
      acceptGrantQuoteRequests: acceptGrantQuoteRequests || false,
      acceptStateLocalQuoteRequests: acceptStateLocalQuoteRequests || false,
    })
  }, [acceptQuoteRequests, acceptGrantQuoteRequests, acceptStateLocalQuoteRequests])

  useEffect(() => {
    if (coForQuoteRequests) {
      setCoForQuotes(
        coForQuotes.map(item => {
          if (coForQuoteRequests.some(i => i.name === item.name && i.checked)) {
            return {
              ...item,
              checked: true,
            }
          }

          return item
        })
      )
    }
  }, [coForQuoteRequests])

  return (
    <Paper component={SpacingContainer} py={3} px="5sm3">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs zeroMinWidth>
          <Typography
            style={{
              paddingBottom: '1rem',
              alignItems: 'center',
              display: 'flex',
            }}
            variant="h4"
            weight="500"
            fontFamily="lexend"
          >
            <AttachMoneyIcon /> {trans('quote-settings')}{' '}
            <ShowTooltip sectionToShow="quote-settings" />
          </Typography>
        </Grid>
      </Grid>

      <Box
        style={{
          borderTop: `1px solid rgba(78, 89, 120, 0.12)`,
          padding: '2rem 0 2rem 0',
        }}
      >
        <Grid container direction="row" justifyContent="flex-start">
          <Grid item sm={4} xs={12}>
            <Grid container>
              <Grid item sm={12} xs={12}>
                <Typography weight={800} style={{ marginLeft: '.5rem' }}>
                  {trans('contract-opportunities')}
                </Typography>
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormControlLabel
                  value="end"
                  style={{ marginLeft: '.5rem' }}
                  control={
                    <Switch
                      color="primary"
                      checked={quoteRequestData.acceptQuoteRequests}
                      onChange={event => {
                        setQuoteRequestData({
                          ...quoteRequestData,
                          acceptQuoteRequests: event.target.checked,
                        })
                      }}
                    />
                  }
                  label={
                    <Typography weight={600} variant="body2">
                      {trans('turn-ability-to-accept-quotes')}
                    </Typography>
                  }
                  labelPlacement="start"
                />

                <div style={{ marginTop: '.25rem' }}>
                  {coForQuotes.map(co => (
                    <div>
                      <label className="mr-2">
                        <CheckBox
                          color="secondary"
                          checked={co.checked}
                          onChange={() => onChecked(co.name)}
                          name="opportunityTypesForQuoteRequests"
                        />
                        {co.name}
                      </label>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>


          <Grid item sm={4} xs={12}>
            <Grid container>
              <Grid item sm={12} xs={12}>
                <Typography weight={800} style={{ marginLeft: '.5rem' }}>
                  {trans('grant-opportunities')}
                </Typography>
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormControlLabel
                  value="end"
                  style={{ marginLeft: '.5rem' }}
                  control={
                    <Switch
                      color="primary"
                      checked={quoteRequestData.acceptGrantQuoteRequests}
                      onChange={event => {
                        setQuoteRequestData({
                          ...quoteRequestData,
                          acceptGrantQuoteRequests: event.target.checked,
                        })
                      }}
                    />
                  }
                  label={
                    <Typography weight={600} variant="body2">
                      {trans('turn-ability-to-accept-quotes')}
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}></Grid>
          </Grid>

          <Grid item sm={4} xs={12}>
            <Grid container>
              <Grid item sm={12} xs={12}>
                <Typography weight={800} style={{ marginLeft: '.5rem' }}>
                  {trans('state-local-contract-opportunities')}
                </Typography>
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormControlLabel
                  value="end"
                  style={{ marginLeft: '.5rem' }}
                  control={
                    <Switch
                      color="primary"
                      checked={quoteRequestData.acceptStateLocalQuoteRequests}
                      onChange={event => {
                        setQuoteRequestData({
                          ...quoteRequestData,
                          acceptStateLocalQuoteRequests: event.target.checked,
                        })
                      }}
                    />
                  }
                  label={
                    <Typography weight={600} variant="body2">
                      {trans('turn-ability-to-accept-quotes')}
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}></Grid>
          </Grid>


        </Grid>
      </Box>
      {isEnabled(permissions, 'global-settings', '', 'edit') && (
        <Box
          style={{
            borderTop: `1px solid rgba(78, 89, 120, 0.12)`,
            padding: '2rem 0 0.5rem 0',
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs zeroMinWidth>
              <Button
                variant="contained"
                style={{ background: '#17b471', color: '#fff' }}
                onClick={() => saveSettings()}
                pending={isSend}
              >
                {' '}
                Save Settings
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Paper>
  )
}
export default connect(QuoteSettigns)
