import makeStyles from '@material-ui/core/styles/makeStyles';
import MUITextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import React from 'react';
import Typography from '../../Typography';
import { PropTypes } from 'prop-types';

const useStyles = makeStyles({
  bigInput: {
    '& input': { paddingTop: 21, paddingBottom: 22 },
  },
});

export default function TextField(props) {
  const {
    InputProps: _InputProps,
    inputProps: _inputProps,
    error,
    maxLength,
    label = null,
    fullWidth = true,
    disableBrowserAutoComplete = true,
    big = false,
    className,
    id = '',
    name = '',
    bgColor = '',
    align = '',
    type = 'text',
    ...rest
  } = props;
  const InputProps = _InputProps || {};
  const inputProps = _inputProps || {};
  const styles = useStyles();
  InputProps.disableUnderline = true;
  if (disableBrowserAutoComplete) inputProps.autoComplete = 'off';
  if (typeof maxLength === 'number') inputProps.maxLength = maxLength;

  return (
    <>
      {label && (
        <Typography weight="500" fontFamily="lexend" color="label" gutter="label" variant="body2">
          {label}
        </Typography>
      )}
      <MUITextField
        id={id || `input-${name}`}
        name={name}
        InputProps={InputProps}
        inputProps={inputProps}
        variant="filled"
        helperText={error}
        error={Boolean(error)}
        hiddenLabel={!(error || label)}
        fullWidth={fullWidth}
        autoComplete={'off'}
        type={type}
        className={clsx(
          className,
          big && styles.bigInput,
          bgColor && `textfield-${bgColor}`,
          align && `textfield-${align}`
        )}
        {...rest}
      />
    </>
  );
}

TextField.propTypes = {
  ...MUITextField.propTypes,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  error: PropTypes.string,
  maxLength: PropTypes.number,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  disableBrowserAutoComplete: PropTypes.bool,
  big: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  bgColor: PropTypes.string,
  align: PropTypes.string,
  type: PropTypes.string,
};