import { createAsyncThunk, createAction, createReducer } from '@reduxjs/toolkit'
import { API, SCOPE_SAVED_SEARCHES } from '../../constants'
import { application } from '../../services/application'
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common'
import {
  extendListBuilder,
  generateListActions,
} from '../helpers/listActionsHelpers'
import { extendBuilderWithAsyncAction } from '../helpers/sharedCases'

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['createdAt', 'desc'],
  savedSearches: null,
  userDetail: null,
  isAllCheckedSavedSearches: false,
  isSomeCheckedSavedSearches: false,
  savedSearchesSelected: [],
  savedSearchesUnselected: [],
  savedSearchStats: {},
}

const listActions = generateListActions({
  scope: SCOPE_SAVED_SEARCHES,
  apiMethod: {
    GET_LIST: API.SAVED_SEARCHES.GET,
  },
  getStore: store => store.registeredUserDetail,
})

const saveSearchAction = createAsyncThunk(
  `${SCOPE_SAVED_SEARCHES}/saveSearch`,
  data => {
    const {
      id,
      searchSavedOwner,
      title,
      notification,
      notificationFrequency,
      notificationNumber,
    } = data
    return application.call(API.SAVED_SEARCHES.UPDATE, {
      id,
      searchSavedOwner,
      title,
      notification,
      notificationFrequency,
      notificationNumber,
    })
  }
)

/***
 * Deletes a specific saved search
 */
const deleteSearchAction = createAsyncThunk(
  `${SCOPE_SAVED_SEARCHES}/deleteSearch`,
  ({ id, savedSearchOwner }) => {
    return application.call(API.SAVED_SEARCHES.DELETE, { id, savedSearchOwner })
  }
)

/***
 * Store data on redux after a delete action
 */
const saveDataAfterDeleteSearchAction = createAction(
  `${SCOPE_SAVED_SEARCHES}/saveDataAfterDeleteSearch`
)
function onSaveDataAfterDeleteSearch(state, action) {
  // Remove item in case it was in selected or unselected array
  Object.assign(state, {
    savedSearchesSelected: [
      ...state.savedSearchesSelected.filter(s => s !== action.payload.id),
    ],
    savedSearchesUnselected: [
      ...state.savedSearchesUnselected.filter(s => s !== action.payload.id),
    ],
    savedSearchStats: {
      ...state.savedSearchStats,
      savedSearchCount: state.savedSearchStats.savedSearchCount - 1,
    },
  })
}

/***
 * Deletes one or more saved searches
 */
const deleteBulkSearchAction = createAsyncThunk(
  `${SCOPE_SAVED_SEARCHES}/deleteBulkSearch`,
  ({ ids, type, savedSearchOwner }) => {
    return application.call(API.SAVED_SEARCHES.DELETE_BULK, {
      ids,
      type,
      savedSearchOwner,
    })
  }
)

/***
 * Store data on redux after a delete bulk action
 */
const saveDataAfterDeleteBulkSearchAction = createAction(
  `${SCOPE_SAVED_SEARCHES}/saveDataAfterDeleteBulkSearch`
)
function onSaveDataAfterDeleteBulkSearch(state, action) {
  // Remove item in case it was in selected or unselected array
  Object.assign(state, {
    isAllCheckedSavedSearches: false,
    isSomeCheckedSavedSearches: false,
    savedSearchesSelected: [],
    savedSearchesUnselected: [],
    savedSearchStats: {
      ...state.savedSearchStats,
      savedSearchCount:
        state.savedSearchStats.savedSearchCount - action.payload.ids.length,
    },
  })
}

const findOneSearchAction = createAsyncThunk(
  `${SCOPE_SAVED_SEARCHES}/findOneSearch`,
  params => {
    return application.call(API.SAVED_SEARCHES.FIND_ONE, params)
  }
)

const turnOnOffNotificationsAction = createAsyncThunk(
  `${SCOPE_SAVED_SEARCHES}/turnOnOffNotifications`,
  ({
    savedSearchOwner,
    pursuitNotices,
    savedSearchNotices,
    teamUpdates,
    savedSearchTypeNotices,
    consolidateNotificationSelect,
    consolidateNotificationNumber,
    taskReminders,
    onEmailNotifications,
  }) => {
    return application.call(API.USERS.SET_NOTIFICATION_SETTINGS, {
      savedSearchOwner,
      pursuitNotices,
      savedSearchNotices,
      teamUpdates,
      savedSearchTypeNotices,
      consolidateNotificationSelect,
      consolidateNotificationNumber,
      taskReminders,
      onEmailNotifications,
    })
  }
)

const toggleWatchAction = createAsyncThunk(
  `${SCOPE_SAVED_SEARCHES}/toggleWatch`,
  ({ id, savedSearchOwner }) => {
    return application.call(API.SAVED_SEARCHES.TOGGLE_WATCH, {
      id,
      savedSearchOwner,
    })
  }
)

/***
 * Get info from registered user selected
 */
const findOne = createAsyncThunk('registeredUsers/findOne', params => {
  return application.call(API.USERS.FIND_ONE, params)
})
function onFindOne(state, action) {
  Object.assign(state, {
    userDetail: action.payload,
  })
}

/***
 * In charge of add all items on selected array
 */
const addAllSavedSearchesAction = createAction(
  `${SCOPE_SAVED_SEARCHES}/addAllSavedSearchesAction`
)
function onaAddAllSavedSearches(state, action) {
  console.log('onaAdAllSavedSearchesAction')
  // Clean
  Object.assign(state, {
    savedSearchesSelected: [],
    savedSearchesUnselected: [],
  })
  return Object.assign(state, {
    isAllCheckedSavedSearches: true,
    isSomeCheckedSavedSearches: false,
    savedSearchesSelected: state.items.map(item => item.id),
    savedSearchesUnselected: [],
  })
}

/***
 * In charge of add all items on selected array
 */
const removeAllSavedSearchesAction = createAction(
  `${SCOPE_SAVED_SEARCHES}/removeAllSavedSearchesAction`
)
function onRemoveAllSavedSearches(state, action) {
  console.log('onRemoveAllSavedSearchesAction')
  return Object.assign(state, {
    isAllCheckedSavedSearches: false,
    isSomeCheckedSavedSearches: false,
    savedSearchesSelected: [],
    savedSearchesUnselected: [],
  })
}

/***
 * When page or order change, add remaining items, except those in unSelected array
 */
const addRemainingSavedSearchesAction = createAction(
  `${SCOPE_SAVED_SEARCHES}/addRemainingSavedSearches`
)
function onAddRemainingSavedSearches(state, action) {
  console.log('addRemainingSavedSearchesAction')
  if (state.isAllCheckedSavedSearches) {
    // Get items ids
    const itemsIds = state.items.map(item => item.id)
    // Get only items that are not in savedSearchesUnselected nor savedSearchesSelected
    const filteredItemsIds = itemsIds.filter(itemId => {
      if (
        !state.savedSearchesUnselected.includes(itemId) &&
        !state.savedSearchesSelected.includes(itemId)
      ) {
        return itemId
      }
    })

    return Object.assign(state, {
      savedSearchesSelected: [
        ...state.savedSearchesSelected,
        ...filteredItemsIds,
      ],
    })
  }
}

/***
 * Add a single item to savedSearchesSelected array and remove it from savedSearchesUnselected
 */
const addOneSavedSearchesAction = createAction(
  `${SCOPE_SAVED_SEARCHES}/addOneSavedSearches`
)
function onAddOneSavedSearches(state, action) {
  console.log('addOneSavedSearchesAction')
  // Get how many unselected items
  const countUnslected = [
    ...state.savedSearchesUnselected.filter(s => s !== action.payload.id),
  ]
  return Object.assign(state, {
    savedSearchesSelected: [...state.savedSearchesSelected, action.payload.id],
    savedSearchesUnselected: [...countUnslected],
    isSomeCheckedSavedSearches: countUnslected.length === 0 ? false : true,
  })
}

/***
 * Remove a single item to savedSearchesSelected array and add it to savedSearchesUnselected
 */
const removeOneSavedSearchesAction = createAction(
  `${SCOPE_SAVED_SEARCHES}/removeOneSavedSearches`
)
function onRemoveOneSavedSearches(state, action) {
  console.log('removeOneSavedSearchesAction')
  if (state.isAllCheckedSavedSearches) {
    const countSelected = [
      ...state.savedSearchesSelected.filter(s => s !== action.payload.id),
    ]
    const countUnselected = [
      ...state.savedSearchesUnselected,
      action.payload.id,
    ]
    const isAllUnselected = countUnselected.length !== state.count
    return Object.assign(state, {
      isAllCheckedSavedSearches: isAllUnselected ? true : false,
      isSomeCheckedSavedSearches: isAllUnselected ? true : false,
      savedSearchesSelected: [...countSelected],
      savedSearchesUnselected: isAllUnselected ? [...countUnselected] : [],
    })
  } else {
    return Object.assign(state, {
      savedSearchesSelected: [
        ...state.savedSearchesSelected.filter(s => s !== action.payload.id),
      ],
    })
  }
}

/***
 * Store data on redux after a delete bulk action
 */
const restartStateAction = createAction(`${SCOPE_SAVED_SEARCHES}/restartState`)
function onRestartState(state, action) {
  // Restart the data in case the user look for another registered user
  return Object.assign(state, {
    isAllCheckedSavedSearches: false,
    isSomeCheckedSavedSearches: false,
    savedSearchesSelected: [],
    savedSearchesUnselected: [],
  })
}

const getSavedSearchStats = createAsyncThunk(
  `${SCOPE_SAVED_SEARCHES}/getSavedSearchStats`,
  params => {
    return application.call(API.SAVED_SEARCHES.GET_STATS, params)
  }
)
const onGetSavedSearchStats = (state, action) => {
  return Object.assign(state, {
    savedSearchStats: action.payload,
    pending: false,
  })
}

export const actions = {
  ...listActions,
  saveSearchAction,
  deleteSearchAction,
  deleteBulkSearchAction,
  toggleWatchAction,
  findOneSearchAction,
  findOne,
  turnOnOffNotificationsAction,
  addAllSavedSearchesAction,
  removeAllSavedSearchesAction,
  addRemainingSavedSearchesAction,
  addOneSavedSearchesAction,
  removeOneSavedSearchesAction,
  saveDataAfterDeleteSearchAction,
  saveDataAfterDeleteBulkSearchAction,
  restartStateAction,
  getSavedSearchStats,
}

export default createReducer(INITIAL_STATE, builder => {
  extendListBuilder(builder, actions)
  extendBuilderWithAsyncAction(builder, saveSearchAction)
  extendBuilderWithAsyncAction(builder, deleteSearchAction)
  extendBuilderWithAsyncAction(builder, deleteBulkSearchAction)
  extendBuilderWithAsyncAction(builder, toggleWatchAction)
  extendBuilderWithAsyncAction(builder, turnOnOffNotificationsAction)
  extendBuilderWithAsyncAction(builder, getSavedSearchStats, {
    onSuccess: onGetSavedSearchStats,
  })
  return builder
    .addCase(findOne.fulfilled, onFindOne)
    .addCase(addAllSavedSearchesAction, onaAddAllSavedSearches)
    .addCase(removeAllSavedSearchesAction, onRemoveAllSavedSearches)
    .addCase(addRemainingSavedSearchesAction, onAddRemainingSavedSearches)
    .addCase(addOneSavedSearchesAction, onAddOneSavedSearches)
    .addCase(removeOneSavedSearchesAction, onRemoveOneSavedSearches)
    .addCase(saveDataAfterDeleteSearchAction, onSaveDataAfterDeleteSearch)
    .addCase(
      saveDataAfterDeleteBulkSearchAction,
      onSaveDataAfterDeleteBulkSearch
    )
    .addCase(restartStateAction, onRestartState)
})
