import React from 'react';
import { SvgIcon } from '../../common/SvgIcon';
import { simpleIconPropsTypes } from '../propTypes';

export const IconCheckBox = (props) => {
  const { checked = false, indeterminate = false, width = 24, height = 24, fill = "#FFF" } = props;

  return (
    <SvgIcon viewBox="0 0 24 24" width={width} height={height}>
      {checked ? (
        <>
          <path
            d="M22 0c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2H2c-1.105 0-2-.895-2-2V2C0 .895.895 0 2 0h20zm-4.247 8.342c-.363-.416-.994-.459-1.41-.095l-6.219 5.425-2.466-2.153-.1-.076c-.412-.278-.976-.212-1.311.172-.364.416-.32 1.048.095 1.411l3.125 2.727.098.076c.372.25.872.226 1.218-.076l6.875-6 .089-.088c.33-.37.342-.938.006-1.323z"
            transform="translate(-28.000000, -247.000000) translate(0.000000, 162.000000) translate(0.000000, 65.000000) translate(28.000000, 20.000000)"
          />
          <path
            fill={fill}
            d="M16.342 8.247c.417-.364 1.048-.32 1.411.095.336.385.325.952-.006 1.323l-.09.088-6.874 6c-.346.302-.846.327-1.218.076l-.098-.076-3.125-2.727c-.416-.363-.459-.995-.095-1.41.335-.385.899-.451 1.31-.173l.1.076 2.467 2.153 6.218-5.425z"
          />
        </>
      ) : indeterminate ? (
        <>
          <path
            d="M22 0c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2H2c-1.105 0-2-.895-2-2V2C0 .895.895 0 2 0h20zm-5 11H7c-.552 0-1 .448-1 1s.448 1 1 1h10c.552 0 1-.448 1-1s-.448-1-1-1z"
            transform="translate(-28.000000, -109.000000) translate(0.000000, 81.000000) translate(28.000000, 20.000000) translate(0.000000, 8.000000)"
          />
          <rect width="12" height="2" x="6" y="11" fill={fill} rx="1" />
        </>
      ) : (
        <g fill={fill} stroke="#000" strokeOpacity=".1">
          <rect width="23" height="23" x=".5" y=".5" rx="2" />
        </g>
      )}
    </SvgIcon>
  );
};

IconCheckBox.propTypes = simpleIconPropsTypes;
