import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  searchCover: {
    marginTop: 33,
    marginBottom: 10,
  },
  searchInput: {
    '& .MuiFilledInput-root': {
      borderRadius: '16px !important',
      border: `1px solid transparent`,
      margin: 0,
      boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.04)',
      '& .MuiIconButton-root': {
        color: theme.palette.gray.main,
      },
      '& .MuiFilledInput-inputAdornedStart': {
        paddingLeft: '0 !important',
      },
      '& .MuiIconButton-root.primary ': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.input,
        boxShadow: theme.shadows[3],
      },
      '& .MuiIconButton-root.primary:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '& .MuiIconButton-root + .MuiIconButton-root': {
        marginLeft: theme.spacing(1),
      },
    },
  },
  searchInputLight: {
    '& > .MuiFilledInput-root': {
      margin: 0,
      '& .MuiIconButton-root': {
        color: theme.palette.gray.main,
      },
      '& > .MuiFilledInput-inputAdornedStart': {
        paddingLeft: '0 !important',
      },
    },
  },
  searchInputFocused: {
    '& .MuiFilledInput-root': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  dropDownBtn: {
    width: 65,
    height: 64,
    borderRadius: 16,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    borderLeftColor: 'rgba(0, 0, 0, 0.1)',
  },
  listDropDownBtn: {
    height: 64,
    borderRadius: 16,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    color: theme.palette.gray.active,
  },

  qvSearch: {
    height: '100%',
    '& > *:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.gray.background}`,
    },
  },

  action: {
    minWidth: 80,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      minWidth: 60,
    },
  },
}));
