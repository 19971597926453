import { useState } from 'react'
import Typography from '../../../../components/common/Typography'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { InlineContainer } from '../../../../components/common/Containers'
import { RouterLink } from '../../../../components/common/Buttons'
import { IconButton } from '@material-ui/core'
import ConfirmDialog from '../../../../components/generics/Confirm/Dialog'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import { useTrans } from '../../../../services/i18n'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useAlertContext } from '../../../../contexts/AlertContext'
import connect from '../../connect'
import { useNavigate } from 'react-router-dom'
import { GolfCourse } from '@material-ui/icons'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const Item = ({
  form,
  defaultSAPRequests,
  changeCurrentFormDetail,
  getDefaultSAPRequests,
  saveDefaultSAPRequests,
}) => {
  const [openRemove, onOpenRemove, onCloseRemove] = useBooleanHandlers()
  const [pendingRemove, setPendingRemove] = useState(false)
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const permissions = useSelector(selectUserPermissions)

  const navigate = useNavigate()

  const { trans } = useTrans()
  const onDeleteTemplate = () => {
    setPendingRemove(true)
    const newSettings = defaultSAPRequests.map(i => {
      if (i.id !== form.id) return i
      else return { ...i, checked: false }
    })

    saveDefaultSAPRequests(
      newSettings.filter(i => {
        return i.checked === true
      })
    )
      .then(() => {
        setPendingRemove(false)
        successAlert('Default SAP  forms updated!')
        onCloseRemove()
        getDefaultSAPRequests()
      })
      .catch(() => {
        errorAlert('Error')
      })
  }

  const showDetail = () => {
    changeCurrentFormDetail(form)
    navigate(`/request-template/${form.id}/${form.name}`)
  }

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <InlineContainer spacing={1}>
            <Typography
              weight="500"
              onClick={() => showDetail()}
              variant="h6"
              component={RouterLink}
              noWrap
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {form.name}
            </Typography>
          </InlineContainer>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {form.filesCount}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {form.formsCount}
          </Typography>
        </TableCell>
        {isEnabled(permissions, 'global-settings', '', 'delete') && (
          <TableCell align="right">
            <IconButton onClick={() => onOpenRemove()}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <ConfirmDialog
        open={openRemove}
        onClose={onCloseRemove}
        onConfirm={onDeleteTemplate}
        pending={pendingRemove}
        title={trans('remove-form')}
        text={`Are you sure you want to remove this form`}
      >
        <Typography color="hint">{form.name}</Typography>
      </ConfirmDialog>
    </>
  )
}

export default connect(Item)
