import React from 'react'
import { SvgIcon } from '../../common/SvgIcon'
import { simpleIconPropsTypes } from '../propTypes'

export const IconThumbUp = props => {
  const { width = 22, height = 20, fill = '#B2B8CF' } = props

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 20H4V8H0V20ZM22 9C22 7.9 21.1 7 20 7H13.69L14.64 2.43L14.67 2.11C14.67 1.7 14.5 1.32 14.23 1.05L13.17 0L6.59 6.59C6.22 6.95 6 7.45 6 8V18C6 19.1 6.9 20 8 20H17C17.83 20 18.54 19.5 18.84 18.78L21.86 11.73C21.95 11.5 22 11.26 22 11V9Z"
        fill={fill}
      />
    </SvgIcon>
  )
}

IconThumbUp.propTypes = simpleIconPropsTypes
