import React from 'react'
import { SvgIcon } from '../../common/SvgIcon'
import { simpleIconPropsTypes } from '../propTypes'

export const IconThumbDown = props => {
  const { width = 22, height = 20, fill = '#B2B8CF' } = props

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 0H18V12H22V0ZM0 11C0 12.1 0.9 13 2 13H8.31L7.36 17.57L7.33 17.89C7.33 18.3 7.5 18.68 7.77 18.95L8.83 20L15.41 13.41C15.78 13.05 16 12.55 16 12V2C16 0.9 15.1 0 14 0H5C4.17 0 3.46 0.499999 3.16 1.22L0.14 8.27C0.0500003 8.5 0 8.74 0 9V11Z"
        fill={fill}
      />
    </SvgIcon>
  )
}

IconThumbDown.propTypes = simpleIconPropsTypes
