export * from './routes'
export * as ROUTES from './routes'
export * as API from './api'

export const AUTH_NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'
export const AUTH_NOMFA = 'NOMFA'
export const AUTH_SMS_MFA = 'SMS_MFA'
export const AUTH_SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA'
export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_PRETTY_FORMAT = 'MMM D, YYYY'
export const DATE_TIME_PRETTY_FORMAT = 'MMM D, YYYY h:mm  A'
export const DATE_TIME_FORMAT = 'MM/DD/YY, h:mm  A'
export const ISO_STANDART_DATE_TIME_FORMAT = 'YYYY-MM-DDThh:mm:ss±zz'
export const SCOPE_NOTIFICATIONS = 'NOTIFICATIONS'
export const SCOPE_CLIENTS = 'CLIENTS'
export const SCOPE_USERS = 'USERS'
export const SCOPE_SAVED_SEARCHES = 'SAVED_SEARCHES'
export const SCOPE_USFCR_STAFF = 'USFCR_STAFF'
export const SCOPE_RELEASES = 'RELEASES'
export const SCOPE_REQUESTED_QUOTES = 'REQUESTED_QUOTES'
export const SCOPE_REQUEST_TEMPLATES = 'REQUEST_TEMPLATES'
export const SCOPE_REQUEST_TEMPLATE_FILES = 'REQUEST_TEMPLATE_FILES'
export const SCOPE_REQUEST_TEMPLATE_FORMS = 'REQUEST_TEMPLATE_FORMS'
export const SCOPE_PENDING_ORG_AVATAR = 'PENDING_ORG_AVATAR'

export const PORTAL_SECTIONS = [
  {
    key: 'workplace',
    title: 'Workplace',
  },
  {
    key: 'discovery',
    title: 'Discovery',
  },
  {
    key: 'business',
    title: 'Client Portal',
  },
  {
    key: 'knowledge',
    title: 'Knowledge',
  },
]

export const PORTAL_VIEWS = [
  {
    key: 'dashboard',
    section: 'workplace',
    title: 'Dashboard',
  },
  {
    key: 'pipelines',
    section: 'workplace',
    title: 'Pipelines',
  },
  {
    key: 'tasks',
    section: 'workplace',
    title: 'Tasks',
  },
  {
    key: 'saved-searches',
    section: 'workplace',
    title: 'Saved Searches',
  },
  {
    key: 'teaming-opportunities',
    section: 'workplace',
    title: 'Teaming Opportunities',
  },
  {
    key: 'pursuits',
    section: 'workplace',
    title: 'Pursuits',
  },
  {
    key: 'vendors-management',
    section: 'workplace',
    title: 'Vendors Management',
  },
  {
    key: 'vendors',
    section: 'discovery',
    title: 'Vendors',
  },
  {
    key: 'fco',
    section: 'discovery',
    title: 'Contract Opportunities',
  },
  {
    key: 'fgo',
    section: 'discovery',
    title: 'Grant Opportunities',
  },
  {
    key: 'nsn',
    section: 'discovery',
    title: 'NSN Opportunities',
  },
  {
    key: 'awards',
    section: 'discovery',
    title: 'Contract Awards',
  },
  {
    key: 'federal-agents',
    section: 'discovery',
    title: 'Federal Agents',
  },
  {
    key: 'federal-agencies',
    section: 'discovery',
    title: 'Federal Agencies',
  },
  {
    key: 'government-files',
    section: 'discovery',
    title: 'Government Files',
  },
  {
    key: 'overview',
    section: 'business',
    title: 'Overview',
  },
  {
    key: 'forms',
    section: 'business',
    title: 'Forms',
  },
  {
    key: 'documents',
    section: 'business',
    title: 'Documents',
  },
  {
    key: 'tickets',
    section: 'business',
    title: 'Support Tickets',
  },
  {
    key: 'code-categories',
    section: 'knowledge',
    title: 'Code Categories',
  },
  {
    key: 'learning-center',
    section: 'knowledge',
    title: 'Learning Center',
  },
  {
    key: 'releases',
    section: 'knowledge',
    title: 'Releases',
  },
]
