import { useEffect, useState } from 'react'
import { useTrans } from '../../../../services/i18n'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Grid } from '@material-ui/core'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import Typography from '../../../../components/common/Typography'
import Item from './item'
import connect from '../connect'
import { emptyArray } from '../../../../utils/arrayUtils'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'
import { Divider } from '../../../../components/common/Divider'
import { ROWS_PER_PAGE_OPTIONS } from '../../../../hooks/usePagination'
import PaginationSmall from '../../../../components/common/Paginations/PaginationSmall'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import Button from '../../../../components/common/Buttons/Button'
import usePopover from '../../../../hooks/usePopover'
import {
  ContextMenuListItem,
  Popover,
} from '../../../../components/common/Dialogs/Popover'
import ListItemTextBase from '../../../../components/common/Containers/Base/ListItemTextBase'
import { LoaderSmall } from '../../../../components/common/LoaderSmall'
import FilterSimple from '../../../../components/generics/FilterSimple'
import { EVENT_LOG_TYPES } from '../../../../constants/filter'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'

const ActivityHistory = ({ organization, getHistoryEvents, historyEvents }) => {
  const { trans } = useTrans()
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 })
  const [pending, setPending] = useState(false)
  const [count, setCount] = useState(0)
  const [order, setOrder] = useState(['createdAt', 'desc'])
  const [filter, setFilter] = useState({ name: 'All types', value: 'all' })
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()

  const fetchHistory = () => {
    setPending(true)
    getHistoryEvents({
      organizationId: organization?.id,
      pagination,
      order,
      filter,
    }).then(res => {
      setCount(res.payload.count)
      setPending(false)
    })
  }

  const onChangePage = page =>
    setPagination({ page, perPage: pagination.perPage })
  const onChangeRowPerPage = perPage =>
    setPagination({ page: pagination.page, perPage })
  const onChangeFilter = e => setFilter(e)

  useEffect(() => {
    if (organization) fetchHistory()
  }, [pagination, filter])

  return (
    <SpacingContainerBase>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              variant="h4"
              weight="500"
              fontFamily="lexend"
              style={{ alignItems: 'center', display: 'flex' }}
            >
              <HistoryOutlinedIcon /> {trans('activity-history')} • {count}{' '}
              <ShowTooltip sectionToShow="activity-history" />
            </Typography>
          </Grid>

          <Grid item>
            <FilterSimple
              filter={filter}
              scope={'history-organization'}
              filterOptions={EVENT_LOG_TYPES}
              onChangeFilter={onChangeFilter}
            />
          </Grid>
        </Grid>

        {pending && <LoaderSmall />}
        {historyEvents && !emptyArray(historyEvents?.items) && !pending && (
          <SpacingContainer px="0" py={3}>
            <Divider light />
            <TableContainer component={Paper} radius={9}>
              <Table style={{ minWidth: 1000 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{trans('timestamp')}</TableCell>
                    <TableCell align="left">
                      {trans('associated-user')}
                    </TableCell>
                    <TableCell align="left">{trans('event-type')}</TableCell>
                    <TableCell align="left">{trans('description')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyEvents.items.map(h => (
                    <Item item={h} key={h.id} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </SpacingContainer>
        )}
        {!pending && historyEvents && !emptyArray(historyEvents.items) && (
          <Grid container alignItems="center">
            <Grid item xs zeroMinWidth>
              <PaginationSmall
                divider="top"
                page={pagination.page}
                perPage={pagination.perPage}
                count={count}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                pending={pending}
                actions={
                  <Button size="small" onClick={onOpenPopover}>
                    <Typography
                      variant="body2"
                      weight="bold"
                      color="inherit"
                      style={{ marginRight: '10px' }}
                    >
                      {trans('results-per-page')}
                    </Typography>
                    <Typography variant="body2" weight="bold" color="inherit">
                      {pagination.perPage}
                    </Typography>
                    <ArrowDropDown />
                  </Button>
                }
              />
            </Grid>
          </Grid>
        )}
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClosePopover}
        >
          {ROWS_PER_PAGE_OPTIONS.map(rowPageList => (
            <ContextMenuListItem
              button
              onClick={() => onChangeRowPerPage(rowPageList)}
              onClickCB={onClosePopover}
              key={rowPageList}
            >
              <ListItemTextBase>{rowPageList}</ListItemTextBase>
            </ContextMenuListItem>
          ))}
        </Popover>

        {historyEvents && emptyArray(historyEvents.items) && (
          <NothingFoundCard
            title={trans('this-user-has-no-activity-yet')}
            size="medium"
          />
        )}
      </Paper>
    </SpacingContainerBase>
  )
}

export default connect(ActivityHistory)
