import { useState } from 'react'
import connect from '../../../connect'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { useTrans } from '../../../../../../services/i18n'
import usePopover from '../../../../../../hooks/usePopover'
import useBooleanHandlers from '../../../../../../hooks/useBooleanHandlers'
import {
  ContextMenuListItem,
  Popover,
} from '../../../../../../components/common/Dialogs/Popover'
import { ListItemTextBase } from '../../../../../../components/common/Containers'
import UserModal from '../UserModal'
import React from 'react'
import { useAlertContext } from '../../../../../../contexts/AlertContext'
import ResetPasswordModal from '../../../../../RegisteredUsers/components/ResetPasswordModal'
import ConfirmDialog from '../../../../../../components/generics/Confirm/Dialog'
import Typography from '../../../../../../components/common/Typography'
import { selectUserPermissions, isEnabled } from '../../../../../../selectors'
import { useSelector } from 'react-redux'

function UserActions({
  user,
  removeUserFromOrg,
  changeUserAccountStatus,
  detail,
  getDetail,
  authUser
}) {
  const { trans } = useTrans()
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const [openEdit, openEditModal, onEditClose] = useBooleanHandlers()
  const [openRemove, onOpenRemove, onCloseRemove] = useBooleanHandlers()
  const { successAlert, errorAlert } = useAlertContext()
  const permissions = useSelector(selectUserPermissions)
  const onRemoveUserFromOrg = React.useCallback(() => {
    if (removeUserFromOrg) {
      removeUserFromOrg({
        organizationUserId: user.organizationUserId,
      }).then(res => {
        successAlert(trans('user-removed-organization'))
        getDetail({ organizationId: detail.id })
      })
    }
  }, [removeUserFromOrg, user])

  const onChangeUserAccountStatus = React.useCallback(() => {
    if (changeUserAccountStatus) {
      changeUserAccountStatus({
        organizationUserId: user.organizationUserId,
        disabled: user.disabled ? false : true,
      }).then(res => {})
    }
  }, [changeUserAccountStatus, user.id, user.disabled])

  return !user ? null : (
    <>
      {user && (
        <IconButton variant={'contained'} size="small" onClick={onOpenPopover}>
          <MoreHorizIcon />
        </IconButton>
      )}

      <Popover
        id={'id-users-actions'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClosePopover}
      >
        {isEnabled(
          permissions,
          'organization',
          'organization-members',
          'edit'
        ) && (
          <>
            <ContextMenuListItem
              button
              onClick={onOpenModal}
              onClickCB={onClosePopover}
            >
              <ListItemTextBase>{trans('reset-password')}</ListItemTextBase>
            </ContextMenuListItem>

            <ContextMenuListItem
              button
              onClick={openEditModal}
              onClickCB={onClosePopover}
            >
              <ListItemTextBase>{trans('edit')}</ListItemTextBase>
            </ContextMenuListItem>
            <ContextMenuListItem
              button
              onClick={onChangeUserAccountStatus}
              onClickCB={onClosePopover}
            >
              <ListItemTextBase>
                {user.disabled
                  ? trans('enable-user-organization')
                  : trans('disable-user-organization')}
              </ListItemTextBase>
            </ContextMenuListItem>
          </>
        )}
        {isEnabled(
          permissions,
          'organization',
          'organization-members',
          'delete'
        ) && (
          <ContextMenuListItem
            button
            onClick={onOpenRemove}
            onClickCB={onClosePopover}
          >
            <ListItemTextBase>{trans('delete')}</ListItemTextBase>
          </ContextMenuListItem>
        )}
      </Popover>
      <ConfirmDialog
        open={openRemove}
        onClose={onCloseRemove}
        onConfirm={onRemoveUserFromOrg}
        title={trans('remove-user-organization')}
        text={`Are you sure you want to remove this user from ${detail?.orgName}?`}
      >
        <Typography color="hint">
          {user.name} {user.familyName}
        </Typography>
      </ConfirmDialog>

      <UserModal
        open={openEdit}
        onClose={onEditClose}
        user={user}
        authUser={authUser}
      />
      <ResetPasswordModal item={user} open={openModal} onClose={onCloseModal} />
    </>
  )
}

export default connect(UserActions)
