import React from 'react'
import { Chip } from '../../../../components/common/Chip'
import { useTrans } from '../../../../services/i18n'
import { ACCOUNT_FEATURES } from '../../../../constants/subscriptionSettings'

const colorMap = {
  'Active': 'linear-gradient(#a5cb55,#5b7524)',
  'Claimed': 'linear-gradient(#0066ff, #0047b3)',
  'Verified':'linear-gradient(#00cc99, #008060)',
  'Save Searches':'linear-gradient(#cc66ff, #b31aff)',
  [ACCOUNT_FEATURES.LIMITED]:'linear-gradient(#17b471,#128754)',
  [ACCOUNT_FEATURES.ADVANTAGE]:'linear-gradient(#FF7825, #FFAC00)',
  [ACCOUNT_FEATURES.PRO]:'linear-gradient(#1559DD, #0B477D)',
  [ACCOUNT_FEATURES.SAP_ADVANTAGE]:'linear-gradient(#ff3300, #cc2900)',
  [ACCOUNT_FEATURES.APP_MAX]:'linear-gradient(#8080ff, #4d4dff)',
  [ACCOUNT_FEATURES.PROCUREMENT_PRO]:'linear-gradient(#8080ff, #4d4dff)',
  [ACCOUNT_FEATURES.FREE_TRIAL]:'linear-gradient(#FFAC00, #FF7825)',
}

const TagsChip = React.memo(({ status, expired = false }) => {
  const { trans } = useTrans()
  return !status ? null : (
    <>
      {expired ? (
        <Chip label={trans('expired')} color={'secondary'} />
      ) : (
        <Chip
          label={trans(status)}
          color={'secondary'}
          style={{
            backgroundImage: colorMap[status] ?? colorMap.unknown,
          }}
        />
      )}
    </>
  )
})

export default TagsChip
