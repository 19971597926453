import PaginationSmall from '../../../components/common/Paginations/PaginationSmall'
import { Grid } from '@material-ui/core'
import SearchTopPanel from '../../../components/generics/SearchTopPanel'
import QuickViewLayout from '../../../components/layouts/QuickViewLayout'
import { QUERY_QUICK_VIEW } from '../../../constants/queries'
import useQueryCallbacks from '../../../hooks/query/useQueryCallbacks'
import { useTrans } from '../../../services/i18n'
import { emptyArray } from '../../../utils/arrayUtils'
import { filterOptions, orderOptions } from '../filter'
import SectionScrollable from '../../../components/common/Containers/SectionScrollable'
import { SpacingContainer } from '../../../components/common/Containers'
import SortingPanel from '../../../components/generics/SortingPanel'
import NothingFoundCardList from '../../../components/generics/Cards/NothingFoundCard/NothingFoundCardList'
import useSelectedItem, {
  SELECTED_ID_PREFIX,
} from '../../../hooks/useSelectedItem'
import connect from '../connect'
import Loader from '../../../components/common/Loader'
import { CloseTitle, ModalDialog } from '../../../components/common/Dialogs'
import StepButtons from '../../../components/generics/StepButtons'
import AdaptiveView from '../../../components/common/Containers/AdaptiveViews/AdaptiveView'
import OpportunitySkeleton from '../../../components/generics/Opportunity/Skeleton'
import TeamingOpportunityItem from '../TeamingOpportunityItem'
import { useEffect, useMemo } from 'react'
import QuickViewItem from './QuickViewItem'
import { ITEM_VARIANT } from '../constants'

function QuickViewList({
  scope,
  opportunities = {},
  onChangeFilters,
  onChangePage,
  onChangeRowPerPage,
  onAddToFavorites,
  onClaimVendor,
  onChangeOrder,
}) {
  const { trans } = useTrans()
  const { query, deleteQuery } = useQueryCallbacks()
  const title = useMemo(
    () => trans(`${query?.type || 'active'}-opportunities`),
    [query, trans]
  )

  const { filters, order, pending, pagination, items, count } = opportunities
  const hasItems = !emptyArray(items)
  const maxIndex = hasItems ? items.length - 1 : 0
  const [
    { showItem, selectedIndex, isFirstPage, isLastPage },
    onSetSelected,
    onCloseSelected,
    onNext,
    onPrev,
  ] = useSelectedItem({ maxIndex, onChangePage, count, ...pagination })

  useEffect(() => {
    if (!query?.qv) return

    const itemId = query.id
    const index = items.findIndex(item => item.id === itemId)
    if (index === -1) return

    onSetSelected(index)
  }, [query, items, onSetSelected])

  return (
    <QuickViewLayout
      title={title}
      onBackClick={deleteQuery([QUERY_QUICK_VIEW, 'id'])}
      search={
        <SearchTopPanel
          filters={filters}
          filterOptions={filterOptions}
          onChangeFilters={onChangeFilters}
          quickView
        />
      }
    >
      <Grid container justifyContent="space-between" className="forwardScroll">
        <Grid item md={5} sm={12} xs={12} className="forwardScroll">
          <SectionScrollable
            bgColor="paperSecondary"
            dividedFixed
            TopFixed={
              <SpacingContainer px={2} py={3}>
                <SortingPanel
                  order={order}
                  scope={scope}
                  orderOptions={orderOptions}
                  onChangeOrder={onChangeOrder}
                />
              </SpacingContainer>
            }
            BottomFixed={
              <PaginationSmall
                page={pagination.page}
                perPage={pagination.perPage}
                count={count}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                pending={pending}
                px={2}
                py={1}
              />
            }
          >
            <Loader open={pending} />
            <SpacingContainer px={3} py={3}>
              {hasItems ? (
                <Grid container direction="column" spacing={1}>
                  {items.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs
                      id={`${SELECTED_ID_PREFIX}${index}`}
                    >
                      <TeamingOpportunityItem
                        item={item}
                        variant={ITEM_VARIANT.CARD}
                        onClick={() => onSetSelected(index)}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : !pending ? (
                <NothingFoundCardList />
              ) : null}
            </SpacingContainer>
          </SectionScrollable>
        </Grid>

        <AdaptiveView
          wrapper={<Grid md={7} item className="forwardScroll" />}
          wrapperMobile={
            <ModalDialog
              disableContentSpacing
              open={showItem}
              onClose={onCloseSelected}
              maxWidth="sm"
              DialogTitle={<CloseTitle onClose={onCloseSelected} />}
            />
          }
        >
          <SectionScrollable
            BottomFixed={
              hasItems ? (
                <StepButtons
                  onNext={
                    selectedIndex < maxIndex || !isLastPage ? onNext : undefined
                  }
                  onPrev={
                    selectedIndex > 0 || !isFirstPage ? onPrev : undefined
                  }
                />
              ) : undefined
            }
          >
            <AdaptiveView
              wrapper={<SpacingContainer px={5} py={0} />}
              wrapperMobile={null}
            >
              {items[selectedIndex] ? (
                <QuickViewItem item={items[selectedIndex]} />
              ) : (
                <OpportunitySkeleton />
              )}
            </AdaptiveView>
          </SectionScrollable>
        </AdaptiveView>
      </Grid>
    </QuickViewLayout>
  )
}

export default connect(QuickViewList)
