import { connect } from 'react-redux'
import { actions } from '../../../../store/adminPortalRoles'

function mapStateToProps(state) {
  return {
    authUser: state.auth,
    rolesList: state.adminPortalRoles.rolesList,
    defaultRole: state.adminPortalRoles.defaultRole
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getRolesList: (params) => dispatch(actions.getRolesList(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
