import { SpacingContainer } from '../../../components/common/Containers/Base'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'
import { useQuery } from '../../../hooks/query/useQuery'
import { Box } from '@material-ui/core'
import {
  ListAltRounded,
  BlockRounded,
  DeleteForeverOutlined,
} from '@material-ui/icons'
import OpportunityTypeButton from './OpportunityTypeButton'
import { useNavigate } from 'react-router-dom'
import ListInfoRow from '../../../components/generics/ListInfoRow'
import SortingPanel from '../../../components/generics/SortingPanel'
import connect from '../connect'
import SearchTopPanel from '../../../components/generics/SearchTopPanel'
import { filterOptions, orderOptions } from '../filter'

const TYPE_BUTTONS = [
  {
    text: 'active',
    icon: props => <ListAltRounded {...props} />,
  },
  {
    text: 'blocked',
    icon: props => <BlockRounded {...props} />,
  },
  {
    text: 'deleted',
    icon: props => <DeleteForeverOutlined {...props} />,
  },
]

const Header = ({ scope, opportunities, onChangeOrder, onChangeFilters }) => {
  const { trans } = useTrans()
  const { query } = useQuery()
  const navigate = useNavigate()
  const opportunityType = query?.type || 'active'

  const { filters, order, count, items } = opportunities

  const onClick = type => {
    navigate(`/teaming-opportunities?type=${type}`)
  }

  return (
    <>
      <SpacingContainer py={3} px={0}>
        <Box display="flex" justifyContent="space-between">
          <Typography
            weight="600"
            variant="h2"
            fontFamily="lexend"
            title
            style={{ alignItems: 'center', display: 'flex' }}
          >
            {trans('open-opportunities')}

            <ShowTooltip sectionToShow="organizations-table" />
          </Typography>

          <Box>
            {TYPE_BUTTONS.map(({ text, icon }) => (
              <OpportunityTypeButton
                text={trans(text)}
                active={opportunityType === text}
                icon={icon}
                onClick={() => onClick(text)}
              />
            ))}
          </Box>
        </Box>

        <Box>
          <SearchTopPanel
            scope={scope}
            filters={filters}
            onChangeFilters={onChangeFilters}
            filterOptions={filterOptions}
          />

          {!!items.length && (
            <ListInfoRow
              count={count}
              description={trans(`${opportunityType}-opportunities`)}
              sort={
                <SortingPanel
                  order={order}
                  scope={scope}
                  orderOptions={orderOptions}
                  onChangeOrder={onChangeOrder}
                />
              }
            />
          )}
        </Box>
      </SpacingContainer>
    </>
  )
}

export default connect(Header)
