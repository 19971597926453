import React from 'react'
import { Grid } from '@material-ui/core'
import { useTrans } from '../../../services/i18n'
import { ITEM_VARIANT } from '../constants'
import { Label, ValueText } from '../components/styled'

function DetailsRow({
  publisher,
  location,
  postedDate,
  responseByDate,
  variant,
  fontSize,
}) {
  const { trans } = useTrans()
  const showLabel = [ITEM_VARIANT.LIST, ITEM_VARIANT.QUICK_VIEW].includes(
    variant
  )

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item>
        {showLabel && <Label fontSize={fontSize}> {trans('publisher')}:</Label>}
        <ValueText fontSize={fontSize}>{publisher}</ValueText>
      </Grid>

      {location && (
        <Grid item>
          {showLabel && (
            <Label fontSize={fontSize}> {trans('location')}:</Label>
          )}
          {location && <ValueText fontSize={fontSize}>{location}</ValueText>}
        </Grid>
      )}

      {variant !== ITEM_VARIANT.QUICK_VIEW && (
        <Grid item>
          {showLabel && (
            <Label fontSize={fontSize}> {trans('posted-date')}:</Label>
          )}
          <ValueText fontSize={fontSize}>{postedDate}</ValueText>
        </Grid>
      )}

      {responseByDate && variant !== ITEM_VARIANT.CARD && (
        <Grid item>
          <>
            {showLabel && (
              <Label fontSize={fontSize}> {trans('response-by-date')}:</Label>
            )}
            {!showLabel && trans('ends-on') + ' '}
            <ValueText fontSize={fontSize}>{responseByDate}</ValueText>
          </>
        </Grid>
      )}
    </Grid>
  )
}

export default DetailsRow
