import IconButton from '@material-ui/core/IconButton';
import IconSorting from '@material-ui/icons/ImportExport';
import React from 'react';
import { Button } from '../../../components/common/Buttons';
import { Popover, ContextMenuListItem } from '../../../components/common/Dialogs/Popover';
import Typography from '../../../components/common/Typography';
import { IconSort } from '../../../components/svg/Icons/IconSort';
import { useMediaQueryDown } from '../../../hooks/useMediaQuery';
import usePopover from '../../../hooks/usePopover';
import { useTrans } from '../../../services/i18n';
import { SpacingContainer } from '../../common/Containers/Base';
import ListItemTextBase from '../../common/Containers/Base/ListItemTextBase'

export default function SortingList({ scope, filter, filterOptions, onChangeFilter, media = 'xs', mobileButton = false }) {
  const isSmScreen = useMediaQueryDown(media);
  const { trans } = useTrans();
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();

  const onItemClick = React.useCallback(
    (value) => {
      onClosePopover();
      return onChangeFilter && onChangeFilter(value);
    },
    [onChangeFilter, onClosePopover]
  );

  const current = React.useMemo(() => {
    const { value } = filter || {};
    const founded = filterOptions.find((o) => o.value === value);
    return founded ? { name: founded.name, value } : { name: 'N/A', value: '' };
  }, [filterOptions, filter]);

  return (
    <>
      {mobileButton && isSmScreen ? (
        <IconButton onClick={onOpenPopover} id="sort-by" size="small">
          <IconSorting />
        </IconButton>
      ) : (
        <Button
          onClick={onOpenPopover}
          id="sort-by"
          fullWidth={isSmScreen}
          variant={isSmScreen ? 'outlined' : 'text'}
          size={isSmScreen ? 'medium' : 'small'}
          endIcon={<IconSort />}
        >
          <Typography variant="body2" color="hint" noWrap>
            {trans('Show')}&nbsp;&nbsp;&nbsp;
            <Typography color="label" weight="700" variant="body2" component="span">
              {trans(current.name)}
            </Typography>
          </Typography>
        </Button>
      )}

      <Popover id={`id-popover-sorting-${scope}`} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover}>
        <SpacingContainer py={1} px={2}>
          {filterOptions.map((item, index) => (
            <ContextMenuListItem
              key={index}
              button
              onClick={() => onItemClick(item)}
              disabled={current.value === item.value}
            >
              <ListItemTextBase>
                {item.name}
              </ListItemTextBase>
            </ContextMenuListItem>

          ))}
        </SpacingContainer>
      </Popover>
    </>
  );
}
