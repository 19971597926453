import React from 'react'
import { SvgIcon } from '../../common/SvgIcon'
import { simpleIconPropsTypes } from '../propTypes'

export const IconNotifyOff = props => {
  const defaultValue = 19
  const { width = defaultValue, height = defaultValue } = props

  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 24 24`}>
      <g clip-path="url(#clip0_4920_111145)">
        <path
          d="M9 20H15C15 21.6569 13.6569 23 12 23C10.3431 23 9 21.6569 9 20Z"
          fill="#4E5978"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L4.58275 5.99696C4.20696 6.92422 4 7.93794 4 9V14C4 15.0544 3.18412 15.9182 2.14926 15.9945L2 16C0.712644 16 0.668252 17.8644 1.86683 17.993L2 18H16.5858L22.2929 23.7071C22.6834 24.0976 23.3166 24.0976 23.7071 23.7071C24.0976 23.3166 24.0976 22.6834 23.7071 22.2929L19.4142 18H22L22.1332 17.993C23.2889 17.869 23.2889 16.131 22.1332 16.007L22 16C20.8954 16 20 15.1046 20 14V9C20 4.58172 16.4183 1 12 1C9.38649 1 7.06568 2.25324 5.60577 4.19155L1.70711 0.292893ZM6.16839 7.58261C6.05833 8.03706 6 8.51172 6 9V14C6 14.6694 5.83555 15.3005 5.54484 15.8549L5.463 16H14.5858L6.16839 7.58261ZM17.4142 16L7.0388 5.62458C8.11881 4.04024 9.93793 3 12 3C15.3137 3 18 5.68629 18 9V14L18.0049 14.1996C18.0342 14.7949 18.1935 15.3559 18.4552 15.8549L18.536 16H17.4142Z"
          fill="#4E5978"
        />
      </g>
      <defs>
        <clipPath id="clip0_4920_111145">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

IconNotifyOff.propTypes = simpleIconPropsTypes
