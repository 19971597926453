import {
  Box,
  CircularProgress,
  Fade,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import { Button } from '../../../components/common/Buttons'
import Typography from '../../../components/common/Typography'
import { IconClose, IconInfo, IconSearch } from '../../../components/svg/Icons'
import { CheckBox, TextField } from '../../../components/common/Inputs'
import * as Yup from 'yup'
import { useForm } from '../../../hooks/useForm'
import makeValidationSchema from '../../../hooks/makeValidationSchema'
import { useAlertContext } from '../../../contexts/AlertContext'
import { useState } from 'react'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import clsx from 'clsx'
import { application } from '../../../services/application'
import { API } from '../../../constants'
import { ModalDialog } from '../../../components/common/Dialogs'
import { useDispatch } from 'react-redux'
import { actions } from '../../../store/clientDetail'
import { useNavigate } from 'react-router-dom'

const dataToTrasnfer = {
  USFCR_STAFF: 'usfcr_staff',
  FILES: 'files',
  ORGANIZATION_MEMBERS: 'organization_members',
  FORMS_AND_ADJUSTED_FORMS: 'forms_and_adjusted_forms',
}

const useStyles = makeStyles(theme => ({
  infoAlert: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.gray.main,
  },
  dataToTransferTitle: {
    fontWeight: 500,
  },
  dataToTransferSubtitle: {
    color: theme.palette.gray.main,
    '& span': {
      color: theme.palette.warning.light,
      fontWeight: 700,
    },
  },
  searchMergeTo: {
    position: 'relative',
    '& button': {
      position: 'absolute',
      right: '1%',
      top: '30%',
    },
    '& .loader': {
      position: 'absolute',
      right: '3%',
      top: '44%',
    },
  },
  mergeToPaper: {
    position: 'relative',

    '& button': {
      position: 'absolute',
      right: '1%',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
}))

const useScheme = makeValidationSchema(() => {
  return Yup.object().shape({
    mergeTo: Yup.string().required('Please select organization'),
    dataToTransfer: Yup.array().required('Please select data to transfer'),
  })
})

const initialValues = {
  mergeTo: '',
  dataToTransfer: [],
}

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: true,
}

const useMergeToOrganiztion = () => {
  const [loading, setLoading] = useState(false)
  const [mergeToOrganization, setMergeToOrganization] = useState(null)
  const [mergeToError, setMergeToError] = useState(null)

  const dispatch = useDispatch()

  const handleSearchMergeToOrg = async value => {
    if (!value) return
    setMergeToOrganization(null)
    setMergeToError(null)
    try {
      setLoading(true)
      const res = await application.call(API.CLIENT_DETAIL.GET_DETAIL, {
        organizationId: value,
      })
      setMergeToOrganization(res?.organization)
    } catch (err) {
      setMergeToError("Can't find this organization")
    } finally {
      setLoading(false)
    }
  }

  const handleResetMergeToOrg = () => {
    setMergeToOrganization(null)
  }

  const handleMergeSubmit = async params => {
    return Promise.resolve(dispatch(actions.mergeOrganizations(params)))
  }

  return {
    handleMergeSubmit,
    handleResetMergeToOrg,
    handleSearchMergeToOrg,
    loading,
    mergeToError,
    mergeToOrganization,
    setLoading,
  }
}

function MergeOrganizationModal({ open, onClose, org, orgHierarchy = [] }) {
  const styles = useStyles()
  const scheme = useScheme()
  const { successAlert, errorAlert } = useAlertContext()
  const navigate = useNavigate();

  const handleCheckBoxChange = e => {
    const { value, checked } = e.target
    if (checked) {
      setFieldValue('dataToTransfer', [...values.dataToTransfer, value])
    } else {
      setFieldValue(
        'dataToTransfer',
        values.dataToTransfer.filter(v => v !== value)
      )
    }
  }

  const onResetMergeToOrg = () => {
    setFieldValue('mergeTo', '');
    handleResetMergeToOrg();
  }

  const closeModal = () => {
    resetForm()
    handleResetMergeToOrg()
    onClose()
  }

  const {
    loading,
    setLoading,
    mergeToOrganization,
    mergeToError,
    handleSearchMergeToOrg,
    handleResetMergeToOrg,
    handleMergeSubmit
  } = useMergeToOrganiztion();

  const onSubmit = ({ mergeTo, dataToTransfer }) => {
    const body = {
      mergeFrom: org,
      mergeTo,
      dataToTransfer,
    }
    handleMergeSubmit(body)
      .then(res => {
        console.log(res)
        if (res?.payload?.success) {
          closeModal();
          successAlert('Organization was successfully merged');
          navigate(`/organizations/${mergeTo}`)
        } else {
          errorAlert('Something went wrong')
        }
      })
      .catch(err => {
        errorAlert(err.message)
      })
      .finally(() => setLoading(false))
  }

  const { values, getFieldProps, setFieldValue, handleSubmit, resetForm } =
    useForm(scheme, initialValues, onSubmit, formOptions);

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title="Merge organization"
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" onClick={closeModal}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              pending={loading}
              disabled={loading}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Box className={styles.infoAlert}>
        <IconInfo />
        <Typography variant="body2">
          Current organization will be merged with another one and no longer
          exist. You can select which data you want to transfer.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <TextField
            name="mergeFrom"
            label="Merge from"
            disabled
            value="Current Organization"
          />
        </Grid>
        <Grid xs={12} item>
          <Box className={styles.searchMergeTo}>
            <TextField
              placeholder="Please select..."
              label="Merge to"
              name="mergeTo"
              helperText=" "
              {...getFieldProps('mergeTo')}
            />
            {!loading ? (
              <IconButton onClick={e => handleSearchMergeToOrg(values.mergeTo)}>
                <IconSearch />
              </IconButton>
            ) : (
              <span className="loader">
                <CircularProgress color="inherit" size="1.29em" />
              </span>
            )}
          </Box>
        </Grid>
        <Grid xs={12} item>
          <Fade in={mergeToOrganization || mergeToError}>
            <Paper
              bgColor={!mergeToError ? 'paperSecondary' : 'error'}
              component={SpacingContainer}
              px={2}
              py={1}
              className={clsx(styles.mergeToPaper, 'center')}
              radius={4}
            >
              <Typography>
                {mergeToError ?? mergeToOrganization?.orgName}
              </Typography>
              {mergeToOrganization && (
                <IconButton onClick={onResetMergeToOrg}>
                  <IconClose />
                </IconButton>
              )}
            </Paper>
          </Fade>
        </Grid>
        <Grid xs={12} item>
          <Typography className={styles.dataToTransferTitle} variant="body1">
            Data to transfer
          </Typography>
          <Typography className={styles.dataToTransferSubtitle} variant="body2">
            <span>Note!</span> All unselected and not listed data will be lost.
          </Typography>
          <FormGroup>
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid xs={12} md={6} item>
                <FormControlLabel
                  control={
                    <CheckBox
                      color="secondary"
                      checked={values.dataToTransfer.includes(
                        dataToTrasnfer.USFCR_STAFF
                      )}
                      value={dataToTrasnfer.USFCR_STAFF}
                      onChange={handleCheckBoxChange}
                    />
                  }
                  label="USFCR Staff"
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <FormControlLabel
                  control={
                    <CheckBox
                      color="secondary"
                      checked={values.dataToTransfer.includes(
                        dataToTrasnfer.FILES
                      )}
                      value={dataToTrasnfer.FILES}
                      onChange={handleCheckBoxChange}
                    />
                  }
                  label="Files"
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <FormControlLabel
                  control={
                    <CheckBox
                      color="secondary"
                      checked={values.dataToTransfer.includes(
                        dataToTrasnfer.ORGANIZATION_MEMBERS
                      )}
                      onChange={handleCheckBoxChange}
                      value={dataToTrasnfer.ORGANIZATION_MEMBERS}
                    />
                  }
                  label="Organization Members"
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <FormControlLabel
                  control={
                    <CheckBox
                      color="secondary"
                      checked={values.dataToTransfer.includes(
                        dataToTrasnfer.FORMS_AND_ADJUSTED_FORMS
                      )}
                      value={dataToTrasnfer.FORMS_AND_ADJUSTED_FORMS}
                      onChange={handleCheckBoxChange}
                    />
                  }
                  label="Forms an Adjusted Forms"
                />
              </Grid>
            </Grid>
          </FormGroup>
        </Grid>
      </Grid>
    </ModalDialog>
  )
}

export default MergeOrganizationModal
