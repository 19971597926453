import { useRef, useState } from 'react'
import { useTrans } from '../../../services/i18n'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import {
  Card,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Typography from '../../../components/common/Typography'
import { Avatar } from '../../../components/common/Cards'
import { Button } from '../../../components/common/Buttons'
import ApproveDenyLogoModal from './ApproveDenyLogoModal'
import connect from '../connect'
import { AVATAR_STATUSES } from '../../../constants/status'

const useStyles = makeStyles(theme => ({
  actionButton: {
    minWidth: '5.3125rem',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  publisher: {
    display: 'flex',
    flexDirection: 'row',
  },
  infoContainer: {
    display: 'flex',
    flex: '1 0 0',
    gap: '0.3rem',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  general: {
    display: 'flex',
    gap: '1rem',
  },
}))

const List = ({ items, updateAvatarStatus, onInit }) => {
  const { trans } = useTrans()
  const styles = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [modalOpen, setModalOpen] = useState(false)
  const [actionTitle, setActionTitle] = useState()
  const [actionSubTitle, setActionSubTitle] = useState()
  const [isLogoApproved, setIsLogoApproved] = useState(false)
  const itemRef = useRef()

  const approveLogo = item => {
    itemRef.current = item
    setActionTitle(trans('approve-organization-logo'))
    setActionSubTitle(trans('approve-logo-text'))
    setIsLogoApproved(true)
    setModalOpen(true)
  }

  const denyLogo = item => {
    itemRef.current = item
    setActionTitle(trans('deny-organization-logo'))
    setActionSubTitle(trans('deny-logo-text'))
    setIsLogoApproved(false)
    setModalOpen(true)
  }

  return (
    <>
      <ApproveDenyLogoModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        item={itemRef.current}
        refreshList={true}
        title={actionTitle}
        subtitle={actionSubTitle}
        isLogoApproved={isLogoApproved}
        handleAction={async () => {
          await updateAvatarStatus({
            status: isLogoApproved
              ? AVATAR_STATUSES.approved
              : AVATAR_STATUSES.rejected,
            id: itemRef.current.id,
          })
          await onInit()
        }}
      />

      {items.map((item, index) => (
        <>
          <Card
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
            key={item.id}
          >
            <Paper component={SpacingContainer} py={3} px={3}>
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid item className={styles.general}>
                  <Grid item>
                    <Avatar
                      src={item.avatarUrl}
                      alt="-"
                      size={isMobile ? 60 : 100}
                    />
                  </Grid>
                  <Grid item zeroMinWidth className={styles.infoContainer}>
                    <Typography
                      variant="h4"
                      weight="600"
                      color="primary"
                      noWrap
                    >
                      {item.orgName}
                    </Typography>

                    <span className={styles.publisher}>
                      <Typography
                        variant="h6"
                        weight="400"
                        color="hint"
                        style={{ marginRight: '0.2rem' }}
                      >
                        {`${trans('publisher')}:  `}
                      </Typography>
                      <Typography variant="h6" weight="400">
                        {item.publisher}
                      </Typography>
                    </span>
                  </Grid>
                </Grid>
                <Grid item className={styles.actionsContainer}>
                  <Button
                    variant="outlined"
                    color="success"
                    className={styles.actionButton}
                    onClick={() => {
                      approveLogo(item)
                    }}
                  >
                    {trans('approve')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    className={styles.actionButton}
                    onClick={() => {
                      denyLogo(item)
                    }}
                  >
                    {trans('deny')}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Card>
        </>
      ))}
    </>
  )
}

export default connect(List)
