import { alpha as fade } from '@material-ui/core/styles/colorManipulator'

const palette = {
  type: 'light',
  common: {
    white: '#FFF',
    black: '#000',
  },
  primary: {
    main: '#2445a7',
    light: '#52cbff',
    dark: '#233a7e',
    fade: fade('#2445a7', 0.2),
    tabs: '#1d3169',
  },
  secondary: { main: '#f32445', dark: '#e3212e', fade: fade('#f32445', 0.2) },
  success: { main: '#17b471', dark: '#11ca7b', fade: fade('#17b471', 0.2) },
  error: { main: '#ff5225', fade: fade('#ff5225', 0.2) },
  violet: { main: '#9576ff' },
  warning: { main: '#faa900', light: '#ffac00', fade: fade('#faa900', 0.2) },
  info: { main: '#526fff', fade: fade('#526fff', 0.2) },
  pipeline: { main: '#29365b', light: '#2e3c63', dark: '#20283e' },
  gray: {
    main: '#4e5978',
    light: '#888fa9',
    active: '#b2b8cf',
    paperActive: '#dce0e8',
    divider: '#eaebef',
    border: '#edeef1',
    background: '#f1f3f9',
    fade: fade('#4e5978', 0.1),
  },
  text: {
    primary: '#000',
    secondary: '#cac9cf',
    disabled: '#6b6a6f',
    hint: '#9a99a1',
    white: '#ffffff',
    success: '#17b471',
    placeholder: '#b2b8cf',
  },
  background: {
    paper: '#fff',
    paperSecondary: '#f1f3f9',
    default: '#fff',
    input: '#f1f3f9',
  },
  action: {
    light: 'rgba(255,255,255,0.8)',
    disabledBackground: '#888FA9',
  },
  divider: '#b2b8cf',
}

export default palette
