import React, { useCallback, useEffect, useRef } from 'react'
import connect from '../connect'
import { Grid } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Typography from '../../../components/common/Typography'
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase'
import { useTrans } from '../../../services/i18n'
import { EditViewContainer } from '../../../components/common/Containers'
import BaseFields from '../components/BaseFields'
import EmailTemplateEditor from '../components/EmailTemplateEditor'
import SaveButton from '../components/SaveButton'
import Loader from '../../../components/common/Loader'
import { useForm } from '../../../hooks/useForm'
import { useScheme } from './formik'
import { useAlertContext } from '../../../contexts/AlertContext'

const EmailTemplateDetails = ({
  findOne,
  saveEmailTemplate,
  currentTemplate,
  pending,
}) => {
  const { trans } = useTrans()
  const { id } = useParams()
  const schema = useScheme()
  const wasUpdated = useRef(false)
  const navigate = useNavigate()
  const { successAlert, errorAlert } = useAlertContext()

  const getInitialState = useCallback(() => {
    if (!currentTemplate) return {}

    const { id, emailSubject, name, primaryEmailBody, secondaryEmailBody } =
      currentTemplate
    return {
      id,
      emailSubject,
      name,
      primaryEmailBody,
      secondaryEmailBody,
    }
  }, [currentTemplate])

  const onSubmit = async values => {
    saveEmailTemplate(values)
      .then(() => {
        successAlert(trans('email-template-saved'))
      })
      .catch(() => {
        successAlert(trans('something-went-wrong'))
      })
  }

  const formik = useForm(schema, getInitialState(), onSubmit, {
    validateOnChange: false,
    validateOnBlur: false,
  })

  useEffect(() => {
    if (!currentTemplate && !wasUpdated) return

    wasUpdated.current = true
    formik.setValues(getInitialState())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate, getInitialState])

  useEffect(() => {
    findOne({ id })
  }, [findOne, id])

  return (
    <EditViewContainer
      pending={false}
      title={trans('edit-template')}
      onSave={() => {
        formik.handleSubmit()
        navigate('/email-templates')
      }}
    >
      {pending && <Loader open={pending} />}
      <SpacingContainerBase>
        <Grid container justifyContent="center" style={{ gap: '1rem' }}>
          <Grid item xs={12} md={10}>
            <BaseFields formik={formik} />
          </Grid>
          <Grid item xs={12} md={10}>
            <EmailTemplateEditor formik={formik} template={currentTemplate} />
          </Grid>
          <Grid item xs={12} md={10}>
            <SaveButton onClick={formik.handleSubmit} />
          </Grid>
        </Grid>
      </SpacingContainerBase>
    </EditViewContainer>
  )
}

export default connect(EmailTemplateDetails)
