import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import { KeyboardDatePicker as MUIDatePicker } from '@material-ui/pickers/DatePicker';
import dayjs from 'dayjs';
import React from 'react';
import { DATE_FORMAT } from '../../../../constants';
import Typography from '../../Typography';

const InputPropsDefault = { disableUnderline: true, className: 'datepicker' };
const PopoverPropsDefault = { className: 'datepicker' };
const InputAdornmentProps = { position: 'start', variant: 'outlined' };
const KeyboardButtonProps = { size: 'small' };

export default function DatePicker({
  label,
  value,
  onChange,
  onChangeValue,
  name = 'date',
  format = DATE_FORMAT,
  error,
  ...rest
}) {
  const handleChange = React.useCallback(
    (date) => {
      if (!dayjs(date).isValid()) return;
      const newValue = dayjs(date).toISOString();

      if (typeof onChange === 'function') return onChange(name)(newValue);
      if (typeof onChangeValue === 'function') return onChangeValue(newValue, name, null);
    },
    [name, onChange, onChangeValue]
  );

  return (
    <>
      {label && (
        <Typography weight="500" fontFamily="lexend" color="label" gutter="label" variant="body2">
          {label}
        </Typography>
      )}
      <MUIDatePicker
        value={value || null}
        name={name}
        onChange={handleChange}
        inputVariant="filled"
        variant="inline"
        autoOk
        format={format}
        fullWidth
        invalidDateMessage={null}
        KeyboardButtonProps={KeyboardButtonProps}
        InputAdornmentProps={InputAdornmentProps}
        InputProps={InputPropsDefault}
        PopoverProps={PopoverPropsDefault}
        keyboardIcon={<CalendarIcon fontSize="small" />}
        helperText={error}
        error={Boolean(error)}
        {...rest}
      />
    </>
  );
}
