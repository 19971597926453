export const ACCEPTABLE_FILE_TYPES = [
  {
    extension: 'PDF',
    type: 'word-processor',
  },
  {
    extension: 'DOC',
    type: 'word-processor',
  },
  {
    extension: 'TXT',
    type: 'word-processor',
  },
  {
    extension: 'ODT',
    type: 'word-processor',
  },
  {
    extension: 'RTF',
    type: 'word-processor',
  },
  {
    extension: 'XLS',
    type: 'spreadsheet',
  },
  {
    extension: 'CSV',
    type: 'spreadsheet',
  },
  {
    extension: 'XLSM',
    type: 'spreadsheet',
  },
  { extension: 'ODS',
    type: 'spreadsheet' 
  },
  {
    extension: 'JPEG',
    type: 'image',
  },
  {
    extension: 'JPG',
    type: 'image',
  },
  {
    extension: 'PNG',
    type: 'image',
  },
  {
    extension: 'BMP',
    type: 'image',
  },
  {
    extension: 'GIF',
    type: 'image',
  },
  {
    extension: 'TIF',
    type: 'image',
  },
  {
    extension: 'TIFF',
    type: 'image',
  },
  {
    extension: 'WEBP',
    type: 'image',
  },
]
