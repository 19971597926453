import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useEffect, useState, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'
import connect from '../../connect'
import { Button } from '../../../../components/common/Buttons'
import EditIcon from '@material-ui/icons/Edit'
import AssigmentIcon from '@material-ui/icons/AssignmentOutlined'
import Item from './item'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@mui/material/TablePagination'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import SetupTemplatesModal from './setupTemplatesModal'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard'
import { emptyArray } from '../../../../utils/arrayUtils'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const ManageFeatures = ({
  forms,
  changeCurrentIdsForms,
  formstackForms,
  changeFormstackForms,
  defaultSAPRequests,
}) => {
  const permissions = useSelector(selectUserPermissions)

  const { trans } = useTrans()
  const [templates, setTemplates] = useState([])
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')

  const parentFormsModal = () => {
    const ids = forms.map(i => i.formId)

    let formstackFormsCopy = []
    formstackFormsCopy = JSON.parse(JSON.stringify(formstackForms))
    formstackFormsCopy = formstackFormsCopy.map(i => ({
      ...i,
      checked: ids.includes(i.id),
    }))

    changeFormstackForms(formstackFormsCopy)

    changeCurrentIdsForms(ids)
    onOpenModal()
  }

  useEffect(() => {
    setTemplates(
      defaultSAPRequests.filter(i => {
        return i.checked === true
      })
    )
  }, [defaultSAPRequests])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const visibleRows = useMemo(
    () =>
      stableSort(templates, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, templates]
  )

  const labelDisplayedRows = ({ from, to, count }) => {
    return `${from}-${to} / ${count !== -1 ? count : ' ' + to}`
  }
  return (
    <SpacingContainer py={2}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <SetupTemplatesModal open={openModal} onClose={onCloseModal} />
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              variant="h4"
              weight="500"
              fontFamily="lexend"
              style={{ alignItems: 'center', display: 'flex' }}
            >
              <AssigmentIcon style={{ marginRight: '5px' }} />{' '}
              {trans('default-SAP-requests')}{' '}
              <ShowTooltip sectionToShow="default-SAP-requests" />
            </Typography>
          </Grid>
          {isEnabled(permissions, 'global-settings', '', 'edit') && (
            <Grid item>
              <Button
                isIcon
                color="primary"
                variant="contained"
                onClick={() => parentFormsModal()}
              >
                <EditIcon />
              </Button>
            </Grid>
          )}
        </Grid>
        <Divider light style={{ marginTop: '20px' }} />
        {!emptyArray(visibleRows) && (
          <>
            <TableContainer component={Paper} radius={9}>
              <Table style={{ minWidth: 700 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{trans('category-name')}</TableCell>
                    <TableCell align="left">{trans('files')}</TableCell>
                    <TableCell align="left">{trans('forms')}</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {visibleRows.map(item => (
                    <Item form={item} key={`sap-${item.id}`} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              sx={{
                borderTop: '1px solid #eaebef',
                '.MuiTablePagination-spacer': { display: 'none' },
                '.MuiTablePagination-toolbar': {
                  justifyContent: 'center',
                  padding: '0',
                },
                '.MuiTablePagination-selectLabel': { order: '3' },
                '.MuiInputBase-root': { order: '4', marginRight: '0' },
                '.MuiTablePagination-displayedRows': { flexGrow: '3.5' },
                '.MuiTablePagination-actions': { flexGrow: '4' },
              }}
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={templates.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Result per page"
              labelDisplayedRows={labelDisplayedRows}
              className="pagination-main"
            />
          </>
        )}
        {emptyArray(templates) &&
          isEnabled(permissions, 'global-settings', '', 'create') && (
            <>
              <div style={{ textAlign: 'center', padding: '16px' }}>
                <NothingFoundCard
                  title={trans('no-request-templates-added-yet')}
                  size="medium"
                />

                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => parentFormsModal()}
                >
                  {trans('add-template')}
                </Button>
              </div>
            </>
          )}
      </Paper>
    </SpacingContainer>
  )
}
export default connect(ManageFeatures)
