import React from 'react'
import { RouterLink } from '../../../../../components/common/Buttons'
import {
  TableCell,
  TableRow,
} from '../../../../../components/common/ResponsiveTable'
import { CheckBox } from '../../../../../components/common/Inputs'
import Typography from '../../../../../components/common/Typography'
import { SCOPES_ROUTES } from '../../../../../constants/search'
import { QUERY_SAVED_SEARCH } from '../../../../../constants/queries'
import { useTrans } from '../../../../../services/i18n'
import { formatDate } from '../../../../../utils/dateUtils'
import ItemMenu from './ItemMenu'
import EmailingChip from './EmailingChip'
import { IconNotify, IconNotifyOff } from '../../../../../components/svg'
import { IconButton } from '@material-ui/core'
import { WATCH_SCOPES } from '../constants'

export default function Item({
  item,
  pending,
  userDetail,
  isChecked,
  addOneSavedSearches,
  removeOneSavedSearches,
  saveDataAfterDeleteSearch,
  ...itemActions
}) {
  const { onWatch } = itemActions
  const { trans } = useTrans()

  const route = SCOPES_ROUTES[item.scope] || {}
  const searchLink = route.path
    ? `${route.path}?${QUERY_SAVED_SEARCH}=${item.id}`
    : ''

  const onWatchClick = React.useCallback(
    () => onWatch({ id: item.id, savedSearchOwner: userDetail.userName }),
    [onWatch, item.id]
  )

  /***
   * According to if it is checked, call an action
   */
  const onChangeCheckBox = () => {
    if (isChecked) {
      removeOneSavedSearches(item)
    } else {
      addOneSavedSearches(item)
    }
  }

  return (
    <TableRow>
      <TableCell label={''} order={-1}>
        <CheckBox
          checked={isChecked}
          onChange={onChangeCheckBox}
          dense
          color="secondary"
        />
      </TableCell>
      <TableCell label={trans('name')} order={-1}>
        <Typography
          variant="body2"
          weight="500"
          component={RouterLink}
          to={null}
        >
          {item.title}
        </Typography>
      </TableCell>

      <TableCell label={trans('date-created')}>
        {item.createdAt ? formatDate(item.createdAt, 'MM/DD/YYYY') : '-'}
      </TableCell>

      <TableCell label={trans('scope')} respWidth="full">
        <Typography variant="body2">
          {route.key ? trans(route.key) : '-'}
        </Typography>
      </TableCell>

      <TableCell label={trans('Count')}>{item.count || 0}</TableCell>

      <TableCell label={trans('emailing')}>
        <EmailingChip
          item={item}
          userDetail={userDetail}
          isGlobal={item.notification === 'GLOBAL'}
        />
      </TableCell>

      <TableCell align="right" order={-1} respWidth="auto">
        {WATCH_SCOPES.includes(item.scope) && (
          <IconButton onClick={onWatchClick}>
            {item.watch ? <IconNotify /> : <IconNotifyOff />}
          </IconButton>
        )}
      </TableCell>
      <TableCell align="right" order={-1} respWidth="auto">
        <ItemMenu
          item={item}
          userDetail={userDetail}
          route={route}
          searchLink={searchLink}
          pending={pending}
          saveDataAfterDeleteSearch={saveDataAfterDeleteSearch}
          {...itemActions}
        />
      </TableCell>
    </TableRow>
  )
}
