import React, { useState } from 'react'
import Typography from '../../../../components/common/Typography'
import { intlDateFormat } from '../../../../utils/dateUtils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import StatusChip from './Status'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import CloseIcon from '@material-ui/icons/Close'
import { CircularProgress, IconButton } from '@material-ui/core'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import { IconDownload } from '../../../../components/svg/Icons'
import { useAlertContext } from '../../../../contexts/AlertContext'
import ConfirmDialog from '../../../../components/generics/Confirm/Dialog'
import { useTrans } from '../../../../services/i18n'

const Item = ({
  organizationId,
  form,
  getFormSubmissionJSONSignedUrl,
  deleteForm,
}) => {
  const { trans } = useTrans()
  const [pendingDownload, setPendingDownload] = useState(false)
  const [openRemove, onOpenRemove, onCloseRemove] = useBooleanHandlers()
  const [pendingRemove, setPendingRemove] = useState(false)
  const { infoAlert, successAlert, errorAlert } = useAlertContext()

  const onDeleteForm = id => {
    setPendingRemove(true)
    return deleteForm({ id, isAdjustForm: true })
      .then(() => {
        setPendingRemove(false)
        onCloseRemove()
        successAlert('Form has been removed from this organization')
      })
      .catch(error => {
        setPendingRemove(false)
        onCloseRemove()
        console.log(error)
        errorAlert('Error')
      })
  }

  const onDownloadForm = (organizationId, usfcrFormId) => {
    setPendingDownload(true)
    getFormSubmissionJSONSignedUrl({ organizationId, usfcrFormId })
      .then(res => {
        if (res.payload) {
          window.open(res.payload, '_self')
          setTimeout(() => {
            setPendingDownload(false)
          }, 3000)
        } else {
          infoAlert('This submission was not uploaded')
          setPendingDownload(false)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const openLink = () => {
    window.open(form.form.url, '_blank')
  }

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <Typography
            noWrap
            variant="inherit"
            fontFamily="lexend"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '20rem',
            }}
          >
            {form.associatedFormName}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            noWrap
            variant="inherit"
            fontFamily="lexend"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '20rem',
            }}
          >
            {form?.form?.name}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {intlDateFormat(form.updatedAt)}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <StatusChip status={form.status} />
        </TableCell>
        <TableCell align="left">
          {['submitted', 're-submitted'].includes(form.status) ? (
            <IconButton
              onClick={() =>
                !pendingDownload &&
                onDownloadForm(organizationId, form.formStackId)
              }
            >
              {pendingDownload ? (
                <CircularProgress color="inherit" size="1.1rem" />
              ) : (
                <IconDownload />
              )}
            </IconButton>
          ) : form.status === 'progress' ? (
            <IconButton onClick={() => openLink()}>
              <OpenInNewIcon />
            </IconButton>
          ) : (
            <IconButton onClick={onOpenRemove}>
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={openRemove}
        onClose={onCloseRemove}
        onConfirm={() => onDeleteForm(form.id)}
        pending={pendingRemove}
        title={trans('remove-form-organization')}
        text={`Are you sure you want to remove this form?`}
      >
        <Typography color="hint">{form.form.name}</Typography>
      </ConfirmDialog>
    </>
  )
}

export default Item
