import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import connect from '../connect'

const AcountsBreactdwon = ({ options }) => {
  return (
    <div>{options && <HighchartsReact style={{ width: '50px' }} highcharts={Highcharts} options={options} />}</div>
  );
};

export default connect(AcountsBreactdwon);
