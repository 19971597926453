import { useEffect, useMemo } from 'react'
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase'
import Header from './components/Header'
import connect from './connect'
import List from './components/List'
import { useQuery } from '../../hooks/query/useQuery'
import Loader from '../../components/common/Loader'
import QuickViewList from './components/QuickViewList'
import { DrawerDialog } from '../../components/common/Dialogs/DrawerDialog'
import { SCOPE_TEAMING_OPPORTUNITIES } from '../../constants/search'

const TeamingOpportunities = ({ onInit, opportunities }) => {
  const { defaultParams, pending } = opportunities
  const { query } = useQuery()
  const isQuickView = useMemo(() => !!query?.qv, [query])

  useEffect(() => {
    const status = query?.type || 'active'
    onInit({ ...defaultParams, status })
  }, [onInit, defaultParams, query])

  return (
    <SpacingContainerBase>
      <DrawerDialog
        open={isQuickView}
        maxWidth="100%"
        transitionDuration={300}
        anchor="bottom"
      >
        <QuickViewList scope={SCOPE_TEAMING_OPPORTUNITIES} />
      </DrawerDialog>

      {!isQuickView && (
        <>
          {pending && <Loader open size={50} />}
          <Header />
          <List />
        </>
      )}
    </SpacingContainerBase>
  )
}

export default connect(TeamingOpportunities)
