import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useEffect, useState } from 'react'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'

import { Button } from '../../../../components/common/Buttons'
import { TextField } from '../../../../components/common/Inputs'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import { Box } from '@mui/material'
import { Launch } from '@material-ui/icons'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import connect from '../connect'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { getColorAndPercentage } from '../../../../utils/helpers'
import LinearProgressBar from '../../../../components/common/ProgressBars/LinearProgressBar/index'
import dayjs from 'dayjs'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const ExportsConfiguration = ({
  detail,
  updateExportsConfiguration,
  current,
  resetLimitDate,
  monthlyExportsLimitOrgSettings,
}) => {
  const { trans } = useTrans()
  const { successAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const permissions = useSelector(selectUserPermissions)
  const [monthlyExportsLimit, setMonthlyExportsLimit] = useState(null)
  const changeInput = ev => {
    let value = ev.target.value
    if (isNaN(value) || Math.sign(value) === -1) {
      value = null
      return
    } else {
      value = parseInt(value)
    }
    setMonthlyExportsLimit(value)
  }

  const saveSettings = async () => {
    try {
      setIsSend(true)
      await updateExportsConfiguration({
        exportsLimit: monthlyExportsLimit,
        orgId: detail.id,
      })
      setIsSend(false)
      successAlert('Export limits saved successfully')
    } catch (error) {
      console.log(error)
      setIsSend(false)
    }
  }

  useEffect(() => {
    setMonthlyExportsLimit(
      detail.exportsLimit ?? monthlyExportsLimitOrgSettings ?? ''
    )
  }, [detail])

  const formatDatePretty = (date, customFormat) => {
    if (!date) return ''
    return dayjs(date).format(customFormat)
  }

  const { color, percentageValue } = getColorAndPercentage(
    current,
    monthlyExportsLimit
  )

  return (
    <SpacingContainerBase>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              style={{ alignItems: 'center', display: 'flex' }}
              variant="h4"
              weight="500"
              fontFamily="lexend"
            >
              <Launch style={{ marginRight: '5px' }} />{' '}
              {trans('exports-configuration')}{' '}
              <ShowTooltip sectionToShow="exports-configuration" />
            </Typography>
          </Grid>
        </Grid>
        <Divider light style={{ marginTop: '20px' }} />

        <Box style={{ alignItems: 'center', paddingTop: '20px' }}>
          <TextField
            placeholder="Ex.: 3000"
            type="number"
            name="monthlyExportsLimit"
            onChange={changeInput}
            value={monthlyExportsLimit}
            label="Monthly exported items limit"
            style={{ width: '60%', marginRight: '16px' }}
          />{' '}
        </Box>

        <Box
          style={{ display: 'flex', alignItems: 'center', paddingTop: '20px' }}
        >
          <InfoOutlinedIcon />
          <Typography variant="body2" style={{ marginLeft: 4 }}>
            {trans('using-zero-export-warning')}
          </Typography>
        </Box>

        <Box style={{ alignItems: 'center', paddingTop: '20px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8} gap={3}>
              <Typography
                variant="body2"
                style={{
                  display: 'block',
                }}
              >
                Your monthly export limit is <b>{monthlyExportsLimit || 0}</b>{' '}
                items total
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              <Typography variant="body2">
                {'Used: '}
                <b>
                  <span style={{ color }}>{current ?? ''}</span>
                </b>{' '}
                of <b>{monthlyExportsLimit || 0}</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LinearProgressBar value={percentageValue} color={color} />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              {resetLimitDate && (
                <Typography variant="caption" style={{ color: '#666' }}>
                  Resetting the limit on{' '}
                  <b>{formatDatePretty(resetLimitDate, 'MMMM D, YYYY')}</b>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        {isEnabled(
          permissions,
          'organization',
          'exports-configuration',
          'edit'
        ) && (
          <Box
            style={{
              borderTop: `1px solid rgba(78, 89, 120, 0.12)`,
              padding: '2rem 0 0.5rem 0',
              marginTop: '2rem',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs zeroMinWidth>
                <Button
                  variant="contained"
                  style={{ background: '#17b471', color: '#fff' }}
                  onClick={() => saveSettings()}
                  pending={isSend}
                >
                  {' '}
                  Save Settings
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </SpacingContainerBase>
  )
}
export default connect(ExportsConfiguration)
