import { Grid } from '@material-ui/core'
import React from 'react'
import { Label, ValueText } from '../styled'
import { LABEL_SIZE } from '../../constants'
import { useTrans } from '../../../../services/i18n'
import { formatDate } from '../../../../utils/dateUtils'
import Typography from '../../../../components/common/Typography'

function OpportunitySection({ item }) {
  const { trans } = useTrans()
  const { opportunityNumber, primaryNaics, primaryPsc, dueDate } = item
  return (
    <>
      <Typography weight={'700'} style={{ marginBottom: '.5rem' }}>
        {trans('opportunity')}
      </Typography>
      <Grid container spacing={2}>
        {opportunityNumber && (
          <Grid item xs={3}>
            <Label fontSize={LABEL_SIZE}>{trans('number')}</Label> <br />
            <ValueText fontSize={LABEL_SIZE}>
              {opportunityNumber}
            </ValueText>
          </Grid>
        )}
        {primaryNaics && (
          <Grid item xs={3}>
            <Label fontSize={LABEL_SIZE}>{trans('naics')}</Label> <br />
            <ValueText fontSize={LABEL_SIZE}>{primaryNaics?.id}</ValueText>
          </Grid>
        )}
        {primaryPsc && (
          <Grid item xs={3}>
            <Label fontSize={LABEL_SIZE}>{trans('psc')}</Label> <br />
            <ValueText fontSize={LABEL_SIZE}>{primaryPsc?.id}</ValueText>
          </Grid>
        )}
        {dueDate && (
          <Grid item xs={3}>
            <Label fontSize={LABEL_SIZE}>{trans('expiration')}</Label> <br />
            <ValueText fontSize={LABEL_SIZE}>
              {formatDate(dueDate)}
            </ValueText>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default OpportunitySection
