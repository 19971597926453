import { connect } from 'react-redux'
import { actions } from '../../store/adminPortalRoles'

function mapStateToProps(state) {
  return {
    subscriptionSettingsData: state.subscriptionSettings.subscriptionSettingsData,
    authUser: state.auth,
    rolesList: state.adminPortalRoles.rolesList,
    currentRole: state.adminPortalRoles.currentRole,
    defaultRole: state.adminPortalRoles.defaultRole
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createRole: (params) => dispatch(actions.createRole(params)),
    getRolesList: (params) => dispatch(actions.getRolesList(params)),
    deleteRole: (params) => dispatch(actions.deleteRole(params)),
    setRoleToEdit: (params) => dispatch(actions.setRoleToEdit(params)),
    updateRole: (params) => dispatch(actions.updateRole(params)),
    updatePermissions: (params) => dispatch(actions.updatePermissions(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps);
