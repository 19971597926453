// To get the client routes in order to redirect admin when select a saved search

export const ROUTE_AGENCIES_OFFICES_DETAILS = {
  key: 'federal-agencies-offices',
  path: '/agencies-offices/:id',
}

export const ROUTE_AGENCIES_OFFICES = {
  key: 'federal-agencies-offices',
  path: '/agencies-offices',
  icon: 'IconLocation',
  component: 'FederalAgenciesAndOffices',
  nested: [ROUTE_AGENCIES_OFFICES_DETAILS],
  showMenu: true,
  keyPermission: 'federal-agencies-offices',
  onboardingDes: 'All opportunities and funding info for each agency',
}

export const ROUTE_CODE_CATEGORIES_DETAILS = {
  key: 'code-categories',
  path: '/code-categories/:id',
}
export const ROUTE_CODE_CATEGORIES = {
  key: 'code-categories',
  path: '/code-categories',
  icon: 'IconCodeCategories',
  component: 'CodeCategories',
  nested: [ROUTE_CODE_CATEGORIES_DETAILS],
  showMenu: true,
  keyPermission: 'code-categories',
  onboardingDes: 'Opportunities and relevant info by Industry Code',
}

export const ROUTE_CONTRACT_AWARDS_DETAILS = {
  key: 'contract-awards',
  path: '/contract-awards/:id',
}
export const ROUTE_CONTRACT_AWARDS = {
  key: 'contract-awards',
  path: '/contract-awards',
  icon: 'IconContractAwards',
  component: 'ContractAwards',
  nested: [ROUTE_CONTRACT_AWARDS_DETAILS],
  showMenu: true,
  keyPermission: 'contract-awards',
  onboardingDes: 'Stay in the loop with the latest contracts awarded',
}

export const ROUTE_CONTRACT_OPPORTUNITIES_DETAILS = {
  key: 'contract-opportunities',
  path: '/contract-opportunities/:id',
}
export const ROUTE_CONTRACT_OPPORTUNITIES = {
  key: 'contract-opportunities',
  path: '/contract-opportunities',
  icon: 'IconContractOpportunities',
  component: 'ContractOpportunities',
  nested: [ROUTE_CONTRACT_OPPORTUNITIES_DETAILS],
  showMenu: true,
  keyPermission: 'contract-opportunities',
  onboardingDes: 'Search across millions of contract opportunities',
}

//
export const ROUTE_FEDERAL_AGENTS_DETAILS = {
  key: 'federal-agents',
  path: '/federal-agents/:id',
}
export const ROUTE_FEDERAL_AGENTS = {
  key: 'federal-agents',
  path: '/federal-agents',
  icon: 'IconFederalAgents',
  component: 'FederalAgents',
  subscribedOnly: true,
  nested: [ROUTE_FEDERAL_AGENTS_DETAILS],
  showMenu: true,
  keyPermission: 'federal-agents',
  onboardingDes: 'Find contact info for thousands of Federal Agents',
}

//
export const ROUTE_GOVERMENT_FILES_DETAILS = {
  key: 'government-files',
  path: '/government-files/:id',
}
export const ROUTE_GOVERMENT_FILES = {
  key: 'government-files',
  path: '/government-files',
  icon: 'IconGovermentFiles',
  component: 'GovermentFiles',
  nested: [ROUTE_GOVERMENT_FILES_DETAILS],
  showMenu: true,
  keyPermission: 'government-files',
  onboardingDes: 'Read the latest documents from government sources',
}

//
export const ROUTE_GRANT_OPPORTUNITIES_DETAILS = {
  key: 'grant-opportunities',
  path: '/grant-opportunities/:id',
}
export const ROUTE_GRANT_OPPORTUNITIES = {
  key: 'grant-opportunities',
  path: '/grant-opportunities',
  icon: 'IconGrantOpportunities',
  component: 'GrantOpportunities',
  nested: [ROUTE_GRANT_OPPORTUNITIES_DETAILS],
  showMenu: true,
  keyPermission: 'grant-opportunities',
  onboardingDes: 'Explore and find the latest grant opportunities',
}

export const ROUTE_VENDORS_DETAILS = {
  key: 'vendors',
  path: '/vendors/:id',
}
export const ROUTE_VENDORS = {
  key: 'vendors',
  path: '/vendors',
  icon: 'IconVendors',
  component: 'Vendors',
  nested: [ROUTE_VENDORS_DETAILS],
  showMenu: true,
  keyPermission: 'vendors',
  onboardingDes: 'Find summaries and contact information of vendors',
}

export const ROUTE_PIPELINES = {
  key: 'pipelines',
  path: '/pipelines',
  icon: 'IconPipelines',
  component: 'Pipelines',
  subscribedOnly: true,
  showMenu: true,
  keyPermission: 'pipelines',
  onboardingDes: 'Create workflows for your pursuits and prospects',
}

export const ROUTE_TASKS = {
  key: 'Tasks',
  path: '/tasks',
  icon: 'IconTasks',
  component: 'Tasks',
  subscribedOnly: true,
  showMenu: true,
  keyPermission: 'tasks',
  onboardingDes: 'Keep track of your work and pipelines with tasks',
}

export const ROUTE_PURSUIT_DETAILS = {
  key: 'Pursuits',
  path: '/pursuits/:id',
}
export const ROUTE_MY_PURSUITS = {
  key: 'Pursuits',
  path: '/pursuits',
  icon: 'IconPursuits',
  component: 'MyPursuits',
  subscribedOnly: true,
  showMenu: true,
  nested: [ROUTE_PURSUIT_DETAILS],
  keyPermission: 'my-pursuits',
  onboardingDes: "A list of your pursuits and the stage they're in",
}

export const ROUTE_TEAMING_OPPORTUNITIES_DETAILS = {
  key: 'teaming-opportunities',
  path: '/teaming-opportunities/:id',
}
export const ROUTE_TEAMING_OPPORTUNITIES = {
  key: 'teaming-opportunities',
  path: '/teaming-opportunities',
  icon: 'IconTeamingOpportunities',
  component: 'TeamingOpportunities',
  nested: [ROUTE_TEAMING_OPPORTUNITIES_DETAILS],
  showMenu: true,
  keyPermission: 'teaming-opportunities',
  onboardingDes: 'Find out if there are any opportunities to team up',
}

export const ROUTE_OPEN_PURSUITS = {
  key: 'teaming-opportunities',
  path: '/open-pursuits',
  component: 'OpenPursuits',
}

export const ROUTE_NSN_OPPORTUNITIES = {
  key: ' NSN Opportunities',
  path: '/nsn-opportunities',
  icon: 'IconNsnOpportunities',
  component: 'NsnOpportunities',
  showMenu: true,
  keyPermission: 'nsn-opportunities',
  onboardingDes: 'Find Opportunities from the Department of Defense',
}

export const ROUTE_EXPORTS = {
  key: 'exports',
  path: '/exports',
  icon: 'IconExports',
  component: 'Exports',
  showMenu: true,
  keyPermission: 'exports',
  onboardingDes: 'Check all your exports here',
}
