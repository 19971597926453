import React, { useState } from 'react'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import { Grid, Box } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import StatusChip from './Status'
import { IconButton } from '@material-ui/core'
import { Avatar } from '../../../../components/common/Cards'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { InlineContainer } from '../../../../components/common/Containers/Base'
import { Divider } from '../../../../components/common/Divider'
import { emptyArray } from '../../../../utils/arrayUtils'
import { intlDateFormat } from '../../../../utils/dateUtils'
import ConfirmDialog from '../../../../components/generics/Confirm/Dialog'
import Tag from './Tag'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import { useTrans } from '../../../../services/i18n'
import { useNavigate } from 'react-router-dom'
import MDEditor from '@uiw/react-md-editor'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const ReleaseDetails = ({ item, deleteRelease }) => {
  const permissions = useSelector(selectUserPermissions)
  const { trans } = useTrans()
  let history = useNavigate()
  const [openDelete, onOpenDelete, onCloseDelete] = useBooleanHandlers()
  const { successAlert } = useAlertContext()

  const [pendingDelete, setPendingDelete] = useState(false)
  const onDelete = params => {
    setPendingDelete(true)
    deleteRelease({ id: item.id }).then(() => {
      onCloseDelete()
      successAlert(`${item?.title} has been removed`)
      history('/releases')
    })
  }

  return (
    <>
      <Paper component={SpacingContainer} py={4} px="5sm3">
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item xs zeroMinWidth component={InlineContainer}>
            <Avatar
              variantAvatar="rounded"
              size={40}
              value={item.author}
              bgColor="gray-second"
            />
            <Box>
              <Typography
                weight="bold"
                variant="body2"
                color="primary"
                style={{ marginBottom: 3 }}
              >
                {item.author}
              </Typography>
              <Typography variant="body2" color="hint" weight="500">
                {`Last Modified ${intlDateFormat(item.updatedAt)}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <StatusChip status={item.status} />
            {isEnabled(permissions, 'releases', '', 'delete') && (
              <IconButton onClick={onOpenDelete}>
                <DeleteOutlinedIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>

        <Divider light spacing={4} />

        <SpacingContainer px="25" py={1}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={3}
          >
            <Grid item xs zeroMinWidth>
              <Typography weight="bold" variant="h3">
                {item.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {intlDateFormat(item.createdAt)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                {item.tags &&
                  !emptyArray(item.tags) &&
                  item.tags.map((item, index) => (
                    <Grid item key={index}>
                      <Tag tagName={item} />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDEditor.Markdown source={item.content} />
            </Grid>
          </Grid>
        </SpacingContainer>
      </Paper>

      <ConfirmDialog
        open={openDelete}
        onClose={onCloseDelete}
        onConfirm={onDelete}
        pending={pendingDelete}
        title={trans('delete-release')}
        text={trans('confirm-action-release')}
      >
        <Typography color="hint">{item.title}</Typography>
      </ConfirmDialog>
    </>
  )
}

export default ReleaseDetails
