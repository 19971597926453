import * as Yup from 'yup'
import makeValidationSchema from '../../../hooks/makeValidationSchema'

export const initialValues = {
  usersId: [],
  role: '',
  sub_role_id: '',
}

export const getInitialValues = item => {
  return Object.assign({}, initialValues, item)
}

export const useScheme = makeValidationSchema(trans =>
  Yup.object().shape({
    id: Yup.string().optional().nullable().default(undefined),
    usersId: Yup.array(
      Yup.object().shape({
        id: Yup.string(),
      })
    ).min(1, trans('required')),
    role: Yup.string().required(trans('required')),
    sub_role_id: Yup.string().required(trans('required')),
  })
)

export const roles = [
  { text: 'Admin', value: 'admin' },
  { text: 'Manager', value: 'manager' },
  { text: 'Consulting', value: 'consulting' },
]
