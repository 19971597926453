import { useState } from 'react'
import Typography from '../../../../components/common/Typography'
import { intlDateFormat, addDays } from '../../../../utils/dateUtils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import StatusChip from './Status'
import CloseIcon from '@material-ui/icons/Close'
// import EditIcon from '@material-ui/icons/Edit'
import { IconButton } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import usePopover from '../../../../hooks/usePopover'
import Popover from '@material-ui/core/Popover'
import Grid from '@material-ui/core/Grid'
import { SpacingContainer } from '../../../../components/common/Containers'
import ConfirmDialog from '../../../../components/generics/Confirm/Dialog'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import { useTrans } from '../../../../services/i18n'
import Approval from './Approval'
import { IconGovermentFiles } from '../../../../components/svg/Icons/Menu'
import { IconDownload, IconClose } from '../../../../components/svg/Icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelectedDocumentCategory } from '../../../../contexts/SelectedDocumentCategoryContext'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'
const Item = ({
  file,
  deleteFile,
  editFile,
  approveFile,
  denyFile,
  downloadFile,
  onChangeTab,
}) => {
  const { trans } = useTrans()
  const [openRemove, onOpenRemove, onCloseRemove] = useBooleanHandlers()
  const [pendingRemove, setPendingRemove] = useState(false)
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()
  const [pendingDownload, setPendingDownload] = useState(false)
  const { setSelectedDocumentCategory } = useSelectedDocumentCategory()
  const permissions = useSelector(selectUserPermissions)
  const onDeleteFile = () => {
    setPendingRemove(true)
    deleteFile(file).then(() => {
      setPendingRemove(false)
      onCloseRemove()
    })
  }

  const onDownloadFile = file => {
    setPendingDownload(true)
    downloadFile(file).then(() => {
      setPendingDownload(false)
    })
  }

  const getCategory = category => {
    if (category === 'General/Default') return 'General'
    return category
  }

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <Typography
            variant="inherit"
            fontFamily="manrope"
            noWrap
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '20rem',
            }}
          >
            {file.fileName}
            <IconButton onClick={onOpenPopover}>
              <ErrorIcon />
            </IconButton>
          </Typography>

          <Popover
            id={'show-description'}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClosePopover}
            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          >
            <SpacingContainer px={3} py={2}>
              <Grid container spacing={1}>
                <Grid item sm={12} xs={12}>
                  <Typography variant="body2" weight="600" color="inherit">
                    {file.description}
                  </Typography>
                </Grid>
              </Grid>
            </SpacingContainer>
          </Popover>
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="inherit"
            fontFamily="manrope"
            style={{
              marginRight: '2rem',
            }}
          >
            {intlDateFormat(file.createdAt)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography
            variant="inherit"
            fontFamily="manrope"
            style={{
              marginRight: '2rem',
            }}
          >
            {file.dueDate
              ? intlDateFormat(file.dueDate)
              : intlDateFormat(
                  addDays(intlDateFormat(file.createdAt), file.daysFulFill)
                )}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            variant="inherit"
            fontFamily="manrope"
            style={{
              marginRight: '2rem',
            }}
          >
            {getCategory(file.category)}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <StatusChip status={file.status} />
        </TableCell>
        {isEnabled(permissions, 'organization', 'files', 'edit') && (
          <TableCell align="left">
            <Approval
              file={file}
              approveFile={approveFile}
              denyFile={denyFile}
            />
          </TableCell>
        )}
        <TableCell align="right">
          {file.status.toLowerCase() === 'in progress' &&
          isEnabled(permissions, 'organization', 'files', 'delete') ? (
            <>
              <IconButton onClick={onOpenRemove}>
                <IconClose />
              </IconButton>
              {/* <IconButton onClick={() => editFile(file)}>
                <EditIcon fontSize="small" />
              </IconButton> */}
            </>
          ) : (
            <>
              <IconButton
                onClick={() => {
                  setSelectedDocumentCategory(file.category)
                  onChangeTab(null, 3)
                }}
              >
                <IconGovermentFiles />
              </IconButton>
              <IconButton
                onClick={() => !pendingDownload && onDownloadFile(file)}
              >
                {pendingDownload ? (
                  <CircularProgress color="inherit" size="1.1rem" />
                ) : (
                  <IconDownload />
                )}
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={openRemove}
        onClose={onCloseRemove}
        onConfirm={onDeleteFile}
        pending={pendingRemove}
        title={trans('remove-file-upload-request')}
        text={`Are you sure you want to remove the following file upload request?`}
      >
        <Typography color="hint">{file.fileName}</Typography>
      </ConfirmDialog>
    </>
  )
}

export default Item
