import { useState } from 'react'
import Typography from '../../../../components/common/Typography'
import { intlDateFormat } from '../../../../utils/dateUtils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import StatusChip from './Status'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'
import ConfirmDialog from '../../../../components/generics/Confirm/Dialog'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import { useTrans } from '../../../../services/i18n'
import { IconDownload } from '../../../../components/svg/Icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const Item = ({ form, deleteForm, getFormSubmissionJSONSignedUrl }) => {
  //const formStackUrl = `https://www.formstack.com/admin/submission/view/${form.form.last_submission_id}/31502380?page=1`
  const [openRemove, onOpenRemove, onCloseRemove] = useBooleanHandlers()
  const [pendingRemove, setPendingRemove] = useState(false)
  const { trans } = useTrans()
  const [pendingDownload, setPendingDownload] = useState(false)
  const { infoAlert } = useAlertContext()
  const permissions = useSelector(selectUserPermissions)

  const onDeleteForm = formId => {
    setPendingRemove(true)
    deleteForm(formId).then(() => {
      setPendingRemove(false)
      onCloseRemove()
    })
  }

  const onDownloadForm = (organizationId, usfcrFormId) => {
    setPendingDownload(true)
    getFormSubmissionJSONSignedUrl({ organizationId, usfcrFormId }).then(
      res => {
        if (res.payload) {
          window.open(res.payload, '_self')
          setTimeout(() => {
            setPendingDownload(false)
          }, 3000)
        } else {
          infoAlert('This submission was not uploaded')
          setPendingDownload(false)
        }
      }
    )
  }

  const openLink = () => {
    window.open(form.form.url, '_blank')
  }

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <Typography
            noWrap
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '20rem',
            }}
            variant="inherit"
            fontFamily="lexend"
          >
            {form.form.name}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {intlDateFormat(form.createdAt)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {intlDateFormat(form.updatedAt)}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <StatusChip status={form.status} />
        </TableCell>

        <TableCell align="right">
          {form.status === 'pending' ? (
            isEnabled(
              permissions,
              'organization',
              'assigned-forms',
              'delete'
            ) ? (
              <IconButton onClick={onOpenRemove}>
                <CloseIcon fontSize="small" />
              </IconButton>
            ) : null
          ) : form.status === 'submitted' ? (
            <IconButton
              onClick={() =>
                !pendingDownload &&
                onDownloadForm(form.organizationId, form.formStackId)
              }
            >
              {pendingDownload ? (
                <CircularProgress color="inherit" size="1.1rem" />
              ) : (
                <IconDownload />
              )}
            </IconButton>
          ) : (
            <IconButton onClick={() => openLink()}>
              <OpenInNewIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={openRemove}
        onClose={onCloseRemove}
        onConfirm={() => onDeleteForm(form.id)}
        pending={pendingRemove}
        title={trans('remove-form-organization')}
        text={`Are you sure you want to remove this form?`}
      >
        <Typography color="hint">{form.form.name}</Typography>
      </ConfirmDialog>
    </>
  )
}

export default Item
