import { Grid } from '@material-ui/core'
import { SpacingContainer } from '../../../components/common/Containers/Base'
import Typography from '../../../components/common/Typography'
import Button from '../../../components/common/Buttons/Button'
import { useTrans } from '../../../services/i18n'
import ListInfoRow from '../../../components/generics/ListInfoRow'
import SearchTopPanel from '../../../components/generics/SearchTopPanel'
import SortingPanel from '../../../components/generics/SortingPanel'
import React, { useEffect } from 'react'
import connect from '../connect'
import useBooleanHandlers from '../../../hooks/useBooleanHandlers'
import FormUserStaff from '../../../components/forms/SaveUserStaff/'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'
import { useSelector } from 'react-redux'
import { selectUserPermissions } from '../../../selectors'

const Header = ({
  scope,
  users,
  onChangeOrder,
  onChangeFilters,
  addUser,
  getUsersNoIsUsfcr,
  fetchList,
}) => {
  const { trans } = useTrans()
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const permissions = useSelector(selectUserPermissions)
  const orderOptions = [
    {
      name: 'Registration date',
      value: 'createdAt',
      asc: 'Oldest first',
      desc: 'Newest first',
    },
  ]
  const { filters, order, count, items, notIsUsfcr } = users

  const refresh = () => {
    fetchList()
    getUsersNoIsUsfcr()
  }

  useEffect(() => {
    if (!notIsUsfcr.length) getUsersNoIsUsfcr()
  }, [])

  const isEnabled = () => {
    const routeKey = 'usfcr-staff'
    const module = permissions.find(p => p.key === routeKey)
    if (!module) return true

    const subModule = module.submodules.find(sm => sm.key === module.menuOption)

    if (!subModule) return true
    return subModule.create
  }

  return (
    <>
      <SpacingContainer py={3} px={0}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              weight="600"
              variant="h2"
              fontFamily="lexend"
              title
              style={{ alignItems: 'center', display: 'flex' }}
            >
              {trans('usfcr-staff')}{' '}
              <ShowTooltip sectionToShow="usfcr-staff-table" />
            </Typography>
          </Grid>
          {isEnabled() && (
            <Grid item>
              <Button variant="contained" color="primary" onClick={onOpenModal}>
                {trans('add-user')}
              </Button>
            </Grid>
          )}
          <FormUserStaff
            open={openModal}
            onClose={onCloseModal}
            handleAction={addUser}
            notIsUsfcr={notIsUsfcr}
            fetchList={refresh}
          />
        </Grid>
      </SpacingContainer>

      <SearchTopPanel
        scope={scope}
        filters={filters}
        onChangeFilters={onChangeFilters}
        filterOptions={[]}
      />

      {!!items.length && (
        <ListInfoRow
          count={count}
          description={trans('results')}
          sort={
            <SortingPanel
              order={order}
              scope={scope}
              orderOptions={orderOptions}
              onChangeOrder={onChangeOrder}
            />
          }
        />
      )}
    </>
  )
}

export default connect(Header)
