import { errorAlert, successAlert } from '../../contexts/AlertContext';
import { isLoadMoreAction } from './common';

export function onPending(state) {
  return Object.assign(state, { pending: true });
}

export function onPendingDone(state) {
  return Object.assign(state, { pending: false });
}

export function onPendingList(state, action) {
  return { ...state, pending: true, items: isLoadMoreAction(action) ? state.items : [] };
}

export function onItemDetails(state, action) {
  return Object.assign(state, { item: action.payload, pending: false });
}

export function onItemDetailsReject(state) {
  return Object.assign(state, { item: undefined, pending: false });
}

export function onSuccessAlert(state, action) {
  successAlert('Success');
}

export function onSuccess(state, action) {
  onSuccessAlert(state, action);
  Object.assign(state, { pending: false });
}

export function onRejectAlert(state, action) {
  if (action.error?.code === 'SubscribedContentOnlyError') return;
  errorAlert('api-error');
}

export function onReject(state, action) {
  onRejectAlert(state, action);
  Object.assign(state, { pending: false });
}

export function extendBuilderWithAsyncAction(builder, action, cases = {}) {
  return builder
    .addCase(action.pending, typeof cases.onPending === 'function' ? cases.onPending : onPending)
    .addCase(action.rejected, typeof cases.onReject === 'function' ? cases.onReject : onReject)
    .addCase(action.fulfilled, typeof cases.onSuccess === 'function' ? cases.onSuccess : onSuccess);
}
