import React from 'react'
import { Chip } from '../../../../components/common/Chip'
import { useTrans } from '../../../../services/i18n'

const colorMap = {
  Pending: '#ffac00',
  Uploaded: '#a5cb55',
  InProgress: '#9576FF',
  ReadyForReview: '#FFAC00',
  Approved: '#A5CB55',
  Denied: '#F32445',
}

const StatusChip = React.memo(({ status, expired = false }) => {
  const { trans } = useTrans()
  return (
    <div style={{ marginRight: '2rem' }}>
      {expired ? (
        <Chip label={trans('expired')} color={'secondary'} />
      ) : (
        <Chip
          label={trans(status)}
          color={'secondary'}
          style={{
            backgroundColor: colorMap[status.replace(/\s/g, '')] ?? colorMap.unknown,
          }}
        />
      )}
    </div>
  )
})

export default StatusChip
