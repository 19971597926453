import React from 'react'

import Typography from '../../../../components/common/Typography'
import { intlDateWithTimeFormat } from '../../../../utils/dateUtils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { formatToTitleCase } from '../../../../utils/helpers'

const Item = ({ item }) => {
  return (
    <TableRow>
      <TableCell align="left">
        <Typography variant="inherit" fontFamily="lexend">
          {intlDateWithTimeFormat(item.createdAt)}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="inherit" fontFamily="lexend">
          {item?.user?.name} {item?.user?.familyName}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="inherit" fontFamily="lexend">
          {formatToTitleCase(item.type)}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography variant="inherit" fontFamily="lexend">
          {item.description}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default Item
