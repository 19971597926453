import { useTrans } from '../../../../services/i18n'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Grid } from '@material-ui/core'
import { useState, useEffect } from 'react'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import Typography from '../../../../components/common/Typography'
import { IconPlus } from '../../../../components/svg/Icons'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import AddAssignedOrganizationsModal from '../../../../components/forms/AssignedOrganizations'
import ItemOrganization from './ItemOrganization'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import Button from '../../../../components/common/Buttons/Button'
import { Divider } from '../../../../components/common/Divider'
import { LoaderSmall } from '../../../../components/common/LoaderSmall'
import connect from '../../connect'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard'
import { emptyArray } from '../../../../utils/arrayUtils'
import { useSelector } from 'react-redux'
import { selectUserPermissions } from '../../../../selectors'

const AssignedOrganizations = ({
  userId,
  onAssignOrganizations,
  getAssignedOrganizations,
  removeUserOrganization,
}) => {
  const { trans } = useTrans()
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const [assignedOrganizations, setAssignedOrganizations] = useState([])
  const [organizationsNotInUser, setOrganizationsNotInUser] = useState([])
  const [pending, setPending] = useState(false)

  const permissions = useSelector(selectUserPermissions)
  const isEnabled = option => {
    const routeKey = 'usfcr-staff'
    const module = permissions.find(p => p.key === routeKey)
    if (!module) return true

    const subModule = module.submodules.find(sm => sm.key === module.menuOption)

    if (!subModule) return true
    return subModule[option]
  }

  const getOrganizations = ({ text = '', onlyNotUser = false } = {}) => {
    if (!onlyNotUser) setPending(true)
    getAssignedOrganizations({ userId, text }).then(res => {
      if (!onlyNotUser) setAssignedOrganizations(res.payload.organizations)
      setOrganizationsNotInUser(res.payload.organizationsNotInUser)
      setPending(false)
    })
  }

  const handleRemoveUserOrganization = params => {
    return removeUserOrganization(params).then(() => getOrganizations())
  }

  useEffect(() => {
    getOrganizations()
  }, [userId])

  return (
    <SpacingContainerBase py={0}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs zeroMinWidth>
            <Typography variant="h4" weight="bold">
              <CorporateFareIcon /> {trans('assigned-organizations')}
            </Typography>
          </Grid>
          {isEnabled('edit') && (
            <Grid item>
              <Button
                disabled={pending}
                isIcon
                color="primary"
                variant="contained"
                onClick={onOpenModal}
              >
                <IconPlus />
              </Button>
            </Grid>
          )}
        </Grid>

        <SpacingContainer px="0" py={2}>
          <Divider light />
          {pending && <LoaderSmall />}
          {!pending && !emptyArray(assignedOrganizations) && (
            <TableContainer component={Paper} radius={9}>
              <Table style={{ minWidth: 1000 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{trans('Organization')}</TableCell>
                    <TableCell>{trans('organization-status')}</TableCell>
                    <TableCell align="left">{trans('owner')}</TableCell>
                    <TableCell align="left">{trans()}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assignedOrganizations.map(org => (
                    <ItemOrganization
                      item={org}
                      key={org.id}
                      removeUserOrganization={handleRemoveUserOrganization}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!pending &&
            emptyArray(assignedOrganizations) &&
            isEnabled('edit') && (
              <Grid item xs zeroMinWidth>
                <NothingFoundCard
                  px={5}
                  py={10}
                  title={trans('no-organizations-assigned-yet')}
                  size="medium"
                >
                  <br />
                  <Button
                    disabled={pending}
                    color="primary"
                    variant="contained"
                    onClick={onOpenModal}
                  >
                    {trans('assign')}
                  </Button>
                </NothingFoundCard>
              </Grid>
            )}
        </SpacingContainer>
      </Paper>

      <AddAssignedOrganizationsModal
        userId={userId}
        open={openModal}
        onClose={onCloseModal}
        organizations={organizationsNotInUser}
        handleAction={onAssignOrganizations}
        fullFilledAction={getOrganizations}
      />
    </SpacingContainerBase>
  )
}
export default connect(AssignedOrganizations)
