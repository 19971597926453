import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTrans } from '../../../../../services/i18n'
//import Typography from '../../../../../components/common/Typography'
import { ModalDialog } from '../../../../../components/common/Dialogs'
import {
  Paper,
  SpacingContainer,
} from '../../../../../components/common/Containers'
import { Button } from '../../../../../components/common/Buttons'
import { useAlertContext } from '../../../../../contexts/AlertContext'
import connect from '../connect'
import { SelectField } from '../../../../../components/common/Inputs'
import { MenuItem } from '@material-ui/core'

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  enableReinitialize: true,
}

const AddNewForm = ({
  open,
  onClose,
  item = null,
  saveRequestTemplateForm,
  requestTemplateId,
  getList,
  forms,
}) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [pending, setPending] = React.useState(false)
  const [form, setForm] = useState('')
  const [busy, setBusy] = useState(false)
  const [arrayForms, setArrayForms] = useState([])

  const changeSelect = (e) => {
    setForm(e.target.value)
  }

  const onSubmit = async () => {
    try {
      setBusy(true)
      if (!form) return
      setPending(true)
      const { payload } = await saveRequestTemplateForm({
        form: form,
        id: item?.id,
        requestTemplateId: requestTemplateId,
      })
      setPending(false)
      successAlert(payload.message)

      closeModal()
      getList({
        requestTemplateId: requestTemplateId,
        text: '',
        order: ['createdAt', 'desc'],
        filters: {},
        pagination: { page: 1, perPage: 10 },
      })
    } catch (error) {
      errorAlert('Server error, please refresh the page or try again later.')
      setPending(false)
      closeModal()
    }
  }

  const closeModal = () => {
    setForm('')
    setBusy(false)
    onClose()
  }

  useEffect(() => {
    if (item && forms) {
      const form = forms.find((form) => form.id === item.requestId)
      if (form) setForm(form)
    }
  }, [item, forms])

  useEffect(() => {
    if (forms) {
      const arrayF = forms.filter((form) => form.id !== item?.requestId)
      setArrayForms(arrayF)
    }
  }, [forms])

  return (
    <ModalDialog
      title={item ? trans('edit-form-template') : trans('add-form-template')}
      open={open}
      onClose={onClose}
      actions={
        <Grid container>
          <Grid
            item
            sm={12}
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              variant="contained"
              onClick={closeModal}
              style={{ marginRight: '16px' }}
            >
              {trans('cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              pending={pending}
            >
              {item ? trans('save') : trans('add')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Paper>
        <SpacingContainer>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item sm={12} xs={12}>
              <SelectField
                type="select"
                placeholder={'Required'}
                name={'form'}
                value={form}
                getRenderValue={(value) => `${value.name}`}
                onChange={changeSelect}
                error={!form && busy && trans('required')}
              >
                {arrayForms.map((i) => (
                  <MenuItem value={i} key={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
          </Grid>
        </SpacingContainer>
      </Paper>
    </ModalDialog>
  )
}

export default connect(AddNewForm)
