import { ACCOUNT_FEATURES } from '../../../../constants/subscriptionSettings'

export const LABELS = {
  [ACCOUNT_FEATURES.EXPIRED]: 'default-expired-account-features',
  [ACCOUNT_FEATURES.LIMITED]: 'default-limited-account-features',
  [ACCOUNT_FEATURES.ADVANTAGE]: 'default-advantage-account-features',
  [ACCOUNT_FEATURES.PRO]: 'default-pro-account-features',
  [ACCOUNT_FEATURES.FREE_TRIAL]: 'default-free-trial-account-features',
  [ACCOUNT_FEATURES.SAP_ADVANTAGE]: 'default-sap-advantage-account-features',
  [ACCOUNT_FEATURES.APP_MAX]: 'default-app-max-account-features',
  [ACCOUNT_FEATURES.PROCUREMENT_PRO]:
    'default-procurement-pro-account-features',
}

export const SUCCESS_MESSAGES = {
  [ACCOUNT_FEATURES.EXPIRED]: 'Default Expired features has been updated',
  [ACCOUNT_FEATURES.LIMITED]: 'Default Limited features has been updated',
  [ACCOUNT_FEATURES.ADVANTAGE]: 'Default Advantage features has been updated',
  [ACCOUNT_FEATURES.PRO]: 'Default Pro features has been updated',
  [ACCOUNT_FEATURES.FREE_TRIAL]: 'Default Free Trial features has been updated',
  [ACCOUNT_FEATURES.SAP_ADVANTAGE]:
    'Default SAP Advantage features has been updated',
  [ACCOUNT_FEATURES.APP_MAX]: 'Default APP Max features has been updated',
  [ACCOUNT_FEATURES.PROCUREMENT_PRO]:
    'Default Procurement Pro features has been updated',
}
