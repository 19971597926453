import React from 'react';
import { useMediaQueryDown } from '../../../../../hooks/useMediaQuery';

export default function AdaptiveView({ children, wrapper, wrapperMobile, breakPoint = 'sm' }) {
  const isSmallScreen = useMediaQueryDown(breakPoint);

  return isSmallScreen
    ? React.isValidElement(wrapperMobile)
      ? React.cloneElement(wrapperMobile, {}, children)
      : children
    : React.isValidElement(wrapper)
    ? React.cloneElement(wrapper, {}, children)
    : children;
}
