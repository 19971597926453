import React, { useEffect, useState } from 'react'
import { SpacingContainer, Paper } from '../../components/common/Containers'
import Typography from '../../components/common/Typography'
import { useTrans } from '../../services/i18n'
import Box from '@material-ui/core/Box'
import { Tab, Tabs } from '../../components/common/Tabs'
import styles from './styles.module.css'
import useTabs from '../../hooks/useTabs'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import ExistingUsers from './components/existingUsers'
import connect from './connect'

const SubscriptionSettings = ({
  getFeatures,
  authUser,
  subscriptionSettingsData,
  changeTab,
  optionTab,
}) => {
  const { trans } = useTrans()
  const navigate = useNavigate()
  const [tab, onChangeTab] = useTabs(1)

  useEffect(() => {
    getFeatures()
    changeTab(tab)
  }, [])

  const tabList = [
    {
      key: 'expiredFeatures',
      idTab: 1,
      label: 'Expired',
    },
    /*{ // Hided by APP-5377
      key: 'limitedFeatures',
      idTab: 2,
      label: 'Limited',
    },*/
    {
      key: 'advantageFeatures',
      idTab: 3,
      label: 'Advantage',
    },
    {
      key: 'proFeatures',
      idTab: 4,
      label: 'Pro',
    },
    /*{// Hided by APP-5377
      key: 'freeTrialFeatures',
      idTab: 5,
      label: 'Free Trial',
    },*/
    {
      key: 'sapAdvantageFeatures',
      idTab: 6,
      label: 'SAP Advantage',
    },
    {
      key: 'appMaxFeatures',
      idTab: 7,
      label: 'APP Max',
    },
    {
      key: 'procurementProFeatures',
      idTab: 8,
      label: 'Procurement Pro',
    },
  ]

  useEffect(() => {
    if (
      authUser &&
      (authUser.role === 'manager' || authUser.role === 'consulting')
    ) {
      navigate('/organizations')
    }
  }, [authUser, navigate])

  useEffect(() => {
    changeTab(tab)
  }, [tab])

  return (
    <SpacingContainer py={7} px={7}>
      <Typography variant="h2" weight="bold">
        {trans('subscription-settings')}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 20 }}>
        <Tabs
          key="subscription-tabs"
          indicatorColor="primary"
          TabIndicatorProps={{
            style: {
              height: '3px',
            },
          }}
          className={styles.tabsContainer}
          value={tab}
          onChange={onChangeTab}
          scrollButtons="off"
        >
          {tabList &&
            tabList.map(item => (
              <Tab
                key={item.idTab}
                value={item.idTab}
                label={item.label}
                className={clsx(
                  styles.tab,
                  tab === item.idTab && styles.activeTab
                )}
              />
            ))}
        </Tabs>

        <Paper
          style={{
            marginTop: '2rem',
          }}
          key={`tab-panel-paper-existing-users`}
        >
          <ExistingUsers />
        </Paper>
      </Box>
    </SpacingContainer>
  )
}

export default connect(SubscriptionSettings)
