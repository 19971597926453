import React from 'react'
import { SvgIcon } from '../../../common/SvgIcon'
import { simpleIconPropsTypes } from '../../propTypes'

export const IconReleases = props => {
  const { width = 24, height = 24 } = props

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 24 24">
      <path d="M5 3H19C20.1 3 21 3.9 21 5V15L15 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM19 14V5H5V19H14V14H19ZM12 14H7V12H12V14ZM7 10H17V8H7V10Z" />
    </SvgIcon>
  )
}

IconReleases.propTypes = simpleIconPropsTypes
