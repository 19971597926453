import { Grid, Hidden, IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { IconClose } from '../../../../svg/Icons/IconClose';
import Typography from '../../../Typography';
import { Switch } from '../../../Inputs';

export default function DialogTitle({
  title,
  helperText,
  onClose,
  children,
  disableShadows,
  checkedToggle,
  showToggleOption = false,
  onCheckedToggleOption,
}) {
  const classes = useStyles();

  return (
    <Grid container className={clsx(classes.title, disableShadows && classes.disableShadows)} wrap="nowrap">
      <Grid item xs className={classes.titleText} zeroMinWidth>
        <Typography fontFamily="lexend" weight="500" variant="h4" disableLineHeight noWrap>
          {title}
        </Typography>
        {helperText && (
          <Hidden smDown>
            <Typography color="hint" variant="body2" weight="500" noWrap>
              {helperText}
            </Typography>
          </Hidden>
        )}
        {showToggleOption && (
          <Switch checked={Boolean(checkedToggle)} onChange={(e) => onCheckedToggleOption(e.target.checked)} />
        )}
      </Grid>
      {children &&
        React.Children.map(children, (child, index) => (
          <Grid key={index} item xs="auto" className={classes.action}>
            {child}
          </Grid>
        ))}
      {onClose && (
        <Grid item xs="auto" className={classes.action}>
          <IconButton onClick={onClose} disableRipple>
            <IconClose />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    height: 74,
    position: 'relative',
    zIndex: theme.zIndex.appBar,
    boxShadow: '0 10px 20px 0 rgba(78, 89, 120, 0.06)',
    '& > *:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.gray.background}`,
    },
  },
  disableShadows: { boxShadow: 'none' },
  titleText: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 30px',
    alignItems: 'center',
  },
  action: {
    minWidth: 74,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
