import { useEffect, useState } from 'react'
import Typography from '../../components/common/Typography'
import { useTrans } from '../../services/i18n'
import connect from './connect'
import Loader from '../../components/common/Loader'
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase'
import AddTooltip from './components/addTooltip'
import ListTooltips from './components/listTooltips'
import { emptyArray } from '../../utils/arrayUtils'
import useTabs from '../../hooks/useTabs'
import { Tab, Tabs, TabPanel } from '../../components/common/Tabs'
import Box from '@material-ui/core/Box'
import styles from './styles.module.css'
import clsx from 'clsx'
import PaginationSmall from '../../components/common/Paginations/PaginationSmall'
import { selectUserPermissions } from '../../selectors'
import { useSelector } from 'react-redux'
//Onboarding
import AddOnboarding from './onboarding/addOnboarding'
import ListOnboardings from './onboarding/listOnboardings'

//AddBanner
import AddBanner from './banner/addBanner'

const TooltipsManagement = ({ getTooltips, getOnboardings, tooltips }) => {
  const permissions = useSelector(selectUserPermissions)

  const { trans } = useTrans()
  const [tab, onChangeTab] = useTabs(1)
  const [onboardingItems, setOnboardingItems] = useState({
    items: [],
    count: 0,
  })
  const [pendingOnboardings, setPendingOnboardings] = useState(false)
  const [paginationOnboarding, setPaginationOnboarding] = useState({
    page: 1,
    perPage: 10,
  })

  const getOnboardingViews = () => {
    setPendingOnboardings(true)
    Promise.resolve(getOnboardings({ pagination: paginationOnboarding }))
      .then(res => {
        if (res.payload) setOnboardingItems(res.payload)
      })
      .catch(err => {})
      .finally(() => setPendingOnboardings(false))
  }

  const onChangePage = e => {
    setPaginationOnboarding({ ...paginationOnboarding, page: e })
  }

  useEffect(() => {
    getTooltips()
    getOnboardingViews()
  }, [])

  useEffect(() => {
    getOnboardingViews()
  }, [paginationOnboarding])

  const isEnabled = (att = 'view') => {
    const routeKey = 'tooltips-management'
    const module = permissions.find(p => p.key === routeKey)
    if (!module) return true

    const subModule = module.submodules.find(sm => sm.key === module.menuOption)

    if (!subModule) return true
    return subModule[att]
  }

  return (
    <SpacingContainerBase>
      {false && <Loader open={false} />}
      <Typography weight="600" variant="h2" fontFamily="lexend" title>
        {trans('tooltips-management')}
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 20 }}>
        <Tabs
          indicatorColor="primary"
          TabIndicatorProps={{
            style: {
              height: '3px',
            },
          }}
          className={styles.tabsContainer}
          value={tab}
          onChange={onChangeTab}
          scrollButtons="on"
        >
          <Tab
            key={1}
            value={1}
            label={trans('tooltips')}
            className={clsx(styles.tab, tab === 1 && styles.activeTab)}
          />
          <Tab
            key={2}
            value={2}
            label={trans('onboarding')}
            className={clsx(styles.tab, tab === 2 && styles.activeTab)}
          />
          <Tab
            key={3}
            value={3}
            label={trans('banner')}
            className={clsx(styles.tab, tab === 3 && styles.activeTab)}
          />
        </Tabs>
        <TabPanel value={tab} tab={1} index={1}>
          {isEnabled('create') && <AddTooltip />}
          {!emptyArray(tooltips) && <ListTooltips />}
        </TabPanel>
        <TabPanel value={tab} tab={2} index={2}>
          {isEnabled('create') && (
            <AddOnboarding handleSuccess={getOnboardingViews} />
          )}
          <ListOnboardings
            handleSuccessAction={getOnboardingViews}
            loading={pendingOnboardings}
            items={onboardingItems.items}
          />
          <PaginationSmall
            page={paginationOnboarding.page}
            perPage={paginationOnboarding.perPage}
            count={onboardingItems.count}
            onChangePage={onChangePage}
            onChangeRowPerPage={() => {}}
            pending={pendingOnboardings}
          />
        </TabPanel>
        <TabPanel value={tab} tab={3} index={3}>
          <AddBanner></AddBanner>
        </TabPanel>
      </Box>
    </SpacingContainerBase>
  )
}

export default connect(TooltipsManagement)
