import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { API, SCOPE_USFCR_STAFF } from '../../constants'
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common'
import { application } from '../../services/application'

import {
  extendListBuilder,
  generateListActions,
} from '../helpers/listActionsHelpers'

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  notIsUsfcr: [],
  order: ['createdAt', 'desc'],
  userDetail: null
}

const activeUserStaff = createAsyncThunk(
  'userStaff/activeUserStaff',
  params => {
    return application.call(API.USFCR_STAFF.ACTIVE_USER_STAFF, params)
  }
)
function onActiveUserStaff(state, action) {
  const user = action.payload.user
  const copyItems = [...state.items]
  const index = copyItems.findIndex(i => i.id === user.id)
  if (index !== -1) {
    copyItems[index].usfcrActive = user.usfcrActive
    return Object.assign(state, {
      items: copyItems,
    })
  }
  if (state.userDetail && state.userDetail.id === action.payload.user.id) {
    const copy = {...state.userDetail}
    copy.usfcrActive = action.payload.user.usfcrActive
    Object.assign(state, { userDetail: { ...copy } })
  }
}

const removeUserStaff = createAsyncThunk(
  'userStaff/removeUserStaff',
  params => {
    return application.call(API.USFCR_STAFF.REMOVE_USER_STAFF, params)
  }
)
function onRemoveUserStaff(state, action) {
  const user = action.payload.user
  const copyItems = [...state.items]
  const index = copyItems.findIndex(i => i.id === user.id)
  if (index !== -1) {
    copyItems.splice(index, 1)
    return Object.assign(state, {
      items: copyItems,
      count: state.count - 1
    })
  }
}

const addUser = createAsyncThunk('userStaff/add', params => {
  return application.call(API.USFCR_STAFF.ADD_USER, params)
})

const editUser = createAsyncThunk('userStaff/update', params => {
  return application.call(API.USFCR_STAFF.EDIT_USER, params)
})

const getUsersNoIsUsfcr = createAsyncThunk('userStaff/getUsersNoIsUsfcr', params => {
  return application.call(API.USFCR_STAFF.GET_USERS_NO_IS_USFCR, params)
})
function onUsersNoIsUsfcr(state, action) {
  Object.assign(state, {
    notIsUsfcr: action.payload
  })
}

const findOne = createAsyncThunk('userStaff/findOne', params => {
  return application.call(API.USFCR_STAFF.FIND_ONE, params)
})
function onFindOne(state, action) {
  Object.assign(state, {
    userDetail: action.payload
  })
}

const onAssignOrganizations = createAsyncThunk('userStaff/assignOrganizations', params => {
  return application.call(API.USFCR_STAFF.ASSIGN_ORGS, params)
})

const getAssignedOrganizations = createAsyncThunk('userStaff/getAssignedOrganizations', params => {
  return application.call(API.USFCR_STAFF.GET_ASSIGNED_ORGS, params)
})

const removeUserOrganization = createAsyncThunk('userStaff/removeUserOrganization', params => {
  return application.call(API.USFCR_STAFF.REMOVE_USER_ORG, params)
})

const getActivityHistory = createAsyncThunk('userStaff/getActivityHistory', params => {
  return application.call(API.USFCR_STAFF.GET_ACTIVITY_HISTORY, params)
})

//Actions
const listActions = generateListActions({
  scope: SCOPE_USFCR_STAFF,
  apiMethod: {
    GET_LIST: API.USFCR_STAFF.GET_LIST,
  },
  getStore: store => store.usfcrStaff,
})

const disabledTwoFactorAuth = createAsyncThunk('users/disabledTwoFactorAuth', async (params) => {
  return application.call(API.USERS.DISABLE_TWO_FACTOR_AUTH, params)
})
function onDisabledTwoFactorAuth(state, action) {
  if (action?.payload?.user) {
    const copy = [...state.items]
    const index = copy.findIndex(i => i.id === action.payload.user)
    if (index !== -1) {
      copy[index]['2faActive'] = null
      Object.assign(state, {
        items: [...copy],
      })
    }
  }
}

export const actions = {
  ...listActions,
  activeUserStaff,
  removeUserStaff,
  addUser,
  editUser,
  getUsersNoIsUsfcr,
  findOne,
  onAssignOrganizations,
  getAssignedOrganizations,
  removeUserOrganization,
  getActivityHistory,
  disabledTwoFactorAuth
}

export default createReducer(INITIAL_STATE, builder => {
  return extendListBuilder(builder, actions)
    .addCase(activeUserStaff.fulfilled, onActiveUserStaff)
    .addCase(removeUserStaff.fulfilled, onRemoveUserStaff)
    .addCase(getUsersNoIsUsfcr.fulfilled, onUsersNoIsUsfcr)
    .addCase(findOne.fulfilled, onFindOne)
    .addCase(disabledTwoFactorAuth.fulfilled, onDisabledTwoFactorAuth)
})
