import { connect } from 'react-redux'
import { actions } from '../../store/subscriptionSettings'

function mapStateToProps(state) {
  return {
    subscriptionSettingsData: state.subscriptionSettings.subscriptionSettingsData,
    componentFeatures: state.subscriptionSettings.componentFeatures,
    optionTab: state.subscriptionSettings.optionTab,
    tabTier: state.subscriptionSettings.tabTier,
    authUser: state.auth,
    keyTabOptions: state.subscriptionSettings.keyTabOptions,
    subscriptionSettings: state.subscriptionSettings.subscriptionSettings,
    curretTabSettings: state.subscriptionSettings.curretTabSettings
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getFeatures: (params) => dispatch(actions.getFeatures(params)),
    changeTab: (params) => dispatch(actions.changeTab(params)),
    changeCurrentTabSettings: (params) => dispatch(actions.changeCurrentTabSettings(params)),
    getKeepTrashedNotifications: (params) => dispatch(actions.getKeepTrashedNotifications(params)),
    updateKeepTrashedNotification: (params) => dispatch(actions.updateKeepTrashedNotification(params)),
    updateApiKey: (params) => dispatch(actions.updateApiKey(params)),
    updateSubscriptionSettings: (params) => dispatch(actions.updateSubscriptionSettings(params)),
    saveSubscriptionSettings: (params) => dispatch(actions.saveSubscriptionSettings(params)),
    updateOrganizationsSettings: (params) => dispatch(actions.updateOrganizationsSettings(params)),
    setComponentFeatures: (params) => dispatch(actions.setComponentFeatures(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps);
