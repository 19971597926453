import React from 'react';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Typography from '../../../components/common/Typography';
import { TextField } from '../../../components/common/Inputs';
import { Button } from '../../../components/common/Buttons';
import useStyles from '../styles';
import { useForm } from '../../../hooks/useForm';
import { schemaToInitialState } from '../../../utils/validationUtils';
import makeValidationSchema from '../../../hooks/makeValidationSchema';
import { useTrans } from '../../../services/i18n';

const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    password: Yup.string()
      .password(trans('incorrect-password'))
      .required(trans('empty-not-allowed', { field: trans('password') })),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], trans('passwords-match'))
      .required(trans('empty-not-allowed', { field: trans('confirm-password') })),
  })
);

function ChangePasswordForm({ onSubmit }) {
  const { trans } = useTrans();
  const classes = useStyles();
  const schema = useScheme();

  const formik = useForm(schema, schemaToInitialState(schema), onSubmit, {
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <div className={classes.formBack}>
      <Grid container spacing={0} justifyContent="center" className={classes.content} xs={12} alignItems="center">
        <Grid item xs={12} className={classes.signForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h1Medium" fontFamily="lexend" weight="500">
                {trans('set-new-password')}
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label={trans('password')}
                type="password"
                placeholder={trans('password')}
                {...formik.getFieldProps('password')}
                required
                name="password"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                type="password"
                label={trans('confirm-password')}
                placeholder={trans('confirm-password')}
                {...formik.getFieldProps('confirmPassword')}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                id="change-password-btn"
                variant="contained"
                size="large"
                color="primary"
                onClick={formik.handleSubmit}
              >
                {trans('change-password')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ChangePasswordForm;
