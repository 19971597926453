import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useEffect, useState } from 'react'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'

import connect from '../../connect'
import { Button } from '../../../../components/common/Buttons'
import { TextField } from '../../../../components/common/Inputs'

import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@material-ui/core'

import EditOnboarding from '../editOnboarding'
import styles from '../../styles.module.css'
import { LoaderSmall } from '../../../../components/common/LoaderSmall'
import Item from './Item'

const AddOnboarding = ({ items, loading, handleSuccessAction }) => {
  const { trans } = useTrans()
  const [open, onOpen, onClose] = useBooleanHandlers()
  const { successAlert, errorAlert } = useAlertContext()

  const editMessage = item => {
    //changeCurrentSection(item)
    onOpen()
  }

  return (
    <SpacingContainer py={2}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              variant="h4"
              weight="500"
              fontFamily="lexend"
              style={{ alignItems: 'center', display: 'flex' }}
            >
              {trans('onboarding-step')}{' '}
            </Typography>
            <Typography
              style={{ marginTop: '16px' }}
              variant="body2"
              fontFamily="lexend"
            >
              {trans('manage-added-onboardings')}
            </Typography>
          </Grid>
        </Grid>
        <Divider light style={{ marginTop: '20px' }} />

        {loading ? (
          <LoaderSmall />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableHeadCell} align="left">
                  Section
                </TableCell>
                <TableCell align="left" className={styles.tableHeadCell}>
                  Page
                </TableCell>
                <TableCell align="left" className={styles.tableHeadCell}>
                  Steps
                </TableCell>
                <TableCell
                  className={styles.tableHeadCell}
                  style={{ width: '100px' }}
                  align="center"
                >
                  {trans('actions')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(s => (
                <Item
                  item={s}
                  key={s.view}
                  handleSuccessAction={handleSuccessAction}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>
      <EditOnboarding open={open} onClose={onClose} />
    </SpacingContainer>
  )
}

export default connect(AddOnboarding)
