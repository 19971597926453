import Grid from '@material-ui/core/Grid'
import * as Yup from 'yup'

import React, { useEffect, useState } from 'react'
import { Button } from '../../../../components/common/Buttons'
import { ModalDialog } from '../../../../components/common/Dialogs'
import { useTrans } from '../../../../services/i18n'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { SpacingContainer } from '../../../../components/common/Containers'
import CheckBox from '../../../../components/common/Inputs/CheckBox'
import connect from '../../connect'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '../../../../components/common/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { emptyArray } from '../../../../utils/arrayUtils'
import { useForm } from '../../../../hooks/useForm'
import makeValidationSchema from '../../../../hooks/makeValidationSchema'
import { schemaToInitialState } from '../../../../utils/validationUtils'
import { TextField } from '../../../../components/common/Inputs'

const useScheme = makeValidationSchema(trans =>
  Yup.object().shape({
    fileName: Yup.string().required('File name is a required field'),
  })
)

const AddFileToFormModal = ({
  open,
  onClose,
  saveFileForm,
  type = 'principal',
  parentFormId = null,
  file,
  currentFormDetail,
}) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const schema = useScheme()

  const saveFile = () => {
    setIsSend(true)
    const values = {
      ...formik.values,
    }

    saveFileForm({
      ...values,
      type: type,
      id: currentFormDetail.id,
      edit: file ? true : false,
      fileNameOld: file,
    })
      .then(res => {
        if (res.payload.success) {
          successAlert(
            file ? 'File updated successfully!' : 'File save successfully!'
          )
        } else {
          errorAlert(res.payload.message)
        }

        closeModal()
      })
      .catch(() => {
        errorAlert('Error')
        closeModal()
      })
  }

  const formik = useForm(
    schema,
    schemaToInitialState(schema, file ? { fileName: file } : {}),
    saveFile,
    {
      validateOnChange: false,
      validateOnBlur: false,
      enableReinitialize: true,
    }
  )

  const closeModal = () => {
    setIsSend(false)
    onClose()
  }

  const executeSubmit = () => {
    formik.handleSubmit()
  }

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={file ? trans('edit-file-form') : trans('add-file-to-form')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSend}
              onClick={closeModal}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={() => executeSubmit()}
            >
              {trans('save')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer px={3} py={0}>
        <TextField
          label={trans('file-name')}
          placeholder={trans('required')}
          required
          maxLength={255}
          {...formik.getFieldProps('fileName')}
        />
      </SpacingContainer>
    </ModalDialog>
  )
}

export default connect(AddFileToFormModal)
