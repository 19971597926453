import { Button } from '../../../../components/common/Buttons'
import { Divider } from '../../../../components/common/Divider'
import { emptyArray } from '../../../../utils/arrayUtils'
import { Grid } from '@material-ui/core'
import { IconPlus } from '../../../../components/svg/Icons'
import { IconPipelines } from '../../../../components/svg/Icons/Menu'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import connect from '../connect'
import Item from './item'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import AddOrganizationModal from './addOrganizationModal'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const OrganizationHierarchy = ({
  organizationHierarchy,
  createSubsidiaryOrganization,
  updateParentOrganization,
  detail,
}) => {
  const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const permissions = useSelector(selectUserPermissions)
  return (
    <>
      <SpacingContainerBase py={1}>
        <Paper component={SpacingContainer} py={3} px="5sm3">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs zeroMinWidth>
              <Typography
                variant="h4"
                weight="500"
                fontFamily="lexend"
                style={{ alignItems: 'center', display: 'flex' }}
              >
                <IconPipelines />{' '}
                <span style={{ marginLeft: '10px', display: 'block' }}>
                  Organization Hierarchy
                </span>
                <ShowTooltip sectionToShow="organization-hierarchy" />
              </Typography>
            </Grid>
            {isEnabled(
              permissions,
              'organization',
              'add-organization',
              'view'
            ) && (
              <Grid item>
                <Button
                  onClick={onOpenModal}
                  isIcon
                  color="primary"
                  variant="contained"
                >
                  <IconPlus />
                </Button>
                <AddOrganizationModal
                  onClose={onCloseModal}
                  open={openModal}
                  saveOrganization={createSubsidiaryOrganization}
                  parentId={detail.parentOrganizationId || detail.id}
                />
              </Grid>
            )}
          </Grid>
          {!emptyArray(organizationHierarchy) && (
            <SpacingContainer px="0" py={2}>
              <Divider light />
              <TableContainer component={Paper} radius={9}>
                <Table style={{ minWidth: 1000 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Organization</TableCell>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="left">DateCreated</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {organizationHierarchy.map(org => (
                      <Item
                        updateParentOrganization={updateParentOrganization}
                        detail={org}
                        key={org.id}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </SpacingContainer>
          )}
        </Paper>
      </SpacingContainerBase>
    </>
  )
}

export default connect(OrganizationHierarchy)
