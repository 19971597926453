import React, { useEffect, useState } from 'react'
import { useTrans } from '../../../../services/i18n'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Grid } from '@material-ui/core'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'
import Typography from '../../../../components/common/Typography'
import HistoryIcon from '@mui/icons-material/History'
import { Divider } from '../../../../components/common/Divider'
import PaginationSmall from '../../../../components/common/Paginations/PaginationSmall'
import { LoaderSmall } from '../../../../components/common/LoaderSmall'
import { ROWS_PER_PAGE_OPTIONS } from '../../../../hooks/usePagination'
import usePopover from '../../../../hooks/usePopover'
import {
  ContextMenuListItem,
  Popover,
} from '../../../../components/common/Dialogs/Popover'
import ListItemTextBase from '../../../../components/common/Containers/Base/ListItemTextBase'
import Button from '../../../../components/common/Buttons/Button'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import connect from '../../connect'
import ItemActivity from './ItemActivity'
import FilterSimple from '../../../../components/generics/FilterSimple'
import { EVENT_LOG_TYPES } from '../../../../constants/filter'
import NothingFoundCard from '../../../../components/generics/Cards/NothingFoundCard'
import { emptyArray } from '../../../../utils/arrayUtils'

const LatestActivity = ({ userId, getActivityHistory }) => {
  const { trans } = useTrans()
  const [items, setItems] = useState([])
  const [count, setCount] = useState(0)
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover()
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 })
  const [order, setOrder] = useState(['createdAt', 'desc'])
  const [filter, setFilter] = useState({ name: 'All types', value: 'all' })
  const [pending, setPending] = useState(false)

  const fetchHistory = () => {
    setPending(true)
    getActivityHistory({ userId, pagination, order, filter }).then(res => {
      const { count, items } = res.payload
      setItems(items)
      setCount(count)
      setPending(false)
    })
  }

  const onChangePage = page =>
    setPagination({ page, perPage: pagination.perPage })
  const onChangeRowPerPage = perPage =>
    setPagination({ page: pagination.page, perPage })
  const onChangeFilter = e => setFilter(e)

  useEffect(() => {
    fetchHistory()
  }, [pagination, filter, userId])

  return (
    <SpacingContainerBase>
      <Paper component={SpacingContainer} py={4} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs zeroMinWidth>
            <Typography variant="h4" weight="bold">
              <HistoryIcon /> {trans('activity-history')} • {count}
            </Typography>
          </Grid>
          <Grid item>
            <FilterSimple
              filter={filter}
              scope={'history-usfcr-staff'}
              filterOptions={EVENT_LOG_TYPES}
              onChangeFilter={onChangeFilter}
            />
          </Grid>
        </Grid>

        <SpacingContainer px="0" py={3}>
          <Divider light />
          {pending && <LoaderSmall />}
          {!pending && !!items.length && (
            <TableContainer component={Paper} radius={9}>
              <Table style={{ minWidth: 1000 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{trans('timestamp')}</TableCell>
                    <TableCell align="left">{trans('organization')}</TableCell>
                    <TableCell align="left">{trans('event-type')}</TableCell>
                    <TableCell align="left">{trans('description')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map(item => (
                    <ItemActivity item={item} key={item.id} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </SpacingContainer>
        {!pending && !!items.length && (
          <Grid container alignItems="center">
            <Grid item xs zeroMinWidth>
              <PaginationSmall
                divider="top"
                page={pagination.page}
                perPage={pagination.perPage}
                count={count}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                pending={pending}
                actions={
                  <Button size="small" onClick={onOpenPopover}>
                    <Typography
                      variant="body2"
                      weight="bold"
                      color="inherit"
                      style={{ marginRight: '10px' }}
                    >
                      {trans('results-per-page')}
                    </Typography>
                    <Typography variant="body2" weight="bold" color="inherit">
                      {pagination.perPage}
                    </Typography>
                    <ArrowDropDown />
                  </Button>
                }
              />
            </Grid>
          </Grid>
        )}
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClosePopover}
        >
          {ROWS_PER_PAGE_OPTIONS.map(rowPageList => (
            <ContextMenuListItem
              button
              onClick={() => onChangeRowPerPage(rowPageList)}
              onClickCB={onClosePopover}
              key={rowPageList}
            >
              <ListItemTextBase>{rowPageList}</ListItemTextBase>
            </ContextMenuListItem>
          ))}
        </Popover>
        {!pending && emptyArray(items) && (
          <Grid item xs zeroMinWidth>
            <NothingFoundCard
              px={3}
              py={10}
              title={trans('this-user-has-no-activity-yet')}
              size="medium"
            ></NothingFoundCard>
          </Grid>
        )}
      </Paper>
    </SpacingContainerBase>
  )
}

export default connect(LatestActivity)
