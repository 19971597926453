import Drawer from '@material-ui/core/Drawer'
import Link from '@material-ui/core/Link'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useMediaQueryDown } from '../../../../hooks/useMediaQuery'
import { useTrans } from '../../../../services/i18n'
import Typography from '../../../common/Typography'
import * as MENU_ICONS from '../../../svg/Icons/Menu'
import { IconDashboard } from '../../../svg/Icons/Menu'
import { ColoredLogoSvg } from '../../../svg/logos'
import { IconMenuControl } from '../../../svg'
import useStyles from './styles'
import { Collapse } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useAppMenu } from './hooks/useActiveGroup'
import UserMenu from '../UserMenu'
import IconButton from '@material-ui/core/IconButton'
import connect from './connect'
import { useSelector } from 'react-redux'
import {
  selectUserPermissions,
  isEnabled,
  selectUserRole,
} from '../../../../selectors'

const AppMenu = ({ setDrawerOpen, openDrawer, setDrawerClose, authUser }) => {
  const classes = useStyles()
  const { trans } = useTrans()
  const isSmallScreen = useMediaQueryDown('sm')
  const [menuList, setMenuList] = useState([])

  const { menu, setActive } = useAppMenu()

  const permissions = useSelector(selectUserPermissions)
  const userRole = useSelector(selectUserRole)

  const isEnabledRoute = route => {
    if (route !== 'admin-portal-roles') {
      return isEnabled(permissions, route)
    } else if (userRole?.key === 'admin') {
      return true
    }
    return false
  }
  return (
    <Drawer
      variant="permanent"
      className={clsx(
        classes.drawer,
        openDrawer ? classes.drawerOpen : classes.drawerClose
      )}
      classes={{ paper: openDrawer ? classes.drawerOpen : classes.drawerClose }}
      anchor={isSmallScreen ? 'bottom' : 'left'}
    >
      <div className={classes.topPart}>
        <ColoredLogoSvg short={!openDrawer} />
        {openDrawer && (
          <span style={{ fontSize: '25px', marginLeft: '10px' }}> Admin</span>
        )}
      </div>

      <Grid container direction={'column'} spacing={3}>
        {menu.map((group, groupIndex) => {
          if (group.items.length === 0) return null
          const GroupIcon = MENU_ICONS[group.icon] || IconDashboard
          return (
            <Grid container item key={group.key} direction={'column'}>
              <Grid item style={{ marginBottom: '8px' }}>
                <UserMenu></UserMenu>
              </Grid>

              <Grid item style={{ marginLeft: '10px' }}>
                <Collapse
                  in={group.active}
                  appear={true}
                  unmountOnExit={false}
                  mountOnEnter={true}
                >
                  <Grid
                    container
                    direction={'column'}
                    spacing={3}
                    className={classes.groupContent}
                  >
                    <Grid item />
                    {group.items
                      .filter(
                        i =>
                          i.showMenu &&
                          authUser &&
                          i.roles.includes(authUser.role) &&
                          isEnabledRoute(
                            i.key === 'organizations' ? 'organization' : i.key
                          )
                      )
                      .map((route, index) => {
                        const IconComponent =
                          MENU_ICONS[route.icon] || IconDashboard
                        return (
                          <Grid item key={index}>
                            <Link
                              to={route.path}
                              component={NavLink}
                              className={classes.inactive}
                              onClick={
                                isSmallScreen ? setDrawerClose : () => {}
                              }
                              style={isActive => {
                                return isActive.isActive
                                  ? {
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: '#f32445',
                                      padding: '2px 10px',
                                      borderLeft: `2px solid #f32445`,
                                    }
                                  : {
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '2px 10px',
                                    }
                              }}
                              underline="none"
                            >
                              <IconComponent />
                              <Typography
                                component="span"
                                fontFamily="lexend"
                                variant="body2"
                                className={classes.linkText}
                                style={{ color: 'inherit' }}
                              >
                                {trans(route.key)}
                              </Typography>
                            </Link>
                          </Grid>
                        )
                      })}
                    {isSmallScreen ? (
                      <></>
                    ) : (
                      <Grid item>
                        {' '}
                        <IconButton
                          disableFocusRipple
                          disableRipple
                          className={classes.btnToggle}
                          edge="start"
                          color="inherit"
                          onClick={openDrawer ? setDrawerClose : setDrawerOpen}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '1px',
                            color: '#4e5978',
                          }}
                        >
                          <IconMenuControl rotate={openDrawer} />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </Drawer>
  )
}

export default connect(AppMenu)
