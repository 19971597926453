import * as Yup from 'yup'
import makeValidationSchema from '../../../../hooks/makeValidationSchema'

export const initialValues = {
  message: '',
}

export const getInitialValues = item => {
  return Object.assign({}, initialValues, item)
}

export const useScheme = makeValidationSchema(trans =>
  Yup.object().shape({
    message: Yup.string().max(180).required(trans('required')),
  })
)
