import { SpacingContainer } from '../../../../components/common/Containers/Base'
import { Grid } from '@material-ui/core'
import Button from '../../../../components/common/Buttons/Button'
import Typography from '../../../../components/common/Typography'
import { useTrans } from '../../../../services/i18n'
import React, { useState } from 'react'
import connect from '../../connect'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const Header = ({ item, changeStatus }) => {
  const { trans } = useTrans()
  const permissions = useSelector(selectUserPermissions)

  const [pending, setPending] = useState(false)
  const onChangeStatus = () => {
    setPending(true)
    const status = item.status === 'Published' ? 'Unpublished' : 'Published'
    changeStatus({ id: item.id, status }).then(() => {
      setPending(false)
    })
  }

  return (
    <>
      <SpacingContainer py={3} px={0}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography weight="600" variant="h2" fontFamily="lexend" title>
              {trans('release-details')}
            </Typography>
          </Grid>
          {isEnabled(permissions, 'releases', '', 'edit') && (
            <Grid item>
              <Button
                to={`/releases/update/${item.id}`}
                variant="outlined"
                color="primary"
                style={{ marginRight: '10px' }}
              >
                {trans('edit')}
              </Button>
              <Button
                pending={pending}
                onClick={onChangeStatus}
                variant="contained"
                color="success"
              >
                {item.status === 'Published'
                  ? trans('unpublish')
                  : trans('publish')}
              </Button>
            </Grid>
          )}
        </Grid>
      </SpacingContainer>
    </>
  )
}

export default connect(Header)
