import React, { useEffect, useState } from 'react'
import connect from '../connect'
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase'
import { Grid } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import FileTemplates from './FileTemplates'
import FormTemplates from './FormTemplates'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import ArrowBack from '@material-ui/icons/ArrowBack'

const RequestTemplates = ({}) => {
  const navigate = useNavigate()
  const { trans } = useTrans()
  const { id, slug } = useParams()
  const [categoryName, setCategoryName] = useState()

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    console.log(id)
  }, [id])

  useEffect(() => {
    if (slug) setCategoryName(slug.split('-').join(' '))
  }, [slug])

  return (
    <>
      <SpacingContainerBase>
        <Grid
          container
          justifyContent="space-between"
          style={{ paddingLeft: '35px' }}
        >
          <Grid item>
            <Typography
              variant="h2"
              fontFamily="lexend"
              title
              style={{ alignItems: 'center', display: 'flex' }}
            >
              <ArrowBack
                onClick={handleGoBack}
                style={{ cursor: 'pointer', marginRight: '16px' }}
              />
              Template Category:{' '}
              <span style={{ marginLeft: '10px' }}>{categoryName}</span>
            </Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs>
            <FileTemplates requestTemplateId={id} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs>
            <FormTemplates requestTemplateId={id} />
          </Grid>
        </Grid>
      </SpacingContainerBase>
    </>
  )
}

export default connect(RequestTemplates)
