import { EventEmitter } from 'events';
import { RpcError } from './errors';
import { generateId, JSONRPC_VERSION, rpcRequestString } from './helpers';

const CLOSE_TO_CONNECT = 4000;

/**
 * @typedef {*&{}} RpcData
 */

/**
 * @class WebSocketRpcClient
 */
export default class WebSocketRpcClient extends EventEmitter {
  /**
   * @readonly
   * @type WebSocket
   */
  ws = undefined;

  /**
   * @private
   * @param event
   */
  #onMessage = (event) => {
    try {
      const data = JSON.parse(event.data);
      //console.log(data)
      if (data.jsonrpc === JSONRPC_VERSION) {
        this.emit(data.id ? `id:${data.id}` : 'jsonrpc', data);
        return;
      }
      this.emit('event', data);
      return;
    } catch (e) {
      console.error(e);
    }
    console.warn('WebSocket: unknown message is received.');
    this.emit('unknown', event.data);
  };

  /**
   * @private
   */
  #connect = () => {
    if (this.ws) {
      this.ws.close(CLOSE_TO_CONNECT);
    }

    this.ws = new WebSocket(this.url.toString(), ['jsonrpc']);

    this.ws.addEventListener('open', () => {
      this.#attemptCount = 0;
      this.emit('ws:open', this.opened);
    });

    this.ws.addEventListener('close', (event) => {
      if (event.code === CLOSE_TO_CONNECT) return;
      setTimeout(() => {
        if (this.ws && (this.ws.readyState === WebSocket.OPEN || this.ws.readyState === WebSocket.CONNECTING)) return;
        this.#connect();
        this.#attemptCount++;
      }, 5000);
    });

    this.ws.addEventListener('error', (error) => {
      console.warn('WebSocket Error:', error);
    });

    this.ws.addEventListener('message', this.#onMessage);
  };

  #attemptCount = 0;

  /**
   * @constructor
   * @param {string} uri
   */
  constructor(uri) {
    super();
    this.url = new URL(uri);
    this.#connect();
  }

  get opened() {
    return this.ws.readyState === WebSocket.OPEN;
  }

  /**
   * @public
   * @return {Promise<boolean>}
   */
  async whenOpened() {
    if (this.opened) return true;
    return new Promise((resolve, reject) => {
      let timeout;
      const handler = (value) => {
        clearTimeout(timeout);
        resolve(value);
      };
      timeout = setTimeout(() => {
        this.off('ws:open', handler);
        reject(new Error('Connection timeout'));
      }, 30000);
      this.on('ws:open', handler);
    });
  }

  /**
   * @public
   * @param {string|*} data
   * @return {Promise<void>}
   */
  async send(data) {
    return this.whenOpened().then(() => this.ws.send(data));
  }

  /**
   * @public
   * @param {string} token
   * @return {Promise<void>}
   */
  async auth(token) {
    if (token) this.url.searchParams.set('authToken', token);
    else this.url.searchParams.delete('authToken');

    this.#connect();
    return this.whenOpened();
  }

  /**
   * @public
   * @param {string} method
   * @param {RpcData} [params]
   * @param {string} [id]
   * @return {Promise<void>}
   */
  async sendRpc(method, params, id) {
    return this.whenOpened().then(() => this.ws.send());
  }

  /**
   * @public
   * @param {string} method
   * @param {RpcData} [params]
   * @return {Promise<void>}
   */
  async notify(method, params) {
    return this.send(rpcRequestString(method, params));
  }

  /**
   * @public
   * @param {string} method
   * @param {RpcData} [params]
   * @return {Promise<RpcData>}
   */
  async call(method, params) {
    const id = generateId(method);
    return new Promise((resolve, reject) => {
      this.on(`id:${id}`, (value) => {
        if (value.error) {
          // if (value.error.code === "UnAuthorizedRequestError")
          return reject(new RpcError(value.error, method));
        }
        resolve(value.result);
      });
      this.send(rpcRequestString(method, params, id)).catch(reject);
    });
  }
}
