import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTrans } from '../../../../services/i18n'
import { IconThumbUp, IconThumbDown } from '../../../../components/svg/Icons'
import Modal from '../../../../components/generics/Modal/Modal'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import DenyReasonForm from './DenyReasonForm'

const colorMap = {
  Approved: '#A5CB55',
  Denied: '#F32445',
  Unkown: '#B2B8CF', //In progress or Under review
}

const useStyles = makeStyles({
  approvalContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  thumbUpStyles: {
    marginRight: '1rem',
    cursor: 'pointer',
  },
  thumbDownStyles: {
    cursor: 'pointer',
  },
})

const Approval = React.memo(({ file, approveFile, denyFile }) => {
  const { trans } = useTrans()
  const styles = useStyles()
  const [openAprove, onOpenApprove, onCloseApprove] = useBooleanHandlers()
  const [openDeny, onOpenDeny, onCloseDeny] = useBooleanHandlers()
  const [changingStatus, setChangingStatus] = React.useState(false)
  const [reasonForDenial, setReasonForDenial] = useState({
    reason: '',
    hasError: true,
  })

  /***
   * Change the status of File Request received
   */
  const onApproveFileRequest = () => {
    setChangingStatus(true)
    approveFile(file).then(() => {
      setChangingStatus(false)
      onCloseApprove()
    })
  }

  const onDenyFileRequest = () => {
    setChangingStatus(true)
    denyFile(file, reasonForDenial.reason).then(() => {
      setChangingStatus(false)
      onCloseDeny()
    })
  }

  return (
    <div className={styles.approvalContainer}>
      <div
        className={styles.thumbUpStyles}
        onClick={
          file.status === 'Ready For Review' || file.status === 'Denied'
            ? onOpenApprove
            : null
        }
      >
        <IconThumbUp
          fill={
            file.status === 'Approved' ? colorMap[file.status] : colorMap.Unkown
          }
        />
      </div>
      <div
        className={styles.thumbDownStyles}
        onClick={file.status === 'Ready For Review' ? onOpenDeny : null}
      >
        <IconThumbDown
          fill={
            file.status === 'Denied' ? colorMap[file.status] : colorMap.Unkown
          }
        />
      </div>

      <Modal
        open={openAprove}
        onClose={onCloseApprove}
        onConfirm={onApproveFileRequest}
        pending={changingStatus}
        title={trans('approve-file-request')}
        bodyTitle={`${trans('approve-file-request')}?`}
        bodySubTitle={file.fileName}
        bodyParagraph={trans('approve-file-request-description')}
      ></Modal>

      <Modal
        open={openDeny}
        onClose={onCloseDeny}
        onConfirm={!reasonForDenial.hasError && onDenyFileRequest}
        pending={changingStatus}
        title={trans('deny-file-request')}
        bodyTitle={`${trans('deny-file-request')}?`}
        bodySubTitle={file.fileName}
        bodyParagraph={trans('deny-file-request-description')}
      >
        <DenyReasonForm setReasonForDenial={setReasonForDenial} />
      </Modal>
    </div>
  )
})

export default Approval
