import React, { useEffect, useState } from "react";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";
const highlight = "#2445a7";
const colors = [
  "#e33462",
  "#ac65cd",
  "#df376a",
  "#9976f4",
  "#c94b93",
  "#766ce3",
  "#9877f5",
  "#ba59b2",
  "#6665d6",
  "#4e5bc4"
];

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
};

const getTextColor = (rgb) => {
  if (!rgb) return "#FFFFFF"
  const o = Math.round(
    (parseInt(rgb[0], 10) * 299 +
      parseInt(rgb[1], 10) * 587 +
      parseInt(rgb[2], 10) * 114) /
      1000
  );
  return o > 125 ? "#FFFFFF" : "#FFFFFF";
};

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21]
};

const MapChart = ({ states }) => {
  const [activeGeo, setActiveGeo] = useState("");

  const [statesWithColors, setStatesWithColors] = useState([])

  const buildColors = () => {
    const arrStates = states.map((state) => ({
      fill: colors[(state.value * (colors.length - 1)).toFixed()],
      ...state
    }))
    setStatesWithColors(JSON.parse(JSON.stringify(arrStates)))
  }

  useEffect(() => {
    if (states.length) buildColors()
  }, [states])
  
  return (
    <>
    {
      !!statesWithColors.length &&
      <ComposableMap
        projection="geoAlbersUsa"
        width={1000} 
        height={550}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) => (
            <>
              {geographies.map((geo) => {
                const cur = statesWithColors.find((s) => s.id === geo.id);
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={() => setActiveGeo(geo.id)}
                    onMouseLeave={() => setActiveGeo(null)}
                    fill={geo.id === activeGeo ? highlight : cur.fill}
                    stroke="#FFFFFF"
                    style={{
                      default: { outline: 'none' },
                      hover: { outline: 'none', transition: "all 250ms", fill: "#2445a7", stroke: "#2445a7" },
                      pressed: { outline: 'none', transition: "all 250ms", fill: "#2445a7", stroke: "#2445a7" },
                    }}
                  />
                );
              })}
              {geographies.map((geo) => {
                const centroid = geoCentroid(geo);
                const cur = statesWithColors.find((s) => s.name === geo.properties.name);
                const textFill = getTextColor(hexToRgb(cur || "#2445a7"));
                return (
                  <g key={geo.rsmKey + "-name"}>
                    {cur &&
                      centroid[0] > -160 &&
                      centroid[0] < -67 &&
                      (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                        <Marker
                          coordinates={centroid}
                        >
                          <text
                            y="2"
                            fontSize={10}
                            textAnchor="middle"
                            onMouseEnter={() => setActiveGeo(geo.id)}
                            onMouseLeave={() => setActiveGeo(null)}
                            style={{ cursor: "pointer" }}
                            fill={geo.id === activeGeo ? "#FFFFFF" : textFill}
                          >
                            {cur.code}
                          </text>
                        </Marker>
                      ) : (
                        <Annotation
                          subject={centroid}
                          dx={offsets[cur.id][0]}
                          dy={offsets[cur.id][1]}
                        >
                          <text
                            x={4}
                            fontSize={14}
                            alignmentBaseline="middle"
                            onMouseEnter={() => setActiveGeo(geo.id)}
                            onMouseLeave={() => setActiveGeo(null)}
                            style={{ cursor: "pointer" }}
                          >
                            {cur.code}
                          </text>
                        </Annotation>
                      ))}
                  </g>
                );
              })}
            </>
          )}
        </Geographies>
      </ComposableMap>
    }
    </>
  );
};

export default MapChart;