import React from 'react'
import connect from '../connect'
import Typography from '../../../components/common/Typography'
import { intlDateFormat } from '../../../utils/dateUtils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { InlineContainer } from '../../../components/common/Containers'
import { RouterLink } from '../../../components/common/Buttons'
import { Avatar } from '../../../components/common/Cards'
import StatusChip from './Status'
import UserMenu from './UserMenu'
import { formatToTitleCase } from '../../../utils/helpers'

const Item = ({ user }) => {
  return (
    <>
      <TableRow>
        <TableCell align="left">
          <InlineContainer>
            <Avatar
              variantAvatar="rounded"
              size={40}
              value={`${user.name} ${user.familyName}`}
              bgColor="gray-second"
            />
            <Typography
              weight="500"
              to={`/usfcr-staff/${user.id}`}
              variant="h6"
              component={RouterLink}
            >
              {user.name} {user.familyName}
            </Typography>
          </InlineContainer>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {user.email}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            { user.role ? formatToTitleCase(user.role) : '' }
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="inherit" fontFamily="lexend">
            {intlDateFormat(user.createdAt)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <StatusChip status={user.usfcrActive ? 'Active' : 'Blocked'} />
        </TableCell>
        <TableCell align="left">
          <UserMenu user={user}/>
        </TableCell>
      </TableRow>
    </>
  )
}

export default connect(Item)
