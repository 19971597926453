import React, { useCallback } from 'react'
import connect from '../connect'
import Typography from '../../../components/common/Typography'
import { intlDateFormat } from '../../../utils/dateUtils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { InlineContainer } from '../../../components/common/Containers'
import StatusChip from './Status'
import QuoteMenu from './QuoteMenu'
import { formatNumberToUsd } from '../../../utils/helpers'
import { OrganizationsCell } from '../../../components/common/Table'
import { Button, RouterLink } from '../../../components/common/Buttons'
import { useTrans } from '../../../services/i18n'
import useBooleanHandlers from '../../../hooks/useBooleanHandlers'
import { ModalDialog } from '../../../components/common/Dialogs'
import ProgressStatusChip from './ProgressStatus'

const Item = ({ quote, authUser, columns }) => {
  const { trans } = useTrans()
  const [open, onOpen, onClose] = useBooleanHandlers()
  const organization = {
    organizationId: quote.organizationId,
    orgName: quote.organizationName,
  }

  const hasColumn = useCallback(
    key => columns.some(c => c.key === key),
    [columns]
  )

  return (
    <>
      <TableRow>
        {hasColumn('date') && (
          <TableCell align="left">
            <InlineContainer>
              <Typography weight="500" variant="h6">
                {intlDateFormat(quote.createdAt)}
              </Typography>
            </InlineContainer>
          </TableCell>
        )}
        {hasColumn('solicitation-message') && (
          <TableCell align="left">
            {quote.userMessage ? (
              <Typography
                variant="body"
                onClick={onOpen}
                component={RouterLink}
              >
                {trans('view-message')}
              </Typography>
            ) : (
              <Typography variant="body">{trans('no-message')}</Typography>
            )}
          </TableCell>
        )}
        {hasColumn('type') && (
          <TableCell align="left">
            <Typography variant="body">
              {trans(quote.opportunityType)}
            </Typography>
          </TableCell>
        )}
        {hasColumn('amount') && (
          <TableCell align="left">
            <span style={{ paddingRight: '25px' }}>
              {formatNumberToUsd(quote.amount) || 0}
            </span>
          </TableCell>
        )}
        {hasColumn('expires') && (
          <TableCell align="left">
            <span style={{ paddingRight: '25px' }}>
              {intlDateFormat(quote.expires)}
            </span>
          </TableCell>
        )}
        {hasColumn('status') && (
          <TableCell align="left">
            <Typography
              variant="inherit"
              fontFamily="lexend"
              style={{ padding: '0 16px' }}
            >
              <StatusChip status={quote.status} />
            </Typography>
          </TableCell>
        )}
        {hasColumn('organization') && (
          <TableCell align="left">
            <OrganizationsCell organizations={[organization]} />
          </TableCell>
        )}
        {hasColumn('assigned-to') && (
          <TableCell align="left">
            <span style={{ paddingRight: '25px' }}>
              {quote.assignedUserName || ''}{' '}
              {quote.assignedUserFamilyName || ''}
            </span>
          </TableCell>
        )}
        {hasColumn('progress-status') && (
          <TableCell align="left">
            <ProgressStatusChip quote={quote} />
          </TableCell>
        )}
        <TableCell align="left">
          <QuoteMenu quote={quote} />
        </TableCell>
      </TableRow>

      <ModalDialog
        open={open}
        onClose={onClose}
        title={trans('solicitation-message')}
      >
        {quote.userMessage}
      </ModalDialog>
    </>
  )
}

export default connect(Item)
