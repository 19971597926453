import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

const KeywordWrapper = styled.div`
  display: flex;
  padding: 5px 10px 7px 10px;
  align-items: flex-start;
  gap: 10px;
  color: var(--Gray-Blue-Middle, #4e5978);
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 14px;
  border: 1px solid rgba(136, 143, 169, 0.3);
  background: #fff;
`;

function Keywords({ keywords }) {
  if (!keywords) return <></>;
  return (
    <Grid container spacing={1} wrap="wrap">
      {keywords.map((keyword, index) => (
        <Grid item key={index}>
          <KeywordWrapper>
            <Typography variant="keyword" weight="600">
              {keyword}
            </Typography>
          </KeywordWrapper>
        </Grid>
      ))}
    </Grid>
  );
}

export default Keywords;
