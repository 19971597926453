import IconButton from '@material-ui/core/IconButton';
import IconSorting from '@material-ui/icons/ImportExport';
import React from 'react';
import { Button } from '../../../../components/common/Buttons';
import { Popover } from '../../../../components/common/Dialogs/Popover';
import Typography from '../../../../components/common/Typography';
import { IconSort } from '../../../../components/svg/Icons/IconSort';
import { useMediaQueryDown } from '../../../../hooks/useMediaQuery';
import usePopover from '../../../../hooks/usePopover';
import { useTrans } from '../../../../services/i18n';
import { SpacingContainer } from '../../../common/Containers/Base';
import SortItem from './SortItem';

export default function SortingList({ scope, order, orderOptions, onChangeOrder, media = 'xs', mobileButton = false }) {
  const isSmScreen = useMediaQueryDown(media);
  const { trans } = useTrans();
  const [anchorEl, onOpenPopover, onClosePopover] = usePopover();

  const onItemClick = React.useCallback(
    (value) => {
      onClosePopover();
      return onChangeOrder && onChangeOrder(value);
    },
    [onChangeOrder, onClosePopover]
  );

  const current = React.useMemo(() => {
    const [value, direction = 'asc'] = order || [];
    const founded = orderOptions.find((o) => o.value === value);
    return founded ? { name: founded.name, direction: founded[direction] } : { name: 'N/A' };
  }, [orderOptions, order]);

  return (
    <>
      {mobileButton && isSmScreen ? (
        <IconButton onClick={onOpenPopover} id="sort-by" size="small">
          <IconSorting />
        </IconButton>
      ) : (
        <Button
          onClick={onOpenPopover}
          id="sort-by"
          fullWidth={isSmScreen}
          variant={isSmScreen ? 'outlined' : 'text'}
          size={isSmScreen ? 'medium' : 'small'}
          endIcon={<IconSort />}
        >
          <Typography variant="body2" color="hint" noWrap>
            {trans('sort-by')}&nbsp;&nbsp;&nbsp;
            <Typography color="label" weight="700" variant="body2" component="span">
              {trans(current.name)} {typeof current.direction === 'string' ? `/ ${trans(current.direction)}` : ''}
            </Typography>
          </Typography>
        </Button>
      )}

      <Popover id={`id-popover-sorting-${scope}`} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover}>
        <SpacingContainer py={1} px={2}>
          {orderOptions.map((item) => (
            <SortItem key={item.value} item={item} onItemClick={onItemClick} />
          ))}
        </SpacingContainer>
      </Popover>
    </>
  );
}
