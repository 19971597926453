import { Grid } from '@material-ui/core'
import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { Button } from '../../../components/common/Buttons'
import TextField from '../../../components/common/Inputs/TextField'
import Typography from '../../../components/common/Typography'
import useBooleanHandlers from '../../../hooks/useBooleanHandlers'
import { useForm } from '../../../hooks/useForm'
import { useApplication } from '../../../services/application'
import { useTrans } from '../../../services/i18n'
import {
  catchYupErrors,
  schemaToInitialState,
} from '../../../utils/validationUtils'
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase'
import Paper from '@material-ui/core/Paper'
import { useAlertContext } from '../../../contexts/AlertContext'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'

const RegisterNewUser = () => {
  const { trans } = useTrans()
  const [inputTypePassword, setInputTypePassword] = React.useState('password')
  const [inputTypeConfirmPassword, setInputTypeConfirmPassword] =
    React.useState('password')
  const { successAlert, errorAlert } = useAlertContext()

  const application = useApplication()

  const schema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(
          trans('empty-not-allowed', { field: trans('first-name') })
        ),
        familyName: Yup.string().required(
          trans('empty-not-allowed', { field: trans('last-name') })
        ),
        email: Yup.string()
          .email(trans('incorrect-email'))
          .required(trans('empty-not-allowed', { field: trans('email') })),
        organizationName: Yup.string().required(
          trans('empty-not-allowed', { field: trans('organization-name') })
        ),
      }),
    [trans]
  )

  const handleSubmit = async (values, { setStatus, setErrors }) => {
    return application
      .call('admin.registeredUsers.registerNewUser', {
        ...values,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      .then(
        res => {
          console.log(res)
          if (res.exists) {
            errorAlert(res.message)
          }

          if (!res.exists) {
            successAlert(res.message)
            formik.resetForm()
          }
        }
        // history.push(ROUTE_VERIFY.path, {
        //   firstName: values.firstName,
        //   familyName: values.familyName,
        //   email: values.email,
        // })
      )
      .catch(e => catchYupErrors(e, setErrors, err => setStatus(err.message)))
  }

  const formik = useForm(schema, schemaToInitialState(schema), handleSubmit, {
    validateOnChange: false,
    validateOnBlur: false,
  })

  const handleVisibilityPassword = () => {
    setInputTypePassword(inputTypePassword === 'password' ? 'text' : 'password')
  }

  const handleVisibilityConfirmPassword = () => {
    setInputTypeConfirmPassword(
      inputTypeConfirmPassword === 'password' ? 'text' : 'password'
    )
  }

  return (
    <SpacingContainerBase px={5} py={5}>
      <Grid container spacing={5} style={{ padding: '30px 0' }}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            fontFamily="lexend"
            weight="500"
            style={{ alignItems: 'center', display: 'flex' }}
          >
            {trans('register-new-user')}{' '}
            <ShowTooltip sectionToShow="registered-users-new" />
          </Typography>
        </Grid>
      </Grid>
      <Paper style={{ padding: '30px' }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {formik.status && (
                <Grid item xs={12}>
                  <Typography color="error">{formik.status}</Typography>
                </Grid>
              )}
              <Grid item md={6} xs={12}>
                <TextField
                  label={trans('first-name')}
                  placeholder={trans('name')}
                  required
                  {...formik.getFieldProps('firstName')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label={trans('last-name')}
                  placeholder={trans('last-name')}
                  {...formik.getFieldProps('familyName')}
                  required
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label={trans('email')}
                  placeholder={trans('email')}
                  {...formik.getFieldProps('email')}
                  required
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label={trans('organization-name')}
                  placeholder={trans('organization-name')}
                  {...formik.getFieldProps('organizationName')}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  id="create-an-account-btn"
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={formik.handleSubmit}
                  pending={formik.isSubmitting}
                >
                  {trans('create-account')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </SpacingContainerBase>
  )
}

export default RegisterNewUser
