import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Button } from '../../../components/common/Buttons'
import { ModalDialog } from '../../../components/common/Dialogs'
import { useTrans } from '../../../services/i18n'
import { useAlertContext } from '../../../contexts/AlertContext'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import Typography from '../../../components/common/Typography'
import connect from '../connect'

const DeactivateUserStaffModal = ({ user, open, onClose, activeUserStaff }) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)

  const activeDeactivateUser = () => {
    setIsSend(true)
    activeUserStaff({ id: user.id, active: !user.usfcrActive })
      .then(() => {
        successAlert(
          `${user.name} ${user.familyName} has been ${user.usfcrActive ? 'deactivate' : 'activate'} from USFCR Staff`
        )
        setIsSend(false)
        onClose()
      })
      .catch(error => {
        setIsSend(false)
        errorAlert(error.message)
        onClose()
      })
  }

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={trans('deactivate-account')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSend}
              onClick={onClose}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={() => activeDeactivateUser()}
            >
              {trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer px={3} py={3}>
        <Grid container spacing={3}>
          <Grid style={{ textAlign: 'center' }} item sm={12} xs={12}>
            <Typography
              style={{ marginBottom: '5px' }}
              variant="h2"
              weight="500"
            >
              {user?.usfcrActive
                ? trans('ask-deactivate-account')
                : trans('ask-active-account')}
            </Typography>
            <Typography variant="h6" weight="500">
              {user?.usfcrActive
                ? trans('message-ask-deactivate-account')
                : trans('message-ask-active-account')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Paper
              bgColor="paperSecondary"
              component={SpacingContainer}
              py={2}
              px={2}
              className="center"
            >
              <Typography color="placeholder" variant="h6">
                {'User'}
              </Typography>
              <Typography variant="h6" weight="bold">
                {user?.name} {user?.familyName}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </SpacingContainer>
    </ModalDialog>
  )
}

export default connect(DeactivateUserStaffModal)
