import { Grid } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { Button } from "../../../../components/common/Buttons";
import { Paper, SpacingContainer } from "../../../../components/common/Containers";
import { ReactComponent as PreviewTopBar } from './images/previewTopBar.svg'
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import REVLogosocial from './images/REVLogoSocial.png'
const TopBar = ({ firstColor, secondColor, thirdColor, fourthColor, fifthColor }) => {
    return (
        <React.Fragment>
            <Paper
                bgColor="paperSecondary"
                component={SpacingContainer}
                px={2}
                py={2}
                style={{ width: '520px', height: '350px' }}
            >
                <Grid
                    container
                    direction="column"
                >
                    <Grid item>
                        <PreviewTopBar />
                    </Grid>
                    <Grid
                        item
                        style={{ background: fifthColor, height: '80px', marginTop:'-5px'}}
                    >
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                spacing={2}
                                style={{ marginTop: "10px" }}
                            >
                                <Grid item xs={2}>
                                    <Button item style={{ borderRadius: '15px', width: '13px' }}>
                                        <MenuOpenIcon style={{ color: thirdColor }} />
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button item style={{ borderRadius: '15px', width: '13px' }}>
                                        <SearchIcon style={{ color: thirdColor }} />
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button item style={{ borderRadius: '15px', width: '13px' }}>
                                        <NotificationsNoneIcon style={{ color: thirdColor }} />
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Button
                                                item
                                                variant="contained"
                                                style={{ borderRadius: '15px', width: '13px', background: "#ffffff" }}
                                            >
                                                <img src={REVLogosocial} alt="" style={{ width: '30px', height: '30px' }} />
                                            </Button>
                                        </Grid>
                                        <Grid item style={{marginTop:'12px'}}>
                                            <Grid item style={{ background: '#F1F3F9', height: '10px', width: '80px', borderRadius: '10px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button item style={{ borderRadius: '15px', width: '13px' }}>
                                        <ArrowDropDownIcon style={{ color: thirdColor }} />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Paper>
        </React.Fragment>
    )
}
export default TopBar
