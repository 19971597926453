import { useState } from 'react'
import Typography from '../../../../components/common/Typography'
import { useTrans } from '../../../../services/i18n'
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@material-ui/core'
import IconEdit from '@material-ui/icons/Edit'
import IconDelete from '@material-ui/icons/Delete'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import styles from '../../styles.module.css'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import ConfirmDialog from '../../../../components/generics/Confirm/Dialog'
import connect from '../../connect'
import { useAlertContext } from '../../../../contexts/AlertContext'
import EditOnboarding from '../editOnboarding'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'

import { selectUserPermissions } from '../../../../selectors'
import { useSelector } from 'react-redux'
const Item = ({
  item,
  deleteOnboarding,
  deleteOnboardingStep,
  handleSuccessAction,
  changeCurrentSection,
}) => {
  const { trans } = useTrans()

  return (
    <ExpandableTableRow
      expandComponent={
        <TableCell colSpan={4}>
          <div
            style={{
              padding: '20px 40px',
              background: '#f1f3f9',
              borderRadius: '10px',
            }}
          >
            <Typography
              variant="h4"
              weight="500"
              fontFamily="lexend"
              gutter="label"
            >
              Steps for {trans(item.view)} page
            </Typography>
            <Table style={{ background: '#f1f3f9' }}>
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableHeadCell} align="left">
                    Content
                  </TableCell>
                  <TableCell
                    className={styles.tableHeadCell}
                    style={{ width: '100px' }}
                    align="right"
                  >
                    {trans('actions')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item.helps.map((s, index) => (
                  <Step
                    item={s}
                    view={item.view}
                    section={item.section}
                    onboarding={item}
                    index={index}
                    key={index}
                    handleDelete={deleteOnboardingStep}
                    handleSuccessAction={handleSuccessAction}
                    changeCurrentSection={changeCurrentSection}
                  />
                ))}
              </TableBody>
            </Table>
          </div>
        </TableCell>
      }
      item={item}
      handleDelete={deleteOnboarding}
      handleSuccessAction={handleSuccessAction}
    >
      <TableCell align="left">{trans(item.section)}</TableCell>
      <TableCell align="left">{trans(item.view)}</TableCell>
      <TableCell align="left">{item.helps.length}</TableCell>
    </ExpandableTableRow>
  )
}

const ExpandableTableRow = ({
  item,
  children,
  expandComponent,
  handleDelete,
  handleSuccessAction,
}) => {
  const { trans } = useTrans()
  const [isExpanded, setIsExpanded] = useState(false)
  const [openDelete, onOpenDelete, onCloseDelete] = useBooleanHandlers()
  const [pendingDelete, setPendingDelete] = useState(false)
  const { successAlert } = useAlertContext()
  const permissions = useSelector(selectUserPermissions)

  const isEnabled = (att = 'view') => {
    const routeKey = 'tooltips-management'
    const module = permissions.find(p => p.key === routeKey)
    if (!module) return true

    const subModule = module.submodules.find(sm => sm.key === module.menuOption)

    if (!subModule) return true
    return subModule[att]
  }

  const onDelete = () => {
    setPendingDelete(true)
    Promise.resolve(handleDelete({ id: item.id }))
      .then(() => {
        onCloseDelete()
        successAlert('Onboarding was successfully deleted')
        handleSuccessAction()
      })
      .catch(() => {})
      .finally(() => setPendingDelete(false))
  }

  return (
    <>
      <TableRow>
        {children}
        <TableCell padding="checkbox" align="center">
          {isEnabled('edit') && (
            <button
              style={{ marginRight: 10 }}
              className={styles.actionButton}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? (
                <VisibilityOffOutlinedIcon color="primary" fontSize="small" />
              ) : (
                <VisibilityOutlinedIcon color="primary" fontSize="small" />
              )}
            </button>
          )}
          {isEnabled('delete') && (
            <button className={styles.actionButton} onClick={onOpenDelete}>
              <IconDelete color="primary" fontSize="small" />
            </button>
          )}
        </TableCell>
      </TableRow>
      {isExpanded && <TableRow>{expandComponent}</TableRow>}
      <ConfirmDialog
        open={openDelete}
        onClose={onCloseDelete}
        onConfirm={onDelete}
        pending={pendingDelete}
        title={'Delete onboarding page'}
        text={`Are you sure you want to delete onboarding for ${trans(
          item.view
        )}?`}
      >
        <Typography color="hint">{trans(item.view)}</Typography>
      </ConfirmDialog>
    </>
  )
}

const Step = ({
  item,
  onboarding,
  index,
  handleDelete,
  handleSuccessAction,
  view,
  section,
  changeCurrentSection,
}) => {
  const { trans } = useTrans()
  const [openDelete, onOpenDelete, onCloseDelete] = useBooleanHandlers()
  const [pendingDelete, setPendingDelete] = useState(false)
  const [openEdit, onOpenEdit, onCloseEdit] = useBooleanHandlers()
  const permissions = useSelector(selectUserPermissions)
  const { successAlert } = useAlertContext()

  const onEdit = () => {
    console.log(item, view, section)
    changeCurrentSection({
      section: section,
      menu: view,
      message: item,
    })
    onOpenEdit()
  }

  const onCloseEditModal = () => {
    onCloseEdit()
  }

  const onDelete = () => {
    setPendingDelete(true)
    Promise.resolve(handleDelete({ index, onboardingId: onboarding.id }))
      .then(() => {
        onCloseDelete()
        successAlert('Step was successfully deleted')
        handleSuccessAction()
      })
      .catch(() => {})
      .finally(() => setPendingDelete(false))
  }

  const isEnabled = (att = 'view') => {
    const routeKey = 'tooltips-management'
    const module = permissions.find(p => p.key === routeKey)
    if (!module) return true

    const subModule = module.submodules.find(sm => sm.key === module.menuOption)

    if (!subModule) return true
    return subModule[att]
  }

  return (
    <>
      <TableRow>
        <TableCell align="left">
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            children={item}
          ></ReactMarkdown>
        </TableCell>
        <TableCell padding="checkbox" align="right">
          {isEnabled('edit') && (
            <button
              onClick={onEdit}
              style={{ marginRight: 10 }}
              className={styles.actionButton}
            >
              <IconEdit color="primary" fontSize="small" />
            </button>
          )}
          {isEnabled('delete') && (
            <button className={styles.actionButton} onClick={onOpenDelete}>
              <IconDelete color="primary" fontSize="small" />
            </button>
          )}
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={openDelete}
        onClose={onCloseDelete}
        onConfirm={onDelete}
        pending={pendingDelete}
        title={'Delete step onboarding'}
        text={`Are you sure you want to delete step for onboarding ${onboarding?.view}?`}
      >
        <Typography color="hint">{trans(onboarding?.view)}</Typography>
      </ConfirmDialog>

      <EditOnboarding
        open={openEdit}
        onClose={onCloseEditModal}
        changeCurrentSection={changeCurrentSection}
        handleSuccessAction={handleSuccessAction}
      />
    </>
  )
}

export default connect(Item)
