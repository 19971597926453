import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useEffect, useState } from 'react'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'

import connect from '../../connect'
import { Button } from '../../../../components/common/Buttons'
import { SelectField, TextField } from '../../../../components/common/Inputs'
import { MenuItem } from '@material-ui/core'
import { ReactComponent as KeyIcon } from './images/calendar.svg'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const NotificationSettings = ({
  keepTrashedNotificationsFor,
  getKeepTrashedNotifications,
  updateKeepTrashedNotification,
}) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const permissions = useSelector(selectUserPermissions)

  const [period, setPeriod] = useState('30')
  const [errorInput, setErrorInput] = useState('')
  const [periodInterval, setPeriodInterval] = useState('days')
  const periods = [
    { key: 'days', value: 'days' },
    { key: 'months', value: 'months' },
    { key: 'years', value: 'years' },
  ]

  const changeSelect = ev => {
    setPeriodInterval(ev.target.value)
  }

  const changeInput = ev => {
    setPeriod(ev.target.value)
    const pattern = /^\d+$/
    pattern.test(ev.target.value)
      ? setErrorInput('')
      : setErrorInput('Should be a number')
  }

  const changeTrashedNotificationDays = async () => {
    try {
      const pattern = /^\d+$/
      if (!period || !pattern.test(period)) {
        errorAlert('The period field cannot be empty or can be number')
        return
      }
      setIsSend(true)
      await updateKeepTrashedNotification({ period, periodInterval })
      setIsSend(false)
      successAlert(
        'Your Keep Archived Notification Period was saved successfully'
      )
    } catch (error) {
      console.log(error)
      setIsSend(false)
    }
  }

  useEffect(() => {
    let data = keepTrashedNotificationsFor?.value
      ? JSON.parse(keepTrashedNotificationsFor?.value)
      : keepTrashedNotificationsFor?.value
    if (data) {
      const [responseDays, responsePeriod] = data
      setPeriod(responseDays)
      setPeriodInterval(responsePeriod)
    }
  }, [keepTrashedNotificationsFor])

  useEffect(() => {
    const init = async () => {
      await getKeepTrashedNotifications({})
    }

    init()
  }, [])

  return (
    <SpacingContainer py={2}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              style={{ alignItems: 'center', display: 'flex' }}
              variant="h4"
              weight="500"
              fontFamily="lexend"
            >
              <KeyIcon style={{ marginRight: '5px' }} />{' '}
              {trans('keep-trashed-notification')}{' '}
              <ShowTooltip sectionToShow="keep-trashed-notification" />
            </Typography>
          </Grid>
        </Grid>
        <Divider light style={{ marginTop: '20px' }} />
        <div style={{ alignItems: 'center', paddingTop: '20px' }}>
          <TextField
            placeholder={trans('set-period')}
            onChange={changeInput}
            value={period}
            label={trans('set-period')}
            style={{ width: '30%', marginRight: '16px' }}
            error={errorInput}
          >
            {trans('keep-trashed-notification')}
          </TextField>
          <SelectField
            fullWidth={false}
            type="select"
            name={'periodInterval'}
            value={periodInterval}
            onChange={changeSelect}
            style={{ marginRight: '16px' }}
          >
            {periods.map((i, index) => (
              <MenuItem key={index} value={i.key}>
                {i.value}
              </MenuItem>
            ))}
          </SelectField>
          {isEnabled(permissions, 'global-settings', '', 'edit') && (
            <Button
              onClick={() => changeTrashedNotificationDays()}
              size="large"
              variant="contained"
              color="primary"
              pending={isSend}
            >
              {trans('set-keep-trashed-notification')}
            </Button>
          )}
        </div>
      </Paper>
    </SpacingContainer>
  )
}
export default connect(NotificationSettings)
