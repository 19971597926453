import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  group: {
    '& > .mui-chip': {
      '&:not(:last-child)': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        marginRight: 1,
      },
      '&:not(:first-child)': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  },
});

export default function ChipGroup({ children, className }) {
  const styles = useStyles();

  return <div className={clsx(styles.group, className)}>{children}</div>;
}
