import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React from 'react'
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase'
import Course from './Course'
import { useTrans } from '../../../services/i18n'

function List({ courses, onChange }) {
  const { trans } = useTrans()

  return (
    <TableContainer component={Paper} radius={9}>
      <SpacingContainerBase px="5sm3" py={2}>
        <Table style={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">{trans('title')}</TableCell>
              <TableCell align="left">{trans('learnworldsId')}</TableCell>
              <TableCell align="left">{trans('type')}</TableCell>
              <TableCell align="left">{trans('level')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map((course, index) => (
              <Course key={index} course={course} onChange={onChange} />
            ))}
          </TableBody>
        </Table>
      </SpacingContainerBase>
    </TableContainer>
  )
}

export default List
