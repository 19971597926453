import IconButton from '@material-ui/core/IconButton'
import IconMore from '@material-ui/icons/MoreHoriz'
import React from 'react'
import { ListItemTextBase } from '../../../../../components/common/Containers'
import {
  ContextMenuListItem,
  Popover,
} from '../../../../../components/common/Dialogs/Popover'
import Typography from '../../../../../components/common/Typography'
import SaveSearchForm from '../../../../../components/forms/SaveSearchForm'
import ConfirmDialog from '../../../../../components/generics/Confirm/Dialog'
import useBooleanHandlers from '../../../../../hooks/useBooleanHandlers'
import usePopover from '../../../../../hooks/usePopover'
import { useTrans } from '../../../../../services/i18n'
import { getClientSiteDomain } from '../../../../../utils/helpers'
import { useSelector } from 'react-redux'
import { selectUserPermissions, isEnabled } from '../../../../../selectors'

export default function ItemMenu({
  item,
  route,
  searchLink,
  pending,
  onDelete,
  onSave,
  userDetail,
  saveDataAfterDeleteSearch,
}) {
  const { trans } = useTrans()
  const [anchorEl, onOpen, onClose] = usePopover()
  const [openConfirm, onOpenConfirm, onCloseConfirm] = useBooleanHandlers()
  const [openEdit, onOpenEdit, onCloseEdit] = useBooleanHandlers()
  const permissions = useSelector(selectUserPermissions)

  const redirectToClientPage = () => {
    // Set the URL of the client page you want to redirect to
    const newURL = `${getClientSiteDomain()}${searchLink}`
    // Redirect to the new page
    window.open(newURL, '_blank')
  }

  /***
   *
   */
  const onDeleteHanlder = () => {
    const params = {
      id: item.id,
      savedSearchOwner: userDetail.userName,
    }
    onDelete(params).then(res => {
      saveDataAfterDeleteSearch(params)
      onCloseConfirm()
    })
  }

  return (
    <>
      <IconButton onClick={onOpen} size="small">
        <IconMore />
      </IconButton>

      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClose}>
        <>
          {isEnabled(
            permissions,
            'registered-users',
            'saved-searches',
            'edit'
          ) && (
            <ContextMenuListItem
              button
              onClick={onOpenEdit}
              onClickCB={onClose}
              size="normal"
            >
              <ListItemTextBase>{trans('edit')}</ListItemTextBase>
            </ContextMenuListItem>
          )}
          <ContextMenuListItem
            button
            size="normal"
            onClick={redirectToClientPage}
          >
            <ListItemTextBase>
              {trans('view-on-client-portal')}
            </ListItemTextBase>
          </ContextMenuListItem>
          {isEnabled(
            permissions,
            'registered-users',
            'saved-searches',
            'delete'
          ) && (
            <ContextMenuListItem
              button
              onClick={onOpenConfirm}
              onClickCB={onClose}
              size="normal"
            >
              <ListItemTextBase>{trans('delete')}</ListItemTextBase>
            </ContextMenuListItem>
          )}
        </>
      </Popover>

      {openEdit &&
        isEnabled(
          permissions,
          'registered-users',
          'saved-searches',
          'edit'
        ) && (
          <SaveSearchForm
            item={item}
            open={openEdit}
            onClose={onCloseEdit}
            onSaveSearch={onSave}
            userDetail={userDetail}
          />
        )}

      <ConfirmDialog
        open={openConfirm}
        onClose={onCloseConfirm}
        onConfirm={onDeleteHanlder}
        pending={pending}
        title={trans('delete-saved-search')}
        text={trans('confirm-delete-saved-search')}
      >
        <Typography variant="button" color="hint">
          {route && route.key ? trans(route.key) : '-'}
        </Typography>
        <Typography color="primary">{item.title}</Typography>
      </ConfirmDialog>
    </>
  )
}
