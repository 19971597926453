import { connect } from 'react-redux'
import { actions } from '../../store/releases'
import { generateListCallbacks } from '../../store/helpers/listActionsHelpers'

function mapStateToProps(state) {
  return { releases: state.releases, authUser: state.auth }
}

function mapDispatchToProps(dispatch) {
  return {
    ...generateListCallbacks(dispatch, actions),
    changeStatus: params => dispatch(actions.changeStatus(params)),
    deleteRelease: params => dispatch(actions.deleteRelease(params)),
    findOne: params => dispatch(actions.findOne(params)),
    addRelease: params => dispatch(actions.addRelease(params)),
    updateRelease: params => dispatch(actions.updateRelease(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
