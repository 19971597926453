import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useEffect, useState, useCallback } from 'react'
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'
import SpacingContainerBase from '../../../../components/layouts/SpacingContainerBase'

import connect from '../../connect'
import { Button } from '../../../../components/common/Buttons'
import { TextField } from '../../../../components/common/Inputs'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { SelectField } from '../../../../components/common/Inputs'
import { APP_MENU } from '../../../../constants'
import { useForm } from '../../../../hooks/useForm'
import MenuItem from '@material-ui/core/MenuItem'
import { getInitialValues, useScheme } from './helpers'

const formOptions = {
  validateOnMount: false,
  validateOnChange: true,
  enableReinitialize: true,
}

const AddTooltip = ({ createTooltip }) => {
  const { trans } = useTrans()
  const schema = useScheme()
  const [sections, setSections] = useState([])
  const [isSend, setIsSend] = useState(false)
  const { successAlert, errorAlert, infoAlert } = useAlertContext()

  const onSubmit = useCallback(
    async values => {
      setIsSend(true)
      Promise.resolve(createTooltip({ ...values, menu: values.menu.key }))
        .then(res => {
          if (res.payload && res.payload.success) {
            successAlert('Tooltip was successfully added')
            resetForm()
          } else {
            errorAlert(
              res?.payload?.message
                ? trans(res.payload.message)
                : res?.error?.message
            )
          }
        })
        .catch(err => {
          errorAlert(err.message)
        })
        .finally(() => setIsSend(false))
    },
    [infoAlert, errorAlert, successAlert, createTooltip]
  )

  const {
    getFieldProps,
    handleSubmit,
    values,
    isSubmitting,
    isValid,
    resetForm,
    setFieldValue,
  } = useForm(schema, getInitialValues(), onSubmit, formOptions)

  const changeMenu = ev => {
    console.log(ev)
  }

  useEffect(async () => {
    if (values.menu) {
      setSections(values.menu.sections)
      // setFieldValue(
      //   'section',
      //   values.menu.sections.length !== 0 ? values.menu.sections[0] : ''
      // )
    }
  }, [values])

  return (
    <SpacingContainer py={2}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs zeroMinWidth>
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                marginBottom: '16px',
              }}
            >
              <CheckCircleOutlineIcon
                style={{ marginRight: '16px', color: '#28a745' }}
              />
              <Typography variant="body2" weight="500" fontFamily="lexend">
                {trans('add-tooltip-first-message')}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item sm={6} xs={12}>
            <Typography
              weight="500"
              fontFamily="lexend"
              color="label"
              gutter="label"
              variant="body2"
              style={{ marginBottom: '0.5rem' }}
            >
              {trans('select-where')}
            </Typography>
            <SelectField
              onChangeValue={ev => changeMenu(ev)}
              type="select"
              placeholder={trans('required')}
              {...getFieldProps('menu')}
              getRenderValue={item => item && trans(item.key)}
            >
              {APP_MENU[0].items.map((item, ind) => (
                <MenuItem key={ind} value={item}>
                  {trans(item.key)}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Typography
              weight="500"
              fontFamily="lexend"
              color="label"
              gutter="label"
              variant="body2"
              style={{ marginBottom: '0.5rem' }}
            >
              {trans('select-the-table')}
            </Typography>
            <SelectField
              type="select"
              placeholder={trans('required')}
              {...getFieldProps('section')}
              getRenderValue={item => trans(item)}
            >
              {sections.map((item, ind) => (
                <MenuItem key={ind} value={item}>
                  {trans(item)}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item sm={12} xs={12}>
            <Divider light />
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <CheckCircleOutlineIcon
                style={{ marginRight: '16px', color: '#28a745' }}
              />
              <Typography variant="body2" weight="500" fontFamily="lexend">
                {trans('add-tooltip-second-message')}
              </Typography>
            </div>
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              multiline
              minRows={5}
              label={trans('message') + ' (180 Characters maximum)'}
              placeholder={trans('message')}
              {...getFieldProps('message')}
              name="message"
            />
            <Button
              style={{ marginTop: '30px' }}
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={handleSubmit}
              size="large"
            >
              {trans('add-tooltip')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </SpacingContainer>
  )
}
export default connect(AddTooltip)
