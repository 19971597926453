import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'
import Switch from '../../../../components/common/Inputs/Switch'
import CheckBox from '../../../../components/common/Inputs/CheckBox'
import { Button } from '../../../../components/common/Buttons'
import { useAlertContext } from '../../../../contexts/AlertContext'
import FormControlLabel from '@material-ui/core/FormControlLabel'
// import
import { TabPanel } from '../../../../components/common/Tabs'
import { ReactComponent as IconVLetter } from '../../../../components/assests/ico-V-letter.svg'
import { ReactComponent as IconALetter } from '../../../../components/assests/ico-A-letter.svg'
import { ReactComponent as IconKnowledge } from '../../../../components/assests/ico-m-learn.svg'
import { ReactComponent as IconWorkplace } from '../../../../components/assests/ico-workplace.svg'
import { ReactComponent as IconExplore } from '../../../../components/assests/ico-explore.svg'
import { ReactComponent as IconBusiness } from '../../../../components/assests/ico-business.svg'
import connect from '../../connect'

const ManageFeatures = ({
  newUser = true,
  optionTab,
  updateSubscriptionSettings,
  subscriptionSettings,
}) => {
  const [isSend, setIsSend] = React.useState(false)
  const { trans } = useTrans()
  const [modules, setModules] = useState([])

  const icons = {
    knowledge: <IconKnowledge />,
    workplace: <IconWorkplace />,
    discovery: <IconExplore />,
    business: <IconBusiness />,
  }

  const updateData = () => {
    if (subscriptionSettings) {
      const tabUsers = subscriptionSettings.find(obj => {
        return obj.idTab == optionTab
      })
      setModules(tabUsers?.existingUsers?.optionsList || [])
    }
  }

  useEffect(() => {
    updateData()
  }, [subscriptionSettings])

  useEffect(() => {
    updateData()
  }, [optionTab])

  useEffect(() => {
    updateData()
  }, [])

  const changeColumn = (ev, mo) => {
    const newData = subscriptionSettings.map(tab => {
      if (tab.idTab != optionTab) return tab
      else {
        var optionLists = []
        if (newUser) optionLists = tab.newUsers.optionsList
        else optionLists = tab.existingUsers.optionsList

        const auxOptionList = optionLists.map(option => {
          if (option.key != mo.key) return option
          else {
            const newOptions = option.submodules.map(opt => {
              return {
                ...opt,
                aCheckBox: ev.target.checked,
                vCheckBox: ev.target.checked,
                active: ev.target.checked,
              }
            })
            return {
              ...option,
              active: ev.target.checked,
              submodules: newOptions,
            }
          }
        })

        return {
          ...tab,
          existingUsers: { optionsList: auxOptionList },
        }
      }
    })

    updateSubscriptionSettings(newData)
  }

  const changeCheckbox = (ev, mo, checksOptions, vCheck) => {
    const newData = subscriptionSettings.map(tab => {
      if (tab.idTab != optionTab) return tab
      else {
        var optionLists = tab.existingUsers.optionsList
        const auxOptionList = optionLists.map(option => {
          if (option.key != mo.key) return option
          else {
            const newOptions = option.submodules.map(opt => {
              if (opt.key != checksOptions.key) return opt
              else {
                return {
                  ...opt,
                  aCheckBox: !vCheck ? ev.target.checked : opt.aCheckBox,
                  vCheckBox: vCheck ? ev.target.checked : opt.vCheckBox,
                  active: !vCheck ? ev.target.checked : opt.aCheckBox,
                }
              }
            })
            return {
              ...option,
              submodules: newOptions,
            }
          }
        })

        return {
          ...tab,
          existingUsers: { optionsList: auxOptionList },
        }
      }
    })
    updateSubscriptionSettings(newData)
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={4}
      key={`tab-main-grid-checks-title-${optionTab}-${
        newUser ? 'new-users' : 'existing-ususers'
      }`}
    >
      {modules.length > 0 &&
        modules.map(column => (
          <Grid
            container
            item
            xs={3}
            style={{ alignContent: 'flex-start' }}
            key={`tab-grid-checks-title-${optionTab}-${column.key}-existing-users'}`}
          >
            <Grid container item justifyContent="space-between">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {icons[column.key] || ''}
                <Typography
                  style={{
                    fontSize: '16px',
                    fontFamily: 'Lexend',
                    marginLeft: '12px',
                  }}
                  variant="body2"
                  weight="600"
                  key={`tab-grid-column-name-typography-${optionTab}`}
                >
                  {trans(column.key)}
                </Typography>
              </div>
              <div>
                <Switch
                  checked={column.active}
                  inputProps={{ 'aria-label': 'module' }}
                  color="primary"
                  onChange={ev => changeColumn(ev, column)}
                />
              </div>
            </Grid>
            <Divider light spacing={2} xs={12} style={{ width: '100%' }} />

            <Grid
              container
              item
              justifyContent="flex-start"
              xs={12}
              spacing={0}
            >
              <Grid item xs={2}>
                <IconVLetter />
              </Grid>
              <Grid item xs={2}>
                <IconALetter />
              </Grid>
              <Grid item xs={8}></Grid>
            </Grid>

            {column.submodules.map(checksOptions => (
              <Grid
                container
                item
                justifyContent="flex-start"
                xs={12}
                key={`tab-grid-checks-options-${optionTab}-${
                  checksOptions.key
                }-${newUser ? 'new-users' : 'existing-ususers'}`}
                style={{ marginBottom: '0.6rem' }}
              >
                <Grid container item xs={2}>
                  <FormControlLabel
                    key="checkbox-v"
                    control={
                      <CheckBox
                        color="secondary"
                        onChange={ev =>
                          changeCheckbox(ev, column, checksOptions, true)
                        }
                        checked={checksOptions.vCheckBox}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={10}>
                  <FormControlLabel
                    key={`tab-grid-a-check-option-${optionTab}-${checksOptions.key}`}
                    control={
                      <CheckBox
                        onChange={ev =>
                          changeCheckbox(ev, column, checksOptions, false)
                        }
                        color="secondary"
                        checked={checksOptions.aCheckBox}
                      />
                    }
                    label={checksOptions.name}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        ))}
    </Grid>
  )
}

export default connect(ManageFeatures)
