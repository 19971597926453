import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useMemo } from 'react';
import { TextField } from '../../../../../components/common/Inputs';
import {
  ANY_OF_THOSE,
  NONE_OF_THOSE,
  STARTS_WITH,
  ENDS_WITH,
  CONTAINS,
  STRING_FILTER_OPTIONS,
 } from '../../../../../constants/filter';
import { useTrans } from '../../../../../services/i18n';
import * as AUTOCOMPLETES from '../../../../autocompletes';

const DropdownFilter = ({ getFieldProps, values, autocomplete, options }) => {
  const { trans } = useTrans();

  const AutocompleteComponent = useMemo(
    () => (typeof autocomplete === 'function' ? autocomplete : AUTOCOMPLETES[autocomplete] || null),
    [autocomplete]
  );

  const { menuItems = STRING_FILTER_OPTIONS, ...autocompleteProps } = options || {};

  return (
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12}>
        <TextField {...getFieldProps('type')} select>
          {menuItems.map((v, i) => (
            <MenuItem key={i} value={v}>
              {trans(v.toLocaleLowerCase())}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {(values.type === ANY_OF_THOSE || values.type === NONE_OF_THOSE) && AutocompleteComponent && (
        <Grid item sm={6} xs={12}>
          <AutocompleteComponent multi {...getFieldProps('data')} {...autocompleteProps} />
        </Grid>
      )}
    </Grid>
  );
};

export default DropdownFilter;
