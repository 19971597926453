import React from 'react';
import { Grid, Divider, FormControlLabel, Checkbox, Typography, Zoom } from '@material-ui/core';
import { filterComponents } from './';
import { useTrans } from '../../../../../services/i18n';

const MultipleFilter = ({ filters, values, setValues, getFieldProps, getMultipleFilterValues }) => {
  const { trans } = useTrans();
  const setComponentValues = React.useCallback(
    (newValObj) => setValues({ ...values, ...newValObj }),
    [setValues, values]
  );
  const containerRef = React.useRef(null);
  const showFirst = Object.keys(values).reduce((acc, k) => (k.endsWith('_show') ? [...acc, k] : acc), []).length === 0;
  const sortedFilters = React.useMemo(
    () => filters.sort((f1, f2) => (!!values[`${f1.value}_show`] > !!values[`${f2.value}_show`] ? -1 : 1)),
    [filters]
  );

  const FilterComponentList = React.useMemo(
    () =>
      ({ filters, getComponentValues, setComponentValues, getFieldProps }) =>
        filters.map((filterOption, i) => {
          if (!filterComponents[filterOption.component]) {
            throw new Error(`Filter component ${filterOption.component} not found`);
          }

          const FilterComponent = filterComponents[filterOption.component];
          const values = getComponentValues(filterOption);

          if (showFirst && i === 0 && !values.hasOwnProperty('show'))
            setComponentValues({ [`${filterOption.value}_show`]: true });

          return (
            <Grid container spacing={2} key={i} ref={containerRef}>
              <Grid item xs={12}>
                <FormControlLabel
                  checked={values.show}
                  onChange={(e) => setComponentValues({ [`${filterOption.value}_show`]: e.target.checked })}
                  control={<Checkbox />}
                  label={<Typography>{trans(filterOption.name)}</Typography>}
                />
              </Grid>
              <Zoom in={values.show} mountOnEnter unmountOnExit>
                <Grid item xs={12}>
                  <FilterComponent
                    values={values}
                    autocomplete={filterOption.autocomplete}
                    options={filterOption.options}
                    setValues={setValues}
                    getFieldProps={(key) => getFieldProps(`${filterOption.value}_${key}`)}
                    data={filterOption.values}
                  />
                </Grid>
              </Zoom>
              <Divider />
            </Grid>
          );
        }),
    [setValues, trans, showFirst]
  );

  return (
    <>
      <FilterComponentList
        filters={sortedFilters ?? []}
        getFieldProps={getFieldProps}
        getComponentValues={getMultipleFilterValues}
        setComponentValues={setComponentValues}
      />
    </>
  );
};

export default MultipleFilter;
