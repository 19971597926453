import React from 'react';
import { simpleIconPropsTypes } from '../../propTypes';
import ContactsIcon from '@material-ui/icons/ContactsOutlined';

export const IconOverview = (props) => {
  const { width = 25, height = 25 } = props;

  return <ContactsIcon width={width} height={height} />;
};

IconOverview.propTypes = simpleIconPropsTypes;
