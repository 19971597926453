import { Grid, MenuItem } from '@material-ui/core';
import React from 'react';
import { TextField } from '../../../../../components/common/Inputs';
import { ONLY_TEXT, STARTS_WITH, ENDS_WITH, CONTAINS } from '../../../../../constants/filter';
import { useTrans } from '../../../../../services/i18n';

const TextFilter = ({ getFieldProps, values, options }) => {
  const { trans } = useTrans();

  return (
    <Grid container spacing={2}>
      {[STARTS_WITH, ENDS_WITH, CONTAINS, ONLY_TEXT].includes(values.type) && (
        <Grid item xs={12}>
          <TextField
            label={trans(options?.label ?? 'keyword')}
            placeholder={`Enter ${trans(options?.label ?? 'keyword')}`}
            {...getFieldProps('data')}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TextFilter;
