import { makeStyles } from '@material-ui/core/styles'
import { alpha as fade } from '@material-ui/core/styles/colorManipulator'
const drawerWidth = 300

export default makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  groupTitle: {
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.sharp,
      duration: 250,
    }),
    color: `${theme.palette.primary.main} !important`,
    paddingLeft: 20,
  },
  groupTitleActive: {
    color: `${theme.palette.common.black} !important`,
  },
  groupIconInactive: {
    transition: theme.transitions.create(
      ['color', 'background-color', 'box-shadow'],
      {
        easing: theme.transitions.easing.sharp,
        duration: 250,
      }
    ),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    padding: 10,
    marginLeft: -10,
    marginRight: -10,
    borderRadius: '14px',
    backgroundColor: fade(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
    boxShadow: `0 4px 8px 0 ${fade(theme.palette.secondary.main, 0)}`,
  },
  groupIconActive: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    boxShadow: `0 4px 8px 0 ${fade(theme.palette.secondary.main, 0.3)}`,
  },
  inactive: {
    paddingLeft: 20,
    color: theme.palette.gray.main,
    borderLeft: `2px solid transparent`,
    display: 'block',
    '&:hover': {
      color: theme.palette.secondary.main,
      borderLeftColor: theme.palette.secondary.main,
      '& > span': {
        color: theme.palette.secondary.main,
      },
    },
    '& > span': {
      paddingLeft: 20,
      color: theme.palette.gray.light,
    },
  },
  active: {
    color: `${theme.palette.secondary.main} !important`,
    borderLeft: `2px solid ${theme.palette.secondary.main}`,
    '& > span': {
      color: `${theme.palette.secondary.main} !important`,
    },
  },
  borderActive: {
    borderLeft: `2px solid ${theme.palette.secondary.main}`,
  },
  groupInactive: {
    paddingLeft: 20,
    cursor: 'pointer',
    '&:hover': {
      borderLeftColor: 'transparent',
    },
  },
  groupActive: {},
  menuDivider: {
    marginLeft: 22,
    width: 22,
    backgroundColor: theme.palette.gray.divider,
  },
  appBar: {
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      position: 'absolute',
      flexShrink: 1,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    border: 0,
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 16,
      backgroundColor: theme.palette.gray.background,
      width: '100%',
      height: `calc(100% - 80px)`,
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  drawerClose: {
    border: 0,
    overflowX: 'hidden',
    width: 65,
    [theme.breakpoints.up('sm')]: {
      width: 65,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '0%',
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  topPart: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 20,
    marginBottom: 9,
    flex: '0 0 80px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  icon: {
    minWidth: 'auto',
    marginRight: 20,
    paddingLeft: 5,
  },
}))
