import * as Yup from 'yup';
import makeValidationSchema from '../../../hooks/makeValidationSchema';

export const initialValues = {
  organizationIds: [],
};

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item);
};

export const useScheme = makeValidationSchema((trans) =>
  Yup.object().shape({
    organizationIds: Yup.array(
      Yup.object().shape({
        id: Yup.string()
      })
    ).min(1, trans('required')),
  })
);
