import DateFilter from './DateFilter';
import DropdownFilter from './DropdownFilter';
import MultipleFilter from './MultipleFilter';
import NumberFilter from './NumberFilter';
import TextFilter from './TextFilter';

export const FILTER_COMPONENTS = {
  DROPDOWN: 'DropdownFilter',
  DATE: 'DateFilter',
  TEXT: 'TextFilter',
  NUMBER: 'NumberFilter',
  MULTIPLE: 'MultipleFilter',
};

export const AUTOCOMPLETES = {
  FEDERAL_DEPARTMENTS: 'FederalDepartmentsAutocomplete',
  FEDERAL_AGENCY: 'FederalAgencyAutocomplete',
  FEDERAL_OFFICE: 'FederalOfficesAutocomplete',
  TYPE: 'ContractOpportunityTypeAutocomplete',
  CONTRACT_AWARD: 'ContractAwardAutocomplete',
  NAICS: 'NaicsCodeAutocomplete',
  FEDERAL_CONTACT: 'FederalContactAutocomplete',
  PLACE_OF_PERFORMANCE: 'PlaceOfPerformanceAutocomplete',
  PSC: 'PscAutocomplete',
  SET_ASIDE: 'SetAsideAutocomplete',
  GRANT_PROGRAMS: 'GrantProgramsAutocomplete',
  APPLICANT_TYPES: 'ApplicantTypesAutocomplete',
  SOLICITATION_NUMBER: 'SolicitationNumberAutocomplete',
  SCOPE: 'ScopeAutocomplete',
  STATES: 'StatesAutocomplete',
};

export const filterComponents = {
  [FILTER_COMPONENTS.DATE]: DateFilter,
  [FILTER_COMPONENTS.DROPDOWN]: DropdownFilter,
  [FILTER_COMPONENTS.TEXT]: TextFilter,
  [FILTER_COMPONENTS.NUMBER]: NumberFilter,
  [FILTER_COMPONENTS.MULTIPLE]: MultipleFilter,
};
