import { createSelector } from '@reduxjs/toolkit'

export const selectUserPermissions = createSelector(
  [state => state.auth, state => state.adminPortalRoles.rolesList],
  (user, roles) => {
    const subRole = user.sub_role_id
    if (!roles) return []
    if (!subRole) return []
    const userRole = roles.find(role => role.id === subRole)

    if (!userRole) return []

    return userRole.permissions
  }
)

export const selectUserRole = createSelector(
  [state => state.auth, state => state.adminPortalRoles.rolesList],
  (user, roles) => {
    const subRole = user.sub_role_id
    if (!roles) return []

    if (!subRole) return []
    const userRole = roles.find(role => role.id === subRole)

    if (!userRole) return []

    return userRole
  }
)

export const isEnabled = (permissions = [], ...args) => {
  const [route, subOption = '', att = 'view'] = args
  const module = permissions?.find(p => p.key === route)
  if (!module) {
    return typeof subOption === 'string' || []
  }
  if (typeof subOption === 'string') {
    const selectedOption = subOption || module.menuOption

    const subModule = module.submodules.find(sm => sm.key === selectedOption)
    if (!subModule) return true
    return subModule[att]
  } else if (Array.isArray(subOption)) {
    return subOption.reduce((result, key) => {
      const subModule = module.submodules.find(sm => sm.key === key)
      if (subModule) {
        result.push(subModule)
      }
      return result
    }, [])
  }
}
