import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'
import { Button } from '../../../../components/common/Buttons'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { TabPanel } from '../../../../components/common/Tabs'
import { ReactComponent as IconVLetter } from '../../../../components/assests/ico-V-letter.svg'
import { ReactComponent as IconALetter } from '../../../../components/assests/ico-A-letter.svg'
import OptionsForm from '../optionsForm'
import connect from '../../connect'
import ComponentFeaturesForm from '../ComponentFeaturesForm'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const ManageFeatures = ({
  optionTab,
  tabTier,
  updateSubscriptionSettings,
  saveSubscriptionSettings,
  keyTabOptions,
  subscriptionSettings,
  updateOrganizationsSettings,
  componentFeatures,
}) => {
  const [isSend, setIsSend] = useState(false)
  const { trans } = useTrans()
  const { successAlert } = useAlertContext()
  const [modules, setModules] = useState([])
  const permissions = useSelector(selectUserPermissions)
  const updatePermissions = () => {
    setIsSend(true)
    const currentTabData = subscriptionSettings.find(obj => {
      return obj.idTab === optionTab
    })
    saveSubscriptionSettings({
      features: currentTabData?.existingUsers?.optionsList,
      components: componentFeatures[tabTier],
      type: keyTabOptions[`${optionTab}`],
    })
      .then(res => {
        setIsSend(false)
        successAlert('Subscription settings has been updated')
      })
      .catch(error => {
        setIsSend(false)
        successAlert(error.message)
      })
  }

  const updateOrganizationsPermissions = () => {
    setIsSend(true)
    const currentTabData = subscriptionSettings.find(obj => {
      return obj.idTab === optionTab
    })

    updateOrganizationsSettings({
      features: currentTabData?.existingUsers?.optionsList,
      type: keyTabOptions[`${optionTab}`],
    })
      .then(res => {
        setIsSend(false)
        successAlert('Subscription settings has been applied')
      })
      .catch(error => {
        setIsSend(false)
        successAlert(error.message)
      })
  }
  const selectUnselectAll = (active, visibilty) => {
    let copyModules = modules
    copyModules = copyModules.map(column => {
      const newOptions = column.submodules.map(checkOptions => {
        return {
          ...checkOptions,
          name: checkOptions.name,
          vCheckBox: visibilty ? active : checkOptions.vCheckBox,
          aCheckBox: !visibilty ? active : checkOptions.aCheckBox,
          active: !visibilty ? active : checkOptions.aCheckBox,
        }
      })
      return {
        ...column,
        submodules: newOptions,
      }
    })

    const currentTab = subscriptionSettings.find(obj => {
      return obj.idTab === optionTab
    })
    const newTab = {
      ...currentTab,
      existingUsers: {
        optionsList: copyModules,
      },
    }
    const oldTabs = subscriptionSettings.filter(obj => {
      return obj.idTab !== optionTab
    })
    let newTabs = [...oldTabs, newTab]
    updateSubscriptionSettings(newTabs)
  }

  useEffect(() => {
    if (subscriptionSettings) {
      const tabUsers = subscriptionSettings.find(obj => {
        return obj.idTab === optionTab
      })
      setModules(tabUsers?.existingUsers?.optionsList || [])
    }
  }, [subscriptionSettings, optionTab])

  return (
    <TabPanel
      value={optionTab}
      tab={optionTab}
      index={optionTab}
      style={{ marginBottom: '32px' }}
      key={`existing-users-tab-panel-${optionTab}`}
    >
      <Paper
        component={SpacingContainer}
        py={3}
        px="5sm3"
        key={`existing-users-tabpanel-paper-${optionTab}`}
      >
        <SpacingContainer>
          <Grid
            key={`existing-users-tab-grid-${optionTab}`}
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid
              item
              md={3}
              xs={12}
              key={`existing-users-tab-grid-title-${optionTab}`}
            >
              <Typography
                variant="h4"
                weight="500"
                fontFamily="lexend"
                fontSize="20px"
                style={{ alignItems: 'center', display: 'flex' }}
                key={`existing-users-tab-grid-title-typography-${optionTab}`}
              >
                {'For All Users'}
              </Typography>
            </Grid>
            <Grid
              item
              style={{ alignItems: 'center', display: 'flex' }}
              key={`existing-users-tab-grid-visibility-title-${optionTab}`}
            >
              <IconVLetter />
              <Typography
                weight="600"
                key={`existing-users-typogpraphy-grid-visibility-title-${optionTab}`}
                fontFamily="Manrope"
                fontSize="14px"
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  marginLeft: '6px',
                }}
              >
                {`Visibility`}
              </Typography>
              <Button
                color="primary"
                variant="text"
                onClick={() => selectUnselectAll(true, true)}
              >
                {trans('select-all')}
              </Button>
              <Button
                color="primary"
                variant="text"
                onClick={() => selectUnselectAll(false, true)}
              >
                {trans('unselect-all')}
              </Button>
            </Grid>
            <Grid item style={{ alignItems: 'center', display: 'flex' }}>
              <IconALetter />
              <Typography
                weight="600"
                fontFamily="Manrope"
                fontSize="14px"
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  marginLeft: '6px',
                }}
              >
                {`Accessibility`}
              </Typography>
              <Button
                color="primary"
                variant="text"
                onClick={() => selectUnselectAll(true, false)}
              >
                {trans('select-all')}
              </Button>
              <Button
                color="primary"
                variant="text"
                onClick={() => selectUnselectAll(false, false)}
              >
                {trans('unselect-all')}
              </Button>
            </Grid>
          </Grid>
          <Divider light spacing={2} />
          <OptionsForm newUser={false} />
          <Divider variant="middle" width="inherit" light spacing={4} xs={12} />
          <ComponentFeaturesForm />
          <Divider variant="middle" width="inherit" light spacing={4} xs={12} />
          {isEnabled(permissions, 'subscription-settings', '', 'edit') && (
            <Grid container item justifyContent="space-between">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  color="success"
                  pending={isSend}
                  onClick={updatePermissions}
                >
                  {trans('update-changes')}
                </Button>
              </div>
              <div>
                <Button
                  id={`cancel`}
                  variant="outlined"
                  color="primary"
                  key={`subscriptiion-apply-changes-right-button-existing-users-${optionTab}`}
                  pending={isSend}
                  onClick={updateOrganizationsPermissions}
                >
                  {`Apply settings`}
                </Button>
              </div>
            </Grid>
          )}
        </SpacingContainer>
      </Paper>
    </TabPanel>
  )
}

export default connect(ManageFeatures)
