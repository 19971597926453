import { useState } from 'react';
import Typography from '../../../../components/common/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { InlineContainer } from '../../../../components/common/Containers';
import { RouterLink } from '../../../../components/common/Buttons';
import { Avatar } from '../../../../components/common/Cards';
import { IconButton } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close';
import useBooleanHandlers from '../../../../hooks/useBooleanHandlers';
import ConfirmDialog from '../../../../components/generics/Confirm/Dialog';
import { useTrans } from '../../../../services/i18n';
import { Chip } from '../../../../components/common/Chip';

const ItemOrganization = ({ item, removeUserOrganization }) => {
  const { trans } = useTrans();
	const getLink = () => `/organizations/${item.organizationId}`;
  const [openRemove, onOpenRemove, onCloseRemove] = useBooleanHandlers();
  const [pendingRemove, setPendingRemove] = useState(false)

  const removeOrg = () => {
    setPendingRemove(true)
    removeUserOrganization({ id: item.id }).then(() => {
      setPendingRemove(false)
      onCloseRemove()
    })
  }

  return (
    <>
    <TableRow>
      <TableCell align="left">
        <InlineContainer spacing={1}>
					<Avatar variantAvatar="rounded" bgColor="gray-second" size={40} value={item.orgName} />
					<Typography to={getLink()} weight="500" variant="h6" component={RouterLink}>
						{item.orgName}
					</Typography>
        </InlineContainer>
      </TableCell>

      <TableCell align="left">
        <Chip
          label={item.orgActive ? trans('activate') : trans('deactivate')}
          color={item.orgActive ? 'success' : 'secondary'}
        />
      </TableCell>

      <TableCell align="left">
        <Typography variant="inherit" fontFamily="lexend">
          {item.ownerName}
        </Typography>
      </TableCell>
      <TableCell align="right">
        {
          !item.owner &&
          <IconButton onClick={onOpenRemove}>
            <CloseIcon fontSize="small"/>
          </IconButton>
        }
      </TableCell>

    </TableRow>
    <ConfirmDialog
      open={openRemove}
      onClose={onCloseRemove}
      onConfirm={removeOrg}
      pending={pendingRemove}
      title={trans('remove-user-organization')}
      text={trans('confirm-action-remove-user-organization')}
    >
      <Typography color="hint">{item.orgName}</Typography>
    </ConfirmDialog>
    </>
  );
};

export default ItemOrganization;
