import CloseIcon from '@material-ui/icons/Close'
import MUIAutocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'
import { emptyArray } from '../../../../utils/arrayUtils'
import CheckBox from '../CheckBox'
import TextField from '../TextField'

const ChipProps = { deleteIcon: <CloseIcon /> }

const getOptionSelected = (option, value) => {
  if (option && option.id) return option.id === (value && value.id)
  return option === value
}

const getOptionLabelFormatted = (labelKey, formatLabelCB, option) => {
  if (labelKey) {
    if (option[labelKey]) {
      if (option[labelKey].includes('Unassigned User')) {
        return option['email'] || ''
      }
      return formatLabelCB(option[labelKey])
    }
    return ''
  } else {
    return option
  }
  // return labelKey ? (option[labelKey] ? formatLabelCB(option[labelKey]) : '') : option;
}

const getOptionLabel = (labelKey, option) => {
  if (labelKey) {
    if (
      !emptyArray(option[labelKey]) &&
      option[labelKey].includes('Unassigned User')
    ) {
      return option['email'] || ''
    }
    return option[labelKey] || ''
  } else {
    return option
  }
  // return labelKey ? option[labelKey] || '' : option;
}

const CheckedOption = ({ label, selected }) => (
  <React.Fragment>
    <CheckBox checked={selected} />
    {label}
  </React.Fragment>
)

export default function Autocomplete({
  label,
  options,
  autoFocus,
  error,
  required,
  onChange,
  value,
  labelKey = 'name',
  name = 'auto',
  disableCloseOnSelect = false,
  multiple = false,
  placeholder = 'Select to enter...',
  plain = false,
  formatLabelCB = null,
  getOptionFieldLabel = null,
  ...rest
}) {
  const textFieldProps = {
    name,
    label,
    autoFocus,
    error,
    required,
    placeholder,
  }

  const handleChange = React.useCallback(
    (_event, value) => {
      try {
        if (typeof onChange === 'function')
          return onChange({ target: { name, value } })
      } catch (error) {
        return
      }
    },
    [name, onChange]
  )

  return (
    <>
      <MUIAutocomplete
        id={`autocomplete-${name}`}
        multiple={multiple}
        value={value}
        onChange={handleChange}
        options={options || []}
        getOptionLabel={
          typeof formatLabelCB === 'function'
            ? getOptionLabelFormatted.bind(null, labelKey, formatLabelCB)
            : getOptionLabel.bind(null, labelKey)
        }
        getOptionSelected={getOptionSelected}
        renderInput={params => (
          <TextField
            className={label ? 'aclabel' : 'achiddenlabel'}
            {...params}
            {...textFieldProps}
          />
        )}
        ChipProps={ChipProps}
        disableCloseOnSelect={disableCloseOnSelect}
        renderOption={
          disableCloseOnSelect
            ? (option, { selected }) => (
                <CheckedOption
                  label={
                    getOptionFieldLabel
                      ? getOptionFieldLabel(option)
                      : plain
                      ? option
                      : option[labelKey].includes('Unassigned User')
                      ? option[labelKey] + ' (' + option['email'] + ')'
                      : option[labelKey]
                  }
                  selected={selected}
                />
              )
            : undefined
        }
        {...rest}
      />
    </>
  )
}
