import React from 'react'
import ConfirmDialog from '../../../../../components/generics/Confirm/Dialog'
import { useTrans } from '../../../../../services/i18n'
import ModalText from './ModalText'
import connect from '../../../connect'

function BlockModal({
  item,
  open,
  onClose,
  updateStatus,
  fetchList,
  canUnblock,
}) {
  const { trans } = useTrans()

  const handleConfirm = () => {
    updateStatus({
      id: item.id,
      status: canUnblock ? 'active' : 'blocked',
    }).then(() => {
      fetchList()
    })
    onClose()
  }

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      onConfirm={handleConfirm}
      title={trans(
        canUnblock ? 'unblock-job-opportunity' : 'block-job-opportunity'
      )}
      text={
        <ModalText
          title={trans(
            canUnblock
              ? 'sure-you-want-to-unblock-opportunity'
              : 'sure-you-want-to-block-opportunity'
          )}
          itemName={item.title}
        />
      }
      textStyles={{ margin: 0, padding: 0 }}
      hideIcon
      confirmType
    />
  )
}

export default connect(BlockModal)
