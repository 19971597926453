import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconGovermentFiles = (props) => {
  const { width = 22, height = 22 } = props;

  return (
    <SvgIcon width={width} height={height} viewBox="0 0 22 22">
      <path d="M18 5c1.598 0 2.904 1.249 2.995 2.824L21 8v9c0 1.657-1.343 3-3 3H4c-1.657 0-3-1.343-3-3V5c0-1.657 1.343-3 3-3h4c.334 0 .647.167.832.445L10.535 5zM7.464 4H4c-.552 0-1 .448-1 1v12c0 .552.448 1 1 1h14c.552 0 1-.448 1-1v-6.005h-8c-1.054 0-1.918-.816-1.995-1.85L9 8.994V6.304L7.464 4zM11 7v1.995h8V8c0-.513-.386-.936-.883-.993L18 7h-7z" />
    </SvgIcon>
  );
};

IconGovermentFiles.propTypes = simpleIconPropsTypes;
