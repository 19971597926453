import React from 'react'
import * as Yup from 'yup'
import { Button } from '../../../components/common/Buttons'
import { useTrans } from '../../../services/i18n'
import AuthLayout from '../../../components/layouts/AuthLayout'
import {
  AUTH_NEW_PASSWORD_REQUIRED,
  AUTH_SMS_MFA,
  AUTH_SOFTWARE_TOKEN_MFA,
  ROUTE_MIGRATION,
  ROUTE_SIGNUP,
} from '../../../constants'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { AmplifyAuth, getAmplifyAuthData } from '../../../services/amplifyAuth'
import { useApplication } from '../../../services/application'
import SignInForm from './components/SignInForm'
import makeValidationSchema from '../../../hooks/makeValidationSchema'
import Authentication from './components/Authentication'
import useErrorState from '../../../hooks/useErrorState'
import ChangePasswordForm from '../ChangePassword/ChangePasswordForm'
import {
  shouldResendExceptions,
  temporaryPasswordExpired,
  userNotConfirmed,
} from './constants'
import useLogout from '../../../hooks/useLogout'

const useScheme = makeValidationSchema(trans => {
  return Yup.object().shape({
    login: Yup.string()
      .email(trans('enter-valid-email-password'))
      .required(trans('enter-valid-email-password')),
    password: Yup.string().required(trans('enter-valid-email-password')),
  })
})

const SignIn = () => {
  const { trans } = useTrans()
  const navigate = useNavigate()
  const application = useApplication()
  const schema = useScheme()

  const [error, onError, setError] = useErrorState()
  const [pending, setPending] = React.useState(false)
  const [user, setAuthUser] = React.useState(undefined)
  const [challenge, setChallenge] = React.useState(undefined)
  const logout = useLogout()

  const loginToApplication = React.useCallback(
    async user => {
      const { name } = await application.authorize(getAmplifyAuthData(user))
      if (!name) {
        navigate(ROUTE_MIGRATION.path)
      } else {
        navigate('/dashboard')
      }
    },
    [application, navigate]
  )

  const signInUser = React.useCallback(
    async ({ login, password }) => {
      try {
        setError(undefined)
        const user = await AmplifyAuth.signIn(login.toLowerCase(), password)
        if (user) {
          const { apiKey } = await application.call('admin.createApiKey', {}, false)
          if (apiKey) {
            const checkUser = await application.callPost('admin.checkUser', { email: login }, { API_KEY: apiKey })
            if (!checkUser || !checkUser.success) {
              logout()
              return Promise.reject(trans('incorrect-username-password'))
            }
          }
        }

        if (user.challengeName === AUTH_NEW_PASSWORD_REQUIRED) {
          setAuthUser(user)
          return setChallenge(AUTH_NEW_PASSWORD_REQUIRED)
        }

        if (
          user.challengeName === AUTH_SMS_MFA ||
          user.challengeName === AUTH_SOFTWARE_TOKEN_MFA
        ) {
          setAuthUser(user)
          return setChallenge(user.challengeName)
        }

        return loginToApplication(user)
      } catch (e) {
        if (shouldResendExceptions.includes(e.code)) {
          return Promise.reject(userNotConfirmed)
        }
        if (
          e.code === 'NotAuthorizedException' &&
          e.message
            ?.toLowerCase()
            .includes(
              'temporary password has expired and must be reset by an administrator'
            )
        ) {
          return Promise.reject(temporaryPasswordExpired)
        }
        return Promise.reject(trans('incorrect-username-password'))
      }
    },
    [loginToApplication, trans]
  )

  const onSubmit = React.useCallback(
    values => {
      setPending(true)
      schema
        .validate(values)
        .then(signInUser)
        .catch(e => {
          if (e === temporaryPasswordExpired) {
            setError(temporaryPasswordExpired)
            return
          }
          if (e === userNotConfirmed) {
            setError(userNotConfirmed)
            return
          }
          return onError(e)
        })
        .finally(() => setPending(false))
    },
    [onError, schema, signInUser]
  )

  const onMfaCancel = React.useCallback(
    error => {
      if (error) onError(error)
      setAuthUser(undefined)
      setChallenge(undefined)
      setPending(false)
    },
    [onError]
  )

  const onMfaSubmit = React.useCallback(
    code => {
      return setAuthUser(authUser => {
        AmplifyAuth.confirmSignIn(authUser, code, challenge)
          .then(loginToApplication)
          .catch(() => onError(trans('incorrect-authentication-code'))) // Shout it be translated here?
          .finally(onMfaCancel)

        return undefined
      })
    },
    [challenge, loginToApplication, onError, onMfaCancel, trans]
  )

  const onNewPasswordSubmit = React.useCallback(
    ({ password }) => {
      return setAuthUser(authUser => {
        AmplifyAuth.completeNewPassword(authUser, password)
          .then(loginToApplication)
          .then(() => {
            if (
              authUser.challengeParam.userAttributes.email_verified === 'false'
            ) {
              return application.call('organization.updateOrgUserStatus')
            }
          })
          .catch(() => onError(trans('incorrect-password'))) // Shout it be translated here?
          .finally(onMfaCancel)

        return undefined
      })
    },
    [application, loginToApplication, onError, onMfaCancel, trans]
  )

  return (
    <AuthLayout
      actions={
        <Button
          id="sign-up-btn"
          color="secondary"
          variant="contained"
          component={RouterLink}
          to={ROUTE_SIGNUP.path}
        >
          {trans('sign-up')}
        </Button>
      }
    >
      {[AUTH_SOFTWARE_TOKEN_MFA, AUTH_SMS_MFA].includes(challenge) ? (
        <Authentication
          challenge={challenge}
          onSubmit={onMfaSubmit}
          onCancel={onMfaCancel}
          user={user}
        />
      ) : challenge === AUTH_NEW_PASSWORD_REQUIRED ? (
        <ChangePasswordForm onSubmit={onNewPasswordSubmit} />
      ) : (
        <SignInForm
          onSubmit={onSubmit}
          pending={pending}
          error={error}
          onFlushError={() => {
            setError(undefined)
          }}
        />
      )}
    </AuthLayout>
  )
}

export default SignIn
