import { connect } from 'react-redux'
import { actions } from '../../store/dashboard'

function mapStateToProps(state) {
  return {
    statistics: state.dashboard.statistics,
    pending: state.dashboard.pending,
    recentlyClients: state.dashboard.recentlyClients,
    optionsChart: state.dashboard.optionsChart,
    numberAccounts: state.dashboard.numberAccounts,
    mapChart: state.dashboard.mapChart,
    authUser: state.auth,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetch: () => dispatch(actions.getInformation({})),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
