import { TableCell } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import { Button, RouterLink } from '../../../../components/common/Buttons'
import { intlDateWithTimeFormat } from '../../../../utils/dateUtils'
import { Avatar } from '../../../../components/common/Cards'
import Typography from '../../../../components/common/Typography'
import { InlineContainer } from '../../../../components/common/Containers'
import { useState } from 'react'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const Item = ({ detail, updateParentOrganization }) => {
  const [loading, setLoading] = useState(false)
  const { successAlert, errorAlert } = useAlertContext()

  const permissions = useSelector(selectUserPermissions)
  const handleUpdateParentOrganization = () => {
    setLoading(true)
    Promise.resolve(updateParentOrganization({ id: detail.id }))
      .then(res => {
        if (res.payload && res.payload.success) {
          successAlert('Organization was successfully updated')
        } else {
          errorAlert(
            res?.payload?.message ? res.payload.message : res?.error?.message
          )
        }
      })
      .catch(err => {
        errorAlert(err.message)
      })
      .finally(() => setLoading(false))
  }
  return (
    <TableRow>
      <TableCell
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#2445A7',
        }}
      >
        <InlineContainer spacing={1}>
          <Avatar
            bgColor="gray-second"
            variantAvatar="rounded"
            size={40}
            value={detail?.logo ? detail.logo : detail?.orgName}
          />
          <Typography
            weight="500"
            to={`/organizations/${detail.id}`}
            variant="h6"
            component={RouterLink}
          >
            {detail.orgName}
          </Typography>
        </InlineContainer>
      </TableCell>
      <TableCell align="left">
        {!detail.parentOrganizationId
          ? 'Parent Organization'
          : 'Subsidiary Organization'}
      </TableCell>
      <TableCell align="left">
        {intlDateWithTimeFormat(detail.createdAt)}
      </TableCell>

      {isEnabled(permissions, 'organization', 'add-organization', 'edit') &&
        detail.parentOrganizationId && (
          <TableCell align="left">
            <Typography
              component={Button}
              style={{ color: '#2445A7' }}
              variant="text"
              pending={loading}
              disabled={loading}
              onClick={handleUpdateParentOrganization}
            >
              Set as Parent
            </Typography>
          </TableCell>
        )}
    </TableRow>
  )
}

export default Item
