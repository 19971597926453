import makeStyles from '@material-ui/core/styles/makeStyles';
import { Height } from '@material-ui/icons';

export const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.modal - 1,
    overflow: 'auto',
    alignItems: 'flex-start',
  },
  fixed: {
    zIndex: theme.zIndex.modal + 1,
    position: 'fixed',
  },
  wrapper: {
    position: 'relative',
    width: '633px',
  },
  absolute: {
    position: 'absolute',
  },
  container: {
    backgroundColor: '#17B471',
    borderRadius: '6px',
    width: 'auto',
    height: 'auto',
  },
  iconBanner: {
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleBanner: {
    color: '#ffffff',
  },
  title:{
    marginLeft:"10px"
  },
  question: { 
    color: "#888FA9", 
    display: 'flex',
    justifyContent: 'center', 
    alignItems: 'center'
  },
  counter:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveButton:{
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'right',
    padding:"0px 30px 0px 0px",
  },
  save: {
    height: "52px",
    width: "140px",
    borderRadius: "4px",
    background: "#2445A7",
    color: "#ffffff"
  }
}));

export default useStyles;
