import { Box, Grid } from '@material-ui/core'
import { SpacingContainer } from '../../../components/common/Containers/Base'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import ListInfoRow from '../../../components/generics/ListInfoRow'
import SortingPanel from '../../../components/generics/SortingPanel'
import React, { useEffect } from 'react'
import connect from '../connect'
import useBooleanHandlers from '../../../hooks/useBooleanHandlers'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'
import TabsContainer from './TabsContainer'
import { DropdownSelect } from '../../../components/common/Inputs'
import StatusDropdown from './StatusDropdown'
import TypeDropdown from './TypeDropdown'
import ProgressFilters from './ProgressFilters'

const Header = ({
  scope,
  requestedQuotes,
  tab,
  onChangeTab,
  onChangeOrder,
  onChangeFilters,
  fetchList,
}) => {
  const { trans } = useTrans()
  //const [openModal, onOpenModal, onCloseModal] = useBooleanHandlers()
  const orderOptions = [
    {
      name: 'Registration date',
      value: 'createdAt',
      asc: 'Oldest first',
      desc: 'Newest first',
    },
  ]
  const { order, count, items } = requestedQuotes

  useEffect(() => {}, [])

  return (
    <>
      <SpacingContainer py={3} px={0}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              weight="600"
              variant="h2"
              fontFamily="lexend"
              title
              style={{ alignItems: 'center', display: 'flex' }}
            >
              {trans('requested-quotes')}{' '}
              <ShowTooltip sectionToShow="requested-quotes-table" />
            </Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </SpacingContainer>

      {/* <SearchTopPanel
        scope={scope}
        filters={filters}
        onChangeFilters={onChangeFilters}
        filterOptions={[]}
      /> */}

      <Box display="flex" justifyContent="space-between" maxHeight="3.25rem">
        <TabsContainer tab={tab} onChangeTab={onChangeTab} />
        <ProgressFilters />
      </Box>

      <Box display="flex">
        <Box flex={1}>
          <ListInfoRow
            count={count}
            description={trans('results')}
            sort={
              <SortingPanel
                order={order}
                scope={scope}
                orderOptions={orderOptions}
                onChangeOrder={onChangeOrder}
              />
            }
          />
        </Box>
        <Box display="flex" alignItems="center" gridGap="1.5rem">
          <StatusDropdown isGrant={tab === 2} />
          <TypeDropdown />
        </Box>
      </Box>
    </>
  )
}

export default connect(Header)
