import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTrans } from '../../../../../services/i18n'
import Typography from '../../../../../components/common/Typography'
import { ModalDialog } from '../../../../../components/common/Dialogs'
import {
  Paper,
  SpacingContainer,
} from '../../../../../components/common/Containers'
import { Button } from '../../../../../components/common/Buttons'
import { useAlertContext } from '../../../../../contexts/AlertContext'
import connect from '../connect'
import { TextField } from '../../../../../components/common/Inputs'
import { useForm } from '../../../../../hooks/useForm'
import { getInitialValues, useScheme } from './helpers'

const formOptions = {
  validateOnMount: false,
  validateOnChange: false,
  enableReinitialize: true,
}

const AddNewFile = ({
  open,
  onClose,
  item = null,
  saveRequestTemplateFile,
  requestTemplateId,
  getList,
}) => {
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [pending, setPending] = React.useState(false)
  const schema = useScheme()

  const onSubmit = async () => {
    try {
      setPending(true)
      const { payload } = await saveRequestTemplateFile({
        ...values,
        id: item?.id,
        requestTemplateId: requestTemplateId,
      })
      setPending(false)
      successAlert(payload.message)
      closeModal()
      getList({
        requestTemplateId: requestTemplateId,
        text: '',
        order: ['createdAt', 'desc'],
        filters: {},
        pagination: { page: 1, perPage: 10 },
      })
    } catch (error) {
      errorAlert('Server error, please refresh the page or try again later.')
      setPending(false)
      closeModal()
    }
  }

  const {
    getFieldProps,
    handleSubmit,
    values,
    isSubmitting,
    isValid,
    resetForm,
  } = useForm(schema, getInitialValues(item), onSubmit, formOptions)

  const closeModal = () => {
    resetForm()
    onClose()
  }

  useEffect(() => {}, [])

  return (
    <ModalDialog
      title={item ? trans('edit-file-template') : trans('add-file-template')}
      open={open}
      onClose={onClose}
      actions={
        <Grid container>
          <Grid
            item
            sm={12}
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              variant="contained"
              onClick={closeModal}
              style={{ marginRight: '16px' }}
            >
              {trans('cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              pending={pending}
            >
              {item ? trans('save') : trans('add')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Paper>
        <SpacingContainer>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item sm={12} xs={12}>
              <TextField
                label={trans('file-name')}
                {...getFieldProps('filename')}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                multiline
                rows={3}
                label={trans('description')}
                {...getFieldProps('description')}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                label={trans('days-to-fill')}
                {...getFieldProps('daysToFill')}
              />
            </Grid>
          </Grid>
        </SpacingContainer>
      </Paper>
    </ModalDialog>
  )
}

export default connect(AddNewFile)
