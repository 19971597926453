import * as Yup from 'yup'
import makeValidationSchema from '../../../../hooks/makeValidationSchema'

export const initialValues = {
  section: '',
  view: '',
  content: '',
}

export const getInitialValues = item => {
  return Object.assign({}, initialValues, item)
}

export const useScheme = makeValidationSchema(trans =>
  Yup.object().shape({
    view: Yup.string().required(trans('required')),
    section: Yup.string().required(trans('required')),
    content: Yup.string().max(500).required(trans('required')),
  })
)
