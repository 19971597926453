import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconArrowForward from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';
import React from 'react';
import TextField from '../../../../components/common/Inputs/TextField';
import { IconClose, IconFilters, IconSearch } from '../../../../components/svg';
import { useTrans } from '../../../../services/i18n';
import { SpacingContainer } from '../../../common/Containers/Base';
import useStyles from '../styles';
import useSearchField from './useSearchField';

const startAdornment = (
  <InputAdornment position="start" component={SpacingContainer} px={1}>
    <IconSearch />
  </InputAdornment>
);

function SearchField({ onOpenFilters, quickView = false, name = 'searchText', ...props }) {
  const { trans } = useTrans();
  const styles = useStyles();
  const { text, changed, isFocused, onFocus, onBlur, onSubmit, onClearSearch, onChange } = useSearchField(props);

  const InputPropsSearch = {
    startAdornment,
    endAdornment: (
      <InputAdornment position="end">
        {onOpenFilters && (
          <IconButton onClick={onOpenFilters}>
            <IconFilters />
          </IconButton>
        )}
        {!!text ? (
          changed ? (
            <IconButton className="primary" size="small" type="submit">
              <IconArrowForward />
            </IconButton>
          ) : (
            <IconButton onClick={onClearSearch} size="small">
              <IconClose />
            </IconButton>
          )
        ) : null}
      </InputAdornment>
    ),
  };

  return (
    <form action="#" onSubmit={onSubmit}>
      <TextField
        className={
          quickView ? styles.searchInputLight : clsx(styles.searchInput, isFocused && styles.searchInputFocused)
        }
        InputProps={InputPropsSearch}
        type="text"
        big={!quickView}
        placeholder={trans('search...')}
        name={name}
        value={text}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        bgColor="default"
      />
    </form>
  );
}

export default SearchField;
