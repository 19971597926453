import Grid from '@material-ui/core/Grid';
import React, { useCallback } from 'react';
import ChipGroup from '../../../../../components/common/Chip/ChipGroup';
import { NONE_OF_THOSE } from '../../../../../constants/filter';
import { useTrans } from '../../../../../services/i18n';
import { emptyArray } from '../../../../../utils/arrayUtils';
import { cloneObject, formatToTitleCase } from '../../../../../utils/helpers';
import { ChipOption } from '../ChipOption';

export const ListChipGroup = ({ item, onOpenFilter, onChangeFilters, filters }) => {
  const { trans } = useTrans();
  const isNot = item.type === NONE_OF_THOSE;
  const filterOption = item.filterOption || {};

  const onRemoveSingle = useCallback(
    (key, index) => {
      const newFilters = cloneObject(filters);
      const data = newFilters[key].data;
      const value = !emptyArray(data) ? data.filter((_, i) => i !== index) : [];
      newFilters[key] = emptyArray(value) ? undefined : Object.assign(newFilters[key], { data: value });

      onChangeFilters(newFilters);
    },
    [filters, onChangeFilters]
  );

  const values = !emptyArray(item.data) ? item.data : !!item.data ? [item.data] : [];

  return values.map((d, i) => (
    <Grid item component={ChipGroup} key={i}>
      <ChipOption
        name={filterOption.name ? trans(filterOption.name) : '-'}
        value={`${isNot ? trans('not') : ''} ${d.i18nKey ? trans(d.i18nKey) : formatToTitleCase(d.value || d)}`}
        onClick={onOpenFilter ? onOpenFilter.bind(null, filterOption) : undefined}
        onDelete={onRemoveSingle.bind(null, item.key, i)}
      />
    </Grid>
  ));
};
