import Typography from '../../../components/common/Typography'
import { Grid } from '@material-ui/core'
import { RouterLink } from '../../../components/common/Buttons'
import { Avatar } from '../../../components/common/Cards'
import { InlineContainer } from '../../../components/common/Containers/Base'
import { SpacingContainer } from '../../../components/common/Containers'
import { intlDateFormat } from '../../../utils/dateUtils'
import { useMediaQueryDown } from '../../../hooks/useMediaQuery'

export default function ItemClient({ item }) {
  const getLink = () => `/organizations/${item.id}`
  const isSmallScreen = useMediaQueryDown('sm')

  return (
    <SpacingContainer px={0} py={1}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs zeroMinWidth component={InlineContainer}>
        {isSmallScreen ? (
            <>
            <Grid>
            <Avatar variantAvatar="rounded" size={40} value={item.orgName} />
            </Grid>

            <Grid>
          <div>
            <Typography
              weight="500"
              to={getLink()}
              variant="h6"
              component={RouterLink}
            >
              {item.orgName}
            </Typography>{' '}
            <br />
            <div style={{ display: 'flex' , flexDirection : 'column' }}>
              <Typography
                color="placeholder"
                variant="body2"
                style={{ marginRight: '5px' }}
              >
                Registered
              </Typography>

              <Typography
                weight="600"
                variant="body2"
                style={{ marginRight: '10px' }}
              >
                {intlDateFormat(item.createdAt)}
              </Typography>
              </div>
              </div>
              </Grid>
              <Grid>
              {!!item.ownerName && (
                <>
                  <Typography
                    color="placeholder"
                    variant="body2"
                    style={{ marginRight: '5px' }}
                  >
                    Owner
                  </Typography>
                  <Typography weight="600" variant="body2">
                    {item.ownerName}
                  </Typography>
                </>
              )}

            </Grid>
          </>

        ) : (
          <>
          <Avatar variantAvatar="rounded" size={40} value={item.orgName} />
          <div>
            <Typography
              weight="500"
              to={getLink()}
              variant="h6"
              component={RouterLink}
            >
              {item.orgName}
            </Typography>{' '}
            <br />
            <div style={{ display: 'flex' }}>
              <Typography
                color="placeholder"
                variant="body2"
                style={{ marginRight: '5px' }}
              >
                Registered
              </Typography>
              <Typography
                weight="600"
                variant="body2"
                style={{ marginRight: '10px' }}
              >
                {intlDateFormat(item.createdAt)}
              </Typography>
              {!!item.ownerName && (
                <>
                  <Typography
                    color="placeholder"
                    variant="body2"
                    style={{ marginRight: '5px' }}
                  >
                    Owner
                  </Typography>
                  <Typography weight="600" variant="body2">
                    {item.ownerName}
                  </Typography>
                </>
              )}
            </div>
          </div>
          </>
        )}
        </Grid>
      </Grid>
    </SpacingContainer>
  )
}
