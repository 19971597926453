import { Sketch } from '@uiw/react-color';
import React, { useState } from 'react';
import { Button } from '../../../../components/common/Buttons';
import { BottomLeftPosition, Popover } from '../../../../components/common/Dialogs/Popover';
import usePopover from '../../../../hooks/usePopover';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { Grid } from '@material-ui/core';
import Typography from '../../../../components/common/Typography';

const ColorPicker = ({ color, setColor, label }) => {
    const [anchorEl, onOpenPopover, onClosePopover] = usePopover();

    return (
        <React.Fragment>
            <Grid container>
                <Grid item style={{ marginRight: "10px" }}>
                    <Typography variant="h6" weight="800">
                        {label}
                    </Typography>
                </Grid>
                <Grid item>
                    <Brightness1Icon style={{ color: `${color}`, width:'15px', height:'15px', marginTop:'5px', border:'1px solid', borderColor:'#000000', borderRadius:'400px'}} />
                </Grid>
            </Grid>
            <Button onClick={onOpenPopover} variant="contained" style={{background: '#F1F3F9'}} >
                <Typography color="hint" variant="body2" weight="500">
                    {color}
                </Typography>
            </Button>
            <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClosePopover} {...BottomLeftPosition}>
                <Sketch
                    style={{ marginLeft: 20 }}
                    color={color}
                    onChange={(color) => {
                        setColor(color.hex);
                    }}
                />
            </Popover>
        </React.Fragment>
    )
}
export default ColorPicker