import { FormControl, Grid, TextField, makeStyles } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { Paper } from '../../../components/common/Containers'
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import { Divider } from '../../../components/common/Divider'
import { ColoredLogoSvg } from '../../../components/svg/logos/ColoredLogoSvg'
import { RichTextEditor } from '../../../components/common/Inputs'

const useStyles = makeStyles(theme => ({
  spacer: {
    marginBottom: '2rem',
  },
}))

function EmailTemplateEditor({ template, formik }) {
  const { trans } = useTrans()
  const classes = useStyles()
  const staticEmailBody = useMemo(
    () => template?.staticEmailBody || '',
    [template]
  )

  return (
    <Paper>
      <SpacingContainerBase>
        <Grid container style={{ position: 'relative' }}>
          <Grid item xs={12} className={classes.spacer}>
            <Typography
              variant="h4"
              weight="500"
              fontFamily="lexend"
              style={{ textAlign: 'center' }}
            >
              {trans('email-template')}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.spacer}>
          <Divider light />
        </Grid>

        <Grid item xs={12} className={classes.spacer}>
          <ColoredLogoSvg />
        </Grid>

        <Grid item xs={12} className={classes.spacer}>
          <RichTextEditor
            id="toolbar1"
            value={formik?.values?.primaryEmailBody || ''}
            onChange={value => formik.setFieldValue('primaryEmailBody', value)}
            variables={template?.variableList || []}
          />
        </Grid>

        {staticEmailBody && (
          <Grid item xs={12} className={classes.spacer}>
            <div
              dangerouslySetInnerHTML={{
                __html: staticEmailBody,
              }}
            />
          </Grid>
        )}

        <Grid item xs={12} className={classes.spacer}>
          <Divider light />
        </Grid>

        <Grid item xs={12} className={classes.spacer}>
          <RichTextEditor
            id="toolbar2"
            value={formik?.values?.secondaryEmailBody || ''}
            onChange={value => formik.setFieldValue('secondaryEmailBody', value)}
            variables={template?.variableList || []}
          />
        </Grid>

        <Grid item xs={12} className={classes.spacer}>
          <Divider light />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" color="hint">
            {trans('copyright-2021')}
          </Typography>
        </Grid>
      </SpacingContainerBase>
    </Paper>
  )
}

export default EmailTemplateEditor
