import React from 'react'
import FormAddRelease from '../../../components/forms/FormRelease';
import connect from '../connect'

const AddRelease = ({ addRelease }) => {
  return (
    <FormAddRelease saveRelease={addRelease} />
  )
}

export default connect(AddRelease)
