import React, { useEffect } from 'react'
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase'
import ClientMetrics from './ClientMetrics'
import Loader from '../../components/common/Loader'
import { Grid } from '@material-ui/core'
import Typography from '../../components/common/Typography'
import RecentlyClients from './RecentlyClients'
import AccountsBreakdown from './AccountsBreakdown'
import ClientsLocation from './ClientsLocation'
import { useNavigate } from 'react-router-dom'

import connect from './connect'

const Overview = ({
  pending,
  fetch,
  statistics,
  recentlyClients,
  mapChart,
  optionsChart,
  numberAccounts,
  authUser,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    fetch()
  }, [])

  useEffect(() => {
    if (authUser && (authUser.role === 'manager' || authUser.role === 'consulting')) {
      navigate('/organizations')
    }
  }, [authUser])

  return (
    <SpacingContainerBase>
      {pending && <Loader open={pending} />}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={4}
      >
        <Grid item>
          <Typography weight="600" variant="h2" fontFamily="lexend" title>
            Dashboard
          </Typography>
        </Grid>
      </Grid>
      {!pending && (
        <>
          <ClientMetrics items={statistics} />
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item md={12} lg={7} sm={12} xs={12}>
              <RecentlyClients items={recentlyClients} />
            </Grid>
            <Grid item md={12} lg={5} sm={12} xs={12}>
              <AccountsBreakdown
                options={optionsChart}
                numbers={numberAccounts}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={5}
            style={{ marginTop: 30 }}
          >
            <Grid item  xs={12}>
              <ClientsLocation states={mapChart} />
            </Grid>
          </Grid>
        </>
      )}
    </SpacingContainerBase>
  )
}

export default connect(Overview)
