import React, { useEffect, useState } from 'react'
import connect from '../connect'
import SpacingContainerBase from '../../../components/layouts/SpacingContainerBase'
import { useNavigate, useParams } from 'react-router-dom'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import DetailsAnchorsHeader from '../../../components/generics/DetailsAnchorsHeader'
import SectionScrollable from '../../../components/common/Containers/SectionScrollable'
import { TextField } from '../../../components/common/Inputs'
import { Button } from '../../../components/common/Buttons'
import { Divider } from '../../../components/common/Divider'
import { InlineContainer } from '../../../components/common/Containers'
import Switch from '../../../components/common/Inputs/Switch'
import { V_ADMIN_ROLES } from '../../../utils/helpers'
import { SpacingContainer, Paper } from '../../../components/common/Containers'
import { Box } from '@mui/material'
import CheckBox from '../../../components/common/Inputs/CheckBox'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'
import {
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  FormControlLabel,
  Grid,
} from '@material-ui/core'
import { useAlertContext } from '../../../contexts/AlertContext'
import { IconFilter } from '../../../components/svg/Icons'
import _ from 'lodash'

const AdminRolesDetail = ({
  currentRole,
  defaultRole,
  rolesList,
  updateRole,
  updatePermissions,
  getRolesList,
}) => {
  const navigate = useNavigate()
  const { trans } = useTrans()
  const { successAlert, errorAlert } = useAlertContext()
  const [isSend, setIsSend] = useState(false)
  const { roleKeyEdit } = useParams()
  const [permissions, setPermissions] = useState([])
  const [name, setName] = useState('')
  const [key, setKey] = useState('')
  const getLink = () => `/admin-portal-roles`

  useEffect(() => {
    if (currentRole && Array.isArray(currentRole) && currentRole.length > 0) {
      setPermissions(currentRole.permissions)
      setName(currentRole.name)
      setKey(currentRole.key)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    reloadEffect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      rolesList &&
      Array.isArray(rolesList) &&
      rolesList.length > 0 &&
      permissions.length <= 0
    ) {
      const AuxPermissions = rolesList.find(
        r => r.key === encodeURIComponent(roleKeyEdit)
      )
      setPermissions(AuxPermissions.permissions)
      setName(AuxPermissions.name)
      setKey(AuxPermissions.key)
    }
  }, [rolesList])

  const reloadEffect = async () => {
    await getRolesList()
  }

  const changeSubmodule = (ev, module, submodule, type) => {
    const newRoles = permissions.map(m => {
      if (m.key !== module) {
        return m
      } else {
        const newSubmodule = m.submodules.map(s => {
          if (s.key !== submodule) {
            return s
          } else {
            return {
              ...s,
              [type]: ev.target.checked,
            }
          }
        })
        return {
          ...m,
          submodules: newSubmodule,
        }
      }
    })
    setPermissions(newRoles)
  }

  const savePermissions = async () => {
    try {
      setIsSend(false)
      await updatePermissions({
        roleKey: key,
        permissions,
      })
      setIsSend(false)
      successAlert('Rol permissions updated')
    } catch (error) {
      console.log(error)
      errorAlert('Server error, please refresh the page or try again later.')
      setIsSend(false)
    }
  }

  const saveRole = async () => {
    try {
      setIsSend(true)
      await updateRole({ roleKey: key, name })
      setIsSend(false)
      successAlert('Role name updated')
    } catch (error) {
      console.log(error)
      errorAlert('Server error, please refresh the page or try again later.')
      setIsSend(false)
    }
  }

  const changeName = nameField => {
    setName(nameField.target.value)
  }

  const checkModule = checkModule => {
    const result = defaultRole.find(r => r.key === checkModule)
    if (result) return true
    else return false
  }

  const checkSubmodule = (checkModule, checkSubmodule, type = 'exists') => {
    const resultModule = defaultRole.find(r => r.key === checkModule)
    const resultSubmodule =
      resultModule?.submodules.find(r => r.key === checkSubmodule) || null

    if (type === 'view') {
      if (resultSubmodule.view) return true
      else return false
    }

    if (type === 'edit') {
      if (resultSubmodule.edit) return true
      else return false
    }

    if (type === 'create') {
      if (resultSubmodule.create) return true
      else return false
    }

    if (type === 'delete') {
      if (resultSubmodule.delete) return true
      else return false
    }

    if (resultSubmodule) return true
    else return false
  }

  return (
    <SectionScrollable
      TopFixed={
        <DetailsAnchorsHeader
          anchors={V_ADMIN_ROLES}
          backLink={getLink}
          onPrint={undefined}
        />
      }
    >
      <SpacingContainerBase>
        <div id="role-info">
          <SpacingContainer py={0} px={7}>
            <Paper component={SpacingContainer} py={3} px="5sm3">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs zeroMinWidth sm={12}>
                  <Typography
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      fontSize: '20px',
                    }}
                    weight="500"
                    fontFamily="lexend"
                  >
                    {trans('role-information')}{' '}
                  </Typography>
                </Grid>
              </Grid>
              <Box style={{ alignItems: 'center', paddingTop: '20px' }}>
                <TextField
                  placeholder="Required"
                  type="text"
                  value={name}
                  name="name"
                  onChange={ev => changeName(ev)}
                  disabled={false}
                  required
                  label="Role Name"
                  style={{ width: '100%', marginRight: '16px' }}
                />{' '}
                {!name && (
                  <p
                    className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled Mui-required"
                    id="input-name-helper-text"
                    style={{ color: '#ff5225' }}
                  >
                    name is a required field
                  </p>
                )}
              </Box>
              <Box
                style={{
                  marginTop: '1rem',
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs zeroMinWidth>
                    <Button
                      variant="contained"
                      style={{ background: '#17b471', color: '#fff' }}
                      onClick={name ? saveRole : () => {}}
                      pending={isSend}
                    >
                      {' '}
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </SpacingContainer>
        </div>
        <div id="role-permissions">
          <SpacingContainer py={3} px={7}>
            <Paper component={SpacingContainer} py={3} px="5sm3">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginBottom: '12px' }}
              >
                <Grid item xs zeroMinWidth>
                  <Typography
                    weight="500"
                    fontFamily="lexend"
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      fontSize: '20px',
                      marginTop: '12px',
                    }}
                  >
                    <IconFilter style={{ marginRight: '5px' }} />{' '}
                    {trans('role-permissions')}{' '}
                    <ShowTooltip sectionToShow="role-permissions" />
                  </Typography>
                </Grid>
              </Grid>
              {permissions &&
                Array.isArray(permissions) &&
                permissions.map(p => {
                  if (checkModule(p.key)) {
                    return (
                      <div role={p.key}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item xs zeroMinWidth>
                            <Typography
                              weight="500"
                              fontFamily="lexend"
                              style={{
                                alignItems: 'center',
                                display: 'flex',
                                fontSize: '16px',
                                marginTop: '19px',
                              }}
                            >
                              {`${p.name}`}{' '}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider
                          light
                          style={{ marginTop: '12px', marginBottom: '8px' }}
                        />
                        <TableContainer component={Paper} radius={9}>
                          <Table style={{ minWidth: 1000 }}>
                            <TableBody>
                              {p.submodules &&
                                p.submodules.map(q => {
                                  if (
                                    q.key !== undefined &&
                                    checkSubmodule(p.key, q.key, 'exists')
                                  ) {
                                    return (
                                      <TableRow
                                        key={q.key}
                                        style={{ width: '50%' }}
                                      >
                                        <TableCell
                                          align="left"
                                          style={{
                                            width: '50%',
                                            padding: '0 !important',
                                          }}
                                        >
                                          <InlineContainer
                                            style={{ marginLeft: '16px' }}
                                          >
                                            <Typography
                                              style={{ fontSize: '14px' }}
                                            >
                                              {q.name}
                                            </Typography>
                                          </InlineContainer>
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          style={{
                                            width: '13%',
                                            padding: '0 !important',
                                          }}
                                        >
                                          <InlineContainer>
                                            <FormControlLabel
                                              key={`form-control-switch-view-${q.key}`}
                                              control={
                                                <Switch
                                                  checked={
                                                    q.view &&
                                                    checkSubmodule(
                                                      p.key,
                                                      q.key,
                                                      'view'
                                                    )
                                                  }
                                                  inputProps={{
                                                    'aria-label': 'module',
                                                  }}
                                                  color="primary"
                                                  disabled={
                                                    !checkSubmodule(
                                                      p.key,
                                                      q.key,
                                                      'view'
                                                    )
                                                  }
                                                  onChange={ev =>
                                                    changeSubmodule(
                                                      ev,
                                                      p.key,
                                                      q.key,
                                                      'view'
                                                    )
                                                  }
                                                  name={`switch-view-${q.key}`}
                                                />
                                              }
                                              style={{ fontSize: '12px' }}
                                              label={`Can View`}
                                            />
                                          </InlineContainer>
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          style={{
                                            width: '8%',
                                            padding: '0 !important',
                                          }}
                                        >
                                          <InlineContainer>
                                            <FormControlLabel
                                              key={`edit-check-${q.key}`}
                                              control={
                                                <CheckBox
                                                  key={`checkbox-edit-${q.key}`}
                                                  onChange={ev =>
                                                    changeSubmodule(
                                                      ev,
                                                      p.key,
                                                      q.key,
                                                      'edit'
                                                    )
                                                  }
                                                  checked={q.edit}
                                                  color={
                                                    q.edit
                                                      ? 'secondary'
                                                      : '#EDEDED'
                                                  }
                                                  disabled={
                                                    !checkSubmodule(
                                                      p.key,
                                                      q.key,
                                                      'edit'
                                                    )
                                                  }
                                                  style={
                                                    !q.view ||
                                                    !checkSubmodule(
                                                      p.key,
                                                      q.key,
                                                      'edit'
                                                    )
                                                      ? { opacity: '0.3' }
                                                      : {}
                                                  }
                                                  name={`checkbox-edit-${q.key}`}
                                                />
                                              }
                                              style={{ fontSize: '12px' }}
                                              label={`Edit`}
                                            />
                                          </InlineContainer>
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          style={{
                                            width: '13%',
                                            padding: '0 !important',
                                          }}
                                        >
                                          <InlineContainer>
                                            <FormControlLabel
                                              key={`create-check-${q.key}`}
                                              control={
                                                <CheckBox
                                                  key={`checkbox-create-${q.key}`}
                                                  onChange={ev =>
                                                    changeSubmodule(
                                                      ev,
                                                      p.key,
                                                      q.key,
                                                      'create'
                                                    )
                                                  }
                                                  checked={q.create}
                                                  color={
                                                    q.create
                                                      ? 'secondary'
                                                      : '#EDEDED'
                                                  }
                                                  disabled={
                                                    !checkSubmodule(
                                                      p.key,
                                                      q.key,
                                                      'create'
                                                    )
                                                  }
                                                  style={
                                                    !q.view ||
                                                    !checkSubmodule(
                                                      p.key,
                                                      q.key,
                                                      'create'
                                                    )
                                                      ? { opacity: '0.3' }
                                                      : {}
                                                  }
                                                  name={`checkbox-create-${q.key}`}
                                                />
                                              }
                                              style={{ fontSize: '12px' }}
                                              label={`Add/Create`}
                                            />
                                          </InlineContainer>
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          style={{
                                            width: '13%',
                                            padding: '0 !important',
                                          }}
                                        >
                                          <InlineContainer>
                                            <FormControlLabel
                                              key={`delete-check-${q.key}`}
                                              control={
                                                <CheckBox
                                                  key={`checkbox-delete-${q.key}`}
                                                  onChange={ev =>
                                                    changeSubmodule(
                                                      ev,
                                                      p.key,
                                                      q.key,
                                                      'delete'
                                                    )
                                                  }
                                                  checked={q.delete}
                                                  color={
                                                    q.delete
                                                      ? 'secondary'
                                                      : '#EDEDED'
                                                  }
                                                  disabled={
                                                    !checkSubmodule(
                                                      p.key,
                                                      q.key,
                                                      'delete'
                                                    )
                                                  }
                                                  style={
                                                    !q.view ||
                                                    !checkSubmodule(
                                                      p.key,
                                                      q.key,
                                                      'delete'
                                                    )
                                                      ? { opacity: '0.3' }
                                                      : {}
                                                  }
                                                  name={`checkbox-delete-${q.key}`}
                                                />
                                              }
                                              style={{ fontSize: '12px' }}
                                              label={`Delete`}
                                            />
                                          </InlineContainer>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  }
                                  return <></>
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    )
                  } else {
                    return <></>
                  }
                })}
              <Divider
                light
                style={{ marginTop: '12px', marginBottom: '8px' }}
              />
              <Box
                style={{
                  marginTop: '1rem',
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs zeroMinWidth>
                    <Button
                      variant="contained"
                      style={{ background: '#17b471', color: '#fff' }}
                      onClick={savePermissions}
                      pending={isSend}
                    >
                      {' '}
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </SpacingContainer>
        </div>
      </SpacingContainerBase>
    </SectionScrollable>
  )
}

export default connect(AdminRolesDetail)
