import MUIDivider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: { margin: '0 0' },
  container: {
    margin: '10px 0',
    '&:first-child': { marginTop: 0 },
  },

  '4sp': { marginTop: theme.spacing(4), marginBottom: theme.spacing(4) },
  '3sp': { marginTop: theme.spacing(3), marginBottom: theme.spacing(3) },
  '2sp': { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  '1sp': { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
  '0sp': { marginTop: 0, marginBottom: 0 },

  rounded: { borderRadius: theme.shape.borderRadius, overflow: 'hidden' },
}));

export const Divider = ({ className, spacing, children, rounded, vertical = false, ...rest }) => {
  const styles = useStyles();

  return children ? (
    <div className={clsx(styles.container, className, rounded && styles.rounded, spacing && styles[`${spacing}sp`])}>
      {children}
    </div>
  ) : (
    <MUIDivider
      className={clsx(styles.root, className, spacing && styles[`${spacing}sp`])}
      orientation={vertical ? 'vertical' : 'horizontal'}
      {...rest}
    />
  );
};
