import { Box, Hidden } from '@material-ui/core'
import React from 'react'
import Avatar from '../../../../components/common/Cards/Avatar'
import { Title } from '../../TeamingOpportunityItem/Title'
import { ITEM_VARIANT, LABEL_SIZE } from '../../constants'
import { Label, ValueText } from '../styled'
import { formatDate } from '../../../../utils/dateUtils'
import DetailsRow from '../../TeamingOpportunityItem/DetailsRow'
import { useTrans } from '../../../../services/i18n'

function Information({ item, avatar, onClick }) {
  const { trans } = useTrans()
  return (
    <Hidden xsDown>
      <Box display="flex" width="100%">
        <Avatar
          size={60}
          variant={'h5'}
          value={item.title}
          src={avatar}
          alt={item.title}
        />
        <Box flex={1} marginLeft="1rem">
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom=".5rem"
          >
            <Box flex={1}>
              <Title
                variant="h4"
                weight="600"
                color="primary"
                detailLink={`/teaming-opportunities?qv=y&id=${item.id}`}
                onClick={onClick}
                title={item.title}
                noWrap
              />
            </Box>
            <>
              <Label fontSize={LABEL_SIZE}>{trans('posted-date')}:</Label>
              <ValueText fontSize={LABEL_SIZE}>
                {formatDate(item.createdAt)}
              </ValueText>
            </>
          </Box>
          <DetailsRow
            publisher={item?.publisher}
            location={item?.location}
            postedDate={formatDate(item.createdAt)}
            responseByDate={formatDate(item.responseBy)}
            variant={ITEM_VARIANT.QUICK_VIEW}
            fontSize={LABEL_SIZE}
          />
        </Box>
      </Box>
    </Hidden>
  )
}

export default Information
