import { connect } from 'react-redux'
import { actions } from '../../store/learnWorlds'

function mapStateToProps(state) {
  return {
    items: state.learnWorlds.items,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getItems: params => dispatch(actions.getItems(params)),
    saveCourseLevels: params => dispatch(actions.saveCourseLevels(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
