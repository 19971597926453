import React from 'react'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import Typography from '../../../components/common/Typography'
import { useTrans } from '../../../services/i18n'
import { Card, Grid, Divider } from '@material-ui/core'
import MapChart from './Map/MapChart'
import ShowTooltip from '../../TooltipsManagement/components/showTooltip'

export default function ClientsLocation({ states }) {
  const { trans } = useTrans()
  return (
    <>
      <Card>
        <Paper component={SpacingContainer} py={4} px="5sm3">
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs zeroMinWidth>
              <Typography
                variant="h5"
                weight="bold"
                style={{ alignItems: 'center', display: 'flex' }}
              >
                {trans('clients-location')}{' '}
                <ShowTooltip sectionToShow="clients-location" />
              </Typography>
            </Grid>
            <Grid item xs zeroMinWidth></Grid>
          </Grid>
          <Divider light style={{ marginTop: 20 }} />
          <SpacingContainer py={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MapChart states={states} />
              </Grid>
            </Grid>
          </SpacingContainer>
        </Paper>
      </Card>
    </>
  )
}
