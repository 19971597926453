import React from 'react';
import { useTrans } from '../../../services/i18n';
import RouterLink from '../../common/Buttons/RouterLink';
import Typography from '../../common/Typography';

export default function FieldValue({
  value,
  children,
  label,
  to,
  href,
  noValueText = '',
  noWrap = true,
  color = '',
  weight = '500',
  weightLabel = '500',
}) {
  const { trans } = useTrans();

  return (
    <>
      <Typography weight={weightLabel} variant="body2" color="hint" style={{ marginBottom: 3 }}>
        {label}
      </Typography>

      {!!value ? (
        <Typography
          variant="h6"
          component={to || href ? RouterLink : 'p'}
          to={to || undefined}
          href={href || undefined}
          noWrap={noWrap}
          color={color}
          weight={weight}
        >
          {value}
        </Typography>
      ) : children ? (
        children
      ) : (
        <Typography variant="h6" component="p" color="hint" noWrap={noWrap}>
          {noValueText || trans('not-provided')}
        </Typography>
      )}
    </>
  );
}
