import Container from '@material-ui/core/Container';
import React from 'react';
import SpacingContainer from '../common/Containers/Base/SpacingContainer';

export default function SpacingContainerBase({ children, px = '4sm2', py = '5sm3' }) {
  return (
    <Container component={SpacingContainer} px={px} py={py} maxWidth="lg">
      {children}
    </Container>
  );
}
