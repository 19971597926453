import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { API, SCOPE_RELEASES } from '../../constants'
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common'
import {
  extendListBuilder,
  generateListActions,
} from '../helpers/listActionsHelpers'
import { application } from '../../services/application'

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['createdAt', 'desc'],
  releaseDetail: null,
}

//Actions
const listActions = generateListActions({
  scope: SCOPE_RELEASES,
  apiMethod: {
    GET_LIST: API.RELEASES.GET_LIST,
  },
  getStore: store => store.releases,
})

const changeStatus = createAsyncThunk('releases/changeStatus', params => {
  return application.call(API.RELEASES.CHANGE_STATUS, params)
})
function onChangeStatus(state, action) {
  const copy = [...state.items]
  const index = copy.findIndex(i => i.id === action.payload.id)
  if (index !== -1) {
    copy[index].status = action.payload.status
    Object.assign(state, {
      items: [...copy],
    })
  }
  if (state.releaseDetail) {
    const copyDetail = { ...state.releaseDetail }
    copyDetail.status = action.payload.status
    Object.assign(state, {
      releaseDetail: { ...copyDetail },
    })
  }
}

const deleteRelease = createAsyncThunk('releases/deleteRelease', params => {
  return application.call(API.RELEASES.DELETE, params)
})
function onDeleteRelease(state, action) {
  const copy = [...state.items]
  const index = copy.findIndex(i => i.id === action.payload.id)
  if (index !== -1) {
    copy.splice(index, 1)
    Object.assign(state, {
      items: [...copy],
      count: state.count - 1,
    })
  }
}

const findOne = createAsyncThunk('releases/findOne', params => {
  return application.call(API.RELEASES.FIND_ONE, params)
})
function onFindOne(state, action) {
  Object.assign(state, {
    releaseDetail: action.payload,
  })
}

const addRelease = createAsyncThunk('releases/create', params => {
  return application.call(API.RELEASES.CREATE, params)
})

const updateRelease = createAsyncThunk('releases/update', params => {
  return application.call(API.RELEASES.UPDATE, params)
})

export const actions = {
  ...listActions,
  changeStatus,
  findOne,
  deleteRelease,
  addRelease,
  updateRelease,
}

export default createReducer(INITIAL_STATE, builder => {
  return extendListBuilder(builder, actions)
    .addCase(changeStatus.fulfilled, onChangeStatus)
    .addCase(deleteRelease.fulfilled, onDeleteRelease)
    .addCase(findOne.pending, (state, action) => {
      Object.assign(state, { releaseDetail: null })
    })
    .addCase(findOne.fulfilled, onFindOne)
})
