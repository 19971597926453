import * as Yup from 'yup'
import makeValidationSchema from '../../../../../hooks/makeValidationSchema'

export const initialValues = {
  filename: '',
  description: '',
  daysToFill: '',
}

export const getInitialValues = (item) => {
  return Object.assign({}, initialValues, item)
}

export const useScheme = makeValidationSchema(() =>
  Yup.object().shape({
    filename: Yup.string().required().nullable().default(''),
    description: Yup.string().required().nullable().default(''),
    daysToFill: Yup.number('Must be a number')
      .integer('Must be a number')
      .required('Must be a number')
      .optional()
      .nullable()
      .label('Days to fill'),
  })
)
