import { useTrans } from '../../../services/i18n';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { Paper, SpacingContainer } from '../../../components/common/Containers';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Item from './Item'

export default function List({ items }) {
	const { trans } = useTrans();
	return (
		<TableContainer component={Paper} radius={9}>
			<SpacingContainer px="5sm3" py={2}>
				<Table style = {{minWidth: 1000}}>
					<TableHead>
						<TableRow>
							<TableCell>{ trans('user') }</TableCell>
							<TableCell align="left">{ trans('email') }</TableCell>
							<TableCell align="left">{ trans('registered') }</TableCell>
							<TableCell align="left">{ trans('last-login') }</TableCell>
							<TableCell align="center">{ trans('organization') }</TableCell>
							<TableCell align="right"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{items.map((item, index) => (
							<Item key={index} item={item} />
						))}
					</TableBody>
				</Table>
			</SpacingContainer>
		</TableContainer>
	)
}