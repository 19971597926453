import { Button } from '../../../../components/common/Buttons'
import { ModalDialog } from '../../../../components/common/Dialogs'
import { useTrans } from '../../../../services/i18n'
import Grid from '@material-ui/core/Grid'
import { SpacingContainer } from '../../../../components/common/Containers'
import { useState, useCallback, useEffect } from 'react'
import connect from '../requests/connect'
import { Autocomplete } from '../../../../components/common/Inputs';
import { LoaderSmall } from '../../../../components/common/LoaderSmall'
import { useAlertContext } from '../../../../contexts/AlertContext';

const FormTemplateFile = ({ open, getTemplates, getResourcesByTemplate, handleSelectedFile, onClose }) => {

  const { trans } = useTrans()
  const [template, setTemplate] = useState(null)
  const [file, setFile] = useState(null)
  const [busy, setBusy] = useState(false)
  const [templates, setTemplates] = useState([])
  const [files, setFiles] = useState([])
  const [loadingFiles, setLoadingFiles] = useState(false)
  const { errorAlert, infoAlert } = useAlertContext();

  const handleSelectTemplate = async ev => {
    setLoadingFiles(true)
    const { payload } = await getResourcesByTemplate({ templateId: ev.target.value.id, type: 'file' })
    if (payload !== undefined) {
      setFiles(payload)
      setTemplate(ev.target.value)
      !payload.length && infoAlert('No files found for this template')
    } else {
      setFiles([])
      errorAlert('An error occurred while loading the data, try again')
    }
    setLoadingFiles(false)
  }

  const handleSelectFile = ev => setFile(ev.target.value)

  const getAllTemplates = useCallback(async () => {
    if (open) {
      const { payload } = await getTemplates({ files: true })
      if (payload) {
        setTemplates(payload)
      }
    }
  }, [open, getTemplates])

  const selectFile = () => {
    setBusy(true)
    if (!file || !template) return
    const { name, description, location, daysToFill } = file

    handleSelectedFile({
      name,
      description,
      location,
      daysToFill
    })

    setBusy(false)
    closeModal()
  }

  const closeModal = () => {
    setFiles([])
    setTemplates([])
    setFile(null)
    setTemplate(null)
    setBusy(false)
    onClose()
  }

  useEffect(() => {
    getAllTemplates()
  }, [getAllTemplates])

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={trans('select-file-template')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={closeModal}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={selectFile}
            >
              {trans('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SpacingContainer px={0} py={2}>
        <Autocomplete
          options={templates}
          required
          disableClearable
          label={trans('choose-template-category')}
          placeholder={trans('required')}
          onChange={handleSelectTemplate}
          error={!template && busy && trans('required')}
        />
      </SpacingContainer>

      { loadingFiles && <LoaderSmall text={false} /> }

      { !!files.length &&
      <SpacingContainer px={0} py={2}>
        <Autocomplete
          options={files}
          required
          disableClearable
          label={trans('file-template')}
          placeholder={trans('required')}
          onChange={handleSelectFile}
          error={!file && busy && trans('required')}
        />
      </SpacingContainer>
      }
    </ModalDialog>
  )
}

export default connect(FormTemplateFile)
