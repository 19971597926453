import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { TextField } from '../../../../../components/common/Inputs';
import { IS_BETWEEN, IS_GREATER, IS_LESS, NUMBER_FILTER_OPTIONS } from '../../../../../constants/filter';
import { useTrans } from '../../../../../services/i18n';

const NumberFilter = ({ getFieldProps, values, options = {} }) => {
  const { trans } = useTrans();
  const { leftAdorment } = options;
  const inputOptions = {
    startAdornment: leftAdorment ? <InputAdornment position="start">{leftAdorment}</InputAdornment> : null,
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12}>
        <TextField {...getFieldProps('type')} select>
          {NUMBER_FILTER_OPTIONS.map((v, i) => (
            <MenuItem key={i} value={v}>
              {trans(v.toLocaleLowerCase())}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {(values.type === IS_GREATER || values.type === IS_LESS) && (
        <Grid item sm={6} xs={12}>
          <TextField type="number" {...getFieldProps('data')} InputProps={inputOptions} />
        </Grid>
      )}
      {values.type === IS_BETWEEN && (
        <>
          <Grid item sm={3} xs={12}>
            <TextField type="number" {...getFieldProps('from')} placeholder={trans('from')} InputProps={inputOptions} />
          </Grid>
          <Grid item sm={3} xs={12}>
            <TextField type="number" {...getFieldProps('to')} placeholder={trans('to')} InputProps={inputOptions} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default NumberFilter;
