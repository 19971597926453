import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit'
import { API } from '../../constants'
import { application } from '../../services/application'

//State
export const INITIAL_STATE = {
  currentRol: '',
  currentRollOptions: [],
  rolesList: [],
  currentRole: {},
  defaultRole: []
}

const getRolesList = createAsyncThunk('adminPortalRoles/getRoles', (params) => {
  return application.call(API.ADMIN_ROLES.GET_ROLES, params)
})
function onGetRolesList(state, action) {
  const roles = action.payload.success ? action.payload?.roles : []
  const defaultRole = roles.find(r => r.key === 'defualt')
  return Object.assign(state, {
    rolesList: roles,
    defaultRole: defaultRole?.permissions || []
  })
}

const createRole = createAsyncThunk('adminPortalRoles/saveRole', (params) => {
    return application.call(API.ADMIN_ROLES.CREATE_ROLE, params)
  }
)
function onCreateRole(state, action) {
  let roles = action.payload.success ? action.payload?.roles : []
  return Object.assign(state, {
    rolesList: roles,
  })
}

const updateRole = createAsyncThunk('adminPortalRoles/updateRole', (params) => {
  return application.call(API.ADMIN_ROLES.UPDATE_ROLE, params)
}
)
function onUpdateRole(state, action) {
  return Object.assign(state, {})
}

const updatePermissions = createAsyncThunk('adminPortalRoles/updateRolePermissions', (params) => {
  return application.call(API.ADMIN_ROLES.UPDATE_PERMISSIONS, params)
}
)
function onUpdatePermissions(state, action) {
  return Object.assign(state, {})
}

const deleteRole = createAsyncThunk(
  'adminPortalRoles/deleteRole',
  (params) => {
    return application.call(API.ADMIN_ROLES.DELETE_ROLE, params)
  }
)
function onDeleteRole(state, action) {}

const setRoleToEdit = createAction('adminPortalRoles/setRoleToEdit')
function onSetRoleToEdit(state, action) {
  return Object.assign(state, {
    currentRole: action.payload
  })
}

//Actions
export const actions = {
  getRolesList,
  createRole,
  deleteRole,
  setRoleToEdit,
  updateRole,
  updatePermissions
}

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getRolesList.fulfilled, onGetRolesList)
    .addCase(createRole.fulfilled, onCreateRole)
    .addCase(deleteRole.fulfilled, onDeleteRole)
    .addCase(setRoleToEdit, onSetRoleToEdit)
    .addCase(updateRole, onUpdateRole)
    .addCase(updatePermissions.fulfilled, onUpdatePermissions)
})
